import React, { useEffect, useRef, useState } from 'react';

import { Grid } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComexCashflow } from '../../../utils/filters/filterPresets';

import FinanceFlowTable from './components/FinanceFlowTable';
import IncomeAndExpenseChart from './components/IncomeAndExpenseChart';
import CashFlowStyles from './styles/CashFlowStyles';

const CashFlowPage = () => {
  const hasPermission = permissions.vendemmiaComexCashFlow;

  const request = useRef(0);

  const [forceSetSearchPeriod, setForceSetSearchPeriod] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [fluxCashTableDataValue, setFluxCashTableDataValue] = useState([]);
  const [inOutGraphDataValue, setInOutGraphDataValue] = useState([]);

  const [foreignExchangeAdvance, setForeignExchangeAdvance] = useState([]);
  const [exchangeBalance, setExchangeBalance] = useState([]);
  const [paymentOfNationalizationTaxes, setPaymentOfNationalizationTaxes] = useState([]);
  const [nationalizationExpenses, setNationalizationExpenses] = useState([]);
  const [projectedRevenue, setProjectedRevenue] = useState([]);
  const [invoicedBills, setInvoicedBills] = useState([]);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [period, setPeriod] = useState({
    current: '',
    previous: '',
    next: '',
  });

  const load = async (filters, key) => {
    setIsLoading(true);

    try {
      const CashFlowTable = await requests.getCashFlowTable(filters);
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;
      setPeriod({
        current: CashFlowTable.startsAt,
        previous: CashFlowTable.previous,
        next: CashFlowTable.next,
      });
      setFluxCashTableDataValue(CashFlowTable);

      let metadataAux = { ...CashFlowTable };

      delete metadataAux.dates;

      setMetadata(metadataAux);

      const GraphData = await requests.getInOutGraph(filters);
      setInOutGraphDataValue(GraphData);
    } catch (error) {
      console.error('Error fetching data from the API:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDetailsByStatus = async (slug) => {
    let getClients = localStorage.getItem('filter-comex-cashflow');
    let parsedClients = JSON.parse(getClients) || {};
    let clientsValue = parsedClients?.clients;

    try {
      const resDetailsByStatus = await requests.fetchDetailsByStatus(slug, {
        startsAt: fluxCashTableDataValue.startsAt,
        filters: {
          clients: clientsValue,
        },
      });

      switch (slug) {
        case 'adiantamento-de-cambio':
          setForeignExchangeAdvance(resDetailsByStatus);
          break;
        case 'saldo-do-cambio':
          setExchangeBalance(resDetailsByStatus);
          break;
        case 'pagamento-dos-impostos-de-nacionalizacao':
          setPaymentOfNationalizationTaxes(resDetailsByStatus);
          break;
        case 'despesas-de-nacionalizacao':
          setNationalizationExpenses(resDetailsByStatus);
          break;
        case 'faturamento-previsto':
          setProjectedRevenue(resDetailsByStatus);
          break;
        case 'duplicatas-faturadas':
          setInvoicedBills(resDetailsByStatus);
          break;
        default:
          break;
      }

      return resDetailsByStatus;
    } catch (error) {
      console.error('Error fetching details by status:', error);
      throw error;
    }
  };

  const handleNext = () => {
    setForceSetSearchPeriod({
      startsAt: period.next,
    });
  };

  const handlePrevious = () => {
    setForceSetSearchPeriod({
      startsAt: period.previous,
    });
  };

  const closeAllDetailsByStatus = () => {
    const fetchToggleButton = document.querySelectorAll('a[data-toggle-button="true"]');
    fetchToggleButton.forEach((button) => {
      button.click();
    });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComexCashflow());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    closeAllDetailsByStatus();
  }, [forceSetSearchPeriod, metadata]);

  return (
    <Page
      screen="comex-cashflow"
      title="Fluxo de caixa"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      hasPermission={hasPermission}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      filterOptions={filterOptions}
      forceSetSearchPeriod={forceSetSearchPeriod}
      showTextFilter={false}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}>
      <Grid margin={'10px 10px 20px'} gap={'15px'}>
        <CashFlowStyles />

        <ScreenLoader isLoading={isLoading}>
          <Card p={'30px'} gap={'5px'}>
            <FinanceFlowTable
              fluxCashTableDataValue={fluxCashTableDataValue}
              fetchDetailsByStatus={fetchDetailsByStatus}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              isLoading={isLoading}
              detailsByStatus={{
                foreignExchangeAdvance,
                exchangeBalance,
                paymentOfNationalizationTaxes,
                nationalizationExpenses,
                projectedRevenue,
                invoicedBills,
              }}
            />
          </Card>
        </ScreenLoader>

        {inOutGraphDataValue.data?.length > 0 && (
          <ScreenLoader isLoading={isLoading}>
            <Card>
              <IncomeAndExpenseChart inOutGraphDataValue={inOutGraphDataValue} />
            </Card>
          </ScreenLoader>
        )}
      </Grid>
    </Page>
  );
};

export default CashFlowPage;
