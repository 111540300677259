import React, { useEffect, useState } from 'react';

import { Checkbox, Text } from '@chakra-ui/react';

import FilterOption from '../../../utils/filters/FilterOption';

const FilterCheck = ({ filterOption = new FilterOption() }) => {
  const [checkState, setCheckState] = useState({
    selection: filterOption.value === 'status' || filterOption.value === 'type' ? true : filterOption.selected,
    indeterminate: filterOption.value === 'status',
  });

  const checkboxValue = filterOption.value || '';

  filterOption.checkState = setCheckState;

  useEffect(() => {
    setCheckState({
      selection: filterOption.value === 'status' || filterOption.value === 'type' ? true : filterOption.selected,
      indeterminate: filterOption.value === 'status',
    });
  }, [filterOption.selected]);

  return (
    <Checkbox
      iconColor="primary"
      value={checkboxValue}
      isChecked={checkState.selection}
      isIndeterminate={checkState.indeterminate}
      onChange={(e) => filterOption.toggleSelection()}>
      <Text textStyle="paragraph" fontSize="inherit" textColor="#0F0A1D">
        {filterOption.label}
      </Text>
    </Checkbox>
  );
};

export default FilterCheck;
