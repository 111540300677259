/* 

exemplo de uso
const monthAndDay = formatMonthDay('2023-12-04');

 */

const formatMonthDay = (inputDate, locale = 'pt-BR') => {
  if (typeof inputDate === 'string') {
    const date = new Date(`${inputDate} 00:00:01`);

    // Verifica se a data é válida antes de formatar
    if (!isNaN(date.getTime())) {
      const day = date.getDate();
      const formattedDay = day < 10 ? `0${day}` : day;

      // Ajuste: Subtraindo 1 do mês para corrigir o range
      const month = date.toLocaleString(locale, { month: 'short' });

      // Ajuste: Removendo o ponto no final
      return `${formattedDay}/${month}`.replace('.', '');
    } else {
      console.error('Invalid date:', inputDate);
    }
  } else {
    console.error('Input is not a string:', inputDate);
  }

  return '';
};

export default formatMonthDay;
