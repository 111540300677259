import freightPaymentValidation from './freightPaymentValidation';
import generalInformationValidation from './generalInformationValidation';
import mainDataValidation from './mainDataValidation';

const yup = require('yup');

export const validationSchema = () => {
  return yup.object().shape({
    main: mainDataValidation(),
    // trip: yup.array(partTripValidation()).test({
    //   message: 'Você deve selecionar um ou mais trechos de viagem',
    //   test: (arr) => {
    //     return arr.find(({ address }) => {
    //       return address;
    //     });
    //   },
    // }),
    freight: freightPaymentValidation(),
    information: generalInformationValidation(),
  });
};
