import React, { useEffect, useState } from 'react';

import { TabPanel } from '@chakra-ui/react';

import requests from '../../../../../services/requests';

import TableTravel from './components/Tables/Travel/TableTravel';

const TravelsTab = ({ user }) => {
  const [isLoadingDataload, setIsLoadingData] = useState(false);
  const [data, setData] = useState([]);

  const loadData = () => {
    setIsLoadingData(true);
    requests.listTrips(user.identifier, 'user').then((response) => {
      setData(response?.data);
      setIsLoadingData(false);
    });
  };

  useEffect(() => {
    if (user?.identifier) {
      loadData();
    }
  }, [user]);

  return (
    <TabPanel>
      <TableTravel driver={user} list={data} load={isLoadingDataload} showDetails={true} loadData={loadData} />
    </TabPanel>
  );
};

export default TravelsTab;
