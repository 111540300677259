import React, { useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ReactivationCodeForm = ({ setStep }) => {
  const yup = require('yup');

  const [pin, setPin] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [ShowConfirmPwd, setShowConfirmPwd] = useState(false);

  const handleClickShowPwd = () => setShowPwd(!showPwd);
  const handleClickShowConfirmPwd = () => setShowConfirmPwd(!ShowConfirmPwd);

  const handleChangeCode = (value) => {
    setPin(value);
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('text/plain');
    const pastedArray = pastedData.split('').slice(0, 6);

    setPin(pastedArray);
  };

  return (
    <Formik
      initialValues={{
        code: '',
        pwd: '',
        confirmPwd: '',
      }}
      validationSchema={yup.object().shape({
        code: yup.string(),
        pwd: yup.string().required('Campo de senha obrigatório'),
        confirmPwd: yup
          .string()
          .required('Campo de confirmação de senha obrigatório')
          .oneOf([yup.ref('pwd'), null], 'As senhas não são iguais!'), // yup.ref para comparação de campo
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);

        requests
          .sendNewPasswordUserReactivation({ code: pin, password: values.pwd })
          .then(() => {
            toast.success('O usuário foi reativado com sucesso');

            setStep(3);
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors, setFieldError }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="30px" className="down-anim">
            <Flex direction="column" gap="10px">
              <Text textStyle="subtitle" textColor="linkColor">
                Insira o código recebido
              </Text>
              <Text textStyle="subtitle" textColor="#FFFFFF">
                Se o seu e-mail estiver cadastrado, você irá receber um código para alterar sua senha
              </Text>
            </Flex>

            <Flex flexDirection="column" gap="20px">
              <Flex direction="column">
                <HStack color="#FFFFFF">
                  <PinInput type="alphanumeric" value={pin} onChange={handleChangeCode} onPaste={handlePaste}>
                    <PinInputField />

                    <PinInputField />

                    <PinInputField />

                    <PinInputField />

                    <PinInputField />

                    <PinInputField />
                  </PinInput>
                </HStack>
              </Flex>

              <Flex direction="column">
                <FormLabel className="up-anim" color="white" m="0">
                  Insira sua nova senha
                </FormLabel>

                <InputGroup>
                  <Input
                    variant="flushed"
                    h="40px"
                    color="#FFFFFF"
                    autocomplete="new-password"
                    type={showPwd ? 'text' : 'password'}
                    name="pwd"
                    _placeholder={{ color: '#FFFFFF' }}
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    value={values.pwd}
                    onChange={handleChange}
                  />
                  <InputRightElement color="#FF2F69" cursor="pointer" _hover={{ opacity: '0.8' }} onClick={handleClickShowPwd}>
                    {showPwd ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                  </InputRightElement>
                </InputGroup>
                <ErrorMessage component="p" className="error-message-error" name="pwd" />
              </Flex>

              <Flex direction="column">
                <FormLabel className="up-anim" color="white" m="0">
                  Confirme a senha
                </FormLabel>

                <InputGroup>
                  <Input
                    className="focus:outline-none border-2 border-#EBEBEB py-1 px-2"
                    variant="flushed"
                    h="40px"
                    color="#FFFFFF"
                    name="confirmPwd"
                    type={ShowConfirmPwd ? 'text' : 'password'}
                    _placeholder={{ color: '#FFFFFF' }}
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    value={values.confirmPwd}
                    onChange={handleChange}
                  />

                  <InputRightElement color="#FF2F69" cursor="pointer" _hover={{ opacity: '0.8' }} onClick={handleClickShowConfirmPwd}>
                    {ShowConfirmPwd ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                  </InputRightElement>
                </InputGroup>

                <ErrorMessage component="p" className="error-message-error" name="confirmPwd" />
              </Flex>
            </Flex>
          </Flex>

          <Flex
            w="full"
            mt={{ base: '20px', md: '30px' }}
            gap={{ base: '0px', md: '15px' }}
            justify={{ base: 'space-between', md: 'end' }}>
            <Button
              variant="outline"
              height="40px"
              borderRadius="5px"
              borderColor="#FF2F69"
              textColor="#FF2F69"
              transition=".3s"
              _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
              isDisabled={isSubmitting}
              onClick={() => {
                setStep(1);
              }}>
              <Text>Voltar</Text>
            </Button>

            <Button
              type="submit"
              variant="solid"
              height="40px"
              borderRadius="5px"
              bgColor="#FF2F69"
              textColor="#FFFFFF"
              transition=".3s"
              _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
              isLoading={isSubmitting}>
              <Text>Enviar</Text>
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default ReactivationCodeForm;
