import React from 'react';

import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import { getIconByText } from './getIconByText';

const TransportEventContent = ({ eventInfo, getStatusColor, isSystemAdmin, loadEventDetails }) => {
  const getColorByText = (modality) => {
    switch (modality) {
      case 'RECEBIMENTO':
        return '#a885f9';
      case 'COLETA':
        return '#6c48c2';
      default:
        return null;
    }
  };

  return (
    <Flex
      h="full"
      w="fit-content"
      bgColor="#F5F5F5"
      align="center"
      gap="10px"
      borderRadius="0px 6px 6px 0px"
      cursor="pointer"
      _hover={{ bgColor: '#F2E9FF' }}
      onClick={() => loadEventDetails(eventInfo.identifier)}>
      <Box h="full" minW="7px" borderRadius="6px 0px 0px 6px" bgColor={getColorByText(eventInfo.modality)} />

      <Flex h="full" w="full" align="center" justify="space-between" gap="10px">
        <Flex direction="column">
          <Tooltip label={eventInfo?.companyName}>
            <Text maxW="130px" fontSize="14px" fontWeight="medium" textColor="primary" isTruncated>
              {eventInfo?.companyName}
            </Text>
          </Tooltip>

          <Tooltip label={`Doca ${eventInfo.dockNumber}`}>
            <Text w="fit-content" fontSize="12px" textColor="#A6A6A6">
              Doca {eventInfo.dockNumber}
            </Text>
          </Tooltip>
        </Flex>

        <Flex h="full" w="full" justify="flex-end" gap="10px">
          <Tooltip label={eventInfo.modality}>
            <Image w="25px" src={getIconByText(eventInfo.modality)} />
          </Tooltip>

          <Tooltip label={eventInfo.stageModalityFormatted}>
            <Box h="fit-content">
              <MdTurnedIn size="20px" color={getStatusColor(eventInfo.stageModality)} />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TransportEventContent;
