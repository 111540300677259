import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  // Estrutura da tabela
  title: 'Lista de fornecedores',
  categories: [
    {
      title: 'CNPJ Cadeia Logística',
      /* field: 'e.status', */
      value: 'documentNumberFormatted',
      align: 'center',
      type: 'label',
      defaultValue: 'Não preenchido',
    },
    {
      title: 'Nome Cadeia Logística',
      /* field: 'e.description', */
      value: 'name',
    },
    {
      title: 'Endereço',
      /* field: 'e.description', */
      value: 'address',
      defaultValue: 'Não fornecido',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  subCategories: [
    {
      title: 'Contato',
      /* field: 'e.status', */
      value: 'name',
      defaultValue: 'Não informado',
    },
    {
      title: 'E-mail',
      /* field: 'e.description', */
      value: 'email',
      type: 'label',
    },
  ],
  mainCategoryMobile: 'documentNumberFormatted',
  pathSublist: 'contacts',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: true,
  collapsedContentType: 'table',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 115,
  // Estilo
  resizeRows: '250px 500px 1fr 220px',
  resizeRowsChildren: '220px 250px 1fr',
};
