import React, { useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Collapse, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

const StockAgingTable = ({ list }) => {
  const [collapse, setCollapse] = useState(null);

  const handleCollapseModality = (index) => {
    setCollapse(collapse === index ? null : index);
  };

  const calculatePercentage = (familyTotal, total) => {
    if (!total || total === 0) return '0.00';
    const percentage = (familyTotal / total) * 100;
    return isNaN(percentage) ? '0.00' : percentage.toFixed(2);
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Td w="10px"></Td>

          <Th p="10px">
            <Text fontWeight="bold" fontSize="18px" textAlign="left">
              Família
            </Text>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                0 - 15
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                16 - 30
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                31 - 60
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                61 - 90
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                91 - 180
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                181 - 360
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Flex gap="3px" align="baseline" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                Mais de 360
              </Text>
              <Text fontSize="8px">dias</Text>
            </Flex>
          </Th>

          <Th p="10px" textAlign="center">
            <Text fontWeight="bold" fontSize="18px">
              Total
            </Text>
          </Th>
        </Tr>
      </Thead>

      <Tbody>
        {Object.keys(list).map(
          (family, index) =>
            family !== 'total' && (
              <React.Fragment key={index}>
                <Tr onClick={() => handleCollapseModality(index)} cursor="pointer" className="border">
                  <Td>
                    <Flex h="60px !important">
                      {collapse === index ? (
                        <MdKeyboardArrowDown size={20} color="#707070" />
                      ) : (
                        <MdKeyboardArrowRight size={20} color="#707070" />
                      )}
                    </Flex>
                  </Td>

                  <Td>
                    <Flex h="60px !important" justify="left" textStyle="paragaph" textColor="primary" fontWeight="500">
                      {family}
                    </Flex>
                  </Td>

                  {Object.entries(list[family].total).map(([property, value], index) => (
                    <Td key={index} p="10px" w="180px">
                      <Flex h="60px !important" justify="center" align="center" direction="column">
                        {value.toLocaleString('pt-BR')}

                        <Text fontSize="12px">({calculatePercentage(value, Object.values(list.total)[index])}%)</Text>
                      </Flex>
                    </Td>
                  ))}
                </Tr>

                <Tr>
                  <Td colSpan={10} border="none" p="0px">
                    <Collapse in={collapse === index}>
                      <Flex bgColor="#F9F9F9" pb="10px">
                        <Table>
                          <Tbody>
                            {Object.keys(list[family].data).map((product, prodIndex) => (
                              <Tr key={prodIndex}>
                                <Td p="10px" w="48px">
                                  <Flex></Flex>
                                </Td>

                                <Td p="10px">
                                  <Flex textStyle="paragaph" textColor="primary" fontWeight="500">
                                    {product}
                                  </Flex>
                                </Td>

                                {Object.entries(list[family].data[product]).map(([property, value], index) => (
                                  <Td key={index} p="10px" w="180px">
                                    <Flex h="60px !important" justify="center" align="center" direction="column">
                                      {value.toLocaleString('pt-BR')}

                                      <Text fontSize="12px">({calculatePercentage(value, Object.values(list.total)[index])}%)</Text>
                                    </Flex>
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    </Collapse>
                  </Td>
                </Tr>
              </React.Fragment>
            )
        )}

        {list.total && (
          <Tr className="border">
            <Td>
              <Flex h="60px !important"></Flex>
            </Td>

            <Td>
              <Flex h="60px !important" justify="left" textStyle="paragaph" textColor="primary" fontWeight="500">
                Total
              </Flex>
            </Td>

            {Object.entries(list.total).map(([property, value], index) => (
              <Td key={index} p="10px" w="180px">
                <Flex h="60px !important" justify="center">
                  <Flex h="60px !important" justify="center" align="center" direction="column">
                    {value.toLocaleString('pt-BR')}

                    <Text fontSize="12px">({calculatePercentage(value, list.total.total)}%)</Text>
                  </Flex>
                </Flex>
              </Td>
            ))}
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default StockAgingTable;
