

export const initialValues = (entity = {}) => {
  
    return {
      code: entity?.codeReadable ? entity?.codeReadable : '',
      description: entity?.description ? entity?.description : '',
      startsAtFormatted: entity?.startsAt ? entity?.startsAt : '',
      statusReadable: entity?.statusReadable ? { value: entity?.statusReadable, label: entity?.statusReadable } : '',
    };
  };
  