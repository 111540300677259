import React from 'react';

import { MdOutlineOpenInNew } from 'react-icons/md';

import { Accordion, Flex, Image, Text } from '@chakra-ui/react';

import { processCheck, processUncheck } from '../../../../utils/exports';
import { clamp } from '../../../../utils/math';
import { toPercentage } from '../../../../utils/text/format/toPercentage';

import ProcessDetailStep from './ProcessDetailStep';
import { ProcessStyles } from './ProcessStyles';

const ProcessDetails = ({ waypoints, process, updates, loadUpdates }) => {
  const getSteps = () => {
    if (!process || !updates) return [];

    let infos = {
      preembarque: [],
      transito: [],
      desembaraco: [],
      faturamento: [],
      transporte: [],
      entrega: [],
    };

    infos.preembarque.push(
      process?.arrivalExpectedAtFormatted && {
        label: 'Previsão',
        value: process?.arrivalExpectedAtFormatted,
      },
      process?.startedAtFormatted && {
        label: 'Efetiva',
        value: process?.startedAtFormatted,
      }
    );

    infos.transito.push(
      process?.lastVoyageVessel &&
        process.currentStageModalitySequential === 2 && { label: 'Navio atual', value: process.lastVoyageVessel },
      process.transitTime && { label: 'Transit time prevista', value: process.transitTime + ' dias' },
      process?.containerList && {
        label: 'Containers',
        value: process?.containerList?.containers?.length,
        disableDrawer: false,
        icon: (
          <Text cursor={'pointer'} p={'2px 3px'} bg={'#D0B0FD36'} borderRadius={'3px'}>
            <MdOutlineOpenInNew color="#6C48C2" size={15} />
          </Text>
        ),
      },
      //
      process?.carbonTrackTransitStage &&
        process?.carbonTrackTransitStage.status !== 'PREVENTED' && {
          label: 'Pegada CO₂',
          value:
            process.carbonTrackTransitStage.calculating +
              process.carbonTrackTransitStage.calculated +
              process.carbonTrackTransitStage.retired ?? 'Calculando',
          block: true,
          disableDrawer: true,
          tooltipText:
            process.carbonTrackTransitStage.calculating +
              process.carbonTrackTransitStage.calculated +
              process.carbonTrackTransitStage.retired >
            0
              ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${
                  process.carbonTrackTransitStage.calculating + process.carbonTrackTransitStage.calculated
                } toneladas de C02eq com ${toPercentage(
                  (process.carbonTrackTransitStage.retired /
                    (process.carbonTrackTransitStage.retired +
                      process.carbonTrackTransitStage.calculating +
                      process.carbonTrackTransitStage.calculated)) *
                    100
                )} compensados`
              : 'O cálculo para esta etapa está indisponível',
          icon: (
            <Text>
              {process.carbonTrackTransitStage.retired > 0 ? (
                <Image src={processCheck} opacity={'1'} />
              ) : (
                <Image src={processUncheck} opacity={'1'} />
              )}
            </Text>
          ),
        },
      //
      process?.billOfLadingIndentifier && { label: 'Conhecimento de embarque', value: process?.billOfLadingIndentifier },
      waypoints && waypoints.destination
        ? {
            waypoints,
            ...(waypoints.destination.arrivalExpected = process.arrivalExpectedAtFormatted),
          }
        : {
            waypoints,
          }
    );

    if (process?.arrivalExpectedAtFormatted) {
      infos.desembaraco.push({
        label: process?.clearenceDiAtFormatted ? 'Previsão' : 'Prev. chegada',
        value: process.arrivalExpectedAtFormatted,
      });
    }

    if (process?.arrivedAtFormatted) {
      infos.desembaraco.push({ label: 'Chegada', value: process.arrivedAtFormatted });
    }

    if (process?.clearenceCiAtFormatted) {
      infos.desembaraco.push({ label: 'CI', value: process.clearenceCiAtFormatted });
    }

    if (process?.clearenceDiAtFormatted) {
      infos.desembaraco.push({ label: 'Registro', value: process.clearenceDiAtFormatted });
    }

    if (process?.diNumber) {
      infos.desembaraco.push({ label: 'Número da DI', value: process.diNumber });
    }

    if (process?.billedAtFormatted) {
      infos.faturamento.push({ label: 'Emissão', value: process.billedAtFormatted });
    }

    if (process?.idNota) {
      infos.faturamento.push({ label: 'NF', value: process.idNota });
    }

    infos.transporte.push(
      process?.storedAtFormatted && { label: 'Carregamento', value: process.storedAtFormatted },
      process?.deliveredAtFormatted && { label: 'Entrega', value: process.deliveredAtFormatted },
      //
      process?.carbonTrackDeliverStage &&
        process?.carbonTrackDeliverStage.status !== 'PREVENTED' && {
          label: 'Pegada CO₂',
          value: process?.carbonTrackDeliverStage.calculated + process?.carbonTrackDeliverStage.retired ?? 'Calculando',
          block: true,
          disableDrawer: true,
          tooltipText:
            process?.carbonTrackDeliverStage.calculated + process?.carbonTrackDeliverStage.retired > 0
              ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${
                  process?.carbonTrackDeliverStage.calculating + process?.carbonTrackDeliverStage.calculated
                } toneladas de C02eq com ${toPercentage(
                  (process?.carbonTrackDeliverStage.retired /
                    (process?.carbonTrackDeliverStage.retired +
                      process?.carbonTrackDeliverStage.calculating +
                      process?.carbonTrackDeliverStage.calculated)) *
                    100
                )} compensados`
              : 'O cálculo para esta etapa está indisponível',
          icon: (
            <Text>
              {process?.carbonTrackDeliverStage.retired > 0 ? (
                <Image src={processCheck} opacity={'1'} />
              ) : (
                <Image src={processUncheck} opacity={'1'} />
              )}
            </Text>
          ),
        },
      //
      {
        trips: process?.trips ?? [],
      }
    );

    if (process?.deliveredAtFormatted) {
      infos.entrega.push({ label: 'Entrega', value: process.deliveredAtFormatted });
    }

    return [
      {
        title: 'Pré embarque',
        progress: clamp(process.progressBar / 20.0 - 0, 0, 1),
        block: false,
        infos: infos.preembarque,
        updates: updates[0],
      },
      {
        title: <Flex align={'center'}>{`Trânsito`}</Flex>,
        progress: clamp(process.progressBar / 20.0 - 1, 0, 1),
        borderGreen: process.carbonTrackTransitStage.calculated > 0 ? true : false,
        block: false,
        infos: infos.transito,
        updates: updates[1],
      },
      {
        title: 'Desembaraço',
        progress: clamp(process.progressBar / 20.0 - 2, 0, 1),
        block: false,
        infos: infos.desembaraco,
        updates: updates[2],
      },
      {
        title: !process?.billedAtFormatted ? 'Em faturamento' : 'Faturado',
        progress: !process?.billedAtFormatted && process.progressBar > 60 ? 0 : clamp(process.progressBar / 20.0 - 3, 0, 1),
        block: false,
        infos: infos.faturamento,
        updates: updates[3],
      },
      {
        title: 'Transporte',
        progress: clamp(process.progressBar / 20.0 - 4, 0, 1),
        borderGreen: process.carbonTrackDeliverStage.calculated > 0 ? true : false,
        block: false,
        infos: infos.transporte,
        updates: updates[4],
      },
      {
        title: 'Entrega',
        progress: process.progressBar === 100 ? 1 : 0,
        block: false,
        infos: infos.entrega,
        updates: updates[5],
      },
    ];
  };

  return (
    <Accordion allowMultiple size={'xl'} w="100%" direction="column" defaultIndex={[process.currentStageModalitySequential - 1]}>
      <ProcessStyles />
      {!loadUpdates &&
        getSteps().map((step, i) => (
          <ProcessDetailStep
            key={i}
            stepCount={i}
            delay={i}
            progress={step.progress}
            waypoints={waypoints}
            process={process}
            title={step.title}
            infos={step.infos}
            updates={updates[i]}
            isLast={i == getSteps().length - 1}
            borderGreen={step.borderGreen}
          />
        ))}
    </Accordion>
  );
};

export default ProcessDetails;
