import { useEffect, useState } from 'react';

import { MdOutlineModeEditOutline } from 'react-icons/md';

import { Button, Image, Text, useDisclosure } from '@chakra-ui/react';

import add from '../../../assets/images/add.svg';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicForm, DynamicFormButton, DynamicFormFooter } from '../../../components/CommonForm';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';
import DynamicFormClientManagement from './components/DynamicFormClientManagement';
import ModalManagementClient from './components/ModalManagementClient';

const yup = require('yup');

const ClientManagementPage = () => {
  const hasPermission = permissions.vendemmiaManagementClientManagement;
  const hasPermissionRegister = permissions.vendemmiaManagementClientRegister;

  const [isMobile, setIsMobile] = useState(false);

  const [identifier, setIdentifier] = useState();

  const [list, setList] = useState([]);

  const [initialValues, setInitialValues] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();
  const [isLoadingNew, setIsLoadingNew] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    documentNumber: yup.string().required('Campo obrigatório'),
  });

  const load = (filters, key) => {
    fetchClientListData(filters, metadata.current_page, 25);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const fetchClientListData = async (filters, page, pageSize) => {
    const res = await executeRequest({
      action: () => requests.listClients(filters, page, pageSize, 'all'),
      setIsLoading,
    });

    setList(res.data);
    setMetadata(res.meta);
  };

  const subtitle = `Existe um total de ${metadata.total_count} clientes`;

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  const handleOpenEditModal = (identifier) => {
    onEditModalOpen();
    setIdentifier(identifier);
  };

  const handleEditModalClose = () => {
    onEditModalClose();
    load();
  };

  //component preset
  const customButtons = [
    {
      main: [
        { label: 'Editar', tooltip: 'Editar cliente', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenEditModal },
      ],
      collapsed: [],
    },
  ];

  //component preset

  const handleOpenModalNew = () => {
    setInitialValues({
      name: undefined,
      companyName: undefined,
      documentNumber: undefined,
      city: undefined,
      codeState: 'SP',
      conexosId: undefined,
    });
    onOpen();
  };

  const handleClientNew = async (values) => {
    await executeRequest({
      action: () => requests.addClient(values),
      msgSuccess: 'Cliente criado com sucesso',
      msgError: 'Erro ao criar o cliente, tente novamente.',
      setIsLoadingNew,
      triggerRefresh,
      callback: () => onClose(),
    });
  };

  useEffect(() => {
    load();
  }, [action]);

  return (
    <Page
      screen="client-management"
      title={`Gestão de clientes`}
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por nome ou CNPJ"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      showPeriodFilter={false}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      isRefreshLoading={isLoading}
      showFilters={false}
      showRefreshData={false}
      allowFiltersOverride={false}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
      forceLoadTrigger={forceLoadTrigger}
      {...(hasPermissionRegister && {
        FAB: [
          {
            title: 'Cadastrar cliente',
            text: 'Cadastro de cliente',
            action: (
              <Button variant="primary" pt="23px" pb="23px" borderRadius="50px" onClick={handleOpenModalNew}>
                <Image src={add} />
                <Text ml="10px">Novo cliente</Text>
              </Button>
            ),
            modality: 'custom',
          },
        ],
      })}>
      <ModalManagementClient
        triggerRefresh={triggerRefresh}
        identifier={identifier}
        handleClose={handleEditModalClose}
        handleOpen={onEditModalOpen}
        isOpen={isEditModalOpen}
      />

      <CommonList
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //custom
        customButtons={customButtons}
        //others
        subtitle={subtitle}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />
      <CommonDrawer
        heading="Dados do cliente"
        placement="left"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isLoading={isLoadingNew}>
        <DynamicForm config={{ initialValues, validationSchema, callback: handleClientNew }}>
          <DynamicFormClientManagement />
          <DynamicFormFooter>
            <DynamicFormButton name={'Salvar'} type="button" />
          </DynamicFormFooter>
        </DynamicForm>
      </CommonDrawer>
    </Page>
  );
};

export default ClientManagementPage;
