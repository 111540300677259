import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Consulta tributária',
  // Estrutura da tabela
  categories: [
    {
      title: 'Consulta',
      value: 'code',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Cliente',
      field: 'e.clientDocumentNumber',
      value: 'clientDocumentNumber',
    },
    {
      title: 'Filial Vendemia',
      field: 'e.companyBranchName',
      value: 'companyBranchName',
      align: 'center',
    },
    {
      title: 'Modalidade importação',
      field: 'e.importModality',
      value: 'importModality',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Benefício fiscal',
      field: 'e.taxBenefit',
      value: 'taxBenefit',
      align: 'center',
    },
    {
      title: 'Solicitação',
      field: 'e.requestedAt',
      value: 'requestedAt',
      align: 'center',
    },
    {
      title: 'Validade',
      field: 'e.expiresAt',
      value: 'expiresAt',
      align: 'center',
    },
    {
      title: 'Status',
      field: 'e.status',
      value: 'status',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  hasDetailsButton: true,
  detailsRedirectEndpoint: '/vendemmia/tax-inquiry/detail/${identifier}',
  mainCategoryMobile: 'code',
  tags: ['status'],
  statusPreset: [
    { color: '#47A4F7', label: 'Em validação', value: 'Em validação' },
    { color: '#2ECC71', label: 'Em uso', value: 'Em uso' },
    { color: '#E9C743', label: 'Em estudo', value: 'Em estudo' },
    { color: '#E74C3C', label: 'Cancelada', value: 'Cancelada' },
  ],
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 50,
  // Estilo
  resizeRows: '120px 150px 1fr 1fr 1fr 1fr 1fr 1fr 80px',
};
