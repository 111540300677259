import React, { useEffect } from 'react';

import { ErrorMessage } from 'formik';
import { MdAdd, MdDelete } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';

import { Box, Flex, Grid, IconButton, Text, Textarea, Tooltip } from '@chakra-ui/react';

import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import sizeCtrOptions from '../Helpers/sizeCtrOptions';
import typeCtrOptions from '../Helpers/typeCtrOptions';

const FormContainers = ({ values, setFieldValue, selectCustomStyles }) => {
  const handleAddClick = () => {
    const newField = {
      qtt: '',
      size: '',
      type: '',
    };

    setFieldValue('ctrs', [...values.ctrs, newField]);
  };

  const handleDeleteClick = (index) => {
    const newValues = values.ctrs.filter((_, i) => i !== index);
    setFieldValue('ctrs', newValues);
  };

  useEffect(() => {
    if (!values.ctrs || values.ctrs.length === 0) {
      setFieldValue('ctrs', [
        {
          qtt: '',
          size: '',
          type: '',
        },
      ]);
    }
  }, []);

  return (
    <Flex m="20px" gap="20px" direction="column">
      <Text textStyle="cardTitle">Containers</Text>
      {values.ctrs.map((ctr, index) => (
        <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 90px" gap="15px" key={index}>
          <Flex direction="column">
            <LabelDefault name="qtt" text="Quantidade CTR" />

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              displayType="input"
              allowNegative={false}
              fixedDecimalScale
              placeholder="Quantidade"
              //
              name={`ctrs[${index}].qtt`}
              onChange={(event) => setFieldValue(`ctrs[${index}].qtt`, event.target.value)}
              value={values.ctrs[index]?.qtt}
            />

            <ErrorMessage component="p" className="error-message-error" name={`ctrs[${index}].qtt`} />
          </Flex>

          <Flex direction="column">
            <LabelDefault name="size" text="Tamanho" />

            <Select
              styles={selectCustomStyles()}
              options={sizeCtrOptions}
              name={`ctrs[${index}].size`}
              value={values.ctrs[index]?.size}
              onChange={(option) => setFieldValue(`ctrs[${index}].size`, option)}
              className="input-register"
              placeholder="Selecione o tamanho"
            />

            <ErrorMessage component="p" className="error-message-error" name={`ctrs[${index}].size`} />
          </Flex>

          <Flex direction="column">
            <LabelDefault name="type" text="Tipo" />

            <Select
              styles={selectCustomStyles()}
              options={typeCtrOptions}
              name={`ctrs[${index}].type`}
              value={values.ctrs[index]?.type}
              onChange={(option) => setFieldValue(`ctrs[${index}].type`, option)}
              className="input-register"
              placeholder="Selecione o tipo"
            />

            <ErrorMessage component="p" className="error-message-error" name={`ctrs[${index}].type`} />
          </Flex>

          <Flex direction="column">
            <LabelDefault name="description" text="Observação (opcional)" />

            <Textarea resize="none" bg="#F2F2F2" values={values.quoteObservations} />
          </Flex>

          <Flex justify="space-between" align="center">
            {index === values.ctrs.length - 1 && (
              <Tooltip label="Adicionar">
                <Box>
                  <IconButton
                    borderRadius="full"
                    bgColor="#2ECC7152"
                    border="1px dashed #2ECC71"
                    icon={<MdAdd color="#2ECC71" />}
                    onClick={() => {
                      handleAddClick();
                    }}
                  />
                </Box>
              </Tooltip>
            )}

            {values?.ctrs.length > 1 && (
              <Tooltip label="Excluir">
                <Box>
                  <IconButton icon={<MdDelete color="#E52359" />} onClick={() => handleDeleteClick(index)} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Grid>
      ))}
    </Flex>
  );
};

export default FormContainers;
