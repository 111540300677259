import React, { useState } from 'react';

import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

/**
 *
 * @param downloadAction Função ao clicar no botão
 * @param title Texto apresentado no botão
 * @param text Texto apresentado no modal
 */

const ModalDownloadFile = ({
  downloadAction = () => {},
  title = 'Baixar todos',
  text = 'Exportar todos arquivos'
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const downloadCsv = () => {
    setIsDownloadingCsv(true);
    downloadAction();
    setIsDownloadingCsv(false);
    onClose();
  };

  return (
    <>
      <Button
        variant="primary"
        bgColor="myoga"
        mr="10px"
        _hover={{ bgColor: '#ea4e7a' }}
        _focus={{ bgColor: 'myoga' }}
        onClick={() => downloadAction()}>
        <Text fontSize="14px" textColor="#FFFFFF">
          {title}
        </Text>
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent p="10px 35px" borderRadius="9px">
          <ModalBody p="35px" position="relative">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text textStyle="primaryTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
                {text}
              </Text>

              <Button
                variant="primary"
                w="fit-content"
                borderRadius="7px"
                m="15px"
                p="25px 35px"
                loadingText="Carregando..."
                isLoading={isDownloadingCsv}
                onClick={downloadCsv}>
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDownloadFile;
