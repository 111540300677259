import React, { useEffect, useReducer, useState } from 'react';

import { Text } from '@chakra-ui/react';

const initialState = { text: '', index: 0 };

function reducer(state, action) {
  switch (action.type) {
    case 'type':
      return { text: state.text + action.character, index: state.index + 1 };
    default:
      throw new Error();
  }
}

const TypingText = ({ text, startDelay = 200, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [delayOver, setDelayOver] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayOver(true);
    }, startDelay);
    return () => clearTimeout(timeoutId);
  }, [startDelay]);

  useEffect(() => {
    if (delayOver && state.index < text.length) {
      const timeoutId = setTimeout(() => {
        dispatch({ type: 'type', character: text[state.index] });
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [text, state.index, delayOver]);

  return <Text {...props} dangerouslySetInnerHTML={{ __html: state.text }} />;
};

export default TypingText;
