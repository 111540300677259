import { Link as RouterLink } from 'react-router-dom';

import { Button } from '@chakra-ui/button';
import { Link } from '@chakra-ui/layout';

export function PanelTabsLink({ tab, tabActive, title, text, role, icon, action, link }) {
  const profileRole = localStorage.getItem('profile-role');

  return profileRole !== role ? (
    <Link as={RouterLink} to={`${link}${tabActive ? '#' + tabActive : ''}`}>
      <Button
        variant={tab === tabActive ? 'panelTabsButton' : 'secundary'}
        borderRadius="0"
        p={'0 25px'}
        height={'49px'}
        outline={'none'}
        border={'0 !important'}
        title={title}
        rightIcon={icon}
        onClick={action}>
        {text}
      </Button>
    </Link>
  ) : (
    <></>
  );
}
