import React, { useState } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

import Loading from '../../../components/Loading/Loading';
import BackgroundLogin from '../../../components/Login/BackgroundLogin/BackgroundLogin';
import requests from '../../../services/requests';

import NewPwd from './components/NewPwd';
import RecoverPwd from './components/RecoverPwd';

const RecoverPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);

  return (
    <BackgroundLogin showLogo={false}>
      {step === 1 && <RecoverPwd setStep={setStep} setIsLoading={setIsLoading} />}

      {step === 2 && <NewPwd step={step} setStep={setStep} setIsLoading={setIsLoading} />}

      {step === 3 && (
        <Flex direction="column" gap="20px" justify="center" align="center" className="down-anim">
          <Text textStyle="subtitle" textColor="#FFFFFF">
            Senha alterada com sucesso!
          </Text>

          <Button
            w="fit-content"
            variant="solid"
            height="40px"
            borderRadius="5px"
            bgColor="#FF2F69"
            textColor="#FFFFFF"
            transition=".3s"
            _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
            onClick={() => {
              setIsLoading(true);

              let username = localStorage.getItem('profile-email');
              let password = localStorage.getItem('profile-pwd');

              requests
                .authLogin({ username: username, password: password })
                .then((res) => {
                  var token = res.data.token;
                  localStorage.setItem('session-token', token);

                  requests.getProfile().then((profile) => {
                    localStorage.setItem('profile-role', profile.role);
                    localStorage.setItem('profile-identifier', profile.identifier);
                    localStorage.setItem('profile-name', profile.name);
                    localStorage.setItem('profile-email', profile.email);
                    localStorage.setItem('profile-cellphone', profile.cellphone);
                    localStorage.setItem('profile-phone', profile.phone);
                    localStorage.setItem('profile-role-human-readable', profile.roleHumanReadable);
                    localStorage.setItem('profile-picture', profile.picture);
                    localStorage.setItem('profile-permissions', JSON.stringify(profile.permissions));

                    let redirectUserTo = window.localStorage.getItem('redirectTo') ?? '/dashboard';
                    let redirectUserExpiresAt = window.localStorage.getItem('redirectToExpiresAt');

                    if (!redirectUserExpiresAt || new Date() > redirectUserExpiresAt) {
                      redirectUserTo = '/dashboard';
                    }

                    window.localStorage.removeItem('redirectTo');
                    window.localStorage.removeItem('redirectToExpiresAt');
                    window.location.href = redirectUserTo;

                    localStorage.removeItem('profile-pwd');

                    return;
                  });
                  setIsLoading(false);
                })
                .catch((error) => {
                  setIsLoading(false);

                  localStorage.setItem('profile-email', values.user);

                  const message =
                    'Este usuário está expirado e deve solicitar reativação de seu acesso, entre em contato com o time da Vendemmia.';

                  if (error.response.data.message === message) {
                    window.location.href = '/reactivation';
                  }
                });
            }}>
            <Text>Acessar</Text>
          </Button>
        </Flex>
      )}

      <Loading status={isLoading} />
    </BackgroundLogin>
  );
};

export default RecoverPage;
