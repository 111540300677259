export const CommonModalStyles = () => {
  return (
    <style>
      {`
        body [role="region"] div {
          background-color: transparent;
        }
      `}
    </style>
  );
};
