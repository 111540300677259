/* Exemplo de uso

const [inputNcm, setInputNcm] = useState('');

<Input
  value={inputNcm}
  onChange={(event) => {
    const formattedNCM = formatNCM(event.target.value);
    setInputNcm(formattedNCM); Saída: 9705.22.00
  }}
  maxLength="18"
/>;

*/

const formatNCM = (value) => {
  // Remove caracteres não numéricos
  const unformattedValue = value.replace(/\D/g, '');

  // Aplica a máscara usando regex
  const formattedValue = unformattedValue.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');

  return formattedValue;
};

export default formatNCM;
