import React from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ReviewAsOperational = ({ entity, callback }) => {
  return (
    <Formik
      initialValues={{
        status: 'review_as_operational',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        if (entity?.status === 'ON_REVIEW_LOGISTICS' && entity?.logisticInformation?.length === 0) {
          toast.error(
            `Por favor, preencha os campos de cotação antes de prosseguir e não se esqueça de salvar. Após isso, siga com o procedimento conforme necessário`
          );
          setSubmitting(false);
          return;
        }

        requests
          .postRequestOperacional(entity?.identifier, {
            status: values.status,
          })
          .then(() => {
            toast.success('Análise operacional solicitada com sucesso!');

            setTimeout(() => {
              window.location.href = '/vendemmia/quote';
            }, 2000);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex mt="20px" justify="center" direction="column" gap="20px">
            <Text textStyle="tableTitle" fontWeight="bold" textAlign="center">
              Deseja solicitar análise operacional? {entity.name}
            </Text>

            <Text textAlign="center">Para prosseguir, clique em confirmar</Text>
          </Flex>

          <Flex justify="space-between">
            <Button
              variant="secundary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="25px 35px"
              isDisabled={isSubmitting}
              onClick={() => callback(false)}>
              Cancelar
            </Button>

            <Button type="submit" variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default ReviewAsOperational;
