import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import permissions from '../../../services/permissions';

import CardMetricsRequest from './components/CardMetricsRequest';
import CardMetricsUpdate from './components/CardMetricsUpdate';

const SystemReportsPage = () => {
  const [searchPeriod, setSearchPeriod] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    !permissions.vendemmiaManagementItReports && navigate('/');
  }, []);

  const onChangePeriod = (date) => {
    setSearchPeriod(date);
  };

  return (
    <Layout>
      <Menubar
        title="Relatórios de tecnologia"
        placeholder="Processo, empresa ou ref"
        showTextFilter={false}
        showFilters={false}
        onChangePeriod={onChangePeriod}
      />
      <Flex gap="0px" direction="column">
        <CardMetricsUpdate searchPeriod={searchPeriod} />
        <CardMetricsRequest searchPeriod={searchPeriod} />
      </Flex>
    </Layout>
  );
};

export default SystemReportsPage;
