import React, { useRef, useState } from 'react';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { MdBlock, MdOutlineFileUpload, MdOutlineModeEditOutline, MdOutlineVisibility } from 'react-icons/md';

import { Button, Flex, FormControl, Text, Textarea, useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../../components/CommonModal/CommonModal';
import Page from '../../../../components/Page';
import { useForceRefresh } from '../../../../hooks/useForceRefresh';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';
import ModalNewPolicy from './components/ModalNewPolicy';

const PolicyPrivacyPage = () => {
  const hasPermission = permissions.vendemmiaManagementPolicy;
  const [isMobile, setIsMobile] = useState(false);
  const request = useRef(0);

  const [list, setList] = useState([]);

  //methods
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //fields
  const [identifier, setIdentifier] = useState();
  const [description, setDescription] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  //modals methods
  const { isOpen: modalCloseIsOpen, onOpen: modalCloseOnOpen, onClose: modalCloseOnClose } = useDisclosure();
  const { isOpen: modalEditIsOpen, onOpen: modalEditOnOpen, onClose: modalEditOnClose } = useDisclosure();
  const { isOpen: modalPublishIsOpen, onOpen: modalPublishOnOpen, onClose: modalPublishOnClose } = useDisclosure();
  const { isOpen: modalViewIsOpen, onOpen: modalViewOnOpen, onClose: modalViewOnClose } = useDisclosure();

  //init
  const load = (filters, key) => {
    setIsLoading(true);
    requests.listPolicyPrivacy(filters, metadata.current_page > 0 ? metadata.current_page : 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;
      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  //field state
  const handleContentChange = (event) => {
    setDescription(event.target.value);
  };

  //instances
  const definePreset = (id, data) => {
    setIdentifier(id);
    data &&
      (setDescription(data?.description || ''), setCreatedAt(data?.createdAt || ''), reset({ description: data?.description || '' }));
  };

  //edit
  const handleOpenModalEdit = (id, data) => {
    definePreset(id, data);
    modalEditOnOpen();
  };

  //publish
  const handleOpenModalPublish = (id, data) => {
    definePreset(id, data);
    modalPublishOnOpen();
  };

  //view
  const handleOpenModalView = (id, data) => {
    definePreset(id, data);
    modalViewOnOpen();
  };

  //cancel
  const handleOpenModalCancel = (id) => {
    definePreset(id);
    modalCloseOnOpen();
  };

  //delete
  const handleOpenModalDelete = (id, data) => {
    definePreset(id, data);
    /* modalDeleteOnOpen(); */
  };

  //submit editform
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleEditSubmit = async () => {
    await executeRequest({
      action: () => requests.editPolicyPrivacy(identifier, { description }),
      msgSuccess: 'Tópico editado com sucesso',
      msgError: 'Erro ao editar, tente novamente!',
      setIsLoading,
      triggerRefresh,
    });
    modalEditOnClose();
  };

  const customButtons = [
    {
      main: [
        {
          label: 'Prévia',
          tooltip: 'Visualizar',
          icon: <MdOutlineVisibility size={20} />,
          isButtonVisible: (item) => item.status === 'PUBLISHED' || item.status === 'DEPRECATED',
          action: handleOpenModalView,
        },
        {
          label: 'Editar',
          tooltip: 'Editar',
          icon: <MdOutlineModeEditOutline size={20} />,
          isButtonVisible: (item) => item.status === 'DRAFT',
          action: handleOpenModalEdit,
        },
        {
          label: 'Publicar',
          tooltip: 'Publicar',
          icon: <MdOutlineFileUpload size={20} />,
          isButtonVisible: (item) => item.status === 'DRAFT',
          action: handleOpenModalPublish,
        },
        {
          label: 'Cancelar',
          tooltip: 'Cancelar',
          icon: <MdBlock size={20} color="#E74C3C" />,
          isButtonVisible: (item) => item.status === 'DRAFT',
          action: handleOpenModalCancel,
        },
        {
          label: 'Excluir',
          tooltip: 'Excluir',
          role: ['ADMIN'],
          icon: <FaTrashAlt size={16} color="#E74C3C" />,
          isButtonVisible: (item) => item.status === 'CANCELLED' || item.status === 'DEPRECATED',
          action: handleOpenModalDelete,
        },
      ],
      collapsed: [],
    },
  ];

  return (
    <Page
      screen="policy-privacy"
      title="Políticas de Privacidade"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      showFilters={false}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
      allowEmptySearchString={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar política de privacidade',
          text: 'Cadastro de política de privacidade',
          action: <ModalNewPolicy addPolicyToList={triggerRefresh} />,
          modality: 'custom',
        },
      ]}>
      <CommonList
        //lists
        list={list}
        rawData={list}
        action={action}
        //actions
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //custom
        customButtons={customButtons}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />

      {/* cancel */}
      <CommonModal
        heading={'Certeza que deseja cancelar?'}
        callback={() =>
          executeRequest({
            action: () => requests.cancelPolicyPrivacy(identifier, {}),
            msgSuccess: 'Tópico cancelado com sucesso!',
            msgError: 'Erro ao cancelar o tópico, tente novamente!',
          })
        }
        isOpen={modalCloseIsOpen}
        onOpen={modalCloseOnOpen}
        onClose={modalCloseOnClose}
        variant={'caution'}
        size={'md'}
        removeRadius={true}
      />

      {/* edit */}
      <CommonModal
        heading={'Edição da Politica de Privacidade'}
        isOpen={modalEditIsOpen}
        onOpen={modalEditOnOpen}
        onClose={modalEditOnClose}
        size={'3xl'}
        removeRadius={true}>
        <Flex as="form" h="full" direction="column" onSubmit={handleSubmit(handleEditSubmit)}>
          <FormControl p="3px" h="full">
            <Textarea
              h="full"
              minH={'400px'}
              resize="none"
              value={description}
              bgColor="#F2F2F2"
              borderColor="#7070703B"
              placeholder="Descreva a aqui...."
              name="description"
              {...register('description', { required: true })}
              onChange={handleContentChange}
            />
            {errors.description && <Text textStyle="error">Campo obrigatório.</Text>}
          </FormControl>

          <Flex alignItems="center" justifyContent="center">
            <Button variant="secundary" borderRadius="7px" m="15px" p="25px 35px" onClick={modalEditOnClose}>
              Cancelar
            </Button>

            <Button
              variant="primary"
              bgColor="green"
              borderRadius="7px"
              m="15px"
              p="25px 35px"
              _hover={{ opacity: 0.8 }}
              _active={{ background: 'green' }}
              _focus={{ background: 'green' }}
              type="submit"
              isLoading={isLoading}
              loadingText="Salvando">
              Salvar
            </Button>
          </Flex>
        </Flex>
      </CommonModal>

      {/* publish */}
      <CommonModal
        heading={'Certeza que deseja publicar?'}
        subtitle={'Todos os demais tópicos publicados ficaram como obsoleto'}
        callback={() =>
          executeRequest({
            action: () => requests.publishPolicyPrivacy(identifier, { status: 'PUBLISH' }),
            msgSuccess: 'Tópico publicado com sucesso',
            msgError: 'Erro ao publicar o tópico, tente novamente!',
          })
        }
        isOpen={modalPublishIsOpen}
        onOpen={modalPublishOnOpen}
        onClose={modalPublishOnClose}
        variant={'clear'}
        size={'md'}
        removeRadius={true}
      />

      {/* view */}
      <CommonModal
        heading={'Politicas de privacidade'}
        subtitle={`Criado em: ${moment(createdAt).format('DD/MM/YYYY ')}`}
        isOpen={modalViewIsOpen}
        onOpen={modalViewOnOpen}
        onClose={modalViewOnClose}
        variant={'clear'}
        size={'6xl'}
        removeRadius={false}>
        <Text textStyle="paragraph" fontSize={'16px'} textAlign="justify">
          {description}
        </Text>
      </CommonModal>
    </Page>
  );
};

export default PolicyPrivacyPage;
