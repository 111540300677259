export const toPercentage = (number, decimalPlaces = 2) => {
  // Verifica se o número é 0, null, NaN ou undefined e o converte para 0
  if (number === 0 || number === null || isNaN(number) || number === undefined) {
    number = 0;
  }

  // Verifica se o tipo é number ou string
  if (typeof number !== 'number' && typeof number !== 'string') {
    throw new TypeError('O valor a ser convertido precisa ser um número ou string.');
  }

  // Converte string para number se necessário
  const numberValue = typeof number === 'string' ? parseFloat(number) : number;

  // Verifica se a conversão foi bem-sucedida (NaN indica erro)
  if (isNaN(numberValue)) {
    throw new TypeError('O valor fornecido não é um número válido.');
  }

  // Formata o número como porcentagem
  return `${numberValue.toFixed(decimalPlaces)}%`;
};
