import React from 'react';

export const LabelDefault = ({ name, text, children, style, title = null}) => {
  return (
    <label
      style={{ color: '#422C76', ...style }}
      htmlFor={name}
      title={title ?? undefined} 
    >
      {children}
      {text}
    </label>
  );
};
