import React from 'react';

import { MdHelp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Image } from '@chakra-ui/react';

import logo from '../../assets/images/logo.svg';
import DropdownProfile from '../DropdownProfile/DropdownProfile';
import FAQ from '../Modal/FAQ/FAQ';

import { HeaderStyles } from './HeaderStyles';
import { Navbar } from './Navbar';

const Header = () => {
  return (
    <Box as="header" position="fixed" top="0" zIndex="1399" left="0" right="0" height="63px">
      <HeaderStyles />
      <Box display="flex" w="full" h="full" bgColor="primary" p={{ base: '10px 20px', lgr: '17px 25px' }} boxSizing="border-box">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          w="full"
          display={{ base: 'grid', lgr: 'flex' }}
          gridTemplateColumns={{ base: '85px 1fr 85px', lgr: 'initial' }}>
          <Flex
            order={{ base: '1', lgr: 'initial' }}
            direction="row"
            align="center"
            gap="165px"
            justifyContent={{ base: 'center', lgr: 'initial' }}>
            <Link to="/dashboard">
              <Image src={logo} alt="logo vendemmia" minH={'25px'} maxH={{ base: '25px', lgr: '29px' }} />
            </Link>
          </Flex>

          <Navbar />

          <Flex
            order={{ base: '2', lgr: 'initial' }}
            direction="row"
            align="center"
            gap={{ base: '5px', lgr: '20px' }}
            justify="flex-end">
            {/*<MdCircleNotifications size={25} color="#FFFFFFB3" cursor="pointer" onClick={() => {}} />*/}
            <FAQ selectedContent={0} customButton={<MdHelp size={25} color="#FFFFFFB3" cursor="pointer" />} />
            <DropdownProfile />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
