import React, { useEffect, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { CommonModal } from '../../../../components/CommonModal/CommonModal';
import requests from '../../../../services/requests';
import roleOptions from '../Helpers/roleOptions';

import AuthData from './AuthData';
import ClientData from './ClientData';
import PermissionData from './PermissionData';
import RegistrationData from './RegistrationData';

const ModalManagementUser = ({
  loadList,
  isEditMode = false,
  identifier,
  handleClose,
  handleOpen,
  textButton = 'Novo usuário',
  callhandleClose = false,
  isOpen,
  setFields,
  fields,
  isBasicRegister = false,
  roles = roleOptions,
}) => {
  const [entity, setEntity] = useState({});
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);

  if (entity?.identifier == null) {
    roles = roles?.filter((item) => item.slug !== 'driver');
  }

  const getUser = () => {
    setIsLoadingEntity(true);

    requests
      .showUser(identifier)
      .then((data) => {
        setEntity(data);
        setIsLoadingEntity(false);
      })
      .catch((error) => {
        setIsLoadingEntity(false);
      });
  };

  useEffect(() => {
    if (identifier !== '' && isEditMode === true) {
      getUser(identifier);
    }
  }, [identifier]);

  return (
    <>
      {!isEditMode && (
        <Button
          leftIcon={<MdAdd color="#FFFFFF" size={20} />}
          _hover={{ bgColor: '#70D499' }}
          bg="green"
          p="1.5rem"
          pb="1.5rem"
          pl="1.3rem"
          pr="1.3rem"
          borderRadius="50px"
          onClick={handleOpen}>
          <Text mr="10px" color="white">
            {textButton}
          </Text>
        </Button>
      )}

      <CommonModal
        isOpen={isOpen}
        /* onOpen={onEditModalOpen} */
        onClose={handleClose}
        heading={entity?.identifier ? 'Informações de perfil' : 'Cadastro de novo usuário'}
        closeButton={true}
        fullpage={true}
        removeRadius={true}>
        <Tabs isFitted position="relative" variant="unstyled">
          <TabList justifyContent="flex-start" pl="20px" borderBottom="#70707036 solid 1px" flexWrap={'wrap'}>
            <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
              <Text fontSize="14px">{entity?.identifier ? 'Edição de perfil' : 'Dados cadastrais'}</Text>
            </Tab>

            {entity?.identifier && (
              <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                <Text fontSize="14px">Login e acesso</Text>
              </Tab>
            )}

            <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }} isDisabled={!entity?.identifier ? true : false}>
              <Text fontSize="14px">Permissões</Text>
            </Tab>

            <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }} isDisabled={!entity?.identifier ? true : false}>
              <Text fontSize="14px">{entity?.identifier ? 'Empresas vinculadas' : 'Vincular empresa'}</Text>
            </Tab>
          </TabList>

          <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />

          <TabPanels>
            <TabPanel p="0px">
              {isLoadingEntity ? (
                <Flex justify="center" my="25px">
                  <span>Carregando...</span>
                </Flex>
              ) : (
                <>
                  <RegistrationData
                    getUser={getUser}
                    setFields={setFields}
                    fields={fields}
                    roleOptions={roles}
                    entity={entity}
                    handleClose={handleClose}
                    callhandleClose={callhandleClose}
                    isBasicRegister={isBasicRegister}
                    loadList={loadList}
                  />
                </>
              )}
            </TabPanel>

            <TabPanel p="0px">
              {isLoadingEntity ? (
                <Flex justify="center" my="25px">
                  <span>Carregando...</span>
                </Flex>
              ) : (
                <>
                  {isBasicRegister === false && (
                    <AuthData
                      getUser={getUser}
                      roleOptions={roleOptions}
                      entity={entity}
                      handleClose={handleClose}
                      loadList={loadList}
                    />
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel p="0px">
              {isLoadingEntity ? (
                <Flex justify="center" my="25px">
                  <span>Carregando...</span>
                </Flex>
              ) : (
                <>
                  {isBasicRegister === false && (
                    <PermissionData
                      entity={entity}
                      getUser={getUser}
                      handleClose={handleClose}
                      callhandleClose={callhandleClose}
                      loadList={loadList}
                    />
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel p="0px">
              {isLoadingEntity ? (
                <Flex justify="center" my="25px">
                  <span>Carregando...</span>
                </Flex>
              ) : (
                <>
                  {isBasicRegister === false && (
                    <ClientData
                      user={entity}
                      handleClose={handleClose}
                      callhandleClose={callhandleClose}
                      getUser={getUser}
                      loadList={loadList}
                    />
                  )}
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CommonModal>
    </>
  );
};

export default ModalManagementUser;
