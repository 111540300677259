const incotermOptions = [
    { value: "EXW", label: "EXW – Ex Works – Na Origem (local de entrega nomeado)" },
    { value: "FCA", label: "FCA – Free Carrier – Livre No Transportador (local de entrega nomeado)" },
    { value: "FAS", label: "FAS – Free Alongside Ship – Livre Ao Lado Do Navio (porto de embarque nomeado)" },
    { value: "FOB", label: "FOB – Free On Board – Livre A Bordo (porto de embarque nomeado)" },
    { value: "CPT", label: "CPT – Carriage Paid To – Transporte Pago Até (local de destino nomeado)" },
    { value: "CIP", label: "CIP – Carriage And Insurance Paid To – Transporte E Seguro Pagos Até (local de destino nomeado)"},
    { value: "CFR", label: "CFR – Cost And Freight – Custo E Frete (porto de destino nomeado)" },
    { value: "CIF", label: "CIF – Cost Insurance And Freight – Custo, Seguro E Frete (porto de destino nomeado)" },
    { value: "DAP", label: "DAP – Delivered At Place – Entregue No Local (local de destino nomeado)" },
    { value: "DPU", label: "DPU – Delivered At Place Unloaded – Entregue No Local Desembarcado (Local de destino nomeado)"},
    { value: "DDP", label: "DDP – Delivered Duty Paid – Entregue Com Direitos Pagos (local de destino nomeado)" },
];

export default incotermOptions;