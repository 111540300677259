import React, { useState } from 'react';

import { MdHelp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Accordion, AccordionItem, Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import ProcessListItem from '../../comex/process/components/ProcessListItem';

const PriorityProcess = ({ priorityProcess }) => {
  let [isLoading, setIsLoading] = useState(false);

  const resolution = window.innerWidth;

  return (
    <Card
      header={
        <>
          <Text textStyle="cardTitle" display={{ base: 'none', md: 'flex' }}>
            Processos marcados como favoritos
            <Tooltip label="Os filtros de data não afetam essa seção">
              <Box ml="0.325rem">
                <MdHelp size={15} color="#422C76" />
              </Box>
            </Tooltip>
          </Text>

          <Text
            textStyle="tableTitle"
            fontSize={{ base: '18px', md: '20px' }}
            color="primary"
            fontWeight="bold"
            display={{ base: 'block', md: 'none' }}>
            Processos marcados como favoritos
          </Text>

          <Link to="/comex/process">
            <Text color="primary">Ver todos os processos {'>'}</Text>
          </Link>
        </>
      }>
      <ScreenLoader isLoading={isLoading}>
        {resolution > 768 ? (
          priorityProcess.map((process, index) => <ProcessListItem key={process.identifier} itemCount={index} process={process} />)
        ) : (
          <Accordion>
            {priorityProcess.map((process, index) => (
              <AccordionItem key={process.identifier}>
                <ProcessListItem itemCount={index} process={process} />
              </AccordionItem>
            ))}
          </Accordion>
        )}
        {priorityProcess.length === 0 && (
          <Flex direction="column" align="center" my="20px">
            Nenhum processo prioritário encontrado.
            <Text textStyle="span">Marque algum processo como favorito para vê-lo aqui.</Text>
          </Flex>
        )}
      </ScreenLoader>
    </Card>
  );
};

export default PriorityProcess;
