import React, { useEffect, useRef, useState } from 'react';

import { Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsBillingAnalytics } from '../../../utils/filters/filterPresets';

import BilledTableHeader from './components/BilledTableHeader';
import ConsolidationTable from './components/ConsolidationTable';
import EstimatedTableHeader from './components/EstimatedTableHeader';
import ProgressTableHeader from './components/ProgressTableHeader';

const BillingDashboardPage = () => {
  const hasPermission = permissions.vendemmiaBillingDashboard;
  const request = useRef(0);

  const responsiveCheck = useResponsiveCheck();
  const viewport = responsiveCheck.viewport;

  const [filterOptions, setFilterOptions] = useState([]);

  const [isLoadingEstimatedBilling, setIsLoadingEstimatedBilling] = useState(true);
  const [listEstimatedBilling, setListEstimatedBilling] = useState([]);
  const [actionEstimatedBilling, setActionEstimatedBilling] = useState(1);
  const [metaEstimatedBilling, setMetaEstimatedBilling] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isLoadingProgress, setIsLoadingProgress] = useState(true);
  const [listProgress, setListProgress] = useState([]);
  const [actionProgress, setActionProgress] = useState(1);
  const [metaProgress, setMetaProgress] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isLoadingBilled, setIsLoadingBilled] = useState(true);
  const [listBilled, setListBilled] = useState([]);
  const [actionBilled, setActionBilled] = useState(1);
  const [metaBilled, setMetaBilled] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isLoadingConsolidation, setIsLoadingConsolidation] = useState(true);
  const [listConsolidation, setListConsolidation] = useState([]);

  const load = async (filters, key) => {
    loadEstimatedBilling(filters, key);
    loadProgress(filters, key);
    loadBilled(filters, key);
    loadConsolidation(filters, key);
  };

  const loadEstimatedBilling = async (filters, key) => {
    setIsLoadingEstimatedBilling(true);

    requests
      .getEstimatedBilling(filters, metaEstimatedBilling.current_page > 0 ? metaEstimatedBilling.current_page : 1)

      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setListEstimatedBilling(data);
        setMetaEstimatedBilling(data.result.information.meta);
      })
      .finally(() => {
        setIsLoadingEstimatedBilling(false);
      });
  };

  const loadProgress = async (filters, key) => {
    setIsLoadingProgress(true);

    requests
      .getInProgressBilling(filters, metaProgress.current_page > 0 ? metaProgress.current_page : 1)

      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setListProgress(data);
        setMetaProgress(data.result.information.meta);
      })
      .finally(() => {
        setIsLoadingProgress(false);
      });
  };

  const loadBilled = async (filters, key) => {
    setIsLoadingBilled(true);

    requests
      .getBilledBilling(filters, metaBilled.current_page > 0 ? metaBilled.current_page : 1)

      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setListBilled(data);
        setMetaBilled(data.result.information.meta);
      })
      .finally(() => {
        setIsLoadingBilled(false);
      });
  };

  const loadConsolidation = async (filters, key) => {
    setIsLoadingConsolidation(true);

    requests
      .getConsolidation(filters)

      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setListConsolidation(data);
      })
      .finally(() => {
        setIsLoadingConsolidation(false);
      });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsBillingAnalytics());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="billing-dashboard"
      title="Análise de Faturamento"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo ou cliente"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={load}>
      <Tabs mt={{ base: '10px', md: '0' }} overflow="hidden" variant="unstyled">
        <Card
          m="10px"
          header={
            <Flex position="relative" w="full" borderBottom="0px">
              <TabList w="full" gap="40px">
                <Tab px="0px" py="10px">
                  <Text fontSize="14px" color="primary" fontWeight="bold">
                    Pedidos
                  </Text>
                </Tab>

                <Tab px="0px" py="10px">
                  <Text fontSize="14px" color="primary" fontWeight="bold">
                    Trânsito + desembaraço
                  </Text>
                </Tab>

                <Tab px="0px" py="10px">
                  <Text fontSize="14px" color="primary" fontWeight="bold">
                    Faturados
                  </Text>
                </Tab>

                <Tab px="0px" py="10px">
                  <Text fontSize="14px" color="primary" fontWeight="bold">
                    Consolidação
                  </Text>
                </Tab>
              </TabList>

              <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="primary" borderRadius="1px" />
            </Flex>
          }>
          <TabPanels p="15px" pt="0">
            <TabPanel p="0px">
              <EstimatedTableHeader
                isLoading={isLoadingEstimatedBilling}
                list={listEstimatedBilling}
                meta={metaEstimatedBilling}
                setMeta={setMetaEstimatedBilling}
                action={actionEstimatedBilling}
                setAction={setActionEstimatedBilling}
                height={metaEstimatedBilling.total_pages > 1 ? 'calc(100vh - 410px)' : 'calc(100vh - 350px)'}
              />
            </TabPanel>

            <TabPanel p="0px">
              <ProgressTableHeader
                isLoading={isLoadingProgress}
                list={listProgress}
                meta={metaProgress}
                setMeta={setMetaProgress}
                action={actionProgress}
                setAction={setActionProgress}
                height={metaProgress.total_pages > 1 ? 'calc(100vh - 410px)' : 'calc(100vh - 350px)'}
              />
            </TabPanel>

            <TabPanel p="0px">
              <BilledTableHeader
                isLoading={isLoadingBilled}
                list={listBilled}
                meta={metaBilled}
                setMeta={setMetaBilled}
                action={actionBilled}
                setAction={setActionBilled}
                height={metaBilled.total_pages > 1 ? 'calc(100vh - 410px)' : 'calc(100vh - 350px)'}
              />
            </TabPanel>

            <TabPanel p="0px" h="calc(100vh - 280px)" overflow="auto">
              <ScreenLoader isLoading={isLoadingConsolidation}>
                <ConsolidationTable isLoading={isLoadingConsolidation} list={listConsolidation} viewport={viewport}/>
              </ScreenLoader>
            </TabPanel>
          </TabPanels>
        </Card>
      </Tabs>
    </Page>
  );
};

export default BillingDashboardPage;
