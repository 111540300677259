import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: '',
  // Estrutura da tabela
  categories: [
    {
      title: 'Cód. Produto',
      field: 'e.codProduto',
      value: 'codProduto',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Setor',
      field: 'e.setor',
      value: 'setor',
      defaultValue: 'nenhum',
    },
    {
      title: 'Estado',
      field: 'e.situacao',
      value: 'situacao',
      tooltip: 'Identifica o estado do produto',
      variantTag: 'softDashed',
    },
    {
      title: 'Disponível',
      field: 'e.qtdeEstoqueDisponivel',
      value: 'qtdeEstoqueDisponivelFormatted',
      align: 'center',
      group: [{ name: 'Estoque', color: '#85828C' }],
    },
    {
      title: 'Separação',
      field: 'e.qtdeSeparacao',
      value: 'qtdeSeparacaoFormatted',
      align: 'center',
      group: [{ color: '#85828C' }],
    },
    {
      title: 'Total',
      field: 'e.qtdeEstoque',
      value: 'qtdeEstoqueFormatted',
      align: 'center',
      group: [{ color: '#85828C' }],
    },
    {
      title: 'Avariada',
      field: 'e.qtdeAvariada',
      value: 'qtdeAvariadaFormatted',
      align: 'center',
      group: [{ color: '#85828C' }],
    },
    {
      title: 'Valor',
      field: 'e.totalValue',
      value: 'totalValueFormatted',
      align: 'center',
    },
    {
      title: 'Lote indústria',
      field: 'e.idLoteFabricacao',
      value: 'idLoteFabricacao',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Armazém',
      field: 'e.ufArmazem',
      value: 'stateCodeFormatted',
      align: 'center',
    },
    {
      title: 'Vencimento',
      field: 'e.dtVencimento',
      value: 'dtVencimentoFormatted',
      align: 'center',
    },
    {
      title: 'Curva ABC',
      field: 'e.inAbc',
      value: 'inAbc',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Depositante',
      field: 'e.name',
      value: 'clientName',
      align: 'center',
    },
  ],
  subCategories: [
    {
      title: 'Descrição',
      value: 'descProduto',
    },
  ],
  mainCategoryMobile: 'codProduto',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  collapsedContentType: 'table',
  tags: ['inAbc', 'situacao'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'A', value: 'A' },
    { color: '#F1C40F', label: 'B', value: 'B' },
    { color: '#E74C3C', label: 'C', value: 'C' },
    { color: '#2ECC71', label: 'Normal', value: '1' },
    { color: '#E74C3C', label: 'Danificado', value: '2' },
  ],
  pathSublist: '',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 14,
  maxLengthResumeChildren: 100,
  // Estilo
  resizeRows: '1fr 120px 120px 105px 105px 105px 105px 105px 135px 100px 100px 100px 200px',
};
