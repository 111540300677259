import React, { useState } from 'react';

import { Bar, CartesianGrid, Cell, ComposedChart, LabelList, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const BarChartComponent = ({ entity, handleClickStatus = () => {}, isPreview = false }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const windowWidth = window.innerWidth;
  let chartWidth = windowWidth < 768 ? 280 : 350;

  const handleCellClick = (entry, index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      handleClickStatus(null);
    } else {
      setActiveIndex(index);
      handleClickStatus(entry.status);
    }
  };

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.value.toLocaleString()}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  // Função personalizada para renderizar os rótulos
  const renderCustomLabel = ({ x, y, width, value, index }) => {
    const currentEntity = entity[index];
    return (
      <g transform={`translate(${x + width / 2}, ${y - 10})`}>
        <text textAnchor="middle" fill={currentEntity.color} fontWeight={'bold'}>
          {currentEntity.name}: {value.toLocaleString('pt-BR')}
        </text>
      </g>
    );
  };

  return (
    <ComposedChart width={chartWidth} height={250} data={entity} barSize={110} margin={{ top: 25 }}>
      <CartesianGrid vertical={false} />
      <XAxis tick={false} />
      <YAxis tickFormatter={tickFormatter} />
      {/* <Tooltip content={CustomTooltip} /> */}

      <Bar dataKey="qtt" activeBar={false}>
        {entity.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            cursor={isPreview ? 'arrow' : 'pointer'}
            fill={activeIndex === null || activeIndex === index ? entry.color : `${entry.color}40`}
            onClick={() => {
              if (!isPreview) {
                handleCellClick(entry, index);
              }
            }}
          />
        ))}
        {/* Adiciona o nome e o valor das barras diretamente no gráfico */}
        <LabelList content={renderCustomLabel} />
      </Bar>
    </ComposedChart>
  );
};

export default BarChartComponent;
