import React from 'react';

import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../../components/CommonForm/Fields/DynamicInputField';

const DynamicFormTripEdit = ({ values }) => {
  // Mapeamento dos labels
  const labels = {
    endsAt: 'Previsão de Chegada',
  };

  // Função para formatar o rótulo (label) de cada campo
  const formatLabel = (key) => labels[key] || key;

  // Mapeamento dos tipos de campo
  const fieldTypes = {
  };

  // Função para determinar o tipo do campo (input ou select)
  const getFieldType = (key) => fieldTypes[key] || 'input';

  // Função para renderizar o campo adequado, dependendo do tipo
  const renderDynamicField = (key, item = '') => {
    const type = getFieldType(key);
    const label = formatLabel(key);

    // Se for um campo do tipo 'input', chama DynamicInputField
    return (
      <React.Fragment key={key}>
        <DynamicInputField props={{ name: key, placeholder: label, value: item, mask:'datetime' }} />
      </React.Fragment>
    );
  };

  // Se `values` for nulo ou undefined, inicializa com objeto vazio
  const safeValues = values || {};
  // Cria campos para todas as chaves do labels, usando `values` quando disponível
  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="12px 25px">
      {Object.keys(labels).map((key) => {
        return renderDynamicField(key, safeValues[key]);
      })}
    </Grid>
  );
};

export default DynamicFormTripEdit;
