import { colors } from '../../../../styles/colors';

const ReportPageStyles = () => {
  return (
    <style>
      {`
          [class*="-control"] {
            border: 1px solid ${colors.linkColor};
          }

          .chakra-text [class*="-container"]{
            width: 100%;
          }

          [class*="-control"]:hover, 
          [class*="-container"]:focus-visible {
            border-color: ${colors.linkColor};
          }

          [class*="-ValueContainer"] {
            padding: 14px 12px;
            gap: 10px;
          }

          [class*="-multiValue"] {
            background: ${colors['gray-100']};
            color: ${colors['gray-300']};
            border-radius: 8px;
            border: 1px solid ${colors['gray-200']};
            overflow: hidden;
          }

          [class*="-MultiValueGeneric"] {
            font-size: 15px;
            color: ${colors['gray-300']};
            padding-left: 10px;
          }

          [class*="-MultiValueRemove"] {
            padding-right: 10px;
          }

          [class*="-MultiValueRemove"]:hover {
            background: ${colors.lightViolet};
          }

          [class*="-MultiValueRemove"]:hover svg {
            fill: ${colors['gray-300']};
          }
        `}
    </style>
  );
};

export default ReportPageStyles;
