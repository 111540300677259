import React, { useRef, useState } from 'react';

import { Bar, CartesianGrid, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';

import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Grid, GridItem, Image, Text, useDisclosure} from '@chakra-ui/react';

import CustomTooltip from '../../../../components/Tooltip/customTooltip';
import { itenSemEstoque } from '../../../../utils/exports';

const ChartStockCoverage = ({ 
  data,
}) => {

  const btnRef = useRef()
  
  const resolution = (window.innerWidth) - 150;

  const [drawerData, setDrawerData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure()
 

  const handleClickDrawer = (data, index) => {
    onOpen();
    setDrawerData(data);
  }

  return (

    <>

    <Flex align="center" justify="center">
      <ComposedChart margin={{left:25, top:25}} width={resolution} height={500} data={data}>
        <CartesianGrid vertical={false} />
        <YAxis dataKey="qtt" name="Quantidade de dias cobertos" unit=" produtos" />
        <XAxis dataKey={"days"} unit=" dias" />
        <Tooltip content={
          <CustomTooltip 
            payload={data} 
            tooltip={[
              {
                name: "Quantidade de dias cobertos",
                dataKey: "days",
              },
              {
                name: "Quantidade de produtos",
                dataKey: "qtt",
                color: '#67B7DC'
              }
            ]}
          >
          </CustomTooltip>} 
        />
        <Bar 
          ref={btnRef}
          dataKey="qtt" 
          name="Quantidade de produtos" 
          barSize={40} 
          fill="#67B7DC" 
          onClick={handleClickDrawer}
          cursor={'pointer'}
        />
      </ComposedChart>
    </Flex>

    <Drawer 
    isOpen={isOpen} 
    placement="right" 
    onClose={onClose} 
    >
    <DrawerOverlay />
    <DrawerContent
      maxW={'100% !important'} w={{ base: "initial", md:"50% !important" }} px="30px" py="30px"
    >
      <DrawerCloseButton />
      <DrawerHeader
        display={'flex'}
        gap={'12px'}
        fontSize={'30px'}
        color={'primary'}
        p={{ base: '30px 30px 44px', md: '60px 60px 88px' }}>
        <Image src={itenSemEstoque} /> Itens sem estoque
      </DrawerHeader>

      <DrawerBody>
        <Flex flexDirection={'column'}>
          <Grid
            gridTemplateColumns={'repeat(2, 1fr)'}
            border={'1px solid #7070703D'}
            p={{base: '20px 20px 12px', md: '30px 35px 22px'}}
          >
            <Text 
                fontSize={{base: '16px', md: '18px'}}
                color={'titleColor'} 
                fontWeight={'bold'}
              >
                Quantidade de dias cobertos
              </Text>
              <Text 
                fontSize={{base: '16px', md: '18px'}}
                color={'titleColor'} 
                fontWeight={'bold'}
              >
                Quantidade de produtos
              </Text>
          </Grid>
          <Grid>
            <GridItem
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              alignItems={'center'}
              p={{base: '5px 20px', md: '5px 35px'}}
              minH={'52px'}
              _even={{ bg: '#eee' }}>
              <Text 
                fontSize={{base: '14px', md: '17px'}}
                color={'textColor3'}
              >
                {drawerData && drawerData.days}
              </Text>
               <Text 
                fontSize={{base: '14px', md: '17px'}}
                color={'textColor3'}
              >
                {drawerData && drawerData.qtt}
              </Text>
            </GridItem>
          </Grid>
        </Flex>
      </DrawerBody>
    </DrawerContent>
    </Drawer>
    </>
  );
};

export default ChartStockCoverage;
