import React, { useState } from 'react';

import { AccordionButton, AccordionIcon, AccordionPanel, Flex, Tag, Text } from '@chakra-ui/react';

import CalendarEventItem from './CalendarEventItem';
import CalendarWorkloadItem from './CalendarWorkloadItem';

const CalendarColumn = ({ day, delay, isMobile = false, modality = 'event', setIsOpenModalItem, setCurrentItem }) => {
  const [showMore, setShowMore] = useState(false);

  const isToday = () => {
    const today = new Date();
    const compareDate = new Date(day.date);

    return (
      today.getDate() === compareDate.getDate() &&
      today.getMonth() === compareDate.getMonth() &&
      today.getFullYear() === compareDate.getFullYear()
    );
  };

  return (
    <>
      {!isMobile ? (
        <Flex
          display={{ base: 'none', md: 'flex' }}
          direction="column"
          w="100%"
          alignItems="center"
          minH="calc(100vh - 260px)"
          style={{ '--delay': `${delay / 20}s`, transformOrigin: 'left' }}
          className="up-anim"
          borderLeft="#70707036 solid 1px"
          borderRight="#70707036 solid 1px">
          <Text bg={isToday() ? '#2f85be' : '#6794DC'} p="15px" fontSize="14px" w="100%" color="white" textAlign="center">
            {isToday() ? `Hoje ${day.formatted}` : day.fullFormatted}
          </Text>

          <Flex direction="column" bg="white" h="100%" w="100%" gap="10px" p="15px 2px" alignItems="center">
            {modality === 'event' ? (
              <>
                {day.events?.length > 0 &&
                  day.events.map((event, key) => (key < 5 || showMore) && <CalendarEventItem entity={event} key={key} />)}
                {day.events?.length > 5 && (
                  <Text color="#6794DC" cursor="pointer" onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Ver menos' : `Ver todos (${day.events.length})`}
                  </Text>
                )}
              </>
            ) : (
              <>
                {day.events.map((data, key) => {
                  return (
                    <CalendarWorkloadItem
                      entity={data}
                      key={key}
                      setIsOpenModalItem={setIsOpenModalItem}
                      setCurrentItem={setCurrentItem}
                    />
                  );
                })}
              </>
            )}
          </Flex>
        </Flex>
      ) : (
        <>
          <h2>
            <AccordionButton>
              <Flex
                bg={isToday() ? '#2f85be' : '#6794DC'}
                p="15px"
                fontSize="14px"
                w="100%"
                color="white"
                textAlign="center"
                justifyContent="space-between"
                alignItems="center">
                <Tag>{day.events.length}</Tag>
                <p>{isToday() ? `Hoje ${day.formatted}` : day.fullFormatted}</p> <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex direction="column" bg="white" h="100%" w="100%" gap="10px" p="15px 2px" alignItems="center">
              {modality === 'event' ? (
                <>
                  {day.events?.length > 0 &&
                    day.events.map((event, key) => (key < 5 || showMore) && <CalendarEventItem entity={event} key={key} />)}
                  {day.events?.length > 5 && (
                    <Text color="#6794DC" cursor="pointer" onClick={() => setShowMore(!showMore)}>
                      {showMore ? 'Ver menos' : `Ver todos (${day.events.length})`}
                    </Text>
                  )}
                  {day.events?.length <= 0 && <Text fontSize="14px">Não há registros para esta data.</Text>}
                </>
              ) : (
                <>
                  {day.events.map((data, key) => {
                    return <CalendarWorkloadItem entity={data} key={key} />;
                  })}
                </>
              )}
            </Flex>
          </AccordionPanel>
        </>
      )}
    </>
  );
};

export default CalendarColumn;
