import React, { useEffect, useState } from 'react';

import { TabPanel, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import Tag from '../../../../../components/Generic/Tag';
import requests from '../../../../../services/requests';
import { limitChars } from '../../../../../utils/strings';

import DocumentsActions from './DocumentsActions';

const DocumentsTab = ({ user, refresh }) => {
  const [documents, setDocuments] = useState({});
  const [load, setLoad] = useState(false);

  const listDocuments = () => {
    setLoad(true);
    setDocuments(user?.files);
    setLoad(false);
  };

  const changeStatus = (identifier, transition, data = {}) => {
    setLoad(true);
    requests.changeStatusFileUser(identifier, transition, data).then(() => {
      refresh();
      setLoad(false);
    });
  };

  useEffect(() => {
    listDocuments();
  }, [user]);

  const convertStatusColor = (original) => {
    let color;
    switch (original) {
      case 'APROVADO':
        color = 'green';
        break;
      case 'REPROVADO':
        color = 'red';
        break;
      case 'PENDENTE':
        color = 'gray';
        break;
      default:
        color = 'yellow';
        break;
    }

    return color;
  };

  return (
    <TabPanel>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Documento</Th>
            <Th>Data de envio</Th>
            <Th>Status</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {!load &&
            documents?.length > 0 &&
            documents?.map(function (document, key) {
              return (
                <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                  <Td>{limitChars(document?.name)}</Td>
                  <Td>{document?.lastSendDateFormatted}</Td>

                  <Td>
                    <Tag type={convertStatusColor(document?.status)}>{document?.statusFormatted}</Tag>
                  </Td>
                  <Td>
                    <DocumentsActions
                      enableActions={document?.status == 'EM_ANALISE' || document?.status == 'INVALIDO'}
                      identifier={user?.identifier}
                      name={user?.name}
                      document={document}
                      callback={changeStatus}
                    />
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default DocumentsTab;
