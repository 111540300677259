import { commonListConfigDocumentation } from '../../../../../components/CommonList/document/commonListConfigDocumentation';

// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Documentos de expedição',
  // Estrutura da tabela
  categories: [
    {
      title: 'Nota fiscal',
      field: 'e.noteNumber',
      value: 'noteNumber',
      align: 'center',
    },
    {
      title: 'Número da carga',
      field: 'e.cargoNumber',
      value: 'cargoNumber',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Empresa',
      field: 'e.clientName',
      value: 'clientName',
    },
    {
      title: 'CNPJ',
      field: 'e.clientDocumentNumber',
      value: 'clientDocumentNumber',
      type: 'label',
    },
    {
      title: 'Arquivos',
      align: 'center',
      count: 'files',
    },
    {
      title: 'Data de upload',
      field: 'e.noteNumber',
      align: 'center',
      value: 'createdAtFormatted',
    },
    {
      title: 'Ações',
      align: 'center',
      defaultValue: '',
    },
  ],
  subCategories: [
    {
      title: 'Nome',
      value: 'name',
      link: process.env.REACT_APP_API_URL + '/open/expedition/document/${identifier}/view',
    },
    {
      title: 'Modalidade',
      value: 'modality',
    },
    {
      title: '',
      value: 'identifier',
    },
    {
      title: '',
      value: 'name',
    },
    {
      title: 'Ações',
      align: 'left',
      defaultValue: '',
    },
  ],
  mainCategoryMobile: 'noteNumber',
  pathSublist: 'files',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  collapsedContentType: 'table',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 80,
  // Estilo
  resizeRows: '120px 180px 1fr 200px 120px 160px 80px',
  resizeRowsChildren: '120px 180px 1fr',
};
