import React from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import PieChartMiddle from './PieChartMiddle';
const TypeOperation = ({ isLoading, typeOperationChartData, sizeWidth, filtersCharts, isPreview }) => {
  return (
    <Flex w="100%" h="100%" minH={{ base: 'initial', md: '320px' }} direction="column" textColor="primary" py="10px">
      <ScreenLoader isLoading={isLoading}>
        <Flex
          flexFlow={{ base: 'column', xx: 'row' }}
          alignItems="center"
          w="100%"
          h="full"
          gap={{ base: '25px', xx: '0px' }}
          justify={{ base: 'flex-start', xx: 'center' }}>
          <PieChartMiddle
            entity={typeOperationChartData}
            text="Total de operações"
            sizeWidth={{ base: sizeWidth, md: '500px' }}
            filtersCharts={filtersCharts}
            isPreview={isPreview}
          />

          <Grid
            templateColumns={{ base: '1fr 1fr', md: 'repeat(2, 1fr)', xx: '200px 200px' }}
            justifyItems="flex-start"
            gap={{ base: '10px 25px', md: '20px' }}>
            {typeOperationChartData?.length > 0 &&
              typeOperationChartData.map((item, key) => (
                <Flex gap="8px" align="center" key={key}>
                  <Box minW="28px" minH="18px" bgColor={item.color} borderRadius="3px" />

                  <Text
                    textStyle="subtitle"
                    textColor="titleColor"
                    textAlign="left"
                    fontSize={{ base: '12px', lg: '16px', xl: '18px' }}>
                    {item.name}
                  </Text>
                </Flex>
              ))}
          </Grid>
        </Flex>
      </ScreenLoader>
    </Flex>
  );
};

export default TypeOperation;
