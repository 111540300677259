import React, { useEffect } from 'react';

import { MdCheck, MdClose, MdOutlineModeEditOutline } from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  CloseButton,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  IconButton,
  Image,
  Input,
  Text,
  useEditableControls,
} from '@chakra-ui/react';

import { reportTrash } from '../../../../utils/exports';

const ReportInputParams = (props) => {
  const {
    type,
    handleRemoveRow,
    handleUpdateRow,
    handleKeepRow,
    list,
    onChangeUpdateFields,
    hiddenName,
    isLabelDisabled,
    cols,
    activateUnsavedChanges,
    unsavedChanges,
    isMobile,
  } = props;

  const maxInputLength = '150px';
  const templateColumns = `repeat(${cols}, 1fr)`;

  let timeoutField;

  const EditableControls = (props) => {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <Flex onClick={() => handleUpdateRow(props.param, props.index)}>
          <IconButton icon={<MdCheck color="#A885F9" />} {...getSubmitButtonProps()} />
        </Flex>
        <Flex onClick={() => handleKeepRow(props.param, props.index)}>
          <IconButton icon={<MdClose color="#A885F9" />} {...getCancelButtonProps()} />
        </Flex>
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <ButtonGroup justifyContent="center" size="sm">
          <IconButton size="sm" icon={<MdOutlineModeEditOutline bg="transparent" color="#A885F9" />} {...getEditButtonProps()} />
          {type !== 'default' && props.isValue && (
            <IconButton onClick={() => handleRemoveRow(type, props.index)} icon={<Image color="#A885F9" src={reportTrash} />} />
          )}
        </ButtonGroup>
      </Flex>
    );
  };

  const updateLocalStorage = (event, key, type) => {
    switch (type) {
      case 'label':
        localStorage.removeItem('report-label-' + key);
        localStorage.setItem('report-label-' + key, event.target.value);
        break;
      case 'name':
        localStorage.removeItem('report-name-' + key);
        localStorage.setItem('report-name-' + key, event.target.value);
        break;
      case 'value':
        localStorage.removeItem('report-value-' + key);
        localStorage.setItem('report-value-' + key, event.target.value);
        break;
      default:
        break;
    }

    clearTimeout(timeoutField);

    timeoutField = setTimeout(() => {
      onChangeUpdateFields();
    }, 2000);

    return () => {
      clearTimeout(timeoutField); // Limpa o temporizador ao desmontar o componente
    };
  };

  const removeEditIcon = () => {
    let fields = document.querySelectorAll('[readonly] div');

    fields.forEach((field) => {
      field.style.display = 'none';
    });
  };

  useEffect(() => {
    isLabelDisabled && removeEditIcon();
  }, [list]);

  return list.map((param, key) => (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: templateColumns }}
      gap={{ base: '5px', md: '40px' }}
      pb={{ base: '25px', md: '0' }}
      key={Math.random()}>
      {localStorage.setItem('report-label-' + key, param.label)}
      {localStorage.setItem('report-name-' + key, param.name)}
      {localStorage.setItem('report-value-' + key, param.value)}

      <Editable
        textAlign="center"
        defaultValue={param.label}
        isPreviewFocusable={false}
        selectAllOnFocus={false}
        variant="flushed"
        color={type === 'default' || isLabelDisabled ? '#00000057' : '#000'}
        readOnly={type === 'default' || isLabelDisabled}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        placeholder={`#${key + 1} - Label`}
        data-field="params"
        borderBottom={type === 'default' || isLabelDisabled ? '1px solid #00000057' : '1px solid #6C48C2'}
        isDisabled={!isLabelDisabled ? false : true}
        p={'5px 0'}
        mb={{ base: '4px', md: '0' }}
        w={'full'}>
        <EditablePreview borderColor={'#6C48C2'} />
        <EditableInput
          w={'full'}
          _focus={{
            boxShadow: 'none !important',
            borderColor: '#A885F9 !important',
            border: '0',
          }}
          onChange={(event) => {
            param.label = event.target.value;
            updateLocalStorage(event, key, 'label');
            activateUnsavedChanges && unsavedChanges(true);
          }}
        />
        {type !== 'default' && <EditableControls param={param} index={key} type={type} />}
      </Editable>
      {!hiddenName && (
        <Editable
          textAlign="center"
          defaultValue={param.name}
          isPreviewFocusable={false}
          selectAllOnFocus={false}
          variant="flushed"
          color={type === 'default' ? '#00000057' : '#000'}
          readOnly={type === 'default'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          placeholder={`#${key + 1} - Nome`}
          data-field="params"
          borderBottom={type === 'default' ? '1px solid #00000057' : '1px solid #6C48C2'}
          p={'5px 0'}
          w={'full'}>
          <EditablePreview borderColor={'#6C48C2'} />
          <EditableInput
            w={'full'}
            _focus={{
              boxShadow: 'none !important',
              borderColor: '#A885F9 !important',
              border: '0',
            }}
            onChange={(event) => {
              param.name = event.target.value;
              updateLocalStorage(event, key, 'name');
              activateUnsavedChanges && unsavedChanges(true);
            }}
          />
          {type !== 'default' && <EditableControls param={param} index={key} type={type} />}
        </Editable>
      )}
      <Flex>
        <style>
          {`
            [data-field] .chakra-editable__preview {
              max-width: ${maxInputLength};
              text-align: left;
            }

            div [readonly] > div{
              display: none;
            }
          `}
        </style>
        <Editable
          textAlign="center"
          defaultValue={param.value}
          isPreviewFocusable={false}
          selectAllOnFocus={false}
          variant="flushed"
          color={type === 'default' ? '#00000057' : '#000'}
          readOnly={type === 'default'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          placeholder={`#${key + 1} - Valor`}
          data-field="params"
          borderBottom={type === 'default' ? '1px solid #00000057' : '1px solid #6C48C2'}
          p={'5px 0'}
          w={'full'}>
          <EditablePreview />
          <EditableInput
            w={'full'}
            _focus={{
              boxShadow: 'none !important',
              borderColor: '#A885F9 !important',
              border: '0',
            }}
            onChange={(event) => {
              param.value = event.target.value;
              updateLocalStorage(event, key, 'value');
              activateUnsavedChanges && unsavedChanges(true);
            }}
          />
          {type !== 'default' && <EditableControls param={param} index={key} type={type} isValue={true} />}
        </Editable>
      </Flex>
    </Grid>
  ));
};

export default ReportInputParams;
