import React, { useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, FormLabel, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';

import api from '../../../../services/api';

const NewPwd = ({ setStep, setIsLoading }) => {
  const yup = require('yup');

  const [showPwd, setShowPwd] = useState(false);
  const [ShowConfirmPwd, setShowConfirmPwd] = useState(false);

  const handleClickShowPwd = () => setShowPwd(!showPwd);
  const handleClickShowConfirmPwd = () => setShowConfirmPwd(!ShowConfirmPwd);

  return (
    <Formik
      initialValues={{
        code: '',
        newPwd: '',
        confirmPwd: '',
      }}
      validationSchema={yup.object().shape({
        code: yup.string().required('Campo de código obrigatório'),
        newPwd: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres').required('Campo de nova senha obrigatório'),
        confirmPwd: yup
          .string()
          .required('Campo de confirmação de senha obrigatório')
          .oneOf([yup.ref('newPwd'), null], 'As senhas não são iguais!'), // yup.ref para comparação de campo
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setIsLoading(true);

        api
          .post('recover/send', {
            code: values.code,
            password: values.newPwd,
          })
          .then(() => {
            toast.success('Senha alterada com sucesso!');
            localStorage.setItem('profile-pwd', values.newPwd);

            setStep(3);
            setIsLoading(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setSubmitting(false);
          });
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="30px" className="down-anim">
            <Flex direction="column" gap="10px">
              <Text textStyle="subtitle" textColor="linkColor">
                Recuperar a senha
              </Text>

              <Text textStyle="subtitle" textColor="#FFFFFF">
                Se o seu e-mail estiver cadastrado, você irá receber um código para alterar a senha.{' '}
              </Text>
            </Flex>

            <Flex direction="column" gap="30px">
              <Flex direction="column">
                <FormLabel className="up-anim" color="white" m="0">
                  Código
                </FormLabel>

                <Input
                  color="#FFFFFF"
                  h="40px"
                  variant="flushed"
                  name="code"
                  autocomplete="code"
                  _placeholder={{ color: '#FFFFFF' }}
                  _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                  value={values.code}
                  onChange={handleChange}
                />

                <ErrorMessage component="p" className="error-message-error" name="code" />
              </Flex>

              <Flex direction="column">
                <FormLabel className="up-anim" color="white" m="0">
                  Nova senha
                </FormLabel>

                <InputGroup>
                  <Input
                    pr="4.5rem"
                    color="#FFFFFF"
                    variant="flushed"
                    type={showPwd ? 'text' : 'password'}
                    name="newPwd"
                    _placeholder={{ color: '#FFFFFF' }}
                    _focus={{ borderColor: '#FFFFFF', boxShadow: 'none' }}
                    value={values.newPwd}
                    onChange={handleChange}
                  />
                  <InputRightElement color="#FF2F69" cursor="pointer" _hover={{ opacity: '0.8' }} onClick={handleClickShowPwd}>
                    {showPwd ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                  </InputRightElement>
                </InputGroup>

                <ErrorMessage component="p" className="error-message-error" name="newPwd" />
              </Flex>

              <Flex direction="column">
                <FormLabel className="up-anim" color="white" m="0">
                  Confirmar senha
                </FormLabel>

                <InputGroup>
                  <Input
                    color="#FFFFFF"
                    h="40px"
                    variant="flushed"
                    name="confirmPwd"
                    type={ShowConfirmPwd ? 'text' : 'password'}
                    autocomplete="new-password"
                    _placeholder={{ color: '#FFFFFF' }}
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    value={values.confirmPwd}
                    onChange={handleChange}
                  />
                  <InputRightElement color="#FF2F69" cursor="pointer" _hover={{ opacity: '0.8' }} onClick={handleClickShowConfirmPwd}>
                    {ShowConfirmPwd ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                  </InputRightElement>
                </InputGroup>

                <ErrorMessage component="p" className="error-message-error" name="confirmPwd" />
              </Flex>
            </Flex>
          </Flex>

          <Flex
            w="full"
            mt={{ base: '20px', md: '30px' }}
            gap={{ base: '0px', md: '15px' }}
            justify={{ base: 'space-between', md: 'end' }}>
            <Button
              variant="outline"
              height="40px"
              borderRadius="5px"
              borderColor="#FF2F69"
              textColor="#FF2F69"
              transition=".3s"
              _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
              isDisabled={isSubmitting}
              onClick={() => {
                setStep(1);
              }}>
              <Text>Voltar</Text>
            </Button>

            <Button
              type="submit"
              variant="solid"
              height="40px"
              borderRadius="5px"
              bgColor="#FF2F69"
              textColor="#FFFFFF"
              transition=".3s"
              _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
              isLoading={isSubmitting}>
              <Text>Alterar</Text>
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default NewPwd;
