const yup = require('yup');

export const validationSchema = () => {
  return yup.object().shape({
    code: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    startsAtFormatted: yup.string().required('Campo obrigatório'),
    statusReadable: yup.string().required('Campo obrigatório'),
  });
};
