import React from 'react';

import {
  MdOutlineBookmarkAdded,
  MdOutlineDescription,
  MdOutlineDirectionsBoat,
  MdOutlineHotelClass,
  MdOutlineLocalShipping,
  MdOutlinePinDrop,
  MdOutlineRequestPage,
  MdOutlineSpeakerNotes,
} from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Divider, Flex, Text, Tooltip } from '@chakra-ui/react';

import { limitChars } from '../../../utils/strings';

import UpdateInfoTag from './UpdateInfoTag';

const UpdateItem = ({ type, entity, isLastItem }) => {
  const getIcon = () => {
    if (entity.stageSequential) {
      switch (entity.stageSequential) {
        case 1:
          return <MdOutlineBookmarkAdded size={20} color="white" />;
        case 2:
          return <MdOutlineDirectionsBoat size={20} color="white" />;
        case 3:
          return <MdOutlineDescription size={20} color="white" />;
        case 4:
          return <MdOutlineRequestPage size={20} color="white" />;
        case 5:
          return <MdOutlineLocalShipping size={20} color="white" />;
        case 6:
          return <MdOutlinePinDrop fontWeight="600" size={20} color="white" />;
        default:
          return <MdOutlineBookmarkAdded size={20} color="white" />;
      }
    }

    switch (type) {
      case 'favourite':
        return <MdOutlineHotelClass size={30} color="white" />;
      case 'comment':
        return <MdOutlineSpeakerNotes size={30} color="white" />;
      case 'update':
        return <MdOutlineBookmarkAdded size={30} color="white" />;
      default:
        return <MdOutlineBookmarkAdded size={30} color="white" />;
    }
  };

  const getColor = () => {
    return entity.originDescription ? '#E52359' : 'primary';
  };

  const getUserName = (name) => {
    return limitChars(name, 16);
  };

  return (
    <Flex
      p="10px 0"
      // borderBottom="1px solid #0F0A1D26"
      align="center"
      alignItems="flex-start"
      justify="space-between"
      direction="column">
      <Flex direction="row" w="full" gap="5px" align="center" justify="space-between">
        <Flex w="400px" gap="5px" align="center">
          <Link to={`/comex/process/detail/${entity.processIdentifier}`}>
            <Box p="5px" h="fit-content" bg={getColor()} borderRadius="100%">
              {getIcon()}
            </Box>
          </Link>

          <Link to={`/comex/process/detail/${entity.processIdentifier}`} style={{ width: '100%' }}>
            <UpdateInfoTag getColor={getColor()}>{entity.processCode}</UpdateInfoTag>
          </Link>

          {entity.stageDescription && <UpdateInfoTag getColor={getColor()}>{entity.stageDescription}</UpdateInfoTag>}

          {entity.userName && (
            <UpdateInfoTag width="150%" getColor={getColor()}>
              <Tooltip label={entity.userName}>{getUserName(entity.userName)}</Tooltip>
            </UpdateInfoTag>
          )}
        </Flex>

        <Flex>
          <Text w="full" color="#0F0A1D77" fontSize="10px" fontWeight="700">
            {entity.createdAt}
          </Text>
        </Flex>
      </Flex>

      <Flex direction="column">
        <Text
          color="#0F0A1D77"
          fontSize="14px"
          mt="10px"
          fontWeight="600"
          dangerouslySetInnerHTML={{
            __html: entity.description,
          }}
        />
      </Flex>

      {!isLastItem && <Divider mt="5px" />}
    </Flex>
  );
};

export default UpdateItem;
