import React from 'react';

import { MdOutlineArrowDownward, MdOutlineArrowUpward } from 'react-icons/md';

import { Flex, Text, Tooltip } from '@chakra-ui/react';

const TotalVendemmia = ({ data }) => {
  return (
    <Flex direction="column" m="25px" w="auto" gap="10px">
      <Text textStyle="primaryTitle" textColor="primary" fontWeight="bold" fontSize={{ base: '26px', md: '32px'}}>
        R$ {data?.summary?.total1}
      </Text>

      <Flex align="center" gap="10px">
        <Text textStyle="subtitle" textColor="primary" opacity="0.9">
          relação ao mês anterior
        </Text>

        <Tooltip label={<>
          Mês anterior: R$ {data?.summary?.totalPreviousMonth1}<br />
          Mês atual: R$ {data?.summary?.totalCurrentMonth1}
          </>}>
          {data?.summary?.percentMonth1 > 0 ? (
            <Flex align="center">
              <MdOutlineArrowUpward size={15} color="#2ECC71" />
              <Text textStyle="span" textColor="#2ECC71">
                {data?.summary?.percentMonth1}%
              </Text>
            </Flex>
          ) : (
            <Flex align="center">
              <MdOutlineArrowDownward size={15} color="#E74C3C" />
              <Text textStyle="span" textColor="#E74C3C">
                {data?.summary?.percentMonth1}%
              </Text>
            </Flex>
          )}
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default TotalVendemmia;
