import React, { useEffect, useState } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const HelpAnalyticsPanel = () => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setLoad(true);

    try {
      const res = await requests.listFaqs();

      const filteredData = res.data.filter((entity) => entity?.typeSystem === 'SYSTEM_ANALYTICS');

      setList(filteredData);
    } catch (error) {
      setLoad(false);
    }

    setLoad(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <Flex direction="column" mb="30px" p="30px">
      <Accordion allowToggle>
        <Flex gap="10px" direction="column" mb="30px">
          {list?.map((entity, index) => (
            <AccordionItem borderRadius="15px" border="1px #EAE1FF" key={index}>
              <AccordionButton
                h="78px"
                px={{ base: '10px', md: '60px' }}
                bgColor="#EAE1FF"
                borderRadius="15px"
                justifyContent="space-between"
                _hover={{ bgColor: '3EAE1FF' }}>
                <Text textColor="#6C48C2AD" textAlign="left" textStyle="tableTitle">
                  {entity?.title}
                </Text>
                <AccordionIcon color="#6C48C2AD" />
              </AccordionButton>

              <AccordionPanel bgColor="blackAlpha.50" p="0">
                <Text
                  textStyle="subtitle"
                  textColor="#6C48C2AD"
                  fontSize={{ base: '16px', md: '18px' }}
                  px={{ base: '10px', md: '60px' }}
                  py="25px">
                  {entity?.description}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Flex>
      </Accordion>

      {load ? (
        <Flex justify="center">
          <Text textStyle="tableTitle" color="#6C48C2AD">
            Carregando...
          </Text>
        </Flex>
      ) : (
        <>
          {list.length === 0 && (
            <Text textStyle="tableTitle" color="#6C48C2AD">
              Nenhuma informação disponível no momento.
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default HelpAnalyticsPanel;
