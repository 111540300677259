import React, { useEffect, useState } from 'react';

import { Box, Flex, TabPanel } from '@chakra-ui/react';

import requests from '../../../../../services/requests';
import TravelTimelineStep from '../Timeline/TimelineStep';

const TrackingTab = ({ entity, setCenter, setZoom }) => {
  const [steps, setSteps] = useState([]);
  const processed = [];

  const getSteps = () => {
    requests.listTripRoute(entity?.identifier).then((response) => {
      setSteps(response.routes);
    });
  };

  useEffect(() => {
    if (entity?.identifier !== null && entity?.identifier !== undefined) {
      getSteps();
    }
  }, [entity]);

  return (
    <TabPanel>
      {entity?.status == 'TRAVELLING' && (
        <Flex align="center">
          {entity?.additionalInformation?.travelledDistance > 0 && (
            <Box
              width="133px"
              height="67px"
              backgroundColor="#3498DB"
              color="white"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              textAlign="center">
              <span color="#FFFFFF91" style={{ fontSize: '12px', fontWeight: 'normal' }}>
                Distância
              </span>
              <span style={{ fontSize: '22px', fontWeight: 'normal' }}>{entity?.additionalInformation?.travelledDistance} km</span>
            </Box>
          )}

          {entity?.additionalInformation?.speedAverage > 0 && (
            <Box
              width="133px"
              height="67px"
              ml="4"
              backgroundColor="green"
              color="white"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              textAlign="center">
              <span color="#FFFFFF91" style={{ fontSize: '12px', fontWeight: 'normal' }}>
                Tempo médio
              </span>
              <span style={{ fontSize: '22px', fontWeight: 'normal' }}>{entity?.additionalInformation?.speedAverage} horas</span>
            </Box>
          )}
        </Flex>
      )}

      <Flex w="100%" direction="row" flexWrap={'wrap'}>
        {steps.map((step, i) => {
          let latitude = null;
          let longitude = null;

          if ((step?.latitude !== null && step?.latitude !== undefined) && (step?.longitude !== null && step?.longitude !== undefined)) {
            let latitudeOffset = 0;
            let longitudeOffset = 0;
            const hash = `${step.latitude}-${step.longitude}`;
        
            if (!processed.includes(hash)) {
              processed.push(hash);
            } else {
              const baseOffset = 0.00003; // Distância base para o deslocamento
              const angle = 45; // Ângulo fixo para o deslocamento
              
              // Calcula o deslocamento baseado no ângulo fixo
              latitudeOffset = baseOffset * Math.cos(angle * (Math.PI / 180));
              longitudeOffset = baseOffset * Math.sin(angle * (Math.PI / 180));
            }

            latitude = step.latitude + latitudeOffset;
            longitude = step.longitude + longitudeOffset;
          }

          return <TravelTimelineStep
            key={i}
            infos={step}
            latitude={latitude}
            longitude={longitude}
            setCenter={setCenter}
            setZoom={setZoom}
          />;
        })}
      </Flex>
    </TabPanel>
  );
};

export default TrackingTab;
