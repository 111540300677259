import React, { useState } from 'react';

import axios from 'axios';
import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';

const AUTH_HEADER = {
  Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
};

const ModalDeleteFile = ({ entity, onSave }) => {
  const [load, setLoad] = useState(false);

  const handleDelete = async () => {
    setLoad(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/process/file/${entity.process.identifier}/${entity.identifier}/remove`,
        {
          headers: AUTH_HEADER,
        }
      );
      setLoad(false);
      onSave(response.data);
      toast.success('Arquivo excluído com sucesso!');
    } catch (error) {
      setLoad(false);
      toast.error('Erro ao excluído arquivo, tente novamente!');
    }
  };

  return (
    <>
      <DefaultModal
        ctaButton={<MdDeleteOutline size={20} color="#E74C3C" />}
        title={'Certeza que deseja excluir ?'}
        loading={load}
        subtitle={
          <>
            Deseja mesmo excluir esse documento?
            <br />
            Ele sumirá automaticamente da lista de documentos.
          </>
        }
        buttonColor="red"
        txtButton="Confirmar"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            await handleDelete();
          }
        }}
      />
    </>
  );
};

export default ModalDeleteFile;
