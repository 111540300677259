const roleOptions = [
  {
    slug: 'admin',
    label: 'Administrador',
    value: 'ROLE_SYSTEM_ADMIN',
  },
  {
    slug: 'operator',
    label: 'Operacional',
    value: 'ROLE_OPERATOR',
  },
  {
    slug: 'client',
    label: 'Cliente',
    value: 'ROLE_CLIENT',
  },
  {
    slug: 'driver',
    label: 'Motorista',
    value: 'ROLE_DRIVER',
  },
  {
    slug: 'comercial',
    label: 'Comercial',
    value: 'ROLE_COMERCIAL',
  },
  {
    slug: 'tax',
    label: 'Tributário',
    value: 'ROLE_TAX_INSPECTOR',
  },
  {
    slug: 'financial',
    label: 'Financeiro',
    value: 'ROLE_FINANCIAL_INSPECTOR',
  },
  {
    slug: 'director',
    label: 'Diretoria',
    value: 'ROLE_DIRECTOR',
  },
  {
    slug: 'trip-manager',
    label: 'Gestor de viagens',
    value: 'ROLE_TRIP_MANAGER',
  },
  {
    slug: 'logistic',
    label: 'Logística',
    value: 'ROLE_LOGISTIC',
  },
];

export default roleOptions;
