import React from 'react';

import { Text } from '@chakra-ui/react';

const ProcessInfoTag = ({ children, ...props }) => {
  return (
    <Text p="1px 12px" border="solid 1px" borderRadius="7px" color="#6C48C2" fontSize={{ base: '12px', md: '14px' }} {...props}>
      {children}
    </Text>
  );
};

export default ProcessInfoTag;
