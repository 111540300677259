import React from 'react';

import { Box, Flex, Progress, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

const InconsistencyMetricsChart = ({ data, loadingCharts }) => {
  const inconsistencies = Object.entries(data);
  const totalValue = inconsistencies.reduce((sum, [, value]) => sum + value, 0);

  return (
    <Flex h="full" w="full" p="10px" mb="10px" direction="column" justify="space-between" overflow="auto">
      <ScreenLoader isLoading={loadingCharts}>
        {inconsistencies.map(([label, value], index) => (
          <Box mb="5px" key={index}>
            <Flex justify="space-between" align="center" mb="5px" textColor="primary">
              <Text fontSize="sm" mr="10px">
                {label}
              </Text>

              <Text fontSize="sm">{value}</Text>
            </Flex>
            <Progress
              size="sm"
              value={value}
              max={totalValue}
              borderRadius="3px"
              bg="#EDE7F6" // cor do fundo da barra de progresso
              sx={{
                '& > div': {
                  backgroundColor: '#422C76', // cor da barra preenchida
                },
              }}
            />{' '}
          </Box>
        ))}
      </ScreenLoader>
    </Flex>
  );
};

export default InconsistencyMetricsChart;
