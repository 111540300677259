import React from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const formatMonthYear = (date) => {
  if (date && typeof date === 'string') {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      return format(parsedDate, 'dd/MMM', { locale: ptBR });
    }
  }
  return '';
};

const tickFormatter = (tickItem) => {
  return tickItem.toLocaleString('pt-BR');
};

const WarehousePeriodChart = ({ entity }) => {
  const formattedEntity = entity.map((item) => ({
    ...item,
    date: formatMonthYear(item.date),
  }));

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload.map((entity, key) => (
            <Flex direction="column" align="center" gap="10px" key={key}>
              <Text>{entity.payload.date}</Text>
              <Text>{entity.payload.value.toLocaleString('pt-BR')}</Text>
            </Flex>
          ))}
        </Flex>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={370}>
      <LineChart
        data={formattedEntity}
        margin={{
          right: 50,
          left: 50,
          bottom: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={CustomTooltip} />
        <XAxis dataKey="date" tickMargin={5} />
        <YAxis dataKey="value" tickFormatter={tickFormatter} tickMargin={5} />
        <Legend verticalAlign="top" />
        <Line type="monotone" dataKey="value" name="Recebidos" stroke="#422C76" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default WarehousePeriodChart;
