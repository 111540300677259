const yup = require('yup');

const freightPaymentValidation = () => {
  let validation = yup.object().shape({
    merchandiseValue: yup.string().required('Campo obrigatório'),
    // merchandiseDamagedValue: yup.string().required('Campo obrigatório'),
    freightValue: yup.string().required('Campo obrigatório'),
    // cubage: yup.string().required('Campo obrigatório'),
    // weight: yup.string().required('Campo obrigatório'),
  });

  return validation;
};

export default freightPaymentValidation;
