import { MdOutlineReceiptLong, MdOutlineViewKanban, MdOutlineViewTimeline } from 'react-icons/md';

import { PanelTabs } from '../../../../components/PanelTabs';

const TravelManagementPanelTabs = ({ tab, setTab }) => {
  return (
    <PanelTabs.Root>
      <PanelTabs.Link
        tab={tab}
        tabActive={'progress'}
        title={'Viagens em lista'}
        text={'Lista de viagens'}
        role={''}
        icon={<MdOutlineViewTimeline size={20} />}
        action={() => setTab('progress')}
        link={'/logistic/trip'}
      />
      <PanelTabs.Link
        tab={tab}
        tabActive={'kanban'}
        title={'Viagens em kanban'}
        text={'Quadro de viagens'}
        role={'ROLE_CLIENT'}
        icon={<MdOutlineViewKanban size={20} />}
        action={() => setTab('kanban')}
        link={'/logistic/trip'}
      />
      <PanelTabs.Link
        tab={tab}
        tabActive={'cte'}
        title={"CT-e's"}
        text={"CT-e's"}
        role={''}
        icon={<MdOutlineReceiptLong size={20} />}
        action={() => setTab('cte')}
        link={'/logistic/trip'}
      />
    </PanelTabs.Root>
  );
};

export default TravelManagementPanelTabs;
