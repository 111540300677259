import React from 'react';

import { useForm } from 'react-hook-form';
import { MdAdd, MdEditNote } from 'react-icons/md';

import {
  Box,
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

const ModalFormTerm = ({
  load,
  txtButton,
  txtHeader,
  textButtonConfirm,
  onSubmit,
  description,
  handleContentChange,
  isEdit,
  enabled = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Submit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <>
      {isEdit ? (
        <Tooltip label="Editar">
          <Box
            onClick={() => (enabled ? onOpen() : null)}
            filter={enabled ? 'saturate(1)' : 'saturate(0)'}
            opacity={enabled ? '1' : '0.5'}
            cursor={enabled ? 'pointer' : 'unset'}>
            <MdEditNote size={25} color="#422C76" />
          </Box>
        </Tooltip>
      ) : (
        <Button variant="primary" mr="20px" pt="23px" pb="23px" onClick={onOpen} leftIcon={<MdAdd size={20} />}>
          <Text>{txtButton}</Text>
        </Button>
      )}

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent 
          borderRadius="9px" 
          maxH="700px" 
          h={'full'} 
          maxW="768px" 
          w={'full'} 
          p="40px" 
        >
          <ModalHeader mb="20px" p="0">
            <Text textStyle="primaryTitle" textColor="#0F0A1D" textAlign="center">
              {txtHeader}
            </Text>
          </ModalHeader>

          <Flex as="form" h="full" direction="column" onSubmit={handleSubmit(Submit)}>
            <ModalBody p="0" position="relative" overflowY="auto">
              <FormControl p="3px" h="full">
                <Textarea
                  h="full"
                  resize="none"
                  value={description}
                  bgColor="#F2F2F2"
                  borderColor="#7070703B"
                  placeholder="Descreva a aqui...."
                  name="description"
                  {...register('description', { required: true })}
                  onChange={handleContentChange}
                />
                {errors.description && <Text textStyle="error">Campo obrigatório.</Text>}
              </FormControl>
            </ModalBody>

            <ModalFooter alignItems="center" justifyContent="center">
              <Button variant="secundary" borderRadius="7px" m="15px" p="25px 35px" onClick={onClose}>
                Cancelar
              </Button>

              <Button
                variant="primary"
                bgColor="green"
                borderRadius="7px"
                m="15px"
                p="25px 35px"
                _hover={{ opacity: 0.8 }}
                _active={{ background: 'green' }}
                _focus={{ background: 'green' }}
                type="submit"
                isLoading={load}
                loadingText="Salvando">
                {textButtonConfirm}
              </Button>
            </ModalFooter>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalFormTerm;
