import React from 'react';

import { MdHelp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Text, Tooltip } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import CalendarSection from '../../comex/calendar/components/CalendarSection';

const DashboardWeeklySchedule = ({ isLoading, calendarData }) => {
  return (
    <Card
      header={
        <>
          <Text textStyle="cardTitle" display={{ base: 'none', md: 'flex' }}>
            Agenda da semana
            <Tooltip label="Os filtros de data não afetam essa seção">
              <Box ml="0.325rem">
                <MdHelp size={15} color="#422C76" />
              </Box>
            </Tooltip>
          </Text>

          <Text textStyle="cardTitle" fontSize={{ base: '18px', md: '20px' }} display={{ base: 'flex', md: 'none' }}>
            Agenda da semana
          </Text>

          <Link to="/comex/calendar">
            <Text color="primary" fontSize={{ base: '14px', md: '16px' }}>
              Ver agenda completa {'>'}
            </Text>
          </Link>
        </>
      }>
      <ScreenLoader isLoading={isLoading} minH="500px">
        {calendarData && <CalendarSection calendarData={calendarData ?? {}} />}
      </ScreenLoader>
    </Card>
  );
};

export default DashboardWeeklySchedule;
