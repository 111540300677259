import { colors } from "../../../../styles/colors";

const CashFlowStyles = () => {
  return (
    <style>
      {`
        .recharts-cartesian-grid-vertical line,
        .recharts-tooltip-cursor {
          display: none;
        }

        .recharts-cartesian-grid-horizontal line,
        .recharts-layer recharts-cartesian-axis line,
        .recharts-cartesian-axis line{
          stroke: #EBEBEB;
        }

        .recharts-cartesian-axis-tick text {
          fill: ${colors.titleColor};
          max-width: 105px;
        }

        text[text-anchor="end"],
        text[text-anchor="start"] {
          fill: ${colors['gray-300']};
          font-size: 14px;
        }

        text[text-anchor="middle"] {
          font-weight: 700;
        }

        [role="list"] [data-hover="true"] {
          background: ${colors.white}
        }

        [data-leftLineSwitch="true"] {
          border-left: 1px solid #70707036;
          align-items: center;
          width: 100%;
          height: 100%;
        }

        [data-leftLineSwitch="false"] {
          border-left: 1px solid #70707036;
          align-items: center;
        }

        [data-leftLine="true"] {
          border-left: 1px solid #70707036;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
    `}
    </style>
  );
};

export default CashFlowStyles;