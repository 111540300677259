import React, { useRef, useState } from 'react';

import moment from 'moment';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import Tag from '../../../../components/Generic/Tag';
import Page from '../../../../components/Page';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { limitChars } from '../../../../utils/strings';
import ModalNewPolicy from '../privacy/components/ModalNewPolicy';

import ModalCancelTerm from './components/ModalCancelTerm';
import ModalEditTerm from './components/ModalEditTerm';
import ModalPublishTerm from './components/ModalPublishTerm';
import ModalViewTerm from './components/ModalViewTerm';

const TermUsePage = () => {
  const hasPermission = permissions.vendemmiaManagementPolicy;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const request = useRef(0);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    requests.listTermUse(filters, metadata.current_page > 0 ? metadata.current_page : 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const status = (value) => {
    switch (value) {
      case 'PUBLISHED':
        return 'green';
      case 'DEPRECATED':
        return 'gray';
      case 'CANCELLED':
        return 'red';
      default:
        return 'yellow';
    }
  };

  const textStatus = (value) => {
    switch (value) {
      case 'PUBLISHED':
        return 'Publicado';
      case 'DEPRECATED':
        return 'Obsoleto';
      case 'CANCELLED':
        return 'Cancelado';
      default:
        return 'Rascunho';
    }
  };

  return (
    <Page
      screen="policy-terms"
      title="Termos de uso"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      showFilters={false}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
      allowEmptySearchString={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar termo de uso',
          text: 'Cadastro de Termos de uso',
          action: <ModalNewPolicy addPolicyToList={triggerRefresh} />,
          modality: 'custom',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de termos de uso
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} termos de uso
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <Table className="table-default min">
            <Thead border="1px" borderBottom="1px">
              <Tr>
                <Th>Conteúdo</Th>
                <Th>Data de criação</Th>
                <Th>Ultima atualização</Th>
                <Th>Status</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody border="1px">
              {list?.map((entity) => (
                <React.Fragment key={entity?.identifier}>
                  <Tr>
                    <Td w="60%">{limitChars(entity?.description, 100)}</Td>
                    <Td>{moment(entity?.createdAt).format('DD/MM/YYYY')}</Td>
                    <Td>{moment(entity?.updatedAt).format('DD/MM/YYYY')}</Td>
                    <Td>
                      <Tag type={status(entity?.status)}>{textStatus(entity?.status)}</Tag>
                    </Td>

                    <Td>
                      <Flex gap="10px" align="center" h="30px">
                        <ModalEditTerm entity={entity} onSave={triggerRefresh} />
                        <ModalPublishTerm entity={entity} onSave={triggerRefresh} />
                        <ModalCancelTerm entity={entity} onSave={triggerRefresh} />

                        {(entity?.status === 'PUBLISHED' || entity?.status === 'DEPRECATED') && <ModalViewTerm entity={entity} />}
                      </Flex>
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Card>
    </Page>
  );
};

export default TermUsePage;
