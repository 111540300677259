import React, { useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import PieChartComponent from '../../../components/Charts/PieChartComponent';

const ProductStatusChart = ({ data, setSituation, isPreview }) => {
  const [entity, setEntity] = useState([]);
  const [total, setTotal] = useState(0);

  const handleClickStatus = (status) => {
    setSituation(status);
  };

  const formatValue = (value) => (isNaN(value) ? 0 : value.toLocaleString('pt-BR'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { qttAvailable = 0, qttBlocked = 0, total } = data;

        const chartData = [
          {
            name: 'Liberado',
            qtt: isNaN(qttAvailable) ? 0 : qttAvailable,
            color: '#2ECC71',
            status: '1',
          },
          {
            name: 'Bloqueado',
            qtt: isNaN(qttBlocked) ? 0 : qttBlocked,
            color: '#E74C3C',
            status: '0',
          },
        ];

        setTotal(total);
        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  return (
    <Box>
      <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="10px" flexDirection={{ base: 'column', md: 'row' }}>
        <PieChartComponent
          entity={entity}
          total={total}
          handleClickStatus={handleClickStatus}
          isPreview={isPreview}
          hasPTBRNumberFormat={false}
        />

        <Flex direction="column" gap="20px" flexDirection={{ base: 'row', md: 'column' }}>
          {entity.map((entry, index) => (
            <Flex key={`item-${index}`} direction="column" align="center">
              <Flex gap="10px" align="center">
                <Box w="17px" h="17px" bgColor={entry.color} borderRadius="3px" />
                <Text fontSize={'14px'}>{entry.name}</Text>
              </Flex>

              <Flex align="center" gap="9px">
                <Text textStyle="tableTitle" textColor="#0F0A1D" fontWeight="bold">
                  {entry?.qtt && entry.qtt > 0 ? entry.qtt.toFixed(2) : 0}
                </Text>

                <Text textStyle="paragraph" textColor="#0F0A1D" opacity="0.8">
                  %
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex direction="column" justify="center" h="full" p="15px">
        <Text>Volume de estoque na unidade de medida: {formatValue(data.qttDisponible)}</Text>
      </Flex>
    </Box>
  );
};

export default ProductStatusChart;
