import React, { useEffect, useState } from 'react';

import { MdClose, MdSearch } from 'react-icons/md';

import { Input, InputGroup, InputLeftElement, InputRightElement, Switch, Tooltip } from '@chakra-ui/react';

import { useDebounce } from '../../../hooks/useDebounce';
import { addFilter, getFilter } from '../../../utils/storageFilter';

const Filter = ({ screen, placeholder, onChange, allowFiltersOverride, setShouldIgnoreFilters, isInsideExtraFilter }) => {
  const [searchString, setSearchString] = useState(getFilter(screen, 'search') ?? '');
  const [isCheck, setIsCheck] = useState(getFilter(screen, 'shouldIgnoreFilters') ?? false);

  useEffect(() => {
    debouncedSearch();
    debouncedIgnoreFilters();
  }, [searchString, isCheck]);

  const handleCheck = () => {
    addFilter(screen, 'shouldIgnoreFilters', isCheck);
    setShouldIgnoreFilters?.(isCheck);
  };

  const handleSearch = () => {
    let valueSearch = searchString.trim();

    addFilter(screen, 'search', valueSearch);
    onChange?.(valueSearch);
  };

  const debouncedSearch = useDebounce(handleSearch, 800); // 800ms de delay
  const debouncedIgnoreFilters = useDebounce(handleCheck, 800);

  const clear = () => {
    setSearchString('');
  };

  return (
    <InputGroup w={isInsideExtraFilter ? 'full' : '300px'}>
      <Tooltip label="Insira no mínimo 3 caracteres">
        <Input
          px="10px"
          h="35px"
          pr="40px"
          pl={allowFiltersOverride ? '50px' : ''}
          bgColor="#E8EAED"
          borderRadius="10px"
          borderColor="#70707026"
          value={searchString}
          placeholder={placeholder}
          onChange={(event) => setSearchString(event.target.value)}
        />
      </Tooltip>

      <style>
        {`
          [data-checked] .chakra-switch__track {
            background-color: #422C76;
          }
        `}
      </style>

      {allowFiltersOverride && (
        <Tooltip label="Quando este campo estiver ativado, somente o filtro por texto será usado, ignorando todos os demais.">
          <InputLeftElement ml="5px">
            <Switch bottom="2px" Switch size="md" isChecked={isCheck} onChange={() => setIsCheck(!isCheck)} />
          </InputLeftElement>
        </Tooltip>
      )}

      {searchString.length === 0 && (
        <InputRightElement h="auto" my="auto" top="10px" alignItems="center" pointerEvents="none">
          <MdSearch color="#000000" size={15} />
        </InputRightElement>
      )}

      {searchString.length > 0 && (
        <InputRightElement h="auto" my="auto" top="10px" alignItems="center">
          <MdClose color="#000000" size={15} cursor="pointer" onClick={() => clear()} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default Filter;
