import { Flex, Text } from '@chakra-ui/react';

// Função para renderizar valores de um array de objetos
export const renderObjectArrayValues = (array) =>
  array.map((obj, subIndex) => (
    <Flex key={subIndex} flexDirection="column" alignItems="start" gap="5px">
      {Object.entries(obj).map(([key, val]) => (
        <Text key={key} fontSize={{ base: '0.75rem', md: '1.1rem' }}>
          {`${key}: ${val}`}
        </Text>
      ))}
    </Flex>
  ));
