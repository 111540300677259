import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

const CardMetric = ({ title, subtitle, value }) => {
  return (
    <Box 
      w={{ base: 'full', md: '200px' }} 
      border="1px" 
      borderColor="#6C48C266" 
      py="5px" 
      px="5px"
      display="flex"
      justifyContent="center"
    >
      <Flex 
        gap="10px" 
        direction="column" 
        justify="center" 
        align="center"
        textAlign="center"
      >
        <Text textStyle="paragraph" textColor="primary">
          {title}
          {subtitle && <small>{" "}{subtitle}</small>}
        </Text>
        <Text textStyle="subtitle" textColor="primary" fontWeight="bold">
          {value}
        </Text>
      </Flex>
    </Box>
  );
};

export default CardMetric;
