const NcmDetailPageStyles = ({ children, ...props }) => {
  return (
    <style>
      {`
        [data-form-card]{
          padding: 25px; 
          flex-direction: column;
          box-shadow: 0px 3px 6px #48484826;
          border: 1px solid #70707036;
          border-radius: 10px;
        }

        [type="date"],
        [type="submit"],
        [data-form-card] textarea,
        [data-form-card] div{
          width: 100%;
        }

        body [class*="-control"],
        body [class*="-ValueContainer"],
        body [class*="-IndicatorsContainer"] {
          width: initial;
        }

        [data-form-wrapper] {
          flex-direction: column;
          padding: 0.875rem 0.75rem;
          gap: 25px;
        }

        [data-form-card] div {
          gap: 1rem 8rem;
        }

        body [class*="-control"],
        body [class*="-ValueContainer"],
        body [class*="-IndicatorsContainer"],
        .chakra-stack div div {
          gap: 0;
        }

        .chakra-stack div div {
          gap: 1rem;
        }

        @media (min-width: 768px) {
          [data-form-wrapper] {
            padding: 1.875rem 3.75rem 1.875rem 3.75rem;
          }

          [type="submit"],
          [data-form-card] div{
            width: initial;
          }

          [data-form-card] textarea {
            width: 66.25rem;
          }
        }
      `}
    </style>
  );
};

export default NcmDetailPageStyles;
