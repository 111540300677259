import React from 'react';

import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

const ProgressBarWithLabel = ({ label, value = 1, max = 10, color = '#6576FF' }) => {
  return (
    <Tooltip label={`${value} de ${max}, ${Math.floor((value / max) * 100)}%`}>
      <Flex direction="column" width="100%">
        <Flex justify="space-between" mb="7px">
          <Text className="up-anim" color="primary" opacity=".78">
            {label}
          </Text>
          <Text className="up-anim">{value}</Text>
        </Flex>
        <Box bg="#EFEFEF" borderRadius="8px" h="10px">
          <Box
            className="progress-anim"
            transformOrigin="left"
            bg={color}
            transition=".5s"
            w={`${(value / max) * 100}%`}
            h="100%"
            borderRadius="8px"
          />
        </Box>
      </Flex>
    </Tooltip>
  );
};

export default ProgressBarWithLabel;
