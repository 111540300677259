export const typographyStyles = {
  primaryTitle: {
    fontSize: '40px',
    fontFamily: 'Hanken Grotesk',
  },
  secondaryTitle: {
    fontSize: '30px',
    fontFamily: 'Hanken Grotesk',
  },
  dashboardTitle: {
    fontSize: '30px',
    fontFamily: 'Hanken Grotesk',
    color: 'titleColor',
    fontweight: '600',
  },
  tableTitle: {
    fontSize: '20px',
    fontFamily: 'Hanken Grotesk',
  },
  cardTitle: {
    fontSize: '20px',
    color: 'primary',
    fontWeight: 'bold',
    fontFamily: 'Hanken Grotesk',
  },
  subtitle: {
    fontSize: '16px',
    fontFamily: 'Hanken Grotesk',
  },
  paragraph: {
    fontSize: '14px',
    fontFamily: 'Hanken Grotesk',
  },
  span: {
    fontSize: '12px',
    fontFamily: 'Hanken Grotesk',
  },
  error: {
    fontSize: '12px',
    fontFamily: 'Hanken Grotesk',
    textColor: 'red',
  },
};
