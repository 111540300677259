import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';

/**
 * Renderiza os campos de contato em um layout de grid para cada contato de um fornecedor.
 *
 * @param {Array<Object>} contacts - Lista de objetos de contato. Cada contato deve ter pelo menos `name` e `email`.
 * @param {number} supplierIndex - Índice do fornecedor dentro da lista de fornecedores.
 * @param {Object} additionalProps - Propriedades adicionais a serem repassadas para o componente `DynamicInputField`.
 *
 * @returns {JSX.Element[]} Uma lista de elementos JSX renderizados, com dois campos por linha (Nome e E-mail).
 */
export const renderContactFields = (contacts, supplierIndex, additionalProps) =>
  contacts.map((_, contactIndex) => {
    const basePath = `suppliers[${supplierIndex}].contacts[${contactIndex}]`;

    return (
      <Grid key={contactIndex} gridTemplateColumns="repeat(2, 1fr)" gap={6}>
        <DynamicInputField
          props={{
            name: `${basePath}.name`,
            placeholder: 'Nome',
            ...additionalProps,
          }}
        />
        <DynamicInputField
          props={{
            name: `${basePath}.email`,
            placeholder: 'E-mail',
            ...additionalProps,
          }}
        />
      </Grid>
    );
  });
