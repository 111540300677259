import React from 'react';

import '../styles/transportScheduleDay.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

import TransportEventContent from './TransportEventContent';

const TransportScheduleDay = ({ calendarRef, events, getStatusColor, isSystemAdmin, loadEventDetails }) => {
  const formattedEventsDay = events.map((event) => {
    const stagePriority = ['Concluído', 'Chegou', 'Agendado'];

    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    const end = priorityStage
      ? new Date(new Date(priorityStage.happenedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : event.expectedAt
      ? new Date(new Date(event.expectedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : null;

    return {
      identifier: event.identifier,
      companyName: event.companyDepositor?.companyName ?? '-',
      code: event?.code ?? '-',
      dockNumber: event.dockNumber ?? '-',
      vehiclePlates: event.vehiclePlates ?? '-',
      stageModality: event.currentStageModality?.slug ?? '-',
      stageModalityFormatted: event.currentStageModality?.name ?? '-',
      modality: event.modality ?? '-',
      start,
      end,
    };
  });

  return (
    <FullCalendar
      ref={calendarRef}
      height="100%"
      initialView="timeGridDay"
      plugins={[dayGridPlugin, timeGridPlugin]}
      locale={ptBrLocale}
      events={formattedEventsDay}
      allDaySlot={false}
      nowIndicator
      eventContent={(eventInfo) => (
        <TransportEventContent
          eventInfo={eventInfo.event.extendedProps}
          getStatusColor={getStatusColor}
          isSystemAdmin={isSystemAdmin}
          loadEventDetails={loadEventDetails}
        />
      )}
      dayHeaderFormat={{
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }}
      headerToolbar={{
        start: '',
        center: '',
        end: '',
      }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }}
    />
  );
};

export default TransportScheduleDay;
