import React from 'react';

import { ErrorMessage } from 'formik';
import Select from 'react-select';

import { Checkbox, Flex, FormControl, Grid, Radio, TabPanel, Text } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';

const TributariesDataForm = ({ values, setFieldValue, handleChange, isPreviwer, isDraft, hasEntityExpired }) => {
  const operationPurposeData = [
    {
      value: 'ACTIVE_CONSUMPTION',
      label: 'Ativo/Consumo',
    },
    {
      value: 'INDUSTRIALIZATION_RESALE',
      label: 'Industrialização/Revenda',
    },
  ];

  const fiscalBenefitData = [
    {
      value: 'TTD',
      label: 'TTD',
    },
    {
      value: 'TTD_CDE',
      label: 'TTD - CDE',
    },
    {
      value: 'FOUNDATION',
      label: 'Fundap',
    },
    {
      value: 'PRODEPE',
      label: 'Prodepe',
    },
    {
      value: 'IMPORT_CORRIDOR',
      label: 'Corredor de Importação (MG)',
    },
    {
      value: 'NÃO_SE_APLICA',
      label: 'N/A',
    },
  ];

  const taxRegimeData = [
    {
      label: 'Lucro Presumido',
      value: 'LUCRO_PRESUMIDO',
    },
    {
      label: 'Lucro Real',
      value: 'LUCRO_REAL',
    },
    {
      label: 'Simples Nacional',
      value: 'SIMPLES_NACIONAL',
    },
    {
      label: 'Difal',
      value: 'DIFAL',
    },
  ];

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
    }),
  });

  return (
    <TabPanel p="30px">
      <Grid templateColumns="repeat(3, 1fr)" gap={10} mb="20px">
        <Flex direction="column" gap="10px">
          <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
            <LabelDefault
              text={
                <Flex gap="4px" alignItems="center">
                  <Text>Contribuinte do ICMS</Text>
                  {isDraft && <Text fontSize="small"> (opcional)</Text>}
                </Flex>
              }
            />
            <Flex direction="column">
              <Radio
                color="primary !important"
                w="min-content"
                name="tributary.isIcmsTaxpayer"
                value={true}
                isDisabled={isPreviwer || hasEntityExpired}
                onChange={handleChange}
                isChecked={values.tributary.isIcmsTaxpayer === 'true' || values.tributary.isIcmsTaxpayer === true ? true : false}>
                Sim
              </Radio>

              <Radio
                color="primary !important"
                w="min-content"
                name="tributary.isIcmsTaxpayer"
                value={false}
                isDisabled={isPreviwer || hasEntityExpired}
                onChange={handleChange}
                isChecked={values.tributary.isIcmsTaxpayer === 'false' || values.tributary.isIcmsTaxpayer === false ? true : false}>
                Não
              </Radio>
            </Flex>

            <ErrorMessage name="tributary.isIcmsTaxpayer" component="p" className="error-message-error" />
          </FormControl>

          <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
            <LabelDefault
              text={
                <Flex gap="4px" alignItems="center">
                  <Text>Finalidade da Operação (Cliente)</Text>
                  {isDraft && <Text fontSize="small"> (opcional)</Text>}
                </Flex>
              }
            />
            <Flex direction="column">
              {operationPurposeData.map((item, index) => (
                <React.Fragment key={index}>
                  <Checkbox
                    iconColor="primary"
                    w="fit-content"
                    name="tributary.operationPurpose"
                    isDisabled={isPreviwer || hasEntityExpired}
                    value={item.label}
                    onChange={handleChange}
                    isChecked={values.tributary.operationPurpose.includes(item.label)}>
                    {item.label}
                  </Checkbox>
                </React.Fragment>
              ))}
            </Flex>

            <ErrorMessage name="tributary.operationPurpose" component="p" className="error-message-error" />
          </FormControl>
        </Flex>

        <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px" h="min-content">
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Benefício Fiscal</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />
          <Flex direction="column">
            {fiscalBenefitData.map((item, index) => (
              <React.Fragment key={index}>
                <Checkbox
                  iconColor="primary"
                  w="fit-content"
                  name="tributary.fiscalBenefit"
                  isDisabled={isPreviwer || hasEntityExpired}
                  onChange={handleChange}
                  value={item.label}
                  isChecked={values.tributary.fiscalBenefit.includes(item.label)}>
                  {item.label}
                </Checkbox>
              </React.Fragment>
            ))}
          </Flex>

          <ErrorMessage name="tributary.fiscalBenefit" component="p" className="error-message-error" />
        </FormControl>

        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Regime Tributário (Cliente)</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />

          <Select
            options={taxRegimeData}
            placeholder="Selecione o regime tributário"
            name="tributary.taxRegime"
            styles={selectCustomStyles()}
            isDisabled={isPreviwer || hasEntityExpired}
            isMulti
            value={values.tributary.taxRegime}
            onChange={(option) => {
              setFieldValue('tributary.taxRegime', option);
            }}
          />

          <ErrorMessage name="tributary.taxRegime" component="p" className="error-message-error" />
        </FormControl>
      </Grid>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Comentários adicionais</Text>
              <Text fontSize="small"> (opcional)</Text>
            </Flex>
          }
        />
        <InputDefault
          as="textarea"
          minH="120px"
          pt="10px"
          name="tributary.additionalInformation"
          styles={selectCustomStyles()}
          isDisabled={isPreviwer || hasEntityExpired}
          value={values.tributary.additionalInformation}
          onChange={handleChange}
        />
      </FormControl>
    </TabPanel>
  );
};

export default TributariesDataForm;
