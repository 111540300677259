import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, Flex, Grid, Text } from '@chakra-ui/react';

import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

import AreaLimitable from './components/AreaLimitable';
import CardMetric from './components/CardMetric';
import HistoryBlock from './components/HistoryBlock';
import MessageBlock from './components/MessageBlock';

const AiAssistantPage = () => {
  let navigate = useNavigate();

  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);

  const [list, setList] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [messageText, setMessageText] = useState('');
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const chatSendManager = async () => {
    setIsLoadingChat(true);
    await requests
      .sendAIQuestion({
        question: messageText,
      })
      .then((data) => {
        const newMessage = {
          question: messageText,
          response: replaceNewlinesWithBreaks(data.response),
        };
        let aux = [...chatList];
        aux.push(newMessage);
        setChatList(aux);
        setMessageText('');
      })
      .finally(() => {
        setIsLoadingChat(false);
      });
  };

  const load = async () => {
    loadList();
    loadMetrics();
  };

  const loadList = async () => {
    setIsLoadingList(true);

    await requests
      .listAIMessages(metadata.current_page + 1)
      .then((data) => {
        let aux = [...list];
        Object.values(data.data).map((item, index) => {
          aux.push({
            question: item.question,
            response: replaceNewlinesWithBreaks(item?.response ?? item?.answer),
            identifier: item?.identifier ?? index + 1,
          });
        });

        setList(aux);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoadingList(false);
      });
  };

  const loadMetrics = async () => {
    setIsLoadingMetrics(true);

    await requests
      .getAIMetrics()
      .then((data) => {
        setMetrics(data);
      })
      .finally(() => {
        setIsLoadingMetrics(false);
      });
  };

  const handleTextareaChange = (text) => {
    setMessageText(text);
  };

  const replaceNewlinesWithBreaks = (str) => {
    return str.replace(/\n/g, '<br />');
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      const element = messagesContainerRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [chatList, isLoadingChat]);

  // useEffect(() => {
  //   load();
  // }, [chatList]);

  useEffect(() => {
    !permissions.vendemmiaMIA && navigate('/');
  }, []);

  return (
    <Page
      screen="ai-assistant"
      title="Fale com a Mia"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      // textFilterPlaceholder="Processo, empresa ou ref"
      // setIsMobile={setIsMobile}
      // hasPermission={hasPermission}
      // list={list}
      // metadata={metadata}
      load={load}
      showRefreshData={false}
      hideAllFilters={true}
      allowEmptySearchPeriod={true}
      // isContentLoading={isLoading}
      // filterOptions={filterOptions}
      // allowFiltersOverride={true}
      // isRefreshLoading={isLoading}
    >
      <Card m="10px" p="20px 30px" minH="calc(50h - 274px)">
        <Flex mb="20px" align="center" justify="space-between" flexDirection={{ base: 'column', md: 'row' }}>
          <Flex gap="2px" direction="column">
            <Text textStyle="tableTitle" fontWeight="bold" color="#0F0A1D">
              Faça a sua pergunta para a Mia.
            </Text>

            <Text textStyle="paragraph" color="#0F0A1D99">
              Alguma dúvida? Faça uma pegunta clara e direta, e nossa I.A. irá te responder.
            </Text>
          </Flex>

          <Grid
            gap="14px"
            flexDirection={{ base: 'column', md: 'row' }}
            m={{ base: '25px 0 5px', md: '0' }}
            gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(4, 1fr)' }}>
            <CardMetric title="Total de perguntas" value={metrics?.total} />
            <CardMetric title="Perguntas feitas" subtitle="(período)" value={metrics?.usedThisPeriod} />
            <CardMetric title="Perguntas restantes" subtitle="(período)" value={metrics?.availableThisPeriod} />
            <CardMetric title="Renovação em" value={metrics?.renewsAt} />
          </Grid>
        </Flex>

        <Flex
          direction="column"
          border="1px solid #CEC2EA"
          gap="40px"
          p={{ base: 'initial', md: '30px' }}
          h="fit-content"
          overflowY="auto"
          scrollBehavior="smooth"
          ref={messagesContainerRef}
          minH="200px"
          maxH={{ base: 'initial', md: '70vh' }}>
          {chatList.length === 0 && !isLoadingChat ? (
            <Flex justify="center" my="25px" fontSize={{ base: '14px', md: '16px' }}>
              Faça sua pergunta no campo abaixo.
            </Flex>
          ) : (
            chatList.map((item, index) => {
              return <MessageBlock key={index} question={item.question} response={item.response} />;
            })
          )}
          {isLoadingChat && <MessageBlock question={messageText ?? 'Carregando'} response="Digitando..." />}
          <div ref={messagesEndRef} />
        </Flex>
        <Flex direction="column" alignItems="flex-end">
          <AreaLimitable value={messageText} limit={100} onChange={handleTextareaChange} />
          <Flex justifyContent="space-between" alignItems="flex-start" direction={{ base: 'column', md: 'row' }} w="100%">
            <Text textStyle="paragraph" color="#0F0A1D" w="fit-content" mt="5px">
              * As perguntas são tratadas individualmente, e o histórico não afeta as perguntas futuras
            </Text>
            <Button
              variant="primary"
              mt="10px"
              isLoading={isLoadingChat}
              loadingText="Respondendo"
              onClick={chatSendManager}
              w={{ base: '100%', md: 'initial' }}>
              Fazer pergunta
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card m="10px" p="20px 30px" minH="calc(100vh - 274px)">
        <Flex gap="2px" mb="20px" pb="20px" direction="column" borderBottom="1px solid #7070702E">
          <Text textStyle="tableTitle" fontWeight="bold" color="#0F0A1D">
            Histórico
          </Text>
          <Text textStyle="paragraph" color="#0F0A1D99">
            Perguntas que você já fez, para consulta futura.
          </Text>
        </Flex>
        <Flex flexFlow="wrap" gap="40px">
          {list.length === 0 && !isLoadingList ? (
            <Flex justify="center" my="25px">
              Aqui vamos deixar o histórico de perguntas que você já fez. Faça uma pergunta e ela irá aparecer aqui.
            </Flex>
          ) : (
            list.map((item) => {
              return <HistoryBlock key={item.identifier} question={item.question} response={item.response} />;
            })
          )}
        </Flex>
        {list.length > 0 && list.length < metadata.total_count && (
          <Button onClick={loadList} mt="20px" isLoading={isLoadingList} loadingText="Carregando">
            Carregar mais mensagens
          </Button>
        )}
      </Card>
    </Page>
  );
};

export default AiAssistantPage;
