import { MdOpenInNew } from 'react-icons/md';

import { Button, ButtonGroup, Text, Tooltip } from '@chakra-ui/react';

import CustomButtons from '../../../components/CommonList/components/CustomButtons';
import DeleteConfirmationModal from '../../../components/CommonList/components/DeleteConfirmationModal';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import { redirectToDetailsOnClick } from '../Helpers/redirectToDetailsOnClick';

const CustomButtonsRenderer = ({
  hasDetailsButton,
  hasDeleteButton,
  customButtons,
  rawItem,
  identifier,
  deleteButtonAction,
  deleteButtonSubtitle,
  detailsRedirectEndpoint,
  content,
}) => {
  const { isMobile } = useResponsiveCheck();

  const handleDetailsClick = (e) => {
    e.stopPropagation();
    redirectToDetailsOnClick(detailsRedirectEndpoint, identifier);
  };

  if (!hasDetailsButton && !hasDeleteButton && !customButtons) return null;

  const renderCustomButtons = () =>
    customButtons && (
      <CustomButtons buttons={customButtons} buttonProps={rawItem} identifier={identifier} subCategoriesProps={rawItem} />
    );

  const renderDetailsButton = () =>
    hasDetailsButton && (
      <Tooltip label="Ver detalhes">
        <Button variant="commonTable" onClick={handleDetailsClick}>
          <MdOpenInNew size={20} />
        </Button>
      </Tooltip>
    );

  const renderDeleteButton = () =>
    hasDeleteButton && (
      <Tooltip label="Excluir">
        <DeleteConfirmationModal
          deleteButtonAction={deleteButtonAction}
          identifier={identifier}
          deleteButtonSubtitle={deleteButtonSubtitle}
        />
      </Tooltip>
    );

  return isMobile ? (
    <ButtonGroup display="flex" pt="10px" mt="15px" borderTop="1px solid #70707036" gap="10px" data-content-button>
      {renderCustomButtons()}
      {renderDetailsButton()}
      {renderDeleteButton()}
    </ButtonGroup>
  ) : (
    <Text display="flex" data-content-button>
      {renderCustomButtons()}
      {renderDetailsButton()}
      {renderDeleteButton()}
      {content}
    </Text>
  );
};

export default CustomButtonsRenderer;
