// Função para converter hexadecimal para RGBA
export const hexToRgba = (hex, opacity) => {
  // Remove o símbolo # se estiver presente
  hex = hex.replace('#', '');

  // Converte a cor hexadecimal para RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Retorna a cor no formato rgba
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
