import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import {
  Button,
  Flex,
  FormControl,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react';

const DefaultCommentModal = ({
  action,
  title,
  subtitle,
  tooltip = "",
  load,
  txtButton,
  textButtonConfirm,
  onSubmit,
  enabled = true,
  isDisabledButton = false,
  icon,
  isOpen,
  onOpen,
  onClose,
  background = 'green',
  hoverColor = '#70D499',
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [description, setDescription] = useState('');

  const Submit = async () => {
    await onSubmit(action, description, onClose);
  };

  useEffect(() => {
    if (isOpen == true) {
      setDescription('');
    }
  }, [isOpen]);

  return (
    <>
      <Tooltip label={tooltip}>
          <IconButton
              bgColor="#D0B0FD36"
              icon={icon}
              onClick={() => (enabled ? onOpen() : null)} 
          />
      </Tooltip>
      {/* <Button
        leftIcon={icon}
        _hover={{ bgColor: hoverColor }}
        bg={background}
        shadow="xl"
        p="1.3rem"
        pb="1.3rem"
        pl="1rem"
        pr="1rem"
        borderRadius="10px"
        isDisabled={isDisabledButton}
        onClick={() => (enabled ? onOpen() : null)}>
        <Text mr="10px" color="white">
          {txtButton}
        </Text>
      </Button> */}

      <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />

        <ModalContent borderRadius="9px" maxWidth={'680px'} margin={'25px'} overflowY="auto">
          <ModalHeader p="24px 24px 0 24px">
            <Flex align="center" justify="flex-end">
              <IconButton bgColor="#ffffff" icon={<MdClose size={20} color="#00000057" />} onClick={onClose} />
            </Flex>
          </ModalHeader>

          <Flex as="form" direction="column" onSubmit={handleSubmit(Submit)}>
            <ModalBody p="35px" position="relative" justifyContent="center" display="flex" flexDirection="column" gap="20px">
              <Text textStyle="primaryTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" className="up-anim">
                {title}
              </Text>

              {subtitle && (
                <Text
                  font="normal normal medium 20px/26px Hanken Grotesk"
                  textColor="#0F0A1D80"
                  textAlign="center"
                  className="up-anim">
                  {subtitle}
                </Text>
              )}

              <FormControl p="3px" h="400px">
                <Textarea
                  h="full"
                  resize="none"
                  value={description}
                  bgColor="#F2F2F2"
                  borderColor="#7070703B"
                  placeholder="Descreva o motivo aqui...."
                  name="description"
                  {...register('description', { required: true })}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                />
                {errors.description && <Text textStyle="error">Campo obrigatório.</Text>}
              </FormControl>
            </ModalBody>

            <ModalFooter alignItems="center" justifyContent="center">
              <Button isDisabled={load} variant="secundary" borderRadius="7px" m="15px" p="25px 35px" onClick={onClose}>
                Cancelar
              </Button>

              <Button
                variant="primary"
                borderRadius="7px"
                m="15px"
                p="25px 35px"
                _hover={{ opacity: 0.8 }}
                type="submit"
                isLoading={load}
                loadingText="Salvando...">
                {textButtonConfirm}
              </Button>
            </ModalFooter>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DefaultCommentModal;
