import requests from './requests';

const isClient = typeof window === 'object';
const priorities = isClient ? window.localStorage.getItem('profile-process-priority') : null;
const permissions = isClient ? window.localStorage.getItem('profile-permissions') : null;

const updateProfile = async () => {
  requests.getProfile().then((profile) => {
    localStorage.setItem('profile-role', profile.role);
    localStorage.setItem('profile-identifier', profile.identifier);
    localStorage.setItem('profile-name', profile.name);
    localStorage.setItem('profile-email', profile.email);
    localStorage.setItem('profile-cellphone', profile.cellphone);
    localStorage.setItem('profile-phone', profile.phone);
    localStorage.setItem('profile-role-human-readable', profile.roleHumanReadable);
    localStorage.setItem('profile-picture', profile.picture);
    localStorage.setItem('profile-permissions', JSON.stringify(profile.permissions));
  });
  return;
};

const profile = {
  identifier: isClient ? window.localStorage.getItem('profile-identifier') : null,
  name: isClient ? window.localStorage.getItem('profile-name') : null,
  email: isClient ? window.localStorage.getItem('profile-email') : null,
  cellphone: isClient ? window.localStorage.getItem('profile-cellphone') : null,
  phone: isClient ? window.localStorage.getItem('profile-phone') : null,
  role: isClient ? window.localStorage.getItem('profile-role') : null,
  roleHumanReadable: isClient ? window.localStorage.getItem('profile-role-human-readable') : null,
  picture: isClient ? window.localStorage.getItem('profile-picture')?.replace('//profile', '/profile') : null,
  priorities:
    priorities !== null && typeof priorities !== 'undefined' && priorities !== 'undefined'
      ? JSON.parse(window.localStorage.getItem('profile-process-priority'))
      : [],
  permissions:
    permissions !== null && typeof permissions !== 'undefined' && permissions !== 'undefined'
      ? JSON.parse(window.localStorage.getItem('profile-permissions'))
      : [],
  update: updateProfile,
};

export default profile;
