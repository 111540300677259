export const userInitialValues = (entity = undefined, roleOptions = [], filter) => {
  return ({
    name: entity?.name || "",
    email: entity?.email || "",
    phone: entity?.phone || "",
    cellphone: entity?.cellphone || "",
    role: roleOptions.find(e => e.role === entity?.role) || filter?.role,
    password: "",
  });
}
