import React, { useCallback, useEffect, useState } from 'react';

import { MdDownload } from 'react-icons/md';

import { Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import ModalUpload from '../../../../components/Modal/Upload/ModalUpload';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

import ModalDeleteFile from './ModalDeleteFile';
import ModalDownloadFile from './ModalDownloadFile';
import ModalViewFile from './ModalViewFile';

const DocumentList = ({ process, downloadAllFiles }) => {
  const [data, setData] = useState([]);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [stageOptions, setStageOptions] = useState([]);
  const [modalityOptions, setModalityOptions] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  const getData = () => {
    if (!process || !process?.identifier) {
      return;
    }

    requests
      .getFilesListByProcess(process.identifier)
      .then((data) => {
        setData(data);
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  const downloadFile = useCallback((identifier, filename) => {
    setIsDownloadingFile(filename);

    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/${identifier}/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsDownloadingFile('');
  });

  useEffect(() => {
    getData();
  }, [process]);

  const loadStages = () => {
    requests.getStagesList().then((response) => {
      let data = [];
      response.data?.map((option) => {
        data.push({
          value: option.identifier,
          label: option.description,
        });
      });

      setStageOptions(data);
    });
  };

  async function loadModalities(value, config) {
    if (value === '') {
      setModalityOptions([]);
      return;
    }

    return new Promise((resolve, reject) => {
      requests
        .getProcessFileModalities(value, config)
        .then((response) => {
          let data = [];
          response.data?.map((option) => {
            data.push({
              value: option.identifier,
              label: option.description,
            });
          });
          setModalityOptions(data);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .saveDocsUploads(process.identifier, docs, config)
        .then(() => {
          setStageOptions([]);
          setModalityOptions([]);
          getData();
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  useEffect(() => {
    loadStages();
  }, [openModalUploadFile]);

  return (
    <>
      <ModalUpload
        title="Importar novo arquivo"
        subtitle="Upload de documentos do processo"
        accept=".xml, .pdf, .png, .jpeg"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={openModalUploadFile}
        onOpen={onOpen}
        onClose={actionModalUploadFile}
        multiple={true}
        requestAction={sendUpload}
        nameForm="process_file_upload"
        additional={[
          {
            label: 'Etapa',
            group: 'stage',
            name: 'stage',
            required: true,
            additionalOnChange: loadModalities,
            options: stageOptions,
          },
          {
            label: 'Tipo',
            group: 'modality',
            name: 'modality',
            required: true,
            options: modalityOptions,
          },
        ]}
      />
      <Flex gap="15px" align="center" justify="flex-end" mb="16px">
        {process.qttFiles > 0 && <ModalDownloadFile title="Baixar todos" downloadAction={downloadAllFiles} />}

        <Button variant="primary" onClick={actionModalUploadFile}>
          <Text fontSize="14px" textColor="#FFFFFF">
            Anexar novo
          </Text>
        </Button>
      </Flex>

      <ScreenLoader isLoading={isLoadingData}>
        <Table className="table-default">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              <Th>Data</Th>
              <Th>Enviado por</Th>
              <Th>Etapa</Th>
              <Th>Tipo</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>

          <Tbody border="1px">
            {data?.length > 0 &&
              data.map((entity, key) => (
                <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                  <Td>{entity?.createdAtFormatted ?? '-'}</Td>
                  <Td>
                    {entity?.user?.identifier ? (
                      entity?.user?.status !== 'ANONIMIZADO' ? (
                        entity.user.name
                      ) : (
                        <small>(usuário removido)</small>
                      )
                    ) : (
                      'A.I. Mia'
                    )}
                  </Td>
                  <Td>{entity?.stage?.description ?? '-'}</Td>
                  <Td>
                    {entity?.modality?.description
                      ? entity?.modality?.description + (entity?.uploadName ? ` (${entity.uploadName.split('.').pop()})` : '')
                      : '-'}
                  </Td>
                  <Td>
                    <Flex gap="10px">
                      <button onClick={() => downloadFile(entity.identifier, entity.uploadName)} title="Baixar arquivo">
                        <MdDownload size={20} />
                      </button>
                      <ModalViewFile entity={entity} />
                      <ModalDeleteFile entity={entity} onSave={getData} />
                    </Flex>
                  </Td>
                </Tr>
              ))}

            {isLoadingData ? (
              <Tr>
                <Td colSpan={5}>
                  <Flex justify="center" my="25px">
                    Carregando...
                  </Flex>
                </Td>
              </Tr>
            ) : (
              <>
                {data.length === 0 && (
                  <Tr>
                    <Td colSpan={5}>
                      <Flex justify="center" my="25px">
                        Nenhum dado encontrado para os parâmetros filtrados.
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
        </Table>
      </ScreenLoader>
    </>
  );
};

export default DocumentList;
