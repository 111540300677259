import React from 'react';

import { Accordion, AccordionItem, Flex } from '@chakra-ui/react';

import CalendarColumn from './CalendarColumn';

const CalendarSection = ({ calendarData, modality, isMobile, setIsOpenModalItem, setCurrentItem }) => {
  return (
    <>
      {!isMobile ? (
        <Flex w="100%" p="10px" overflow="auto">
          {calendarData?.days &&
            calendarData.days.map((day, dayKey) => {
              return (
                <CalendarColumn
                  isMobile={isMobile}
                  modality={modality}
                  day={day}
                  key={dayKey + calendarData.formatted}
                  delay={dayKey}
                  setIsOpenModalItem={setIsOpenModalItem}
                  setCurrentItem={setCurrentItem}
                />
              );
            })}
        </Flex>
      ) : (
        <Accordion display={{ base: 'flex', md: 'none' }} flexDirection="column" w="100%" allowToggle>
          {calendarData?.days &&
            calendarData.days.map((day, dayKey) => {
              return (
                <AccordionItem key={dayKey + calendarData.formatted}>
                  <CalendarColumn day={day} delay={dayKey} />
                </AccordionItem>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

export default CalendarSection;
