import React, { useEffect, useRef, useState } from 'react';

import { MdKeyboardArrowRight } from 'react-icons/md';

import { Flex, Select, Text } from '@chakra-ui/react';

import CalendarMonthSection from '../../../components/Calendar/CalendarMonthSection';
import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';

import CalendarController from './components/CalendarController';
import CalendarModalItem from './components/CalendarModalItem';
import CalendarSection from './components/CalendarSection';

const CalendarPage = () => {
  const hasPermission = permissions.comexAgenda;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [forceSetSearchPeriod, setForceSetSearchPeriod] = useState();
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [calendarModality, setCalendarModality] = useState('week');
  const [dataModality, setDataModality] = useState('event');

  const [period, setPeriod] = useState({
    current: '',
    previous: '',
    next: '',
    formatted: '',
    startOfMonth: '',
  });

  const [isOpenModalItem, setIsOpenModalItem] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .calendar(calendarModality ?? 'week', dataModality ?? 'event', filters)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setMetadata(data.meta);

        setPeriod({
          current: data.meta.start,
          formatted: data.meta.formatted,
          previous: data.meta.previous,
          next: data.meta.next,
          startOfMonth: data.meta.startOfMonth,
        });

        if (calendarModality === 'month') {
          // unificar todas as partes da lista em um unico array com os dias do mês
          setList(
            data.weeks.reduce((acc, item) => {
              return acc.concat(item.days);
            }, [])
          );
        } else {
          setList(data.weeks[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNext = () => {
    setForceSetSearchPeriod({
      startsAt: period.next,
    });
  };

  const handlePrevious = () => {
    setForceSetSearchPeriod({
      startsAt: period.previous,
    });
  };

  const handleCloseModalItem = () => {
    setCurrentItem(null);
    setIsOpenModalItem(!isOpenModalItem);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComex());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  }, [calendarModality, dataModality]);

  const calendarTypeButtons = [
    {
      type: 'week',
      title: 'Semana',
    },
    {
      type: 'month',
      title: 'Mês',
    },
  ];

  const filterOptionsSelect = [
    { name: 'Detalhada', value: 'event' },
    { name: 'Agrupada', value: 'workload' },
  ];

  return (
    <Page
      screen="comex-calendar"
      title="Agenda"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      load={load}
      metadata={metadata}
      filterOptions={filterOptions}
      forceLoadTrigger={forceLoadTrigger}
      forceSetSearchPeriod={forceSetSearchPeriod}
      allowFiltersOverride={true}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}>
      <Card
        m="10px"
        p={'0'}
        header={
          <Flex gap={'30px'} w={'full'} flexDirection={{ base: 'column', md: 'row' }}>
            <Flex direction={'column'}>
              <Text as={'span'} fontSize={'12px'} lineHeight={'13px'} fontWeight={'500'} color={'textColor3'}>
                Visualização de dados
              </Text>

              <Select
                value={dataModality}
                color="#A885F9"
                borderColor="#A885F9"
                minW={{ base: '100%', md: '200px' }}
                icon={<MdKeyboardArrowRight size="30" color="#A885F9" />}
                variant="flushed"
                fontSize={'18px'}
                cursor={'pointer'}
                onChange={(e) => {
                  setDataModality(e.target.value);
                }}>
                {filterOptionsSelect &&
                  filterOptionsSelect.map((entity, key) => (
                    <option key={key} value={entity.value}>
                      {entity.name}
                    </option>
                  ))}
              </Select>
            </Flex>

            <CalendarController
              setList={setList}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              calendarTypeButtons={calendarTypeButtons}
              calendarModality={calendarModality}
              setCalendarModality={setCalendarModality}
              setForceSetSearchPeriod={setForceSetSearchPeriod}
              period={period}>
              {calendarModality === 'week' ? <>{list?.formatted}</> : <>{metadata && metadata.monthAndYear}</>}
            </CalendarController>
          </Flex>
        }>
        <ScreenLoader isLoading={isLoading}>
          <Flex h={isLoading ? '80vh' : 'full'} direction="column" gap="0" p="10px">
            {list && (
              <>
                {calendarModality === 'week' ? (
                  <CalendarSection
                    modality={dataModality}
                    calendarData={list ?? {}}
                    isMobile={isMobile}
                    setIsOpenModalItem={setIsOpenModalItem}
                    setCurrentItem={setCurrentItem}
                  />
                ) : (
                  <CalendarMonthSection
                    modality={dataModality}
                    calendarData={list ?? {}}
                    isMobile={isMobile}
                    setIsOpenModalItem={setIsOpenModalItem}
                    setCurrentItem={setCurrentItem}
                  />
                )}
              </>
            )}
          </Flex>

          {isOpenModalItem && <CalendarModalItem isOpen={isOpenModalItem} list={currentItem} onClose={handleCloseModalItem} />}
        </ScreenLoader>
      </Card>
    </Page>
  );
};

export default CalendarPage;
