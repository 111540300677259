import { format, isValid, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

export const newDate = (date) => {
  if (!date || date instanceof Date) {
    return date;
  }

  const dateString = date.toString();
  const dateISO = parseISO(dateString);
  const dateParsed = isValid(dateISO) ? dateISO : new Date(dateString);

  return isValid(dateParsed) ? dateParsed : null;
};

export const ptDateFormatted = (date) => {
  const dateObject = newDate(date);

  if (dateObject instanceof Date) {
    return format(dateObject, 'dd/MM/yyyy', { locale: pt });
  }
  return null;
};

export const dateFormatted = (date) => {
  const dateObject = newDate(date);

  if (dateObject instanceof Date) {
    return format(dateObject, 'yyyy-MM-dd', { locale: pt });
  }
  return null;
};
