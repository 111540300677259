const yup = require('yup');

export const validationSchema = (requiredList = {}, entity = {}, profile) => {
  return yup.object().shape({
    status: yup.mixed().required('Campo obrigatório'),
    responsibleLogisticUser: yup.mixed().nullable(),
    availableDateFormatted: yup.string(),
    reference: yup.string().required('Campo obrigatório'),
    client: yup.mixed().nullable(),
    currency: yup.mixed(),
    valueOfTheMerchandise: yup.mixed().nullable(),
    process: yup.mixed().nullable(),
    modality: yup.mixed().required('Campo obrigatório'),
    isPacklistReceived: yup.mixed().required('Campo obrigatório'),
    originPort: yup.mixed().required('Campo obrigatório'),
    destinationPort: yup.mixed().required('Campo obrigatório'),
    incoterm: yup.mixed().nullable(),
    inlandZipCode: requiredList?.incoterm !== 'EXW' ? yup.string() : yup.string().required('Campo obrigatório'),
    dangerousClass: yup.mixed().required('Campo obrigatório'),
    quoteType: requiredList?.modality?.includes('air') ? yup.mixed() : yup.mixed().required('Campo obrigatório'),
    ncms: yup
      .array(
        yup.object({
          code: yup.string(),
          description: yup.string(),
          exTariffs: yup.string(),
        })
      )
      .test({
        message: 'Você deve selecionar um ou mais NCM',
        test: (arr) => {
          return arr.find(({ description }) => {
            return description;
          });
        },
      }),
    weigth: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    ctrs:
      requiredList?.modality?.includes('air') || requiredList?.quoteType === 'LCL'
        ? yup.string()
        : yup.array().of(
            yup.object().shape({
              qtt: yup.string().required('Campo obrigatório'),
              size: yup.mixed().required('Campo obrigatório'),
              type: yup.mixed().required('Campo obrigatório'),
            })
          ),
    description: yup.string(),
    invoiceValue: yup.string(),
    copyPeople3Email: yup.string().required('Campo obrigatório').email('Deve ser um e-mail válido').trim(),
    copyPeople2: yup.string(),
    logisticInformation:
      (profile.role  === 'ROLE_OPERATOR' || entity?.status !== 'ON_REVIEW_LOGISTICS')
        ? yup.array()
        : yup.array().of(
            yup.object().shape({
              supplier: yup.string().required('Campo obrigatório'),
              currency: yup.mixed().required('Campo obrigatório'),
              totalValue: yup.string().required('Campo obrigatório'),
              transitTime: yup.string().required('Campo obrigatório'),
              freeTime: yup.string().required('Campo obrigatório'),
              expiresAt: yup.string().required('Campo obrigatório'),
            })
          ),
  });
};
