import { useCallback, useEffect, useRef, useState } from 'react';

import { Flex, Grid, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';

import InconsistenciesByStageChart from './components/InconsistenciesByStageChart';
import InconsistencyMetricsChart from './components/InconsistencyMetricsChart';
import ProcessesErrorsTable from './components/ProcessesErrorsTable';
import ProcessesInconsistentTable from './components/ProcessesInconsistentTable';

const InconsistencyPage = () => {
  const hasPermission = permissions.vendemmiaInconsistency;

  const request = useRef(0);

  const [filterOptions, setFilterOptions] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingCharts, setLoadingCharts] = useState(false);

  const [listInconsistency, setListInconsistency] = useState([]);
  const [listErrors, setListErrors] = useState([]);

  const [chartPerStage, setChartPerStage] = useState([]);
  const [chartPerModality, setChartPerModality] = useState([]);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = async (filters, key) => {
    loadProcessesTable(filters, key);
    loadProcessesCharts(filters, key);
  };

  const loadProcessesTable = async (filters, key) => {
    setLoadingTable(true);

    try {
      const data = await requests.getInconsistentProcesses(filters, metadata.current_page > 0 ? metadata.current_page : 1);

      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setListInconsistency(data?.data);
      setListErrors(data?.errors);

      setMetadata(data?.meta);
    } catch (error) {
      console.error('Erro ao carregar dados de processos com inconsistências:', error);
    } finally {
      setLoadingTable(false);
    }
  };

  const loadProcessesCharts = async (filters, key) => {
    setLoadingCharts(true);
    try {
      const data = await requests.getChartInconsistentProcesses(filters);

      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setChartPerStage(data.perStage);
      setChartPerModality(data.perModality);
    } catch (error) {
      console.error('Erro ao carregar dados de processos com inconsistências:', error);
    } finally {
      setLoadingCharts(false);
    }
  };

  const titleButtons = [
    {
      title: 'Erros',
    },
    {
      title: 'Processos',
    },
  ];

  const getFilterOptions = useCallback(async () => {
    const options = await filterOptionsComex();
    setFilterOptions(options);
  }, []);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  return (
    <Page
      screen="inconsistency"
      title="Inconsistências"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={load}
      isContentLoading={loadingTable || loadingCharts}
      isRefreshLoading={loadingTable || loadingCharts}>
      <Flex w="full" h="full" p="10px" gap="10px" direction="column">
        <Grid templateColumns="1fr 1fr" w="full" gap="10px" h="351px">
          <Card
            header={
              <Text textColor="primary" fontSize="20px" fontWeight="bold">
                Inconsistências por etapa
              </Text>
            }>
            <ScreenLoader isLoading={loadingCharts}>
              <InconsistenciesByStageChart data={chartPerStage} />
            </ScreenLoader>
          </Card>

          <Card
            header={
              <Text textColor="primary" fontSize="20px" fontWeight="bold">
                Métricas de inconsistências
              </Text>
            }>
            <InconsistencyMetricsChart data={chartPerModality} loadingCharts={loadingCharts} />
          </Card>
        </Grid>

        <Tabs mt={{ base: '10px', md: '0' }} overflow="hidden" variant="unstyled">
          <ScreenLoader isLoading={loadingTable}>
            <Card
              header={
                <Flex w="full" justify="space-between" align="center">
                  <Text textColor="primary" fontSize="20px" fontWeight="bold">
                    Processos com inconsistências
                  </Text>

                  <TabList>
                    {titleButtons.map((button, index) => {
                      return (
                        <Tab
                          key={index}
                          w="100px"
                          h="30px"
                          p="0px"
                          bg="transparent"
                          border="1px solid #422C76"
                          textColor="#422C76"
                          borderRadius="0px"
                          fontWeight="bold"
                          _selected={{ bg: '#422C76', color: 'white' }}
                          _focus={{ boxShadow: 'none' }}>
                          {button.title}
                        </Tab>
                      );
                    })}
                  </TabList>
                </Flex>
              }>
              <TabPanels p="15px" pt="0">
                <TabPanel p="0px">
                  <ProcessesErrorsTable data={listErrors} />
                </TabPanel>

                <TabPanel p="0px">
                  <ProcessesInconsistentTable data={listInconsistency} />
                </TabPanel>
              </TabPanels>
            </Card>
          </ScreenLoader>
        </Tabs>
      </Flex>
    </Page>
  );
};

export default InconsistencyPage;
