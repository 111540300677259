import React from 'react';

import { Box, CircularProgress, Flex } from '@chakra-ui/react';

import './style.css';

const ScreenLoader = ({ isLoading = false, children, ...props }) => {
  return (
    <Box w="100%" h="100%" position="relative" {...props}>
      <Flex className={`screen-loading ${isLoading ? 'start' : ''}`} pointerEvents={isLoading ? 'all' : 'none'}>
        <CircularProgress className="icon" size="30px" isIndeterminate color="primary" />
      </Flex>
      {children}
    </Box>
  );
};
export default ScreenLoader;
