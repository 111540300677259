import { useLocation } from 'react-router-dom';

/* 
  interface - Optional Fields
  sufix: string, 
  id: string
*/

export default function useScreenPage(props) {
  const location = useLocation();
  const { pathname } = location;

  const regex = /[A-Z0-9]/;

  let sufix = '';
  let id = '';
  let url = '';

  const urlParams = pathname.split('/');

  //verificar se possui partes da url vazias ou com estilo Hash e remove | ex: JGjvoEtX5tHcw9a
  urlParams.forEach((param) => {
    if (param != '' && !regex.test(param)) {
      let concat = param + '-';
      url = url + concat;
    }
  });

  if (props) {
    sufix = props.sufix != undefined && props.sufix;
    id = props.id != undefined && props.id;
  }

  //remove o ultimo caracter '-'
  const removeLastCaracter = url.length - 1;
  url = url.slice(0, removeLastCaracter);

  const screenPage = url + (sufix != '' ? `-${sufix}` : '') + (id != '' ? `-${id}` : '');

  return screenPage;
}
