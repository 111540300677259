import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, Text, Textarea } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ReturnLogistics = ({ entity, callback }) => {
  const yup = require('yup');

  return (
    <Formik
      initialValues={{
        devolutedAsLogistics: '',
      }}
      validationSchema={yup.object().shape({
        devolutedAsLogistics: yup.string().required('Campo obrigatório'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        requests
          .postRequestOperacional(entity?.identifier, {
            status: 'devoluted_as_logistics',
            description: values.devolutedAsLogistics,
          })
          .then(() => {
            toast.success('Cotação devolvida para Análise logistica.');

            setTimeout(() => {
              window.location.href = '/vendemmia/quote';
            }, 2000);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <Flex mt="20px" justify="center" direction="column" gap="20px">
            <Text textStyle="tableTitle" fontWeight="bold" textAlign="center">
              Devolver para análise logística {entity.name}
            </Text>

            <Text textAlign="center">Para prosseguir, descreeva o motivo</Text>

            <Flex direction="column" gap="5px">
              <Textarea
                className="input-register"
                bgColor="#F2F2F2"
                border="1px"
                variant="primary"
                placeholder="Descreeva o motivo..."
                name="devolutedAsLogistics"
                value={values.devolutedAsLogistics}
                onChange={handleChange}
              />
              <ErrorMessage component="p" className="error-message-error" name="devolutedAsLogistics" />{' '}
            </Flex>
          </Flex>

          <Flex justify="space-between">
            <Button
              variant="secundary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="25px 35px"
              isDisabled={isSubmitting}
              onClick={() => callback(false)}>
              Cancelar
            </Button>

            <Button type="submit" variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default ReturnLogistics;
