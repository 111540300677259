/**
 * Função para reduzir o número de caracteres de uma string
 * @param {*} text Texto a ser reduzido
 * @param {*} limit Limite de caracteres (padrão 25)
 * @returns retorna o texto já reduzido
 */
export const limitChars = (text, limit = 25) => {
  if (typeof text !== 'string') {
    // Handle cases where text is not a string, e.g., if it's undefined or null.
    return '';
  }

  return text.slice(0, limit) + (text.length > limit ? '...' : '');
};

export const capitalize = (str) => {
  if (!str || str?.length === 0) {
    return;
  }
 
  return str?.charAt(0)?.toUpperCase() + str?.slice(1).toLowerCase();
};
