import React from 'react';

import { Flex } from '@chakra-ui/react';

import graphKPI from '../../../assets/images/graphKPI.svg';
import graphKPI2 from '../../../assets/images/graphKPI2.svg';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';

import CardRequests from './components/CardRequests';
import CardStatusRequests from './components/CardStatusRequests';
import CardUnitsDelivered from './components/CardUnitsDelivered';

const KPILmsPage = () => {
  return (
    <Layout>
      <Menubar
        linkTree={[{ link: '#', title: 'Métricas' }]}
        title="KPI Transporte"
        placeholder="Processo, empresa ou ref"
      />
      <Flex mx="20px" gap="20px" justify="center">
        <Flex w="480px" gap="20px" direction="column">
          <CardRequests
            title="Pedido Perfeito"
            bgColor="linkColor"
            textColor="#FFFFFF"
            iconColor="#FFFFFFE8"
            Img={graphKPI}
          />

          <CardStatusRequests />

          <CardRequests
            title="Pedidos de Clientes Pendentes"
            bgColor="#FFFFFF"
            textColor="primary"
            iconColor="#00000040"
            Img={graphKPI2}
          />
        </Flex>

        <CardUnitsDelivered />
      </Flex>
    </Layout>
  );
};

export default KPILmsPage;
