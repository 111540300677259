import React, { useEffect, useState } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const HelpTermUse = () => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setLoad(true);
    try {
      const response = await requests.currentTermUse();

      setList([response]);
    } catch (error) {
      setLoad(false);

      console.error(error);
    }
    setLoad(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <Flex direction="column" p="30px">
      {list?.map((entity, key) => {
        return (
          <Text key={key} textStyle="subtitle" color="#6C48C2AD">
            {entity?.description}
          </Text>
        );
      })}

      {load ? (
        <Flex justify="center">
          <Text textStyle="tableTitle" color="#6C48C2AD">
            Carregando...
          </Text>
        </Flex>
      ) : (
        <>
          {list.length === 0 && (
            <Text textStyle="tableTitle" color="#6C48C2AD">
              Nenhuma informação disponível no momento.
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default HelpTermUse;
