import React, { useEffect, useState } from 'react';

import Loading from '../components/Loading/Loading';
import api from '../services/api';

import LoginPage from './auth/LoginPage';

const IndexPage = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = window.localStorage.getItem('session-token');
    if (token && token.length > 0) {
      setLoading(true);

      api
        .get('profile/show', {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          window.location.href = '/dashboard';
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <LoginPage />
      <Loading status={loading} />
    </>
  );
};

export default IndexPage;
