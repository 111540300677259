const yup = require('yup');

const operationalValidation = (isDraft = false, isSecondaryZone = false) => {
  let validation = yup.object().shape({
    forwardingAgent: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    forwardingAgentAdditionalInformation: yup.string(),
    dispatchValue: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    productTypeDescription: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    isAntidumping: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    useSystemBravos: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    importingLicenseConsentingEntity: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
    importingLicenseValue: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    importingLicenseAdditionalInformation: yup.string(),
    operationNature: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    incoterm: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    incotermAdditionalInformation: yup.string(),
    hasInternationalTransportQuote: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    transportModality: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
    goodsEntranceFederativeUnit: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    goodsEntranceFederativeAdditionalInformation: yup.string(),
    customsClearanceLocation: isDraft ? yup.string() : yup.string().required('Campo obrigatório').nullable(),
    customsClearanceLocationAdditionalInformation: yup.string(),
    insuranceCalculationBasisVendemmia: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    insuranceCalculationBasisClient: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    insuranceAdditionalInformation: yup.string(),
    storageAfterBilling: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    transportType: isDraft || !isSecondaryZone ? yup.string() : yup.string().required('Campo obrigatório'),
    goodsEntranceFederativeUnitModality: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    storageAfterBillingAdditionalInformation: yup.string(),
    nationalFreightModality: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    nationalFreightBilling: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    hasCashFlow: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
    nationalizationFactor: isDraft
      ? yup.string()
      : yup.string().when('hasCashFlow', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    nationalizationDays: isDraft
      ? yup.string()
      : yup.string().when('hasCashFlow', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    expensesFactor: isDraft
      ? yup.string()
      : yup.string().when('hasCashFlow', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    expensesDays: isDraft
      ? yup.string()
      : yup.string().when('hasCashFlow', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    billingFactor: isDraft
      ? yup.string()
      : yup.string().when('hasCashFlow', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    billingDays: isDraft
      ? yup.string()
      : yup.string().when('hasCashFlow', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    exchanges: isDraft
      ? yup.string()
      : yup.mixed().when('hasCashFlow', {
          is: true,
          then: yup
            .array(
              yup.object({
                percent: yup.string(),
                days: yup.string(),
              })
            )
            .test({
              message: 'Os campos acima devem ser completamente preenchidos',
              test: (arr) => {
                return arr?.find(({ percent, days }) => {
                  return percent && days;
                });
              },
            }),
        }),
    ncmRelations: isDraft
      ? yup.string()
      : yup
          .array(
            yup.object({
              id: yup.string(),
              description: yup.string(),
              tariff: yup.string(),
            })
          )
          .test({
            message: 'Você deve selecionar um ou mais NCM',
            test: (arr) => {
              return arr.find(({ description }) => {
                return description;
              });
            },
          }),
  });

  return validation;
};

export default operationalValidation;
