import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import ChannelFlag from '../../../comex/process/components/ChannelFlag';

const TableSLA = ({ overflowCount, data }) => {
  return (
    <>
      <Box mx="15px" mt="15px" w="calc(100% - 30px)" height={`calc(100vh - ${overflowCount}px)`} overflowY="auto">
        <Table className="table-default">
          <Thead>
            <Tr>
              <Th>
                <Box></Box>
              </Th>
              <Th style={{ paddingLeft: '15px' }}>Processo </Th>
              <Th>Chegada</Th>
              <Th>T</Th>
              <Th>Registro</Th>
              <Th>T</Th>
              <Th>Liberação (CI)</Th>
              <Th>T</Th>
              <Th>Faturamento</Th>
              <Th>T</Th>
              <Th>SLA</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.table?.map((row) => (
                <Tr key={row.identifier}>
                  <Td w="60px">
                    <Tooltip label="Visualizar processo">
                      <Link to={`/comex/process/detail/${row.identifier}`} target="_blank">
                        <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                      </Link>
                    </Tooltip>
                  </Td>

                  <Td w="170px">
                    <Flex w="full" justifyContent="space-between">
                      {row.code ?? '-'}
                      {row.channel && <ChannelFlag color={row.channel ?? '-'} />}
                    </Flex>
                  </Td>
                  <Td>{row.arrivedAtFormatted ?? '-'}</Td>
                  <Td>{row.tArrivedAt ?? '-'}</Td>
                  <Td>{row.clearenceDiAtFormatted ?? '-'}</Td>
                  <Td>{row.tClearenceDiAt ?? '-'}</Td>
                  <Td>{row.clearenceCiAtFormatted ?? '-'}</Td>
                  <Td>{row.tClearenceCiAt ?? '-'}</Td>
                  <Td>{row.billedAtFormatted ?? '-'}</Td>
                  <Td>{row.tBilledAt ?? '-'}</Td>
                  <Td>{row.SLA ?? '-'}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      <Flex
        position="absolute"
        bottom="4px"
        right="70px"
        bg="#EEEEEE"
        borderRadius="9px"
        p="14px 25px"
        justifyContent="center"
        gap="10px"
        shadow="2xl"
        alignItems="center">
        <Text color="#0F0A1D" fontWeight="medium" fontSize="18px">
          Média
        </Text>
        <Text color="#707070" fontSize="18px">
          {data?.total_average}
        </Text>
      </Flex>
    </>
  );
};

export default TableSLA;
