import React from 'react';

import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';

const ModalChangeSituation = ({
  callback,
  isOpen,
  onClose,
  trajectorySelected
}) => {
  const handleClick = (userDecision) => {
    callback(userDecision);
    onClose();
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent p="10px 35px" borderRadius="50px">
          <ModalBody p="35px" position="relative">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text
                textStyle="secondaryTitle"
                fontWeight="medium"
                textColor="#0F0A1D"
                textAlign="center"
                mb="20px"
                className="up-anim">
                Você tem certeza que deseja <strong>{!trajectorySelected.hasExecuted ? 'confirmar ' : 'desconfirmar '}</strong> a {trajectorySelected.type}?
              </Text>
              <Flex>
                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(false)}
                >
                  Cancelar
                </Button>
                <Button variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={() => handleClick(true)}>
                  Continuar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalChangeSituation;
