import React from 'react';

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';

const ProcessUpdatesCard = ({ entity }) => {
  return (
    <AccordionItem border="none">
      <Tooltip label="Visualizar todas as atualizações dessa etapa">
        <AccordionButton className="gray-box">
          <Box as="span" flex="1" textAlign="left">
            <Flex direction="row" align="center" gap="5px">
              <Text fontSize="12px" color="#0F0A1D99" ml="3px" mr="10px">
                {entity.alerts.length ? entity.alerts.length : '0'}
              </Text>
              {entity.alerts.length > 1 || entity.alerts.length === 0 ? 'Atualizações' : 'Atualização'}
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Tooltip>

      <AccordionPanel p="12px 5px" className="gray-box" mt="10px">
        <ul
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            padding: '10px',
            maxHeight: '300px',
            overflowY: 'auto',
          }}>
          {entity?.alerts && entity.alerts.length > 0 ? (
            entity.alerts.map((entity, i) => {
              return (
                <li
                  key={entity.identifier}
                  style={{
                    '--delay': `${i / 20}s`,
                    width: '100%',
                  }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: entity.description,
                    }}
                  />
                  <Text textStyle="paragraph" textColor="#181818" fontWeight="medium">
                    <small>
                      {entity.createdAt}
                    </small>
                  </Text>
                </li>
              );
            })
          ) : (
            <li className="gray-box">Sem atualizações</li>
          )}
        </ul>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ProcessUpdatesCard;
