import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Flex, Grid, GridItem, IconButton, List, ListItem, Text } from '@chakra-ui/react';

import getDayOfWeek from '../../../../utils/date/getDayOfWeek';

import CategoryColumn from './CategoryColumn';

const FinanceFlowTable = ({ fluxCashTableDataValue, fetchDetailsByStatus, detailsByStatus, handleNext, handlePrevious }) => {
  const { dates } = fluxCashTableDataValue || {};

  const formaterData = (date) => {
    const parts = date.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  };

  const categoryColumnView = [
    {
      category: detailsByStatus && detailsByStatus.foreignExchangeAdvance,
    },
    {
      category: detailsByStatus && detailsByStatus.exchangeBalance,
    },
    {
      category: detailsByStatus && detailsByStatus.paymentOfNationalizationTaxes,
    },
    {
      category: detailsByStatus && detailsByStatus.nationalizationExpenses,
    },
    {
      category: detailsByStatus && detailsByStatus.projectedRevenue,
    },
    {
      category: detailsByStatus && detailsByStatus.invoicedBills,
    },
  ];

  return (
    <>
      <Grid as="header" gap={3}>
        <GridItem display={'grid'} gridTemplateColumns={'1fr'}>
          <Flex minH={'54px'}>
            <IconButton
              icon={<MdKeyboardArrowLeft size={30} />}
              bg={'linkColor'}
              borderRadius={'0'}
              p={'0'}
              color={'white'}
              h={'full'}
              onClick={handlePrevious}
            />

            <Flex align={'center'} justify={'center'} w={'full'} border={'2px solid #6C48C2'}>
              <Text color={'linkColor'} fontSize={'20px'} fontWeight={'700'}>
                Periodo: {dates && formaterData(fluxCashTableDataValue.startsAt)} até
                {dates && ' ' + formaterData(fluxCashTableDataValue.endsAt)}
              </Text>
            </Flex>

            <IconButton
              icon={<MdKeyboardArrowRight size={30} />}
              bg={'linkColor'}
              borderRadius={'0'}
              p={'0'}
              color={'white'}
              h={'full'}
              onClick={handleNext}
            />
          </Flex>
        </GridItem>

        <GridItem>
          <List
            display={'grid'}
            gridTemplateColumns={`repeat(8, 1fr)`}
            lineHeight={'75px'}
            justifyItems={'center'}
            alignItems={'center'}
            border={'1px solid #70707036'}
            borderRadius={'12px'}>
            <ListItem minW={'330px'}>&nbsp;</ListItem>

            {dates &&
              dates.map((item, key) => {
                return (
                  <ListItem key={key} data-leftline={true} display={'Flex'} textAlign={'center'}>
                    <Text as={'p'} lineHeight={'initial'} color={'primary'} fontSize={'20px'} fontWeight={'500'}>
                      <Text as={'span'} lineHeight={'20px'} fontSize={'15px'}>
                        {getDayOfWeek(item)}
                      </Text>

                      <br />
                      {formaterData(item)}
                    </Text>
                  </ListItem>
                );
              })}
          </List>
        </GridItem>
      </Grid>

      {categoryColumnView.map((item, key) => {
        return (
          <>
            <CategoryColumn
              id={key}
              dates={dates}
              fetchDetailsByStatus={fetchDetailsByStatus}
              detailsByStatus={item.category}
              fluxCashTableDataValue={fluxCashTableDataValue}
            />
          </>
        );
      })}
    </>
  );
};

export default FinanceFlowTable;
