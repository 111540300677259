import React from 'react';

import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';

import QuoteApprove from './QuoteApprove';
import QuoteCancel from './QuoteCancel';
import QuoteFinalized from './QuoteFinalized';
import QuoteReject from './QuoteReject';
import ReturnLogistics from './ReturnLogistics';
import ReviewAsOperational from './ReviewAsOperational';

const ModalStatusChange = ({ isOpen, onOpen, onClose, values, entity, callback }) => {
  const { isOpen: disclosureIsOpen, onOpen: disclosureOnOpen, onClose: disclosureOnClose } = useDisclosure();

  const handleOpen = () => {
    onOpen ? onOpen() : disclosureOnOpen();
  };

  const handleClose = () => {
    onClose ? onClose() : disclosureOnClose();
  };

  return (
    <Modal isCentered isOpen={isOpen || disclosureIsOpen} onOpen={handleOpen} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent maxW="none" maxH="80vh" w="auto" h="auto">
        <ModalBody p="35px" position="relative">
          <Flex direction="column" alignItems="center" justifyContent="center">
            {values.status.value === 'APPROVED' && <QuoteApprove entity={entity} callback={callback} />}

            {values.status.value === 'REJECTED' && <QuoteReject entity={entity} callback={callback} />}

            {values.status.value === 'ON_REVIEW_LOGISTICS' && <ReturnLogistics entity={entity} callback={callback} />}

            {values.status.value === 'ON_REVIEW_OPERATIONAL' && <ReviewAsOperational entity={entity} callback={callback} />}

            {values.status.value === 'CANCELLED' && <QuoteCancel entity={entity} callback={callback} />}

            {values.status.value === 'FINALIZED' && <QuoteFinalized entity={entity} callback={callback} />}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalStatusChange;
