function addRadioInputPreset(customFields) {
  // Check if customFields is defined and is an array
  if (Array.isArray(customFields)) {
    // Check if any element in customFields has type 'radio'
    const radioField = customFields.find((field) => field.type === 'radio');

    // Check if radioField is defined and has a bgColor property
    if (radioField && radioField.bgColor) {
      // Store bgColor in sessionStorage
      sessionStorage.setItem('bgColor', JSON.stringify(radioField.bgColor));
    } else {
      // Clear bgColor from sessionStorage if radioField or bgColor is undefined
      sessionStorage.removeItem('bgColor');
    }
  }

  // Retrieve and parse bgColor from sessionStorage
  const storedBgColor = sessionStorage.getItem('bgColor');
  try {
    // Attempt to parse JSON, return null if parsing fails
    return storedBgColor ? JSON.parse(storedBgColor) : null;
  } catch (error) {
    // Handle JSON parsing error (e.g., if storedBgColor is not valid JSON)
    console.error('Error parsing JSON:', error);
    return null;
  }
}

export default addRadioInputPreset;
