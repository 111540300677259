import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Avatar, Button, Flex, Image, Menu, MenuButton, MenuDivider, MenuGroup, MenuList, Text } from '@chakra-ui/react';

import logoff from '../../assets/images/logoff.svg';
import getProfile from '../../services/profile';
import requests from '../../services/requests';
import EditProfileModal from '../Modal/Profile/EditProfile';

const DropdownProfile = () => {
  const navigate = useNavigate();
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);

  const [profile, setProfile] = useState({
    role: getProfile.role !== 'undefined' ? getProfile.role : '',
    name: getProfile.name !== 'undefined' ? getProfile.name : '',
    email: getProfile.email !== 'undefined' ? getProfile.email : '',
    phone: getProfile.phone !== 'undefined' ? getProfile.phone : '',
    picture: getProfile.picture !== 'undefined' ? getProfile.picture : '',
    cellphone: getProfile.cellphone !== 'undefined' ? getProfile.cellphone : '',
    roleHumanReadable: getProfile.roleHumanReadable !== 'undefined' ? getProfile.roleHumanReadable : '',
  });

  const logout = () => {
    setIsLoadingLogout(true);
    requests
      .logout()
      .then(() => {
        toast.success('Seu acesso foi finalizado com sucesso.');
      })
      .catch(() => {
        toast.success('Seu acesso foi finalizado.');
      })
      .finally(() => {
        // Mesmo se der erro, o usuário faz logout, porém apenas localmente.
        setProfile({});
        window.localStorage.clear();
        navigate('/');
        setIsLoadingLogout(false);
      });
  };

  const refreshProfile = () => {
    setProfile({
      role: getProfile.role !== 'undefined' ? getProfile.role : '',
      name: getProfile.name !== 'undefined' ? getProfile.name : '',
      email: getProfile.email !== 'undefined' ? getProfile.email : '',
      phone: getProfile.phone !== 'undefined' ? getProfile.phone : '',
      picture: getProfile.picture !== 'undefined' ? getProfile.picture : '',
      cellphone: getProfile.cellphone !== 'undefined' ? getProfile.cellphone : '',
      roleHumanReadable: getProfile.roleHumanReadable !== 'undefined' ? getProfile.roleHumanReadable : '',
    });
  };

  useEffect(() => {
    refreshProfile();
  }, []);

  return (
    <Menu>
      <MenuButton>
        <Avatar src={profile.picture} name={profile.name} w="38px" h="38px" cursor="pointer" border="1px" />
      </MenuButton>

      <MenuList w="317px" border="1px" borderRadius="7px" borderColor="#A885F982" shadow="lg" p="0">
        <MenuGroup>
          <Flex direction="row" justify="space-between" align="center" p="19px">
            <Avatar src={profile.picture} name={profile.name} w="43px" h="43px" border="1px" />
            <Flex direction="column" justify="center">
              <Text
                textStyle="tableTitle"
                fontWeight="bold"
                color="primary"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                maxW="170px">
                {profile.name}
              </Text>
              <Text color="#422C76" textStyle="paragraph">
                {profile.roleHumanReadable}
              </Text>
            </Flex>

            <Button
              p="0"
              borderColor="primary"
              borderRadius="100%"
              _hover={{ bgColor: 'linkColor' }}
              isLoading={isLoadingLogout}
              aria-label="Sair">
              <Image src={logoff} cursor="pointer" onClick={logout} />
            </Button>
          </Flex>
        </MenuGroup>

        <MenuDivider m="0" bgColor="#A885F982" />

        <MenuGroup>
          <Flex gap="14px" direction="column" p="19px">
            <EditProfileModal profile={profile} setProfile={setProfile} />
          </Flex>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default DropdownProfile;
