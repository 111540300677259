export const MapDashboardStyles = ({ children, ...props }) => {
  return (
    <style>
      {`
        .gm-style-iw-d[style] {
          overflow: auto !important;
          padding: 0px 15px 15px 0px;
        }

        .gm-style-iw-chr {
          border-bottom: 1px solid #ccc;
          margin: 0 10px 11px 0;
        }

        [role="dialog"] .info-window-content{
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: 14px;
          max-width: 280px;
        }
      `}
    </style>
  );
};
