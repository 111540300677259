import React, { useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Collapse, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

import ConsolidationClientsDetails from './ConsolidationClientsDetails';

const ConsolidationClients = ({ list, modality, modalityDetails, clients, index, moneyFormat, colorFormat, viewport }) => {
  const [collapseClients, setCollapseClients] = useState(null);

  const processExists =
    list.result.data[modality].data[modalityDetails].clients[clients].process &&
    Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].process).length > 0;

  const extraInformationExists =
    list.result?.data[modality]?.data[modalityDetails]?.clients[clients]?.extraInformation &&
    Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].extraInformation).length > 0;

  const dataIsEmpty = !processExists && !extraInformationExists;

  const handleCollapseClients = (index) => {
    if (!dataIsEmpty) {
      setCollapseClients(collapseClients === index ? null : index);
    }
  };

  return (
    <>
      <Tr key={index} onClick={() => handleCollapseClients(index)} cursor={dataIsEmpty ? 'default' : 'pointer'} className="border">
        <Td p="10px" minW="70px" maxW="70px">
          <Flex mt="5px !important">
            {!dataIsEmpty &&
              (collapseClients === index ? (
                <MdKeyboardArrowDown size={20} color="#707070" />
              ) : (
                <MdKeyboardArrowRight size={20} color="#707070" />
              ))}
          </Flex>
        </Td>

        <Td p="10px" minW="280px">
          <Flex mt="5px !important">
            <Tooltip
              label={list.result.data[modality].data[modalityDetails].clients[clients].title}
              aria-label={list.result.data[modality].data[modalityDetails].clients[clients].title}>
              <Text fontSize="12px">{limitChars(list.result.data[modality].data[modalityDetails].clients[clients].title, 40)}</Text>
            </Tooltip>
          </Flex>
        </Td>

        <Td p="10px" minW="280px">
          <Flex mt="5px !important" justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>

            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].clients[clients].billed)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].clients[clients].billed)}
            </Text>
          </Flex>
        </Td>

        <Td p="10px" minW="280px">
          <Flex mt="5px !important" justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>

            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].clients[clients].estimated)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].clients[clients].estimated)}
            </Text>
          </Flex>
        </Td>

        <Td p="10px" minW="280px">
          <Flex mt="5px !important" justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>

            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].clients[clients].budgeted)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].clients[clients].budgeted)}
            </Text>
          </Flex>
        </Td>

        <Td p="10px" w="full">
          <Flex justify={viewport > '1500' ? 'center' : 'normal'} mt='5px !important'>
            <Flex w={viewport > '1500' ? '280px' : 'full'} justify="space-between">
              <Text textColor="linkColor" fontWeight="bold">
                R$
              </Text>

              <Text color={colorFormat(list.result.data[modality].data[modalityDetails].clients[clients].difference)}>
                {moneyFormat(list.result.data[modality].data[modalityDetails].clients[clients].difference)}
              </Text>
            </Flex>
          </Flex>
        </Td>
      </Tr>

      <Tr key={`${index}-clients`}>
        <Td colSpan={4} border="none" p="0px">
          <Collapse in={collapseClients === index}>
            <Flex ml="15px" bgColor="#F9F9F9" pb="10px" direction="column" gap="20px">
              {Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].process).length > 0 && (
                <Table>
                  <Thead>
                    <Tr>
                      <Th p="10px" textAlign="left">
                        <Text>Processo</Text>
                      </Th>

                      <Th p="10px" textAlign="center">
                        <Text>Faturado</Text>
                      </Th>

                      <Th p="10px" textAlign="center">
                        <Text>A faturar</Text>
                      </Th>

                      <Th p="10px" textAlign="center"></Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {list.result.data[modality].data[modalityDetails].clients[clients].process &&
                    Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].process).length > 0
                      ? Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].process).map(
                          (clientDetails, index) => (
                            <ConsolidationClientsDetails
                              key={index}
                              index={index}
                              list={list}
                              modality={modality}
                              modalityDetails={modalityDetails}
                              clients={clients}
                              clientDetails={clientDetails}
                              moneyFormat={moneyFormat}
                              colorFormat={colorFormat}
                            />
                          )
                        )
                      : null}
                  </Tbody>
                </Table>
              )}

              {list.result?.data[modality]?.data[modalityDetails]?.clients[clients]?.extraInformation &&
                Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].extraInformation).length > 0 && (
                  <Table ml="15px">
                    <Thead>
                      <Tr>
                        <Th p="10px" textAlign="left">
                          Informações adicionais
                        </Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {Object.keys(list.result.data[modality].data[modalityDetails].clients[clients].extraInformation).map(
                        (information, index) => (
                          <Tr key={index}>
                            <Td p="10px">
                              <Flex>
                                {list.result.data[modality].data[modalityDetails].clients[clients].extraInformation[information]}
                              </Flex>
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
            </Flex>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ConsolidationClients;
