import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import { getLinkValueByValue } from '../Helpers/getLinkValueByValue';

export const useCategoriesProps = (categories, property, index, item, value) => {
  // Responsiveness
  const { isMobile } = useResponsiveCheck();

  // Obter valores atualizados para as dependências
  const linkValue = getLinkValueByValue(categories, property);
  const currentCategory = categories[index];
  const currentProperty = item[property];

  // Verifica se renderHTML é uma função e chama com o item atual
  const renderHTML = currentCategory?.renderHTML ? currentCategory.renderHTML(item) : false;

  // Verificação se currentCategory foi encontrado
  if (!currentCategory) {
    console.error(`Categoria não encontrada para a propriedade ${property}`);
    return null;
  }

  const { formatted = null, align = 'initial', type = 'text', variantTag = 'solidFlat', showMore = false } = currentCategory;

  // Verificação de valor válido
  const isValidValue = value !== undefined && value !== null && value !== '-' && value !== '';
  const isValidCategory =
    currentProperty !== undefined && currentProperty !== null && currentProperty !== '-' && currentProperty !== '';

  // Formatar o valor do campo conforme ao método criado
  let formattedValue = isMobile ? (formatted ? formatted(currentProperty) : currentProperty) : formatted ? formatted(value) : value;

  return {
    linkValue,
    currentCategory,
    // Fields
    align,
    type,
    variantTag,
    formattedValue,
    renderHTML,
    showMore,
  };
};
