import formatDocument from '../../../../utils/fields/masks/formatDocument';

export const initialValues = (entity = {}) => {
  let responsibles = [];
  if (entity?.responsibles?.length > 0) {
    entity?.responsibles.map((user, index) => {
      responsibles.push( { value: user?.identifier, label: user?.name });
    });
  }

  let products = [];
  if (entity?.products?.length > 0) {
    entity?.products.map((product, index) => {
      products.push( { value: product.code, label: product.name });
    });
  }

  return {
    companyBranchName: entity?.companyBranchName ? { value: entity?.companyBranchName, label: entity?.companyBranchName } : '',
    companyBranchDocumentNumber: entity?.companyBranchDocumentNumber ? formatDocument(entity.companyBranchDocumentNumber) : '',
    stateCodeBilling: entity?.stateCodeBilling ? { value: entity?.stateCodeBilling, label: entity?.stateCodeBillingReadable } : '',
    clientDocumentNumber: entity?.clientDocumentNumber ? formatDocument(entity.clientDocumentNumber) : '',
    stateCodeResidenceDocumentNumber: entity?.stateCodeResidenceDocumentNumber ? { value: entity?.stateCodeResidenceDocumentNumber, label: entity?.stateCodeResidenceDocumentNumberReadable } : '',
    isIcmsTaxpayer: entity?.isIcmsTaxpayer != null ? { value: (entity?.isIcmsTaxpayer ? 1 : 0), label: (entity.isIcmsTaxpayer  ? 'Sim' : 'Não') } : '',
    stateRegistration: entity?.stateRegistration ? entity?.stateRegistration : '',
    typeOfSale: entity?.typeOfSale ? { value: entity?.typeOfSale, label: entity?.typeOfSaleReadable } : '',
    taxRegime: entity?.taxRegime ? { value: entity?.taxRegime, label: entity?.taxRegimeReadable } : '',
    importModality: entity?.importModality ? { value: entity?.importModality, label: entity?.importModalityReadable } : '',
    finality: entity?.finality ? { value: entity?.finality, label: entity?.finalityReadable } : '',
    taxBenefit: entity?.taxBenefit ? { value: entity?.taxBenefit, label: entity?.taxBenefitReadable } : '',
    requestedAt: entity?.requestedAt || '',
    expiresAt: entity?.expiresAt || '',
    operationObservation: entity?.operationObservation ? entity?.operationObservation : '',
    fiscalObservation: entity?.fiscalObservation ? entity?.fiscalObservation : '',
    status: entity?.status ? { value: entity?.status, label: entity?.statusReadable } : { value: 'EM_ESTUDO', label: 'Em estudo' },
    products: products,
    responsibles: responsibles,
  };
};
