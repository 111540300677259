/* Exemplo de uso

const [inputCpforCnpj, setInputCpforCnpj] = useState('');

<Input
  value={inputCpforCnpj}
  onChange={(event) => {
    const formatted = formatDocument(event.target.value);
    setInputCpforCnpj(formatted);
  }}
  maxLength="18"
/>;

*/

const formatDocument = (value) => {
  // Se value não for uma string, converte para string ou retorna vazio
  if (!value || typeof value !== 'string') {
    return '';
  }

  // Remove caracteres não numéricos
  const unformattedValue = value.replace(/\D/g, ''); //Remove tudo o que não é dígito

  // Formata como CPF
  if (unformattedValue.length <= 11) {
    let v = unformattedValue;
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Repete para o segundo bloco de números
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  // Formata como CNPJ
  else {
    let v = unformattedValue;
    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }
};

export default formatDocument;
