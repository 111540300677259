import React from 'react';

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { iconMia } from '../../../../utils/exports';

const ProcessMessageCard = ({ entity }) => {
  function getPicture(picture) {
    let url = process.env.REACT_APP_BASE_URL;

    if (url === 'http://localhost:8000' || 'http://localhost:8001') {
      url = 'https://api.vendemmia.com.br';
    }

    const newImage = window.localStorage.getItem('temp-profile-picture');
    const tempPicture = newImage || picture;
    return (url + tempPicture)?.replace('//profile', '/profile');
  }
  return (
    <AccordionItem border="none">
      <Tooltip label="Visualizar todos os Comentários dessa etapa">
        <AccordionButton className="gray-box">
          <Box as="span" flex="1" textAlign="left">
            <Flex direction="row" align="center" gap="5px">
              <Text fontSize="12px" color="#0F0A1D99" ml="3px" mr="10px">
                {entity.comments.length ? entity.comments.length : '0'}
              </Text>
              {entity.comments.length > 1 ? 'Comentários' : 'Comentário'}
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Tooltip>

      <AccordionPanel p="12px 5px" className="gray-box" mt="10px">
        <ul
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            padding: '10px',
            maxHeight: '300px',
            overflowY: 'auto',
            width: '100%',
          }}>
          {entity?.comments?.map((entity, key, i) => {
            return (
              <li
                key={entity.identifier}
                style={{
                  '--delay': `${i / 20}s`,
                  width: '100%',
                }}>
                <Flex w="full" direction="column" gap="10px" key={key}>
                  <Flex justify="space-between">
                    <Flex align="center" justify="center" gap="10px">
                      <Avatar src={getPicture(entity.userProfilePictureUri)} name={entity?.userName} w="40px" h="40px" border="1px" />

                      <Flex direction="column">
                        <Text
                          textStyle="subtitle"
                          textColor="primary"
                          fontWeight="bold"
                          display={'flex'}
                          gap={'3px'}
                          textTransform={'uppercase'}>
                          {entity?.userName}{' '}
                          {entity?.userName === 'A.I. Mia' && <Image src={iconMia} h={'15px'} w={'16px'} mt={'3px'} />}
                        </Text>

                        <Text textStyle="paragraph" textColor="#0F0A1D99">
                          {entity?.userRole}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex textStyle="paragraph" textColor="#707070">
                      {entity?.createdAt}
                    </Flex>
                  </Flex>

                  <Text textStyle="paragraph" textColor="#181818" fontWeight="medium">
                    {entity?.description}
                    {entity.isEdited && (
                      <Tooltip label={`Editado em  ${entity.updatedAt}`}>
                        <small> (editado)</small>
                      </Tooltip>
                    )}
                  </Text>
                </Flex>
              </li>
            );
          })}
        </ul>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ProcessMessageCard;
