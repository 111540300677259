const yup = require('yup');

const tributaryValidation = (isDraft = false) => {
  let validation = yup.object().shape({
    isIcmsTaxpayer: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    operationPurpose: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
    fiscalBenefit: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
    taxRegime: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    additionalInformation: yup.string(),
  });

  return validation;
};

export default tributaryValidation;
