import { useRef } from 'react';

import { MdNorth } from 'react-icons/md';

import {
  Flex,
} from '@chakra-ui/react'
import { Box, Text, Tooltip } from '@chakra-ui/react';

import bgCardNumber from '../../../assets/svgs/kpi-card-number-status.svg'

import CardDrawerButton from './CardDrawerButton';


const CardNumberStatus = ({
  bgColor,
  text,
  textDescription,
  textColor,
  fontSizeSubtitle,
  fontSizeTitle,
  value,
  isMoney, //ativa o "R$" no valor do title
  iconMore,
  iconHelp,
  textHelp,
  valueDiffPreviousPeriod,
  valueTextAppended,
  hasDrawer,
  formatValue,
  minWidth,
  bgImage
}) => {

  const bgCardImage = bgImage && bgImage !== undefined ? "url(" + bgCardNumber + ") bottom right no-repeat " + bgColor : bgColor;
  const fontSizeSubtitleCard = fontSizeSubtitle && fontSizeSubtitle !== undefined ? fontSizeSubtitle + 'px' : '20px';
  const fontSizeTitleCard = fontSizeTitle && fontSizeTitle !== undefined ? fontSizeTitle + 'px' : '40px';
  const minWidthCard = minWidth && minWidth !== undefined ? minWidth : '320px';
  const hasDrawerCard = hasDrawer && hasDrawer !== undefined ? hasDrawer : false;

  return (
    <Box 
      h="full" 
      minW={{ base: '100%', lg: minWidthCard }}  
      border="1px" 
      borderColor="#7070703D" 
      className="up-anim"
      background={bgCardImage}
      backgroundSize={"contain"}
    >
      <Flex 
        h="full" 
        px="30px" 
        py="20px" 
        direction="column" 
        justify="space-around" 
        fontSize={{ base: '18px', md: fontSizeSubtitleCard }}
      >
        {iconMore && (
          <Flex w="full" justify="flex-end">
            {iconMore}
          </Flex>
        )}

        <Flex align="center" gap="13px">
          <Text textStyle="tableTitle" textColor={textColor} fontWeight="bold">
            {text}
          </Text>

          {iconHelp && textHelp && (
            <Tooltip label={textHelp}>
              <Box>{iconHelp}</Box>
            </Tooltip>
          )}
        </Flex>

        <Flex 
          direction="row" 
          align="flex-end" 
          justify="space-between"
          w={'100%'}
        >
          <Flex direction="column" w={'100%'}>
            <Flex 
              direction="row" 
              flexDirection="column" 
            >
              <Text 
                textStyle="primaryTitle" 
                textColor={textColor} 
                fontSize={{ base: '18px', md: fontSizeTitleCard }}
                display={'flex'}
                alignItems={'baseline'}
              >
                <strong>{isMoney ? "R$ " + formatValue(value) : formatValue(value)}</strong>

                {valueTextAppended && (
                  <Text textStyle="secondaryTitle" textColor={textColor}>
                    &nbsp;dias
                  </Text>
                )}
              </Text>              
              <Flex 
                fontSize={'16px'}
                justify="space-between"
                alignItems={{ base:'flex-start', md:'center' }}
                flexDirection={{ base:'column', md:'row' }}
                gap={{ base:'15px', md:'initial' }}
              >
                <Text
                  fontSize={{ base: '18px', md: fontSizeSubtitleCard }}
                  color={'primary'}
                >
                  {textDescription}
                </Text>
                
                {hasDrawerCard && 
                  <CardDrawerButton hasDrawerCard={hasDrawerCard} />
                }
              </Flex>
            </Flex>

            {valueDiffPreviousPeriod && (
              <Flex direction="row" align="center" gap="5px">
                <Flex align="center" gap="5px">
                  <MdNorth color="#2ECC71" size={12} />
                  <Text textStyle="paragraph" textColor="#2ECC71">
                    {valueDiffPreviousPeriod}%
                  </Text>
                </Flex>

                <Text textStyle="paragraph" textColor={textColor}>
                  no período anterior
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CardNumberStatus;
