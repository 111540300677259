import React, { useEffect, useState } from 'react';

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

const DocumentModalView = ({ isOpen, entity, onClose }) => {
  const [linkDocument, setLinkDocument] = useState(null);

  const handleClose = () => {
    setLinkDocument();
    onClose();
  };

  useEffect(() => {
    if (entity?.identifier && isOpen) {
      let link = (process.env.REACT_APP_API_URL + `/open/quote/${entity?.identifier}/file/view`).replace('//open', '/open');

      setLinkDocument(link);
    }
  }, [isOpen]);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalHeader>{entity.fileName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="25px" position="relative">
            <iframe width="100%" height="700em" src={linkDocument} allowFullScreen={true}></iframe>;
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentModalView;
