import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

const CalendarModalItem = ({ isOpen, list, onClose }) => {
  let navigate = useNavigate();
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalHeader>
            {list?.event}: {list?.qtt}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="25px" position="relative">
            <Table className="table-default" w="full">
              <Thead border="1px" borderBottom="1px" h="3rem">
                <Tr>
                  <Th>Processo</Th>
                  <Th>Visualizar</Th>
                </Tr>
              </Thead>
              <Tbody border="1px">
                {list &&
                  list?.process?.map((item, key) => {
                    return (
                      <Tr key={key}>
                        <Td>
                          <Text>{item?.code}</Text>
                        </Td>
                        <Td>
                          <Box>
                            <IconButton
                              bg="transparent"
                              icon={<MdOpenInNew size={26} color="#6C48C2" />}
                              onClick={(decision) => {
                                if (decision) {
                                  navigate(`/comex/process/detail/${item?.identifier}`);
                                }
                              }}
                            />
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CalendarModalItem;
