import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, ModalBody, ModalFooter, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import InputDefaultMask from '../../../../components/Form/Input/InputDefaultMask';
import Tag from '../../../../components/Generic/Tag';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const yup = require('yup');

const SlaData = ({ entity = {}, handleClose }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  const loadData = () => {
    setIsLoadingData(true);

    requests.showSlaClient(entity.identifier).then((response) => {
      setData(response);
      setIsLoadingData(false);
    });
  };

  function getFormInitialValues() {
    let result = [];

    if (data?.length > 0) {
      data.map((item) => {
        result.push({
          modality: item?.modality || '',
          volumeQuantity: item?.volumeQuantity || '',
          cuttingPeriod: item?.cuttingPeriod || '',
          slaBeforeTime: item?.slaBeforeTime || '',
          slaAfterTime: item?.slaAfterTime || '',
          identifier: item?.identifier || '',
        });
      });
    } else {
      result.push({
        modality: '',
        volumeQuantity: '',
        cuttingPeriod: '',
        slaBeforeTime: '',
        slaAfterTime: '',
      });
    }

    return result;
  }

  function validationSchema() {
    return yup.object().shape({
      modality: yup.string(),
      volumeQuantity: yup.string(),
      cuttingPeriod: yup.string(),
      slaBeforeTime: yup.string(),
      slaAfterTime: yup.string(),
      identifier: yup.string(),
    });
  }

  useEffect(() => {
    if (!entity?.identifier) {
      return;
    }

    loadData();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getFormInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        requests.editSlaClient(entity.identifier, { data: values }).then(() => {
          setSubmitting(false);
          toast.success('SLA alterado com sucesso!');
          handleClose();
        });

        setSubmitting(false);
      }}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody mt="10px" overflowY="auto" h={{ md: '500px', sm: '350px' }} w="full" className="up-anim">
            {isLoadingData ? (
              <ScreenLoader isLoading={isLoadingData} />
            ) : (
              <>
                <Table colorScheme="teal" w="full" border="1px" borderColor="#A18BD8">
                  <Thead bgColor="#A18BD81A">
                    <Tr borderBottom="1px" borderColor="#A18BD8">
                      <Th borderBottom="1px" borderColor="#A18BD8">
                        Modalidade
                      </Th>
                      <Th borderBottom="1px" borderColor="#A18BD8">
                        Por volume
                      </Th>
                      <Th borderBottom="1px" borderColor="#A18BD8">
                        Por Período <small>(data de corte)</small>
                      </Th>
                      <Th borderBottom="1px" borderColor="#A18BD8">
                        SLA antes do período
                      </Th>
                      <Th borderColor="#A18BD8">SLA depois do período</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.length > 0 &&
                      data.map((sla, key) => (
                        <Tr key={key} borderBottom="1px" borderColor="#A18BD8">
                          <Td borderBottom="1px" borderColor="#A18BD8">
                            <Tag type="green">{sla?.modalityReadable.toUpperCase()}</Tag>
                          </Td>
                          <Td borderBottom="1px" borderColor="#A18BD8">
                            <InputDefault
                              type="number"
                              setFieldValue={setFieldValue}
                              value={values[key]?.volumeQuantity}
                              name={`[${key}].volumeQuantity`}
                              placeholder="Preencha o volume"
                            />
                          </Td>
                          <Td borderBottom="1px" borderColor="#A18BD8">
                            <InputDefaultMask
                              setFieldValue={setFieldValue}
                              value={values[key]?.cuttingPeriod}
                              name={`[${key}].cuttingPeriod`}
                              placeholder="18:00"
                              mask="99:99"
                            />
                          </Td>
                          <Td borderBottom="1px" borderColor="#A18BD8">
                            <InputDefaultMask
                              setFieldValue={setFieldValue}
                              value={values[key]?.slaBeforeTime}
                              name={`[${key}].slaBeforeTime`}
                              placeholder="D+1"
                              mask="D+9"
                            />
                          </Td>
                          <Td borderColor="#A18BD8">
                            <InputDefaultMask
                              setFieldValue={setFieldValue}
                              value={values[key]?.slaAfterTime}
                              name={`[${key}].slaAfterTime`}
                              placeholder="D+1"
                              mask="D+9"
                            />
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </>
            )}
          </ModalBody>

          <ModalFooter
            borderTop="1px"
            borderColor="#7070702E"
            p="0"
            m={{ sm: '10px', md: 'initial' }}
            justifyContent="space-between"
            flexDirection={{ md: 'row', sm: 'column' }}>
            <Flex w="full" justify="flex-end" flexDirection={{ md: 'row', sm: 'column' }} gap={{ sm: '10px' }}>
              <Button
                border="1px"
                borderRadius="7px"
                m={{ md: '15px' }}
                w={{ md: 'fit-content', sm: '100%' }}
                p="9px 23px"
                color="#E73668"
                borderColor="#E52359"
                bgColor="transparent"
                type="button"
                onClick={handleClose}>
                Cancelar e fechar
              </Button>

              <Button
                variant="primary"
                borderRadius="7px"
                m={{ md: '15px' }}
                w={{ md: 'fit-content', sm: '100%' }}
                p="9px 23px"
                type="submit"
                isLoading={isLoadingSaveData}
                loadingText="Salvando">
                Salvar
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default SlaData;
