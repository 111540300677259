import React, { useEffect, useState } from 'react';

import { FaTruck, FaUser } from 'react-icons/fa';
import { MdHelp, MdKeyboardArrowDown, MdKeyboardArrowUp, MdLocationPin, MdOpenInNew, MdOutlineListAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, Divider, Flex, IconButton, Image, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import logo from '../../../../assets/images/gatsby-icon.png';
import cte from '../../../../assets/svgs/cte.svg';
import iconEfetiva from '../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../assets/svgs/origem-esperada.svg';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import useResponsiveCheck from '../../../../hooks/useResponsiveCheck';
import requests from '../../../../services/requests';
import { processCheck, processUncheck } from '../../../../utils/exports';
import { limitChars } from '../../../../utils/strings';
import { convertColorTrip, descriptionStatusTrip, modalityIcon, modalityTitle } from '../../../../utils/translate';

import DocumentModalView from './Documents/DocumentModalView';

export default function ListCollapse({ entity, entityMarker = null, setCurrentTripData = null, handleCurrentTrip, load }) {
  let navigate = useNavigate();
  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);
  const { isOpen, onToggle } = useDisclosure();
  const [percentageCompletion, setPercentageCompletion] = useState(entity?.percentageCompletion ?? null);
  const [estimatedEnds, setEstimatedEnds] = useState(null);
  const [estimatedEndsTooltip, setEstimatedEndsTooltip] = useState('Data esperada');

  const handleOpen = (identifier, code) => {
    setCurrentIdentifier(identifier);
    setCurrentCode(code);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const mobile = useResponsiveCheck();
  const viewport = mobile.viewport;

  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  const Icon = isOpen ? <MdKeyboardArrowUp color="#707070" size="20" /> : <MdKeyboardArrowDown color="#707070" size="20" />;

  const statusTrip = descriptionStatusTrip(entity?.status);
  const showHelpStartTrip = entity?.startsEtdFormatted != null && entity?.startsEtdFormatted != '' ? true : false;

  const checkPlate = () => {
    if (entity?.plates) {
      return entity?.plates;
    } else if (entity?.vehicle?.licensePlate) {
      return entity?.vehicle?.licensePlate;
    } else {
      return '-';
    }
  };

  const fetchPercentageCompletion = async (identifier, percentageValue) => {
    if (percentageValue >= 0) {
      setPercentageCompletion(percentageValue);
      return;
    } 

    if(!entity?.identifier) {
      return;
    }

    try {
      const response = await requests.tripPercentage(identifier);
      setPercentageCompletion(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  const fetchEstimatedEnds = async (identifier, status, ends) => {
    if (status != 'TRAVELLING') {
      setEstimatedEnds(ends ?? 'Data não disponível');
      return;
    }

    if(!entity?.identifier) {
      return;
    }

    try {
      const response = await requests.tripEstimatedEnds(identifier);
      setEstimatedEnds(response?.date ?? 'Data não disponível');
      setEstimatedEndsTooltip(response?.message ?? 'Data esperada');
    } catch (error) {
      console.error('Erro ao buscar a data estimada de chegada:', error);
    }
  };

  const percentage = (modality, status, typeExternalCode) => {
    let text;

    if (status === 'COMPLETED') {
      text = 'Viagem concluída';
    } else if (modality === 'FRETE_NAO_RASTREAVEL') {
      text = 'Porcentagem estimada de conclusão com base nos horários previstos';
    } else if (modality === 'APP_MOTORISTA' || typeExternalCode === 'OPENTECH') {
      text = 'Porcentagem estimada de conclusão com base na distância';
    } else {
      text = 'Carregando...';
    }

    return text;
  };

  useEffect(() => {
    if (!load) {
      fetchEstimatedEnds(entity?.identifier, entity?.status, entity?.endsAtFormatted);
      fetchPercentageCompletion(entity?.identifier, entity?.percentageCompletion);
    }
  }, [load]);

  return (
    <>
      <Tr id={entity.identifier} className="border">
        {/* collapse */}
        <Td cursor="pointer" onClick={onToggle} sx={{ m: 0, p: 0 }}>
          <Box align="center" sx={{ m: 0, p: 0 }} h="64px !important">
            {Icon}
          </Box>
        </Td>

        {/* modality */}
        <Td border="none" sx={{ m: 0, p: 0 }}>
          <Tooltip
            label={modalityTitle(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}
            aria-label={modalityTitle(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}>
            <Box align="center" sx={{ m: 0, p: 0 }} h="64px !important">
              {modalityIcon(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}
            </Box>
          </Tooltip>
        </Td>

        {/* status */}
        <Td>
          <Flex h="64px !important">
            <Text
              p="5px 12px"
              bg={convertColorTrip(entity.status)}
              color="white"
              borderRadius="6px"
              fontSize="10px"
              fontWeight="bold"
              w="fit-content">
              {statusTrip}
            </Text>
          </Flex>
        </Td>

        {/* viagem */}
        <Td cursor="pointer" sx={{ m: 0, p: 0 }}>
          <Flex direction="column" align="flex-start !important" h="64px !important" justify="space-between">
            <Text
              textColor="linkColor"
              onClick={(decision) => {
                if (decision) {
                  navigate(`/logistic/trip/detail/${entity.identifier}`, {
                    state: {
                      historyBack: '/logistic/trip/timeline',
                    },
                  });
                }
              }}>
              #{entity?.code}
            </Text>

            {entity?.externalCode && (
              <Flex
                gap="3px"
                width={
                  entity?.typeExternalCode == 'OPENTECH' || (entity.modality == 'APP_MOTORISTA' && entity?.externalCode?.length == 8)
                    ? '135px'
                    : 'auto'
                }>
                <Text fontSize="0.8rem">Cód.: {entity?.externalCode}</Text>

                <Box>
                  <Tooltip label="Código externo (integração para rastreio)">
                    <Box style={{ paddingLeft: '1px' }}>
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                </Box>
              </Flex>
            )}
          </Flex>
        </Td>

        {/* docs */}
        <Td bgColor={'transparent'} sx={{ m: 0, p: 0 }}>
          <Flex h="64px !important" align="center" justify="space-between">
            <Text>{entity?.docsNumber && entity?.docsNumber.length > 0 ? entity.docsNumber.join(', ') : '-'}</Text>

            <Box>
              {entity.qttFiles > 0 && (
                <Flex>
                  <Tooltip label="Ver arquivos">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<MdOutlineListAlt size={20} color="#6C48C2" />}
                        onClick={(decision) => {
                          if (decision) {
                            handleOpen(entity?.identifier, entity?.code);
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              )}
            </Box>
          </Flex>
        </Td>

        {/* empresa */}
        <Td sx={{ m: 0, p: 0 }}>
          <Flex h="64px !important">
            <Tooltip label={entity?.clientsFormatted?.join(',')}>
              <Text>{limitChars(entity?.clientsFormatted?.join(','), 30)}</Text>
            </Tooltip>
          </Flex>
        </Td>

        {/* trasporte */}
        <Td sx={{ m: 0, p: 0 }}>
          <Flex flexDirection="column" h="64px !important" align="flex-start !important" justify="space-between">
            <Flex alignItems="center">
              <FaUser color="#8364CB" />
              {entity?.userDriverName?.length > 30 ? (
                <Tooltip label={entity?.userDriverName ?? 'Não identificado'} ml="4">
                  <Box ml="1">{entity?.userDriverName ? limitChars(entity?.userDriverName, 30) : 'Não identificado'}</Box>
                </Tooltip>
              ) : (
                <Box ml="1">{entity?.userDriverName ?? 'Não identificado'} </Box>
              )}
            </Flex>

            <Flex alignItems="center">
              <Tooltip label={entity?.shippingCompanyName ?? 'Não identificado'}>
                <Box mr="1">
                  {entity?.shippingCompanyName?.toLowerCase() == 'vendemmia' ? (
                    <Image src={logo} alt="Logo" margin="auto" width="19px" />
                  ) : (
                    <FaTruck color="#A0A0A0" />
                  )}
                </Box>
              </Tooltip>
              {checkPlate()}
            </Flex>
          </Flex>
        </Td>

        {/* inicio */}
        <Td textStyle="subtitle" textColor="#0F0A1DB3" sx={{ m: 0, p: 0 }}>
          <Flex h="64px !important" alignItems="flex-start !important" direction="column" justify="space-between">
            <Flex title="Data esperada" alignItems="center">
              <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
              {entity?.startsAtFormatted ?? 'Data não disponível'}
            </Flex>

            <Flex alignItems="center" mt="1">
              <Flex title="Data efetiva" alignItems="center">
                <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                {entity?.startsEtdFormatted ?? 'Data não disponível'}
              </Flex>

              {entity?.startsEtdFormatted != null && (
                <Tooltip label={tooltipStart}>
                  <Box style={{ paddingLeft: '2px' }}>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Td>

        {/* conclusão */}
        <Td textStyle="subtitle" textColor="#0F0A1DB3" sx={{ m: 0, p: 0 }}>
          <Flex alignItems="flex-start !important" direction="column" h="64px !important">
            <Flex alignItems="center">
              <Flex>
                <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
                <Tooltip label={estimatedEndsTooltip}>{estimatedEnds !== null ? estimatedEnds : 'Carregando...'}</Tooltip>
              </Flex>
              <Tooltip label={percentage(entity.modality, entity.status, entity.typeExternalCode)}>
                <Text ml="10px" color="#8364CB">{entity?.percentageCompletion !== null ? `(${entity.percentageCompletion}%)` : 'Carregando...'}</Text>
              </Tooltip>

              {entity?.endsEtdFormatted != null && (
                <Tooltip label={tooltipEnd}>
                  <Box style={{ paddingLeft: '4px' }}>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>

            <Flex alignItems="center" mt="1">
              <Flex title="Data efetiva" alignItems="center">
                <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                {entity?.endsEtdFormatted ?? 'Data não disponível'}
              </Flex>
            </Flex>
          </Flex>
        </Td>

        {/* detalhes */}
        <Td sx={{ m: 0, p: 0 }}>
          <Flex direction="row" gap="5px" h="64px !important" justify="space-between">
            <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
              <Flex w="fit-content" p="7px 11px" border="1px" borderColor="#2ECC71" color="#2ECC71" borderRadius="10px" gap="5px">
                Pegada CO₂:{' '}
                {entity?.carbonTrack?.calculated.toLocaleString('pt-BR', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}
                t
                {entity?.carbonTrack?.retired > 0 ? (
                  <Image src={processCheck} opacity={'1'} />
                ) : (
                  <Image src={processUncheck} opacity={'1'} />
                )}
              </Flex>
            </Tooltip>

            <Flex gap="5px">
              <Tooltip label="Detalhe da viagem" placement="left">
                <Box sx={{ m: 0, p: 0 }}>
                  <IconButton
                    bg="transparent"
                    icon={<MdOpenInNew size={20} color="#6C48C2" />}
                    onClick={(decision) => {
                      if (decision) {
                        navigate(`/logistic/trip/detail/${entity.identifier}`, {
                          state: {
                            historyBack: '/logistic/trip/timeline',
                          },
                        });
                      }
                    }}
                  />
                </Box>
              </Tooltip>

              <Tooltip label="Visualizar trajeto da viagem" placement="left">
                <Box sx={{ m: 0, p: 0 }}>
                  <IconButton
                    bg="transparent"
                    icon={<MdLocationPin size={20} color={entityMarker && entityMarker.color ? entityMarker.color : '#ea4335'} />}
                    onClick={() => {
                      if (typeof setCurrentTripData === 'function') {
                        handleCurrentTrip(entityMarker);
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        </Td>
      </Tr>

      {isOpen && (
        <Tr w="full">
          <Td
            p="0"
            colSpan={15}
            border="1px"
            borderColor="#70707036"
            bgColor="#F9F9F9"
            style={{
              padding: 0,
            }}>
            <Collapse in={isOpen} animateOpacity>
              <Flex bgColor="#F9F9F9" direction="column" w="full">
                {entity?.timeline?.length > 0 &&
                  entity.timeline.map((item, index) => (
                    <>
                      {item?.cte && (
                        <Flex gap={'15px'} pl="20px" pt="10px">
                          <Image src={cte} />

                          <Text textStyle="tableTitle" fontWeight="medium" color="#422C76" fontSize="1.3rem">
                            {item?.cte && item?.cte}
                          </Text>
                        </Flex>
                      )}

                      <Box key={index} pl="20px" pt="20px" pb="20px" w="99%">
                        <ProgressBar timeline={item} />
                      </Box>

                      {index < entity?.timeline?.length - 1 && <Divider mt="10px" />}
                    </>
                  ))}
              </Flex>
            </Collapse>
          </Td>
        </Tr>
      )}

      <DocumentModalView isOpen={isOpenModalViewFile} identifier={currentIdentifier} code={currentCode} onClose={handleOpen} />
    </>
  );
}
