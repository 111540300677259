import React, { useState } from 'react';

import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

const PieChartComponent = ({
  extraText = false,
  hasPTBRNumberFormat = true,
  entity,
  total,
  formatValue,
  handleClickStatus = () => {},
  isPreview = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const tickFormatter = (tickItem) => {
    if (!hasPTBRNumberFormat) {
      tickItem.toFixed(2);
    }

    return tickItem.toLocaleString('pt-BR');
  };

  const handleCellClick = (entry, index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      handleClickStatus(null);
    } else {
      setActiveIndex(index);
      handleClickStatus(entry.status);
    }
  };

  return (
    <>
      <Flex align="center" justify="center">
        <Box zIndex="2">
          <PieChart width={250} height={250}>
            <Tooltip formatter={tickFormatter} />
            <Pie data={entity} dataKey="qtt" cx="50%" cy="50%" innerRadius={90} outerRadius={110} paddingAngle={3} minAngle={8}>
              {entity.map((entry, index) => (
                <Cell
                  shapeRendering="true"
                  key={`cell-${index}`}
                  cursor={isPreview ? 'arrow' : 'pointer'}
                  fill={activeIndex === null || activeIndex === index ? entry.color : `${entry.color}40`}
                  onClick={() => {
                    if (isPreview) {
                      null;
                    } else {
                      handleCellClick(entry, index);
                    }
                  }}
                />
              ))}
            </Pie>
          </PieChart>
        </Box>

        <Flex position="absolute" direction="column" align="center">
          <Text textStyle="secondaryTitle" textColor="#B3B8BD">
            Total {extraText ? extraText : ''}
          </Text>

          <Text textStyle="primaryTitle" textColor="#333333" fontWeight="bold">
            {typeof formatValue === 'function' ? formatValue(total) : total}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default PieChartComponent;
