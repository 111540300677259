import React, { useState } from 'react';

import { FaDownload, FaPenNib /*, FaUndo*/ } from 'react-icons/fa';
import { /*MdBlock,*/ MdHelp, MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Grid, IconButton, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';

const ContractManagementTable = ({
  list,
  action,
  setAction,
  metadata,
  setMetadata,
  handleView,
  handleCurrentIdentifier,
  handleCurrentStatus,
  handleCurrentModality,
  handleOpenModalStatus,
}) => {
  let navigate = useNavigate();
  const [isHovered, setHovered] = useState(false);

  const handleColor = (status) => {
    switch (status) {
      case 'CADASTRADO':
        return 'status-100';
      case 'ENVIADO':
        return 'status-300';
      case 'EM_ASSINATURA':
        return 'yellow-500';
      case 'ASSINADO':
      case 'RENOVADO':
        return 'green';
      case 'EXPIRADO':
      case 'CANCELADO':
        return 'red';
      default:
        return 'gray-200';
    }
  };

  const handleDownload = (identifier, uploadName, d4SignFilePathSigned) => {
    const modality = d4SignFilePathSigned != null && d4SignFilePathSigned !== undefined ? 'signed' : 'unsigned';
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/contract/${identifier}/download/${modality}`).replace('//open', '/open');
    link.target = '_blank';
    link.download = uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tooltipSigned = (list) => {
    return (
      <>
        {list?.map((item, index) => (
          <Text key={index}>{item}</Text>
        ))}
      </>
    );
  };

  const tooltipUnsigned = (list) => {
    return (
      <>
        {list?.map((item, index) => (
          <Text key={index}>{item}</Text>
        ))}
      </>
    );
  };

  const handleChangeStatus = (identifier, status) => {
    handleCurrentIdentifier(identifier);
    handleCurrentStatus(status);
    handleCurrentModality('create');
    handleOpenModalStatus(true);
  };

  return (
    <>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th fontWeight="bold">Título</Th>
            <Th fontWeight="bold">Descrição</Th>
            <Th fontWeight="bold">Dt de criação</Th>
            <Th fontWeight="bold">Tipo de vencimento</Th>
            <Th fontWeight="bold">Expiração</Th>
            <Th>
              <Flex direction="column" alignItems="center" justifyContent="space-between">
                <Text fontSize="0.9rem">Partes interessadas</Text>
                <Grid templateColumns="1fr 1fr" width="100%" marginTop="2">
                  <Box borderTop="1px" borderColor="gray.200" textAlign="center">
                    <Text fontWeight="bold">Assinaturas</Text>
                  </Box>
                  <Box borderTop="1px" borderColor="gray.200" textAlign="center">
                    <Text fontWeight="bold">Pendentes</Text>
                  </Box>
                </Grid>
              </Flex>
            </Th>

            <Th fontWeight="bold">Contrato</Th>
            <Th fontWeight="bold">Status</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {list?.map((entity, key) => (
            <Tr key={key} style={{ '--delay': `0.${key}s` }}>
              <Td>{entity?.title ?? '-'}</Td>
              <Td>{entity?.description ?? '-'}</Td>
              <Td>{entity?.createdAtFormatted ?? '-'}</Td>
              <Td>{entity?.expirationTypeReadable ?? '-'}</Td>
              <Td>{entity?.expiresAtFormatted ?? '-'}</Td>
              <Td>
                <Grid templateColumns="1fr 1fr" width="100%">
                  <Flex alignItems="center">
                    <Text fontWeight="bold">{entity?.verifiedSignatures?.signed ?? 0}</Text>

                    {entity?.verifiedSignatures?.signed > 0 && (
                      <Tooltip label={tooltipSigned(entity?.verifiedSignatures?.responsiblesSigned)}>
                        <Box ml="0.625rem">
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                  <Flex alignItems="center">
                    <Text fontWeight="bold">{entity?.verifiedSignatures?.unsigned ?? 0}</Text>
                    {entity?.verifiedSignatures?.unsigned > 0 && (
                      <Tooltip label={tooltipUnsigned(entity?.verifiedSignatures?.responsiblesUnsigned)}>
                        <Box ml="0.625rem">
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                </Grid>
              </Td>
              <Td position="relative" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                <Flex gap="0.625rem">
                  <Tooltip label="Visualizar contrato">
                    <Flex
                      p="1px 0.75rem"
                      border="solid 1px"
                      borderRadius="0.438rem"
                      alignItems="center"
                      align="center"
                      color="#6C48C2"
                      minHeight="2.5rem"
                      fontSize={{ base: '0.75rem', md: '0.875rem' }}
                      onClick={(decision) => {
                        if (decision) {
                          handleView(entity?.identifier, entity?.fileName, entity?.d4SignFilePathSigned);
                        }
                      }}
                      cursor="pointer">
                      {entity.fileName ?? '-'}
                    </Flex>
                  </Tooltip>
                  {/*isHovered && (
                      <Box
                        position="absolute"
                        width="70%"
                        top="0px" // Ajuste conforme necessário
                        left="100px" // Ajuste conforme necessário
                        transform="translateX(-50%)"
                        backgroundColor="white"
                        borderRadius="5px"
                        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
                        zIndex={999}>
                        <Flex gap="0px" justifyContent="space-evenly">
                          <IconButton
                            variant="primary"
                            icon={<MdOpenInNew />}
                            onClick={(decision) => {
                              if (decision) {
                                handleView(entity?.identifier, entity?.fileName);
                              }
                            }}
                          />
                          <IconButton
                            variant="primary"
                            icon={<FaDownload />}
                            onClick={(decision) => {
                              if (decision) {
                                handleDownload(entity?.identifier, entity?.fileName);
                              }
                            }}
                          />
                          <IconButton
                            variant="primary"
                            icon={<FaPenNib />}
                            onClick={(decision) => {
                              if (decision) {
                                handleDownload(entity?.identifier, entity?.fileName);
                              }
                            }}
                          />
                        </Flex>
                      </Box>
                          )*/}
                </Flex>
              </Td>
              <Td>
                <Text
                  p="1px 0.75rem"
                  borderRadius="0.438rem"
                  alignItems="center"
                  align="center"
                  bg={handleColor(entity?.status)}
                  color="white"
                  fontSize={{ base: '0.75rem', md: '0.875rem' }}>
                  {entity.statusReadable ?? '-'}
                </Text>
              </Td>
              <Td>
                <Flex gap="0.62rem" alignItems="center">
                  <Tooltip label="Visualizar">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<MdOpenInNew size={26} color="#6C48C2" />}
                        onClick={(decision) => {
                          if (decision) {
                            navigate(`/vendemmia/contract-management/detail/${entity?.identifier}`);
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip label="Download contrato">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<FaDownload size={23} color="#6C48C2" />}
                        onClick={(decision) => {
                          if (decision) {
                            handleDownload(entity?.identifier, entity?.fileName, entity?.d4SignFilePathSigned);
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>

                  <Tooltip
                    label={
                      entity?.status !== 'EM_RASCUNHO'
                        ? 'Contrato já foi enviado para assinaturas'
                        : 'Enviar contrato para assinaturas'
                    }>
                    <Box>
                      {entity?.status !== 'EM_RASCUNHO' ? (
                        <FaPenNib size={23} color="#B1B1B1" />
                      ) : (
                        <IconButton
                          bg="transparent"
                          icon={<FaPenNib size={23} color="#6C48C2" />}
                          onClick={(decision) => {
                            if (decision) {
                              handleChangeStatus(entity?.identifier, entity?.status);
                            }
                          }}
                        />
                      )}
                    </Box>
                  </Tooltip>

                  {/*<Tooltip label="Visualizar">
                      <Box>
                        <IconButton
                          bg="transparent"
                          icon={<MdBlock size={26} color="#6C48C2" />}
                        />
                      </Box>
                    </Tooltip>

                    <Tooltip label="Visualizar">
                      <IconButton
                        bg="transparent"
                        icon={<FaUndo size={20} color="#6C48C2" />}
                      />
                    </Tooltip>*/}
                </Flex>
              </Td>
            </Tr>
          ))}

          {list?.length == 0 && (
            <Tr>
              <Td colSpan={9}>
                <Flex justify="center" my="1.563rem">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default ContractManagementTable;
