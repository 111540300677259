import React from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Flex, Grid, IconButton, Text } from '@chakra-ui/react';

import CalendarTab from './CalendarTab';

const CalendarController = ({
  children,
  setList,
  handlePrevious,
  handleNext,
  calendarTypeButtons,
  calendarModality,
  setCalendarModality,
  setForceSetSearchPeriod,
  period,
}) => {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: '1fr auto' }}
      justifyItems={'center'}
      w="full"
      alignItems={'center'}
      gap={{ base: '5px', md: '15px' }}>
      <Flex
        alignItems="center"
        justify="space-between"
        w="full"
        gap="10px"
        border="2px solid #6C48C2 "
        order={{ base: '2', md: 'initial' }}
        position={'relative'}>
        <IconButton
          onClick={handlePrevious}
          bg={'#6C48C2 '}
          color={'white'}
          borderRadius={0}
          minH={{ base: 'initial', md: '50px' }}
          p={'0'}
          icon={<MdKeyboardArrowLeft size={30} />}
        />
        <Text textStyle="tableTitle" fontWeight={'bold'} color={'#6C48C2'} fontSize={'20px'}>
          <Text fontSize={{ base: '16px', md: '20px' }}>{children}</Text>
        </Text>
        <IconButton
          onClick={handleNext}
          bg={'#6C48C2 '}
          color={'white'}
          borderRadius={0}
          minH={{ base: 'initial', md: '50px' }}
          p={'0'}
          icon={<MdKeyboardArrowRight size={30} />}
        />
      </Flex>
      <CalendarTab
        setList={setList}
        calendarTypeButtons={calendarTypeButtons}
        calendarModality={calendarModality}
        setCalendarModality={setCalendarModality}
        setForceSetSearchPeriod={setForceSetSearchPeriod}
        period={period}
      />
    </Grid>
  );
};

export default CalendarController;
