import React, { useEffect, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from '@chakra-ui/react';

import Card from '../../../../../components/Card/Card';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import useResponsiveCheck from '../../../../../hooks/useResponsiveCheck';
import requests from '../../../../../services/requests';
import ModalFreight from '../TravelList/ModalFreight';
import TravelManagementPanelTabs from '../TravelManagementPanelTabs';

import KanbanColumn from './KanbanColumn';

const TravelKanban = ({ action, setAction, tab, setTab, filterSelected }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const responsive = useResponsiveCheck();
  const viewport = responsive.viewport;

  const tabOpt = ['Preparação', 'Execução', 'Entrega', 'Faturamento'];

  const [kanbanTab, setKanbanTab] = useState('Preparação');

  const [loadPreparation, setLoadPreparation] = useState(true);
  const [loadExecution, setLoadExecution] = useState(true);
  const [loadDelivery, setLoadDelivery] = useState(true);
  const [loadInvoicing, setLoadInvoicing] = useState(true);

  const [isLoadingItems, setIsLoadingItems] = useState(false);

  // Preparação
  const [refusedByDriver, setRefusedByDriver] = useState();
  const [draft, setDraft] = useState();
  const [created, setCreated] = useState();
  const [sentToDriver, setSentToDriver] = useState();
  const [acceptedByDriver, setAcceptedByDriver] = useState();
  const [cancelled, setCancelled] = useState();

  const [metaRefusedByDriver, setMetaRefusedByDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDraft, setMetaDraft] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCreated, setMetaCreated] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaSentToDriver, setMetaSentToDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCancelled, setMetaCancelled] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaAcceptedByDriver, setMetaAcceptedByDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Execução
  const [goingToCollect, setGoingToCollect] = useState();
  const [readToCollect, setReadToCollect] = useState();
  const [collecting, setCollecting] = useState();
  const [readToTravel, setReadToTravel] = useState();
  const [travelling, setTravelling] = useState();
  const [halted, setHalted] = useState();

  const [metaGoingToCollect, setMetaGoingToCollect] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaReadToCollect, setMetaReadToCollect] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCollecting, setMetaCollecting] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaReadToTravel, setMetaReadToTravel] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaTravelling, setMetaTravelling] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaHalted, setMetaHalted] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Entrega
  const [arrivedAtDestination, setArrivedAtDestination] = useState();
  const [unloading, setUnloading] = useState();
  const [deliverRefused, setDeliverRefused] = useState();
  const [deliverSignatureCollected, setDeliverSignatureCollected] = useState();
  const [deliverSignatureRefused, setDeliverSignatureRefused] = useState();
  const [completed, setCompleted] = useState();

  const [metaArrivedAtDestination, setMetaArrivedAtDestination] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaUnloading, setMetaUnloading] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverRefused, setMetaDeliverRefused] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverSignatureCollected, setMetaDeliverSignatureCollected] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverSignatureRefused, setMetaDeliverSignatureRefused] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCompleted, setMetaCompleted] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Faturamento
  const [paymentAllowed, setPaymentAllowed] = useState();
  const [paid, setPaid] = useState();
  const [paidCancelled, setPaidCancelled] = useState();

  const [metaPaymentAllowed, setMetaPaymentAllowed] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaPaid, setMetaPaid] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaPaidCancelled, setMetaPaidCancelled] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  // Preparação
  const showMoreItems = async (requestFunction, stateSetter, state, metaSetter, meta) => {
    setIsLoadingItems(true);
    try {
      const response = await requestFunction(meta.current_page > 0 ? meta.current_page + 1 : 1, 10, filterSelected);

      stateSetter([[...state[0], ...response.data]]);
      metaSetter(response.meta);
      setIsLoadingItems(false);
    } catch (error) {
      setIsLoadingItems(false);
      console.error('Erro na solicitação da API: ', error);
    }
  };

  const showMoreRefusedByDriver = () => {
    showMoreItems(requests.requestRefusedByDriver, setRefusedByDriver, refusedByDriver, setMetaRefusedByDriver, metaRefusedByDriver);
  };

  const showMoreDraft = () => {
    showMoreItems(requests.requestDraft, setDraft, draft, setMetaDraft, metaDraft);
  };

  const showMoreCreated = () => {
    showMoreItems(requests.requestCreated, setCreated, created, setMetaCreated, metaCreated);
  };

  const showMoreSentToDriver = () => {
    showMoreItems(requests.requestSentToDriver, setSentToDriver, sentToDriver, setMetaSentToDriver, metaSentToDriver);
  };

  const showMoreAcceptedByDriver = () => {
    showMoreItems(
      requests.requestAcceptedByDriver,
      setAcceptedByDriver,
      acceptedByDriver,
      setMetaAcceptedByDriver,
      metaAcceptedByDriver
    );
  };

  const showMoreCancelled = () => {
    showMoreItems(requests.requestCancelled, setCancelled, cancelled, setMetaCancelled, metaCancelled);
  };

  //Execução
  const showMoreGoingToCollect = () => {
    showMoreItems(requests.requestGoingToCollect, setGoingToCollect, goingToCollect, setMetaGoingToCollect, metaGoingToCollect);
  };

  const showMoreReadToCollect = () => {
    showMoreItems(requests.requestReadToCollect, setReadToCollect, readToCollect, setMetaReadToCollect, metaReadToCollect);
  };

  const showMoreCollecting = () => {
    showMoreItems(requests.requestCollecting, setCollecting, collecting, setMetaCollecting, metaCollecting);
  };

  const showMoreReadToTravel = () => {
    showMoreItems(requests.requestReadToTravel, setReadToTravel, readToTravel, setMetaReadToTravel, metaReadToTravel);
  };

  const showMoreTravelling = () => {
    showMoreItems(requests.requestTravelling, setTravelling, travelling, setMetaTravelling, metaTravelling);
  };

  const showMoreHalted = () => {
    showMoreItems(requests.requestHalted, setHalted, halted, setMetaHalted, metaHalted);
  };

  //Entrega
  const showMoreArrivedAtDestination = () => {
    showMoreItems(
      requests.requestArrivedAtDestination,
      setArrivedAtDestination,
      arrivedAtDestination,
      setMetaArrivedAtDestination,
      metaArrivedAtDestination
    );
  };

  const showMoreUnloading = () => {
    showMoreItems(requests.requestUnloading, setUnloading, unloading, setMetaUnloading, metaUnloading);
  };

  const showMoreDeliverRefused = () => {
    showMoreItems(requests.requestDeliverRefused, setDeliverRefused, deliverRefused, setMetaDeliverRefused, metaDeliverRefused);
  };

  const showMoreDeliverSignatureCollected = () => {
    showMoreItems(
      requests.requestDeliverSignatureCollected,
      setDeliverSignatureCollected,
      deliverSignatureCollected,
      setMetaDeliverSignatureCollected,
      metaDeliverSignatureCollected
    );
  };

  const showMoreDeliverSignatureRefused = () => {
    showMoreItems(
      requests.requestDeliverSignatureRefused,
      setDeliverSignatureRefused,
      deliverSignatureRefused,
      setMetaDeliverSignatureRefused,
      metaDeliverSignatureRefused
    );
  };

  const showMoreCompleted = () => {
    showMoreItems(requests.requestCompleted, setCompleted, completed, setMetaCompleted, metaCompleted);
  };

  //Faturamento
  const showMorePaymentAllowed = () => {
    showMoreItems(requests.requestPaymentAllowed, setPaymentAllowed, paymentAllowed, setMetaPaymentAllowed, metaPaymentAllowed);
  };

  const showMorePaid = () => {
    showMoreItems(requests.requestPaid, setPaid, paid, setMetaPaid, metaPaid);
  };

  const showMorePaidCancelled = () => {
    showMoreItems(requests.requestPaidCancelled, setPaidCancelled, paidCancelled, setMetaPaidCancelled, metaPaidCancelled);
  };

  const fetchData = async (requestFunction, setStateFunction, setMetaFunction, currentPage, pageSize) => {
    if (filterSelected.period) {
      try {
        const response = await requestFunction(currentPage > 0 ? currentPage : 1, pageSize, filterSelected);
        setStateFunction([response.data]);
        setMetaFunction(response.meta);

        switch (kanbanTab) {
          case 'Preparação':
            return setLoadPreparation(false);

          case 'Execução':
            return setLoadExecution(false);

          case 'Entrega':
            return setLoadDelivery(false);

          case 'Faturamento':
            return setLoadInvoicing(false);

          default:
            return api;
        }
      } catch (error) {
        console.error('Erro na solicitação da API: ', error);
      }
    }
  };

  const kanbanColumnsPreparation = [
    {
      title: 'Recusado pelo motorista',
      status: 'REFUSED_BY_DRIVER',
      icon: 'trip-refused.svg',
      color: '#E74C3C',
      entity: refusedByDriver,
      meta: metaRefusedByDriver,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreRefusedByDriver,
    },
    {
      title: 'Em rascunho',
      status: 'DRAFT',
      icon: 'trip-draft.svg',
      color: '#6C48C2',
      entity: draft,
      meta: metaDraft,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreDraft,
    },
    {
      title: 'Cadastrada',
      status: 'CREATED',
      icon: 'trip-created.svg',
      color: '#6C48C2',
      entity: created,
      meta: metaCreated,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreCreated,
    },
    {
      title: 'Enviado ao motorista',
      status: 'SENT_TO_DRIVER',
      icon: 'trip-sent.svg',
      color: '#6C48C2',
      entity: sentToDriver,
      meta: metaSentToDriver,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreSentToDriver,
    },
    {
      title: 'Aceito pelo motorista',
      status: 'ACCEPTED_BY_DRIVER',
      icon: 'trip-accepted.svg',
      color: '#2ECC71',
      entity: acceptedByDriver,
      meta: metaAcceptedByDriver,
      setLoad: setLoadPreparation,
      showMoreItems: showMoreAcceptedByDriver,
    },
    // {
    //   title: 'Cancelada',
    //   status: 'CANCELLED',
    //   icon: 'trip-cancelled.svg',
    //   color: '#E74C3C',
    //   entity: cancelled,
    //   meta: metaCancelled,
    //   setLoad: setLoadPreparation,
    //   showMoreItems: showMoreCancelled,
    // },
  ];

  const kanbanColumnsExecution = [
    {
      title: 'Indo carregar',
      status: 'GOING_TO_COLLECT',
      icon: 'trip-going.svg',
      color: '#6C48C2',
      entity: goingToCollect,
      meta: metaGoingToCollect,
      setLoad: setLoadExecution,
      showMoreItems: showMoreGoingToCollect,
    },
    {
      title: 'Carregando',
      status: 'READ_TO_COLLECT',
      icon: 'trip-collect.svg',
      color: '#6C48C2',
      entity: readToCollect,
      meta: metaReadToCollect,
      setLoad: setLoadExecution,
      showMoreItems: showMoreReadToCollect,
    },
    {
      title: 'Carga carregada',
      status: 'COLLECTING',
      icon: 'trip-collecting.svg',
      color: '#6C48C2',
      entity: collecting,
      meta: metaCollecting,
      setLoad: setLoadExecution,
      showMoreItems: showMoreCollecting,
    },
    {
      title: 'Pronto para iniciar',
      status: 'READ_TO_TRAVEL',
      icon: 'trip-travel.svg',
      color: '#6C48C2',
      entity: readToTravel,
      meta: metaReadToTravel,
      setLoad: setLoadExecution,
      showMoreItems: showMoreReadToTravel,
    },
    {
      title: 'Em rota',
      status: 'TRAVELLING',
      icon: 'trip-travelling.svg',
      color: '#6C48C2',
      entity: travelling,
      meta: metaTravelling,
      setLoad: setLoadExecution,
      showMoreItems: showMoreTravelling,
    },
    {
      title: 'Parada',
      status: 'HALTED',
      icon: 'trip-halted.svg',
      color: '#6C48C2',
      entity: halted,
      meta: metaHalted,
      setLoad: setLoadExecution,
      showMoreItems: showMoreHalted,
    },
  ];

  const kanbanColumnsDelivery = [
    {
      title: 'Chegada',
      status: 'ARRIVED_AT_DESTINATION',
      icon: 'trip-arrived.svg',
      color: '#6C48C2',
      entity: arrivedAtDestination,
      meta: metaArrivedAtDestination,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreArrivedAtDestination,
    },
    {
      title: 'Descarregando',
      status: 'UNLOADING',
      icon: 'trip-unloading.svg',
      color: '#6C48C2',
      entity: unloading,
      meta: metaUnloading,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreUnloading,
    },
    {
      title: 'Entrega recusada',
      status: 'DELIVER_REFUSED',
      icon: 'trip-deliver.svg',
      color: '#6C48C2',
      entity: deliverRefused,
      meta: metaDeliverRefused,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreDeliverRefused,
    },
    {
      title: 'Assintatura feita',
      status: 'DELIVER_SIGNATURE_COLLECTED',
      icon: 'trip-signature.svg',
      color: '#6C48C2',
      entity: deliverSignatureCollected,
      meta: metaDeliverSignatureCollected,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreDeliverSignatureCollected,
    },
    {
      title: 'Assinatura recusada',
      status: 'DELIVERED_WITH_SIGNATURE',
      icon: 'trip-signature-off.svg',
      color: '#6C48C2',
      entity: deliverSignatureRefused,
      meta: metaDeliverSignatureRefused,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreDeliverSignatureRefused,
    },
    {
      title: 'Completa',
      status: 'COMPLETED',
      icon: 'trip-completed.svg',
      color: '#6C48C2',
      entity: completed,
      meta: metaCompleted,
      setLoad: setLoadDelivery,
      showMoreItems: showMoreCompleted,
    },
  ];

  const kanbanColumnsInvoicing = [
    {
      title: 'Pagamento liberado',
      status: 'PAYMENT_ALLOWED',
      icon: 'trip-payment.svg',
      color: '#6C48C2',
      entity: paymentAllowed,
      meta: metaPaymentAllowed,
      setLoad: setLoadInvoicing,
      showMoreItems: showMorePaymentAllowed,
    },
    {
      title: 'Pagamento aprovado',
      status: 'PAID',
      icon: 'trip-paid.svg',
      color: '#6C48C2',
      entity: paid,
      meta: metaPaid,
      setLoad: setLoadInvoicing,
      showMoreItems: showMorePaid,
    },
    {
      title: 'Pagamento cancelado',
      status: 'PAID_CANCELLED',
      icon: 'trip-payment-off.svg',
      color: '#6C48C2',
      entity: paidCancelled,
      meta: metaPaidCancelled,
      setLoad: setLoadInvoicing,
      showMoreItems: showMorePaidCancelled,
    },
  ];

  const kanbanColumns = {
    Preparação: kanbanColumnsPreparation,
    Execução: kanbanColumnsExecution,
    Entrega: kanbanColumnsDelivery,
    Faturamento: kanbanColumnsInvoicing,
  };

  const setCurrentTab = (kanbanTab) => {
    setKanbanTab(kanbanTab);
  };

  const fetchKanbanTabData = async () => {
    const fetchDataForTab = (requestsArray) => {
      requestsArray.forEach((request) => {
        fetchData(request.api, request.setter, request.metaSetter, request.currentPage, 10);
      });
    };

    try {
      const tabDataMap = {
        Preparação: [
          {
            api: requests.requestRefusedByDriver,
            setter: setRefusedByDriver,
            metaSetter: setMetaRefusedByDriver,
            currentPage: metaRefusedByDriver.current_page,
          },
          { api: requests.requestDraft, setter: setDraft, metaSetter: setMetaDraft, currentPage: metaDraft.current_page },
          { api: requests.requestCreated, setter: setCreated, metaSetter: setMetaCreated, currentPage: metaCreated.current_page },
          {
            api: requests.requestSentToDriver,
            setter: setSentToDriver,
            metaSetter: setMetaSentToDriver,
            currentPage: metaSentToDriver.current_page,
          },
          {
            api: requests.requestAcceptedByDriver,
            setter: setAcceptedByDriver,
            metaSetter: setMetaAcceptedByDriver,
            currentPage: metaAcceptedByDriver.current_page,
          },
          // {
          //   api: requests.requestCancelled,
          //   setter: setCancelled,
          //   metaSetter: setMetaCancelled,
          //   currentPage: metaCancelled.current_page,
          // },
        ],
        Execução: [
          {
            api: requests.requestGoingToCollect,
            setter: setGoingToCollect,
            metaSetter: setMetaGoingToCollect,
            currentPage: metaGoingToCollect.current_page,
          },
          {
            api: requests.requestReadToCollect,
            setter: setReadToCollect,
            metaSetter: setMetaReadToCollect,
            currentPage: metaReadToCollect.current_page,
          },
          {
            api: requests.requestCollecting,
            setter: setCollecting,
            metaSetter: setMetaCollecting,
            currentPage: metaCollecting.current_page,
          },
          {
            api: requests.requestReadToTravel,
            setter: setReadToTravel,
            metaSetter: setMetaReadToTravel,
            currentPage: metaReadToTravel.current_page,
          },
          {
            api: requests.requestTravelling,
            setter: setTravelling,
            metaSetter: setMetaTravelling,
            currentPage: metaTravelling.current_page,
          },
          { api: requests.requestHalted, setter: setHalted, metaSetter: setMetaHalted, currentPage: metaHalted.current_page },
        ],
        Entrega: [
          {
            api: requests.requestArrivedAtDestination,
            setter: setArrivedAtDestination,
            metaSetter: setMetaArrivedAtDestination,
            currentPage: metaArrivedAtDestination.current_page,
          },
          {
            api: requests.requestUnloading,
            setter: setUnloading,
            metaSetter: setMetaUnloading,
            currentPage: metaUnloading.current_page,
          },
          {
            api: requests.requestDeliverRefused,
            setter: setDeliverRefused,
            metaSetter: setMetaDeliverRefused,
            currentPage: metaDeliverRefused.current_page,
          },
          {
            api: requests.requestDeliverSignatureCollected,
            setter: setDeliverSignatureCollected,
            metaSetter: setMetaDeliverSignatureCollected,
            currentPage: metaDeliverSignatureCollected.current_page,
          },
          {
            api: requests.requestDeliverSignatureRefused,
            setter: setDeliverSignatureRefused,
            metaSetter: setMetaDeliverSignatureRefused,
            currentPage: metaDeliverSignatureRefused.current_page,
          },
          {
            api: requests.requestCompleted,
            setter: setCompleted,
            metaSetter: setMetaCompleted,
            currentPage: metaCompleted.current_page,
          },
        ],
        Faturamento: [
          {
            api: requests.requestPaymentAllowed,
            setter: setPaymentAllowed,
            metaSetter: setMetaPaymentAllowed,
            currentPage: metaPaymentAllowed.current_page,
          },
          { api: requests.requestPaid, setter: setPaid, metaSetter: setMetaPaid, currentPage: metaPaid.current_page },
          {
            api: requests.requestPaidCancelled,
            setter: setPaidCancelled,
            metaSetter: setMetaPaidCancelled,
            currentPage: metaPaidCancelled.current_page,
          },
        ],
      };

      const requestsArray = tabDataMap[kanbanTab];
      if (requestsArray) {
        fetchDataForTab(requestsArray);
      }
    } catch (error) {
      console.error('Erro na solicitação da API: ', error);
    }
  };

  useEffect(() => {
    fetchKanbanTabData();
  }, [filterSelected, kanbanTab]);

  const renderKanbanColumns = (column, index) => {
    return (
      <KanbanColumn
        key={index}
        entity={column.entity}
        title={column.title}
        status={column.status}
        icon={column.icon}
        color={column.color}
        meta={column.meta}
        tab={kanbanTab}
        update={action}
        setUpdate={setAction}
        setLoad={column.setLoad}
        showMoreItems={column.showMoreItems}
        isLoadingItems={isLoadingItems}
        reloadKanban={fetchKanbanTabData}
      />
    );
  };

  return (
    <>
      <Tabs position="relative" variant="unstyled" w="100%">
        <Card
          m="10px"
          h="calc(100vh - 200px)"
          header={
            <Flex w="full" align="center" justify="space-between" position="relative">
              <TabList
                w="full"
                justifyContent="space-between"
                height={viewport < 900 ? '120px' : '65px'}
                flexDirection={viewport < 900 ? 'column-reverse' : 'row'}
                alignItems="center">
                <Flex w={viewport < 1100 ? '300px' : 'auto'} order={{ md: 'initial', sm: '2' }}>
                  {tabOpt.map((tabName) => (
                    <Tab key={tabName} color="#BEBEBE" _selected={{ color: '#6C48C2' }} onClick={() => setCurrentTab(tabName)}>
                      {tabName}
                    </Tab>
                  ))}
                </Flex>

                <TravelManagementPanelTabs tab={tab} setTab={setTab} />

                <TabIndicator
                  bottom={0}
                  height="2px"
                  bg="#6C48C2"
                  borderRadius="1px"
                  visibility={viewport < 900 ? 'hidden' : 'visible'}
                />
              </TabList>
            </Flex>
          }>
          <ScreenLoader
            isLoading={
              kanbanTab === 'Preparação'
                ? loadPreparation
                : kanbanTab === 'Execução'
                ? loadExecution
                : kanbanTab === 'Entrega'
                ? loadDelivery
                : loadInvoicing
            }>
            <TabPanels>
              {tabOpt.map((tabName, index) => (
                <TabPanel key={index} overflowY="auto" p="0">
                  {kanbanTab === tabName && (
                    <Flex>{kanbanColumns[tabName].map((column, idx) => renderKanbanColumns(column, idx))}</Flex>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </ScreenLoader>
        </Card>
      </Tabs>

      <Flex className="float-button-modal">
        <Button
          bgColor="green"
          color="white"
          borderRadius="50px"
          shadow="xl"
          h="50px"
          py="15px"
          px="30px"
          onClick={() => {
            onOpen();
          }}
          _hover={{ bgColor: '#70D499' }}>
          <MdAdd />

          <Text textStyle="paragraph" ml="10px">
            Nova viagem
          </Text>
        </Button>

        <ModalFreight
          entity={false}
          isOpen={isOpen}
          onClose={onClose}
          title="Cadastro de viagem"
          subTitle="Você pode adicionar os dados e salvar sua viagem como rascunho."
          action={action}
          setAction={setAction}
          tab={kanbanTab}
          reloadKanban={fetchKanbanTabData}
        />
      </Flex>
    </>
  );
};

export default TravelKanban;
