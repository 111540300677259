import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Field, Formik } from 'formik';
import { useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import {
  Avatar,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tag,
  TagLabel,
  Text
} from '@chakra-ui/react';

import InputPhoto from './InputPhoto';

/**
 * @param {string} title - Parâmetro que define o título do Modal.
 * @param {string} subtitle - Parâmetro que define o subtítulo do Modal.
 * @param {string} accept - Parâmetro que define as extensões aceitas para upload.
 * @param {string} nameForm - Parâmetro que define a nomenclatura do formulário para envio via api.
 * @param {Function} isOpen - Parâmetro que recebe a função responsável por abrir o modal.
 * @param {Function} onClose - Parâmetro que recebe a função responsável por fechar o modal.
 * @param {Function} requestAction - Parâmetro que recebe função que é disparada ao enviar os anexos e deve retornar uma Promise.
 * @param {bool} useOwnState - Parâmetro que define se o modal deve utilizar o estado próprio para envio.
 */

const ModalUploadPhoto = ({
  title = '',
  subtitle = '',
  accept = '.jpg, .jpeg, .png',
  nameForm = 'image_upload',
  messageConfirm = 'Arquivo enviado com sucesso!',
  isOpen,
  onClose,
  useOwnState = false,
  ownState,
  setOwnState,
  requestAction = () => {},
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onSubmit = async (values, { setSubmitting }) => {
    if (useOwnState) setOwnState(true);
    else setSubmitting(true);

    if (!selectedFile) {
      toast.error('Clique e escolha uma imagem ou arraste.');
      return;
    }

    const formData = new FormData();
    formData.append(`${nameForm}[file]`, selectedFile);

    try {
      await requestAction(formData);
      toast.success(`${messageConfirm}`);
      handleCloseModal();
    } catch {
      toast.error('Falha no upload, tente novamente.');
    } finally {
      if (useOwnState) setOwnState(false);
      else setSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
    onClose();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    if (useOwnState && !ownState) {
      setSelectedFile(null);
      onClose();
    }
  }, [useOwnState, ownState, onClose]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleCloseModal} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" alignItems="flex-start" mb="8">
            <Text color="gray.500">{title}</Text>
          </Flex>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Flex mb="8">
                  <Text color="#422C76" fontSize="22px" fontWeight="bold">{subtitle}</Text>
                </Flex>
                <Flex direction="column" gap="15px" w="full">
                  <InputPhoto
                    name="file"
                    accept={accept}
                    multiple={false}
                    onChange={handleFileChange}
                  />
                </Flex>
                { selectedFile && (
                  <>
                    <Divider mt="6" borderColor="#00000047" />
                    <Flex mt="2" w="full" alignItems="center">
                      <Tag size='lg' colorScheme='red' borderRadius='full'>
                        <Avatar
                          src={URL.createObjectURL(selectedFile)}
                          size='xs'
                          name='Foto do perfil'
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel>{selectedFile.name}</TagLabel>
                      </Tag>
                    </Flex>       
                  </>
                )}
                <Flex justifyContent="flex-end" mt="4">
                  <Button
                    border="1px"
                    px="28px"
                    py="10px"
                    color="#6C48C2"
                    borderColor="#6C48C2"
                    fontSize="14px"
                    bgColor="transparent"
                    onClick={handleRemoveFile}
                    isDisabled={!selectedFile}>
                    Remover imagem
                  </Button>
                  <Button
                    border="1px"
                    ml="4"
                    px="30px"
                    py="10px"
                    color="#6C48C2"
                    borderColor="#6C48C2"
                    fontSize="14px"
                    bgColor="transparent"
                    onClick={handleCloseModal}>
                    Cancelar
                  </Button>
                  <Button
                    ml="4"
                    variant="primary"
                    px="35px"
                    py="10px"
                    fontSize="14px"
                    loadingText="Carregando..."
                    isLoading={useOwnState ? ownState : isSubmitting}
                    type="submit"
                    isDisabled={!selectedFile}>
                    Salvar
                  </Button>
                </Flex>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalUploadPhoto;
