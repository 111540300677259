import React from 'react';

import { MdCircle, MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, IconButton, Td, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import { convertColorTrip, descriptionStatusTrip, modalityIcon, modalityTitle } from '../../../../../../../../utils/translate';

const TavelListCollapse = ({ entity, driver }) => {
  let navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Tr h="64px" className="border">
        <Td border="none" title={modalityTitle(entity.modality, entity.typeExternalCode, entity.externalCode)}>
          <Box>
            <Box h="1.1rem">{modalityIcon(entity?.modality, entity.typeExternalCode, entity.externalCode)}</Box>
          </Box>
        </Td>
        <Td border="none" textColor="linkColor" textStyle="subtitle">
          <Box>#{entity?.code}</Box>
        </Td>
        <Td border="none" textStyle="subtitle" textColor="#0F0A1DB3">
          <Box>{entity?.startsAtFormatted}</Box>
        </Td>
        <Td border="none" textStyle="subtitle" textColor="#0F0A1DB3">
          <Box>{entity?.endsAtFormatted}</Box>
        </Td>
        <Td border="none" textStyle="subtitle">
          <Box>
            <Flex
              align="center"
              gap="10px"
              py="5px"
              px="20px"
              borderRadius="15px"
              bgColor={convertColorTrip(entity.status)}
              color="white"
              h="29px">
              <MdCircle size={7} />
              {descriptionStatusTrip(entity.status)}
            </Flex>
          </Box>
        </Td>
        <Td border="none" /*  w="50%"*/>
          <Flex direction="row" gap="20px" align="center" justify="flex-end">
            <Tooltip label="Detalhe da viagem">
              <Box>
                <IconButton
                  bg="transparent"
                  icon={<MdOpenInNew size={20} color="#6C48C2" />}
                  onClick={(decision) => {
                    if (decision) {
                      navigate(`/logistic/trip/detail/${entity.identifier}`, {
                        state: {
                          historyBack: `/logistic/driver/profile/${driver.identifier}`,
                        },
                      });
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default TavelListCollapse;
