import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import { limitChars } from '../../../../utils/strings';

const TaxInquiryHistoryDrawer = ({ isOpen, onClose }) => {
  const [history, setHistory] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = async () => {
    try {
      const res = await requests.historyTaxInquiry(metadata.current_page > 0 ? metadata.current_page : 1);
      setHistory(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  useEffect(() => {
    load();
  }, [action]);

  return (
    <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px" color="primary">
          Histórico
        </DrawerHeader>
        <DrawerBody>
          <Table className="table-default" w="full" p="0">
            <Thead border="0" borderBottom="1px">
              <Tr>
                <Th>Ação</Th>
                <Th>Data e hora</Th>
                <Th>Usuário</Th>
              </Tr>
            </Thead>
            <Tbody>
              {history?.map((entity, key) => (
                <Tr key={key}>
                  <Td maxW="400px">
                    <Tooltip label={entity?.description}>{entity?.description ? limitChars(entity?.description, 150) : '-'}</Tooltip>
                  </Td>
                  <Td>{entity?.createdAt ? format(new Date(entity?.createdAt), 'dd/MM/yyyy HH:mm') : '-'}</Td>
                  <Td>{entity?.userName}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {metadata.total_pages > 1 && (
            <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TaxInquiryHistoryDrawer;
