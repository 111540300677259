import React, { useEffect, useState } from 'react';

import { subYears } from 'date-fns';
import { MdOutlineHelp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';

import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { dateFormatted } from '../../../utils/data';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';
import CardNumberStatus from '../components/CardNumberStatus';

import CardAcquisitionCostsEvolution from './components/CardAcquisitionCostsEvolution';

const KPIComexPage = () => {
  const hasPermission = permissions.metricsKpiComex;

  let navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingMainChart, setIsLoadingMainChart] = useState(false);
  const [isLoadingLeadTimeTotal, setIsLoadingLeadTimeTotal] = useState(false);
  const [isLoadingLeadTimeOperation, setIsLoadingLeadTimeOperation] = useState(false);
  const [isLoadingLeadTimeTransit, setIsLoadingLeadTimeTransit] = useState(false);
  const [isLoadingLeadTimeDispatch, setIsLoadingLeadTimeDispatch] = useState(false);

  const [mainChartData, setMainChartData] = useState([]);
  const [leadTimeTotalData, setLeadTimeTotalData] = useState(0);
  const [leadTimeOperationData, setLeadTimeOperationData] = useState(0);
  const [leadTimeTransitData, setLeadTimeTransitData] = useState(0);
  const [leadTimeDispatchData, setLeadTimeDispatchData] = useState(0);

  const [filterOptions, setFilterOptions] = useState([]);

  const loadCharts = (filters, key) => {
    setIsLoading(true);
    loadLeadTimeTotal(filters);
    loadLeadTimeOperation(filters);
    loadLeadTimeTransit(filters);
    loadLeadTimeDispatch(filters);
    loadMainChart(filters);
    setIsLoading(false);
  };

  const loadLeadTimeTotal = (filters) => {
    setIsLoadingLeadTimeTotal(true);
    requests.getProcessKpiLeadTimeTotalData(filters).then((data) => {
      setLeadTimeTotalData(data.averageDays);

      setIsLoadingLeadTimeTotal(false);
    });
  };

  const loadLeadTimeOperation = (filters) => {
    setIsLoadingLeadTimeOperation(true);
    requests.getProcessKpiLeadTimeOperationData(filters).then((data) => {
      setLeadTimeOperationData(data.averageDays);

      setIsLoadingLeadTimeOperation(false);
    });
  };

  const loadLeadTimeTransit = (filters) => {
    setIsLoadingLeadTimeTransit(true);
    requests.getProcessKpiLeadTimeTransitData(filters).then((data) => {
      setLeadTimeTransitData(data.averageDays);

      setIsLoadingLeadTimeTransit(false);
    });
  };

  const loadLeadTimeDispatch = (filters) => {
    setIsLoadingLeadTimeDispatch(true);
    requests.getProcessKpiLeadTimeDispatchData(filters).then((data) => {
      setLeadTimeDispatchData(data.averageDays);

      setIsLoadingLeadTimeDispatch(false);
    });
  };

  const loadMainChart = (filters) => {
    setIsLoadingMainChart(true);
    requests.getProcessKpiMainChartData(filters).then((data) => {
      setMainChartData(data);

      setIsLoadingMainChart(false);
    });
  };

  const formatValue = (value) => {
    if (value === undefined) {
      return '';
    }
    return value.toLocaleString('pt-BR');
  };

  const getDefaultSearchPeriod = (isFormatted) => {
    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let oneYearAgo = subYears(today, 1);

    if (isFormatted) {
      today = dateFormatted(today);
      oneYearAgo = dateFormatted(oneYearAgo);
    }

    return {
      startsAt: oneYearAgo,
      endsAt: today,
    };
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComex());
  };

  useEffect(() => {
    !permissions.metricsKpiComex && navigate('/');

    getFilterOptions();
  }, []);

  return (
    <Page
      screen="comex-kpi"
      title="KPI de importação"
      breadcrumbs={[{ link: '#', title: 'Métricas' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      loadCharts={loadCharts}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}>
      <Flex gap="10px" min="100vh" mx="10px" direction={{ base: 'column', xl: 'row' }}>
        <Box display={{ base: 'grid', md: 'flex' }} gridTemplateColumns="1fr 1fr" gap="10px" flexDirection="column">
          <ScreenLoader isLoading={isLoadingLeadTimeTotal}>
            <CardNumberStatus
              text="Lead time total"
              valueTextAppended="dias"
              bgColor="linkColor"
              textColor="#FFFFFFE8"
              value={leadTimeTotalData}
              formatValue={formatValue}
              isLoading={isLoadingLeadTimeTotal}
              iconHelp={
                <Box display={{ base: 'none', xl: 'block' }}>
                  <MdOutlineHelp color="#FFFFFFE8" size={20} />
                </Box>
              }
              textHelp="Média de dias entre a data de embarque e a data de faturamento"
            />
          </ScreenLoader>

          <ScreenLoader isLoading={isLoadingLeadTimeOperation}>
            <CardNumberStatus
              text="Lead time de operação"
              valueTextAppended="dias"
              bgColor="#FFFFFF"
              textColor="linkColor"
              value={leadTimeOperationData}
              formatValue={formatValue}
              isLoading={isLoadingLeadTimeOperation}
              iconHelp={
                <Box display={{ base: 'none', xl: 'block' }}>
                  <MdOutlineHelp color="#00000040" size={20} />
                </Box>
              }
              textHelp="Média de dias entre a data de chegada e a data de faturamento"
            />
          </ScreenLoader>

          <ScreenLoader isLoading={isLoadingLeadTimeTransit}>
            <CardNumberStatus
              text="Lead time de trânsito"
              valueTextAppended="dias"
              bgColor="#FFFFFF"
              textColor="linkColor"
              value={leadTimeTransitData}
              formatValue={formatValue}
              isLoading={isLoadingLeadTimeTransit}
              iconHelp={
                <Box display={{ base: 'none', xl: 'block' }}>
                  <MdOutlineHelp color="#00000040" size={20} />
                </Box>
              }
              textHelp="Média de dias entre a data de embarque e a data de chegada"
            />
          </ScreenLoader>

          <ScreenLoader isLoading={isLoadingLeadTimeDispatch}>
            <CardNumberStatus
              text="Lead time de despacho"
              valueTextAppended="dias"
              bgColor="#FFFFFF"
              textColor="linkColor"
              value={leadTimeDispatchData}
              formatValue={formatValue}
              isLoading={isLoadingLeadTimeDispatch}
              iconHelp={
                <Box display={{ base: 'none', xl: 'block' }}>
                  <MdOutlineHelp color="#00000040" size={20} />
                </Box>
              }
              textHelp="Média de dias entre a data de chegada e a data de CI"
            />
          </ScreenLoader>
        </Box>

        <ScreenLoader isLoading={isLoadingMainChart}>
          <CardAcquisitionCostsEvolution isLoading={isLoadingMainChart} data={mainChartData} />
        </ScreenLoader>
      </Flex>
    </Page>
  );
};

export default KPIComexPage;
