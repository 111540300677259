import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ContractManagementChangeStatus = ({ isOpen, identifier, status, modality, onClose, action, setAction }) => {
  const btnRef = React.useRef();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    onOpen();
  };

  const handleClick = async () => {
    setIsLoadingData(true);

    if (status === 'EM_RASCUNHO') {
      requests
        .changeStatusContract(identifier, modality)
        .then(() => {
          toast.success(`Contrato enviado para assinaturas.`);
          setAction(action + 1);
          setIsLoadingData(false);
          onClose();
        })
        .catch((error) => {
          toast.error(`Problema ao enviar contrato para assinaturas.`);
          setIsLoadingData(false);
        });
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent p="10px 35px" borderRadius="50px">
        <ModalBody p="35px" position="relative">
          <Flex direction="column" alignItems="center" justifyContent="center">
            <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
              Você tem certeza que deseja <strong>enviar</strong> o contrato para os responsáveis assinarem?
            </Text>
            <Flex>
              <Button variant="secundary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                w="fit-content"
                borderRadius="7px"
                m="15px"
                p="25px 35px"
                isLoading={isLoadingData}
                loadingText="Salvando..."
                onClick={() => handleClick()}>
                Continuar
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ContractManagementChangeStatus;
