import React, { useRef, useState } from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

import ProfitabilityTable from './components/ProfitabilityTable';

const ProfitabilityPage = () => {
  const hasPermission = permissions.vendemmiaProfitability;

  const request = useRef(0);

  const [isLoading, setIsLoading] = useState(false);

  const [totalBilling, setTotalBilling] = useState(0);
  const [totalProfitability, setTotalProfitability] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [meta, setMeta] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = async (filters, key, page) => {
    setIsLoading(true);

    requests
      .getProfitability(filters, page)
      .then((res) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;
        setList(res.data);
        setMeta(res.meta);

        calculateTotals(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  const calculateTotals = (data) => {
    const sumTotalBilling = data.reduce((acc, { billingValue }) => {
      const formattedValue = billingValue.replace(/\./g, '').replace(',', '.');
      return acc + parseFloat(formattedValue);
    }, 0);

    const sumProfitability = data.reduce((acc, { totalProfitability }) => {
      const formattedValue = totalProfitability.replace(/\./g, '').replace(',', '.');
      return acc + parseFloat(formattedValue);
    }, 0);

    const sumPercentage = (sumProfitability * 100) / sumTotalBilling;

    setTotalBilling(sumTotalBilling);
    setTotalProfitability(sumProfitability);
    setPercentage(sumPercentage);
  };

  return (
    <Page
      screen="profitability"
      title="Análise de Rentabilidade"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo ou cliente"
      hasPermission={hasPermission}
      list={list}
      metadata={meta}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showFilters={false}>
      <ScreenLoader isLoading={isLoading}>
        <Card m="10px" p="20px">
          <Box overflow="auto" h="calc(100vh - 390px)">
            <Table w="full">
              <Thead>
                <Tr>
                  <Th>
                    <Box></Box>
                  </Th>

                  <Th>
                    <Flex direction="row" align="center">
                      <Text>Cliente</Text>
                    </Flex>
                  </Th>

                  <Th>
                    <Flex direction="row" align="center">
                      <Text>Tipo</Text>
                    </Flex>
                  </Th>

                  <Th>
                    <Flex direction="row" justify="end">
                      <Text>Valor faturado</Text>
                    </Flex>
                  </Th>

                  <Th>
                    <Flex direction="row" justify="end">
                      <Text>Valor da rentabilidade</Text>
                    </Flex>
                  </Th>

                  <Th>
                    <Flex direction="row" justify="end">
                      <Text>Percentual de rentabilidade</Text>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {list.map((entity, key) => (
                  <ProfitabilityTable key={key} entity={entity} />
                ))}
              </Tbody>
            </Table>
          </Box>

          <Table className="table-default min" mt="20px">
            <Tbody>
              <Tr>
                <Td px="24px !important" w="60px"></Td>

                <Td px="24px !important" w="900px">
                  Total
                </Td>

                <Td px="24px !important" w="200px"></Td>

                <Td px="24px !important" w="200px">
                  <Flex direction="row" justify="end">
                    {moneyFormat(totalBilling)}
                  </Flex>
                </Td>

                <Td px="24px !important" w="200px">
                  <Flex direction="row" justify="end">
                    {moneyFormat(totalProfitability)}
                  </Flex>
                </Td>

                <Td px="24px !important" w="210px">
                  <Flex>{!isNaN(percentage) ? percentage.toFixed(2) : 0}%</Flex>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          {meta.total_pages > 1 && (
            <Paginate setMetadata={setMeta} metadata={meta} action={action} setAction={setAction} showDetails={true} />
          )}
        </Card>
      </ScreenLoader>
    </Page>
  );
};

export default ProfitabilityPage;
