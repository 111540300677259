import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Image, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import cte from '../../../../../assets/svgs/cte.svg';
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';
import { modalityIcon, modalityTitle } from '../../../../../utils/translate';
import DocumentModalView from '../../../CurrentTrips/components/Documents/DocumentModalView';

const TableListCte = ({ tab, entity, setLoad }) => {
  let navigate = useNavigate();
  let hasChildren = false;

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#E52359';
    }

    return '#6C48C2';
  };

  return (
    <Tr>
      <Td>
        <Box pl="20px" pt="10px" pb="10px" bgColor="#F9F9F9">
          <>
            <Flex align="center" gap="2">
              <Image src={cte} />

              <Text ml="4" textStyle="tableTitle" fontWeight="medium" color="#422C76" fontSize="1.3rem">
                {entity?.cteData && `CT-e: ${entity?.cteData?.number}`}
              </Text>

              <Tooltip label="Detalhe da viagem">
                <Button
                  ml="4"
                  h={'32px'}
                  variant="secundary"
                  fontSize="0.7rem"
                  onClick={(decision) => {
                    if (decision) {
                      navigate(`/logistic/trip/detail/${entity?.driverFreight?.identifier}`, {
                        state: {
                          historyBack: '/logistic/trip#cte',
                        },
                      });
                    }
                  }}>
                  Viagem: #{entity?.driverFreight?.code}
                </Button>
              </Tooltip>

              {entity?.driverFreight?.externalCode && (
                <Tooltip label="Código externo (integração para rastreio)">
                  <Button cursor="auto" ml="4" h={'32px'} variant="secundary" fontSize="0.7rem">
                    Cód.: {entity?.driverFreight?.externalCode}
                  </Button>
                </Tooltip>
              )}

              <Button
                cursor="auto"
                variant="secundary"
                fontSize="0.7rem"
                h={'32px'}
                color={getColor(entity?.driverFreight?.modality)}
                borderColor={getColor(entity?.driverFreight?.modality)}>
                <Flex alignItems="center" justifyItems="center">
                  <Box h="1rem" mr="1">
                    {modalityIcon(
                      entity?.driverFreight?.modality,
                      entity?.driverFreight?.typeExternalCode,
                      entity?.driverFreight?.externalCode
                    )}
                  </Box>
                  {modalityTitle(
                    entity?.driverFreight?.modality,
                    entity.driverFreight?.typeExternalCode,
                    entity.driverFreight?.externalCode
                  )}
                </Flex>
              </Button>

              {/* <Flex align="center" justify="flex-start" h="70px !important" gap="5px">
                <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
                  <Flex
                    w="max-content"
                    maxH="30px"
                    p="7px 11px"
                    border="1px"
                    borderColor="#2ECC71"
                    color="#2ECC71"
                    direction="row"
                    align="center"
                    borderRadius="10px"
                    gap="5px">
                    Pegada CO₂: {entity?.carbonTrack?.calculated}t
                    {entity?.carbonTrack?.retired > 0 ? (
                      <Image src={processCheck} opacity={'1'} />
                    ) : (
                      <Image src={processUncheck} opacity={'1'} />
                    )}
                  </Flex>
                </Tooltip>
              </Flex> */}

              <DocumentModalView
                identifier={entity?.driverFreight?.identifier}
                code={entity.driverFreight.code}
                files={entity.files}
                isDefaultTitle={false}
              />
            </Flex>
          </>

          {entity?.timeline != null && entity?.timeline != undefined && (
            <Box pt="10px" w="99%">
              <ProgressBar mt="-15px" tab={tab} timeline={entity?.timeline} setLoad={setLoad} />
            </Box>
          )}
        </Box>
      </Td>
    </Tr>
  );
};

export default TableListCte;
