export default function validateCNPJ(cnpj) {
  // Remova caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // Verifique se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verifique dígitos verificadores
  const cnpjArray = cnpj.split('').map(Number);
  const validateDigit = (cnpj, positions) => {
    const total = positions.reduce((acc, position, index) => {
      acc += cnpj[index] * position;
      return acc;
    }, 0);
    const remainder = total % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const firstDigit = validateDigit(cnpjArray, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
  const secondDigit = validateDigit(cnpjArray, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);

  return cnpjArray[12] === firstDigit && cnpjArray[13] === secondDigit;
}
