const ReactionEmojiBarStyles = ({ children, ...props }) => {
  return (
    <style>
      {`
        [data-emoji-bar] .chakra-popover__body {
          padding: 6px;
        }

        [data-emoji-bar] .chakra-popover__body div{
          justify-content: center;
        }
      `}
    </style>
  );
};

export default ReactionEmojiBarStyles;
