// DynamicForm.js
import React, { useContext } from 'react';

import { DynamicFormContext } from './DynamicForm';

export const DynamicFormFooter = ({ children, ...props }) => {
  const formikContext = useContext(DynamicFormContext);
  return (
    <footer
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '25px 0px 0px 0px',
        borderTop: '1px solid #dfdfdf',
        marginTop: '25px',
        gap: '10px',
      }}
      {...props}>
      {children}
    </footer>
  );
};
