import React, { useEffect, useState } from 'react';

import { MdCircle, MdTune } from 'react-icons/md';
import Select from 'react-select';

import { Box, Button, Flex, Menu, MenuButton, MenuList, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import FilterOption from '../../../utils/filters/FilterOption';
import { addFilter, appendFilter, getFilter } from '../../../utils/storageFilter';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';

const Filter = ({ filterOptions = [], onChange, screen, calendarFilter, searchFilter }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filters, setFilters] = useState(() => {
    let aux = getFilter(screen) ?? {};

    delete aux.period;
    delete aux.search;

    return aux;
  });

  const isFilterPopulated = () => {
    let filter = getFilter(screen);

    delete filter.period;
    delete filter.search;

    return Object.keys(filter).length > 0;
  };

  const createSelectedChecklist = () => {
    const selectedChecklist = {};

    filterOptions.forEach((item) => {
      if (item instanceof FilterOption) {
        // const selectedChildren = item.getSelectedChildrenValue();
        const selectedChildren = item?.getFullSelectedChildrenValue();
        selectedChecklist[item.value] = selectedChildren.length > 0 ? selectedChildren : null;
      }
    });

    return selectedChecklist;
  };

  const handleSelectChange = (field, value, isMultiple) => {
    // let data = Array.isArray(value)
    //   ? value.map((row) => (typeof row === 'string' ? row : row.value || row.identifier || row.id))
    //   : [value && typeof value === 'object' ? value.value || value.identifier || value.id : value].filter(Boolean);

    let data = [];
    Array.isArray(value)
      ? value.map((row) => {
          if (typeof row === 'object' && Array.isArray(row?.children) && row?.children?.length > 0) {
            data.push(row.value || row.identifier || row.id);
            const childIdentifiers = row.children.map((child) => child.identifier);
            data.push(...childIdentifiers);
          } else if (typeof row === 'string') {
            data.push(row);
          } else {
            data.push(row.value || row.identifier || row.id);
          }
        })
      : data.push(value && typeof value === 'object' ? value.value || value.identifier || value.id : value);

    if (!isMultiple) {
      data = data.length > 0 ? data[0] : null;
    }

    addFilter(screen, field, data);
    setFilters(getFilter(screen));
  };

  const applyFilters = () => {
    const selectedChecklist = createSelectedChecklist();

    appendFilter(screen, selectedChecklist);
    setFilters(getFilter(screen), { ...selectedChecklist, ...filters });
  };

  const cleanFilters = () => {
    const selectedData = {};

    filterOptions.forEach((item) => {
      if (item.value !== 'period') {
        if (item instanceof FilterOption) {
          item.resetSelected();
          selectedData[item.value] = null;
        } else {
          handleSelectChange(item.name, [], false);
          selectedData[item.name] = null;
        }
      }
    });

    appendFilter(screen, selectedData);
    setFilters(getFilter(screen));
  };

  useEffect(() => {
    delete filters.period;
    delete filters.search;

    onChange?.(filters);
  }, [filters]);

  return (
    <>
      <Menu isOpen={isOpen} onClose={onClose}>
        <Tooltip label={!isOpen ? 'Escolha filtros' : ''}>
          <MenuButton bgColor="primary" onClick={onOpen} borderRadius="10px" w="44px" h="35px" _hover={{ bg: 'linkColor' }}>
            <Flex justify="center">
              <MdTune size={24} color="#FFFFFFB3" />
              {isFilterPopulated() && (
                <Box>
                  <MdCircle size={7} color="red" />
                </Box>
              )}
            </Flex>
          </MenuButton>
        </Tooltip>

        <style>
          {`
            [data-field="clients"] [class*="-control"] {
              max-height: 220px;
              overflow: auto;
            }
          `}
        </style>

        <MenuList w="354px" shadow="lg" borderRadius="5px" p="0">
          <ScreenLoader isLoading={!filterOptions || filterOptions.length === 0}>
            <Flex maxH="60vh" w="full" p="10px 10px 0px 10px" direction="column" gap="20px" overflowY="auto">
              {calendarFilter && (
                <Flex direction="column" gap="8px">
                  {calendarFilter}
                </Flex>
              )}

              {searchFilter && (
                <Flex direction="column" gap="8px">
                  <Text textStyle="paragraph" opacity="0.5">
                    Pesquisa por texto
                  </Text>
                  {searchFilter}
                </Flex>
              )}

              {filterOptions && filterOptions?.length > 0
                ? filterOptions.map((item, key) => {
                    if (!(item instanceof FilterOption)) {
                      return (
                        <Flex direction="column" gap="8px" key={key} data-field={item.name}>
                          <Text textStyle="paragraph" opacity="0.5">
                            {item.label}
                          </Text>

                          <Select
                            name={key}
                            placeholder="Selecione"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            isMulti={item.isMultiple}
                            options={item.options}
                            value={item?.options?.filter((option) =>
                              item.isMultiple
                                ? getFilter(screen, item.name)?.indexOf(option.value) > -1
                                : option.value === getFilter(screen, item.name)
                            )}
                            onChange={(option) => handleSelectChange(item.name, option, item.isMultiple)}
                            components={{
                              ClearIndicator: ({ innerProps }) => (
                                <Flex
                                  textColor="red"
                                  fontSize="14px"
                                  fontWeight="bold"
                                  pr="10px"
                                  cursor="pointer"
                                  _hover={{ opacity: '0.8', transition: '0.3s' }}
                                  {...innerProps}>
                                  Limpar
                                </Flex>
                              ),
                            }}
                          />
                        </Flex>
                      );
                    }
                  })
                : null}

              {filterOptions && filterOptions?.length > 0 ? (
                <Flex direction="column" px="10px">
                  <ul>
                    {filterOptions.map((item, key) => {
                      if (item instanceof FilterOption) {
                        return (
                          <li key={key} style={{ listStyle: 'none', fontSize: '18px', fontWeight: 'bold' }}>
                            {item.renderTree(getFilter(screen, item.value))}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Flex>
              ) : null}
            </Flex>

            <Flex align="center" m="0" p="0">
              {isFilterPopulated() && (
                <Button
                  variant="solid"
                  borderRadius="0"
                  w="full"
                  bgColor="#6C48C2B3"
                  color="#FFFFFF"
                  _hover={{ bg: 'linkColor' }}
                  onClick={() => {
                    cleanFilters();
                    onClose();
                  }}>
                  Limpar
                </Button>
              )}

              <Button
                variant="solid"
                borderRadius="0"
                w="full"
                bgColor="primary"
                color="#FFFFFF"
                _hover={{ bg: 'linkColor' }}
                onClick={() => {
                  applyFilters();
                  onClose();
                }}
                data-aplly-filter>
                Aplicar
              </Button>
            </Flex>
          </ScreenLoader>
        </MenuList>
      </Menu>
    </>
  );
};

export default Filter;
