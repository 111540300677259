import React from 'react';

import { Flex } from '@chakra-ui/react';

import TypingText from './TypingText';

const MessageBlock = ({ question, response }) => {
  return (
    <Flex
      direction="column"
      border="1px solid #6C48C299"
      className="up-anim"
      bg="#6C48C214"
      borderRadius="26px"
      p="40px 30px"
      gap="20px">
      <TypingText
        textStyle="subtitle"
        color="#0F0A1D"
        fontWeight="800"
        className="up-anim"
        startDelay={300}
        style={{ '--delay': '0.2s' }}
        text={question}
      />
      <TypingText
        textStyle="subtitle"
        color="#0F0A1D"
        fontWeight="500"
        className="up-anim"
        startDelay={600}
        style={{ '--delay': '0.4s' }}
        text={response}
      />
    </Flex>
  );
};

export default MessageBlock;
