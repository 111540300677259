import { Flex, ListItem, Text } from '@chakra-ui/react';

import CommonListCategories from '../CommonListCategories';
import CommonListItemLine from '../CommonListItemLine';
import { FormattedList } from '../Helpers/formattedList';

/**
* @param  rawItem necessário para o recebimento de todos os parametros do item(o item padrão vem com filtro de campos especificos)

*/

const CommonListTable = ({ ...props }) => {
  //data
  const data = props?.data;

  //default properties
  const { categories, subcategories } = props;

  const pathSublist = props?.pathSublist;

  //instance
  const listIndex = props.listIndex;

  //status
  const tags = props?.tags;
  const statusPreset = props?.statusPreset;

  let gridTemplateLength = props?.gridTemplateLength;
  /* gridTemplateLength = subcategories.length > 0 ? subcategories.map(() => '1fr').join(' ') : 'auto'; */

  //buttonDelete
  const hasDeleteButton = props?.hasDeleteButton;
  const deleteButtonAction = props?.deleteButtonAction;
  const deleteButtonSubtitle = props?.deleteButtonSubtitle;

  const children = props?.content;

  //order
  const order = props.order;

  //customButtons
  const customButtons = props?.customButtons;

  //customFields
  const customFields = props?.customFields;

  //resume
  const maxLengthResume = props?.maxLengthResume;

  const tooltipCategories = props?.tooltipCategories;

  //buttonDetails
  const hasDetailsButton = props?.hasDetailsButton;
  const detailsRedirectEndpoint = props?.detailsRedirectEndpoint;

  //responsiveness
  const isMobile = props?.isMobile;
  const deviceType = props?.deviceType;
  const viewport = props?.viewport;

  //rowSize
  const cellRowSize = props?.cellRowSize;
  const resizeRowsChildren = props?.resizeRowsChildren;

  //messages
  const emptyListMessage = props?.emptyListMessage;

  //formatação padrão
  const subList = props?.subList;

  //formatação com a remoção das categorias(hiddenCategories) no caminho especifico(pathSublist)
  const sublistPathItens = props?.sublistPathItens;

  /* const formattedPathSublist = sublistPathItens && removeKeysFromList(sublistPathItens, hiddenCategories ? hiddenCategories : []); */
  const formattedPathSublist =
    pathSublist != '' ? FormattedList(subList, subcategories, sublistPathItens) : FormattedList([data[listIndex]], subcategories);

  //receber todos os parametros, inclusive os ocultos quem vem de commonListConfig.jsx do subCategories
  const subCategoriesProps = formattedPathSublist[0];

  const identifier = subCategoriesProps?.identifier;

  //loader
  const isLoading = props?.isLoading;

  return (
    <Flex direction={'column'} gap={'15px'} p={'15px'}>
      <CommonListCategories categories={subcategories} gridTemplateLength={subcategories && resizeRowsChildren} />

      {
        pathSublist ? (
          formattedPathSublist.length > 0 ? (
            formattedPathSublist?.map((subitem, key) => (
              <ListItem key={key}>
                <CommonListItemLine
                  item={subitem}
                  content={children}
                  gridTemplateLength={subcategories && resizeRowsChildren}
                  isMobile={isMobile}
                  deviceType={deviceType}
                  viewport={viewport}
                  identifier={identifier ?? ''}
                  subCategoriesProps={subCategoriesProps}
                  categories={subcategories}
                  statusPreset={statusPreset}
                  hasDetailsButton={hasDetailsButton}
                  detailsRedirectEndpoint={detailsRedirectEndpoint}
                  hasDeleteButton={hasDeleteButton}
                  deleteButtonAction={deleteButtonAction}
                  deleteButtonSubtitle={deleteButtonSubtitle}
                  customButtons={customButtons}
                  customFields={customFields}
                  maxLengthResume={maxLengthResume}
                  tooltipCategories={tooltipCategories}
                  listIndex={listIndex}
                  tags={tags}
                  data={data[listIndex][pathSublist]}
                  cellRowSize={cellRowSize}
                  order={order}
                  isLoading={isLoading}
                />
              </ListItem>
            ))
          ) : (
            <Text alignSelf={'left'}>{emptyListMessage}</Text>
          )
        ) : subList && subList.length > 0 ? (
          subList?.map((subitem, key) => {
            return (
              <ListItem key={key}>
                <CommonListItemLine
                  item={subitem}
                  content={children}
                  gridTemplateLength={resizeRowsChildren}
                  isMobile={isMobile}
                  deviceType={deviceType}
                  viewport={viewport}
                  identifier={identifier ?? ''}
                  subCategoriesProps={subCategoriesProps}
                  categories={subcategories}
                  statusPreset={statusPreset}
                  hasDetailsButton={hasDetailsButton}
                  detailsRedirectEndpoint={detailsRedirectEndpoint}
                  hasDeleteButton={hasDeleteButton}
                  deleteButtonAction={deleteButtonAction}
                  deleteButtonSubtitle={deleteButtonSubtitle}
                  customButtons={customButtons}
                  customFields={customFields}
                  maxLengthResume={maxLengthResume}
                  tooltipCategories={tooltipCategories}
                  listIndex={key}
                  tags={tags}
                  data={data}
                  cellRowSize={cellRowSize}
                  order={order}
                  isLoading={isLoading}
                />
              </ListItem>
            );
          })
        ) : (
          <Text alignSelf={'left'}>{emptyListMessage}</Text>
        ) /* executa caso seja uma lista normal */
      }
    </Flex>
  );
};

export default CommonListTable;
