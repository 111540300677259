import { MiniCardBox } from './MiniCardBox';
import { MiniCardContent } from './MiniCardContent';
import { MiniCardHeader } from './MiniCardHeader';
import { MiniCardPercentage } from './MiniCardPercentage';
import { MiniCardRoot } from './MiniCardRoot';
import { MiniCardTotalCarbon } from './MiniCardTotalCarbon';

export const MiniCard = {
  Root: MiniCardRoot,
  Box: MiniCardBox,
  Header: MiniCardHeader,
  Content: MiniCardContent,
  TotalCarbon: MiniCardTotalCarbon,
  Percentage: MiniCardPercentage,
};
