import { toast } from 'react-toastify';

import { saveImportFile } from '../../services/requests';

/**
 * Realiza a importação de arquivos.
 * @param {string} endpoint - O endpoint da API para envio dos arquivos.
 * @param {Array} docs - A lista de documentos a serem importados.
 * @param {object} config - Configurações de importação.
 * @param {Function} setIsLoading - Função para controlar o estado de carregamento (opcional).
 * @param {Function} triggerRefresh - Função para atualizar a lista após a importação (opcional).
 */
export const importFileAction = async ({ endpoint, docs, config, setIsLoading = () => {}, triggerRefresh = () => {} }) => {
  const toastId = toast.info('Aguarde enquanto enviamos os arquivos...', {
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    closeButton: false,
  });

  setIsLoading(true);

  try {
    await saveImportFile(endpoint, docs, config);

    toast.dismiss(toastId);
    toast.success('Upload realizado com sucesso!', { autoClose: 1500 });

    triggerRefresh?.();
  } catch (error) {
    handleUploadError(toastId, error);
  } finally {
    setIsLoading(false);
  }
};

/**
 * Lida com erros durante o upload de arquivos.
 * @param {string} toastId - ID da notificação de carregamento.
 * @param {object} error - Objeto de erro capturado.
 */
const handleUploadError = (toastId, error) => {
  toast.dismiss(toastId);
  toast.error('Erro no upload. Tente novamente ou contate o administrador.');
  console.error('Erro durante a importação:', error.message || error);
};
