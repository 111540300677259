import React from 'react';

import { MdSettings } from 'react-icons/md';

import { Button, Flex, Tooltip } from '@chakra-ui/react';

const FloatActionButton = ({ saveButton, options = [], showOptions = true, isOpen, setIsOpen }) => {
  return (
    <Flex>
      <Flex position="fixed" bottom="40px" right={showOptions ? '100px' : '40px'} z-index="10000" display="flex">
        {saveButton}
      </Flex>

      {showOptions && (
        <Flex className="float-action-button">
          <Tooltip label="Opções">
            <Button onClick={() => setIsOpen(!isOpen)}>
              <MdSettings size={20} color="white" />
            </Button>
          </Tooltip>

          <Flex className="options" display={isOpen ? 'flex' : 'none'}>
            <ul style={{ display: 'flex', flexDirection: 'column-reverse', gap: '10px', alignItems: 'flex-end' }}>
              {options.map((option, key) => (
                <li
                  key={key}
                  style={{ '--delay': `${key / 7}s`, display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end' }}>
                  {option}
                </li>
              ))}
            </ul>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default FloatActionButton;
