import React from 'react';

import { Box, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

import FilterOption from '../../../../utils/filters/FilterOption';
import { addFilter, appendFilter } from '../../../../utils/storageFilter';

const OrderProgressBar = ({
  isPreview = false,
  tooltip,
  label,
  value = 0,
  max = 10.0,
  maxIsMoney = false,
  color = '#6576FF',
  originalValue = value,
  entity,
  filterOptions,
  type,
}) => {
  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  function getValue(val) {
    if (!val) {
      return 0;
    }

    if (maxIsMoney) {
      return moneyFormat(val);
    }

    return val;
  }

  function getPercent() {
    if (originalValue === 0) {
      return 0;
    }

    return ((originalValue / max) * 100.0).toFixed(2);
  }

  const handleClickCollectStatus = () => {
    const selectedData = {};

    if (entity && typeof entity.selected === 'boolean') {
      entity.selected = !entity.selected;
    }

    filterOptions.forEach((item) => {
      if (!(item instanceof FilterOption)) {
        return;
      }

      let selectedChildren = item.getFullSelectedChildrenValue();

      if (selectedChildren.length > 0) {
        selectedData[item.value] = selectedChildren;
      } else {
        selectedData[item.value] = null;
      }
    });

    appendFilter('warehouse-picking', selectedData);

    const apllyFilters = document.querySelector('[data-aplly-filter]');
    apllyFilters && apllyFilters.click();
  };

  const handleClickState = () => {
    if (label === 'Outros') {
      return;
    } else {
      addFilter('warehouse-expedition', 'uf_destinatario', label);

      const apllyFilters = document.querySelector('[data-aplly-filter]');
      apllyFilters && apllyFilters.click();
    }
  };

  return (
    <Tooltip label={tooltip ? tooltip : `${getValue(value)} de ${getValue(max)} (${getPercent()}%)`}>
      <Flex
        direction="column"
        width="100%"
        cursor={isPreview ? 'arrow' : 'pointer'}
        onClick={() => {
          if (isPreview) {
            null;
          } else {
            type === 'status' ? handleClickCollectStatus() : handleClickState();
          }
        }}>
        <Grid
          templateColumns={type === 'status' ? '2fr 3fr 50px' : '80px 1fr 130px '}
          gap={'15px'}
          justify="space-between"
          paddingLeft={'10px'}
          mb="7px"
          fontSize={'12px'}
          alignItems={'center'}>
          <Text className="up-anim" opacity=".78">
            {label}
          </Text>
          <Box width={'100%'} bg="#EFEFEF" borderRadius="8px" h="10px">
            <Box
              className="progress-anim"
              h="100%"
              transformOrigin="left"
              transition=".5s"
              borderRadius="8px"
              bg={color}
              w={`${getPercent()}%`}
            />
          </Box>
          <Text className="up-anim">{value}</Text>
        </Grid>
      </Flex>
    </Tooltip>
  );
};

export default OrderProgressBar;
