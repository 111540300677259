const yup = require('yup');

export const validationSchema = () => {
  return yup.object().shape({
    title:  yup.string(),
    description: yup.string(),
    expiresAt: yup.string(),
    expirationType: yup.string().required('Campo obrigatório'),
    responsibles: yup.string().required('Campo obrigatório'),
    tempFile:  yup.string(),
  });
};
