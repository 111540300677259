import { Text } from '@chakra-ui/react';

import CustomFields from '../../../components/CommonList/components/CustomFields';

const CustomFieldsRenderer = (props) => {
  const { customFields, data, listIndex, identifier } = props;

  if (!customFields || customFields.length === 0) return null;

  return customFields.map((field, key) => {
    const initialValue = data[listIndex][field.name];

    return (
      <Text key={`${field.name}-${key}`}>
        <CustomFields field={field} selected={initialValue} identifier={identifier} />
      </Text>
    );
  });
};

export default CustomFieldsRenderer;
