import React, { useEffect, useRef, useState } from 'react';

import { Polyline } from '@react-google-maps/api'; // Certifique-se de importar o componente correto

export const AnimatedPolyline = ({ path, hasArrow = false, reverse = false }) => {
  const [line, setLine] = useState(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (line) {
      animateCircle(line, path);
    }

    return () => {
      if (animationRef.current !== null) {
        clearInterval(animationRef.current);
      }
    };
  }, [line, path, reverse]);

  const calculateDistance = (path) => {
    let distance = 0;
    for (let i = 1; i < path.length; i++) {
      const prev = path[i - 1];
      const current = path[i];
      const dx = current.lat - prev.lat;
      const dy = current.lng - prev.lng;
      distance += Math.sqrt(dx * dx + dy * dy);
    }
    return distance;
  };

  const animateCircle = (line, path) => {
    let count = 0;
    const distance = calculateDistance(path);
    const speed = Math.max(distance / 100, 50); // Ajuste esse valor para controlar a velocidade base

    animationRef.current = window.setInterval(() => {
      count = (count + 1) % 200;

      const icons = line.get('icons');

      icons[0].offset = reverse ? 100 - count / 2 + '%' : count / 2 + '%';
      line.set('icons', icons);
    }, speed);
  };

  return (
    <Polyline
      onLoad={(polyline) => {
        setLine(polyline);
      }}
      options={{
        strokeColor: '#A885F9',
        strokeOpacity: 0.4,
        icons: [
          {
            //navio animado
            /* icon: {
              path: 'M39.732,23.911l1.213-4.221a1.4,1.4,0,0,0-.96-1.734A1.384,1.384,0,0,0,39.6,17.9H14.451V10.11a1.4,1.4,0,0,0-1.4-1.4h-.525V5.514a1.4,1.4,0,0,0-1.4-1.4h-.516V1.434A1.428,1.428,0,0,0,9.275-.006a1.4,1.4,0,0,0-1.469,1.4V4.113H7.289a1.4,1.4,0,0,0-1.4,1.4h0V8.709H5.364a1.4,1.4,0,0,0-1.4,1.4V17.9H1.4a1.4,1.4,0,0,0-1.4,1.4,1.39,1.39,0,0,0,.08.466L3.365,29.04h34.7l1.664-5.129ZM9.207,15.9A1.189,1.189,0,1,1,10.4,14.706,1.188,1.188,0,0,1,9.207,15.9Z',
              fillColor: '#6c49c2',
              fillOpacity: 1,
              scale: 0.5, // Ajuste a escala conforme necessário
              strokeColor: '#6c49c2',
              strokeWeight: 1,
              rotation: 90, // Girar o ícone para a horizontal
            }, */
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              strokeOpacity: 1,
            },
            offset: '100%',
          },
          /* hasArrow && {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeOpacity: 1,
            },
            offset: '99%',
          }, */
          {
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 4,
            },
            offset: '0',
            repeat: '15px',
          },
        ],
      }}
      path={path}
    />
  );
};
