import { memo, useCallback } from 'react';

import { ErrorMessage, Field, useFormikContext } from 'formik';

import { FormControl, FormLabel, Input, Text } from '@chakra-ui/react';

import { getMaskByKey } from '../../../utils/fields/masks/getMaskByKey';
import { applyMask } from '../Helpers/applyMask';

/**
 * Componente de campo de formulário reutilizável com suporte para máscara e validação.
 * Usa Formik para controle do estado e Chakra UI para estilização.
 *
 * @param {object} props - Propriedades do campo.
 * @param {string} name - Nome do campo no Formik.
 * @param {string} placeholder - Texto placeholder do input.
 * @param {object} inputProps - Propriedades adicionais do input.
 * @param {string|null} mask - Máscara opcional a ser aplicada ao valor do campo.
 * @returns {JSX.Element} Um campo de formulário estilizado e validado.
 */
const MemoizedDynamicInputField = ({ props }) => {
  const { setFieldValue } = useFormikContext();
  const { name, placeholder = null, mask = null, ...restProps } = props;

  /**
   * Manipula a mudança de valor no campo de input.
   * Aplica uma máscara se fornecida e atualiza o valor do campo no Formik.
   *
   * @param {Event} e - Evento de mudança (onChange) do input.
   */

  // Obter a máscara caso uma chave seja fornecida
  const resolvedMask = mask ? getMaskByKey(mask) : null;

  const handleInputChange = useCallback(
    (e) => {
      const { value } = e.target; // Valor bruto do input
      const cleanValue = resolvedMask ? value.replace(/\D/g, '') : value; // Remove caracteres não numéricos se houver máscara
      const formattedValue = resolvedMask ? applyMask(cleanValue, resolvedMask) : value; // Aplica a máscara, se houver
      setFieldValue(name, formattedValue); // Atualiza o estado do campo no Formik
    },
    [resolvedMask, name] // Dependências para garantir que a função seja memorizada corretamente
  );

  return (
    <FormControl mb={4}>
      {placeholder && <FormLabel htmlFor={name}>{placeholder}</FormLabel>}

      <Field name={name}>
        {({ field }) => (
          <Input
            {...field}
            {...restProps}
            value={resolvedMask ? applyMask(field.value || '', resolvedMask) : field.value}
            placeholder={placeholder}
            onChange={(e) => handleInputChange(e)}
          />
        )}
      </Field>

      {/* Exibe mensagem de erro, se houver, com base no nome do campo */}
      <ErrorMessage name={name} component={Text} className="error-message-error" />
    </FormControl>
  );
};

// Exporta o componente memorizado para evitar renderizações desnecessárias
export const DynamicInputField = memo(MemoizedDynamicInputField);
