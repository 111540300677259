import React, { useEffect, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const QuoteList = ({ process }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getQuotes = () => {
    if (!process || !process.identifier) {
      return;
    }

    requests
      .getProcessQuote(process.identifier)
      .then((data) => {
        setData(data.data);
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    getQuotes();
  }, [process]);

  return (
    <>
      <Flex gap="15px" align="center" justify="flex-end" mb="16px">
        <Link to="/vendemmia/quote/new">
          <Button leftIcon={<MdAdd />} variant="primary">
            <Text fontSize="14px" textColor="#FFFFFF">
              Nova cotação
            </Text>
          </Button>
        </Link>
      </Flex>

      <ScreenLoader isLoading={isLoadingData}>
        <Table className="table-default">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              <Th>Nome</Th>
              <Th>Tipo</Th>
              <Th>Carga disponível</Th>
              <Th>Origem</Th>
              <Th>Destino</Th>
              <Th>Valor invoice</Th>
            </Tr>
          </Thead>
          <Tbody border="1px">
            {data.length > 0 &&
              data.map((quote, index) => (
                <Tr key={index} border="none" cursor="pointer" style={{ '--delay': `${index / 10}s` }}>
                  <Td style={{ color: '#6C48C2' }}>
                    <Link to={`/vendemmia/quote/detail/${quote.identifier}`}>{quote.name ?? '-'}</Link>
                  </Td>
                  <Td>{quote?.transportModality ?? '-'}</Td>
                  <Td>{quote?.availableDateFormatted ?? '-'}</Td>
                  <Td>{quote?.originPortName ? quote?.originPortName + ' (' + quote?.originPortCountry + ')' : '-'}</Td>
                  <Td>{quote?.destinyPortName ? quote?.destinyPortName + ' (' + quote?.destinyPortCountry + ')' : '-'}</Td>
                  <Td>{quote?.invoiceValueFormatted ?? '-'}</Td>
                </Tr>
              ))}

            {isLoadingData ? (
              <Tr>
                <Td colSpan={6}>
                  <Flex justify="center" my="25px">
                    Carregando...
                  </Flex>
                </Td>
              </Tr>
            ) : (
              <>
                {data.length === 0 && (
                  <Tr>
                    <Td colSpan={6}>
                      <Flex justify="center" my="25px">
                        Nenhum dado encontrado para os parâmetros filtrados.
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
        </Table>
      </ScreenLoader>
    </>
  );
};

export default QuoteList;
