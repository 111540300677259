import React from 'react';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import formatMonthDay from '../../../utils/date/format/formatMonthDay';
import CustomTooltip from '../../Tooltip/customTooltip';

const tickFormatter = (tickItem) => {
  return tickItem.toLocaleString('pt-BR');
};

const StockCollectionHistoryChart = ({ data }) => {
  const formatteddata = data?.map((item) => ({
    ...item,
    date: formatMonthDay(item.date),
  }));

  return (
    <ResponsiveContainer height={440}>
      <LineChart
        data={formatteddata}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickMargin={5} />
        <YAxis yAxisId="left" orientation="left" tickFormatter={tickFormatter} tickMargin={5} />
        <YAxis yAxisId="right" orientation="right" tickFormatter={tickFormatter} tickMargin={5} />
        <Tooltip
          content={
            <CustomTooltip
              payload={data}
              tooltip={[
                {
                  name: 'Blocado',
                  dataKey: 'Blocado',
                  color: '#5289C9',
                },
                {
                  name: 'Devolução',
                  dataKey: 'Devolução',
                  color: '#f1c40f',
                },
                {
                  name: 'Expedição',
                  dataKey: 'Expedição',
                  color: '#2ECC71',
                },
                {
                  name: 'Porta Pallet',
                  dataKey: 'Porta Pallet',
                  color: '#E52359',
                },
                {
                  name: 'Recebimento',
                  dataKey: 'Recebimento',
                  color: '#422C76',
                },
              ]}
            />
          }
        />
        <Legend verticalAlign="top" />
        <Line type="monotone" dataKey="Blocado" name="Blocado" yAxisId="right" stroke="#5289C9" strokeWidth={2} />
        <Line type="monotone" dataKey="Devolução" name="Devolução" yAxisId="right" stroke="#f1c40f" strokeWidth={2} />
        <Line type="monotone" dataKey="Expedição" name="Expedição" yAxisId="right" stroke="#2ECC71" strokeWidth={2} />
        <Line type="monotone" dataKey="Porta Pallet" name="Porta Pallet" yAxisId="right" stroke="#E52359" strokeWidth={2} />
        <Line type="monotone" dataKey="Recebimento" name="Recebimento" yAxisId="right" stroke="#422C76" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default StockCollectionHistoryChart;
