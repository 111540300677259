import React, { useState } from 'react';

import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';

const ModalDeleteDocument = ({ quote = undefined, document = undefined, handleSuccess = () => {} }) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    requests
      .removeQuoteFile(quote.identifier, document.identifier)
      .then((response) => {
        setIsLoadingDelete(false);
        toast.success('Arquivo removido com sucesso!');
        handleSuccess();
      })
      .catch((error) => {
        setIsLoadingDelete(false);
        toast.error('Ocorreu um erro ao remover arquivo, atualize a página e tente novamente.');
      });
  };

  return (
    <>
      <DefaultModal
        ctaButton={<MdDeleteOutline size={20} color="#E74C3C" />}
        title={'Certeza que deseja excluir ?'}
        loading={isLoadingDelete}
        subtitle={
          <>
            Deseja mesmo excluir esse documento?
            <br />
            Ele sumirá automaticamente da lista de documentos.
          </>
        }
        buttonColor="red"
        txtButton="Confirmar"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            await handleDelete();
          }
        }}
      />
    </>
  );
};

export default ModalDeleteDocument;
