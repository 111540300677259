import React, { useState } from 'react';

import { Flex, Text, Textarea } from '@chakra-ui/react';

const AreaLimitable = ({ value, onChange, limit }) => {
  const [qtt, setQtt] = useState(0);
  const handleTextareaChange = (event) => {
    if (event.target.value.length > limit) {
      return;
    }
    setQtt(event.target.value.length);
    onChange(event.target.value);
  };
  return (
    <Flex w="100%" h="100px" position="relative">
      <Text
        pos="absolute"
        zIndex={15}
        textStyle="paragraph"
        color="#0F0A1D99"
        bottom="5px"
        right="10px">{`${qtt}/${limit}`}</Text>
      <Textarea
        w="calc(100% )"
        h="calc(100% - 40px)"
        bg="#F2F2F2"
        mt="20px"
        p="20px"
        resize="none"
        border="1px solid #7070703B"
        placeholder="Escreva sua mensagem..."
        value={value}
        zIndex={10}
        onChange={handleTextareaChange}
      />
    </Flex>
  );
};

export default AreaLimitable;
