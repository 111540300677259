import React from 'react';

import { MdHelp } from 'react-icons/md';
import { ProgressBar } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';

import { Box, Divider, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import pointStatus from '../../../../assets/svgs/trip/pointStatus.svg';
import truckIcon from '../../../../assets/svgs/trip/truckIcon.svg';
import Card from '../../../../components/Card/Card';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import TrajectoryPublic from './Trajectory/TrajectoryPublic';

const TripStatus = ({ entity, setCenter, setZoom, tripPercentage, isLoading }) => {
  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  return (
    <ScreenLoader h="auto" isLoading={isLoading}>
      <Card
        // maxH="calc(100vh - 366px)"
        header={
          <Flex w="full" h="45px" justify="space-between">
            <Flex gap="5px" align="center">
              <Box h="31px" w="3px" bgColor="#6C48C2" />

              <Text fontSize={{ sm: '18px', md: '20px' }} fontWeight="bold">
                Status da viagem
              </Text>
            </Flex>

            <Flex gap="5px" align="center">
              <Box h="9px" w="9px" bgColor="#2ECC71" borderRadius="50%" />

              <Text fontSize={{ sm: '14px', md: '16px' }}>{entity.statusReadable}</Text>
            </Flex>
          </Flex>
        }>
        <Flex h="full" direction="column" m={{ sm: '10px', md: '20px' }} gap="20px" overflow="auto">
          {tripPercentage !== 100 && (
            <Box position="relative" h="0px" m="20px">
              {/* Barra de progresso */}
              <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px" />

              {/* Ícone no início */}
              <Image src={pointStatus} position="absolute" top="-9px" left="-10px" width="20px" alt="Starting Point" />

              {/* Imagem do caminhão seguindo o progresso */}
              <Tooltip label={`${tripPercentage}%`}>
                <Image
                  src={truckIcon}
                  width="24px"
                  position="absolute"
                  top="-43px"
                  left={`calc(${tripPercentage}% - 55px)`}
                  transition="left 0.5s ease"
                  transform="rotate(90deg)"
                  alt="Truck Icon"
                  zIndex={1}
                />
              </Tooltip>

              {/* Ícone no fim */}
              <Image
                src={pointStatus}
                position="absolute"
                top="-9px"
                right="-10px"
                width="20px"
                filter={tripPercentage === 100 ? 'none' : 'grayscale(100%)'}
                alt="Ending Point"
              />
            </Box>
          )}

          {tripPercentage !== 100 && <Divider borderBottomWidth="2px" />}

          <Table fontSize={{ sm: '12px', md: '14px' }}>
            <Thead>
              <Tr>
                <Th borderBottom="none" p="0px">
                  <Box p="10px!important"></Box>
                </Th>

                <Th borderBottom="none" p="0px">
                  <Box p="10px!important">Viagem</Box>
                </Th>

                <Th borderBottom="none" p="0px">
                  <Box p="10px!important">Previsão</Box>
                </Th>
                <Th borderBottom="none" p="0px">
                  <Box p="10px!important">Efetivo</Box>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr className="border">
                <Td>
                  <Flex marginTop="0px !important" borderColor="#6C48C2 !important" p="10px !important"></Flex>
                </Td>

                <Td>
                  <Flex
                    marginTop="0px !important"
                    borderColor="#6C48C2 !important"
                    p="10px !important"
                    textColor="#6C48C2"
                    fontWeight="bold">
                    Início
                  </Flex>
                </Td>

                <Td>
                  <Flex marginTop="0px !important" borderColor="#6C48C2 !important" p="10px !important">
                    {entity?.startsAtFormatted ?? 'Data não disponível'}
                  </Flex>
                </Td>

                <Td>
                  <Flex marginTop="0px !important" borderColor="#6C48C2 !important" p="10px !important" gap="5px">
                    {entity?.startsEtdFormatted ?? 'Data não disponível'}

                    <Tooltip label={tooltipStart}>
                      <Flex h="full" justify="center">
                        <MdHelp color="#422C76" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>

              <Tr className="border">
                <Td>
                  <Flex borderColor="#6C48C2 !important" p="10px!important"></Flex>
                </Td>

                <Td>
                  <Flex borderColor="#6C48C2 !important" p="10px!important" textColor="#6C48C2" fontWeight="bold">
                    Final
                  </Flex>
                </Td>

                <Td>
                  <Flex borderColor="#6C48C2 !important" p="10px!important">
                    {entity?.endsAtFormatted ?? 'Data não disponível'}
                  </Flex>
                </Td>

                <Td>
                  <Flex borderColor="#6C48C2 !important" p="10px!important" gap="5px">
                    {entity?.endsEtdFormatted ?? 'Data não disponível'}

                    <Tooltip label={tooltipEnd}>
                      <Flex h="full" justify="center">
                        <MdHelp color="#422C76" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Divider borderBottomWidth="2px" />

          <TrajectoryPublic entity={entity} setCenter={setCenter} setZoom={setZoom} />
        </Flex>
      </Card>
    </ScreenLoader>
  );
};

export default TripStatus;
