import { useRef, useState } from 'react';

import { MdEdit } from 'react-icons/md';

import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ModalDeleteadditionalField from '../../../components/Modal/AditionalFields/components/ModalDeleteAdditionalField';
import ModalRegisterAdditionalFields from '../../../components/Modal/AditionalFields/components/ModalRegisterAdditionalFields';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

const AdditionalFieldsManagement = () => {
  const [entity, setEntity] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const actionModal = () => {
    setOpenModal(!openModal);

    if (openModal) {
      setEntity({});
    }
  };

  const hasPermission = permissions.vendemmiaAdditionalFieldsManagement;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const request = useRef(0);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    requests.listAditionalFields(filters, metadata.current_page > 0 ? metadata.current_page : 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const handleEditAdditionalFields = (data) => {
    setEntity(data);
    setOpenModal(!openModal);
  };

  return (
    <Page
      screen="additional-fields-management"
      title="Campos adicionais"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      showFilters={false}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
      allowEmptySearchString={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar campo adicional',
          text: 'Cadastro de campo adicional',
          action: (
            <ModalRegisterAdditionalFields
              entity={entity}
              actionModal={actionModal}
              openModal={openModal}
              handleSuccess={() => {
                actionModal();
                triggerRefresh();
              }}
            />
          ),
          modality: 'custom',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de campos adicionais
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} campos adicionais
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <Table className="table-default" w="100%">
            <Thead>
              <Tr>
                <Th>Título do campo</Th>
                <Th>Data do cadastro</Th>
                <Th>Data da ultima alteração</Th>
                <Th>Responsável pelo cadastro</Th>
                <Th>Última pessoa que alterou</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>

            <Tbody>
              {list.map((entity, key) => (
                <Tr key={key}>
                  <Td>{entity.label}</Td>
                  <Td>{entity.createdAtFormatted}</Td>
                  <Td>{entity.updatedAtFormatted}</Td>
                  <Td>{entity.createdBy.name}</Td>
                  <Td>{entity.lastUpdatedBy.name}</Td>
                  <Td>
                    <Flex gap="20px">
                      <Tooltip label="Editar">
                        <Button onClick={() => handleEditAdditionalFields(entity)}>
                          <MdEdit color="#422C7694" />
                        </Button>
                      </Tooltip>
                      <Tooltip label="Excluir">
                        <ModalDeleteadditionalField entity={entity} triggerRefresh={triggerRefresh} />
                      </Tooltip>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Card>
    </Page>
  );
};

export default AdditionalFieldsManagement;
