import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Flex, Image, Tooltip } from '@chakra-ui/react';

import block from '../../../../assets/images/block.svg';
import document from '../../../../assets/images/document.svg';
import goTo from '../../../../assets/images/go_to.svg';
import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';
import ModalSendTrip from '../../components/Modals/ModalSendTrip';

const ActionButtons = ({ driver, action, setAction, loadList }) => {
  const [load, setLoad] = useState(false);

  const changeStatus = (identifier, transition) => {
    setLoad(true);
    requests.changeStatusUser(identifier, transition).then(() => {
      setLoad(false);
      toast.success(`Motorista suspenso com sucesso`);
      loadList();
    });
  };
  return (
    <Flex gap="15px">
      <Tooltip label="Perfil">
        <Link to={`/logistic/driver/profile/${driver.identifier}`} /*state={driver}*/>
          <Image
            w={{ base: '20px', md: '25px' }}
            src={document}
            alt="Detalhes"
            filter="saturate(0)"
            cursor="pointer"
            transition="0.2s"
            _hover={{
              filter: 'saturate(1)',
            }}
          />
        </Link>
      </Tooltip>

      {/*<Tooltip label="Relatório">
        <Image
          w="25px"
          src={graph}
          alt="Relatório"
          filter="saturate(0)"
          cursor="pointer"
          transition="0.2s"
          _hover={{
            filter: 'saturate(1)',
          }}
        />
        </Tooltip>*/}

      <ModalSendTrip icon={goTo} enabled={driver?.status == 'ATIVO'} user={driver} />

      <DefaultModal
        label="Suspender"
        ctaButton={<Image src={block} w={{ base: '20px', md: '25px' }} />}
        title={'Certeza que deseja suspender?'}
        loading={load}
        subtitle={
          <>
            Você tem certeza que deseja suspender esse motorista?
            <br /> Seu acesso a plataforma será cancelado e ele não receberá viagens.
          </>
        }
        buttonColor="red"
        enabled={driver?.status === 'ATIVO'}
        txtButton="Suspender"
        callback={(decision) => {
          if (decision) {
            changeStatus(driver?.identifier, 'reject');
          }
        }}
      />
    </Flex>
  );
};

export default ActionButtons;
