import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';
import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: '',
  // Estrutura da tabela
  categories: [
    {
      title: 'Importação',
      /* field: 'e.originModality', */
      value: 'originModality',
      align: 'center',
    },
    {
      title: 'Nota fiscal',
      value: 'notasFormatted',
      defaultValue: 'Não encontrado',
      /* type: 'label', */
      align: 'center',
      showMore: true,
    },
    {
      title: 'Status',
      field: 'e.status',
      value: 'status',
      defaultValue: 'Não encontrado',
      variantTag: 'softDashed',
    },
    {
      title: 'OR',
      field: 'e.idOrdemRecto',
      value: 'idOrdemRecto',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Remetente',
      field: 'e.nomeRemetente',
      value: 'nomeRemetente',
    },
    {
      title: 'Destinatário',
      field: 'e.nomeDestinatario',
      value: 'nomeDestinatario',
    },
    {
      title: 'DI',
      field: 'e.idDi',
      value: 'idDi',
      align: 'center',
    },
    {
      title: 'Origem',
      field: 'e.origem',
      value: 'origem',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Armazém',
      field: 'e.ufArmazem',
      value: 'stateCodeFormatted',
      align: 'center',
    },
    {
      title: 'Início de recebimento',
      field: 'e.dtInicio',
      value: 'dtInicioFormatted',
      align: 'center',
    },
    {
      title: 'Término de recebimento',
      field: 'e.dtFim',
      value: 'dtFimFormatted',
      align: 'center',
    },
    {
      title: 'Data de conferência',
      field: 'e.conferenceAt',
      value: 'conferenceAtFormatted',
      align: 'center',
    },
    {
      title: 'Volumes',
      field: 'e.qtdVolume',
      value: 'qtdVolumeFormatted',
      align: 'center',
      /* type: 'label', */
    },
    {
      title: 'Processo',
      field: 'e.idProcesso',
      value: 'processCode',
      link: '/comex/process/detail/${processIdentifier}',
      align: 'center',
    },
    {
      title: 'Situação',
      field: 'e.situacao',
      value: 'situacao',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      align: 'center',
      defaultValue: '',
    },
  ],
  subCategories: [
    {
      title: 'Código',
      value: 'codProduto',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'descricao',
    },
    {
      title: 'Quantidade',
      value: 'qtdeEntradaFormatted',
      align: 'center',
    },
    {
      title: 'Lote',
      value: 'loteFabricacao',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Estado do produto',
      value: 'estadoText',
      align: 'left',
      variantTag: 'softDashed',
    },
  ],
  mainCategoryMobile: 'notasFormatted',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  /* identifierKey: 'processIdentifier', */
  collapsedContentType: 'table',
  tags: ['status', 'situacao', 'estadoText'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Recebido', value: 'Recebido' },
    { color: '#2ECC71', label: 'Liberado', value: 'Liberado' },
    { color: '#CCA42E', label: 'Em Recebimento', value: 'Em Recebimento' },
    { color: '#CCA42E', label: 'Aguardando NF', value: 'AGUARDANDO NF' },
    { color: '#CCA42E', label: 'Pendente', value: 'PENDENTE' },
    { color: '#E74C3C', label: 'Interrompido', value: 'Interrompido' },
    { color: '#E74C3C', label: 'Bloqueado', value: 'Bloqueado' },
    { color: '#E74C3C', label: 'Cancelada', value: 'CANCELADA' },
    { color: '#E74C3C', label: 'Bloqueada', value: 'BLOQUEADA' },
    { color: '#2ECC71', label: 'Processada', value: 'PROCESSADA' },
    { color: '#2EC5CC', label: 'Importada', value: 'IMPORTADA' },
    { color: '#CC782E', label: 'Ag. Alocação', value: 'AG. ALOCAÇÃO' },
    { color: '#422C76', label: 'Em carga', value: 'EM CARGA' },
    { color: '#B1B1B1', label: 'Digitada', value: 'DIGITADA' },
    { color: '#222222', label: 'Não definido', value: 'STATUS NÃO DEFINIDO' },
  ],
  images: ['originModality'],
  imagePreset: [
    { value: 'API', label: 'Importação via API Sênior', image: api },
    { value: 'FTP', label: 'Importação via FTP Sênior', image: ftp },
    { value: 'OUTROS', label: 'Importação via FTP Sênior', image: ftp },
  ],
  pathSublist: 'itens',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 16,
  maxLengthResumeChildren: 100,
  // Estilo
  cellRowSize: '1fr',
  resizeRows: '100px 160px 180px 100px 230px 230px 150px 120px 100px 150px 150px 150px 100px 120px 180px 220px',
  resizeRowsChildren: '180px 420px 120px 150px 180px',
};
