import React from 'react';

import { Bar, CartesianGrid, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const ChartNationalizationTaxes = ({ entity }) => {
  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.value.toLocaleString()}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  return (
    <>
      <Flex justify="center">
        <ComposedChart width={328} height={200} data={entity} margin={{ bottom: -10, left: 40, right: 5 }}>
          <CartesianGrid vertical={false} />
          <YAxis tickFormatter={tickFormatter} />
          <XAxis dataKey="tax" />
          <Tooltip content={CustomTooltip} />

          <Bar dataKey="value" barSize={26} fill="#AA8BE0" />
        </ComposedChart>
      </Flex>
    </>
  );
};

export default ChartNationalizationTaxes;
