import React, { useCallback, useEffect, useState } from 'react';

import {
  MdDescription,
  MdFlag,
  MdLocationPin,
  MdNotifications,
  MdOpenInNew,
  MdOutlineChatBubble,
  MdOutlineRemove,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import LabelWithTextH from '../../../../components/Generic/LabelWithTextH';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { descriptionStatusTrip, isCompletedTrip } from '../../../../utils/translate';

import ProcessFileCard from './ProcessFileCard';
import ProcessMessageCard from './ProcessMessageCard';
import ProcessProgressBar from './ProcessProgressBar';
import ProcessUpdatesCard from './ProcessUpdatesCard';
import { VoyageList } from './VoyageList';

const ProcessDetailStep = ({
  title,
  progress,
  infos,
  stepCount = 0,
  process,
  waypoints,
  delay = 0,
  // isBlock = false,
  updates,
  borderGreen = false,
  isLast = false,
}) => {
  let navigate = useNavigate();
  const hasPermission = permissions.logisticTripView;
  const [downloadingFile, setDownloadingFile] = useState('');
  const [percentageCompletion, setPercentageCompletion] = useState({});

  const downloadFile = useCallback((identifier, filename) => {
    setDownloadingFile(filename);

    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/${identifier}/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setDownloadingFile('');
  });

  const modalityImageSuffix = {
    Aéreo: 'air',
    Marítimo: 'nav',
    Terrestre: 'car',
  };

  const imageSuffix = modalityImageSuffix[process.transportModality];

  const fetchPercentageCompletion = async (identifier) => {
    try {
      const response = await requests.tripPercentage(identifier);
      setPercentageCompletion((prev) => ({ ...prev, [identifier]: response }));
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };


  useEffect(() => {
    if (infos) {
      infos.forEach((info) => {
        if (info?.trips) {
          info.trips.forEach((trip) => {
            if (trip?.percentageCompletion >= 0) {
              setPercentageCompletion((prev) => ({ ...prev, [trip?.identifier]: trip?.percentageCompletion }));
            } else if (trip?.identifier) {
              fetchPercentageCompletion(trip?.identifier);
            }
          });
        }
      });
    }
  }, [infos]);

  return (
    <AccordionItem border="none">
      <style>
        {`
          body .chakra-collapse {
            overflow: initial !important;
          }

          body  .chakra-accordion__item .chakra-accordion__item .chakra-accordion {
            margin: 20px 20px 0 23px;
          }

          [aria-expanded] svg.chakra-icon{
            width: 1.5em;
            height: 1.5em;
          }

          .chakra-accordion__item .chakra-accordion__item .chakra-accordion__item svg.chakra-icon {
            width: 1.1rem;
            height: 1.1rem;
          }

          [aria-expanded] svg.chakra-icon path{
            fill: #6C48C2;
          }

          .bg-color-tag button p.chakra-text{
            background: rgb(229, 35, 89);
            color: #ffffff !important;
            min-width: 13px;
            text-align: center;
            padding: 0 12px;
            border-radius: 10px;
            border: 1.5px solid #E52359;
            alignItems: center;
          }
        `}
      </style>
      <Flex flexFlow="column">
        <Flex>
          <AccordionButton p="4px 15px">
            <Flex flex="1" alignItems="center">
              <Flex data-border-green={borderGreen} minW={'41px'} justifyContent={'center'}>
                <Image
                  src={require(`../../../../assets/images/process-step-${stepCount}${
                    stepCount > 0 && stepCount < 2 && imageSuffix ? '-' + imageSuffix : ''
                  }${process?.originDescription ? '-mag' : ''}.svg`)}
                  filter={progress !== 0 ? 'saturate(1)' : 'saturate(0)'}
                  opacity={progress !== 0 ? '1' : '0.5'}
                  transition=".4s"
                  style={{ animationName: 'scale' }}
                />
              </Flex>
              <Flex justifyContent={'space-between'} w={'100%'} pr={'10px'}>
                <Text m="0 20px" fontSize={'20px'} color={'primary'} fontWeight={'bold'}>
                  {title}
                </Text>
                <Flex gap={'10px'}>
                  {updates?.alerts && updates.alerts.length > 0 && (
                    <>
                      <Flex alignItems={'center'} gap={'5px'}>
                        <MdNotifications size={15} color="#E52359" />
                        <Tooltip label={'Atualizações'}>
                          <Text
                            fontSize="12px"
                            color="#E52359"
                            minW={'13px'}
                            textAlign={'center'}
                            padding={'0 12px'}
                            borderRadius={'10px'}
                            border={'1.5px solid #E52359'}
                            alignItems={'center'}>
                            {updates.alerts.length}
                          </Text>
                        </Tooltip>
                      </Flex>
                    </>
                  )}
                  {updates?.comments && updates.comments.length > 0 && (
                    <>
                      <Flex alignItems={'center'} gap={'5px'}>
                        <MdOutlineChatBubble size={13} color="#E52359" />
                        <Tooltip label={'Comentários'}>
                          <Text
                            fontSize="12px"
                            color="#E52359"
                            minW={'13px'}
                            textAlign={'center'}
                            padding={'0 12px'}
                            borderRadius={'10px'}
                            border={'1.5px solid #E52359'}
                            alignItems={'center'}>
                            {updates.comments.length}
                          </Text>
                        </Tooltip>
                      </Flex>
                    </>
                  )}
                  {updates?.files && updates.files.length > 0 && (
                    <>
                      <Flex alignItems={'center'} gap={'5px'}>
                        <MdDescription size={15} color="#E52359" />
                        <Tooltip label={'Arquivos'}>
                          <Text
                            fontSize="12px"
                            color="#E52359"
                            minW={'13px'}
                            textAlign={'center'}
                            padding={'0 12px'}
                            borderRadius={'10px'}
                            border={'1.5px solid #E52359'}
                            alignItems={'center'}>
                            {updates?.files ? updates.files.length : '0'}
                          </Text>
                        </Tooltip>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </Flex>

        <Flex alignItems="stretch">
          <Box className="bar" w="5px" ml="32px" bg="#0F0A1D54" opacity={isLast ? 0 : 1} style={{ animationName: 'progress' }}>
            <Box
              w="5px"
              bg={process.originDescription ? '#E52359' : '#A885F9'}
              borderRadius={'100px'}
              transition=".4s"
              h={`${progress * 100}%`}
            />
          </Box>

          <Box w="calc(100% - 37px)" pb="20px">
            <AccordionPanel>
              <ul
                style={{
                  marginLeft: '23px',
                  marginRight: '20px',
                  display: 'flex',
                  flexFlow: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  animationName: 'progress',
                  gap: '10px',
                }}>
                {infos &&
                  infos.map((info, key) => {
                    return info?.value ? (
                      <li key={key} style={{ listStyle: 'none', display: 'flex', '--delay': `${delay / 10}s` }}>
                        <LabelWithTextH
                          title={info.label}
                          process={process}
                          content={info.value}
                          detailsTooltipText={info.detailsTooltipText}
                          icon={info.icon}
                          tooltipText={info.tooltipText}
                          disableDrawer={info.disableDrawer}
                        />
                      </li>
                    ) : null;
                  })}
              </ul>

              {
                //waypoints
                infos &&
                  infos.map(
                    (info, key) =>
                      info?.waypoints && (
                        <>
                          <VoyageList
                            key={key}
                            waypoints={waypoints}
                            path={'origin'}
                            icon={<MdLocationPin color="#ffffff" />}
                            title={['title']}
                            description={[{ type: 'origin', value: 'Origem:' }]}
                            labels={[{ title: 'Partida', content: 'departureAt' }]}
                            process={process}
                          />
                          <VoyageList
                            key={key}
                            waypoints={waypoints}
                            path={'voyages'}
                            icon={<MdOutlineRemove color="#ffffff" />}
                            title={['vessel', 'name']}
                            description={[{ type: 'stops' }]}
                            labels={[
                              { title: 'Chegada', content: 'arrivedAt' },
                              { title: 'Partida', content: 'departureAt' },
                              { title: 'Status', content: 'status' },
                            ]}
                            process={process}
                          />
                          <VoyageList
                            key={key}
                            waypoints={waypoints}
                            path={'destination'}
                            icon={<MdFlag color="#ffffff" />}
                            title={['title']}
                            description={[{ type: 'destination', value: 'Destino:' }]}
                            labels={[
                              { title: 'Chegada', content: 'arrivedAt' },
                              { title: 'Data esperada de chegada', content: 'arrivalExpected' },
                            ]}
                            process={process}
                          />
                        </>
                      )
                  )
              }

              {infos &&
                infos.map(
                  (info, key) =>
                    info?.trips &&
                    info?.trips?.length > 0 && (
                      <Flex
                        key={key}
                        ml="23px"
                        mt="7px"
                        mr="20px"
                        flexWrap="wrap"
                        alignItems="center"
                        gap="10px"
                        animationName="progress">
                        {info?.trips?.map((trip, key2) => (
                          <React.Fragment key={key2}>
                            <Divider />
                            <Flex direction="column" justifyItems="center" width={{ base: '100%', xl: 'calc(50% - 10px)' }}>
                              <LabelWithTextH
                                title="Viagem"
                                content={
                                  <Flex alignItems="center">
                                    #{trip?.code}
                                    {hasPermission && (
                                      <Tooltip label="Detalhe da viagem" placement="left">
                                        <IconButton
                                          bg="transparent"
                                          icon={<MdOpenInNew size={16} color="#6C48C2" />}
                                          onClick={(decision) => {
                                            if (decision) {
                                              navigate(`/logistic/trip/detail/${trip?.identifier}`, {
                                                state: {
                                                  historyBack: '/logistic/trip/timeline',
                                                },
                                              });
                                            }
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Flex>
                                }
                              />
                            </Flex>
                            <Flex direction="column" width={{ base: '100%', xl: 'calc(50% - 10px)' }}>
                              <LabelWithTextH title="Status" content={<Text>{descriptionStatusTrip(trip?.status)}</Text>} />
                            </Flex>
                            <Flex direction="column" width={{ base: '100%', xl: 'calc(50% - 10px)' }}>
                              <LabelWithTextH
                                title="Início"
                                content={`${trip?.startsEtdFormatted != null ? trip?.startsEtdFormatted : trip?.startsAtFormatted}`}
                                tooltipText={`Data ${trip?.startsEtdFormatted != null ? 'Efetiva' : 'Esperada'}`}
                              />
                            </Flex>
                            <Flex direction="column" width={{ base: '100%', xl: 'calc(50% - 10px)' }}>
                              <LabelWithTextH
                                title="Conclusão"
                                content={`${trip?.endsEtdFormatted != null ? trip?.endsEtdFormatted : trip?.endsAtFormatted ?? '-'}`}
                                tooltipText={`Data ${trip?.endsEtdFormatted != null ? 'Efetiva' : 'Esperada'}`}
                              />
                            </Flex>
                            {hasPermission && !isCompletedTrip(trip?.status) && (
                              <ProcessProgressBar
                                isPreview={false}
                                label="Andamento estimado"
                                value={percentageCompletion[trip.identifier] || 0}
                                max={100}
                                color="green"
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </Flex>
                    )
                )}

              <Accordion allowMultiple mt="20px" data-accordion-arraw>
                <Flex direction="column" gap="10px" className="bg-color-tag">
                  {infos?.length === 0 &&
                    updates?.alerts?.length === 0 &&
                    updates?.comments?.length === 0 &&
                    updates?.files?.length == 0 && (
                      <>
                        <li>
                          <LabelWithTextH content="Sem informações para listar nesta etapa." />
                        </li>
                      </>
                    )}

                  {updates?.alerts?.length > 0 && <ProcessUpdatesCard entity={updates} />}

                  {updates?.comments?.length > 0 && <ProcessMessageCard entity={updates} />}

                  {updates?.files?.length > 0 && (
                    <ProcessFileCard entity={updates} downloadFile={downloadFile} downloadingFile={downloadingFile} />
                  )}
                </Flex>
              </Accordion>
            </AccordionPanel>
          </Box>
        </Flex>
      </Flex>
    </AccordionItem>
  );
};

export default ProcessDetailStep;
