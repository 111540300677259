import React from 'react';

import { Box, Flex, Grid, Image, List, ListItem, Text, Tooltip } from '@chakra-ui/react';

import LabelWithTextH from '../../../../components/Generic/LabelWithTextH';

import { ProcessStyles } from './ProcessStyles';

/**
 *
 * @param infos Array de {value: "string", label: "string"}, onde: value obrigatório, label opcional
 * @param progress ponto flutuante entre 0 e 1 para o nível de progresso da barra.
 * @returns segmento da timeline com 1 step
 */
const ProcessTimelineStep = ({ title, progress, process, infos, stepCount = 0, delay = 0, isBlock = false, borderGreen = false }) => {
  const windowWidth = window.innerWidth;

  const getImage = (count) => {
    let transportModality = '';
    let type = process.originDescription ? '-mag' : '';
    if (stepCount == 1) {
      switch (process.transportModality) {
        case 'Marítimo':
          transportModality = '-nav';
          break;
        case 'Aéreo':
          transportModality = '-air';
          break;
        case 'Terrestre':
          transportModality = '-car';
          break;
      }
    }
    return require(`../../../../assets/images/process-step-${count}${transportModality}${type}.svg`);
  };

  const getLabel = () => {
    let label = title;
    if (stepCount == 1 && process.transportModality) {
      label = process.transportModality;
    }
    return label;
  };

  const getColor = () => {
    return process.originDescription ? '#E52359' : 'linkColor';
  };

  return (
    <Grid
      gridTemplateColumns={{ base: '120px 1fr', md: '1fr' }}
      w="100%"
      className="process-timeline"
      style={{ '--delay': `${delay / 10}s` }}>
      <ProcessStyles />

      {windowWidth > 768 ? (
        /* desktop */
        <Text textStyle="subtitle" ml="45px" fontWeight="bold" mb="-10px" color={progress != 0 ? getColor() : '#0F0A1D54'}>
          {title}
        </Text>
      ) : (
        /* mobile */
        <Flex flexDirection="column" order="3" alignItems="baseline" justifyContent="flex-start">
          <Text textStyle="subtitle" fontWeight="bold" textAlign="center" pt="10px" color={progress != 0 ? getColor() : '#0F0A1D54'}>
            {title}
          </Text>

          <List
            m={{ base: '0', md: '0 20px 0 45px' }}
            display="flex"
            flexWrap="wrap"
            justifyContent={{ base: 'initial', md: 'space-between' }}
            alignItems="center"
            animation="progress"
            gap="1px">
            {infos &&
              infos.map((info, key) => {
                return info?.value ? (
                  <ListItem key={key} display="flex" _delay={{ '--delay': `${delay / 10}s` }}>
                    <LabelWithTextH
                      title={info.label}
                      process={process}
                      content={info.value}
                      icon={info.container}
                      tooltipText={info.tooltipText}
                      disableDrawer={info.disableDrawer}
                    />
                  </ListItem>
                ) : null;
              })}
          </List>
        </Flex>
      )}

      <Flex alignItems="center" flexDirection={{ base: 'column', md: 'initial' }} order={{ base: '2', md: 'initial' }}>
        <Box
          position="absolute"
          h="2px"
          w={isBlock ? '55px' : '0px'}
          transition=".4s"
          animation="none"
          opacity="1"
          top="0"
          transform="rotate(45deg)"
          background="primary"
          zIndex="100"
          borderRadius="5px"
        />

        <Tooltip label={getLabel()}>
          <Flex data-border-green={borderGreen} minW={'41px'} justifyContent={'center'}>
            <Image
              src={getImage(stepCount)}
              filter={progress != 0 ? 'saturate(1)' : 'saturate(0)'}
              opacity={progress != 0 ? '1' : '0.5'}
              transition=".4s"
              style={{ animationName: 'scale' }}
            />
          </Flex>
        </Tooltip>

        {windowWidth > 768 ? (
          /* desktop */
          <Box className="bar" height="5px" m="3px" width="100%" bg="#0F0A1D54" style={{ animationName: 'progress' }}>
            <Box height="5px" bg={getColor()} transition=".4s" width={`${progress * 100}%`} />
          </Box>
        ) : (
          <>
            {/* mobile */}
            <Box className="bar" height="100%" minH={'65px'} m="3px" width="5px" bg="#0F0A1D54" style={{ animationName: 'progress' }}>
              <Box height={`${progress * 100}%`} bg={getColor()} transition=".4s" width="5px" />
            </Box>
          </>
        )}

        {stepCount == 4 && (
          <Tooltip label={'Entrega'}>
            <Flex minW={'41px'} justifyContent={'center'}>
              <Image
                src={getImage(stepCount + 1)}
                filter={progress == 1 ? 'saturate(1)' : 'saturate(0)'}
                opacity={progress == 1 ? '1' : '0.5'}
                transition=".4s"
                style={{ animationName: 'scale' }}
              />
            </Flex>
          </Tooltip>
        )}
      </Flex>

      {/* desktop */}
      {windowWidth > 768 && (
        <List
          m={{ base: '0', md: '0 20px 0 45px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems="center"
          animation="progress"
          gap="1px">
          {infos &&
            infos.map((info, key) => {
              return info?.value ? (
                <ListItem
                  key={key}
                  display="flex"
                  _delay={{ '--delay': `${delay / 10}s` }}
                  marginBottom={{ base: '15px', md: 'initial' }}>
                  <LabelWithTextH
                    title={info.label}
                    process={process}
                    content={info.value}
                    icon={info.container}
                    tooltipText={info.tooltipText}
                    disableDrawer={info.disableDrawer}
                  />
                </ListItem>
              ) : null;
            })}
        </List>
      )}
    </Grid>
  );
};

export default ProcessTimelineStep;
