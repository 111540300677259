import React, { useState } from 'react';

import { MdFileUpload } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';


import ModalUpload from './ModalUpload';

const ModalUploadWithButton = ({
  additional,
  setIsOpenFloatButton,
  disableAdditionalFields,
  disableMultiUpload,
  nameForm,
  uploadAction,
  title = 'Upload',
  subtitle = 'Faça o envio dos arquivos',
  useOwnState = false,
  ownState,
  setOwnState,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  return (
    <>
      <Button
        variant="primary"
        key={1}
        h="46px"
        px="24px"
        py="14px"
        color="#FFFFFF"
        borderRadius="27px"
        leftIcon={<MdFileUpload color="#FFFFFF" size={20} />}
        onClick={() => {
          setIsOpenFloatButton(false);
          actionModalUploadFile();
        }}>
        <Text>{title}</Text>
      </Button>
      <ModalUpload
        title={title}
        subtitle={subtitle}
        accept=".xml, .pdf, .png, .jpeg, .csv"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={openModalUploadFile}
        onOpen={onOpen}
        onClose={actionModalUploadFile}
        multiple={true}
        requestAction={uploadAction}
        needToSendAll={true}
        nameForm={nameForm ? nameForm : 'list'}
        useOwnState={useOwnState}
        ownState={ownState}
        setOwnState={setOwnState}
        disableAdditionalFields={disableAdditionalFields}
        disableMultiUpload={disableMultiUpload}
        additional={additional}
      />
    </>
  );
};

export default ModalUploadWithButton;