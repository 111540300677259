import React from 'react';

import { MdOutlineSimCardDownload, MdTask } from 'react-icons/md';

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';

const ProcessFileCard = ({ entity, downloadFile, downloadingFile }) => {
  return (
    <AccordionItem border="none">
      <Tooltip label="Visualizar todos os arquivos dessa etapa">
        <AccordionButton className="gray-box">
          <Box as="span" flex="1" textAlign="left">
            <Flex direction="row" align="center" gap="5px">
              <Text fontSize="12px" color="#0F0A1D99" ml="3px" mr="10px">
                {entity.files.length ? entity.files.length : '0'}
              </Text>
              {entity.files.length > 1 ? 'Arquivos' : 'Arquivo'}
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Tooltip>

      <AccordionPanel p="12px 5px" className="gray-box" mt="10px">
        <ul
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            padding: '10px',
            maxHeight: '300px',
            overflowY: 'auto',
          }}>
          {entity?.files && entity.files.length > 0 ? (
            entity.files.map((entity) => {
              return (
                <li
                  key={entity.identifier}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div>
                    <Text>{entity.uploadName}</Text>


                    <Text textStyle="paragraph" textColor="#181818" fontWeight="medium">
                      <small>
                        {entity.createdAtFormatted}
                      </small>
                    </Text>
                  </div>

                  <Tooltip label="Baixar arquivo">
                    <Button className="up-anim" onClick={() => downloadFile(entity.identifier, entity.uploadName)}>
                      {!downloadingFile || downloadingFile !== entity.identifier ? (
                        <Box>
                          <MdOutlineSimCardDownload color="#422C76" size={20} />
                        </Box>
                      ) : (
                        <MdTask />
                      )}
                    </Button>
                  </Tooltip>
                </li>
              );
            })
          ) : (
            <li>Nenhum arquivo aqui</li>
          )}
        </ul>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ProcessFileCard;
