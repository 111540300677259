import React, { useEffect, useState } from 'react';

import { getFontEmbedCSS, toPng } from 'html-to-image';
import { MdOutlineScreenshotMonitor } from 'react-icons/md';
import { toast } from 'react-toastify';

import { IconButton, Tooltip } from '@chakra-ui/react';

const ScreenshotButton = ({
  refElement = null,
  fileName = 'Captura de tela',
  label = 'Capturar uma imagem desta sessão',
  ...props
}) => {
  const [fontEmbedCss, setFontEmbedCss] = useState('');
  const [buttonVisible, setButtonVisible] = useState(true);

  useEffect(() => {
    if (refElement && refElement.current) {
      getFontEmbedCSS(refElement.current).then(setFontEmbedCss);
    }
  }, [refElement]);

  const handleScreenshot = async () => {
    if (refElement.current) {
      await document.fonts.ready;

      setButtonVisible(false);

      toPng(refElement.current, {
        backgroundColor: '#fff',
        fontEmbedCSS: fontEmbedCss,
      })
        .then((dataUrl) => {
          const link = document.createElement('a');

          link.href = dataUrl;
          link.download = `${fileName}.png`;
          link.click();

          toast.success("A captura de tela foi salva com sucesso na pasta 'Downloads.");
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao capturar a tela. Por favor, tente novamente.');
        })
        .finally(() => {
          setButtonVisible(true);
        });
    }
  };

  return (
    <Tooltip label={label} placement="left">
      <IconButton
        bgColor="transparent"
        icon={<MdOutlineScreenshotMonitor color="#422C76" />}
        onClick={() => handleScreenshot()}
        style={{ visibility: buttonVisible ? 'visible' : 'hidden' }}
        {...props}
      />
    </Tooltip>
  );
};

export default ScreenshotButton;
