import React, { useEffect, useRef, useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { FaDownload } from 'react-icons/fa';
import { MdDelete, MdUploadFile } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Box, Button, Flex, Grid, GridItem, IconButton, Input, Text, Textarea, Tooltip } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import Layout from '../../../../components/Layout';
import Menubar from '../../../../components/MenuBar/Menubar';
import api from '../../../../services/api';
import requests from '../../../../services/requests';
import { initialValues } from '../Helpers/initialValues';
import { validationSchema } from '../Helpers/validationSchema';

const ContractManagementDetailPage = () => {
  const [attachment, setAttachment] = useState(null);
  const [attachmentName, setAttachmentName] = useState('');
  const { identifier } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [entity, setEntity] = useState({});
  let fileInputRef = useRef(null);
  let navigate = useNavigate();
  const expirationOptions = [
    { value: 'NA_DATA', label: 'Na data' },
    { value: 'RENOVACAO_COMPULSORIA', label: 'Renovação compulsória' },
  ];
  const getContract = () => {
    setIsLoading(true);

    requests.getContract(identifier).then((data) => {
      setEntity(data);
      setAttachmentName(data?.fileName);
      setIsLoading(false);
    });
  };

  const loadUsers = () => {
    let aux = [];
    api
      .get(`/contract/users`, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
        params: { clients: aux },
      })
      .then((res) => {
        let options = res?.data.map((data) => {
          return {
            value: data.identifier,
            label: data.name,
          };
        });

        setUserOptions(options);
      });
  };

  const saveUpload = async (files) => {
    Object.entries(files).forEach(([key, file]) => {
      setAttachment(file);
      const fileName = file.name;
      setAttachmentName(fileName);
    });
  };

  const handleDelete = () => {
    setAttachment(null);
    setAttachmentName('');
  };

  const handleDownload = (identifier, uploadName, d4SignFilePathSigned) => {
    const modality = d4SignFilePathSigned != null && d4SignFilePathSigned !== undefined ? 'signed' : 'unsigned';
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/contract/${identifier}/download/${modality}`).replace('//open', '/open');
    link.target = '_blank';
    link.download = uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    loadUsers();

    if (identifier !== undefined) {
      getContract(identifier);
    }
  }, []);

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  return (
    <>
      <Layout>
        <Menubar
          linkTree={[
            { link: '#', title: 'Vendemmia' },
            { link: '/vendemmia/contract-management', title: 'Gestão de contratos' },
          ]}
          title={entity.identifier ? 'Detalhes do contrato' : 'Novo contrato'}
          hideAllFilters={true}
        />
        <Box>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues(entity)}
            validationSchema={validationSchema()}
            onSubmit={(values, { setFieldError, setSubmitting }) => {
              setSubmitting(true);

              if (identifier === undefined && attachment === null) {
                setFieldError('tempFile', 'É necessário selecionar um arquivo.');
                setSubmitting(false);
                return;
              }

              const config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              };

              const aux = [];

              if (values?.responsibles?.length > 0) {
                values?.responsibles.map((responsible) => {
                  aux.push(responsible?.value);
                });
              }

              const formData = new FormData();
              formData.append('title', values?.title);
              formData.append('description', values?.description);
              formData.append('expiresAt', values?.expiresAt);
              formData.append('expirationType', values?.expirationType?.value);
              formData.append('status', 'EM_RASCUNHO');
              formData.append('responsibles', JSON.stringify(aux));

              if (attachment !== null) {
                formData.append('tempFile', attachment);
              }

              if (entity?.identifier) {
                requests
                  .editContract(entity.identifier, formData, config)
                  .then(() => {
                    toast.success(`Contrato editado com sucesso`);
                    setSubmitting(false);

                    setTimeout(() => {
                      navigate('/vendemmia/contract-management');
                    }, 1000);
                  })
                  .catch((error) => {
                    toast.error(`Problema ao editar contrato`);
                    setSubmitting(false);
                  });
              } else {
                requests
                  .addContract(formData, config)
                  .then(() => {
                    toast.success(`Contrato cadastrado com sucesso`);
                    setSubmitting(false);

                    setTimeout(() => {
                      navigate('/vendemmia/contract-management');
                    }, 1000);
                  })
                  .catch((error) => {
                    toast.error(`Problema ao cadastrar contrato`);
                    setSubmitting(false);
                  });
              }
            }}>
            {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting, errors }) => (
              <form id="form" onSubmit={handleSubmit}>
                <Card
                  m="10px"
                  header={
                    <>
                      <Flex gap="2px" direction="column">
                        <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                          Contrato
                        </Text>
                        {entity.identifier && (
                          <>
                            <Flex gap="2px">
                              <Text textStyle="subtitle" color="#BEBEBE" pt="3px">
                                Título:
                              </Text>
                              <Text textStyle="cardTitle" fontWeight="bold" color="primary" ml="5px">
                                {entity?.title}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </>
                  }>
                  <Flex direction="column" pl="3.75rem" pr="3.75rem" pt="1.875rem" pb="1.875rem">
                    <Text mb="4" color="secondary">
                      Contrato
                    </Text>
                    <Flex gap="1.875rem" direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column">
                        <LabelDefault name="title" text="Título" />
                        <InputDefault
                          width={{ base: '15.5rem', md: '37.5rem' }}
                          setFieldValue={setFieldValue}
                          value={values.title}
                          disabled={!(identifier === undefined || entity?.status === 'EM_RASCUNHO')}
                          name="title"
                          placeholder="Preencha o título"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="title" />
                      </Flex>
                      <Box>
                        <LabelDefault name="tempFile" text="Anexar documento" />
                        <Flex gap="1.25rem">
                          <Input
                            className="input-register"
                            width={{ base: '15.5rem', md: '18.75rem' }}
                            variant="primary"
                            type="text"
                            disabled={true}
                            name="tempFile"
                            value={attachmentName}
                          />
                          <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept=".docx, .pdf, .jpg, .png"
                            disabled={!(identifier === undefined || entity?.status === 'EM_RASCUNHO')}
                            onChange={(e) => saveUpload(e.target.files)}
                            ref={(input) => (fileInputRef = input)}
                          />

                          {(identifier === undefined || entity?.status === 'EM_RASCUNHO') && (
                            <Tooltip label="Anexar">
                              <IconButton
                                icon={<MdUploadFile size={24} color="#6C48C2" />}
                                onClick={() => {
                                  fileInputRef && fileInputRef.click();
                                }}
                              />
                            </Tooltip>
                          )}

                          {identifier !== undefined && entity?.status !== 'EM_RASCUNHO' && (
                            <Tooltip label="Download contrato">
                              <Box>
                                <IconButton
                                  bg="transparent"
                                  icon={<FaDownload size={23} color="#6C48C2" />}
                                  onClick={(decision) => {
                                    if (decision) {
                                      handleDownload(entity?.identifier, entity?.fileName, entity?.d4SignFilePathSigned);
                                    }
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          )}

                          {attachment != null && (
                            <Tooltip label="Excluir">
                              <IconButton
                                icon={<MdDelete size={24} color="#6C48C2" />}
                                isLoading={false}
                                onClick={() => handleDelete()}
                              />
                            </Tooltip>
                          )}
                        </Flex>
                        {errors?.tempFile && <Text className="error-message-error">{errors.tempFile}</Text>}
                      </Box>
                    </Flex>

                    <Flex direction="column" mt="6">
                      <LabelDefault name="description" text="Descrição" />

                      <Textarea
                        name="description"
                        disabled={!(identifier === undefined || entity?.status === 'EM_RASCUNHO')}
                        width={{ base: '15rem', md: '66.25rem' }}
                        height="11.25rem"
                        resize="none"
                        bg="#F2F2F2"
                        mt="1"
                        value={values.description}
                        onChange={(event) => setFieldValue('description', event.target.value)}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="description" />
                    </Flex>

                    <Flex mt="8" mb="2">
                      <Text>Validade</Text>
                    </Flex>

                    <Flex gap="1.875rem" direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column">
                        <LabelDefault name="expirationType" text="Tipo de vencimento" />
                        <Select
                          options={expirationOptions}
                          width={{ base: '7rem', md: '8.125rem' }}
                          styles={selectCustomStyles()}
                          id="expirationType"
                          name="expirationType"
                          value={values.expirationType}
                          onChange={(option) => setFieldValue('expirationType', option)}
                          isDisabled={!(identifier === undefined || entity?.status === 'EM_RASCUNHO')}
                          className="input-register"
                          placeholder="Selecione tipo de vencimento"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="expirationType" />
                      </Flex>

                      <Flex direction="column">
                        <LabelDefault name="expiresAt" text="Data de expiração/renovação" />

                        <Input
                          type="date"
                          name="expiresAt"
                          cursor="pointer"
                          bg="#F2F2F2"
                          borderColor="#70707033"
                          disabled={!(identifier === undefined || entity?.status === 'EM_RASCUNHO')}
                          value={values.expiresAt}
                          onChange={handleChange}
                        />
                        <ErrorMessage component="p" className="error-message-error" name="expiresAt" />
                      </Flex>
                    </Flex>
                    <Grid templateColumns="repeat(1, 1fr)" gap="40px" mt="40px">
                      <GridItem colSpan={2}>
                        <Flex direction="column">
                          <Grid templateColumns="repeat(2, 1fr)" gap="40px">
                            <Flex direction="column">
                              <Text color="secondary">Partes interessadas:</Text>
                              <LabelDefault name="responsibles" text="E-mail" />

                              <Select
                                styles={selectCustomStyles()}
                                name="responsibles"
                                value={values.responsibles}
                                className="input-register"
                                placeholder="Selecone os e-mails"
                                onChange={(option) => {
                                  setFieldValue('responsibles', option);
                                }}
                                options={userOptions}
                                isDisabled={!(identifier === undefined || entity?.status === 'EM_RASCUNHO')}
                                isMulti={true}
                                isClearable={true}
                                menuPlacement="top"
                              />

                              <ErrorMessage component="p" className="error-message-error" name="responsibles" />
                            </Flex>
                          </Grid>
                        </Flex>
                      </GridItem>
                    </Grid>
                  </Flex>
                  <Flex justify="flex-end" p="20px">
                    {(identifier === undefined || entity?.status === 'EM_RASCUNHO') && (
                      <Button variant="primary" type="submit" isLoading={isSubmitting} loadingText="Salvando...">
                        Salvar
                      </Button>
                    )}
                  </Flex>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </Layout>
    </>
  );
};

export default ContractManagementDetailPage;
