import React from 'react';

import { Box, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';

import ConsolidationModality from './ConsolidationModality';

const ConsolidationTable = ({ list, viewport }) => {
  if (!list || !list.result) {
    return;
  }

  return (
    <Box>
      <Table minW="1500px">
        <Thead>
          <Tr h="60px">
            <Th></Th>

            <Th></Th>

            <Th textAlign="center">
              <Text>Faturado</Text>
            </Th>

            <Th textAlign="center">
              <Text>A faturar</Text>
            </Th>

            <Th textAlign="center">
              <Text>Orçado</Text>
            </Th>

            <Th textAlign="center">
              <Text>Diferença</Text>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {Object.keys(list.result.data).map((modality, index) => (
            <ConsolidationModality list={list} modality={modality} index={index} key={index} viewport={viewport}/>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ConsolidationTable;
