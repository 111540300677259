import { Flex, Image, Text } from '@chakra-ui/react';

import { iconMia } from '../../utils/exports';

export function CommentsHeader({ userName, stageDescription } = props) {
  return (
    <Flex as={'header'} gap="10px" align="center">
      <Text color="primary" fontSize="19px" fontWeight="700" display={'flex'} gap={'3px'} textTransform={'uppercase'}>
        {userName} {userName === 'A.I. Mia' && <Image src={iconMia} h={'20px'} w={'20px'} mt={'2px'} />}
      </Text>

      <Text bg="#A885F9" color="white" fontSize="12px" borderRadius="5px" p="4px 25px">
        {'Autor'}
      </Text>

      <Text bg="#E52359" color="white" fontSize="12px" borderRadius="5px" p="4px 25px">
        {stageDescription}
      </Text>
    </Flex>
  );
}
