import React, { useRef, useState } from 'react';

import { StandaloneSearchBox } from '@react-google-maps/api';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdAdd, MdDeleteOutline, MdMenu } from 'react-icons/md';
import Select from 'react-select';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Icon,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';

import InputDefault from '../../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../../components/Form/Label/LabelDefault';

const CardTrip = ({
  values,
  setFieldValue,
  errors,
  setErrors,
  setFieldError,
  validatedForm,
  setStep,
  filterOptions,
  clientList,
  geolocationList,
  setGeolocationList,
  docsTrajectoriesSelected,
}) => {
  const searchBoxRef = useRef(null);
  const [searchBoxes, setSearchBoxes] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const handleAddField = (values = undefined, setFieldValue = undefined) => {
    let aux = values.trip;
    aux.push({
      type: '',
      cteNumber: '',
      address: '',
      observation: '',
      latitude: null,
      longitude: null,
    });
    setFieldValue('trip', aux);
  };

  const handleRemoveField = (values = undefined, index = undefined) => {
    let aux = Array.from(values.trip);
    aux.splice(index, 1);
    setFieldValue('trip', aux);

    const updatedGeolocationList = { ...geolocationList };
    delete updatedGeolocationList[index];
    setGeolocationList(updatedGeolocationList);
  };

  const handleSearchBoxLoad = (ref, currentIndex) => {
    searchBoxRef.current = ref;
    setSearchBoxes((prevSearchBoxes) => {
      const newSearchBoxes = [...prevSearchBoxes];
      newSearchBoxes[currentIndex] = ref;
      return newSearchBoxes;
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = Array.from(values.trip);
    const [reorderedItem] = reorderedItems.splice(result?.source?.index, 1);
    reorderedItems.splice(result?.destination?.index, 0, reorderedItem);
    setFieldValue('trip', reorderedItems);

    let aux = { ...geolocationList };

    for (let key in reorderedItems) {
      aux[key] = {
        address: reorderedItems[key]['address'],
        position: {
          latitude: reorderedItems[key]['latitude'],
          longitude: reorderedItems[key]['longitude'],
        },
      };
    }

    setGeolocationList(aux);
  };

  const handleSelectChange = (value, name, setFieldValue) => {
    const selectedValues = Array.isArray(value) ? value : [value];
    const lastIndex = selectedValues?.length - 1;
    const selectedChildren = selectedValues.flatMap((item, key) => {
      if (key === lastIndex && item?.children) {
        return [
          item,
          ...item?.children?.map((child) => ({
            label: ` ${'\u00A0'} - ${child.name} (${child.documentNumberFormatted})`,
            value: child.identifier,
          })),
        ];
      } else {
        // Se não for o último item, retorna apenas o item sem mapear os filhos
        return item;
      }
    });

    setFieldValue(name, selectedChildren);
  };

  const onSubmit = async () => {
    // Limpando a variável de erros
    setErrors({});

    if (await validatedForm(values, setFieldError, 'tripSchema', errors)) {
      setStep(2);
    } else {
      setShowErrors(true);
    }
  };

  return (
    <>
      <Flex direction="column" gap="20px" mx="3px">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box ref={provided?.innerRef} {...provided?.droppableProps}>
                {values?.trip?.map((field, index) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided?.draggableProps}>
                        <Accordion allowToggle defaultIndex={Array.from({ length: values.trip?.length }, (_, i) => i)}>
                          <AccordionItem>
                            <AccordionButton _expanded={{ bgColor: 'gray-100', color: '#422C76' }}>
                              <Flex w="full" alignItems="center" height="35px" justifyContent="space-between">
                                <Box
                                  {...provided?.dragHandleProps}
                                  style={{
                                    display: 'inline-flex',
                                    cursor: 'grab',
                                    alignItems: 'center',
                                  }}>
                                  <Icon as={MdMenu} size={22} mr={2} color="#422C76" />
                                  <LabelDefault name="type" text={`Trecho ${index + 1}`} />
                                </Box>

                                <Flex>
                                  {index > 0 && (
                                    <Tooltip label={`Excluir trecho ${index + 1}`}>
                                      <Box h="20px">
                                        <Icon
                                          as={MdDeleteOutline}
                                          color="#AFAEB4"
                                          w="20px"
                                          h="20px"
                                          cursor="pointer"
                                          _hover={{ color: '#E74C3C' }}
                                          onClick={(decision) => {
                                            decision.stopPropagation(); // Impede que o evento se propague para o AccordionButton
                                            if (decision) {
                                              handleRemoveField(values, index);
                                            }
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                                  <AccordionIcon color="#AFAEB4" _hover={{ color: '#422C76' }} />
                                </Flex>
                              </Flex>
                            </AccordionButton>

                            <AccordionPanel>
                              <Flex
                                direction={{ sm: 'column', md: 'row' }}
                                gap={{ sm: '10px', md: '0' }}
                                justifyContent="space-between">
                                <FormControl w="auto">
                                  <Flex align="center" gap="8px">
                                    <LabelDefault name="type" text="Tipo do trecho" />
                                  </Flex>
                                  <Select
                                    options={[
                                      {
                                        label: 'COLETA',
                                        value: 'COLETA',
                                      },
                                      {
                                        label: 'ENTREGA',
                                        value: 'ENTREGA',
                                      },
                                    ]}
                                    name={`trip[${index}].type`}
                                    value={values.trip[index]?.type}
                                    onChange={(option) => setFieldValue(`trip[${index}].type`, option)}
                                    className="input-register"
                                    placeholder="Selecione tipo"
                                  />
                                  {showErrors && errors?.trip && errors.trip[index]?.type && (
                                    <Text className="error-message-error">{errors.trip[index].type}</Text>
                                  )}
                                </FormControl>
                                <FormControl w="auto">
                                  <LabelDefault
                                    name="cteNumber"
                                    text={
                                      <Flex gap="4px" alignItems="center">
                                        <Text>Arquivos do trecho</Text>
                                      </Flex>
                                    }
                                  />
                                  <Select
                                    placeholder="Selecione"
                                    options={docsTrajectoriesSelected}
                                    isMulti={true}
                                    name={`trip[${index}].cteNumber`}
                                    value={docsTrajectoriesSelected?.filter((option) => {
                                      if (typeof values.trip[index]?.cteNumber === 'string') {
                                        return option.value === values.trip[index]?.cteNumber;
                                      }

                                      return values.trip[index]?.cteNumber.some((item) => item?.value === option.value);
                                    })}
                                    onChange={(option) => {
                                      setFieldValue(`trip[${index}].cteNumber`, option);
                                    }}
                                    components={{
                                      ClearIndicator: ({ innerProps }) => (
                                        <Flex
                                          textColor="red"
                                          fontSize="14px"
                                          fontWeight="bold"
                                          px="3px"
                                          cursor="pointer"
                                          _hover={{ opacity: '0.8', transition: '0.3s' }}
                                          {...innerProps}>
                                          Limpar
                                        </Flex>
                                      ),
                                    }}
                                  />
                                  {showErrors && errors?.trip && errors.trip[index]?.cteNumber && (
                                    <Text className="error-message-error">{errors.trip[index].cteNumber}</Text>
                                  )}
                                </FormControl>
                              </Flex>

                              <FormControl w="100%" mt="4">
                                <LabelDefault name="address" text="Local" />
                                <StandaloneSearchBox
                                  onLoad={(ref) => handleSearchBoxLoad(ref, index)}
                                  onPlacesChanged={() => {
                                    const places = searchBoxes[index]?.getPlaces();

                                    if (places && places?.length > 0) {
                                      const place = places[0];
                                      const latitude = place?.geometry?.location?.lat();
                                      const longitude = place?.geometry?.location?.lng();
                                      setFieldValue(`trip[${index}].address`, place?.formatted_address);
                                      setFieldValue(`trip[${index}].latitude`, latitude);
                                      setFieldValue(`trip[${index}].longitude`, longitude);

                                      let aux = { ...geolocationList };

                                      aux[index] = {
                                        address: place?.formatted_address,
                                        position: {
                                          latitude,
                                          longitude,
                                        },
                                      };

                                      setGeolocationList(aux);
                                    }
                                  }}
                                  className="pac-container">
                                  <InputDefault
                                    placeholder="Preencha o endereço"
                                    name={`trip[${index}].address`}
                                    className="input-register"
                                    background="transparent"
                                    setFieldValue={setFieldValue}
                                    value={values.trip[index]?.address}
                                  />
                                </StandaloneSearchBox>
                                {showErrors && errors?.trip && errors.trip[index]?.address && (
                                  <Text className="error-message-error">{errors.trip[index].address}</Text>
                                )}
                              </FormControl>

                              <Flex direction="column" gap="8px" mt="4">
                                <LabelDefault name="clients" text="CNPJ (neste trecho)" />
                                <Select
                                  placeholder="Selecione"
                                  options={clientList}
                                  isMulti={true}
                                  name={`trip[${index}].clients`}
                                  value={clientList?.filter((option) => {
                                    if (typeof values.trip[index]?.clients !== 'object') {
                                      return option.value === values.trip[index]?.clients;
                                    }

                                    return values.trip[index]?.clients.some((item) => {
                                      if (typeof item === 'object') {
                                        return item.value === option.value;
                                      }

                                      return item === option.value;
                                    });
                                  })}
                                  onChange={(option) => {
                                    handleSelectChange(option, `trip[${index}].clients`, setFieldValue);
                                  }}
                                  components={{
                                    ClearIndicator: ({ innerProps }) => (
                                      <Flex
                                        textColor="red"
                                        fontSize="14px"
                                        fontWeight="bold"
                                        px="3px"
                                        cursor="pointer"
                                        _hover={{ opacity: '0.8', transition: '0.3s' }}
                                        {...innerProps}>
                                        Limpar
                                      </Flex>
                                    ),
                                  }}
                                />
                                {showErrors && errors?.trip && errors.trip[index]?.clients && (
                                  <Text className="error-message-error">{errors.trip[index].clients}</Text>
                                )}
                              </Flex>

                              <FormControl w="100%" mt="4">
                                <LabelDefault
                                  name="observation"
                                  text={
                                    <Flex gap="4px" alignItems="center">
                                      <Text>Observações do trecho</Text>
                                      <Text fontSize="small"> (opcional)</Text>
                                    </Flex>
                                  }
                                />
                                <Textarea
                                  height="180px"
                                  resize="none"
                                  bg="#F2F2F2"
                                  placeholder="Preencha a observação..."
                                  value={values.trip[index]?.observation}
                                  onChange={(event) => setFieldValue(`trip[${index}].observation`, event?.target?.value)}
                                  name={`trip[${index}].observation`}
                                />
                              </FormControl>
                              {index !== values?.trip?.length - 1 && <Divider borderColor="#70707033 " />}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          mb="20px"
          bgColor="transparent"
          border="1px"
          borderColor="red"
          borderRadius="9px"
          textColor="red"
          fontSize="12px"
          py="6px"
          h="auto"
          _hover={{ bgColor: 'transparent', shadow: 'lg' }}
          leftIcon={<MdAdd color="red" size={15} />}
          onClick={(decision) => {
            if (decision) {
              const lastTripIndex = values.trip?.length - 1;
              const isAddressFilled = !!values.trip[lastTripIndex]?.address;
              const isTypeFilled = !!values.trip[lastTripIndex]?.type;
              const isClientFilled = !!values.trip[lastTripIndex]?.clients;

              if (!isTypeFilled) setFieldError(`trip[${lastTripIndex}].type`, 'Campo obrigatório');
              if (!isAddressFilled) setFieldError(`trip[${lastTripIndex}].address`, 'Campo obrigatório');
              if (!isClientFilled) setFieldError(`trip[${lastTripIndex}].clients`, 'Campo obrigatório');

              if (isTypeFilled && isAddressFilled) {
                setShowErrors(false);
                handleAddField(values, setFieldValue);
              } else {
                setShowErrors(true);
              }
            }
          }}>
          Adicionar trecho
        </Button>
      </Flex>
      <Button
        mt="20px"
        variant="primary"
        onClick={(decision) => {
          if (decision) {
            onSubmit();
          }
        }}>
        <Text fontStyle="paragraph">{`Avançar`}</Text>
      </Button>
    </>
  );
};

export default CardTrip;
