import React, { useState } from 'react';

import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import { IconButton } from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';

const ModalDeleteadditionalField = ({ entity, triggerRefresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);

    requests
      .deleteAditionalField(entity.identifier)
      .then(() => {
        toast.success(`Campo ${entity.label} removido com sucesso!`);
        triggerRefresh();

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <DefaultModal
        ctaButton={<IconButton icon={<MdDelete color="#E74C3C" />} />}
        title={'Certeza que deseja excluir ?'}
        loading={isLoading}
        subtitle={<>Esta ação é permanente, deseja prosseguir?</>}
        buttonColor="red"
        txtButton="Excluir"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            handleDelete();
          }
        }}
      />
    </>
  );
};

export default ModalDeleteadditionalField;
