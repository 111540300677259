import { FaTrashAlt } from 'react-icons/fa';

import { Button } from '@chakra-ui/react';

import DefaultModal from '../../Modal/Template/DefaultModal';

const DeleteConfirmationModal = ({ deleteButtonAction, identifier, deleteButtonSubtitle }) => {
  return (
    <DefaultModal
      ctaButton={
        <Button variant="commonTable">
          <FaTrashAlt size={16} color="#E74C3C" />
        </Button>
      }
      title={'Certeza que deseja excluir ?'}
      subtitle={
        deleteButtonSubtitle ? (
          deleteButtonSubtitle
        ) : (
          <>
            Deseja mesmo excluir esse documento?
            <br />
            Ele sumirá automaticamente da lista de documentos.
          </>
        )
      }
      buttonColor="red"
      txtButton="Confirmar"
      loadingText="Excluindo"
      callback={async (decision) => {
        if (decision) {
          (await deleteButtonAction)
            ? deleteButtonAction(identifier)
            : console.error('You need to add the deleteButtonAction property with a valid action for it to work.');
        }
      }}
      data-delete-modal
    />
  );
};

export default DeleteConfirmationModal;
