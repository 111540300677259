import { memo, useCallback } from 'react';

import { ErrorMessage, Field, useFormikContext } from 'formik';

import { FormControl, FormLabel, Select, Text } from '@chakra-ui/react';

/**
 * Componente de campo de seleção (Select) reutilizável para formulários.
 * Usa Formik para controle do estado e Chakra UI para estilização.
 *
 * @param {object} props - Propriedades do campo.
 * @param {string} name - Nome do campo no Formik.
 * @param {string} placeholder - Texto placeholder do select.
 * @param {Array} list - Lista de opções para o select.
 * @param {string} keyProp - A chave que será usada como a chave única (key) para cada opção.
 * @param {string} displayProp - A chave que será exibida como o valor da opção.
 * @returns {JSX.Element} Um campo de seleção estilizado e validado.
 */
const MemoizedDynamicSelectField = ({ props }) => {
  const { setFieldValue } = useFormikContext();
  const { name, placeholder = null, list = [], keyProp, displayProp, ...restProps } = props;

  /**
   * Manipula a mudança de valor no campo de seleção.
   * Atualiza o valor do campo no Formik.
   *
   * @param {Event} e - Evento de mudança (onChange) do select.
   */
  const handleSelectChange = useCallback(
    (e) => {
      const { value } = e.target; // Valor selecionado
      setFieldValue(name, value); // Atualiza o estado do campo no Formik
    },
    [name, setFieldValue] // Dependências para garantir que a função seja memorizada corretamente
  );

  return (
    <FormControl mb={4}>
      {placeholder && <FormLabel htmlFor={name}>{placeholder}</FormLabel>}

      <Field name={name}>
        {({ field }) => (
          <Select {...field} {...restProps} value={field.value || ''} onChange={(e) => handleSelectChange(e)}>
            {/* Placeholder como uma opção desabilitada */}
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}

            {/* Mapeia a lista de opções */}
            {list.map((option) => (
              <option key={option[keyProp]} value={option[keyProp]}>
                {option[displayProp]}
              </option>
            ))}
          </Select>
        )}
      </Field>

      {/* Exibe mensagem de erro, se houver, com base no nome do campo */}
      <ErrorMessage name={name} component={Text} className="error-message-error" />
    </FormControl>
  );
};

// Exporta o componente memorizado para evitar renderizações desnecessárias
export const DynamicSelectField = memo(MemoizedDynamicSelectField);
