import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsQuote } from '../../../utils/filters/filterPresets';

import QuoteTable from './components/QuoteTable';

const QuotePage = () => {
  const hasPermission = permissions.vendemmiaFreightQuote;
  const request = useRef(0);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key, page) => {
    window.localStorage.removeItem('entity-old');

    setIsLoading(true);

    requests
      .getQuotes(filters, page)
      .then((response) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(response.data);
        setMetadata(response.meta);

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsQuote());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="quote"
      title="Cotação de frete"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Buscar por referência ou texto"
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}>
      <Card m="10px">
        <ExpandContainer>
          <Box w="full">
            <ScreenLoader isLoading={isLoading}>
              <Box overflow="auto" h="calc(100vh - 190px)">
                <QuoteTable list={list} loadList={load} />
              </Box>
            </ScreenLoader>

            {metadata.total_pages > 1 && (
              <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
            )}
          </Box>
        </ExpandContainer>

        <ScreenLoader isLoading={isLoading}>
          <Box height="calc(100vh - 255px)" overflow="auto">
            <QuoteTable list={list} loadList={load} />
          </Box>
        </ScreenLoader>

        {metadata.total_pages > 1 && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )}

        <FloatActionButton
          showOptions={false}
          isOpen={isOpenFloatButton}
          setIsOpen={setIsOpenFloatButton}
          saveButton={
            <Link to="/vendemmia/quote/new">
              <Button
                leftIcon={<MdAdd color="#FFFFFF" size={20} />}
                _hover={{ bgColor: '#70D499' }}
                bg="green"
                p="1.5rem"
                pb="1.5rem"
                pl="1.3rem"
                pr="1.3rem"
                borderRadius="50px">
                <Text mr="10px" color="white">
                  Nova cotação
                </Text>
              </Button>
            </Link>
          }
        />
      </Card>
    </Page>
  );
};

export default QuotePage;
