import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Flex, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

const ConsolidationClientsDetails = ({ list, modality, modalityDetails, clients, clientDetails, index, moneyFormat, colorFormat }) => {
  return (
    <>
      <Tr key={index}>
        <Td p="10px" minW="280px" color="#422C76" fontWeight="500">
          <Flex>{list.result.data[modality].data[modalityDetails].clients[clients].process[clientDetails].code}</Flex>
        </Td>

        <Td p="10px" minW="280px">
          <Flex justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].clients[clients].process[clientDetails].billed)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].clients[clients].process[clientDetails].billed)}
            </Text>
          </Flex>
        </Td>

        <Td p="10px" minW="280px">
          <Flex justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text
              color={colorFormat(list.result.data[modality].data[modalityDetails].clients[clients].process[clientDetails].estimated)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].clients[clients].process[clientDetails].estimated)}
            </Text>
          </Flex>
        </Td>

        <Td p="10px">
          <Flex>
            <Tooltip label="Visualizar processo" w="fit-content">
              <Link
                to={`/comex/process/detail/${list.result.data[modality].data[modalityDetails].clients[clients].process[clientDetails].identifier}`}
                target="_blank">
                <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
              </Link>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default ConsolidationClientsDetails;
