import React from 'react';

import { Box, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

const TotalFamilyBarChart = ({ label, value = 0, max = 10.0, originalValue, color = '#6576FF' }) => {
  function getValue(val) {
    if (!val) {
      return 0;
    }

    return val;
  }

  function getPercent() {
    return ((originalValue / max) * 100.0).toFixed(2);
  }

  return (
    <Tooltip label={`${getValue(value)} de ${getValue(max)} (${getPercent()}%)`}>
      <Flex direction="column" width="100%">
        <Grid templateColumns="1fr 3fr 1fr" gap="15px" justify="space-between" paddingLeft="10px" fontSize="16px" alignItems="center">
          <Text className="up-anim" opacity=".78">
            {label}
          </Text>

          <Box width="100%" bg="#EFEFEF" borderRadius="8px" h="10px">
            <Box
              className="progress-anim"
              h="100%"
              transformOrigin="left"
              transition=".5s"
              borderRadius="8px"
              bg={color}
              w={`${getPercent()}%`}
            />
          </Box>

          <Text className="up-anim">{value}</Text>
        </Grid>
      </Flex>
    </Tooltip>
  );
};

export default TotalFamilyBarChart;
