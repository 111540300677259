import React from 'react';

import { Flex } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import ChartNationalizationTaxes from './ChartNationalizationTaxes';

const NationalizationTaxes = ({ isLoading, data }) => {
  return (
    <Flex direction="column" align="center" mx="25px" my="15px">
      <ScreenLoader isLoading={isLoading}>
        <ChartNationalizationTaxes entity={data} />
      </ScreenLoader>
    </Flex>
  );
};

export default NationalizationTaxes;
