import React from 'react';

import { Text } from '@chakra-ui/react';

const Tag = ({ fontSize = '10px', type = 'green', children, ...props }) => {
  const colors = {
    green: {
      bg: '#2ECC7161',
      color: '#14A752',
    },
    yellow: {
      bg: '#F1C40F61',
      color: '#CCB32E',
    },
    red: {
      bg: '#E5235961',
      color: '#950E33',
    },
    blue: {
      bg: '#3498DB61',
      color: '#3498DB',
    },
    gray: {
      bg: '#AFAEB480',
      color: '#333',
    },
    orange: {
      bg: '#F8991C',
      color: '#AC8E13',
    },
  };
  return (
    <Text
      p="5px 12px"
      bg={colors[type]?.bg}
      color={colors[type]?.color}
      borderRadius="6px"
      fontSize={fontSize}
      fontWeight="bold"
      w="fit-content"
      {...props}>
      {children}
    </Text>
  );
};

export default Tag;
