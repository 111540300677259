import React from 'react';

import { Flex } from '@chakra-ui/react';

import OrderProgressBar from '../../../pages/metrics/MetricsWarehouse/components/OrderProgressBar';

const OrderProgressChart = ({ data, collectionStatus, filterOptions, isPreview = false, type }) => {
  const importado = parseInt(data.IMPORTADO, 10);
  const emSeparacao = parseInt(data.SEPARACAO, 10);
  const emConferencia = parseInt(data.CONFERENCIA, 10);
  const aguardandoFaturamento = parseInt(data.ENVIADO_PARA_FATURAMENTO, 10);
  const aguardandoColeta = parseInt(data.FATURADO, 10);
  const coletaIniciada = parseInt(data.COLETA_INICIADA, 10);
  const outros = parseInt(data.OUTROS, 10);
  const cancelado = parseInt(data.CANCELADO, 10);
  const geradoCorte = parseInt(data.GERADA_COM_CORTE, 10);

  const totalValue =
    importado +
    emSeparacao +
    emConferencia +
    aguardandoFaturamento +
    aguardandoColeta +
    coletaIniciada +
    outros +
    cancelado +
    geradoCorte;

  const colorArray = ['#422C76', '#2EC5CC', '#2EA0CC', '#F1C40F', '#9B2ECC', '#CC2E96', '#422ECC', '#F14F0F', '#CC782E'];

  return (
    <Flex direction="column" px="10px" h="full" justify="center">
      <Flex direction="column" w="100%" h={{ sm: 'auto', md: '16.875rem' }} gap="5px" pr="10px" overflowY="auto">
        {isPreview ? (
          <>
            <OrderProgressBar isPreview={isPreview} label="Importado" value={data.IMPORTADO} max={totalValue} color="#422C76" />
            <OrderProgressBar isPreview={isPreview} label="Separação" value={data.SEPARACAO} max={totalValue} color="#2EC5CC" />
            <OrderProgressBar isPreview={isPreview} label="Conferência" value={data.CONFERENCIA} max={totalValue} color="#2EA0CC" />
            <OrderProgressBar
              isPreview={isPreview}
              label="Enviado para faturamento"
              value={data.ENVIADO_PARA_FATURAMENTO}
              max={totalValue}
              color="#F1C40F"
            />
            <OrderProgressBar
              isPreview={isPreview}
              label="Faturado | Aguardando coleta"
              value={data.FATURADO}
              max={totalValue}
              color="#9B2ECC"
            />
            <OrderProgressBar
              isPreview={isPreview}
              label="Coleta iniciada"
              value={data.COLETA_INICIADA}
              max={totalValue}
              color="#CC2E96"
            />
            <OrderProgressBar isPreview={isPreview} label="Outros" value={data.OUTROS} max={totalValue} color="#422ECC" />
            <OrderProgressBar isPreview={isPreview} label="Cancelado" value={data.CANCELADO} max={totalValue} color="#F14F0F" />
            <OrderProgressBar
              isPreview={isPreview}
              label="Gerado com corte"
              value={data.GERADA_COM_CORTE}
              max={totalValue}
              color="#CC782E"
            />
          </>
        ) : (
          <>
            {collectionStatus.map((entity, key) =>
              entity.map((entityArray, index) => (
                <React.Fragment key={key}>
                  <OrderProgressBar
                    isPreview={isPreview}
                    entity={entityArray}
                    filterOptions={filterOptions}
                    label={entityArray.label}
                    value={data[entityArray.value]}
                    max={totalValue}
                    color={colorArray[index % colorArray.length]}
                    type={type}
                  />
                </React.Fragment>
              ))
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default OrderProgressChart;
