import React from 'react';

import { MdCancel, MdCheckCircle, MdWarning } from 'react-icons/md';

import { Flex, Text } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';

import CardStatus from './CardStatus';
import TableMetricsUpdate from './TableMetricsUpdate';

const cardStatusView = [
  {
    title: 'Atualizações iniciadas',
    bgColor: '#6C48C21F',
    icon: false
  },
  {
    title: 'Finalizadas com sucesso',
    bgColor: '#2ECC711F',
    iconRender: <MdCheckCircle color="#2ECC71" size={25} />,
    icon: true
  },
  {
    title: 'Precisam de atenção',
    bgColor: '#F1C40F1F',
    iconRender: <MdWarning color="#F1C40F" size={25} />,
    icon: true
  },
  {
    title: 'Falhas de atualização',
    bgColor: '#E523591F',
    iconRender: <MdCancel color="#E52359" size={25} />,
    icon: true
  }
]

const CardMetrics = ({ searchPeriod }) => {
  return (
    <Card
      m="10px"
      header={
        <Flex gap="2px" direction="column">
          <Text textStyle="cardTitle" fontWeight="bold" color="primary">
            Métricas de atualização
          </Text>
        </Flex>
      }>
      <Flex 
        gap="15px" 
        p="10px" 
        flexDirection={{ base: "column", md:"row" }}
      >
        <Flex 
          gap="19px" 
          direction="column"
        >

          {cardStatusView.map((card, key) => {
            
            return (
              card.icon ? (
                <CardStatus key={key}
                  text={card.title}
                  bgColor={card.bgColor}
                  fontSize="16px" 
                  p="20px" 
                  icon={card.iconRender}
                  margin="15px"
                />
                ) : (
                  <CardStatus key={key}
                    text={card.title}
                    bgColor={card.bgColor}
                    fontSize={{ base: "22px", md:"26px" }}
                    p="20px"
                    margin="0"
                  />
                )
            )
          })}
        </Flex>

        <Flex 
          direction="column" 
          w="full"
          overflow={'auto'}
        >
          <TableMetricsUpdate searchPeriod={searchPeriod} />
        </Flex>
      </Flex>
    </Card>
  );
};

export default CardMetrics;
