import { useEffect, useState } from 'react';

import { Box, Button, Card, Flex, Grid, List, ListItem, Text, Tooltip } from '@chakra-ui/react';

const ReportList = ({ dataReport, maxH, paginateTextDisplay, isReportSelected }) => {

  const [data, setData] = useState(
    !dataReport
      ? {
          columnCount: 0,
          rowCount: 0,
        }
      : dataReport
  );

  const handlerCopyText = (event, text) => {
    event.target.textContent = 'Texto Copiado';

    // Cria uma área de transferência temporária
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Adiciona a área de transferência à página
    document.body.appendChild(textarea);

    // Seleciona o texto na área de transferência
    textarea.select();
    textarea.setSelectionRange(0, 99999); // Para dispositivos móveis

    // Copia o texto para a área de transferência
    document.execCommand('copy');

    // Remove a área de transferência temporária
    document.body.removeChild(textarea);

    setTimeout(() => {
      event.target.textContent = 'Copiar Texto';
    }, 1500);
  };

  useEffect(() => {
    setData(dataReport);
  }, [dataReport]);

  return (
    <Card bg={'white'} h={'100%'}>
      <Grid overflow={'auto'}>
        <Box
          as="header"
          boxShadow="0px 3px 20px #00000012"
          border={'1px solid #70707036'}
          borderRadius={'8px'}
          p={'12px 20px'}
          marginBottom={'5px'}>
          {data.columnCount > 0 ? (
            <List
              display={'grid'}
              gap={'10px'}
              gridTemplateColumns={data.columns ? 'repeat(' + dataReport.columns.length + ', minmax(200px, 1fr))' : '1fr'}>
              {data.columns.map((col, key) => {
                return (
                  <ListItem key={key} fontSize={'16px'} fontWeight={'bold'} wordBreak={'break-word'}>
                    {col}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Text>{isReportSelected ? 'Não há colunas a serem exibidas.' : 'Selecione um relatório'}</Text>
          )}
        </Box>
        <Box as="section" overflow={'auto'} maxH={maxH ?? '100%'}>
          <List display={'grid'} columnGap={'10px'}>
            {data.rowCount > 0 ? (
              data.rows.map((rows, key) => {
                return (
                  <ListItem
                    key={key}
                    display={'grid'}
                    alignItems={'center'}
                    padding={'2px 0'}
                    gap={'10px'}
                    _even={{ bg: '#eee' }}
                    gridTemplateColumns={data.columns ? 'repeat(' + data.columns.length + ', minmax(200px, 1fr))' : '1fr'}
                    p={'14px 20px'}>
                    {data.rows[key].map((row, index) => {
                      return (
                        <Box key={index} fontSize={'15px'} wordBreak={'break-word'}>
                          {row.length > 50 ? (
                            <>
                              {row.slice(0, 50) + '... '}
                              <Button
                                fontSize={'14px'}
                                lineHeight={'18px'}
                                p={'5px 10px'}
                                maxH={'30px'}
                                onClick={(event) => {
                                  handlerCopyText(event, row);
                                }}>
                                Copiar Texto
                              </Button>
                            </>
                          ) : row.length > 0 ? (
                            row
                          ) : (
                            '-'
                          )}
                          {/* {row.length < 50 ? row : <Tooltip label={row}>{row.slice(0, 50)}</Tooltip>} */}
                        </Box>
                      );
                    })}
                  </ListItem>
                );
              })
            ) : (
              <Text p={'12px 20px'} textAlign={'center'}>{isReportSelected ? 'Não há registros na lista a serem exibidos.' : 'Sem dados a mostrar'}</Text>
            )}
          </List>
        </Box>
      </Grid>
      {paginateTextDisplay && data.columnCount > 0 && (
        <Flex fontSize={'12px'} p={'12px 20px'} color={'textColor3'}>
          <Text>A paginação é desabilitada para essa tela de relatório, mas todos os dados estarão presentes na exportação</Text>
        </Flex>
      )}
    </Card>
  );
};

export default ReportList;
