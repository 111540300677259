import React, { useEffect, useRef, useState } from 'react';

import { MdClose, MdMenu } from 'react-icons/md';

import { Box, IconButton, useDisclosure } from '@chakra-ui/react';

import { NavbarContent } from './NavbarContent';

export const Navbar = () => {
  const { onClose } = useDisclosure();
  const [mobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMobileSubMenuToggle = () => {
    setMobileSubMenuOpen(!mobileSubMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMobileSubMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Box as="nav">
      {/* Hamburger menu */}
      <Box position="relative" display={{ base: 'flex', lgr: 'none' }} ref={dropdownRef}>
        <IconButton
          variant="link"
          display="contents"
          position="absolute"
          icon={mobileSubMenuOpen ? <MdClose color="#FFFFFFB3" size={25} /> : <MdMenu color="#FFFFFFB3" size={25} />}
          onClick={handleMobileSubMenuToggle}
        />
        {mobileSubMenuOpen && (
          <Box
            className="down-anim"
            w={{ base: '200px', lgr: 'initial' }}
            p="19px"
            border="1px"
            zIndex="9999"
            borderRadius="7px"
            top={{ base: '40px', lg: 'initial' }}
            borderColor="#A885F982"
            bg="#ffffff"
            shadow="lg"
            position="absolute">
            <NavbarContent isMobile={true} />
          </Box>
        )}
      </Box>

      <Box display={{ base: 'none', lgr: 'flex' }}>
        <NavbarContent isMobile={false} />
      </Box>
    </Box>
  );
};
