import { GridItem, Text, Tooltip } from '@chakra-ui/react';

export const MiniCardTotalCarbon = ({ value, weight, children, tooltip, ...props }) => {
  return (
    <GridItem display={'grid'} alignItems={'center'} gap={'5px'} {...props}>
      <Text fontSize={'23px'} color={'#70B961'} fontWeight={'bold'} display={'flex'} alignItems={'center'} gap={'15px'}>
        {value}

        <Text fontSize={'15px'} color={'primary'} fontWeight={'bold'}>
          {tooltip ? <Tooltip label={tooltip}>{weight}</Tooltip> : weight}
        </Text>
      </Text>
    </GridItem>
  );
};
