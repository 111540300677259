import { useEffect, useState } from 'react';

export const useForceRefresh = (load) => {
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const triggerRefresh = () => {
    setForceLoadTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    const key = Date.now();
    load({}, key);
  }, [forceLoadTrigger]);

  return { forceLoadTrigger, triggerRefresh };
};
