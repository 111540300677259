import React from 'react';

import { Flex, Image } from '@chakra-ui/react';

import ok from '../../../../assets/images/check_green.svg';
import warning from '../../../../assets/images/warning_yellow.svg';

const Verify = ({ email = false, tell = false }) => {
  return (
    <Flex>
      <Image mr="4px" src={email ? ok : warning} alt={email ? 'Verificado' : 'Pendente'} />
      Telefone
      <Image mr="4px" ml="10px" src={tell ? ok : warning} alt={tell ? 'Verificado' : 'Pendente'} />
      Email
    </Flex>
  );
};

export default Verify;
