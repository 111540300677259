import { LoadScript } from '@react-google-maps/api';

import { CircularProgress, Flex } from '@chakra-ui/react';

import { GOOGLE_MAPS_LIBRARIES } from './pages/logistic/components/places';
import Pages from './routes';

export const GlobalProvider = ({ children }) => {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
      libraries={GOOGLE_MAPS_LIBRARIES}
      loadingElement={
        <Flex w="full" justify="center" align="center" p="40px">
          <CircularProgress className="icon" size="30px" isIndeterminate color="primary" />
        </Flex>
      }>
      <div>
        <Pages />
        {children}
      </div>
    </LoadScript>
  );
};
