import React, { useEffect, useRef, useState } from 'react';

import { Button, Card, Flex } from '@chakra-ui/react';

import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';

import KanbanSection from './components/KanbanSection';

const KanbanPage = () => {
  const hasPermission = permissions.comexKanban;
  const responsiveCheck = useResponsiveCheck();
  const isMobile = responsiveCheck.isMobile;
  const viewport = responsiveCheck.viewport;
  const request = useRef(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listProcess(filters, metadata.current_page, null, 50)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClickLoadMore = () => {
    setIsLoadingMore(true);
  };

  const loadMore = (filters) => {
    requests
      .listProcess(filters, metadata.current_page + 1)
      .then((data) => {
        setList((prevList) => [...prevList, ...data.data]);
        setMetadata(data.meta);
      })
      .finally(() => setIsLoadingMore(false));
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComex());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="comex"
      title="Kanban"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      allowFiltersOverride={true}
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={load}
      list={list}
      metadata={metadata}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading || isLoadingMore}>
      <Card m="10px" overflow="auto">
        <Flex direction="column" gap="0" p="10px" minH="calc(100vh - 260px)">
          <ScreenLoader isLoading={isLoading}>{list && <KanbanSection list={list ?? []} viewport={viewport} />}</ScreenLoader>
        </Flex>

        {list.length > 0 && list.length < metadata.total_count && (
          <Button w="full" onClick={handleClickLoadMore} mt="20px" isLoading={isLoadingMore} loadingText="Carregando...">
            Carregar mais processos
          </Button>
        )}
      </Card>
    </Page>
  );
};

export default KanbanPage;
