import React from 'react';

import { TabPanel } from '@chakra-ui/react';

import VehiclesTable from './VehiclesTable';
//
const VehiclesTab = ({ user }) => {
  return (
    <TabPanel>
      <VehiclesTable user={user} hideButtons={true} />
    </TabPanel>
  );
};

export default VehiclesTab;
