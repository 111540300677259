import { Flex, Text } from '@chakra-ui/react';

import LabelWithTextH from '../../../../components/Generic/LabelWithTextH';

export const VoyageListContent = ({ labels, content }) => {
  return (
    <Text>
      <Flex flexWrap="wrap" gap="10px">
        {labels &&
          labels.length > 0 &&
          labels.map(
            (label, index) =>
              content &&
              content[label.content] && <LabelWithTextH key={index} title={label.title} content={content && content[label.content]} />
          )}
      </Flex>
    </Text>
  );
};
