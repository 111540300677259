const moneyFormat = (valor, currency, style) => {
  if (valor !== undefined && valor !== null) {
    return valor.toLocaleString('pt-BR', {
      style: style ?? 'currency',
      currency: currency ?? 'BRL',
    });
  }
  return '';
};

export default moneyFormat;
