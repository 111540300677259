export const HeaderStyles = () => {
  return (
    <style>
      {`
        body {
          padding-top: 123px !important;
        }
      `}
    </style>
  );
};
