import React  from 'react';

import { FaCheckDouble } from 'react-icons/fa';
import { MdCancel, MdHelp, MdOutlineEdit } from 'react-icons/md';
import { ProgressBar } from 'react-step-progress-bar';

import { Box, Divider, Flex, Grid, GridItem, IconButton, Image, Select, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import pointStatus from '../../../../../assets/svgs/trip/pointStatus.svg';
import truckIcon from '../../../../../assets/svgs/trip/truckIcon.svg';
import Card from '../../../../../components/Card/Card';
import DefaultCommentModal from '../../../../../components/Modal/Template/DefaultCommentModal';
import permissions from '../../../../../services/permissions';

const InformationManagement = ({
    entity,
    additionalInformation,
    load,
    onSubmit,
    percentage,
    tripPercentage,
    onOpenEdit,
    drawerOnOpen,
    handleClickChangeStatus
}) => {
    let profileRole = localStorage.getItem('profile-role');
    const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
    const { isOpen: isOpenFinish, onOpen: onOpenFinish, onClose: onCloseFinish } = useDisclosure();

    const checkStatus = (action, status) => {
        const cancel = ['DRAFT', 'CREATED', 'SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER', 'TRAVELLING'];
        const complete_travel = [
            'DELIVERED_WITH_SIGNATURE',
            'DELIVERED_WITHOUT_SIGNATURE',
            'SENT_TO_DRIVER',
            'ACCEPTED_BY_DRIVER',
            'TRAVELLING',
        ];

        switch (action) {
            case 'cancel':
                return cancel.indexOf(status) !== -1 ? true : false;
            case 'complete_travel':
                return complete_travel.indexOf(status) !== -1 ? true : false;
            default:
                return false;
        }
    };

    const canShowEditButton = (status) => {
        if (!permissions.logisticTripEdit) {
            return false;
        }

        switch (status) {
            case 'DRAFT':
            case 'CREATED':
            case 'SENT_TO_DRIVER':
            case 'REFUSED_BY_DRIVER':
            case 'REFUSED_BY_DRIVER_HANDLED':
            case 'ACCEPTED_BY_DRIVER':
            case 'GOING_TO_COLLECT':
            case 'READ_TO_COLLECT':
            case 'COLLECTING':
            case 'READ_TO_TRAVEL':
            case 'TRAVELLING':
                return true;   
            default:
                return false;
        }
    }

    const tooltipStart = (
      <>
        {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
        {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
        {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
      </>
    );

    const tooltipEnd = (
      <>
        {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
        {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
        {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
      </>
    );

  return (
    <>
    {profileRole !== 'ROLE_CLIENT' &&
        entity?.modality != 'MANUAL' && (
          <Card 
            p="20px"
            gap="40px"
            h={{ base: 'min(calc(100vh - 180px), 530px)', md: 'min(calc(100vh - 180px), 365px)' }}
          >
            <Flex
              align="center"
              justify='space-between'
              gap={{ base: '20px', xl: '0' }}
            >
              <Flex gap="10px">
                <Flex bgColor="linkColor" p="10px 2px" borderRadius="2px"></Flex>
                <Flex direction="column">
                  <Text fontSize="1.3rem" fontWeight="bold">Gerenciamento da viagem</Text>
                </Flex>
              </Flex>

              {(canShowEditButton(entity?.status) ||
                checkStatus('cancel', entity?.status) ||
                checkStatus('complete_travel', entity?.status)) && (
                  <Flex align="center" gap="10px" direction={{ base: 'column', md: 'row' }}>
                    {canShowEditButton(entity?.status) && (
                      <Tooltip label="Editar viagem">
                          <IconButton
                              bgColor="#D0B0FD36"
                              icon={
                                  <MdOutlineEdit size={20} color="#6C48C2" />
                              }
                              onClick={(decision) => {
                                  if (decision) {
                                      if (entity?.status === 'DRAFT') {
                                          onOpenEdit();
                                      } else {
                                          drawerOnOpen();
                                      }
                                  }
                              }} 
                          />
                      </Tooltip>
                    )}

                    {checkStatus('cancel', entity?.status) && (
                      <DefaultCommentModal
                        action="cancel"
                        title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                        txtButton="Cancelar"
                        textButtonConfirm="Salvar"
                        icon={<MdCancel size={20} color="#6C48C2" />}
                        tooltip="Cancelar viagem"
                        load={load}
                        onSubmit={onSubmit}
                        isOpen={isOpenCancel}
                        onOpen={onOpenCancel}
                        onClose={onCloseCancel}
                        background="red"
                        hoverColor="#B53C2F"
                      />
                    )}

                    {checkStatus('complete_travel', entity?.status) && (
                      <DefaultCommentModal
                        action="complete_travel"
                        title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                        txtButton="Finalizar"
                        textButtonConfirm="Salvar"
                        icon={<FaCheckDouble size={16} color="#6C48C2" />}
                        tooltip="Finalizar viagem"
                        load={load}
                        onSubmit={onSubmit}
                        isOpen={isOpenFinish}
                        onOpen={onOpenFinish}
                        onClose={onCloseFinish}
                        hoverColor="#26A65B"
                      />
                    )}
                  </Flex>
                )
              }
            </Flex>

            <Box style={{ position: 'relative', width: '100%', height: '0px' }}>
              <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px" />
              {/* Ícone no início */}
              <Image 
                src={pointStatus}
                style={{
                  position: 'absolute',
                  top: '-9px',
                  left: '-10px',
                  width: '20px',
                }}
                alt="Starting Point"
              />

              {/* Imagem do caminhão seguindo o progresso */}
              <Tooltip
                bg="white"
                color='#6C48C2'
                placement="right"
                p="2"
                shadow="lg"
                label={`${`${entity?.percentageCompletion}%`}`}
              >
                <Image 
                  src={truckIcon}
                  style={{
                    width: '24px',
                    position: 'absolute',
                    top: '-43px',
                    left: tripPercentage > 13 ? `calc(${tripPercentage}% - 55px)` : '36px',
                    transition: 'left 0.5s ease',
                    transform: 'rotate(90deg)',
                  }}
                  alt="Truck Icon"
                />
              </Tooltip>

              {/* Ícone no fim */}
              <Image 
                src={pointStatus}
                style={{
                  position: 'absolute',
                  top: '-9px',
                  right: '-10px',
                  width: '20px',
                  filter: 'grayscale(100%)',
                }}
                alt="Ending Point"
              />
              <Box mt="4" color='#5F5B68'>
                <Text 
                  textStyle="subtitle" 
                  textColor="gray-250"
                  fontSize={"12px"}
                >
                  {`* ${percentage(
                      entity?.modality,
                      entity?.status,
                      entity?.typeExternalCode
                    )
                  }`}
                </Text>
              </Box>
            </Box>
            <Divider mt="4" />
            <Flex direction="column" gap={2}>
              <Flex>
                <Flex flex="1" fontWeight="bold" ml="4">Etapa</Flex>
                <Flex flex="1" fontWeight="bold">Previsão</Flex>
                <Flex flex="1" fontWeight="bold">Efetivo</Flex>
              </Flex>

              <Flex
                border="1px solid"
                borderColor="#6C48C2"p
                borderRadius="md"
              >
                <Flex flex="1" p={2}>
                  <Text color="#6C48C2">Início</Text>
                </Flex>
                <Flex flex="1" p={2}>{entity?.startsAtFormatted ?? '-'}</Flex>
                <Flex flex="1" alignItems="center">
                  {entity?.startsEtdFormatted != null && (
                    <>
                      <Text>
                        {entity?.startsEtdFormatted}
                      </Text>

                      <Tooltip label={tooltipStart}>
                        <Box style={{ paddingLeft: '2px' }}>
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex  border="1px solid"
                borderColor="#6C48C2"
                borderRadius="md"
              >
                <Flex flex="1" p={2}>
                  <Text color="#6C48C2">Final</Text>
                </Flex>
                <Flex flex="1" p={2}>
                  {additionalInformation?.estimatedEndsAt ?? entity?.endsAtFormatted}
                </Flex>
                <Flex flex="1" p={2} alignItems="center">
                  {entity?.endsEtdFormatted != null && (
                    <>
                      <Text>
                        {entity?.endsEtdFormatted}
                      </Text>

                      <Tooltip label={tooltipEnd}>
                        <Box style={{ paddingLeft: '2px' }}>
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex color='#5F5B68'>
                {additionalInformation?.estimatedEndsAt != null && (
                  <Text 
                    textStyle="subtitle" 
                    textColor="gray-250"
                    fontSize={"12px"}
                  >
                    {`* ${additionalInformation?.endsMessage}`}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Card>
        )
      }

      {entity?.modality === 'MANUAL' && (
        <Card p="20px">
          <Flex align="center" justify={{ base: 'space-around', xl: 'space-between' }} gap="10px">
            <Flex justify="space-between">
              <Flex bgColor="linkColor" p="10px 2px" ml={4} borderRadius="2px"></Flex>

              <Box ml={4}>
                <Text fontSize={{ base: '14px', xl: '1.375rem' }} fontWeight={'bold'}>
                  Status da viagem
                </Text>
                <Text fontSize={{ base: '11px', xl: '1.1rem' }}>Alterar status manualmente</Text>
              </Box>
            </Flex>

            <Select
              variant="primary"
              w="fit-content"
              name="status"
              defaultValue={entity?.status}
              isDisabled={entity?.statusAvailable?.length === 1}
              onChange={(event) => handleClickChangeStatus(event)}>
              {entity &&
                entity?.statusAvailable?.length > 0 &&
                entity.statusAvailable.map((status, key) => {
                  return (
                    <>
                      <option key={key} value={status.description}>
                        {status.translated}
                      </option>
                    </>
                  );
                })}
            </Select>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default InformationManagement;
