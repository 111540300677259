import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const TotalRevenue = ({ data }) => {
  return (
    <Flex direction="column" m="25px" w="auto" gap="10px">
      <Text 
        textStyle="primaryTitle" 
        textColor="primary" 
        fontWeight="bold" 
        fontSize={{ base: '26px', md: '32px'}}
      >
        R$ {data?.summary?.total}
      </Text>
    </Flex>
  );
};

export default TotalRevenue;
