import { createContext } from 'react';

import { Formik } from 'formik';

import { DynamicFormStyles } from './styles/DynamicFormStyles';

// Criando o contexto para compartilhar propriedades, se necessário
export const DynamicFormContext = createContext();

export const DynamicForm = ({ children, ...props }) => {
  const config = props.config;
  const { callback } = config;

  return (
    <DynamicFormContext.Provider value={props}>
      <DynamicFormStyles />
      <Formik
        onSubmit={(values) => {
          callback && callback(values); // Quando o formulário for submetido, chama a função callback com os valores do formulário.
        }}
        {...config} // Passa props adicionais para o Formik, se houver.
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: '1 1 0%',
            }}>
            {children}
          </form>
        )}
      </Formik>
    </DynamicFormContext.Provider>
  );
};
