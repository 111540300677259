import React from 'react';

import '../styles/transportScheduleMonth.css';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { MdTurnedIn } from 'react-icons/md';

import { Box, Flex, Grid, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';

const TransportScheduleMonth = ({ calendarRef, events, getStatusColor, loadEventDetails, setIsLoading, filterSelected, filters }) => {
  const formattedEventsMonth = events.map((event) => {
    const stagePriority = ['Concluído', 'Chegou', 'Agendado'];

    const priorityStage = stagePriority
      .map((stageName) => event.stage?.find((stage) => stage.stage === stageName))
      .find((stage) => stage);

    const start = priorityStage ? priorityStage.happenedAt.replace(' ', 'T') : event.expectedAt?.replace(' ', 'T') || null;

    const end = priorityStage
      ? new Date(new Date(priorityStage.happenedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : event.expectedAt
      ? new Date(new Date(event.expectedAt).getTime() + 60 * 60000).toISOString().replace(' ', 'T')
      : null;

    return {
      identifier: event.identifier,
      companyName: event.companyDepositor?.companyName ?? '-',
      code: event?.code ?? '-',
      dockNumber: event.dockNumber ?? '-',
      vehiclePlates: event.vehiclePlates ?? '-',
      stageModality: event.currentStageModality?.slug ?? '-',
      stageModalityFormatted: event.currentStageModality?.name ?? '-',
      modality: event.modality ?? '-',
      start,
      end,
    };
  });

  const getColorByText = (modality) => {
    switch (modality) {
      case 'RECEBIMENTO':
        return '#a885f9';
      case 'COLETA':
        return '#6c48c2';
      default:
        return null;
    }
  };

  const TransportEvent = ({ eventInfo }) => {
    return (
      <Flex
        h="50px"
        w="195px"
        bgColor="#F5F5F5"
        align="center"
        gap="3px"
        borderRadius="0px 6px 6px 0px "
        cursor="pointer"
        _hover={{ bgColor: '#F2E9FF' }}
        onClick={() => loadEventDetails(eventInfo.identifier)}>
        <Box h="full" minW="7px" bgColor={getColorByText(eventInfo.modality)} borderRadius="6px 0px 0px 6px" />

        <Flex h="full" w="full" justify="space-between" gap="3px" overflow={'hidden'}>
          <Grid direction="column" overflow={'hidden'} alignItems="center" justifyItems={'start'}>
            <Tooltip label={eventInfo?.companyName}>
              <Text fontSize="10px" fontWeight="medium" textColor="primary" overflow={'hidden'} isTruncated>
                {eventInfo?.companyName}
              </Text>
            </Tooltip>

            <Tooltip label={`Doca ${eventInfo.dockNumber}`}>
              <Text fontSize="9px" textColor="#A6A6A6">
                Doca {eventInfo.dockNumber}
              </Text>
            </Tooltip>
          </Grid>

          <Flex justify="flex-end">
            <Tooltip label={eventInfo.stageModalityFormatted}>
              <Box>
                <MdTurnedIn size="20px" color={getStatusColor(eventInfo.stageModality)} />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <FullCalendar
      ref={calendarRef}
      height="100%"
      plugins={[dayGridPlugin]}
      locale={ptBrLocale}
      events={formattedEventsMonth}
      firstDay={0}
      dayMaxEvents={2}
      showNonCurrentDates={false}
      dayHeaderFormat={{ weekday: 'long' }}
      moreLinkText={(num) => `+ ${num}`}
      eventContent={(eventInfo) => <TransportEvent eventInfo={eventInfo.event.extendedProps} />}
      headerToolbar={{
        start: '',
        center: '',
        end: '',
      }}
    />
  );
};

export default TransportScheduleMonth;
