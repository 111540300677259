import moment from "moment";

export const initialValues = (entity = {}) => {

  return {
    // title: entity?.title ? entity?.title : '',
    releasedAt: entity?.releasedAt ? moment(entity.releasedAt).format('YYYY-MM-DDTHH:mm') : '',
    expiredAt: entity?.expiredAt ? moment(entity.expiredAt).format('YYYY-MM-DDTHH:mm') : '',
    status: entity?.status ? entity?.status : '',
  };
};
