import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import { MdAdd, MdDelete, MdHelp } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';

import { Box, Button, Divider, Flex, FormControl, Grid, GridItem, IconButton, Text, Textarea, Tooltip } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import requests from '../../../../services/requests';
import { handleRemoveChange } from '../../operation-specification/Helpers/inputMultiple';

const FormMerchandiseCargo = ({
  values,
  setFieldValue,
  setModality,
  modality,
  selectCustomStyles,
  inputNcmsRelations,
  setInputNcmsRelations,
  loadUserOptions,
  userOptions,
}) => {
  const [ncmOptions, setNcmOptions] = useState([]);
  const [isLoadingNcmOptions, setIsLoadingNcmOptions] = useState(false);
  const [noNcmOptionsMessage, setNoNcmOptionsMessage] = useState('Nenhum resultado encontrado');

  const calculateCubic = () => {
    const aux = values?.additionalCubageValues.map((item, index) => {
      const quantity = parseFloat(item.quantity);
      const height = parseFloat(item.height);
      const width = parseFloat(item.width);
      const length = parseFloat(item.length);

      let cubicMeters = '';
      let cubicWeight = '';

      if (quantity && height && width && length) {
        cubicMeters = quantity * height * width * length;

        let factor;

        switch (modality) {
          case 'rodoviario':
            factor = 300;
            break;
          case 'Marítimo (sea)':
            factor = 1000;
            break;
          case 'Aéreo (air)':
            factor = 166.667;
            break;
          default:
            factor = 1;
        }

        cubicWeight = cubicMeters * factor;
      }

      setFieldValue(`additionalCubageValues[${index}].cbmCubicMeters`, cubicMeters);
      setFieldValue(`additionalCubageValues[${index}].cubicWeight`, cubicWeight);

      return {
        ...item,
        cbmCubicMeters: cubicMeters,
        cubicWeight: cubicWeight,
      };
    });

    setFieldValue('additionalCubageValues', aux);
  };

  const handleAddClick = () => {
    const newField = {
      quantity: '',
      height: '',
      width: '',
      length: '',
      cbmCubicMeters: '',
      cubicWeight: '',
    };

    setFieldValue('additionalCubageValues', [...values.additionalCubageValues, newField]);
  };

  const handleDeleteClick = (index) => {
    const newValues = values.additionalCubageValues.filter((_, i) => i !== index);
    setFieldValue('additionalCubageValues', newValues);
  };

  const listOperationNcmsAction = (code = '') => {
    if (code?.length < 4) {
      setNoNcmOptionsMessage('Insira ao menos 4 digítos para fazer a busca');
      return;
    }

    if (code?.length > 3) {
      setNoNcmOptionsMessage('Carregando');
      setIsLoadingNcmOptions(true);

      requests
        .listNcmsAction(code)
        .then((response) => {
          let result = [];
          response?.data?.length &&
            response.data.forEach((ncm) => {
              result.push({
                value: ncm.code,
                label: ncm.code + ' ' + ncm.description,
              });
            });

          setNcmOptions(result);
          setIsLoadingNcmOptions(false);
          setNoNcmOptionsMessage('Nenhum resultado encontrado');
        })
        .catch((error) => {
          toast.error('Ocorreu um erro ao trazer os ncms');
        });
    }
  };

  useEffect(() => {
    calculateCubic();
  }, [modality]);

  useEffect(() => {
    if (!values.additionalCubageValues || values.additionalCubageValues.length === 0) {
      setFieldValue('additionalCubageValues', [
        {
          quantity: '',
          height: '',
          width: '',
          length: '',
          cbmCubicMeters: '',
          cubicWeight: '',
        },
      ]);
    }
  }, []);

  return (
    <Flex m="20px" gap="20px" direction="column">
      <Text textStyle="cardTitle">Carga</Text>
      {values?.additionalCubageValues.map((entity, index) => (
        <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 90px" gap="15px" alignItems="flex-end" key={index}>
          <Flex direction="column">
            <LabelDefault name="Quantidade" text={`Quantidade`} />

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              displayType="input"
              allowNegative={false}
              fixedDecimalScale
              onBlur={calculateCubic}
              placeholder="Quantidade"
              //
              name={`additionalCubageValues[${index}].quantity`}
              onChange={(event) => setFieldValue(`additionalCubageValues[${index}].quantity`, event.target.value)}
              value={values.additionalCubageValues[index]?.quantity}
            />
          </Flex>

          <Flex direction="column">
            <LabelDefault name="Altura" text={`Altura (metros)`} />

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              decimalSeparator=","
              displayType="input"
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale
              onBlur={calculateCubic}
              placeholder="Altura"
              //
              name={`additionalCubageValues[${index}].height`}
              onChange={(event) => setFieldValue(`additionalCubageValues[${index}].height`, event.target.value)}
              value={values.additionalCubageValues[index]?.height}
            />
          </Flex>

          <Flex direction="column">
            <LabelDefault name="Largura" text={`Largura (metros)`} />

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              decimalSeparator=","
              displayType="input"
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale
              onBlur={calculateCubic}
              placeholder="Largura"
              //
              name={`additionalCubageValues[${index}].width`}
              onChange={(event) => setFieldValue(`additionalCubageValues[${index}].width`, event.target.value)}
              value={values.additionalCubageValues[index]?.width}
            />
          </Flex>

          <Flex direction="column">
            <LabelDefault name="Comprimento" text={`Comprimento (metros)`} />

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              decimalSeparator=","
              displayType="input"
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale
              onBlur={calculateCubic}
              placeholder="Comprimento"
              //
              name={`additionalCubageValues[${index}].length`}
              onChange={(event) => setFieldValue(`additionalCubageValues[${index}].length`, event.target.value)}
              value={values.additionalCubageValues[index]?.length}
            />
          </Flex>

          <Flex direction="column">
            <Flex gap="5px">
              <LabelDefault name="cbmCubageWeight" text="Cubagem (m³)" />

              <Tooltip label="Quantidade * altura * largura * comprimento" placement="auto">
                <Box>
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            </Flex>

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              decimalSeparator=","
              displayType="input"
              decimalScale={4}
              allowNegative={false}
              fixedDecimalScale
              readOnly
              placeholder="Cubagem"
              //
              name={`additionalCubageValues[${index}].cbmCubicMeters`}
              value={values.additionalCubageValues[index]?.cbmCubicMeters}
            />
          </Flex>

          <Flex direction="column">
            <Flex gap="5px">
              <LabelDefault name="cbmCubicWeight" text="Peso Cubado" />

              <Tooltip
                label={
                  <>
                    Peso cubado = cubagem * fator de cubagem <br /> <br /> Fator de cubagem é: <br /> modal rodoviário – 1 m³ é igual a
                    300kg <br /> modal marítimo – 1 m³ é igual a 1.000kg <br /> modal aéreo – 1 m³ é igual a 166,667kg
                  </>
                }
                placement="auto">
                <Box>
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            </Flex>

            <NumericFormat
              className="custom-input-mask"
              type="text"
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              decimalSeparator=","
              displayType="input"
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale
              readOnly
              placeholder="Peso Cubado"
              //
              name={`additionalCubageValues[${index}].cubicWeight`}
              value={values.additionalCubageValues[index]?.cubicWeight}
            />
          </Flex>

          <Flex justify="space-between">
            {index === values.additionalCubageValues.length - 1 && (
              <Tooltip label="Adicionar">
                <Box>
                  <IconButton
                    borderRadius="full"
                    bgColor="#2ECC7152"
                    border="1px dashed #2ECC71"
                    icon={<MdAdd color="#2ECC71" />}
                    onClick={() => handleAddClick()}
                  />
                </Box>
              </Tooltip>
            )}

            {values?.additionalCubageValues.length > 1 && (
              <Tooltip label="Excluir">
                <Box>
                  <IconButton icon={<MdDelete color="#E52359" />} onClick={() => handleDeleteClick(index)} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Grid>
      ))}

      <Divider my="20px" />

      <Text textStyle="cardTitle">Mercadoria</Text>
      <Grid gridTemplateColumns="3fr 2fr" gap="90px">
        <Flex direction="column">
          <FormControl>
            <LabelDefault
              text="Relação dos NCM's"
              style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', gap: '10px' }}>
              <Tooltip label="Tributação será aplicada conforme Legislação" placement="auto">
                <Box>
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            </LabelDefault>

            <Select
              id="ncms"
              name="ncms"
              className="outline-none transform-none text-black"
              placeholder="Digite os quatro primeiros digitos do código NCM. ex: 0101"
              styles={selectCustomStyles()}
              value=""
              options={ncmOptions}
              onChange={(option) => {
                setFieldValue('ncms', [
                  ...inputNcmsRelations,
                  {
                    code: option.value,
                    description: option.label,
                    exTariffs: '',
                  },
                ]);
                setInputNcmsRelations([
                  ...inputNcmsRelations,
                  {
                    code: option.value,
                    description: option.label,
                    exTariffs: '',
                  },
                ]);
                setNcmOptions([]);
              }}
              onInputChange={(inputValue) => {
                listOperationNcmsAction(inputValue);
              }}
              noOptionsMessage={() => noNcmOptionsMessage}
              isLoading={isLoadingNcmOptions}
            />
            <ErrorMessage component="p" className="error-message-error" name="ncms" />
          </FormControl>

          <Flex mt="5px">
            <i>
              {"NCM's"} selecionados ({inputNcmsRelations?.length})
            </i>
          </Flex>

          {values.ncms.map((entity, index) => (
            <Grid
              key={index}
              templateColumns="repeat(6, 1fr)"
              alignItems="center"
              gap="20px"
              border="1px"
              bgColor="#70707033"
              borderColor="#70707033"
              p="10px"
              mt="10px">
              <GridItem colSpan={5}>
                {entity?.description?.length > 100 ? (
                  <Text>
                    {entity.description.substring(0, 100)}
                    <Tooltip label={entity.description}>[...]</Tooltip>
                  </Text>
                ) : (
                  <p className="mt-5">{entity.description}</p>
                )}
              </GridItem>

              {/* <GridItem colSpan={2}>
                <InputDefault
                  setFieldValue={setFieldValue}
                  value={entity.exTariffs}
                  name={`ncms[${index}].exTariffs`}
                  placeholder="Preencha o EX tarifário ou deixe em branco"
                />
              </GridItem> */}

              <Button
                textAlign="end"
                w="min-content"
                onClick={() =>
                  handleRemoveChange(setFieldValue, index, values.ncms, 'ncms', inputNcmsRelations, setInputNcmsRelations, 'ncms')
                }>
                <MdDelete color="#E52359" />
              </Button>
            </Grid>
          ))}
        </Flex>

        <Flex maxW="600px" direction="column" alignItems="flex-start" gap="20px">
          <Flex direction="column" w="full">
            <LabelDefault name="description" text="Observação (opcional)" />

            <Textarea
              name="description"
              height="180px"
              resize="none"
              bg="#F2F2F2"
              value={values.description}
              onChange={(event) => setFieldValue('description', event.target.value)}
            />

            <ErrorMessage component="p" className="error-message-error" name="description" />
          </Flex>

          <Flex gap="40px">
            <Flex direction="column">
              <LabelDefault name="copyPeople3Email" text="Contato cópia 1 (e-mail)" />

              <InputDefault
                type="email"
                name="copyPeople3Email"
                placeholder="Preencha o contato cópia 1 (e-mail)"
                setFieldValue={setFieldValue}
                value={values.copyPeople3Email}
              />

              <ErrorMessage component="p" className="error-message-error" name="copyPeople3Email" />
            </Flex>

            <Flex direction="column">
              <LabelDefault name="copyPeople2" text="Contato cópia 2 (e-mail)" />

              <Select
                styles={selectCustomStyles()}
                name="copyPeople2"
                className="input-register"
                placeholder="Selecone o contato cópia 2 (e-mail)"
                options={userOptions}
                value={values.copyPeople2}
                isLoading={loadUserOptions}
                isDisabled={!values.client}
                onChange={(option) => {
                  setFieldValue('copyPeople2', option);
                }}
              />

              <ErrorMessage component="p" className="error-message-error" name="copyPeople2" />
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    </Flex>
  );
};

export default FormMerchandiseCargo;
