import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import ModalCSV from '../../../../components/Modal/CSV/ModalCSV';

import ChartUnitsDelivered from './ChartUnitsDelivered';

const CardUnitsDelivered = () => {
  return (
    <Flex
      bgColor="#FFFFFF"
      px="45px"
      py="50px"
      direction="column"
      gap="40px"
      border="1px"
      borderColor="#7070703D"
      className="up-anim">
      <Flex gap="64px" align="center">
        <Text textStyle="tableTitle" fontSize="26px" textColor="linkColor" fontWeight="medium">
          Unidades entregues
        </Text>

        <ModalCSV />

        <Flex gap="30px" align="center">
          <Flex gap="15px">
            <Box w="42px" borderRadius="3px" bgColor="green" />

            <Text textStyle="subtitle" textColor="#0F0A1D" fontWeight="medium">
              No prazo
            </Text>
          </Flex>

          <Flex gap="15px">
            <Box w="42px" borderRadius="3px" bgColor="red" />

            <Text textStyle="subtitle" textColor="#0F0A1D" fontWeight="medium">
              Fora do prazo
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <ChartUnitsDelivered />
    </Flex>
  );
};

export default CardUnitsDelivered;
