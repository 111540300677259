import React from 'react';

import { ImLocation } from 'react-icons/im';

import { Box, Grid, GridItem } from '@chakra-ui/react';

const TrajectoryPublic = ({ entity, setCenter, setZoom }) => {
  const formartType = (type) => {
    switch (type) {
      case 'COLETA':
        return `Coleta`;
      default:
        return `Entrega`;
    }
  };

  return (
    <>
      {entity?.trajectories?.length > 0 &&
        entity.trajectories.map(function (trajectory, key) {
          return (
            <Grid
              h="full"
              gridTemplateColumns={'60px 1fr 30px'}
              fontSize={{ sm: '12px', md: '14px' }}
              gap={'20px'}
              alignItems={'center'}
              p={'16px 24px'}
              borderBottom={'1px solid #E2E8F0'}
              key={key}>
              <GridItem color="#854FFF">{formartType(trajectory?.type)}</GridItem>

              <GridItem color="#0F0A1DB3">
                <Box>{trajectory?.address}</Box>
              </GridItem>

              <GridItem color="#854FFF" display="flex" gap="15">
                {trajectory?.latitude != 0 && trajectory?.longitude != 0 && (
                  <ImLocation
                    color="#ea4335"
                    cursor="pointer"
                    size="30px"
                    onClick={(decision) => {
                      if (decision) {
                        setCenter({
                          lat: trajectory?.latitude,
                          lng: trajectory?.longitude,
                        });
                        setZoom(18);
                      }
                    }}
                  />
                )}
              </GridItem>
            </Grid>
          );
        })}
    </>
  );
};

export default TrajectoryPublic;
