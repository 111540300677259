import React, { useEffect, useState } from 'react';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const ProductList = ({ process }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getData = () => {
    if (!process || !process.identifier) {
      return;
    }

    requests
      .getProcessItem(process.identifier)
      .then((data) => {
        setData(data.data);
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    getData();
  }, [process]);

  return (
    <>
      <Box overflowX="auto">
        <ScreenLoader isLoading={isLoadingData}>
          <Table className="table-default">
            <Thead border="1px" borderBottom="1px">
              <Tr>
                <Th>TEC (NCM)</Th>
                <Th>Descrição</Th>
                <Th>Quantidade</Th>
                <Th>Peso</Th>
                <Th>Moeda</Th>
                <Th>Unidade</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody border="1px">
              {data.length > 0 &&
                data.map((entity, key) => (
                  <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                    <Td>{entity?.ncm ?? '-'}</Td>
                    <Td>{entity?.description ?? '-'}</Td>
                    <Td>{entity?.quantity ?? '-'}</Td>
                    <Td>{entity?.weightGrossFormatted ?? '-'}</Td>
                    <Td>{entity?.currency ?? '-'}</Td>
                    <Td>{entity?.valueUnitFormatted ?? '-'}</Td>
                    <Td>{entity?.valueTotalFormatted ?? '-'}</Td>
                  </Tr>
                ))}

              {isLoadingData ? (
                <Tr>
                  <Td colSpan={7}>
                    <Flex justify="center" my="25px">
                      Carregando...
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                <>
                  {data.length === 0 && (
                    <Tr>
                      <Td colSpan={7}>
                        <Flex justify="center" my="25px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </Tbody>
          </Table>
        </ScreenLoader>
      </Box>
    </>
  );
};

export default ProductList;
