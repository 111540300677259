import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { VoyageListContent } from './VoyageListContent';
import { VoyageListIcon } from './VoyageListIcon';
import { VoyageListTitle } from './VoyageListTitle';

export const VoyageList = ({ waypoints, path, icon, title, description, labels, process }) => {
  if (!waypoints || !path || !title || !description) {
    return null; // Retorna null se algum dos props estiver ausente
  }

  const type = description[0].type;
  const value = description[0].value;
  const mapInformation = waypoints[path];

  const getTitleValue = (voyage, title) => {
    return title.reduce((acc, curr) => (acc ? acc[curr] : ''), voyage);
  };

  return (
    <>
      {type && type === 'stops' ? (
        //stops
        mapInformation.map(
          (voyage, key) =>
            voyage && (
              <Flex key={key} gap="15px" flexDirection="column" m="30px 20px 0 23px">
                <Flex flexDirection="column" position={'relative'}>
                  <VoyageListIcon process={process}>{icon}</VoyageListIcon>

                  <VoyageListTitle>
                    <Text display={'flex'} alignItems={'center'} gap={'5px'} flexWrap={'wrap'}>
                      {key + 1}º viagem: <Text fontWeight="normal">de</Text> {voyage?.origin.title}
                      <Text fontWeight="normal">para</Text> {voyage?.destination.title}
                      <Box fontSize={'12px'} color={'primary'} fontWeight={'500'}>
                        ({'Navio: ' + getTitleValue(voyage, title)})
                      </Box>
                    </Text>
                  </VoyageListTitle>

                  <VoyageListContent labels={labels} content={voyage} />
                </Flex>
              </Flex>
            )
        )
      ) : (
        //origin && destination
        <Flex flexDirection="column" m="30px 20px 0 23px" position={'relative'}>
          <VoyageListIcon process={process}>{icon}</VoyageListIcon>
          <VoyageListTitle>
            {value && value} {mapInformation?.title}
          </VoyageListTitle>
          <VoyageListContent labels={labels} content={mapInformation} />
        </Flex>
      )}
    </>
  );
};
