import React, { useState } from 'react';

import { toast } from 'react-toastify';

import requests from '../../../../services/requests';

import ModalFormFaq from './ModalFormFaq';

const ModalNewFaq = ({ addTopicToList }) => {
  const [typeSystem, setTypeSystem] = useState('');
  const [modality, setModality] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [load, setLoad] = useState(false);

  const handlesetTypeSystemChange = (event) => {
    setTypeSystem(event.target.value);
  };

  const handleModalityChange = (event) => {
    setModality(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    setLoad(true);

    try {
      await requests.addFaq({
        title,
        description,
        typeSystem,
        modality,
        videoUrl: null,
        status: 'DRAFT',
      });

      addTopicToList();
      setLoad(false);
      toast.success('Tópico criado com sucesso');
    } catch (error) {
      toast.error('Erro ao criar o tópico, tente novamente!');
      setLoad(false);
    }
  };

  return (
    <>
      <ModalFormFaq
        txtButton="Novo tópico"
        txtHeader="Cadastro de tópico FAQ"
        textButtonConfirm="Salvar"
        onSubmit={onSubmit}
        title={title}
        description={description}
        typeSystem={typeSystem}
        modality={modality}
        handlesetTypeSystemChange={handlesetTypeSystemChange}
        handleModalityChange={handleModalityChange}
        handleTitleChange={handleTitleChange}
        handleContentChange={handleContentChange}
        load={load}
      />
    </>
  );
};

export default ModalNewFaq;
