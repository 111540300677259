const yup = require('yup');

const contactValidation = (isDraft = false) => {
  let validation = yup.object().shape({
    commercial: isDraft
    ? yup.array()
    : yup
        .array(
          yup.object({
            commercialName: yup.string(),
            commercialPhone: yup.string(),
            commercialEmail: yup.string(),
          })
        )
        .test({
          message: 'Nome, Email e Telefone devem ser completamente preenchidos',
          test: (arr) => {
            return arr.find(({ commercialName, commercialPhone, commercialEmail }) => {
              return commercialName && commercialPhone && commercialEmail;
            });
          },
        }),
  operation: isDraft
    ? yup.array()
    : yup
        .array(
          yup.object({
            operationName: yup.string(),
            operationPhone: yup.string(),
            operationEmail: yup.string(),
          })
        )
        .test({
          message: 'Nome, Email e Telefone devem ser completamente preenchidos',
          test: (arr) => {
            return arr.find(({ operationName, operationPhone, operationEmail }) => {
              return operationName && operationPhone && operationEmail;
            });
          },
        }),
  financial: isDraft
    ? yup.array()
    : yup
        .array(
          yup.object({
            financialName: yup.string(),
            financialPhone: yup.string(),
            financialEmail: yup.string(),
          })
        )
        .test({
          message: 'Nome, Email e Telefone devem ser completamente preenchidos',
          test: (arr) => {
            return arr.find(({ financialName, financialPhone, financialEmail }) => {
              return financialName && financialPhone && financialEmail;
            });
          },
        }),
  legal: isDraft
    ? yup.array()
    : yup
        .array(
          yup.object({
            legalName: yup.string(),
            legalPhone: yup.string(),
            legalEmail: yup.string(),
          })
        )
        .test({
          message: 'Nome, Email e Telefone devem ser completamente preenchidos',
          test: (arr) => {
            return arr.find(({ legalName, legalPhone, legalEmail }) => {
              return legalName && legalPhone && legalEmail;
            });
          },
        }),
  });

  return validation;
};

export default contactValidation;
