const yup = require('yup');

export const validationSchema = () => {
  return yup.object().shape({
    companyBranchName: yup.string().required('Campo obrigatório'),
    companyBranchDocumentNumber: yup.string().required('Campo obrigatório'),
    stateCodeBilling: yup.string().required('Campo obrigatório'),
    clientDocumentNumber: yup.string().required('Campo obrigatório'),
    stateCodeResidenceDocumentNumber: yup.string().required('Campo obrigatório'),
    isIcmsTaxpayer: yup.string().required('Campo obrigatório'),
    stateRegistration: yup.string().required('Campo obrigatório'),
    typeOfSale: yup.string().required('Campo obrigatório'),
    taxRegime: yup.string().required('Campo obrigatório'),
    importModality: yup.string().required('Campo obrigatório'),
    finality: yup.string().required('Campo obrigatório'),
    taxBenefit: yup.string().required('Campo obrigatório'),
    requestedAt: yup.string().required('Campo obrigatório'),
    expiresAt: yup.string().required('Campo obrigatório'),
    operationObservation: yup.string(),
    fiscalObservation: yup.string(),
    status: yup.string().required('Campo obrigatório'),
    products: yup.string().required('Campo obrigatório'),
    responsibles: yup.string().required('Campo obrigatório'),
  });
};
