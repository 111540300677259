import React, { useState } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react';

const CreditAnalysisAccordion = ({ content }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Accordion allowToggle defaultIndex={isOpen ? 0 : -1} mt="4" border="1px" borderColor="#7070703D">
      <AccordionItem border="0px">
        <AccordionButton>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            flex="1"
            textAlign="left"
            justifyContent={{ base: 'flex-end', md: 'space-between' }}>
            <Box fontSize="1.5rem" fontWeight="bold" color="purple-400">
              {content?.header?.title}
            </Box>
            <Flex alignSelf={'end'}>
              <Flex direction={{ base: 'column', md: 'row' }} mr="2">
                <Flex direction="column" mr={{ base: '1', md: '6' }}>
                  <Text textAlign="left" fontWeight="bold" color="purple-400">
                    Sit. Cadastral
                  </Text>
                  <Text
                    p="1px 12px"
                    border="solid 1px"
                    borderRadius="7px"
                    alignItems="center"
                    align="center"
                    color={content?.header?.colorStatus}
                    fontSize={{ base: '12px', md: '14px' }}>
                    {content?.header?.status ?? '-'}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text textAlign="left" fontWeight="bold" color="purple-400">
                    Result. consulta
                  </Text>
                  <Text
                    p="1px 12px"
                    border="solid 1px"
                    borderRadius="7px"
                    alignItems="center"
                    align="center"
                    color={content?.header?.colorResult}
                    fontSize={{ base: '12px', md: '14px' }}>
                    {content?.header?.result ?? '-'}
                  </Text>
                </Flex>
              </Flex>
              <AccordionIcon />
            </Flex>
          </Flex>
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Flex flexWrap="wrap" gap="10px">
            {content?.body?.map((item, index) => (
              <Box key={index} width={{ base: '100%', md: 'calc(50% - 8px)' }} mb={{ base: '4', md: '8px' }}>
                <Flex direction="column" border="1px" borderColor={'#e2e8f0'} p="4" borderRadius="md" height="6.3rem" overflowY="auto">
                  <Text fontWeight="bold" color="primary">
                    {item.title}
                  </Text>
                  {Array.isArray(item.description) && item.description.length > 0 ? (
                    item.description.map((value, key) => {
                      if (typeof value === 'string') {
                        return (
                          <>
                            <Text key={key}>{value}</Text>
                          </>
                        );
                      } else if (typeof value?.description === 'string') {
                        return (
                          <>
                            <Text fontWeight="bold">{value?.title}</Text>
                            <Text key={key}>{value?.description}</Text>
                          </>
                        );
                      }

                      let textElements = null;
                      if (Array.isArray(value?.description)) {
                        textElements = value?.description?.map((value2, key2) => {
                          if (typeof value2 === 'string') {
                            return <Text key={key2}>{value2}</Text>;
                          }
                        });

                        return (
                          <>
                            <Text fontWeight="bold">{value?.title}</Text>
                            {textElements}
                          </>
                        );
                      }

                      return;
                    })
                  ) : (
                    <Text>{item.description}</Text>
                  )}
                </Flex>
              </Box>
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CreditAnalysisAccordion;
