import React, { useEffect, useState } from 'react';

import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from 'recharts';

import { Flex, Grid, Image, Text } from '@chakra-ui/react';

import footprint from '../../../assets/svgs/process-calculated.svg';

const CarbonFootprintChart = ({ carbonFootprint, sizeWidth }) => {
  const [entity, setEntity] = useState([]);

  const formatNumber = (value) => (isNaN(value) ? 0 : value);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let calculating = parseFloat(carbonFootprint.calculating);
        let calculated = parseFloat(carbonFootprint.calculated);

        let total = formatNumber(calculating) + formatNumber(calculated);

        const chartData = [
          {
            name: 'calculating',
            qtt: formatNumber(total),
            color: '#70B961',
            icon: footprint,
          },
        ];

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [carbonFootprint]);

  return (
    <Flex h="full" align="center" justify="space-between" p="20px" direction="column">
      <BarChart data={entity} width={sizeWidth} height={300}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="qtt" tickFormatter={(value) => `${value.toLocaleString()} CO₂eq`} />
        <YAxis tickFormatter={(value) => `${value}`} />

        <Bar dataKey="qtt" barSize={120}>
          {entity.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>

      <Grid templateColumns="repeat(1, 1fr)" gap="5px">
        <Flex gap="8px" align="center">
          <Image width="14px" src={footprint} />

          <Text textStyle={{ base: 'paragraph', xl: 'subtitle' }} textColor="titleColor" textAlign="left">
            Pegada de carbono
          </Text>
        </Flex>

        {/* <Flex gap="8px" align="center">
          <Image width="14px" src={neutral} />

          <Text textStyle={{ base: 'paragraph', xl: 'subtitle' }} textColor="titleColor" textAlign="left">
            Carbono compensado
          </Text>
        </Flex> */}
      </Grid>
    </Flex>
  );
};

export default CarbonFootprintChart;
