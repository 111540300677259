import React from 'react';

import { MdHelp, MdOutlineOpenInNew } from 'react-icons/md';

import { AccordionButton, AccordionIcon, AccordionPanel, Box, Flex, IconButton, Image, Text, Tooltip } from '@chakra-ui/react';

import useResponsiveCheck from '../../../../hooks/useResponsiveCheck';
import { processCheck, processUncheck } from '../../../../utils/exports';
import { clamp } from '../../../../utils/math';
import { toPercentage } from '../../../../utils/text/format/toPercentage';

import ProcessInfo from './ProcessInfo';
import ProcessTimelineStep from './ProcessTimelineStep';
const ProcessListItem = ({ process, itemCount = 0, processPriority, forceLoadTrigger, setForceLoadTrigger }) => {
  const windowWidth = window.innerWidth;

  const responsiveCheck = useResponsiveCheck();

  const { viewport } = responsiveCheck;
  const isMaritime =
    process.currentStageModalitySequential === 2 && process?.lastVoyageVessel && process?.transportModality === 'Marítimo';

  const getSteps = () => {
    return [
      {
        title: 'Pré embarque',
        progress: clamp(process.progressBar / 20.0 - 0, 0, 1),
        block: false,
        infos: [
          process?.startedAtFormatted && { label: 'Abertura', value: process?.startedAtFormatted },
          process?.originPortName && { label: 'Origem', value: process?.originPortName },
        ],
      },
      {
        title: (
          <Flex align={'center'} color="linkColor">
            {`Trânsito`}
            <Text pl={'5px'}>
              {viewport < 1600
                ? isMaritime && (
                    <Tooltip label={'(Navio: ' + process.lastVoyageVessel + ')'}>
                      <Box ml="0.325rem">
                        <MdHelp size={15} color="#422C76" />
                      </Box>
                    </Tooltip>
                  )
                : isMaritime
                ? '(Navio: ' + process.lastVoyageVessel + ')'
                : ''}
            </Text>
          </Flex>
        ),
        progress: clamp(process.progressBar / 20.0 - 1, 0, 1),
        borderGreen: process.carbonTrackTransitStage.calculated > 0 ? true : false,
        block: false,
        infos: [
          process?.boardedAtFormatted && { label: 'Partida', value: process?.boardedAtFormatted },
          process?.destinyPortName && { label: 'Destino', value: process?.destinyPortName },
          process?.containerList && {
            label: 'Containers',
            value: process?.containerList?.containers?.length,
            container: (
              <IconButton
                px="5px"
                py="3px"
                minW="none"
                h="none"
                bg="#D0B0FD36"
                _hover={{ color: 'primary', transition: '0.2s' }}
                icon={<MdOutlineOpenInNew color="#6C48C2" size={15} />}
              />
            ),
          },
          process?.carbonTrackTransitStage &&
            process?.carbonTrackTransitStage.status !== 'PREVENTED' && {
              label: 'Pegada CO₂',
              value: process.carbonTrackTransitStage.calculated ?? 'Calculando',
              block: false,
              disableDrawer: true,
              tooltipText: process.carbonTrackTransitStage.calculated
                ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${
                    process.carbonTrackTransitStage.calculated
                  } toneladas de C02eq com ${toPercentage(
                    process.carbonTrackTransitStage.retired / process.carbonTrackTransitStage.calculated
                  )} compensados`
                : 'O cálculo para esta etapa está indisponível',
              /* container: !process?.qtt ? 'Calculando...' : 'Indisponível', */
              container:
                process.carbonTrackTransitStage.retired > 0 ? (
                  <Image src={processCheck} opacity={'1'} />
                ) : (
                  <Image src={processUncheck} opacity={'1'} />
                ),
            },
        ],
      },
      {
        title: 'Desembaraço',
        progress: clamp(process.progressBar / 20.0 - 2, 0, 1),
        block: false,
        infos: [
          process?.arrivedAtFormatted
            ? { label: 'Chegada', value: process.arrivedAtFormatted }
            : process?.arrivalExpectedAtFormatted && {
                label: process?.clearenceDiAtFormatted ? 'Previsão' : 'Prev. chegada',
                value: process.arrivalExpectedAtFormatted,
              },
          process?.diNumber && { label: 'DI', value: process.diNumber },
          process?.clearenceDiAtFormatted && { label: 'Registro', value: process.clearenceDiAtFormatted },
          process?.clearenceCiAtFormatted && { label: 'CI', value: process.clearenceCiAtFormatted },
        ],
      },
      {
        title: !process?.billedAtFormatted ? 'Em faturamento' : 'Faturado',
        progress: !process?.billedAtFormatted && process.progressBar > 60 ? 0 : clamp(process.progressBar / 20.0 - 3, 0, 1),
        block: false,
        infos: [
          process?.billedAtFormatted && { label: 'Emissão', value: process.billedAtFormatted },
          process?.idNota && { label: 'NF', value: process.idNota },
        ],
      },
      {
        title: 'Transporte',
        progress: clamp(process.progressBar / 20.0 - 4, 0, 1),
        borderGreen: process.carbonTrackDeliverStage.calculated > 0 ? true : false,
        block: false,
        infos: [
          process?.storedAtFormatted && { label: 'Carregamento', value: process.storedAtFormatted },
          process?.deliveredAtFormatted && { label: 'Entrega', value: process.deliveredAtFormatted },
          process?.carbonTrackDeliverStage && {
            label: 'Pegada CO₂',
            value: process.carbonTrackDeliverStage.calculated ?? 'Calculando',
            block: false,
            disableDrawer: true,
            tooltipText: process.carbonTrackDeliverStage.calculated
              ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${
                  process.carbonTrackDeliverStage.calculated
                } toneladas de C02eq com ${toPercentage(
                  process.carbonTrackDeliverStage.retired / process.carbonTrackDeliverStage.calculated
                )} compensados`
              : 'O cálculo para esta etapa está indisponível',
            /* container: !process?.qtt ? 'Calculando...' : 'Indisponível', */
            container:
              process.carbonTrackTransitStage.retired > 0 ? (
                <Image src={processCheck} opacity={'1'} />
              ) : (
                <Image src={processUncheck} opacity={'1'} />
              ),
          },
        ],
      },
    ];
  };

  const flagClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Flex direction="column" p="10px" transition=".3s" borderTop="#70707036 solid 1px" _hover={{ bg: '#e4e6e761' }}>
      {windowWidth > 768 ? (
        <>
          <ProcessInfo
            process={process}
            processPriority={processPriority}
            forceLoadTrigger={forceLoadTrigger}
            setForceLoadTrigger={setForceLoadTrigger}
          />

          <Flex
            mt="10px"
            w="100%"
            justify="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="baseline"
            p={{ base: '25px 0 10px', md: 'inherit' }}>
            {getSteps().map((step, i) => (
              <ProcessTimelineStep
                key={i}
                stepCount={i}
                delay={i + itemCount}
                progress={step.progress}
                title={step.title}
                infos={step.infos}
                process={process}
                isBlock={step.block}
                tooltipText={step.tooltipText}
                disableDrawer={step.disableDrawer}
                borderGreen={step.borderGreen}
              />
            ))}
          </Flex>
        </>
      ) : (
        <>
          <AccordionButton onClick={flagClick.bind(this)} _hover={{ bg: 'transparent' }} p={'10px 4px'} alignItems={'baseline'}>
            <ProcessInfo process={process} />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel>
            <Flex
              w="100%"
              justify="space-between"
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems="baseline"
              p={{ base: '25px 0 10px', md: 'inherit' }}>
              {getSteps().map((step, i) => (
                <ProcessTimelineStep
                  key={i}
                  stepCount={i}
                  delay={i + itemCount}
                  progress={step.progress}
                  title={step.title}
                  infos={step.infos}
                  process={process}
                  isBlock={step.block}
                  tooltipText={step.tooltipText}
                  disableDrawer={step.disableDrawer}
                  borderGreen={step.borderGreen}
                />
              ))}
            </Flex>
          </AccordionPanel>
        </>
      )}
    </Flex>
  );
};

export default ProcessListItem;
