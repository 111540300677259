import React, { useRef } from 'react';

import { Avatar, Button, Divider, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';

import '../../../styles/globals.css';

const EditInfos = ({ profile = {}, setProfile = () => {}, newPicture, setNewPicture, imageBlob, setImageBlob }) => {
  const fileInputRef = useRef(null);

  function handleInputImage(e) {
    const file = e.target.files[0];
    setNewPicture(file);
    setImageBlob(URL.createObjectURL(file));
  }

  function handleButtonClick() {
    fileInputRef.current.click();
  }

  return (
    <Flex gap="20px" direction="column">
      <Flex justify="space-between" align="center">
        <Flex direction="column" gap="10px">
          <Flex alignItems="center">
            <Avatar
              src={newPicture ? imageBlob : profile.tempPicture || profile.picture}
              name={profile.name}
              w="120px"
              h="120px"
              border="3px solid #422C76"
              cursor="pointer"
            />

            <Flex direction="column" marginLeft="20px">
              <Button variant="primary" onClick={handleButtonClick}>
                Alterar Foto
              </Button>

              <input type="file" accept=".png, .jpeg" onChange={handleInputImage} hidden ref={fileInputRef} />
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" gap="10px">
          <FormControl direction="column">
            <FormLabel color="primary">Nome completo</FormLabel>

            <Input
              placeholder="Nome"
              w="300px"
              variant="primary"
              type="text"
              value={profile.name}
              name="name"
              id="name"
              onChange={(event) => setProfile({ ...profile, name: event.target.value })}
            />
          </FormControl>

          <FormControl direction="column">
            <FormLabel color="primary">E-mail</FormLabel>

            <Input
              placeholder="E-mail"
              w="300px"
              variant="primary"
              type="email"
              value={profile.email}
              name="email"
              id="email"
              onChange={(event) => setProfile({ ...profile, email: event.target.value })}
            />
          </FormControl>
        </Flex>
      </Flex>

      <Divider borderColor="#7070702E" />

      <Flex justify="space-between">
        <FormControl w="fit-content">
          <FormLabel w="fit-content" color="primary">
            Celular (Opcional)
          </FormLabel>

          <Input
            placeholder="(11) 9 9999-9999"
            w="300px"
            variant="primary"
            type="text"
            value={profile.cellphone}
            name="cellphone"
            id="cellphone"
            onChange={(event) => setProfile({ ...profile, cellphone: event.target.value })}
          />
        </FormControl>

        <FormControl w="fit-content">
          <FormLabel w="fit-content" color="primary">
            Telefone (Opcional)
          </FormLabel>

          <Input
            placeholder="(11) 9999-9999"
            w="300px"
            variant="primary"
            type="text"
            value={profile.phone}
            name="phone"
            id="phone"
            onChange={(event) => setProfile({ ...profile, phone: event.target.value })}
          />
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default EditInfos;
