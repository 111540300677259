import React from 'react';

import { ErrorMessage } from 'formik';
import { MdHelp } from 'react-icons/md';
import Select from 'react-select';

import { Box, Flex, FormControl, FormLabel, Input, Tooltip } from '@chakra-ui/react';

import FilterOption from '../../../../utils/filters/FilterOption';
import { addFilter, getFilter } from '../../../../utils/storageFilter';

const FormConfigFilter = ({ filterOptions, handleChange, screen, setFilters, userOptions, values, setFieldValue }) => {
  let profileRole = localStorage.getItem('profile-role');

  const handleSelectChange = (field, value) => {
    const selectedValues = Array.isArray(value) ? value : [value];
    const lastIndex = selectedValues?.length - 1;
    let clientValues = [];

    const selectedChildren = selectedValues.flatMap((item, key) => {
      if (key === lastIndex) {
        return [
          item,
          ...item.children.map((child) => ({
            label: ` ${'\u00A0'} - ${child.name} (${child.documentNumberFormatted})`,
            value: child.identifier,
          })),
        ];
      } else {
        // Se não for o último item, retorna apenas o item sem mapear os filhos
        return item;
      }
    });

    selectedChildren.forEach((client) => {
      let identifierClient;

      if (typeof client === 'object') {
        identifierClient = client.value;
      } else {
        identifierClient = client;
      }

      clientValues.push(identifierClient);
    });

    addFilter(screen, field, clientValues);
    setFieldValue('clients', clientValues);
    setFilters(getFilter(screen));
  };

  return (
    <Flex gap="20px" direction="column">
      <Flex gap="20px" direction={{ sm: 'column', md: 'row' }}>
        <FormControl>
          <Flex>
            <FormLabel mr="3px" fontWeight="bold">
              Nome do filtro
            </FormLabel>

            <Tooltip label="Se for necessário salvar um filtro, este campo é obrigatório">
              <Box>
                <MdHelp color="#422C76" />
              </Box>
            </Tooltip>
          </Flex>

          <Input
            h="38px"
            name="name"
            placeholder="Digite um nome para o filtro"
            borderRadius="4px"
            borderColor="hsl(0, 0%, 80%)"
            _placeholder={{ fontSize: '16px', color: 'hsl(0, 0%, 50%)' }}
            _focus={{ border: '1px', boxShadow: 'none' }}
            value={values.name}
            onChange={handleChange}
          />

          <ErrorMessage name="name" component="p" className="error-message-error" />
        </FormControl>

        {profileRole !== 'ROLE_CLIENT' && (
          <FormControl>
            <Flex>
              <FormLabel mr="3px" fontWeight="bold">
                Duplicar filtro ao usuário
              </FormLabel>

              <Tooltip label="Se for necessário salvar este filtro para um usuário, basta selecioná-lo.">
                <Box>
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            </Flex>

            <Select
              placeholder="Selecione um usuário"
              options={userOptions}
              value={userOptions?.filter((option) => values?.user && values.user?.value && values.user.value.includes(option.value))}
              onChange={(option) => setFieldValue('user', option)}
              isClearable
            />
          </FormControl>
        )}
      </Flex>

      <FormControl>
        {filterOptions &&
          filterOptions.map((item, key) => {
            if (!(item instanceof FilterOption)) {
              return (
                <React.Fragment key={key}>
                  <FormLabel fontWeight="bold">{item.label}</FormLabel>

                  <Select
                    name={key}
                    placeholder="Selecione"
                    isMulti={true}
                    closeMenuOnSelect={false}
                    options={item.options}
                    value={item?.options?.filter((option) => values.clients.includes(option.value))}
                    onChange={(option) => handleSelectChange(item.name, option)}
                    getOptionLabel={(option) => option.label}
                    components={{
                      ClearIndicator: ({ innerProps }) => (
                        <Flex
                          textColor="red"
                          fontSize="14px"
                          fontWeight="bold"
                          pr="10px"
                          cursor="pointer"
                          _hover={{ opacity: '0.8', transition: '0.3s' }}
                          {...innerProps}>
                          Limpar
                        </Flex>
                      ),
                    }}
                  />
                </React.Fragment>
              );
            }
            return null;
          })}
      </FormControl>
    </Flex>
  );
};

export default FormConfigFilter;
