import moment from 'moment';

import { Text } from '@chakra-ui/react';

import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Listagem de perguntas frequentes',
  // Estrutura da tabela
  categories: [
    {
      title: 'Modalidade',
      value: 'modality',
      variantTag: 'softDashed',
    },
    {
      title: 'Título',
      value: 'title',
    },
    {
      title: 'Conteúdo',
      value: 'description',
    },
    {
      title: 'Data de Criação',
      value: 'renderHTMLCreatedAt',
      align: 'center',
      renderHTML: (item) => <Text>{moment(item.updatedAt).format('DD/MM/YYYY')}</Text>,
    },
    {
      title: 'Tipo',
      value: 'typeSystem',
      align: 'center',
      variantTag: 'softDashed',
    },

    {
      title: 'Ações',
      align: 'center',
      defaultValue: '',
    },
  ],
  mainCategoryMobile: 'title',
  collapsedContentType: 'table',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  tags: ['typeSystem', 'modality'],
  statusPreset: [
    { color: '#422C76', label: 'Painel Analytics', value: 'SYSTEM_ANALYTICS' },
    { color: '#2EC5CC', label: 'App do Motorista', value: 'APP_DRIVER' },
    { color: '#2ECC71', label: 'Autenticado', value: 'AUTHENTICATED' },
    { color: '#E74C3C', label: 'Público', value: 'ANONYMOUS' },
  ],
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 80,
  maxLengthResumeChildren: 50,
  hasDeleteButton: true,
  deleteButtonSubtitle: (
    <>
      Deseja mesmo excluir esse tópico?
      <br />
      Ele sumirá automaticamente da página de FAQ.
    </>
  ),
  // Estilo
  resizeRows: '180px 400px 1fr 150px 220px 80px',
};
