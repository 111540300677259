const yup = require('yup');

const partTripValidation = () => {
  let validation = yup.object().shape({
    type: yup.string().required('Campo obrigatório'),
    cteNumber: yup.string().required('Campo obrigatório'),
    address: yup.string().required('Campo obrigatório'),
    observation: yup.string().nullable(),
    latitude: yup.mixed(),
    longitude: yup.mixed(),
    clients: yup.string().required('Campo obrigatório'),
  });

  return validation;
};

export default partTripValidation;
