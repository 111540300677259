import React, { useEffect, useState } from 'react';

import { ToastContainer } from 'react-toastify';

import { Flex, Grid, Image, Text } from '@chakra-ui/react';

import highlight from '../../../assets/images/highlight.png';
import carbono from '../../../assets/images/login/bgs/carbono.png';
import carbono_neutro from '../../../assets/images/login/bgs/carbono_neutro.png';
// import gptw from '../../../assets/images/login/bgs/gptw.png';
import logo_vendemmia from '../../../assets/images/login/bgs/logo_vendemmia.svg';
import madala from '../../../assets/images/login/bgs/madala.gif';
import vendemmia_evoluted from '../../../assets/images/login/bgs/vendemmia_evoluted.svg';
import vendemmia_evoluted_mobile from '../../../assets/images/login/bgs/vendemmia_evoluted_mobile.svg';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import FAQ from '../../Modal/FAQ/FAQ';
import '../BackgroundLogin/styles.css';

const BackgroundLogin = ({ children, showLogo = true, maxW = '400px' }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const imagesRight = [vendemmia_evoluted, madala];

  const responsiveCheck = useResponsiveCheck();
  const isMobile = responsiveCheck.isMobile;
  const viewport = responsiveCheck.viewport;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % imagesRight.length);
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const backgroundImageStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: -1,
    transition: 'background-image 0.5s',
    backgroundImage: `url(${imagesRight[imageIndex]})`,
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Flex className="background-login" justify="center" align={viewport >= '768' ? 'center' : 'flex-start'} overflow="auto">
      {isMobile ? (
        <Flex w="full" h="full" direction="column" justify="space-between" p="30px">
          <Flex w="full" h="full" align="center" direction="column">
            <Image src={logo_vendemmia} h="80px" mb={{ base: '50px', sm: '100px' }} />

            <Flex w="full" maxW="350px" justify="center">
              {children}
            </Flex>

            <Image src={vendemmia_evoluted_mobile} mt="50px" w="full" maxW="400px" />
          </Flex>

          <Flex
            w="full"
            mt="50px"
            pb="30px"
            justify="space-between"
            direction={{ base: 'column', sm: 'row' }}
            align={{ base: 'center', sm: 'end' }}
            gap={{ base: '20px', sm: '0' }}>
            <FAQ
              _hover={{ textColor: '#FF2F69', textDecoration: 'none' }}
              selectedContent={2}
              showSupportRequest={false}
              showInfoVendemmia={false}
              customButton={
                <Text
                  textStyle="paragraph"
                  textColor="#FFFFFF"
                  transition=".3s"
                  _hover={{ textColor: '#FF2F69', textDecoration: 'none' }}>
                  Política de Privacidade
                </Text>
              }
            />

            <Flex maxW="120px" direction="column" align="flex-end" justify="space-between" gap="10px">
              <Image src={carbono} />
              <Image src={carbono_neutro} />
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Grid gridTemplateColumns={viewport > '1024' ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} w="full" h="full">
          <Flex
            direction="column"
            align={viewport > '1024' ? 'flex-start' : 'center'}
            justify={viewport > '1024' ? 'space-between' : 'flex-start'}
            m={{ base: '50px', xl: '140px' }}>
            {viewport < '1024' && <Image src={logo_vendemmia} h="130px" mb="100px" />}

            <Flex w="full" maxW={maxW}>
              {children}
            </Flex>

            {showLogo && viewport >= '1024' && (
              <Flex w="full" align="center" mt="20px" className="down-anim">
                <Image src={logo_vendemmia} w="full" h={{ base: '150px', xl: '200px' }} />
              </Flex>
            )}

            <FAQ
              position="fixed"
              bottom="20px"
              left="20px"
              _hover={{ textColor: '#FF2F69', textDecoration: 'none' }}
              showSupportRequest={false}
              showInfoVendemmia={false}
              selectedContent={2}
              customButton={
                <Text
                  textStyle="paragraph"
                  textColor="#FFFFFF"
                  transition=".3s"
                  _hover={{ textColor: '#FF2F69', textDecoration: 'none' }}>
                  Política de Privacidade
                </Text>
              }
            />

            <Flex direction="column" justify="space-between" position="fixed" bottom="20px" right="20px">
              {imageIndex ? (
                <>
                  <Image src={carbono} h={{ base: '60px', xl: '70px', xx: '80px' }} />
                  <Image src={carbono_neutro} h={{ base: '60px', xl: '70px', xx: '80px' }} />
                </>
              ) : (
                <>
                  <Image src={highlight} h={{ base: '70px', md: '100px' }} />
                  {/* <Image src={gptw} h={{ base: '70px', md: '120px' }} /> */}
                </>
              )}
            </Flex>
          </Flex>

          {viewport > '1024' && <Flex h="full" w="full" style={backgroundImageStyle} />}
        </Grid>
      )}

      <ToastContainer />
    </Flex>
  );
};

export default BackgroundLogin;
