import React, { useEffect, useRef, useState } from 'react';

import { MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { reportFilter } from '../../../utils/exports';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';
import ReportControllers from '../report-generator/components/ReportControllers';
import ReportList from '../report-generator/components/ReportList';
import ReportParams from '../report-generator/components/ReportParams';
import ReportSidebar from '../report-generator/components/ReportSidebar';
import ReportSql from '../report-generator/components/ReportSql';
import roleOptions from '../user-management/Helpers/roleOptions';

const ReportViewPage = () => {
  const floatActionButton = document.querySelector('.float-action-button button');
  const hasPermission = permissions.vendemmiaSQLReportManagement || permissions.vendemmiaSQLReportView;
  const delayBeforeReload = 2000;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [isLoadingSidebarMenu, setIsLoadingSidebarMenu] = useState(true);
  const [isLoadingSingleReport, setIsLoadingSingleReport] = useState(true);
  const [isLoadingReportRun, setIsLoadingReportRun] = useState(true);

  const [isLoadingRun, setIsLoadingRun] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUserPermission, setIsLoadingUserPermission] = useState(false);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [isNewReport, setIsNewReport] = useState(false);
  const [switchPageButton, setSwitchPageButton] = useState(false);

  const [currentFolder, setCurrentFolder] = useState();

  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [singleReport, setSingleReport] = useState();
  const [reportRun, setReportRun] = useState([]);
  const [listDefautParams, setListDefautParams] = useState([]);

  const [disableParameters, setDisableParameters] = useState(true);

  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState([]);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [labelsForRoles, setLabelsForRoles] = useState([]);
  const [labelsForUserRoles, setLabelsForUserRoles] = useState([]);

  const [responsibleUsersOptions, setResponsibleUsersOptions] = useState([]);
  const [isReportSelected, setIsReportSelected] = useState(false);

  const request = useRef(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    removeIDParameterIfTrueAndReload();
    /* setIsNewReport(hasSpecificURLParameters(['new-report']).exists); */

    /* isNewRecordURL(); */

    loadSidebarMenu(filters);
    /* loadListDefautParams(); */
    hasSelectedReport();
  };

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  };

  //chamadas as APIs
  //puxar itens do menu
  const loadSidebarMenu = async (filters) => {
    setIsLoadingSidebarMenu(false);

    let res;

    try {
      res = await requests.getListOfReportsMenu({ filters: filters });
      setSidebarMenu(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    //se houver parametro na URL chama o relatário do id
    const hasIdentifier = hasSpecificURLParameters(['id']).exists;

    if (hasIdentifier) {
      const identifier = hasSpecificURLParameters(['id']).value;

      //definir em qual folder está o relatório slecionado
      localStorage.removeItem('report-folder');

      if (!currentFolder) {
        res.forEach((menu, index) => {
          menu.list.forEach((submenu) => {
            identifier === submenu.identifier && setCurrentFolder(index);
          });
        });
      }

      loadSingleReportOfMenu(identifier);
    }

    setIsLoadingSidebarMenu(true);
  };

  //puxar dados do relatorio escolhido no menu
  const loadSingleReportOfMenu = async (identifier, filters) => {
    setIsLoadingSingleReport(false);

    //atualizar trocar de tela de criação
    setIsNewReport(false);
    addParametersToURL({ id: identifier });

    try {
      /* if (filters !== null) {
        delete filters?.search;
      } */

      const res = await requests.getSingleReportOfMenu(identifier, { filters: filters });
      setSingleReport(res);

      //definir se os parametros estao ativos
      setDisableParameters(res.isParameterVisible);

      //definir as permissões ativas para perfil e usuário
      /* setActivePermissionsForProfile(res.allowedRoles);
      setActivePermissionsForUser(res.allowedUsers); */

      //definir se tem carregamento de algum relatório
      hasSelectedReport();

      /* if (!currentFolder) {
        res.forEach((item, index) => {
          identifier === submenu.identifier && setCurrentFolder(index);
        });
      }
 */
      //É necessario limpar o localStorage para nao vir com dados antigos e setar o atual
      localStorage.removeItem(`report-sql`);
      localStorage.setItem('report-sql', JSON.stringify(res));

      loadReportRun(res, true);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoadingSingleReport(true);
  };

  //carregar lista de parametros padrões
  /* const loadListDefautParams = async () => {
    setIsLoadingSingleReport(false);

    try {
      const res = await requests.getListDefautParams();
      setListDefautParams(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoadingSingleReport(true);
  }; */

  //executar relatorio atual
  const loadReportRun = async (payload, isEdit) => {
    setIsLoadingReportRun(false);
    setIsLoadingRun(true);

    let dataSql = JSON.parse(localStorage.getItem('report-sql')) || {};
    let dataParams = JSON.parse(localStorage.getItem('report-parameters')) || {};

    const { parameters, identifier } = payload;

    //remover o parametro identifier do payload
    if (payload?.parameters.length > 1) {
      parameters.forEach((item) => {
        delete item.identifier;
      });
    } else if (payload?.parameters.length === 1) {
      parameters[0] && delete payload?.parameters[0].identifier;
    }

    const name = dataSql?.name ?? payload.name;
    const folder = dataSql?.folder ?? payload.folder;
    const query = removeBackslashes(dataSql?.query ?? payload.query);
    const databaseName = dataSql?.databaseName ?? payload.databaseName;
    const isParameterVisible = disableParameters;

    //isEdit serve para verificar o primeiro carregamento na edição
    const parametersArray = isEdit ? parameters : dataParams.customParameters;

    try {
      const res = await requests.runReport(identifier, {
        name,
        folder,
        query,
        databaseName,
        parametersArray,
        isParameterVisible,
        allowedRoles,
        allowedUsers,
      });
      setReportRun(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingRun(false);
    setIsLoadingReportRun(true);
  };

  //exportar relatório
  const handlerExportReport = async (payload, extension) => {
    const { name, folder, query, databaseName, identifier } = payload;
    const parametersArray = payload?.parameters;

    //preset
    const filters = {};
    const endpoint = `report-generator/${identifier}/${extension}`;
    const method = `POST`;
    const body = {
      name,
      folder,
      query,
      databaseName,
      parametersArray,
      isParameterVisible: true,
      allowedRoles,
      allowedUsers,
    };

    if (!payload) {
      toast.error('Não há dados na lista para exportar.');
      return;
    }

    setIsLoadingReportRun(false);

    //remover o parametro identifier do payload
    if (parametersArray.length > 1) {
      parametersArray.forEach((item) => {
        delete item.identifier;
      });
    } else {
      parametersArray[0] && delete parametersArray[0].identifier;
    }

    try {
      //generate file
      generateExportFile(endpoint, payload.name, extension, method, body, filters);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingReportRun(true);
  };

  //editar relatorio atual
  const handlerEditReport = async (data) => {
    setIsLoadingCreate(true);

    //remover ação de mudanças não salvas
    setUnsavedChangesExist(false);
    window.removeEventListener('beforeunload', beforeUnloadHandler);

    //localStorage
    const dataSql = JSON.parse(localStorage.getItem('report-sql'));
    const dataParams = JSON.parse(localStorage.getItem('report-parameters'));

    const identifier = data.identifier;

    const name = dataSql.name ?? data.name;
    const folder = dataSql.folder ?? data.folder;
    const query = dataSql.query ?? data.query;
    const databaseName = dataSql.databaseName ?? data.databaseName;
    const parametersArray = dataParams.customParameters;

    //field validation
    const isNameValid = validateAndDisplayToast('Nome do relatório', name);
    const isFolderValid = validateAndDisplayToast('Nome da pasta', folder);
    const isQueryValid = validateAndDisplayToast('Query', query);
    const isDatabaseNameValid = validateAndDisplayToast('Base de Dados', databaseName);

    const isParamValid = validateParametersArray(parametersArray);

    if (!doesArrayContainFalse(isParamValid)) {
      if (isNameValid && isFolderValid && isQueryValid && isDatabaseNameValid) {
        try {
          await requests.editReport(identifier, {
            name,
            folder,
            query,
            databaseName,
            parametersArray,
            isParameterVisible: disableParameters,
            allowedRoles,
            allowedUsers,
          });

          toast.success('Relatório atualizado com sucesso.');

          //desabilitar todos os módulos
          enableAllLoaders();

          setTimeout(() => {
            window.location.reload();
          }, delayBeforeReload);
        } catch (error) {
          console.error('Erro ao obter os dados da API:', error);
        }
      }
    }

    setIsLoadingCreate(false);
  };
  //chamadas as APIs

  const setActivePermissionsForProfile = (data) => {
    const allowedRolesFormatted = data.map((item) => item.role);
    const labelsForRolesFormatted = getLabelsForRoles(allowedRolesFormatted);

    setAllowedRoles(allowedRolesFormatted);
    setLabelsForRoles(labelsForRolesFormatted);
  };

  const setActivePermissionsForUser = (data) => {
    const allowedRolesFormatted = data.map((item) => item.identifier);
    const labelsForRolesFormatted = data.map((item) => item.name);

    setAllowedUsers(allowedRolesFormatted);
    setLabelsForUserRoles(labelsForRolesFormatted);
  };

  function getLabelsForRoles(response) {
    const labels = [];

    // Itera sobre os valores da resposta da API
    for (const role of response) {
      // Procura o objeto correspondente em roleOptions pelo valor
      const roleOption = roleOptions.find((option) => option.value === role);

      if (roleOption) {
        // Se encontrar o objeto, adiciona o rótulo ao array de rótulos
        labels.push(roleOption.label);
      }
    }

    return labels;
  }

  const validateParametersArray = (parametersArray) => {
    const validate = [];

    let isValid;

    const isValidItem = (label, index) => {
      const fieldName = label + ' ' + '#' + (index + 1);
      const errorMessage = `O campo ${fieldName} não pode estar vazio.`;
      toast.error(errorMessage);
      isValid = false;
    };

    parametersArray.forEach((parameter, index) => {
      isValid = true;

      !parameter.label && isValidItem('Label', index);
      !parameter.name && isValidItem('Name', index);
      !parameter.value && isValidItem('Value', index);

      validate.push(isValid);
    });

    return validate;
  };

  const doesArrayContainFalse = (array) => {
    return array.some((item) => item === false);
  };

  /*  const validateAndDisplayToast = (fieldName, fieldValue) => {
    if (!fieldValue) {
      toast.error(`O campo '${fieldName}' precisa ser preenchido.`);
      return false;
    }
    return true;
  }; */

  const removeBackslashes = (inputString) => {
    const cleanedString = inputString.replace(/\\/g, '');
    return cleanedString;
  };

  const removeIDParameterIfTrueAndReload = () => {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    // Verifica se o parâmetro "id" está definido como "true" e o remove, se necessário
    if (params.has('id') && params.get('id') === 'true') {
      params.delete('id');
      // Recarrega a página
      window.location.href = url.toString();
    }
  };

  /* const addNewReport = () => {
    const urlWithoutId = window.location.pathname;
    window.history.replaceState(null, null, urlWithoutId);

    window.location.reload();
  }; */

  const closeFloatActionButton = () => {
    floatActionButton && floatActionButton.click();
  };

  const unsavedChanges = (boolean) => {
    setUnsavedChangesExist(boolean);
  };

  const hasSelectedReport = () => {
    setIsReportSelected(hasSpecificURLParameters(['id']).exists);
  };

  //triggers
  useEffect(() => {
    load();
  }, []);

  const floatActionButtonOptions = [
    {
      isLoading: '',
      rightIcon: <MdOutlineFileDownload ref={btnRef} color="#FFFFFF" size={25} />,
      handler: () => {
        handlerExportReport(singleReport, 'csv');
        closeFloatActionButton();
      },
      title: 'Exportar dados (CSV)',
      showInNewReport: true,
    },
    {
      isLoading: '',
      rightIcon: <MdOutlineFileDownload ref={btnRef} color="#FFFFFF" size={25} />,
      handler: () => {
        handlerExportReport(singleReport, 'xlsx');
        closeFloatActionButton();
      },
      title: 'Exportar dados (Excel)',
      showInNewReport: true,
    },
  ];

  return (
    <Page
      screen="report-view"
      title="Relatórios"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome do relatório"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      /* filterOptions={filterOptions} */
      isRefreshLoading={isLoading || isLoadingMore}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}
      allowFiltersOverride={false}
      allowEmptySearchString={true}>
      <Grid
        as="main"
        minH={'100vh'}
        gridTemplateColumns={{ base: '1fr', md: 'auto 1fr' }}
        /* templateColumns='repeat(5, 1fr)' */
        gap={4}
        m="10px">
        <GridItem>
          <ScreenLoader isLoading={!isLoadingSidebarMenu}>
            <ReportSidebar
              sidebarMenu={sidebarMenu}
              loadSingleReportOfMenu={loadSingleReportOfMenu}
              setIsNewReport={setIsNewReport}
              setSwitchPageButton={setSwitchPageButton}
              unsavedChangesExist={unsavedChangesExist}
              unsavedChanges={unsavedChanges}
            />
          </ScreenLoader>
        </GridItem>
        <GridItem>
          <Grid minH={'100vh'} gap={4} templateRows="repeat(13fr)" templateColumns="repeat(12, 1fr)">
            <GridItem rowSpan={1} colSpan={12} display={'none'}>
              <ScreenLoader isLoading={!isLoadingSingleReport}>
                <ReportSql
                  dataReport={singleReport}
                  sidebarMenu={sidebarMenu}
                  isNewReport={isNewReport}
                  setIsNewReport={setIsNewReport}
                  unsavedChangesExist={unsavedChangesExist}
                  unsavedChanges={unsavedChanges}
                />
              </ScreenLoader>
            </GridItem>
            <GridItem rowSpan={13} colSpan={12}>
              <ScreenLoader isLoading={!isLoadingReportRun}>
                <ReportList dataReport={reportRun} paginateTextDisplay={true} isReportSelected={isReportSelected} />
              </ScreenLoader>
            </GridItem>
          </Grid>
        </GridItem>

        {isReportSelected && (
          <FloatActionButton
            display="flex"
            flexDirection="column"
            options={[
              floatActionButtonOptions.map((button, key) => {
                return (
                  button.showInNewReport && (
                    <Button
                      key={key}
                      h="46px"
                      px="24px"
                      py="14px"
                      bgColor="green"
                      color="#FFFFFF"
                      borderRadius="27px"
                      _hover={{ bgColor: '#70D499' }}
                      isLoading={button.isLoading}
                      rightIcon={button.rightIcon}
                      onClick={button.handler}>
                      <Text>{button.title}</Text>
                    </Button>
                  )
                );
              }),
              disableParameters && (
                <Button
                  key="params-drawer-open"
                  h="46px"
                  px="24px"
                  py="14px"
                  bgColor="green"
                  color="#FFFFFF"
                  borderRadius="27px"
                  _hover={{ bgColor: '#70D499' }}
                  ref={btnRef}
                  rightIcon={<Image src={reportFilter} color="#FFFFFF" h={5} w={5} />}
                  onClick={() => {
                    onOpen();
                    closeFloatActionButton();
                  }}>
                  <Text>Parâmetros</Text>
                </Button>
              ),
            ]}
            isOpen={isOpenFloatButton}
            setIsOpen={setIsOpenFloatButton}
          />
        )}
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>&nbsp;</DrawerHeader>

            <DrawerBody>
              <ScreenLoader isLoading={!isLoadingSingleReport} h={'auto'}>
                <ReportParams
                  //consts
                  dataReport={singleReport}
                  listDefautParams={listDefautParams}
                  disableParameters={disableParameters}
                  //sets
                  setDisableParameters={setDisableParameters}
                  //methods
                  unsavedChanges={unsavedChanges}
                  //styles
                  cols={2}
                  containerHeight={'auto'}
                  //conditionals
                  hiddenName={true}
                  resetStyles={true}
                  isLabelDisabled={true}
                  shouldAddParameter={false}
                  showDisableParametersButton={false}
                />
              </ScreenLoader>
              <ReportControllers
                //consts
                dataReport={singleReport}
                isNewReport={isNewReport}
                allowedRoles={allowedRoles}
                allowedUsers={allowedUsers}
                labelsForRoles={labelsForRoles}
                labelsForUserRoles={labelsForUserRoles}
                responsibleUsersOptions={responsibleUsersOptions}
                //sets
                setAllowedRoles={setAllowedRoles}
                setAllowedUsers={setAllowedUsers}
                setLabelsForRoles={setLabelsForRoles}
                setLabelsForUserRoles={setLabelsForUserRoles}
                //methods
                loadReportRun={loadReportRun}
                /* handlerRemoveReport={handlerRemoveReport} */
                handlerEditReport={handlerEditReport}
                /* handlerCreateReport={handlerCreateReport} */
                /* beforeUnloadHandler={beforeUnloadHandler} */
                unsavedChanges={unsavedChanges}
                //loads
                isLoadingRun={isLoadingRun}
                isLoadingCreate={isLoadingCreate}
                isLoadingDelete={isLoadingDelete}
                isLoadingUserPermission={isLoadingUserPermission}
                //styles
                containerHeight={'auto'}
                justifyContent={'end'}
                //conditionals
                changenameButtonRun={'Aplicar'}
                hiddenButtonCreate={true}
                hiddenButtonDuplicate={true}
                hiddenButtonDelete={true}
                hiddenButtonPermission={true}
                resetStyles={true}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Grid>
    </Page>
  );
};

export default ReportViewPage;
