import { Polyline } from '@react-google-maps/api';

export const CommonPolyline = ({ path, type }) => {
  return type === 'dashed' ? (
    <Polyline
      options={{
        strokeColor: '#A885F9',
        strokeOpacity: 0.4,
        icons: [
          {
            icon: {
              strokeOpacity: 1,
            },
            offset: '100%',
          },
          {
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 4,
            },
            offset: '0',
            repeat: '15px',
          },
        ],
      }}
      path={path}
    />
  ) : (
    <Polyline
      options={{
        strokeColor: '#6C48C2',
        strokeOpacity: 1.0,
        icons: [],
      }}
      path={path}
    />
  );
};
