import React from 'react';

import { Accordion } from '@chakra-ui/react';

import KanbanColumn from './KanbanColumn';

const KanbanSection = ({ list, navigation, viewport }) => {
  const getColumns = () => {
    const columns = [];
    for (let i = 1; i <= 6; i++) {
      const filteredData = list.filter((entity) => entity.currentStageModalitySequential === i);
      const isFirstColumn = i === 1;
      const isLastColumn = i === 6;
      columns.push(
        <KanbanColumn
          stage={i}
          key={i}
          delay={i}
          list={filteredData}
          isFirstColumn={isFirstColumn}
          isLastColumn={isLastColumn}
          viewport={viewport}
        />
      );
    }
    return columns;
  };

  return (
    <Accordion w="100%" display="grid" gridTemplateColumns={{ base: 'initial', lg: 'repeat(6, 1fr)' }} allowToggle>
      {getColumns()}
    </Accordion>
  );
};

export default KanbanSection;
