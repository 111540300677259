const initialFields = [
  {
    id: 'pre-embarque',
    name: 'Pré Embarque',
    active: true,
    opened: true,
    subFields: [
      {
        id: 'ref',
        name: 'Ref',
        active: true,
        width: 150,
      },
      {
        id: 'cliente',
        name: 'Cliente',
        active: true,
        width: 300,
      },
      {
        id: 'etapa',
        name: 'Etapa',
        active: true,
        width: 150,
      },
      {
        id: 'processo',
        name: 'Processo',
        active: true,
        width: 150,
      },
      {
        id: 'tipoOperacao',
        name: 'Tipo Operação',
        active: true,
        width: 250,
      },
      {
        id: 'frete',
        name: 'Modal',
        active: true,
        width: 100,
      },
      {
        id: 'valorFreteUsd',
        name: 'Valor Frete USD',
        active: true,
        width: 150,
      },
      {
        id: 'refExportador',
        name: 'Ref. Exportador',
        active: true,
        width: 150,
      },
      {
        id: 'filial',
        name: 'Filial',
        active: true,
        width: 75,
      },
      {
        id: 'incoterm',
        name: 'Incoterm',
        active: true,
        width: 75,
      },
      {
        id: 'exportador',
        name: 'Exportador',
        active: true,
        width: 300,
      },
    ],
  },
  {
    id: 'transito',
    name: 'Trânsito',
    active: true,
    opened: false,
    subFields: [
      {
        id: 'portoOrigem',
        name: 'Porto Origem',
        active: true,
        width: 200,
      },
      {
        id: 'portoDestino',
        name: 'Porto Destino',
        active: true,
        width: 200,
      },
      {
        id: 'weight',
        name: 'Peso (kg)',
        active: true,
        width: 150,
      },
      {
        id: 'descricaoProduto',
        name: 'Descrição do Produto',
        active: true,
        width: 300,
      },
      {
        id: 'produtoRefMaterial',
        name: 'Produto / Ref. Material',
        active: true,
        width: 200,
      },
      {
        id: 'invoice',
        name: 'Invoice',
        active: true,
        width: 200,
      },
      {
        id: 'qtde',
        name: 'Qtde',
        active: true,
        width: 100,
      },
      {
        id: 'valorUnit',
        name: 'Valor Unit.',
        active: true,
        width: 150,
      },
      {
        id: 'moeda',
        name: 'Moeda',
        active: true,
        width: 150,
      },
      {
        id: 'valorTotalUSD',
        name: 'Valor Total',
        active: true,
        width: 150,
      },
      {
        id: 'li',
        name: 'LI',
        active: true,
        width: 150,
      },
      {
        id: 'ncm',
        name: 'NCM',
        active: true,
        width: 150,
      },
      {
        id: 'imo',
        name: 'IMO',
        active: true,
        width: 150,
      },
      {
        id: 'vencimentoCambio',
        name: 'Vencimento do Câmbio',
        active: true,
        width: 150,
      },
      {
        id: 'originais',
        name: 'Originais',
        active: true,
        width: 150,
      },
      {
        id: 'blAwbCrt',
        name: 'BL/AWB/CRT',
        active: true,
        width: 200,
      },
      {
        id: 'crt20',
        name: 'CRT 20',
        active: true,
        width: 100,
      },
      {
        id: 'crt40',
        name: 'CRT 40',
        active: true,
        width: 100,
      },
      {
        id: 'tipocntr',
        name: 'Tipo CNTR',
        active: true,
        width: 250,
      },
      {
        id: 'navio',
        name: 'Navio',
        active: true,
        width: 250,
      },
      {
        id: 'etd',
        name: 'ETD',
        active: true,
        width: 150,
      },
      {
        id: 'envioCartaProtesto',
        name: 'Envio Carta Protesto',
        active: true,
        width: 150,
      },
      {
        id: 'mapaStatus',
        name: 'Mapa Status',
        active: true,
        width: 150,
      },
      {
        id: 'eta',
        name: 'ETA',
        active: true,
        width: 150,
      },
      {
        id: 'presencaCarga',
        name: 'Presença Carga',
        active: true,
        width: 150,
      },
      {
        id: 'freeTime',
        name: 'Free Time',
        active: true,
        width: 150,
      },
      {
        id: 'dataVencFreeTime',
        name: 'Data de Venc. Free Time',
        active: true,
        width: 150,
      },
    ],
  },
  {
    id: 'desembaraco',
    name: 'Desembaraço',
    active: true,
    opened: false,
    subFields: [
      {
        id: 'contratoCambio',
        name: 'Contrato de Câmbio',
        active: true,
        width: 150,
      },
      {
        id: 'modalidadePagamento',
        name: 'Modalidade de Pagamento',
        active: true,
        width: 150,
      },
      {
        id: 'di',
        name: 'DI',
        active: true,
        width: 150,
      },
      {
        id: 'dataRegistro',
        name: 'Data do Registro',
        active: true,
        width: 150,
      },
      {
        id: 'canal',
        name: 'Canal',
        active: true,
        width: 100,
      },
      {
        id: 'dataDesembaraco',
        name: 'Data Desembaraço',
        active: true,
        width: 150,
      },
      // {
      //   id: 'localDesembaraco',
      //   name: 'Local Desembaraço',
      //   active: true,
      //   width: 250,
      // },
    ],
  },
  {
    id: 'faturamento',
    name: 'Faturamento',
    active: true,
    opened: false,
    subFields: [
      {
        id: 'acompDetalhado',
        name: 'Acomp. Detalhado',
        active: true,
        width: 200,
      },
      {
        id: 'valorFatNf',
        name: 'Valor Fat. (NF)',
        active: true,
        width: 150,
      },
      {
        id: 'dataFaturamento',
        name: 'Data de Faturamento',
        active: true,
        width: 150,
      },
    ],
  },
  {
    id: 'entrega',
    name: 'Entrega',
    active: true,
    opened: false,
    subFields: [
      {
        id: 'dataEntrega',
        name: 'Data de Entrega',
        active: true,
        width: 200,
      },
    ],
  },
];
export default initialFields;
