import { toast } from 'react-toastify';

import { createExportFile, getExportFile } from '../../services/requests';

/* exemplo de uso:
  const exportListAction = () => {
    const endpoint = `/adm/user/export-csv`;
    const fileName = `users`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};
    const filters = {};

    return generateExportFile(endpoint, fileName, extension, method, body, filters);
  };
*/

/**
 * Função para gerar e baixar um arquivo de exportação.
 * @param {string} endpoint - O endpoint da API para obter os dados ou gerar o arquivo.
 * @param {string} fileName - O nome do arquivo a ser baixado.
 * @param {string} extension - A extensão do arquivo (xlsx, xls, csv).
 * @param {string} [method='GET'] - O método HTTP a ser usado para obter os dados da API (GET ou POST).
 * @param {object} [body] - O corpo da requisição em caso de método POST.
 * @param {object} [filters] - Os filtros/params a serem aplicados na consulta à API.
 */
export const generateExportFile = async (endpoint, fileName, extension, method = 'GET', body, filters) => {
  const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    closeButton: false,
  });

  /* const contentTypeMapping = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    csv: 'text/csv',
    default: 'application/octet-stream',
  };

  const contentType = contentTypeMapping[extension] || contentTypeMapping.default; */

  try {
    const response = method === 'POST' ? await createExportFile(endpoint, filters, body) : await getExportFile(endpoint, filters);

    if (response instanceof Blob || response.status === 200) {
      const date = new Date();

      const year = date.getFullYear();
      const month = date.getMonth().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      const day = date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      const hour = date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      const minute = date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      const second = date.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

      const fileNameDate = `${year}${month}${day}${hour}${minute}${second}`;

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}-${fileNameDate}.${extension || 'xlsx'}`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      toast.dismiss(toastId);
      toast.success('Exportação efetuada com sucesso!', { autoClose: 1500 });
    } else {
      // Se a resposta não for um Blob (provavelmente um erro), exiba a mensagem de erro no toast
      toast.dismiss(toastId);
      toast.error(response.status === 204 ? 'Não há arquivos para baixar' : 'Erro ao exportar arquivo. Tente novamente.');
    }
  } catch (error) {
    // Se ocorrer um erro durante a chamada da API, exiba a mensagem de erro no toast
    toast.dismiss(toastId);

    if (error.response.status === 404) {
      toast.error('Não há documentos para download');
    } else {
      toast.error('Erro ao exportar arquivo. Tente novamente.');
    }
    console.error('Erro durante a exportação:', error.message || error);
  }
};
