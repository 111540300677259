import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

import Card from '../../../../components/Card/Card';
import Page from '../../../../components/Page';
import requests from '../../../../services/requests';
import formatDocument from '../../../../utils/fields/masks/formatDocument';

import CreditAnalysisAccordion from './CreditAnalysisAccordion';
import CreditAnalysisBox from './CreditAnalysisBox';

const CreditAnalysisDetailPage = () => {
  const { identifier } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [entity, setEntity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMatriz, setIsLoadingMatriz] = useState(false);

  const load = () => {
    setIsLoading(true);
    requests.showCreditAnalysis(identifier).then((response) => {
      setEntity(response);
      setIsLoading(false);
    });
  };

  const addMatriz = (modality) => {
    setIsLoadingMatriz(true);
    requests
      .addRequestMatrizCreditAnalysis(
        {
          documentNumber: entity?.documentNumber,
        },
        identifier,
        modality
      )
      .then(() => {
        setIsLoadingMatriz(false);
        load();
      });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Page
      title="Detalhes da análise de crédito"
      breadcrumbs={[
        { link: '#', title: 'Vendemmia' },
        { link: '/vendemmia/credit-analysis', title: 'Análise de crédito' },
      ]}
      setIsMobile={setIsMobile}
      isContentLoading={isLoading}
      hideAllFilters={true}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}>
      <Card>
        <Box className="down-anim" justify="space-between" pl="3.75rem" pr="3.75rem" pt="1.875rem" pb="1.875rem">
          <Flex gap="0.125rem" direction="column">
            <Text fontSize="26px" fontWeight="bold" color="purple-400">
              Análise de Crédito - Visualização
            </Text>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              mt={{ base: '1', md: '6' }}
              justifyContent="space-between"
              w="100%"
              border="1px"
              borderColor="#7070703D"
              pl={{ base: '1rem', md: '3.75rem' }}
              pr={{ base: '1rem', md: '3.75rem' }}
              pt={{ base: '0.75rem', md: '1.875rem' }}
              pb={{ base: '0.75rem', md: '1.875rem' }}>
              <Flex gap="0.125rem" direction="column">
                <Text fontSize="1.15rem" fontWeight="bold" color="primary">
                  CNPJ
                </Text>
                <Text>{entity?.documentNumber != null ? formatDocument(entity.documentNumber) : '-'}</Text>
              </Flex>
              <Flex gap="0.125rem" direction="column">
                <Flex gap="0.125rem" direction="column">
                  <Text fontSize="1.15rem" fontWeight="bold" color="primary">
                    Nome Fantasia
                  </Text>
                  <Text>{entity.businessName ?? '-'}</Text>
                </Flex>
              </Flex>
              <Flex gap="0.125rem" direction="column">
                <Flex gap="0.125rem" direction="column">
                  <Text fontSize="1.15rem" fontWeight="bold" color="primary">
                    Razão Social
                  </Text>
                  <Text>{entity.companyName ?? '-'}</Text>
                </Flex>
              </Flex>
            </Flex>

            {entity.matrizTokenInitial != null ? (
              <CreditAnalysisAccordion content={entity.jsonInitial} />
            ) : (
              <CreditAnalysisBox
                isLoading={isLoadingMatriz}
                text="Ainda não possui consulta inicial"
                modality="initial"
                handleClick={addMatriz}
              />
            )}

            {entity.matrizTokenDetailed != null ? (
              <CreditAnalysisAccordion content={entity.jsonDetailed} />
            ) : (
              <CreditAnalysisBox
                isLoading={isLoadingMatriz}
                text="Ainda não possui consulta aprofundada"
                modality="detailed"
                handleClick={addMatriz}
              />
            )}
            {entity.matrizTokenBusinessPartner != null ? (
              <CreditAnalysisAccordion content={entity.jsonBusinessPartner} />
            ) : (
              <CreditAnalysisBox
                isLoading={isLoadingMatriz}
                text="Ainda não possui consulta societária"
                modality="businessPartner"
                handleClick={addMatriz}
              />
            )}
            {entity.matrizTokenComplete != null ? (
              <CreditAnalysisAccordion content={entity.jsonComplete} />
            ) : (
              <CreditAnalysisBox
                isLoading={isLoadingMatriz}
                text="Ainda não possui consulta completa"
                modality="complete"
                handleClick={addMatriz}
              />
            )}
            {entity.matrizTokenOptional != null ? (
              <CreditAnalysisAccordion content={entity.jsonOptional} />
            ) : (
              <CreditAnalysisBox
                isLoading={isLoadingMatriz}
                text="Ainda não possui consulta opcional"
                modality="optional"
                handleClick={addMatriz}
              />
            )}
          </Flex>
        </Box>
      </Card>
    </Page>
  );
};

export default CreditAnalysisDetailPage;
