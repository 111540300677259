/**
 * Função para condensar um valor entre um mínimo e um máximo
 * @param {*} valor  Valor a ser condensado
 * @param {*} min Valor mínimo
 * @param {*} max Valor máximo
 * @returns retorna o valor já reduzido
 */
export const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};
