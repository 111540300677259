const getObjectFromLocalStorage = (key, obj) => {
  return JSON.parse(window.localStorage.getItem(key) ?? '{}');
};

const saveObjectIntoLocalStorage = (key, obj) => {
  if (typeof obj === 'object') {
    for (let index in obj) {
      if (obj[index] === null || obj[index]?.length === 0) {
        delete obj[index];
      }
    }
    obj = JSON.stringify(obj);
  }

  window.localStorage.setItem(key, obj);
};

export const getFilter = (screen, item = undefined) => {
  let filter = getObjectFromLocalStorage(`filter-${screen}`);

  if (item === undefined) {
    return filter;
  }

  return item in filter ? filter[item] : null;
};

export const setFilter = (screen, obj) => {
  saveObjectIntoLocalStorage(`filter-${screen}`, obj);
};

export const addFilter = (screen, item, value) => {
  let filter = getObjectFromLocalStorage(`filter-${screen}`);

  filter[item] = value;

  saveObjectIntoLocalStorage(`filter-${screen}`, filter);
};

export const appendFilter = (screen, obj) => {
  let current = getObjectFromLocalStorage(`filter-${screen}`);

  saveObjectIntoLocalStorage(`filter-${screen}`, { ...current, ...obj });
};
