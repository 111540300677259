import React from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Collapse, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

const ProgressListBody = ({ entity }) => {
  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowDown size={20} color="#707070" /> : <MdKeyboardArrowRight size={20} color="#707070" />;

  return (
    <>
      <Tr onClick={onToggle} cursor="pointer" className="border">
        <Td>
          <Box>{Icon}</Box>
        </Td>

        <Td>
          <Box>
            <Text textColor="primary" fontWeight="500">
              {entity?.category ?? '-'}
            </Text>
          </Box>
        </Td>

        <Td>
          <Box>
            <Text>{entity?.modality ?? '-'}</Text>
          </Box>
        </Td>

        <Td>
          <Box gap="10px">
            <Text>{entity?.client?.name ?? '-'}</Text>
            {entity?.operationSpecification?.identifier && (
              <Tooltip label="Visualizar especificação operacional">
                <Link
                  to={`/vendemmia/operation-specification/detail/${entity.operationSpecification.identifier}/isPreviwer`}
                  target="_blank">
                  <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                </Link>
              </Tooltip>
            )}
          </Box>
        </Td>

        <Td>
          <Box>
            <Text>{entity?.currency?.description ?? '-'}</Text>
          </Box>
        </Td>

        <Td>
          <Flex justify="end">
            <Text>{entity?.orderEmineg ?? '-'}</Text>
          </Flex>
        </Td>

        <Td>
          <Flex justify="end">
            <Text>{entity?.currency?.value ?? '-'}</Text>
          </Flex>
        </Td>

        <Td>
          <Flex justify="end">
            <Text>{entity?.orderEminac ?? 0}</Text>
          </Flex>
        </Td>

        <Td>
          <Flex justify="end">
            <Text>{entity?.operationSpecification?.billingFactor ?? '1.34'}</Text>
          </Flex>
        </Td>

        <Td>
          <Flex justify="end">
            <Text color="#1A202C">R$ {entity?.estimatedBilling ?? 0}</Text>
          </Flex>
        </Td>
      </Tr>

      <Tr>
        <Td
          p="0"
          colSpan={15}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen}>
            <Box bgColor="#F9F9F9" mb="10px">
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <Flex direction="row" align="center"></Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Processo</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>ATA</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Navio</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Câmbio</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Dt. Câmbio</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Impostos + despesas</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Dt. Impostos</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Faturamento</Text>
                      </Flex>
                    </Th>

                    <Th>
                      <Flex direction="row" align="center">
                        <Text>Dt. Faturamento</Text>
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {entity.processes?.length > 0 ? (
                    entity.processes?.map((subEntity, key) => {
                      return (
                        <Tr key={key}>
                          <Td w="60px">
                            <Tooltip label="Visualizar contrato">
                              <Link to={`/comex/process/detail/${subEntity.identifier}`} target="_blank">
                                <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                              </Link>
                            </Tooltip>
                          </Td>

                          <Td style={{ color: '#422C76', fontWeight: '500' }}>{subEntity.code}</Td>

                          <Td>{subEntity?.arrivedAt ??  subEntity?.arrivalExpectedAt}</Td>

                          <Td>{subEntity?.vessel ?? '-'}</Td>

                          <Td>R$ {subEntity?.exchange ?? '-'}</Td>

                          <Td>{subEntity?.exchangeDate ?? '-'}</Td>

                          <Td>R$ {subEntity?.taxesAndExpenses ?? '-'}</Td>

                          <Td>{subEntity?.taxesAndExpensesDate ?? '-'}</Td>

                          <Td>R$ {subEntity?.billing ?? 0}</Td>

                          <Td>{subEntity?.billedAt ?? '-'}</Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={10}>
                        <Flex justify="center" my="10px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ProgressListBody;
