import React, { useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Flex, FormLabel, Grid, Image, Input, InputGroup, InputRightElement, Text, Tooltip } from '@chakra-ui/react';

import facebook from '../../../assets/images/login/icons/facebook.svg';
import instagram from '../../../assets/images/login/icons/instagram.svg';
import linkedin from '../../../assets/images/login/icons/linkedin.svg';
import siteVendemmia from '../../../assets/images/login/icons/siteVendemmia.svg';
import youtube from '../../../assets/images/login/icons/youtube.svg';
import Loading from '../../../components/Loading/Loading';
import BackgroundLogin from '../../../components/Login/BackgroundLogin/BackgroundLogin';
import api from '../../../services/api';

const RegisterPage = () => {
  const yup = require('yup');

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showPwd, setShowPwd] = useState(false);
  const [ShowConfirmPwd, setShowConfirmPwd] = useState(false);

  const handleClickShowPwd = () => setShowPwd(!showPwd);
  const handleClickShowConfirmPwd = () => setShowConfirmPwd(!ShowConfirmPwd);

  return (
    <BackgroundLogin showLogo={false} maxW="500px">
      <Formik
        initialValues={{
          name: '',
          cellphone: '',
          email: '',
          companyName: '',
          companyDocumentNumber: '',
          companyRole: '',
          newPwd: '',
          confirmPwd: '',
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required('Campo obrigatório'),
          cellphone: yup.string(),
          email: yup.string().required('Campo obrigatório'),
          companyName: yup.string().required('Campo obrigatório'),
          companyDocumentNumber: yup.string(),
          companyRole: yup.string(),
          newPwd: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres').required('Campo de nova senha obrigatório'),
          confirmPwd: yup
            .string()
            .required('Campo de confirmação de senha obrigatório')
            .oneOf([yup.ref('newPwd'), null], 'As senhas não são iguais!'), // yup.ref para comparação de campo
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setIsLoading(true);

          const aux = {
            name: values.name,
            email: values.email,
            password: values.newPwd,
            cellphone: values.cellphone,
            companyName: values.companyName,
            companyDocumentNumber: values.companyDocumentNumber,
            companyRole: values.companyRole,
          };

          api
            .post('register/pre-register', aux)
            .then(() => {
              setStep(2);

              setIsLoading(false);
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
              setIsLoading(false);
            });
        }}>
        {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {step === 1 && (
              <>
                <Flex direction="column" className="down-anim">
                  <Text textStyle="subtitle" textColor="linkColor" mb="20px">
                    Cadastrar
                  </Text>

                  <Grid gridTemplateColumns="repeat(2, 1fr)" gap="20px" alignItems="end">
                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        Nome
                      </FormLabel>

                      <Input
                        color="#FFFFFF"
                        h="40px"
                        variant="flushed"
                        name="name"
                        id="name"
                        _placeholder={{ color: '#FFFFFF' }}
                        _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                        value={values.name}
                        onChange={handleChange}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="name" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        Celular
                      </FormLabel>

                      <Input
                        color="#FFFFFF"
                        h="40px"
                        variant="flushed"
                        name="cellphone"
                        id="cellphone"
                        _placeholder={{ color: '#FFFFFF' }}
                        _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                        value={values.cellphone}
                        onChange={handleChange}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="cellphone" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        E-mail
                      </FormLabel>

                      <Input
                        color="#FFFFFF"
                        h="40px"
                        variant="flushed"
                        name="email"
                        _placeholder={{ color: '#FFFFFF' }}
                        _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                        value={values.email}
                        onChange={handleChange}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="email" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        Nome da empresa
                      </FormLabel>

                      <Input
                        color="#FFFFFF"
                        h="40px"
                        variant="flushed"
                        name="companyName"
                        id="companyName"
                        _placeholder={{ color: '#FFFFFF' }}
                        _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                        value={values.companyName}
                        onChange={handleChange}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="companyName" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        CNPJ da empresa
                      </FormLabel>

                      <Input
                        color="#FFFFFF"
                        h="40px"
                        variant="flushed"
                        name="companyDocumentNumber"
                        id="companyDocumentNumber"
                        _placeholder={{ color: '#FFFFFF' }}
                        _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                        value={values.companyDocumentNumber}
                        onChange={handleChange}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="companyDocumentNumber" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        Cargo
                      </FormLabel>

                      <Input
                        color="#FFFFFF"
                        h="40px"
                        variant="flushed"
                        name="companyRole"
                        id="companyRole"
                        _placeholder={{ color: '#FFFFFF' }}
                        _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                        value={values.companyRole}
                        onChange={handleChange}
                      />

                      <ErrorMessage component="p" className="error-message-error" name="companyRole" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        Nova senha
                      </FormLabel>

                      <InputGroup>
                        <Input
                          color="#FFFFFF"
                          h="40px"
                          variant="flushed"
                          type={showPwd ? 'text' : 'password'}
                          name="newPwd"
                          id="newPwd"
                          _placeholder={{ color: '#FFFFFF' }}
                          _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                          value={values.newPwd}
                          onChange={handleChange}
                        />
                        <InputRightElement color="#FF2F69" cursor="pointer" _hover={{ opacity: '0.8' }} onClick={handleClickShowPwd}>
                          {showPwd ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                        </InputRightElement>
                      </InputGroup>

                      <ErrorMessage component="p" className="error-message-error" name="newPwd" />
                    </Flex>

                    <Flex direction="column">
                      <FormLabel className="up-anim" color="white" m="0">
                        Confirmar senha
                      </FormLabel>

                      <InputGroup>
                        <Input
                          pr="4.5rem"
                          type={ShowConfirmPwd ? 'text' : 'password'}
                          color="#FFFFFF"
                          variant="flushed"
                          name="confirmPwd"
                          autocomplete="new-password"
                          _placeholder={{ color: '#FFFFFF' }}
                          _focus={{ borderColor: '#FFFFFF', boxShadow: 'none' }}
                          value={values.confirmPwd}
                          onChange={handleChange}
                        />

                        <InputRightElement
                          color="#FF2F69"
                          cursor="pointer"
                          _hover={{ opacity: '0.8' }}
                          onClick={handleClickShowConfirmPwd}>
                          {ShowConfirmPwd ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                        </InputRightElement>
                      </InputGroup>

                      <ErrorMessage component="p" className="error-message-error" name="confirmPwd" />
                    </Flex>
                  </Grid>
                </Flex>
              </>
            )}

            {step === 1 && (
              <>
                <Flex
                  w="full"
                  mt={{ sm: '20px', lg: '30px' }}
                  gap={{ sm: '0px', lg: '15px' }}
                  justify={{ sm: 'space-between', lg: 'end' }}
                  className="down-anim">
                  <Button
                    variant="outline"
                    height="40px"
                    borderRadius="5px"
                    borderColor="#FF2F69"
                    textColor="#FF2F69"
                    transition=".3s"
                    _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
                    isDisabled={isSubmitting}
                    onClick={() => {
                      window.location.href = '/';
                    }}>
                    <Text>Voltar</Text>
                  </Button>

                  <Button
                    type="submit"
                    variant="solid"
                    height="40px"
                    borderRadius="5px"
                    bgColor="#FF2F69"
                    textColor="#FFFFFF"
                    transition=".3s"
                    _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
                    isLoading={isSubmitting}>
                    <Text>Enviar</Text>
                  </Button>
                </Flex>
              </>
            )}

            {step === 2 && (
              <Flex direction="column" gap="30px" justify="center" align="center" className="down-anim">
                <Flex direction="column" gap="10px">
                  <Text textStyle="subtitle" textColor="linkColor">
                    Cadastro realizado com sucesso!
                  </Text>

                  <Text textStyle="subtitle" textColor="#FFFFFF">
                    Seu perfil será validado por nossa equipe interna e em breve você receberá um e-mail confirmando seu acesso.{' '}
                  </Text>

                  <Text textStyle="subtitle" textColor="#FFFFFF">
                    Enquanto isso, nos siga nas redes sociais e fique por dentro das novidades
                  </Text>
                </Flex>

                <Flex justify="space-between" w="full">
                  <Flex gap="20px">
                    <Tooltip label="Linkedin">
                      <Link to="https://www.linkedin.com/company/vendemmiabr/" target="_blank">
                        <Image src={linkedin} />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Instagram">
                      <Link to="https://www.instagram.com/vendemmiabr/?hl=pt-br" target="_blank">
                        <Image src={instagram} />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Facebook">
                      <Link to="https://www.facebook.com/VendemmiaBR" target="_blank">
                        <Image src={facebook} />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Youtube">
                      <Link to="https://www.youtube.com/c/vendemmialogisticaintegrada" target="_blank">
                        <Image src={youtube} />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Vendemmia">
                      <Link to="https://vendemmia.com.br/" target="_blank">
                        <Image src={siteVendemmia} />
                      </Link>
                    </Tooltip>
                  </Flex>

                  <Button
                    w="fit-content"
                    variant="solid"
                    height="40px"
                    borderRadius="5px"
                    bgColor="#FF2F69"
                    textColor="#FFFFFF"
                    transition=".3s"
                    _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
                    onClick={() => {
                      window.location.href = '/';
                    }}>
                    <Text>Início</Text>
                  </Button>
                </Flex>
              </Flex>
            )}
          </form>
        )}
      </Formik>

      <Loading status={isLoading} />
    </BackgroundLogin>
  );
};

export default RegisterPage;
