import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, FormLabel, Input, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ReactivationEmailForm = ({ setStep }) => {
  const yup = require('yup');

  return (
    <Formik
      initialValues={{
        email: window.localStorage.getItem('profile-email') ?? '',
      }}
      validationSchema={yup.object().shape({
        email: yup.string().required('Campo de e-mail obrigatório'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        requests.sendEmailUserReactivation({
          username: values.email,
        });

        toast.success('Código enviado para email informado!');
        setStep(2);

        setSubmitting(false);
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="30px" className="down-anim">
            <Flex direction="column" gap="10px">
              <Text textStyle="subtitle" textColor="linkColor">
                Reativação de conta expirada
              </Text>
              <Text textStyle="subtitle" textColor="#FFFFFF">
                Informe o e-mail cadastrado para receber instruções para a recuperação da sua conta
              </Text>
            </Flex>

            <Flex direction="column">
              <FormLabel className="up-anim" color="white" m="0">
                E-mail
              </FormLabel>

              <Input
                variant="flushed"
                h="40px"
                color="#FFFFFF"
                name="email"
                type="email"
                _placeholder={{ color: '#FFFFFF' }}
                _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                value={values.email}
                onChange={handleChange}
              />

              <ErrorMessage component="p" className="error-message-error" name="email" />
            </Flex>
          </Flex>

          <Flex
            w="full"
            mt={{ base: '20px', md: '30px' }}
            gap={{ base: '0px', md: '15px' }}
            justify={{ base: 'space-between', md: 'end' }}>
            <Button
              variant="outline"
              height="40px"
              borderRadius="5px"
              borderColor="#FF2F69"
              textColor="#FF2F69"
              transition=".3s"
              _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
              isDisabled={isSubmitting}
              onClick={() => {
                window.location.href = '/';
              }}>
              <Text>Voltar</Text>
            </Button>

            <Button
              type="submit"
              variant="solid"
              height="40px"
              borderRadius="5px"
              bgColor="#FF2F69"
              textColor="#FFFFFF"
              transition=".3s"
              _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
              isLoading={isSubmitting}>
              <Text>Enviar</Text>
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default ReactivationEmailForm;
