import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, Text, Textarea } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const QuoteReject = ({ entity, callback }) => {
  const yup = require('yup');

  return (
    <Formik
      initialValues={{
        rejectDescription: '',
      }}
      validationSchema={yup.object().shape({
        rejectDescription: yup.string().required('Campo obrigatório'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        requests
          .postRequestOperacional(entity?.identifier, {
            status: 'reject',
            description: values.rejectDescription,
          })
          .then(() => {
            toast.success('Análise operacional rejeitada com sucesso!');

            setTimeout(() => {
              window.location.href = '/vendemmia/quote';
            }, 2000);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <Flex mt="20px" justify="center" direction="column" gap="20px">
            <Text textStyle="tableTitle" fontWeight="bold" textAlign="center">
              Rejeitar cotação {entity.name}
            </Text>

            <Text textAlign="center">Para prosseguir, descreva o motivo</Text>

            <Flex direction="column" gap="5px">
              <Textarea
                className="input-register"
                placeholder="Descreeva o motivo..."
                bgColor="#F2F2F2"
                border="1px"
                name="rejectDescription"
                id="rejectDescription"
                value={values.rejectDescription}
                onChange={handleChange}
              />

              <ErrorMessage component="p" className="error-message-error" name="rejectDescription" />
            </Flex>
          </Flex>

          <Flex justify="space-between">
            <Button
              variant="secundary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="25px 35px"
              isDisabled={isSubmitting}
              onClick={() => callback(false)}>
              Cancelar
            </Button>

            <Button type="submit" variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default QuoteReject;
