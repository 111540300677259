import React, { createContext } from 'react';

import { useCommonListProps } from '../Hooks/useCommonListProps';

export const CommonListContext = createContext();

export const CommonListProvider = ({ children, ...props }) => {
  const contextValue = useCommonListProps(props); // Usa as props centralizadas

  return <CommonListContext.Provider value={contextValue}>{children}</CommonListContext.Provider>;
};
