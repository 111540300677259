import React from 'react';

import { Flex, Text, Tooltip } from '@chakra-ui/react';

import ProcessContainersDrawer from '../../pages/comex/process/components/ProcessContainersDrawer';

const LabelWithTextH = ({ title, content, process, icon, tooltipText, detailsTooltipText, disableDrawer }) => {
  return (
    <Flex align="center">
      <style>
        {`
        [role="list"] li div div {
          font-size: 14px;
        }
      `}
      </style>

      {title && (
        <Text textStyle="paragraph" mr="3px" fontWeight="bold">
          {title}:
        </Text>
      )}
      <Flex align="center" gap="5px">
        <Text textStyle="paragraph">{detailsTooltipText ? <Tooltip label={detailsTooltipText}>{content}</Tooltip> : content}</Text>
        <ProcessContainersDrawer process={process} disableDrawer={disableDrawer} icon={icon} tooltipText={tooltipText} />
      </Flex>
    </Flex>
  );
};

export default LabelWithTextH;
