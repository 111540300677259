import React from 'react';

import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

const ChartPortShipmentsContainers = ({ portShipmentsContainersChartData, isLoading }) => {
  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" w="full" px="15px" py="20px" gap="10px">
          <p>{label}</p>

          {payload.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.dataKey == 'processQtt' ? 'Embarques' : 'Containers'}:</Text>

                <Text>{entity.value}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  return (
    <ScreenLoader isLoading={isLoading}>
      <Flex w="full" h="full" direction="column" justify="space-between" p="20px" align="center">
        <ResponsiveContainer height={300}>
          <ComposedChart data={portShipmentsContainersChartData} margin={{ bottom: -10, left: -10, top: 5 }}>
            <CartesianGrid vertical={false} />
            <YAxis tickFormatter={tickFormatter} />
            <XAxis dataKey="port" />
            <Tooltip content={<CustomTooltip />} />

            <Bar dataKey="processQtt" barSize={40} fill="#AA8BE0" />
            <Line dataKey="containerQtt" stroke="#E52359" dot={{ fill: '#E52359' }} />
          </ComposedChart>
        </ResponsiveContainer>

        <Flex gap="20px">
          <Flex align="center" gap="8px">
            <Box w="28px" h="18px" bgColor="#AA8BE0" borderRadius="3px" />
            <Text>Processos</Text>
          </Flex>

          <Flex align="center" gap="8px">
            <Box w="28px" h="18px" bgColor="#E52359" borderRadius="3px" />
            <Text>Container</Text>
          </Flex>
        </Flex>
      </Flex>
    </ScreenLoader>
  );
};

export default ChartPortShipmentsContainers;
