import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Listagem de NCMs',
  // Estrutura da tabela
  categories: [
    {
      title: 'NCM',
      value: 'codeReadable',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'description',
    },
    {
      title: 'Ex tárifarios',
      value: 'exTariffs',
      align: 'center',
    },
    {
      title: 'Ex tipis',
      value: 'exTipis',
      align: 'center',
    },
    {
      title: 'Data de inicio da vigência',
      value: 'startsAtFormatted',
      align: 'center',
    },
    {
      title: 'Status',
      value: 'statusReadable',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  hiddenCategories: ['identifier', 'code', 'startsAt', 'status', 'role'],
  mainCategoryMobile: 'codeReadable',
  // Funcionalidades
  hasCollapsed: false,
  hasPagination: true,
  hasDetailsButton: true,
  detailsRedirectEndpoint: '/vendemmia/ncm-management/detail/${identifier}',
  tags: ['statusReadable'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Ativado', value: 'Ativo' },
    { color: '#E74C3C', label: 'Inativo', value: 'Inativo' },
  ],
  pathSublist: 'itens',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 150,
  // Estilo
  resizeRows: '150px 700px 180px 180px 210px 180px 1fr',
  /* order: ['codeReadable', 'description', 'startsAtFormatted', 'statusReadable'], */
};
