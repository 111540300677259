import { MdHelp } from 'react-icons/md';

import { Box, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

import CustomButtonsRenderer from './components/CustomButtonsRenderer';
import CustomFieldsRenderer from './components/CustomFieldsRenderer';
import { CustomValueRenderer } from './components/CustomValueRenderer';
import { useCategoriesProps } from './Hooks/useCategoriesProps';

export const CommonListItemLineMobile = ({
  categories,
  data,
  item,
  rawItem,
  content,
  listIndex,
  tags,
  images,
  imagePreset,
  identifier,
  hasDetailsButton,
  detailsRedirectEndpoint,
  hasDeleteButton,
  deleteButtonAction,
  customButtons,
  customFields,
  maxLengthResume,
  statusPreset,
  viewport,
}) => {
  return (
    <Grid
      gridTemplateColumns={'1fr'}
      alignItems={'center'}
      border={'1px solid #70707036'}
      borderRadius={'10px'}
      columnGap={'12px'}
      p={'20px 20px 10px'}
      lineHeight={'40px'}
      boxShadow="sm"
      data-screen-width>
      {/* Renderiza dinamicamente os itens sem se referir explicitamente a item.algumacoisa */}
      <Flex flexDirection={'column'}>
        <Flex gap={'15px'} flexDir={'column'} align={'flex-start'}>
          {Object.entries(item).map(([property, value], index) => {
            const {
              linkValue,
              currentCategory: category,
              formattedValue,
              align,
              type,
              variantTag,
              renderHTML,
              showMore,
            } = useCategoriesProps(categories, property, index, item, value);

            // Exibe a propriedade formatada de acordo com o tipo
            return (
              <Text
                key={`${property}-${index}`}
                lineHeight={'20px'}
                display={'grid'}
                gridTemplateColumns={`auto auto ${category.tooltip ? 'auto' : ''}`}
                alignItems={'center'}
                gap={'10px'}
                fontSize={'14px'}
                data-type={type}>
                <Text as={'strong'} fontSize={'14px'}>
                  {category.title}
                </Text>
                <CustomValueRenderer
                  property={property}
                  value={value}
                  index={index}
                  linkValue={linkValue}
                  category={category}
                  formattedValue={formattedValue}
                  identifier={identifier}
                  align={align}
                  type={type}
                  variantTag={variantTag}
                  renderHTML={renderHTML}
                  images={images}
                  imagePreset={imagePreset}
                  tags={tags}
                  statusPreset={statusPreset}
                  viewport={viewport}
                  maxLengthResume={maxLengthResume}
                  rawItem={rawItem}
                  showMore={showMore}
                />
                {category.tooltip && (
                  <Tooltip label={category.tooltip}>
                    <Box ml="0.325rem">
                      <MdHelp size={15} color="#422C76" />
                    </Box>
                  </Tooltip>
                )}
              </Text>
            );
          })}
        </Flex>

        {/* Renderiza campos customizados */}
        <CustomFieldsRenderer customFields={customFields} data={data} listIndex={listIndex} identifier={identifier} />

        {/* Renderiza botões customizados */}
        <CustomButtonsRenderer
          hasDetailsButton={hasDetailsButton}
          detailsRedirectEndpoint={detailsRedirectEndpoint}
          hasDeleteButton={hasDeleteButton}
          deleteButtonAction={deleteButtonAction}
          customButtons={customButtons}
          rawItem={rawItem}
          identifier={identifier}
          content={content}
        />
      </Flex>
    </Grid>
  );
};
