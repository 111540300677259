const yup = require('yup');

export const userValidationSchema = (entity = {}) => {
  return yup.object().shape({
    cellphone: yup.string(),
    phone: yup.string(),
    name: yup.string().required('Campo obrigatório'),
    role: yup.mixed().required('Campo obrigatório'),
    password: entity?.identifier
      ? yup.string()
      : yup.string().required('Campo obrigatório').min(8, 'A senha deve conter no mínimo 8 caracteres'),
    email: yup.string().required('Campo obrigatório').email('Deve ser um e-mail válido').trim(),
  });
};
