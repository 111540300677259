import React, { useEffect, useState } from 'react';

import { MdFileCopy } from 'react-icons/md';
import Select from 'react-select';

import {
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { reportPermission, reportPlay, reportSave, reportTrash } from '../../../../utils/exports';
import roleOptions from '../../user-management/Helpers/roleOptions';

import ReportPageStyles from './ReportPageStyles';

const ReportControllers = ({
  dataReport,
  loadReportRun,
  isNewReport,
  handlerRemoveReport,
  handlerEditReport,
  handlerCreateReport,
  isLoadingRun,
  isLoadingCreate,
  isLoadingDelete,
  changenameButtonRun,
  hiddenButtonCreate,
  hiddenButtonDuplicate,
  hiddenButtonDelete,
  hiddenButtonPermission,
  containerHeight,
  justifyContent,
  resetStyles,
  unsavedChanges,
  removeUnsaveChanges,
  allowedRoles,
  setAllowedRoles,
  labelsForRoles,
  setLabelsForRoles,
  allowedUsers,
  setAllowedUsers,
  labelsForUserRoles,
  setLabelsForUserRoles,
  responsibleUsersOptions,
  isLoadingUserPermission,
}) => {
  const [identifier, setIdentifier] = useState();

  const [profilePermissionsOptions, setProfilePermissionsOptions] = useState([]);
  const [userPermissionsOptions, setUserPermissionsOptions] = useState([]);
  /* const profilePermissionIsReady = profilePermissionsOptions.length > 0 */

  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();

  const isPageForCreationOrEditing = () => {
    //remover avisos após salvar as atualizações pendentes
    removeUnsaveChanges();

    isNewReport ? handlerCreateReport() : handlerEditReport(dataReport);
  };

  function scrollToTableRun() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth', // Para animar o scroll suavemente
    });
  }

  const handlerClickRun = () => {
    closeDrawer();
    loadReportRun(dataReport);
    scrollToTableRun();
  };

  const handlerClickDuplicate = () => {
    handlerCreateReport('duplicate');
  };

  const closeDrawer = () => {
    const closeButton = document.querySelector('[aria-label="Close"');
    closeButton && closeButton.click();
  };

  const handlerAddPermissionsToProfile = (event) => {
    const { permissions, labels } = getPermissionAndLabelData(event);

    setAllowedRoles(permissions);
    setLabelsForRoles(labels);
  };

  const handlerAddPermissionsToUser = (event) => {
    const { permissions, labels } = getPermissionAndLabelData(event);

    setAllowedUsers(permissions);
    setLabelsForUserRoles(labels);
  };

  const getPermissionAndLabelData = (event) => {
    unsavedChanges(true);

    const data = {
      permissions: event.map((role) => role.value),
      labels: event.map((role) => role.label),
    };

    return data;
  };

  useEffect(() => {
    /* formatos dos dados
      allowedRoles = ['ROLE_CLIENT', 'ROLE_TAX_INSPECTOR', 'ROLE_FINANCIAL_INSPECTOR'];
      labelsForRoles = ['Cliente', 'Tributário', 'Financeiro'];
    */

    //converte no formato especifico: { value: 'ROLE_CLIENT', label: 'Cliente' },
    const mergedArray = allowedRoles.map((value, index) => ({
      value,
      label: labelsForRoles[index],
    }));

    setProfilePermissionsOptions(mergedArray);
  }, [allowedRoles, labelsForRoles]);

  useEffect(() => {
    /* formatos dos dados
      allowedUsers = ["Jos7fvHFe2S_uHY", "RQoOa38nzbkLBgj"];
      labelsForUserRoles = ["Adriano Lourenço Ferreira", "Adriana Nascimento (expirado)"];
    */

    //converte no formato especifico: { value: 'Jos7fvHFe2S_uHY', label: Adriano Lourenço Ferreira },
    const mergedArray = allowedUsers.map((value, index) => ({
      value,
      label: labelsForUserRoles[index],
    }));

    setUserPermissionsOptions(mergedArray);
  }, [allowedUsers, labelsForUserRoles]);

  useEffect(() => {
    setIdentifier(dataReport?.identifier);
  }, [dataReport]);

  const optionsPermissionsView = [
    {
      title: 'Permissões por perfil',
      defaultValue: profilePermissionsOptions,
      options: roleOptions,
      handler: handlerAddPermissionsToProfile,
    },
    {
      title: 'Permissões por usuário',
      defaultValue: userPermissionsOptions,
      options: responsibleUsersOptions,
      handler: handlerAddPermissionsToUser,
    },
  ];

  return (
    <Card
      bg={'white'}
      h={containerHeight}
      display={'flex'}
      flexDirection={'row'}
      align={'center'}
      minH={'55px'}
      p={resetStyles ? '18px 40px' : '18px 26px'}
      boxShadow={resetStyles && 'none'}
      justifyContent={justifyContent}>
      <ReportPageStyles />

      <Grid
        gap={'10px'}
        alignItems={'center'}
        flexWrap={{ base: 'wrap', md: 'initial' }}
        gridTemplateColumns={{ base: '1fr 1fr', md: 'initial' }}
        w={{ base: '100%', md: 'initial' }}
        display={{ base: 'grid', md: 'flex' }}>
        {!isNewReport && (
          <ScreenLoader isLoading={isLoadingRun}>
            <Button leftIcon={<Image src={reportPlay} />} w={'full'} variant="secundary" onClick={() => handlerClickRun()}>
              {changenameButtonRun ?? 'Executar'}
            </Button>
          </ScreenLoader>
        )}
        {!hiddenButtonCreate && (
          <ScreenLoader isLoading={isLoadingCreate}>
            <Button leftIcon={<Image src={reportSave} />} w={'full'} variant="secundary" onClick={isPageForCreationOrEditing}>
              Salvar
            </Button>
          </ScreenLoader>
        )}
        {!isNewReport && (
          <>
            {!hiddenButtonDuplicate && (
              <ScreenLoader isLoading={isLoadingCreate}>
                <Button leftIcon={<MdFileCopy color="#a885f9" />} w={'full'} variant="secundary" onClick={handlerClickDuplicate}>
                  Duplicar
                </Button>
              </ScreenLoader>
            )}
            {!hiddenButtonDelete && (
              <ScreenLoader isLoading={isLoadingDelete}>
                <DefaultModal
                  ctaButton={
                    <Button leftIcon={<Image src={reportTrash} />} w={'full'} variant="secundary">
                      Excluir
                    </Button>
                  }
                  title={'Certeza que deseja excluir ?'}
                  subtitle={
                    <>
                      Deseja mesmo excluir esse documento?
                      <br />
                      Ele sumirá automaticamente da lista de documentos.
                    </>
                  }
                  buttonColor="red"
                  txtButton="Confirmar"
                  loadingText="Excluindo"
                  callback={async (decision) => {
                    if (decision) {
                      await handlerRemoveReport(identifier);
                    }
                  }}
                />
              </ScreenLoader>
            )}
            {!hiddenButtonPermission && (
              <>
                <ScreenLoader isLoading={responsibleUsersOptions.length > 0 ? false : true}>
                  <Button leftIcon={<Image src={reportPermission} />} w={'full'} variant="secundary" onClick={onOpen}>
                    Permissões
                  </Button>
                  <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader
                        borderLeft={'6px solid #A885F9'}
                        p={'0 0 0 18px'}
                        m={'50px 35px'}
                        fontSize={'33px'}
                        color={'primary'}
                        fontWeight={'700'}>
                        <Text>
                          Permissões de{' '}
                          <Text as={'span'} color={'#A885F9'}>
                            {dataReport?.name}
                          </Text>
                        </Text>
                      </DrawerHeader>

                      <DrawerBody p={'0 57px'}>
                        <Text fontSize={'22px'} lineHeight={'29px'} color={'#6E6E6E'}>
                          Usuários que atenderem a qualquer um dos critérios abaixo, terão acesso ao relatório.
                        </Text>
                        {optionsPermissionsView.map((permission) => {
                          return (
                            <>
                              <Text as={'section'} display={'flex'} flexDirection={'column'} pt={'46px'}>
                                <Text fontSize={'20px'} lineHeight={'26px'} color={'titleColor'} pb={'35px'}>
                                  {permission.title}
                                </Text>
                                <Text display={'flex'} direction="column" gap="8px">
                                  <Select
                                    defaultValue={permission.defaultValue}
                                    placeholder="Selecione"
                                    options={permission.options}
                                    onChange={permission.handler}
                                    isMulti
                                  />
                                </Text>
                              </Text>
                            </>
                          );
                        })}
                        {!hiddenButtonCreate && (
                          <Flex textAlign={'right'}>
                            <ScreenLoader isLoading={isLoadingCreate}>
                              <Button
                                mt={'44px'}
                                maxW={'108px'}
                                bg={'primary'}
                                color={'#a885f9'}
                                w={'full'}
                                _focus={{ color: '#a885f9' }}
                                variant="secundary"
                                onClick={isPageForCreationOrEditing}
                                leftIcon={<Image src={reportPermission} />}>
                                Salvar
                              </Button>
                            </ScreenLoader>
                          </Flex>
                        )}
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </ScreenLoader>
              </>
            )}
          </>
        )}
      </Grid>
    </Card>
  );
};

export default ReportControllers;
