export const DynamicFormStyles = () => {
  return (
    <style>
      {`

        .chakra-modal__content {
          padding: 25px 35px;
        }

        .chakra-modal__content form {
          display: grid;
          flex: 1 1 0%;
          align-content: space-between;
        }

        .chakra-modal__content form input, 
        .chakra-modal__content form select {
          border: 1px solid #70707036;
          background-color: #F5F5F5;
          height: initial;
          padding: 12px 20px;
          max-height: 47px;
        }

        .chakra-modal__content form input:hover, 
        .chakra-modal__content form select:hover, 
        .chakra-modal__content form input:focus-visible, 
        .chakra-modal__content form select:focus-visible, 
        [data-focus-visible] {
          border: 1px solid #6C48C2;
        }
          
        .chakra-modal__content form input::placeholder {
          color: #C0C0C0;
        }

        .chakra-modal__content form label {
          color: #422C76;
          font-size: 14px;
          font-weight: medium;
        }
      
      `}
    </style>
  );
};
