import React, { useEffect, useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdHistory, MdOutlineSave } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Flex, HStack, Input, Tag, TagCloseButton, TagLabel, Text, Textarea, useDisclosure } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import { CommonDrawer } from '../../../../components/CommonDrawer/CommonDrawer';
import CommonList from '../../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../../components/CommonList/methods/commonListMethods';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import FloatActionButton from '../../../../components/Generic/FloatActionButton';
import Page from '../../../../components/Page';
import requests from '../../../../services/requests';
import closeFloatActionButton from '../../../../utils/actions/modal/closeFloatActionButton';
import formatNCM from '../../../../utils/fields/masks/formatNCM';
import { executeRequest } from '../../../../utils/requests/executeRequest';
import { initialValues } from '../Helpers/initialValues';
import { validationSchema } from '../Helpers/validationSchema';

import { commonListHistoryViewerConfig } from './commonListHistoryViewerConfig';
import NcmDetailPageStyles from './NcmDetailPageStyles';

// Constantes para mensagens de sucesso e erro
const MESSAGES = {
  editSuccess: 'Consulta NCM editada com sucesso',
  editError: 'Problema ao editar consulta NCM',
  createSuccess: 'Consulta NCM cadastrada com sucesso',
  createError: 'Problema ao cadastrar consulta NCM',
};

const NcmDetailPage = () => {
  let navigate = useNavigate();

  const { identifier } = useParams();
  const [isMobile, setIsMobile] = useState();
  const [deviceType, setDeviceType] = useState();
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);
  const [entity, setEntity] = useState({});

  const [ncmLogs, setNcmLogs] = useState({});

  const hasIdentifier = entity?.identifier;

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //trigger Drawer
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();

  const [listTags, setListTags] = useState({ exTariffs: [], exTipis: [] });
  const [tagInput, setTagInput] = useState({});

  const fetchNCMQueryData = async () => {
    const res = await executeRequest({
      action: () => requests.fetchNCMQuery(identifier),
      setIsLoading,
    });

    setEntity(res);
    setListTags({
      exTariffs: Array.isArray(res.exTariffs) ? res.exTariffs : [],
      exTipis: Array.isArray(res.exTipis) ? res.exTipis : [],
    });
    setIsLoading(false);

    setIsLoading(true);
  };

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  const checkDisabled = (name) => {
    if (!identifier && name !== 'status') {
      return false;
    } else if (entity?.status === 'ACTIVE') {
      return false;
    }

    return true;
  };

  const showButtons = () => {
    if (!identifier) {
      return true;
    } else if (entity?.status === 'ACTIVE') {
      return true;
    }

    return false;
  };

  const viewNCMLogsData = async (identifier) => {
    if (!identifier) {
      return;
    }

    const res = await executeRequest({
      action: () => requests.viewNCMLogs(identifier),
      setIsLoading,
    });

    setNcmLogs(res.data);
    setMetadata(res.meta);
  };

  useEffect(() => {
    if (identifier) {
      fetchNCMQueryData(identifier);
    }
  }, []);

  const load = (filters) => {
    viewNCMLogsData(identifier);
  };

  useEffect(() => {
    load();
  }, [action]);

  const statusOptions = [
    { value: 'ACTIVE', label: 'Ativo' },
    { value: 'DISCONTINUED', label: 'Descontinuado' },
  ];

  const handleTagAdd = (modality) => {
    if (tagInput[modality].trim() !== '') {
      setListTags((prevState) => {
        const currentTags = Array.isArray(prevState[modality]) ? prevState[modality] : [];
        return {
          ...prevState,
          [modality]: [...currentTags, tagInput[modality].trim()],
        };
      });

      setTagInput({ [modality]: '' });
    }
  };

  const handleTagRemove = (modality, index) => {
    setListTags((prevState) => {
      const newTags = Array.isArray(prevState[modality]) ? [...prevState[modality]] : [];
      newTags.splice(index, 1);

      return {
        ...prevState,
        [modality]: newTags,
      };
    });
  };

  const onSubmitForm = (values) => {
    const aux = {
      code: values?.code,
      description: values?.description,
      startsAtFormatted: values?.startsAtFormatted,
      exTariffs: listTags?.exTariffs?.length > 0 ? listTags?.exTariffs : [],
      exTipis: listTags?.exTipis?.length > 0 ? listTags?.exTipis : [],
    };

    if (hasIdentifier) {
      executeRequest({
        action: () => requests.editNCMQuery(entity.identifier, aux),
        msgSuccess: MESSAGES.editSuccess,
        msgError: MESSAGES.editError,
        setIsLoading,
        callback: () => {
          setTimeout(() => {
            navigate('/vendemmia/ncm-management');
          }, 1000);
        },
      });
    } else {
      executeRequest({
        action: () => requests.createNCMQuery(aux),
        msgSuccess: MESSAGES.createSuccess,
        msgError: MESSAGES.createError,
        setIsLoading,
        callback: () =>
          setTimeout(() => {
            navigate('/vendemmia/ncm-management');
          }, 1000),
      });
    }
  };

  return (
    <Page
      title={entity.identifier ? `NCM ${entity?.code}` : 'Nova consulta'}
      breadcrumbs={[
        { link: '#', title: 'Gestão' },
        { link: '/vendemmia/ncm-management', title: 'Gestão de NCM' },
      ]}
      setIsMobile={setIsMobile}
      setDeviceType={setDeviceType}
      load={load}
      isContentLoading={isLoading}
      hideAllFilters={true}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}>
      <NcmDetailPageStyles />
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues(entity)}
          validationSchema={validationSchema()}
          onSubmit={(values) => {
            onSubmitForm(values); // Quando o formulário for submetido, chama a função callback com os valores do formulário.
          }}>
          {({ values, setFieldValue, handleChange, handleSubmit, lisSubmitting, errors }) => (
            <form id="form" onSubmit={handleSubmit}>
              <Card
                m="10px"
                header={
                  <Flex gap="2px" direction="column">
                    <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                      Detalhes de NCM {entity?.code}
                    </Text>
                  </Flex>
                }>
                <Flex data-form-wrapper>
                  <Flex data-form-card mt="0">
                    <Text mb="4" color="secondary">
                      Dados da NCM
                    </Text>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" flexWrap={'wrap'} width={{ sm: 'full', md: 'initial' }}>
                        <LabelDefault name="code" text="NCM" />
                        <InputDefault
                          width={'full'}
                          setFieldValue={setFieldValue}
                          value={values.code}
                          maxLength={10}
                          onChange={(event) => {
                            const formattedNCM = formatNCM(event.target.value);
                            handleChange({ target: { name: 'code', value: formattedNCM } });
                          }}
                          disabled={checkDisabled('code')}
                          name="code"
                          placeholder="Digite o código NCM"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="code" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex data-form-card>
                    <Flex gap="1.875rem" direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column">
                        <LabelDefault name="description" text="Descrição" />

                        <Textarea
                          name="description"
                          disabled={checkDisabled('description')}
                          width={{ base: 'full', md: '66.25rem' }}
                          height="11.25rem"
                          resize="none"
                          bg="#F2F2F2"
                          mt="1"
                          value={values.description}
                          onChange={(event) => setFieldValue('description', event.target.value)}
                        />

                        <ErrorMessage component="p" className="error-message-error" name="description" />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex data-form-wrapper>
                  <Flex data-form-card mt="0">
                    <Text mb="4" color="secondary">
                      Dados Ex Tárifario
                    </Text>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" flexWrap={'wrap'} width={{ sm: 'full', md: 'initial' }}>
                        <LabelDefault name="exTariffs" text="Códigos" />
                        <HStack spacing={4}>
                          <Flex direction="column">
                            <Input
                              type="number"
                              placeholder="Digite o código e pressione Enter"
                              value={tagInput.exTariffs}
                              disabled={checkDisabled('tags')}
                              onChange={(e) => setTagInput({ exTariffs: e.target.value })}
                              width={{ base: '10rem', md: '18rem' }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleTagAdd('exTariffs');
                                }
                              }}
                            />
                            <Flex gap="10px" mt="4">
                              {listTags?.exTariffs?.map((tag, index) => (
                                <Tag size="md" key={index} variant="subtle" colorScheme="cyan">
                                  <TagLabel>{tag}</TagLabel>
                                  {showButtons() && <TagCloseButton onClick={() => handleTagRemove('exTariffs', index)} />}
                                </Tag>
                              ))}
                            </Flex>

                            {errors?.tags && <Text className="error-message-error">{errors.tags}</Text>}
                          </Flex>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex data-form-wrapper>
                  <Flex data-form-card mt="0">
                    <Text mb="4" color="secondary">
                      Dados Ex Tipi
                    </Text>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" flexWrap={'wrap'} width={{ sm: 'full', md: 'initial' }}>
                        <LabelDefault name="exTipis" text="Códigos" />
                        <HStack spacing={4}>
                          <Flex direction="column">
                            <Input
                              type="number"
                              placeholder="Digite o código e pressione Enter"
                              value={tagInput.exTipis}
                              disabled={checkDisabled('tags')}
                              onChange={(e) => setTagInput({ exTipis: e.target.value })}
                              width={{ base: '10rem', md: '18rem' }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleTagAdd('exTipis');
                                }
                              }}
                            />
                            <Flex gap="10px" mt="4">
                              {listTags?.exTipis?.map((tag, index) => (
                                <Tag size="md" key={index} variant="subtle" colorScheme="cyan">
                                  <TagLabel>{tag}</TagLabel>
                                  {showButtons() && <TagCloseButton onClick={() => handleTagRemove('exTipis', index)} />}
                                </Tag>
                              ))}
                            </Flex>

                            {errors?.tags && <Text className="error-message-error">{errors.tags}</Text>}
                          </Flex>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>

              {showButtons() && (
                <FloatActionButton
                  saveButton={
                    <Button
                      rightIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                      type="submit"
                      h="46px"
                      px="24px"
                      py="14px"
                      bgColor="primary"
                      borderRadius="27px"
                      color="#FFFFFF"
                      loadingText="Salvando"
                      _hover={{ bgColor: '#8067DC' }}>
                      <Text>{identifier ? 'Salvar edição' : 'Salvar cadastro'}</Text>
                    </Button>
                  }
                  options={
                    identifier && [
                      <>
                        <Button
                          variant={'floatingGreenButton'}
                          onClick={() => {
                            drawerOnOpen();
                            closeFloatActionButton();
                          }}>
                          <Text mr="10px" color="white">
                            Ver Historico
                          </Text>
                          <MdHistory size={20} color="#ffffff" />
                        </Button>
                      </>,
                    ]
                  }
                  isOpen={isOpenFloatButton}
                  setIsOpen={setIsOpenFloatButton}
                />
              )}
            </form>
          )}
        </Formik>
      </Box>
      {/* NcmHistoryViewer */}
      <CommonDrawer
        showFooter={false}
        placement="left"
        heading={'Histórico'}
        isOpen={drawerIsOpen}
        onOpen={drawerOnOpen}
        onClose={() => {
          drawerOnClose();
        }}>
        <CommonList
          list={ncmLogs}
          rawData={ncmLogs}
          action={action}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          isLoading={isLoading}
          {...commonListHistoryViewerConfig}
        />
      </CommonDrawer>
    </Page>
  );
};

export default NcmDetailPage;
