import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, Select, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const QuoteApprove = ({ entity, callback }) => {
  const yup = require('yup');

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  return (
    <Formik
      initialValues={{
        approveDescription: '',
      }}
      validationSchema={yup.object().shape({
        approveDescription: yup.mixed().required('Selecione o agente de carga'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        requests
          .postRequestOperacional(entity?.identifier, { status: 'approve', description: values.approveDescription })
          .then(() => {
            toast.success('Análise operacional aprovada com sucesso!');

            setTimeout(() => {
              window.location.href = '/vendemmia/quote';
            }, 2000);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <Flex mt="20px" justify="center" direction="column" gap="20px">
            <Text textStyle="tableTitle" fontWeight="bold" textAlign="center">
              Aprovar cotação {entity.name}
            </Text>

            <Text textAlign="center">Para prosseguir, selecione o agente de carga</Text>

            <Select
              styles={selectCustomStyles()}
              placeholder="Selecione..."
              name="approveDescription"
              value={values.approveDescription}
              onChange={handleChange}>
              <option value="vendemmia">Indicado pela Vendemmia</option>
              <option value="cliente">Indicado pelo cliente</option>
            </Select>
          </Flex>
          <ErrorMessage component="p" className="error-message-error" name="approveDescription" />

          <Flex justify="space-between">
            <Button
              variant="secundary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="25px 35px"
              isDisabled={isSubmitting}
              onClick={() => callback(false)}>
              Cancelar
            </Button>

            <Button type="submit" variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default QuoteApprove;
