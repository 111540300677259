import React, { useEffect, useState } from 'react';

import { Box, Tooltip } from '@chakra-ui/react';

const ChannelFlag = ({ color = '' }) => {
  const [tooltipLabel, setTooltipLabel] = useState('Sem canal');

  const translateChannel = (color) => {
    switch (color.toLowerCase()) {
      case 'amarelo':
        return 'yellow';

      case 'verde':
        return 'green';

      case 'vermelho':
        return 'red';

      case 'cinza':
        return 'gray';

      case '':
        return 'white';

      default:
        return color.toLowerCase();
    }
  };

  const colors = {
    yellow: '#F1C40F',
    green: '#2ECC71',
    blue: '#3498DB',
    gray: '#555555',
    red: '#950E33',
    white: '#FFFFFF',
  };

  const label = {
    yellow: 'Amarelo',
    green: 'Verde',
    blue: 'Azul',
    gray: 'Cinza',
    red: 'Vermelho',
  };

  useEffect(() => {
    if (color === '') {
      setTooltipLabel('Sem canal');
    } else {
      const channelColor = translateChannel(color);
      setTooltipLabel(`Canal ${label[channelColor]}`);
    }
  }, [color]);

  return (
    <Tooltip label={tooltipLabel}>
      <Box w="15px" h="15px" borderRadius="100%" bgColor={colors[translateChannel(color)]} shadow="lg" cursor="default" />
    </Tooltip>
  );
};

export default ChannelFlag;
