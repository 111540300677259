import React, { useState } from 'react';

import { Flex, Image, Textarea } from '@chakra-ui/react';

import suspend from '../../../../../assets/images/block_ball_small.svg';
import confirm from '../../../../../assets/images/confirm_ball_small.svg';
import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';

import DocumentModalView from './DocumentModalView';

const DocumentsActions = ({ enableActions = true, identifier, name, document, callback }) => {
  const [load, setLoad] = useState(false);
  const [observation, setObservation] = useState('');

  const handleTextareaChange = (event) => {
    setObservation(event.target.value);
  };

  return (
    <Flex>
      <Flex gap="8px">
        <DefaultModal
          label="Aprovar"
          ctaButton={<Image src={confirm} />}
          title={'Confirmar aprovação'}
          loading={load}
          subtitle={
            <>
              Você tem certeza que deseja aprovar este documento?
              <br /> Valide corretamente antes de realizar a aprovação.
            </>
          }
          buttonColor="green"
          txtButton="Aprovar"
          enabled={enableActions}
          callback={(decision) => {
            if (decision) {
              callback(document.identifier, 'approve');
            }
          }}
        />
        <DefaultModal
          label="Reprovar"
          ctaButton={<Image src={suspend} />}
          title={'Certeza que deseja reprovar?'}
          loading={load}
          subtitle={
            <>
              Você tem certeza que deseja reprovar esse documento?
              <br /> Ao reprovar o motorista receberá uma notificação com a sua mensagem de observação.
            </>
          }
          buttonColor="red"
          txtButton="Reprovar"
          enabled={enableActions}
          callback={(decision) => {
            if (decision) {
              callback(document.identifier, 'reject', { observation: observation });
            }
          }}>
          <Textarea
            height="180px"
            resize="none"
            mt="20px"
            bg="#F2F2F2"
            placeholder="Descreva a motivo..."
            value={observation}
            onChange={handleTextareaChange}
          />
        </DefaultModal>
      </Flex>
      {document?.path && <DocumentModalView identifier={identifier} name={name} file={document} />}
    </Flex>
  );
};

export default DocumentsActions;
