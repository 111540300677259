import React, { useEffect, useState } from 'react';

import { MdDownloadForOffline } from 'react-icons/md';

import { Box, Button, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import api from '../../../services/api';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsMapping } from '../../../utils/filters/filterPresets';

import initialFields from './components/initialFields';
import MapTable from './components/MapTable';
import ModalConfigFilter from './components/ModalConfigFilter';

const MappingPage = () => {
  const hasPermission = permissions.comexMapping;

  const screenPage = useScreenPage();

  const [isLoading, setIsLoading] = useState(true);
  const [filterSalved, setFilterSalved] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [columnData, setColumnData] = useState(initialFields);
  const [list, setList] = useState([]);

  const [appliedFields, setAppliedFields] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState({});

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const [isDownloadingXlsx, setIsDownloadingXlsx] = useState(false);

  const load = async () => {
    setIsLoading(true);

    await requests
      .listMap(appliedFilter, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        setList(data.data);
        setMetadata(data.meta);

        if (filterSalved) {
          refreshAppliedFields(appliedFilter.fields);
        } else {
          refreshAppliedFields(columnData);
        }

        setFilterSalved(false);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const listAdditionalFields = () => {
    setIsLoading(true);

    requests
      .listAditionalFields({ screenPage })
      .then((data) => {
        var aux = initialFields;

        aux.forEach((item, index) => {
          if (item.id === 'camposAdicionais') {
            data.data.map((adittionalField) => {
              aux[index].subFields.push({
                id: 'camposAdicionais',
                name: adittionalField.label,
                active: true,
              });
            });
          }
        });
        refreshAppliedFields(aux);
        setColumnData(aux);
      })
      .finally(() => setIsLoading(false));
  };

  const refreshAppliedFields = (fields) => {
    var copy = [];

    for (var attr in fields) {
      if (fields.hasOwnProperty(attr)) copy[attr] = clone(fields[attr]);
    }

    setAppliedFields(copy);
  };

  const clone = (obj) => {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || 'object' != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  };

  const downloadXlsx = () => {
    setIsDownloadingXlsx(true);

    var filters = appliedFilter;
    filters.fields = appliedFields;

    api
      .get(`/map-filter/map/export-xlsx?${new Date().getTime()}`, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
        params: { filters: JSON.stringify(filters) },
      })
      .then((response) => {
        const fileType = response.headers['content-type'];
        const blob = new Blob([response.data], { fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportXLSX.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(() => {
        setIsDownloadingXlsx(false);
      });
  };

  const downloadCsv = () => {
    setIsDownloadingCsv(true);
    var filters = appliedFilter;
    filters.fields = appliedFields;

    api
      .get(`/map-filter/map/export-csv?${new Date().getTime()}`, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
        params: { filters: JSON.stringify(filters) },
      })
      .then((response) => {
        const fileType = response.headers['content-type'];
        const blob = new Blob([response.data], { fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportCSV.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(() => {
        setIsDownloadingCsv(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const options = await filterOptionsMapping();
        setFilterOptions(options);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching filter options:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsMapping());
  };

  useEffect(() => {
    load();
  }, [action, appliedFilter]);

  useEffect(() => {
    getFilterOptions();
    listAdditionalFields();
  }, []);

  return (
    <Page
      screen="mapping"
      title="Mapeamento"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isRefreshLoading={isLoading}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      hideAllFilters={true}
      FAB={[
        {
          title: 'Exportar .csv',
          text: 'Exportar .csv',
          action: (
            <Button
              h="46px"
              px="24px"
              py="14px"
              bgColor="green"
              color="#FFFFFF"
              borderRadius="27px"
              _hover={{ bgColor: '#70D499' }}
              isLoading={isDownloadingCsv}
              rightIcon={<MdDownloadForOffline color="#FFFFFF" size={20} />}
              onClick={() => downloadCsv()}>
              <Text>Exportar .csv</Text>
            </Button>
          ),
          modality: 'custom',
        },
        {
          title: 'Exportar .xlsx',
          text: 'Exportar .xlsx',
          action: (
            <Button
              h="46px"
              px="24px"
              py="14px"
              bgColor="green"
              color="#FFFFFF"
              borderRadius="27px"
              _hover={{ bgColor: '#70D499' }}
              isLoading={isDownloadingXlsx}
              rightIcon={<MdDownloadForOffline color="#FFFFFF" size={20} />}
              onClick={() => downloadXlsx()}>
              <Text>Exportar .xlsx</Text>
            </Button>
          ),
          modality: 'custom',
        },
        {
          title: 'Configurar filtro',
          text: 'Configurar filtro',
          action: (
            <ModalConfigFilter
              key={3}
              setAppliedFilter={setAppliedFilter}
              refreshAppliedFields={refreshAppliedFields}
              setColumnData={setColumnData}
              columnData={columnData}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              setFilterSalved={setFilterSalved}
            />
          ),
          modality: 'custom',
        },
      ]}>
      <Card m="10px" className="up-anim">
        <ScreenLoader isLoading={isLoading}>
          <ExpandContainer>
            <ScreenLoader isLoading={isLoading}>
              <Box w="full">
                <Box overflow="auto" h="calc(100vh - 220px)">
                  <MapTable data={list} appliedFields={appliedFields} isLoading={isLoading} />
                </Box>

                {metadata.total_pages > 1 && (
                  <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
                )}
              </Box>
            </ScreenLoader>
          </ExpandContainer>

          <Box maxH="calc(100vh - 260px)" overflow="auto">
            <MapTable data={list} appliedFields={appliedFields} isLoading={isLoading} />
          </Box>

          {metadata.total_pages > 1 && (
            <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
          )}
        </ScreenLoader>
      </Card>
    </Page>
  );
};

export default MappingPage;
