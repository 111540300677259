import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { Button, Image, Modal, ModalBody, ModalContent, ModalFooter, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import EditPass from '../../../../components/DropdownProfile/Edit/EditPass';
import requests from '../../../../services/requests';

export const ModalRedefineDriverPassword = ({ icon, enabled, w = '25px', user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [password, setPassword] = useState('');

  const [isLoaded, setIsLoaded] = useState(false);

  const saveProfilePassword = async () => {
    setIsLoaded(true);

    try {
      await requests.editUserPassword(user.identifier, {
        password: password,
      });
      toast.success('Dados alterados com sucesso!');
      setIsLoaded(false);
    } catch (error) {
      toast.error('Algo deu errado, por favor, revise os dados inseridos.');
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Tooltip label="Alterar senha">
        <Image
          w={{ base: '38px !important', md: w }}
          src={icon}
          alt="Enviar viagem"
          filter="saturate(0)"
          cursor="pointer"
          transition="0.2s"
          _hover={{
            filter: enabled ? 'saturate(1)' : 'saturate(0)',
          }}
          onClick={enabled ? onOpen : onClose}
        />
      </Tooltip>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalContent borderRadius="5px" maxWidth={'400px'} margin={'25px'}>
          <ModalBody p="25px" position="relative">
            <EditPass setPassword={setPassword} maxW={'full'} />
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Text>
              <Button variant="secundary" borderRadius="7px" onClick={onClose}>
                Cancelar e Fechar
              </Button>

              <Button
                variant="primary"
                borderRadius="7px"
                marginLeft="15px"
                isLoading={isLoaded}
                onClick={async () => {
                  if (password.length >= 8) {
                    await saveProfilePassword();
                  } else {
                    password.length >= 8 && toast.error('A senha precisa ter pelo menos 8 caracteres.');
                    password.length === 0 && toast.error('Um ou mais campos de senha não foram preenchidos.');
                  }
                }}>
                Salvar
              </Button>
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
