import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Card, Flex } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

export const ProcessDetailsCard = ({ title, isLoading, children, ...props }) => {
  return (
    <Card w="full">
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem border={0}>
          <h2>
            <AccordionButton p={'20px'} maxH={'60px'} pointerEvents={isLoading ? 'none' : 'auto'}>
              <Box as="span" flex="1" textAlign="left" color={'primary'} fontWeight={'bold'} fontSize={'20px'}>
                {title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <ScreenLoader isLoading={isLoading}>
            <AccordionPanel borderTop={'1px solid #0F0A1D26'} pb={4}>
              <Flex direction="column" gap="5px" m="25px 10px">
                {children}
              </Flex>
            </AccordionPanel>
          </ScreenLoader>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
