import React, { useState } from 'react';

import moment from 'moment';
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Select, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import CustomTooltip from '../../../../components/Tooltip/customTooltip';
import moneyFormat from '../../../../utils/currency/moneyFormat';

const CardAcquisitionCostsEvolution = ({ data }) => {
  const [chartType, setChartType] = useState('bar');

  const tickFormatter = (tickItem) => {
    if (tickItem !== undefined && tickItem !== null) {
      return tickItem.toLocaleString('pt-BR');
    }
    return '';
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  let chartComponent;

  switch (chartType) {
    case 'bar':
      const barChartData = data.map((entity) => ({
        sum: parseInt(entity.sum, 10),
        date: moment(entity.date).format('MM/YYYY'),
      }));

      chartComponent = (
        <Flex direction="column" alignItems="center" w="full">
          <ResponsiveContainer>
            <BarChart
              data={barChartData}
              margin={{
                left: 40,
              }}>
              <CartesianGrid />
              <Tooltip content={
                <CustomTooltip payload={data} tooltip={
                  [
                    {
                      name: "Valor",
                      dataKey: "sum",
                      format: "currency",
                      style: "USD"
                    }
                  ]
                }>
                </CustomTooltip>}
              />

              <XAxis dataKey="date" tickLine={false} />
              <YAxis yAxisId="left" orientation="left" stroke="#422C76" tickFormatter={tickFormatter} />

              <Bar dataKey="sum" yAxisId="left" barSize={25} fill="#422C76" />
            </BarChart>
          </ResponsiveContainer>
        </Flex>
      );
      break;

    case 'line':
      const lineChartData = data.map((entity) => ({
        sum: parseInt(entity.sum, 10),
        date: moment(entity.date).format('MM/YYYY'),
      }));

      chartComponent = (
        <Flex direction="column" alignItems="center" w="full">
          <ResponsiveContainer height={'100%'} width={'95%'}>
            <LineChart
              data={lineChartData}
              margin={{
                left: 40,
                right: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <XAxis dataKey="date" tickLine={false} />
              <YAxis yAxisId="left" orientation="left" stroke="#422C76" tickFormatter={tickFormatter} />

              <Line dataKey="sum" type="monotone" yAxisId="left" stroke="#422C76" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </Flex>
      );
      break;

    case 'table':
      const tableChartData = data.map((entity) => ({
        sum: parseInt(entity.sum, 10),
        date: moment(entity.date).format('MM/YYYY'),
      }));
      chartComponent = (
        <Table className="table-default" w="max-content">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              <Th>Mês</Th>
              <Th>Soma</Th>
            </Tr>
          </Thead>
          <Tbody border="1px">
            {tableChartData.map(function (entity, key) {
              return (
                <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                  <Td>{entity.date}</Td>
                  <Td>{moneyFormat(entity.sum, 'USD')}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      );
      break;

    default:
      chartComponent = null;
      break;
  }

  return (
    <Card
      header={
        <Flex align="center" justify="center" gap="20px" flexDirection={{ base: "column", md: "row" }}>
          <Select w={{ base: "100%", md: "260px" }} variant="primary" onChange={handleChartTypeChange}>
            <option value="bar">Gráfico em barras</option>
            <option value="line">Gráfico em linhas</option>
            <option value="table">Tabela de dados</option>
          </Select>

          <Text textStyle="secondaryTitle" fontSize={{ base: "16px", md: "26px" }} textColor="linkColor" fontWeight="medium">
            Evolução dos valores nacionalizados em US$
          </Text>
        </Flex>
      }>
      <Flex minH="68vh" p="20px" overflowY="scroll">
        {chartComponent}
      </Flex>
    </Card>
  );
};

export default CardAcquisitionCostsEvolution;
