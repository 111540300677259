import React, { useEffect, useState } from 'react';

import { MdKeyboardArrowRight, MdRefresh } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

import { Box, Breadcrumb, BreadcrumbItem, Flex, IconButton, Image, Text, Tooltip } from '@chakra-ui/react';

import overview from '../../assets/images/overview-icon.svg';
import { headerLinks } from '../Header/HeaderLinks';
import ScreenshotButton from '../ScreenshotButton/ScreenshotButton';

import CalendarFilter from './components/CalendarFilter/CalendarFilter';
import Filter from './components/Filter';
import SearchFilter from './components/SearchFilter';

const Menubar = ({
  placeholder = 'Pesquisar por texto',
  title = 'Carregando ...',
  detailsTitle,
  linkTree = [],
  filterOptions = [],
  filterSelectInfo,
  hideAllFilters = false,
  showPrefferedFilters = false,
  showPeriodFilter = true,
  searchPeriod = null,
  onChangePeriod,
  showTextFilter = true,
  allowFiltersOverride = false,
  shouldIgnoreFilters,
  showFilters = true,
  onChangeFilters,
  showRefreshData = false,
  triggerRefreshData,
  isRefreshLoading,
  refreshSeconds = 90,
  onChangeshouldIgnoreFilters,
  onChangeTextFilter,
  screen = 'none',
  showScreenshot,
  refScreen,
  ScreenShotIsDisabled,
}) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [showPeriodFilterByScreenSize, setShowPeriodFilterByScreenSize] = useState(true);
  const [showTextFilterByScreenSize, setShowTextFilterByScreenSize] = useState(true);

  const minScreenSizeForSearchFilter = 1060;
  const minScreenSizeForPeriodFilter = 750;

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  function getIconAndTitle() {
    const location = useLocation();
    const identifier = detailsTitle;

    for (const menu of headerLinks) {
      if (location.pathname === menu.link) {
        document.title = menu.title + ' - Vendemmia Analytics';
        return menu.icon;
      }

      if (menu.subMenu && menu.subMenu.length > 0) {
        for (const item of menu.subMenu) {
          if (location.pathname === item.link) {
            document.title = item.title + ' - Vendemmia Analytics';
            return item.icon;
          }
        }
      }
    }

    document.title = `Analytics Vendemmia ${identifier && `- ` + identifier}`;
    return overview;
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize && screenSize?.width) {
      setShowPeriodFilterByScreenSize(screenSize.width >= minScreenSizeForPeriodFilter);
      setShowTextFilterByScreenSize(screenSize.width >= minScreenSizeForSearchFilter);
    }
  }, [screenSize]);

  useEffect(() => {
    if (!showRefreshData || !refreshSeconds || refreshSeconds <= 0) {
      return;
    }

    const refreshTimer = setInterval(() => {
      if (!isRefreshLoading) {
        triggerRefreshData();
      }
    }, refreshSeconds * 1000); // Timeout in seconds converted to milliseconds

    return () => clearTimeout(refreshTimer);
  }, []);

  return (
    <Flex
      bgColor="#FFFFFF"
      w="full"
      h="60px"
      px="10px"
      py="17px"
      border="1px"
      borderColor="#70707030"
      shadow="lg"
      justify="space-between"
      position="fixed"
      top="63px"
      zIndex="999"
      mb="20px">
      <Flex direction="row" align="center" gap={{ base: '15px', md: '20px' }}>
        <Image src={getIconAndTitle()} alt="overview" display={{ base: 'none', md: 'block' }} maxWidth={'45px'} />

        <Breadcrumb
          spacing="4px"
          separator={
            <Box>
              <MdKeyboardArrowRight color="gray.500" />
            </Box>
          }>
          {linkTree.map((item, key) => (
            <BreadcrumbItem key={key}>
              <Link to={item.link}>
                <Text
                  textStyle="tableTitle"
                  style={{
                    cursor: item.link !== '#' ? 'pointer' : 'default',
                  }}
                  className="rl05-anim"
                  opacity=".5"
                  color="primary"
                  fontWeight="bold"
                  fontSize={{ base: '16px', md: '20px' }}
                  lineHeight={{ base: '16px', md: '20px' }}>
                  {item.title}
                </Text>
              </Link>
            </BreadcrumbItem>
          ))}

          <BreadcrumbItem isCurrentPage>
            <Text
              textStyle="tableTitle"
              color="primary"
              fontWeight="bold"
              fontSize={{ base: '16px', md: '20px' }}
              lineHeight={{ base: '16px', md: '20px' }}>
              {title}
            </Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Flex>
        <Flex direction="row" align="center" gap="10px">
          {!hideAllFilters && (
            <>
              {showPrefferedFilters && <Image src={addfilter} alt="filter" cursor="pointer" onClick={() => {}} />}

              {showPeriodFilter && showPeriodFilterByScreenSize && (
                <CalendarFilter screen={screen} onChange={onChangePeriod} defaultPeriod={searchPeriod} />
              )}

              {showTextFilter && showTextFilterByScreenSize && (
                <SearchFilter
                  screen={screen}
                  placeholder={placeholder}
                  allowFiltersOverride={allowFiltersOverride}
                  shouldIgnoreFilters={shouldIgnoreFilters}
                  setShouldIgnoreFilters={onChangeshouldIgnoreFilters}
                  onChange={onChangeTextFilter}
                />
              )}

              {showFilters && (
                <Filter
                  screen={screen}
                  filterOptions={filterOptions}
                  selectInfos={filterSelectInfo}
                  onChange={onChangeFilters}
                  showPeriodFilter={screenSize.width < minScreenSizeForPeriodFilter}
                  showTextFilter={screenSize.width < minScreenSizeForSearchFilter}
                  calendarFilter={
                    showPeriodFilterByScreenSize ? null : (
                      <CalendarFilter
                        screen={screen}
                        onChange={onChangePeriod}
                        defaultPeriod={searchPeriod}
                        isInsideExtraFilter={!showPeriodFilterByScreenSize}
                      />
                    )
                  }
                  searchFilter={
                    showTextFilterByScreenSize ? null : (
                      <SearchFilter
                        screen={screen}
                        placeholder={placeholder}
                        allowFiltersOverride={allowFiltersOverride}
                        shouldIgnoreFilters={shouldIgnoreFilters}
                        isInsideExtraFilter={!showTextFilterByScreenSize}
                        setShouldIgnoreFilters={onChangeshouldIgnoreFilters}
                        onChange={onChangeTextFilter}
                      />
                    )
                  }
                />
              )}
            </>
          )}

          {showRefreshData && (
            <Tooltip label={isRefreshLoading === true ? 'Atualizando' : 'Atualizar os dados'}>
              <IconButton
                icon={<MdRefresh size={25} color="#422C76" cursor="pointer" />}
                bgColor="#E8EAED"
                w="44px"
                h="35px"
                p="10px"
                borderRadius="10px"
                borderColor="#70707026"
                isLoading={isRefreshLoading}
                onClick={() => triggerRefreshData()}
              />
            </Tooltip>
          )}

          {showScreenshot && (
            <ScreenshotButton
              refElement={refScreen}
              label="Capturar uma imagem desta tela"
              bgColor="#E8EAED"
              w="44px"
              h="35px"
              p="10px"
              borderRadius="10px"
              borderColor="#70707026"
              isDisabled={ScreenShotIsDisabled}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Menubar;
