import React, { useEffect, useState } from 'react';

import { MdOutlineRecordVoiceOver } from 'react-icons/md';

import { Flex, Grid, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const TableRequestRole = ({ searchPeriod }) => {

  const resolution = window.innerWidth

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setIsLoading(true);
    try {
      const response = await requests.requestByRole({ period: searchPeriod });

      setList(response);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
      loadList();
    }
  }, [searchPeriod]);

  return (
    <Flex direction="column" gap="30px">
      <Grid 
        alignItems="center" 
        gridTemplateColumns={"30px 1fr"} 
        justifyItems={'start'}
        gap="20px"
      >
        <MdOutlineRecordVoiceOver size={30} color="#6C48C2" />
        <Text textStyle="secondaryTitle" color="#6C48C2" fontSize={{base:"24px", md:"30px" }}>
          Ranking de requisições por função
        </Text>
      </Grid>

      <ScreenLoader isLoading={isLoading}>
        <Table className="table-default">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              {resolution > 768 ? (
                <>
                  <Th>Usuário</Th>
                  <Th>Quantidade de requisições</Th>
                  <Th>Porcentagem do total</Th>
                </>
              ) : (
                <>
                  <Th>Usuário</Th>
                  <Th>Qtd. de requisições</Th>
                  <Th>% do total</Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody border="1px">
            {list.length > 0 ? (
              <>
                {list?.map((entity, key) => (
                  <Tr key={key}>
                    <Td>{entity.role}</Td>
                    <Td>{entity.qtt}</Td>
                    <Td>1</Td>
                  </Tr>
                ))}
              </>
            ) : (
              <Tr>
                <Td colSpan={3}>
                  <Flex justify="center" my="25px">
                    Não há resultados que correspondam aos filtros selecionados.
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </ScreenLoader>
    </Flex>
  );
};

export default TableRequestRole;
