import React, { useEffect, useState } from 'react';

import { Bar, CartesianGrid, ComposedChart, LabelList, XAxis, YAxis } from 'recharts';

import { Flex } from '@chakra-ui/react';

const ShipmentAnalysisChart = ({ data }) => {
  const windowWidth = window.innerWidth;
  let chartWidth = windowWidth < 768 ? 280 : 350;

  const [entity, setEntity] = useState([]);

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  useEffect(() => {
    const formatValue = data.average ? data.average.toLocaleString('pt-BR') : '';

    const fetchData = async () => {
      try {
        const chartData = [
          {
            average: data.average,
            color: '#422C76',
            formatValue: formatValue,
          },
        ];

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  // Função para customizar o conteúdo da label
  const customLabel = ({ x, y, width, value, index }) => {
    const currentEntity = entity[index];
    return (
      <g transform={`translate(${x + width / 2}, ${y + 105})`}>
        <text textAnchor="middle" fill={'white'} fontWeight="bold">
          {`Remessas:`}
        </text>
        <text
          x={0}
          y={20} // Ajuste a posição vertical conforme necessário
          textAnchor="middle"
          fill="white"
          fontWeight="bold">
          {value}
        </text>
      </g>
    );
  };

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="10px" direction={{ base: 'column', md: 'row' }}>
      <ComposedChart data={entity} width={chartWidth} height={250} margin={{ left: 60, bottom: -10 }}>
        <CartesianGrid vertical={false} />
        <XAxis tick={false} />
        <YAxis tickFormatter={tickFormatter} />
        <Bar dataKey="average" barSize={155} fill={entity.length > 0 ? entity[0].color : null}>
          {/* Usando a função customLabel para formatar a label */}
          <LabelList dataKey="formatValue" content={customLabel} />
        </Bar>
      </ComposedChart>
    </Flex>
  );
};

export default ShipmentAnalysisChart;
