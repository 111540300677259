import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import comment from '../../../../assets/images/comment.svg';
import gestao from '../../../../assets/images/gestao-ball.png';
import notification from '../../../../assets/images/notification.svg';
import manual from '../../../../assets/images/proprio-ball.png';
import api from '../../../../assets/images/vendemmia-ball.png';
import permissions from '../../../../services/permissions';

import ChannelFlag from './ChannelFlag';
import FavoriteProcess from './FavoriteProcess';
import HideProcess from './HideProcess';
import { ProcessCarbonTag } from './ProcessCarbonTag';
import ProcessCommentsDrawer from './ProcessCommentsDrawer';
import ProcessInfoTag from './ProcessInfoTag';
import ProcessUpdatesDrawer from './ProcessUpdatesDrawer';

const ProcessInfo = ({ process, forceLoadTrigger, processPriority, setForceLoadTrigger }) => {
  const canHideProcess = permissions.canHideProcess;
  let navigate = useNavigate();
  const getIcon = () => {
    switch (process.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  return (
    <Flex align="center" justify="space-between" p={{ base: '0', md: '0 5px' }} gap="10px">
      <Flex align="center" gap={{ sm: '10px', md: '25px' }} justifyContent={'space-between'} w={'100%'}>
        <Flex align="center" className="up-anim" justifyContent={{ base: 'space-between', md: 'inherit' }} gap="10px">
          <Image src={getIcon()} borderRadius="100%" w="30px" h="30px" />

          <Link borderRadius="5px" to={`/comex/process/detail/${process.identifier}`}>
            <Text textStyle="tableTitle" fontWeight="bold" textColor="#422C76" _hover={{ opacity: '0.8' }}>
              {process.code}
            </Text>
          </Link>

          {process.clientInternalCode && (
            <ProcessInfoTag key={process.clientInternalCode}>ref. {process.clientInternalCode}</ProcessInfoTag>
          )}

          <ChannelFlag color={process.channel} />

          <Flex align="center" opacity={process.qttRecentUpdates == 0 ? '.1' : '1'} gap="10px">
            <ProcessUpdatesDrawer process={process} notification={notification} />

            <ProcessCommentsDrawer process={process} comment={comment} />

            <FavoriteProcess process={process} processPriority={processPriority} />

            {canHideProcess && (
              <HideProcess process={process} forceLoadTrigger={forceLoadTrigger} setForceLoadTrigger={setForceLoadTrigger} />
            )}
          </Flex>
        </Flex>

        <Flex gap="10px" flexWrap="wrap">
          <ProcessCarbonTag process={process} />

          {/* {process.clientInternalCode && (
            <ProcessInfoTag key={process.clientInternalCode}>{process.clientInternalCode}</ProcessInfoTag>
          )} */}

          {process.currentStageModality && (
            <ProcessInfoTag key={process.currentStageModality}>{process.currentStageModality}</ProcessInfoTag>
          )}

          {process.returnOrigin
            ? process.originDescription && (
                <ProcessInfoTag key={process.originDescription}>
                  {process.originDescription?.toLowerCase() === 'gestao' ? 'Gestão' : 'Própria'}
                </ProcessInfoTag>
              )
            : process.modality && <ProcessInfoTag key={process.modality}>{process.modality}</ProcessInfoTag>}
        </Flex>
      </Flex>

      <Tooltip label="Visualizar detalhes do processo">
        <Box align="center">
          <Link to={`/comex/process/detail/${process.identifier}`} target="_blank">
            <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
          </Link>
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default ProcessInfo;
