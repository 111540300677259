import React from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex } from '@chakra-ui/react';

const formatMonthYear = (date) => {
  if (date && typeof date === 'string') {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      return format(parsedDate, 'dd/MMM', { locale: ptBR });
    }
  }
  return '';
};

const tickFormatter = (tickItem) => {
  return tickItem.toLocaleString('pt-BR');
};

const valueFormat = (valor) => {
  return valor.toLocaleString('pt-BR');
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
        {payload.map((entity, key) => (
          <React.Fragment key={key}>
            <Flex direction="row" align="center">
              {entity.dataKey === 'qttDispatched' ? 'Expedidos: ' : 'Cancelados: '}
              {valueFormat(entity.value)}
            </Flex>
          </React.Fragment>
        ))}
      </Flex>
    );
  }
  return null;
};

const WerehouseDispatchedChart = ({ entity }) => {
  const formattedEntity = entity?.map((item) => ({
    ...item,
    dispatchedAt: formatMonthYear(item.dispatchedAt),
  }));

  return (
    <ResponsiveContainer height={440}>
      <LineChart
        data={formattedEntity}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dispatchedAt" tickMargin={5} />
        <YAxis yAxisId="left" orientation="left" stroke="#422C76" tickFormatter={tickFormatter} tickMargin={5} />
        <YAxis yAxisId="right" orientation="right" stroke="#E52359" tickFormatter={tickFormatter} tickMargin={5} />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" />
        <Line type="monotone" dataKey="qttDispatched" name="Expedidos" yAxisId="left" stroke="#422C76" strokeWidth={2} />
        <Line type="monotone" dataKey="qttCancelled" name="Cancelados" yAxisId="right" stroke="#E52359" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default WerehouseDispatchedChart;
