import React, { useEffect, useState } from 'react';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';

const VehiclesTable = ({ user, hideButtons = false }) => {
  const [vehicles, setVehicles] = useState([]);
  const [load, setLoad] = useState(false);

  const listVehicles = () => {
    setLoad(true);
    setVehicles(user?.vehicles);
    setLoad(false);
  };

  useEffect(() => {
    listVehicles();
  }, [user]);

  return (
    <ScreenLoader isLoading={load}>
      <Box overflowY="scroll" h="calc(80vh - 120px)">
        <Table className="table-default min">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              <Th>Placa</Th>
              <Th>Modelo</Th>
              <Th>Cor</Th>
              <Th>Ano fab.</Th>
              <Th>Tipo carreta</Th>
              <Th>Capacidade</Th>
              <Th>
                Veículo
                <br /> próprio
              </Th>
              {/*<Th>CNPJ</Th>*/}
            </Tr>
          </Thead>

          <Tbody border="1px">
            {!load &&
              vehicles?.length > 0 &&
              vehicles?.map(function (vehicle, key) {
                return (
                  <React.Fragment key={key}>
                    <Tr style={{ '--delay': `0.${key}s` }}>
                      <Td>{vehicle?.licensePlate}</Td>
                      <Td>{vehicle?.model}</Td>
                      <Td>{vehicle?.color}</Td>
                      <Td>{vehicle?.yearManufacture != 0 ? vehicle?.yearManufacture : ''}</Td>
                      <Td>{vehicle?.typeCar}</Td>
                      <Td>{vehicle?.capacity}</Td>
                      <Td>{vehicle?.ownership == 'PROPRIO' ? 'SIM' : 'NÃO'}</Td>
                      {/*<Td>41.743.982/0001-60</Td>*/}
                    </Tr>
                  </React.Fragment>
                );
              })}
            {!load && vehicles?.length == 0 && (
              <Tr>
                <Td colSpan={9}>
                  <Flex justify="center" my="25px">
                    Nenhum dado encontrado para os parâmetros filtrados.
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </ScreenLoader>
  );
};

export default VehiclesTable;
