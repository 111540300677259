import React from 'react';

import { Text } from '@chakra-ui/react';

const UpdateInfoTag = ({ children, getColor, fontSize, width = "full" }) => {
  return (
    <Text
      w={width}
      p="3px 7px"
      border={getColor ?? 'primary'}
      fontSize={fontSize ?? "12px" }
      borderRadius="7px"
      textAlign="center"
      bg={getColor ?? 'primary'}
      color="white">
      {children}
    </Text>
  );
};

export default UpdateInfoTag;
