import { Accordion, AccordionItem, Grid, Text } from '@chakra-ui/react';

import CalendarMonthColumn from './CalendarMonthColumn';

const CalendarMonthSection = ({ calendarData, modality = 'event', isMobile = false, setIsOpenModalItem, setCurrentItem }) => {
  return (
    <>
      {!isMobile ? (
        <Grid gridTemplateColumns={'repeat(7, 1fr)'} alignItems={'start'} className="borderCalendar">
          {calendarData &&
            calendarData.map((day, dayKey) => {
              return (
                dayKey < 7 && (
                  <Grid key={dayKey}>
                    <Text bg={'#6794DC'} p="15px" fontSize="22px" w="100%" color="white" textAlign="center">
                      {day.weekdayName}
                    </Text>
                  </Grid>
                )
              );
            })}

          {calendarData &&
            calendarData.map((day, dayKey) => {
              return (
                <Grid key={dayKey} bt="0" h="full">
                  <CalendarMonthColumn
                    day={day}
                    key={dayKey}
                    delay={dayKey}
                    modality={modality}
                    isMobile={isMobile}
                    setIsOpenModalItem={setIsOpenModalItem}
                    setCurrentItem={setCurrentItem}
                  />
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <Accordion display={{ base: 'flex', md: 'none' }} flexDirection="column" w="100%" allowToggle>
          {calendarData &&
            calendarData.map((day, dayKey) => {
              return (
                <AccordionItem
                  key={dayKey}
                  /* border={'0.25px solid #70707033'} */
                  bt="0"
                  h="full">
                  <CalendarMonthColumn
                    day={day}
                    key={dayKey}
                    delay={dayKey}
                    modality={modality}
                    isMobile={isMobile}
                    setIsOpenModalItem={setIsOpenModalItem}
                    setCurrentItem={setCurrentItem}
                  />
                </AccordionItem>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

export default CalendarMonthSection;
