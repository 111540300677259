import React, { useEffect, useState } from 'react';

import { Flex, Table, Tbody, Text, Thead, Tr } from '@chakra-ui/react';

import Card from '../../../../../components/Card/Card';
import Paginate from '../../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import useResponsiveCheck from '../../../../../hooks/useResponsiveCheck';
import requests from '../../../../../services/requests';
import TravelManagementPanelTabs from '../TravelManagementPanelTabs';

import TableListCte from './TableListCte';

const TravelCte = ({ tab, filterSelected, setTab }) => {
  const responsive = useResponsiveCheck();
  const viewport = responsive.viewport;

  const [load, setLoad] = useState(true);

  const [list, setList] = useState([]);

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const listCtes = (filterSelected) => {
    setLoad(true);

    requests
      .listCtes(filterSelected, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        setList(data?.data);
        setMetadata(data?.meta);

        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    listCtes(filterSelected);
  }, [filterSelected, action]);

  return (
    <Card
      m="10px"
      h="calc(100vh - 200px)"
      header={
        <Flex
          w="full"
          h={viewport < 900 ? '120px' : '65px'}
          direction={viewport < 900 ? 'column-reverse' : 'row'}
          justify="space-between"
          align="center">
          <Flex gap="2px" direction="column" align={viewport < 900 ? 'center' : 'normal'}>
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              {"CT-e's"} em trânsito
            </Text>

            <Text textStyle="subtitle" color="#BEBEBE" fontSize={{ base: '14px', md: '16px' }}>
              Existe um total de {metadata?.item_count} {"CT-e's"} cadastrados
            </Text>
          </Flex>

          <TravelManagementPanelTabs tab={tab} setTab={setTab} />
        </Flex>
      }>
      <ScreenLoader isLoading={load} overflowY="auto">
        <Table w="full">
          <Thead>
            <Tr></Tr>
          </Thead>

          <Tbody>
            {list?.map((entity, key) => {
              return <TableListCte tab={tab} entity={entity} setLoad={setLoad} key={key} />;
            })}
          </Tbody>
        </Table>

        {!load && list?.length === 0 && (
          <Flex justify="center" my="25px">
            Nenhum dado encontrado para os parâmetros filtrados.
          </Flex>
        )}
      </ScreenLoader>

      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </Card>
  );
};

export default TravelCte;
