import React from 'react';

import { MdTune } from 'react-icons/md';

import { Box, Button, Checkbox, Flex, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';

const FilterComments = ({
  handleSelectStage,
  handleOrderChange,
  selectedStage,
  selectedOrder,
  isOpen,
  onClose,
  onOpen,
}) => {
  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton bgColor="primary" onClick={onOpen} borderRadius="10px" w="50px" h="45px" _hover={{ bg: 'linkColor' }}>
        <Flex justify="center">
          <MdTune size={24} color="#FFFFFFB3" />
        </Flex>
      </MenuButton>

      <MenuList w="270px" shadow="lg" borderRadius="5px" p="0">
        <Flex maxH="60vh">
          <Flex gap="30px" w="100%" direction="column" py="27px" px="25px">
            <Flex direction="column" gap="10px" maxH="calc(60vh - 100px)" overflowY="auto">
              <Flex direction="column" gap="5px" ml="5px">
                <Text textColor="#0F0A1D" opacity="0.5">
                  Etapa do processo
                </Text>

                <Checkbox value="0" iconColor="primary" onChange={handleSelectStage} isChecked={selectedStage === '0'}>
                  Pré embarque
                </Checkbox>
                <Checkbox value="1" iconColor="primary" onChange={handleSelectStage} isChecked={selectedStage === '1'}>
                  Trânsito
                </Checkbox>
                <Checkbox value="2" iconColor="primary" onChange={handleSelectStage} isChecked={selectedStage === '2'}>
                  Desembaraço
                </Checkbox>
                <Checkbox value="3" iconColor="primary" onChange={handleSelectStage} isChecked={selectedStage === '3'}>
                  Faturamento
                </Checkbox>
                <Checkbox value="4" iconColor="primary" onChange={handleSelectStage} isChecked={selectedStage === '4'}>
                  Transporte
                </Checkbox>
                <Checkbox value="5" iconColor="primary" onChange={handleSelectStage} isChecked={selectedStage === '5'}>
                  Entrega
                </Checkbox>

                <Text textColor="#0F0A1D" opacity="0.5" mt="10px">
                  Ordenar visualização
                </Text>

                <Checkbox
                  value="first"
                  iconColor="primary"
                  onChange={handleOrderChange}
                  isChecked={selectedOrder === 'first'}>
                  Mais recente
                </Checkbox>

                <Checkbox
                  value="last"
                  iconColor="primary"
                  onChange={handleOrderChange}
                  isChecked={selectedOrder === 'last'}>
                  Mais antigo
                </Checkbox>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Box m="0" p="0">
          <Button
            variant="solid"
            borderRadius="0"
            w="full"
            bgColor="primary"
            color="#FFFFFF"
            _hover={{ bg: 'linkColor' }}>
            Aplicar
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default FilterComments;
