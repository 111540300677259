import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const primary = definePartsStyle({
  field: {
    height: '48px',
    color: 'primary',
    fontSize: '16px',
    fontWeight: 'medium',
    background: '#FBFBFB',
    border: '1px',
    borderColor: '#7070702B',
    borderRadius: '17px',
    shadow: 'lg',
    cursor: 'pointer',
    _focus: {
      borderColor: '#707070B5',
    },
  },
  icon: {
    color: '#707070B5',
  },
});

export const selectStyles = defineMultiStyleConfig({ variants: { primary } });
