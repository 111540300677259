import React, { useEffect, useRef, useState } from 'react';

import { MdOutlineModeEditOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsManagementUser } from '../../../utils/filters/filterPresets';
import { getFilter } from '../../../utils/storageFilter';

import { commonListConfig } from './components/commonListConfig';
import ModalManagementUser from './components/ModalManagementUser';
import roleOptions from './Helpers/roleOptions';
import statusOptions from './Helpers/statusOptions';

const UserManagementPage = () => {
  const request = useRef(0);

  const hasPermission = permissions.vendemmiaManagementUserManagement;
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);

  const [identifier, setIdentifier] = useState();

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [filterOptions, setFilterOptions] = useState([]);

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure();

  const [fields, setFields] = useState({
    role: {
      slug: '',
      value: '',
      label: '',
    },
    status: {
      label: '',
      value: '',
    },
  });

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsManagementUser());
  };

  const handleCreateModalClose = () => {
    onCreateModalClose();
    triggerRefresh();
  };

  const handleEditModalClose = () => {
    onEditModalClose();
    load();
  };

  const handleOpenEditModal = (identifier) => {
    onEditModalOpen();
    setIdentifier(identifier);
  };

  const handleOpenCreateModal = () => {
    onCreateModalOpen();
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests.listUsers(filters, page).then((data) => {
      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const exportListAction = (filters, key) => {
    //preset
    const endpoint = `/adm/user/export-csv`;
    const fileName = `users`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  //actions
  const handleClickChangeRole = async (identifier, fields) => {
    try {
      const res = await requests.editUserRole(identifier, { role: fields.value });

      toast.success(`Perfil do usuário ${res.name} alterado para ${roleOptions.find((e) => e.value === res?.role)?.label}`);
    } catch (error) {
      console.error('Problemas ao alterar perfil do usuário, tente novamente ou entre em contato com o Administrador', error);
    }
  };

  const handleClickChangeStatus = async (identifier, fields) => {
    try {
      const res = await requests.deleteUser(identifier, { statusUser: fields.value });

      toast.success(`Status do usuário ${res.name} alterado para ${statusOptions.find((e) => e.value === res?.status)?.label}`);
    } catch (error) {
      console.error('Problemas ao alterar status do usuário, tente novamente ou entre em contato com o Administrador', error);
    }
  };
  //actions

  useEffect(() => {
    getFilterOptions();
  }, []);

  //component preset
  const subtitle = `Existe um total de ${metadata.total_count} usuários`;

  const customFields = [
    { type: 'select', title: 'Perfil', options: roleOptions, name: 'role', action: handleClickChangeRole },
    { type: 'select', title: 'Status', options: statusOptions, name: 'status', action: handleClickChangeStatus },
  ];

  const customButtons = [
    {
      main: [
        { label: 'Prévia', tooltip: 'Visualizar contrato', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenEditModal },
      ],
      collapsed: [],
    },
  ];
  //component preset

  return (
    <Page
      screen="user"
      title="Gestão de usuários"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Nome ou email"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar usuário',
          text: 'Cadastro de usuário',
          action:
            (handleOpenCreateModal,
            (
              /* create */
              <ModalManagementUser
                loadList={load}
                filter={getFilter}
                fields={fields}
                setFields={setFields}
                isEditMode={false}
                handleClose={handleCreateModalClose}
                handleOpen={onCreateModalOpen}
                isOpen={isCreateModalOpen}
              />
            )),
          modality: 'custom',
        },
        {
          title: 'Exportar usuários',
          text: 'Exportar arquivo CSV com dados de usuários',
          action: exportListAction,
          modality: 'export-csv',
        },
      ]}>
      {/* edit */}
      <ModalManagementUser
        loadList={load}
        filter={getFilter}
        fields={fields}
        setFields={setFields}
        isEditMode={true}
        handleClose={handleEditModalClose}
        handleOpen={onEditModalOpen}
        isOpen={isEditModalOpen}
        identifier={identifier}
      />

      <CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //custom
        customFields={customFields}
        customButtons={customButtons}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />
    </Page>
  );
};

export default UserManagementPage;
