export const convertCoordinates = (location) => {
  if (!location) {
    return {
      title: '',
      lat: null,
      lng: null,
      departureAt: '',
    };
  }

  const { latitude, longitude, title = '', departureAt = '' } = location;

  return {
    title,
    lat: latitude,
    lng: longitude,
    departureAt,
  };
};
