import React, { useContext } from 'react';

import { useFormikContext } from 'formik';

import { Button } from '@chakra-ui/react';

import { DynamicFormContext } from './DynamicForm';

export const DynamicFormButton = ({ ...props }) => {
  // Usando useFormikContext para acessar o contexto do formulário
  const formikContext = useFormikContext();
  const dynamicFormContext = useContext(DynamicFormContext);
  const callbackFormik = dynamicFormContext.config.callback;

  // Propriedades adicionais do contexto, caso necessário
  const {
    name = 'Enviar',
    loadingText = 'Enviando',
    isLoading = false,
    callback,
  } = {
    ...dynamicFormContext,
    ...props,
  };

  if (!dynamicFormContext || !callbackFormik) {
    console.error('Formik context is not available.');
    return null;
  }

  const { values, isSubmitting, errors } = formikContext;

  return (
    <Button
      h="47px"
      w={'fit-content'}
      alignSelf={'end'}
      variant="submit"
      onClick={() => {
        callback ? callback : callbackFormik(values);
        /* submitForm(); */
      }}
      isLoading={isLoading}
      loadingText={loadingText}
      disabled={isSubmitting || Object.keys(errors).length > 0}
      {...props}>
      {name}
    </Button>
  );
};
