import React from 'react';

import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

import FilterOption from '../../../../utils/filters/FilterOption';
import { appendFilter } from '../../../../utils/storageFilter';

const PieChartMiddle = ({ text, sizeWidth, entity, filtersCharts = {}, isPreview = false }) => {
  const filteredData = entity?.filter((item) => item.value !== 0);

  const total = () => {
    let totalValue = 0;

    for (let index = 0; index < entity?.length; index++) {
      totalValue += entity[index].value;
    }
    return totalValue;
  };

  const handleClickStage = (entry) => {
    const selectedData = {};
    const index = filtersCharts.findIndex((obj) => obj.label === entry.name);

    if (filtersCharts[index] && typeof filtersCharts[index].selected === 'boolean') {
      filtersCharts[index].selected = !filtersCharts[index].selected;
    }

    filtersCharts.forEach((item) => {
      if (!(item instanceof FilterOption)) {
        return;
      }

      let selectedChildren = item.getFullSelectedChildrenValue();

      if (selectedChildren.length > 0) {
        selectedData[item.value] = selectedChildren;
      } else {
        selectedData[item.value] = null;
      }
    });

    appendFilter('dashboard', selectedData);

    const apllyFilters = document.querySelector('[data-aplly-filter]');
    apllyFilters && apllyFilters.click();
  };

  return (
    <Flex pos="relative" justify="center" w={sizeWidth} h={{ base: '160px', md: '200px' }}>
      <Box zIndex="2" w={sizeWidth} h={{ base: '160px', md: '200px' }}>
        <ResponsiveContainer>
          <PieChart margin={{ top: -180 }}>
            <Pie
              cy="100%"
              outerRadius="100%"
              stroke="none"
              dataKey="value"
              data={filteredData}
              endAngle={0}
              startAngle={180}
              innerRadius={120}
              minAngle={8}
              paddingAngle={1}>
              {filteredData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                  cursor={isPreview ? 'arrow' : 'pointer'}
                  onClick={() => {
                    if (isPreview) {
                      null;
                    } else {
                      handleClickStage(entry);
                    }
                  }}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Flex direction="column" pos="absolute" bottom="0" align="center" zIndex="1">
        <Text textStyle="primaryTitle" textColor="#0F0A1D" fontWeight="bold">
          {total().toLocaleString()}
        </Text>

        <Text textStyle="tableTitle" textColor="#0F0A1D93">
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PieChartMiddle;
