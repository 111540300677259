import moment from 'moment/moment';

import { cleanPath } from './cleanPath';
import { cleanPathDuplicateAndNull } from './cleanPathDuplicateAndNull';
import { registerCoordenatedPath } from './registerCoordenatedPath';

export const buildPath = async (information) => {
  // API data
  const { origin, destination, currentPosition, voyages } = information || {};

  const markerPast = [];
  const markerCurrent = [];
  const markerFuture = [];

  const markerDone = [];
  const pastPath = [];
  const currentPath = [];
  const futurePath = [];

  const positions = [];

  // conditions
  const voyagesExists = voyages && voyages?.length > 0;
  const hasDestinationArrived = destination?.arrivedAt;

  // informational messages
  const originTitle = `<strong>Origem: ${origin?.title}</strong>`;
  const originDescription = '<strong>Partida: </strong>' + origin?.departureAt;
  const originMarker = 'origin';

  const currentTitle = '<strong>Posição Atual: </strong>';
  const currentDescription = '-';

  const destinationTitle = `<strong>Destino: ${destination?.title}</strong>`;
  const isFutureDate = moment(destination?.arrivedAt, 'DD/MM/YYYY').isAfter(moment());

  const destinationDescription = !isFutureDate
    ? '<strong>Chegada: </strong>' + (destination?.arrivedAt ?? 'Não entregue')
    : '<strong>Chegada:</strong> Não entregue';
  const destinationMarker = 'destination';

  const lastVoyagePositions = voyagesExists ? voyages[voyages.length - 1] : null;

  //registrar posição atual sem formatação
  registerCoordenatedPath(currentPosition, markerCurrent, currentTitle, currentDescription);

  // Adiciona o ponto de origem
  if (origin) {
    if (!voyagesExists && !hasDestinationArrived) {
      // current Line
      registerCoordenatedPath(origin, currentPath, originTitle, originDescription);
      // future Line
      registerCoordenatedPath(origin, futurePath, originTitle, originDescription);
      // future Marker
      registerCoordenatedPath(origin, markerFuture, originTitle, originDescription, originMarker);
    } else {
      // past Line
      registerCoordenatedPath(origin, pastPath, originTitle, originDescription);
      // past Marker
      registerCoordenatedPath(origin, markerPast, originTitle, originDescription, originMarker);
    }
  }

  // Verifica se existem voyages
  if (voyagesExists) {
    voyages.forEach(
      ({ status, vessel, positions: voyagePositions, isVisible, origin: voyageOrigin, destination: voyageDestination }, index) => {
        function formatDate(dateString) {
          return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
        }

        const positionCurrentPath = [];

        // Information about the voyage
        const voyagesTitle = `
        <strong>${index + 1}º Parada: ${voyageDestination.title} <br />
          <span style="font-size:13px; color:#422C76; font-weight: 500; ">
            (Navio: ${vessel.name})
          </span>
        </strong>`;

        const voyagesDescription = `
        <span style="font-size:10px; font-weight: 500; ">
          De ${voyageOrigin.title} para ${voyageDestination.title}
        </span>`;

        // Iterate over each position in the voyagePositions array
        if (voyagePositions) {
          const cleanedPath = cleanPathDuplicateAndNull(voyagePositions);
          cleanedPath.forEach((position, _positionIndex) => {
            const voyagesMarkerTitle = `
            <strong>Navio: </strong><span style="font-size:12px">${vessel.name}</span>
          `;

            const voyagesMarkerDescription = `
            <strong>Origem: </strong><span style="font-size:12px">${voyageOrigin.title}</span></br>
            <strong>Destino: </strong><span style="font-size:12px">${voyageDestination.title}</span></br>
            <strong>Latitude: </strong><span style="font-size:12px">${position.latitude}</span></br>
            <strong>Longitude: </strong><span style="font-size:12px">${position.longitude}</span></br>
            <strong>Data: </strong><span style="font-size:12px">${formatDate(position.date)}</span></br>
          `;

            registerCoordenatedPath(position, markerDone, voyagesMarkerTitle, voyagesMarkerDescription);
            registerCoordenatedPath(position, positions, voyagesMarkerTitle, voyagesMarkerDescription);
            registerCoordenatedPath(position, positionCurrentPath, voyagesMarkerTitle, voyagesMarkerDescription);
          });

          const lastposition = cleanedPath[cleanedPath.length - 1];
          registerCoordenatedPath(lastposition, positionCurrentPath, currentTitle, currentDescription);
        }

        if (status === 'Concluído') {
          // past Marker
          isVisible && registerCoordenatedPath(voyageDestination, markerPast, voyagesTitle, voyagesDescription);
          // past Line
          registerCoordenatedPath(voyageDestination, pastPath, voyagesTitle, voyagesDescription);
        } else if (status === 'Em andamento') {
          if (positionCurrentPath.length > 0) {
            // past Line
            registerCoordenatedPath(positionCurrentPath, pastPath, currentTitle, currentDescription);
            // current Line
            registerCoordenatedPath(positionCurrentPath, currentPath, currentTitle, currentDescription);
          } else {
            // current Line
            registerCoordenatedPath(voyageDestination, currentPath, currentTitle, currentDescription);
          }
        } else if (status === 'Pendente') {
          //marker
          isVisible && registerCoordenatedPath(voyageDestination, markerFuture, voyagesTitle, voyagesDescription);
          //points
          registerCoordenatedPath(voyageDestination, futurePath, voyagesTitle, voyagesDescription);
        }
      }
    );
  }

  // Adiciona o ponto de destino ao caminho principal se não houver voyages ou se não tiver sido adicionado a data de entrega
  if (destination) {
    if (!hasDestinationArrived) {
      // future Line
      registerCoordenatedPath(destination, futurePath, destinationTitle, destinationDescription);
      // future Marker
      registerCoordenatedPath(destination, markerFuture, destinationTitle, destinationDescription, destinationMarker);
    } else {
      // past Line
      registerCoordenatedPath(destination, pastPath, destinationTitle, destinationDescription);
      // past Marker
      registerCoordenatedPath(destination, markerPast, destinationTitle, destinationDescription, destinationMarker);
    }
  }

  return {
    origin,
    destination,
    currentPosition,
    positions,
    markerPast,
    markerCurrent,
    markerFuture,
    markerDone,
    pastPath: cleanPath(pastPath),
    currentPath: cleanPath(currentPath),
    futurePath: cleanPath(futurePath),
    registerCoordenatedPath,
    cleanPath,
  };
};
