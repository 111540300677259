import React from 'react';

import { Flex } from '@chakra-ui/react';

import OrderProgressBar from '../../../pages/metrics/MetricsWarehouse/components/OrderProgressBar';

const OrderProgressByStateChart = ({ data, filterOptions, isPreview, type }) => {
  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  function getColor(key) {
    let color = 'red';

    if (key === 0) {
      color = '#422C76';
    } else if (key === 1) {
      color = '#2EC5CC';
    } else if (key === 2) {
      color = '#2EA0CC';
    } else if (key === 3) {
      color = '#F1C40F';
    } else if (key === 4) {
      color = '#9B2ECC';
    } else if (key === 5) {
      color = '#CC2E96';
    }

    return color;
  }

  return (
    <Flex direction="column" px="10px" h="full" justify="center">
      <Flex direction="column" w="100%" gap="5px" h={'100%'} justifyContent={'space-evenly'}>
        {data?.byStateCodeValue?.length > 0 &&
          data.byStateCodeValue.map((item, key) => {
            let label = item.stateCode;
            if (label?.includes('Outros')) {
              label = 'Outros';
            }

            return (
              <OrderProgressBar
                isPreview={isPreview}
                filterOptions={filterOptions}
                tooltip={label === 'Outros' ? item.stateCode.replace('Outros: ', '') : ''}
                key={key}
                label={label}
                originalValue={item.totalValue}
                value={item.totalValue ? moneyFormat(item.totalValue) : 0}
                max={data.totalBilling ? data.totalBilling : 0}
                maxIsMoney={true}
                color={getColor(key)}
                type={type}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

export default OrderProgressByStateChart;
