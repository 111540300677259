export default function generateIconEvent(modality, color) {
  switch (modality) {
    case 'MOVING':
      return `<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.15 503.47">
          <defs>
            <style>
              .cls-1 {
                fill: ${color};
                stroke-width: 0px;
              }
            </style>
          </defs>
          <path class="cls-1" d="m391.07,190.12C388.06,82.15,298.09-2.93,190.12.08,85.65,3.9,2.28,88.52.02,193.04c-.65,42.88,12.99,84.76,38.78,119.02,3.61,5.33,96.14,134.13,126.99,176.83,6.61,9.1,17.15,14.51,28.39,14.58h.17c11.19-.01,21.71-5.33,28.36-14.33,14.3-19.47,125.93-172.12,128.9-175.78l.49-.65c25.16-33.89,38.83-74.95,39.01-117.16h.04c0-1.81-.03-3.62-.08-5.43Zm-148.83,5.22c-.15.05-.3.1-.44.16h-.01c-.28.13-.54.27-.79.44-.26.17-.49.36-.7.58-.11.11-.21.22-.31.33-.68.81-1.09,1.86-1.09,3.01v129.2c0,1.36-.34,2.64-.95,3.76-.37.67-.83,1.28-1.36,1.81-.27.28-.55.53-.86.74-.37.29-.78.54-1.2.75-.14.07-.29.14-.43.2-.95.4-1.98.62-3.07.62h-70.91c-4.35,0-7.88-3.53-7.88-7.88v-129.2c0-2.61-2.12-4.73-4.73-4.73h-29.35c-6.73,0-10.36-7.9-5.98-13.01l77.41-90.31c.2-.23.41-.45.63-.64.21-.2.44-.38.67-.56.23-.17.46-.32.7-.47h0c.37-.21.74-.39,1.13-.55h.01c.13-.05.26-.09.4-.14,1.58-.51,3.3-.51,4.88,0,1.33.43,2.56,1.22,3.54,2.37l77.41,90.31c.49.58.88,1.19,1.18,1.82.11.21.2.42.27.64,1.76,4.85-1.72,10.54-7.44,10.54h-29.35c-.49,0-.95.08-1.39.21Z"/>
        </svg>`;
    case 'PAUSED':
      return `<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.15 503.47">
          <defs>
            <style>
              .cls-1 {
                fill: ${color};
                stroke-width: 0px;
              }
            </style>
          </defs>
          <path class="cls-1" d="m391.07,190.12C388.06,82.15,298.09-2.93,190.12.08,85.65,3.9,2.28,88.52.02,193.04c-.65,42.88,12.99,84.76,38.78,119.02,3.61,5.33,96.14,134.13,126.99,176.83,6.61,9.1,17.15,14.51,28.39,14.58h.17c11.19-.01,21.71-5.33,28.36-14.33,14.3-19.47,125.93-172.12,128.9-175.78l.49-.65c25.16-33.89,38.83-74.95,39.01-117.16h.04c0-1.81-.03-3.62-.08-5.43Zm-68.66,45.76c0,8.76-7.1,15.86-15.86,15.86H84.61c-8.76,0-15.86-7.1-15.86-15.86h.03l-.04-.02v-47.56c0-8.76,7.1-15.86,15.86-15.86h221.94v.02s.01-.02.01-.02h.03c8.76.01,15.84,7.12,15.83,15.88v47.56Z"/>
        </svg>`;
    case 'COLETA':
      return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="138.291" viewBox="0 0 138.291 178">
          <defs>
            <clipPath id="clip-path">
              <rect id="Retângulo_1824" data-name="Retângulo 1824" width="62.592" height="62.6" fill="none"/>
            </clipPath>
          </defs>
          <g id="Grupo_4802" data-name="Grupo 4802" transform="translate(-9788 -1556)">
            <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(9788.002 1555.997)" fill="${color}"/>
            <g id="Grupo_4798" data-name="Grupo 4798" transform="matrix(0.875, 0.485, -0.485, 0.875, 9845.349, 1589)">
              <g id="Grupo_4797" data-name="Grupo 4797" transform="translate(0)" clip-path="url(#clip-path)">
                <path id="Caminho_2360" data-name="Caminho 2360" d="M20.881,119.91l4.887,1.815a1.079,1.079,0,0,0,1.317-1.317l-1.815-4.887a1.117,1.117,0,0,0-.294-.512L4.606,94.641,0,99.247l20.369,20.369a1.117,1.117,0,0,0,.512.294" transform="translate(35.472 -59.161)" fill="#fff"/>
                <path id="Caminho_2361" data-name="Caminho 2361" d="M67.451,54.967C64.214,45.59,50.558,31.932,50.558,31.932L64.38,18.11S78.038,31.77,87.415,35Z" transform="translate(-43.777 -11.321)" fill="#e7e5e8"/>
                <path id="Caminho_2362" data-name="Caminho 2362" d="M50.563,23.363A36.3,36.3,0,0,0,64.1,37.77a35.263,35.263,0,0,0,8.364,3.615L78.85,35c-9.378-3.227-23.031-16.89-23.031-16.89Z" transform="translate(-35.214 -11.319)" fill="#fff"/>
                <path id="Caminho_2363" data-name="Caminho 2363" d="M123.729.632,106.817,17.544a2.158,2.158,0,0,0,0,3.053l1.554,1.554a2.158,2.158,0,0,0,3.052,0L128.336,5.239a2.159,2.159,0,0,0,0-3.053L126.781.632a2.159,2.159,0,0,0-3.052,0" transform="translate(-106.185 0)" fill="#fff"/>
                <path id="Caminho_2364" data-name="Caminho 2364" d="M54.611,51.833,26.949,79.494a2.159,2.159,0,0,0,0,3.052L28.5,84.1a2.158,2.158,0,0,0,3.052,0L59.218,56.44a2.159,2.159,0,0,0,0-3.053l-1.555-1.554a2.159,2.159,0,0,0-3.052,0" transform="translate(-7.124 -32.006)" fill="#fff"/>
                <path id="Caminho_2365" data-name="Caminho 2365" d="M138.811,14.351,126.647,26.515a2.159,2.159,0,0,0,0,3.052l1.555,1.555a2.159,2.159,0,0,0,3.052,0l10.11-10.107s-2.288-4.907-2.553-6.664" transform="translate(-126.015 -8.971)" fill="#e7e5e8"/>
              </g>
            </g>
          </g>
        </svg>`;
    case 'ENTREGA':
      return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="138.291" viewBox="0 0 138.291 178">
          <defs>
            <clipPath id="clip-path">
              <rect id="Retângulo_1825" data-name="Retângulo 1825" width="69.343" height="73.867" fill="none"/>
            </clipPath>
          </defs>
          <g id="Grupo_4805" data-name="Grupo 4805" transform="translate(-9561 -1532)">
            <g id="Grupo_4801" data-name="Grupo 4801" transform="translate(-45 -24)">
              <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(9606.002 1555.997)" fill="${color}"/>
            </g>
            <g id="Grupo_4804" data-name="Grupo 4804" transform="translate(9595.475 1571.133)">
              <g id="Grupo_4803" data-name="Grupo 4803" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Caminho_2366" data-name="Caminho 2366" d="M71.563,17.166s-1.78,30.349-1.8,32.239c-.014,1.8-1.006,1.16-1.393.856a3.824,3.824,0,0,0-.485-.347c-4.236-2.548-11.074-2.25-15.683-1.55a31.453,31.453,0,0,0-7.057,1.974c-4.932,1.981-15.092,6.723-23.1,5.3-6.515-1.16-10.547-6.5-13.037-12.581,0,0-.021-.056-.063-.163-.668-1.727-5.959-16.686,8.914-24.7a20.526,20.526,0,0,0,6.951.821c.584-.014,1.161-.063,1.745-.127.387-.042.767-.085,1.154-.148a35.683,35.683,0,0,0,3.574-.715c1.78-.446,3.567-1.019,5.347-1.641s3.56-1.309,5.34-1.981c2.674-1.012,5.347-2.01,8.021-2.774.894-.255,1.787-.481,2.674-.679.239-.05.478-.092.718-.142.274-.056.549-.113.823-.163.232-.042.464-.078.7-.113.077-.014.148-.028.225-.035.035-.007.07-.007.106-.014.366-.057.725-.085,1.091-.134a11.96,11.96,0,0,1,1.745-.127H58.1c.436-.022.879-.022,1.316-.007A20.056,20.056,0,0,1,69.142,13a4.852,4.852,0,0,1,2.42,4.168" transform="translate(-2.22 -3.024)" fill="#fff"/>
                <path id="Caminho_2367" data-name="Caminho 2367" d="M69.343,13.666l-.272,37.622a1.083,1.083,0,0,1-.189.619,1.163,1.163,0,0,1-.922.542h-.007a.54.54,0,0,1-.112-.014,1.415,1.415,0,0,1-.167-.034,1.028,1.028,0,0,1-.126-.049.893.893,0,0,1-.182-.091A19.992,19.992,0,0,0,57.7,49.195c-.216-.014-.433-.02-.649-.027-.44-.007-.887-.007-1.333.007a28.764,28.764,0,0,0-5.4.7l-.112.021c-7.037,1.544-14.067,5.154-21.105,6.941-.034.007-.077.021-.111.028a31.454,31.454,0,0,1-6.41.974c-.53.021-1.061.028-1.6.007a20.509,20.509,0,0,1-11.9-4.313v17.3a3.047,3.047,0,0,1-6.094,0V11.209A6,6,0,0,1,.031,5.4a6.042,6.042,0,0,1,12.049.619,6,6,0,0,1-3,5.189v.585c.154.118.314.229.468.326A20.811,20.811,0,0,0,14.86,14.9c.077.034.146.056.223.084.1.034.189.063.265.09a.405.405,0,0,0,.112.035.983.983,0,0,0,.1.034.931.931,0,0,0,.139.042.883.883,0,0,0,.084.028C1.022,23.09,6.273,37.795,6.935,39.492c.042.1.063.16.063.16C9.47,45.627,13.47,50.878,19.935,52.019c7.945,1.4,18.026-3.262,22.92-5.21a31.423,31.423,0,0,1,7-1.94c4.573-.689,11.358-.981,15.562,1.523a3.859,3.859,0,0,1,.482.341c.383.3,1.368.925,1.382-.841.021-1.857,2.06-32.224,2.06-32.224" transform="translate(0 0)" fill="#e7e5e8"/>
              </g>
            </g>
          </g>
        </svg>`;
    default:
      return `<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.15 503.47">
            <defs>
              <style>
                .cls-1 {
                  fill: ${color};
                  stroke-width: 0px;
                }
              </style>
            </defs>
            <path class="cls-1" d="m391.07,190.12C388.06,82.15,298.09-2.93,190.12.08,85.65,3.9,2.28,88.52.02,193.04c-.65,42.88,12.99,84.76,38.78,119.02,3.61,5.33,96.14,134.13,126.99,176.83,6.61,9.1,17.15,14.51,28.39,14.58h.17c11.19-.01,21.71-5.33,28.36-14.33,14.3-19.47,125.93-172.12,128.9-175.78l.49-.65c25.16-33.89,38.83-74.95,39.01-117.16h.04c0-1.81-.03-3.62-.08-5.43Zm-157.7,124.31c-.74.24-1.53.36-2.35.36h-70.9c-4.35,0-7.88-3.53-7.88-7.88v-118.53c0-5.9,4.78-10.68,10.68-10.68h65.71c5.67,0,10.27,4.6,10.27,10.27v118.94c0,.81-.12,1.6-.35,2.34-.77,2.46-2.71,4.41-5.18,5.18Zm5.54-161.39c0,5.3-4.3,9.59-9.59,9.59h-67.49c-5.3,0-9.59-4.3-9.59-9.59v-45.92c0-5.3,4.3-9.59,9.59-9.59h57.07s10.13.01,10.13.01c5.45,0,9.88,4.42,9.88,9.88v45.62Z"/>
          </svg>`;
  }
}
