import { Image } from '@chakra-ui/image';
import { Flex, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';

import { processCheck, processUncheck } from '../../../../utils/exports';
import { toPercentage } from '../../../../utils/text/format/toPercentage';

import ProcessInfoTag from './ProcessInfoTag';

export const ProcessCarbonTag = ({ process }) => {
  // soma do carbono calculando e calculados
  const calcCO2 =
    (process?.carbonTrackDeliverStage.calculated ?? 0) +
    (process?.carbonTrackDeliverStage.calculating ?? 0) +
    (process?.carbonTrackTransitStage.calculated ?? 0) +
    (process?.carbonTrackTransitStage.calculating ?? 0);

  // soma do carbono retirado
  const calcRetired = process?.carbonTrackDeliverStage.retired + process?.carbonTrackTransitStage.retired;

  // formatação do carbono calculando e calculados
  const calcCO2Formatted = calcCO2.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  // formatação do carbono retirado
  const calcRetiredFormatted = calcRetired.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  return (
    process.carbonTrackTransitStage.status != 'PREVENTED' && (
      <ProcessInfoTag key={process.qtt} borderColor="#2ecc71" textColor="#2ecc71">
        {calcCO2 > 0 && calcRetired === 0 && (
          <Tooltip label={`O cálculo estimado de sua pegada de carbono para essa etapa foi de ${calcCO2Formatted} toneladas de CO₂eq`}>
            <Flex alignItems={'center'} gap={'10px'}>
              <Image src={processUncheck} opacity={'1'} w={'15px'} />

              <Text>Pegada CO₂: {calcCO2Formatted} tC0₂eq</Text>
            </Flex>
          </Tooltip>
        )}

        {calcRetired > 0 && calcCO2 === 0 && (
          <Tooltip
            label={`O cálculo estimado de sua pegada de carbono para essa etapa foi de ${calcRetiredFormatted} toneladas de CO₂eq totalmente compensadas`}>
            <Flex alignItems={'center'} gap={'10px'}>
              <Image src={processCheck} opacity={'1'} w={'15px'} />

              <Text>Pegada CO₂: {calcRetiredFormatted} tC0₂eq</Text>
            </Flex>
          </Tooltip>
        )}

        {calcRetired > 0 && calcCO2 > 0 && (
          <Tooltip
            label={`O cálculo estimado de sua pegada de carbono para essa etapa foi de ${calcCO2Formatted} toneladas de CO₂eq com ${calcRetiredFormatted} compensadas (${toPercentage(
              calcRetired / calcCO2
            )})`}>
            <Flex alignItems={'center'} gap={'10px'}>
              <Image src={processUncheck} opacity={'1'} w={'15px'} />

              <Flex direction="column">
                <Text>Pegada CO₂: {calcCO2Formatted} tC0₂eq</Text>

                <Text>Compensação CO₂: {calcRetiredFormatted} tC0₂eq</Text>
              </Flex>
            </Flex>
          </Tooltip>
        )}

        {calcRetired === 0 && calcCO2 === 0 && (
          <Tooltip label="Estamos calculando a pegada de carbono">
            <Flex alignItems={'center'} gap={'10px'}>
              <Image src={processUncheck} opacity={'1'} w={'15px'} />

              <Text>Pegada CO₂: calculando</Text>
            </Flex>
          </Tooltip>
        )}
      </ProcessInfoTag>
    )
  );
};
