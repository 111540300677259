import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';

// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  // Estrutura da tabela
  title: 'Cartas de protesto',
  categories: [
    {
      title: 'Código',
      /* field: 'e.status', */
      value: 'code',
      type: 'label',
    },
    {
      title: 'Processo',
      /* field: 'e.status', */
      value: 'processCode',
      link: '/comex/process/detail/${processIdentifier}',
      type: 'label',
    },
    {
      title: 'Data de chegada',
      /* field: 'e.description', */
      value: 'dateFormatted',
      align: 'center',
    },
    {
      title: 'Status do protesto',
      /* field: 'e.createdAt', */
      value: 'status',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  tags: ['status'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#6BDD9E', label: 'Enviado', value: 'ENVIADO', tooltip: 'Foi disparado para todos os responsáveis.' },
    {
      color: '#F1C40F',
      label: 'Rascunho',
      value: 'EM_RASCUNHO',
      tooltip: 'Somente quando o primeiro disparo for feito, esta carta de protesto sairá deste status.',
    },
    { color: '#F1C40F', label: 'Pendente', value: 'PENDENTE', tooltip: 'Existem envios pendentes ainda.' },
    { color: '#E74C3C', label: 'Cancelado', value: 'CANCELADO', tooltip: 'Esta carta de protesto foi cancelada por um operador.' },
  ],
  mainCategoryMobile: 'processCode',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 115,
  // Estilo
  resizeRows: '1fr 1fr 1fr 1fr 1fr',
};
