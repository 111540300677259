import React from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Collapse, Flex, Table, Tbody, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

const ProfitabilityTable = ({ entity }) => {
  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowDown size={20} color="#707070" /> : <MdKeyboardArrowRight size={20} color="#707070" />;

  return (
    <>
      <Tr onClick={onToggle} cursor="pointer" className="border">
        <Td px="24px" w="60px !important">
          <Box>{Icon}</Box>
        </Td>

        <Td px="24px" w="900px">
          <Box>
            <Text textColor="primary" fontWeight="500">
              {entity.clientName}
            </Text>
          </Box>
        </Td>

        <Td px="24px" w="200px">
          <Box>
            <Text>{entity.type}</Text>
          </Box>
        </Td>

        <Td px="24px" w="200px">
          <Flex justify="end">
            <Text>R$ {entity.billingValue}</Text>
          </Flex>
        </Td>

        <Td px="24px" w="200px">
          <Flex justify="end">
            <Text>R$ {entity.totalProfitability}</Text>
          </Flex>
        </Td>

        <Td px="24px" w="200px">
          <Flex>
            <Text color="#1A202C">{entity.percentage}%</Text>
          </Flex>
        </Td>
      </Tr>

      <Tr>
        <Td
          p="0"
          colSpan={15}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen}>
            <Box bgColor="#F9F9F9" mb="10px">
              <Table className="table-default min">
                <Tbody>
                  {entity?.profitabilityDetails && entity?.profitabilityDetails?.length > 0 ? (
                    entity?.profitabilityDetails?.map((subEntity, key) => {
                      return (
                        <Tr key={key}>
                          <Td w="60px" pl="24px !important">
                            <Tooltip label="Visualizar contrato">
                              <Link to={`/comex/process/detail/${subEntity.processIdentifier}`} target="_blank" w="0px">
                                <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                              </Link>
                            </Tooltip>
                          </Td>

                          <Td w="900px" px="24px !important" style={{ color: '#422C76', fontWeight: '500' }}>
                            {subEntity.processCode}
                          </Td>

                          <Td w="200px" px="24px !important">
                            {subEntity.type}
                          </Td>

                          <Td px="24px !important" w="200px">
                            <Flex justify="end">R$ {subEntity.billingValue}</Flex>
                          </Td>

                          <Td px="24px !important" w="200px">
                            <Flex justify="end">R$ {subEntity.profitability}</Flex>
                          </Td>

                          <Td px="24px !important" w="200px">
                            <Flex>{subEntity.percentage}%</Flex>
                          </Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex justify="center" my="10px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ProfitabilityTable;
