import { Flex, Grid } from '@chakra-ui/react';

import CustomButtonsRenderer from './components/CustomButtonsRenderer';
import CustomFieldsRenderer from './components/CustomFieldsRenderer';
import { CustomValueRenderer } from './components/CustomValueRenderer';
import { useCategoriesProps } from './Hooks/useCategoriesProps';

export const CommonListItemLineDesktop = ({
  categories,
  data,
  item,
  rawItem,
  content,
  listIndex,
  tags,
  images,
  imagePreset,
  identifier,
  hasDetailsButton,
  detailsRedirectEndpoint,
  hasDeleteButton,
  deleteButtonAction,
  deleteButtonSubtitle,
  customButtons,
  customFields,
  maxLengthResume,
  gridTemplateLength,
  viewport,
  statusPreset,
}) => {
  return (
    <Grid
      gridTemplateColumns={gridTemplateLength}
      alignItems="center"
      border="1px solid #70707036"
      borderRadius="10px"
      columnGap="15px"
      p="8px 25px"
      lineHeight="40px"
      boxShadow="sm"
      minH="58px"
      fontWeight="500"
      data-screen-width>
      {/* Renderiza dinamicamente os itens sem se referir explicitamente a item.algumacoisa */}
      {Object.entries(item).map(([property, value], index) => {
        const {
          linkValue,
          currentCategory: category,
          formattedValue,
          align,
          type,
          variantTag,
          renderHTML,
          showMore,
        } = useCategoriesProps(categories, property, index, item, value);

        if (!category.title) return null;

        // Exibe a propriedade formatada de acordo com o tipo
        return (
          <Flex
            key={`${property}-${index}`}
            fontSize="16px"
            lineHeight="20px"
            data-type={type}
            data-email-break={property === 'email'}
            justifyContent={align ? align : 'initial'}>
            <CustomValueRenderer
              property={property}
              value={value}
              index={index}
              linkValue={linkValue}
              category={category}
              formattedValue={formattedValue}
              identifier={identifier}
              align={align}
              type={type}
              variantTag={variantTag}
              renderHTML={renderHTML}
              images={images}
              imagePreset={imagePreset}
              tags={tags}
              statusPreset={statusPreset}
              viewport={viewport}
              maxLengthResume={maxLengthResume}
              rawItem={rawItem}
              showMore={showMore}
            />
          </Flex>
        );
      })}

      {/* Renderiza campos customizados */}
      <CustomFieldsRenderer customFields={customFields} data={data} listIndex={listIndex} identifier={identifier} />

      {/* Renderiza botões customizados */}
      <CustomButtonsRenderer
        hasDetailsButton={hasDetailsButton}
        detailsRedirectEndpoint={detailsRedirectEndpoint}
        hasDeleteButton={hasDeleteButton}
        deleteButtonAction={deleteButtonAction}
        deleteButtonSubtitle={deleteButtonSubtitle}
        customButtons={customButtons}
        rawItem={rawItem}
        identifier={identifier}
        content={content}
      />
    </Grid>
  );
};

export default CommonListItemLineDesktop;
