import React from 'react';

import { ImLocation } from 'react-icons/im';

import { Flex, TabPanel, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';

import { descriptionStatusTrip } from '../../../../../utils/translate';

const EventsTab = ({ events, setCenter, setZoom }) => {
  const processed = [];

  const generateDescription = (event) => {
    switch (event?.modality?.id) {
      case 5:
        if (event?.section == 'DRAFT') {
          return 'Viagem criada';
        }

        if (event?.section == 'CTE_TRAVELLING' || event?.section == 'CTE_COMPLETED') {
          return event.description;
        }

        return 'Status da viagem alterado para ' + descriptionStatusTrip(event.section);
      default:
        return event?.observation != null ? event.observation : event.description;
    }
  };

  function getLabelSection(section, modality) {
    switch (section) {
      case 'emergency':
        return 'Emergência';
      case 'stop':
        return 'Parada';
      case 'low':
        return 'Movimento lento';
      case 'no-face':
        return 'Reconhecimento facial';
    }

    switch (modality) {
      case 'LOG':
        return 'Log';
      case 'MOVING':
        return 'Movimento';
      case 'PAUSED':
        return 'Parada';
      case 'STATUS':
        return 'Status';
      case 'WARNING':
        return 'Aviso';
    }

    return null;
  }

  return (
    <TabPanel>
      <Table className="table-striped">
        <Tbody>
          {events?.length > 0 &&
            events?.map(function (event, key) {
              let latitude = null;
              let longitude = null;
    
              if ((event?.latitude !== null && event?.latitude !== undefined) && (event?.longitude !== null && event?.longitude !== undefined)) {
                let latitudeOffset = 0;
                let longitudeOffset = 0;
                const hash = `${event.latitude}-${event.longitude}`;
            
                if (!processed.includes(hash)) {
                  processed.push(hash);
                } else {
                  const baseOffset = 0.00003; // Distância base para o deslocamento
                  const angle = 45; // Ângulo fixo para o deslocamento
                  
                  // Calcula o deslocamento baseado no ângulo fixo
                  latitudeOffset = baseOffset * Math.cos(angle * (Math.PI / 180));
                  longitudeOffset = baseOffset * Math.sin(angle * (Math.PI / 180));
                }
    
                latitude = event.latitude + latitudeOffset;
                longitude = event.longitude + longitudeOffset;
              }

              const section = getLabelSection(event?.section, event?.modality?.description);
              return (
                <Tr key={key}>
                  <Td color="#854FFF" fontSize={14}>
                    {event?.occuredAtFormatted}
                  </Td>
                  <Td color="#0F0A1DB3" fontSize={14}>
                    <Flex direction="column" pl="40px">
                      {section != null && (
                        <Text fontSize={14} color="#0F0A1D">
                          {section}
                        </Text>
                      )}
                      <Text fontWeight="bold" fontSize={14} color="#0F0A1D">
                        {generateDescription(event)}
                      </Text>
                      <Text mt="5px" fontSize={14} color="#0F0A1DB3">
                        {event?.observation}
                      </Text>
                    </Flex>
                  </Td>
                  <Td color="#0F0A1DB3" fontSize={14}>
                    {latitude !== null && longitude !== null && event?.modality?.description != 'LOG' && (
                      <ImLocation
                        title="Origem"
                        color="#ea4335"
                        cursor="pointer"
                        size="25px"
                        onClick={(decision) => {
                          if (decision) {
                            setCenter({
                              lat: event.latitude,
                              lng: event.longitude,
                            });
                            setZoom(18);
                          }
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default EventsTab;
