import { useEffect, useState } from 'react';

import { buildPath } from '../Helpers/buildPath';
import { checkCoordinateStatus } from '../Helpers/checkCoordinateStatus';
import { cleanPath } from '../Helpers/cleanPath';
import { removeDuplicates } from '../Helpers/cleanPathDuplicateAndNull';
import { convertCoordinates } from '../Helpers/convertCoordinates';
import { generateRoute } from '../Helpers/generateRoute';

export const usePopulateRoutes = (information) => {
  const [pastPathRender, setPastPathRender] = useState([]);
  const [currentPathRender, setCurrentPathRender] = useState([]);
  const [futurePathRender, setFuturePathRender] = useState([]);
  const [originLineExtender, setOriginLineExtender] = useState([]);
  const [destinationLineExtender, setDestinationLineExtender] = useState([]);
  const [originLineExtenderType, setOriginLineExtenderType] = useState([]);
  const [destinationLineExtenderType, setDestinationLineExtenderType] = useState([]);

  const [markerPastRender, setMarkerPastRender] = useState([]);
  const [markerCurrentRender, setMarkerCurrentRender] = useState([]);
  const [markerFutureRender, setMarkerFutureRender] = useState([]);
  const [markerDoneRender, setMarkerDoneRender] = useState([]);

  const [originRender, setOriginRender] = useState([]);
  const [destinationRender, setDestinationRender] = useState([]);
  const [positionsRender, setPositionsRender] = useState([]);

  const [hasPastPathCount, setHasPastPathCount] = useState(0);

  const cleanArray = (arr) => {
    return arr.filter((item) => item.title !== undefined);
  };

  useEffect(() => {
    const loadPaths = async () => {
      const {
        origin,
        destination,
        positions,
        markerDone,
        markerPast,
        markerCurrent,
        markerFuture,
        pastPath,
        currentPath,
        futurePath,
      } = await buildPath(information);

      // Nome intuitivo
      const isOnlyFuturePath = futurePath.length <= 1;

      // Ponto atual
      const currentWaypoint = pastPath[pastPath.length - 1];

      // Geração de rotas
      const past = await generateRoute(pastPath);
      const current = await generateRoute([currentWaypoint, currentPath]);
      const future = await generateRoute([currentWaypoint, ...futurePath]);

      const done = await generateRoute(cleanPath(markerDone));

      const cleanedArray = removeDuplicates(done);

      const isArrived = future.length <= 0;
      const currentPosition = past[past.length - 1];
      const hasCurrentPath = !!(currentPosition || markerCurrent);

      //verificar estado da linha para exibição(past, future)
      const originPositionType = checkCoordinateStatus(origin, pastPath, futurePath, hasCurrentPath);
      const destinationPositionType = checkCoordinateStatus(destination, pastPath, futurePath);

      //definir tipo de linha para exibição(dashed, line)
      const originPosition = originPositionType === 'dashed' ? futurePath[0] : pastPath[0];
      const destinationPosition =
        destinationPositionType === 'dashed' ? futurePath[futurePath.length - 1] : pastPath[pastPath.length - 1];

      //Defaults
      setOriginRender(convertCoordinates(origin));
      setDestinationRender(convertCoordinates(destination));
      setPositionsRender(positions);

      // Pontos
      setPastPathRender(past);
      setCurrentPathRender(current);
      setFuturePathRender(future);

      //definir valores para extenção de linhas de origem e destino
      setOriginLineExtenderType(originPositionType);
      setDestinationLineExtenderType(destinationPositionType);
      //inicial + ponto inicial convertido
      setOriginLineExtender([originPosition, originPositionType === 'dashed' ? future[0] : past[0]]);
      //final + ponto final convertido
      setDestinationLineExtender([
        destinationPosition,
        destinationPositionType === 'dashed' ? future[future.length - 1] : past[past.length - 1],
      ]);

      // Marcadores
      setMarkerPastRender(markerPast);
      //nao exibe se entregue, se tiver positions exibe a rota atual com base no ultimo ponto, se nao tiver, exibe com base no current da API
      setMarkerCurrentRender(isArrived ? [] : currentPosition ? [currentPosition] : markerCurrent);
      setMarkerFutureRender(markerFuture);
      setMarkerDoneRender(markerDone);

      // Valores de continuação
      setHasPastPathCount(markerPast.length);
    };

    loadPaths();
  }, [information]);

  const hasPastPath = pastPathRender.length > 0;
  const hasCurrentPath = currentPathRender.length > 0;
  const hasFuturePath = futurePathRender.length > 0;

  return {
    // Default
    originRender,
    destinationRender,
    positionsRender,
    // Pontos
    pastPathRender,
    currentPathRender,
    futurePathRender,
    // Extenções de linhas
    originLineExtender,
    originLineExtenderType,
    destinationLineExtender,
    destinationLineExtenderType,
    // Marcadores
    markerPastRender,
    markerCurrentRender,
    markerFutureRender,
    markerDoneRender,
    // Condições
    hasPastPath,
    hasCurrentPath,
    hasFuturePath,
    hasPastPathCount,
  };
};
