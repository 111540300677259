import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { MdTune } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';
import closeFloatActionButton from '../../../../utils/actions/modal/closeFloatActionButton';
import FilterOption from '../../../../utils/filters/FilterOption';
import { filterOptionsMapping } from '../../../../utils/filters/filterPresets';
import { appendFilter, getFilter } from '../../../../utils/storageFilter';

import DragAndDropConfigFilter from './DragAndDropConfigFilter';
import FormConfigFilter from './FormConfigFilter';
import initialFields from './initialFields';
import ListSavedFilters from './ListSavedFilters';

const yup = require('yup');

const ModalConfigFilter = ({
  screen = 'comex-mapping',
  setAppliedFilter,
  refreshAppliedFields,
  setFilterSalved,
  setFilterOptions,
  filterOptions,
  setColumnData,
  columnData,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(() => getFilter(screen) ?? null);

  const [indexTab, setIndexTab] = useState(0);

  const [isEdit, setIsEdit] = useState(false);
  const [filterEditing, setFilterEditing] = useState('');
  const [titleSelection, setTitleSelection] = useState('');

  const [selectionList, setSelectionList] = useState([]);

  const [userOptions, setUserOptions] = useState([]);
  const [sendDailyMail, setSendDailyMail] = useState(false);

  const [metaSelectionMap, setMetaSelectionMap] = useState({
    current_page: 1,
  });

  const loadUsers = () => {
    requests.listClientsUsers().then((data) => {
      setUserOptions(
        data?.map((data) => {
          return {
            value: data.identifier,
            label: data.name,
          };
        })
      );
    });
  };

  const loadSelectionList = () => {
    requests.listSelection(metaSelectionMap.current_page > 0 ? metaSelectionMap.current_page : 1).then((list) => {
      setSelectionList(list.data);
      setMetaSelectionMap(list.meta);
    });
  };

  const createSelectedChecklist = () => {
    const selectedChecklist = {};
    filterOptions.forEach((item) => {
      if (item instanceof FilterOption) {
        const selectedChildren = item.getSelectedChildrenValue();
        selectedChecklist[item.value] = selectedChildren.length > 0 ? selectedChildren : null;
      }
    });
    return selectedChecklist;
  };

  const onlyApply = () => {
    setIsLoading(true);

    const selectedChecklist = createSelectedChecklist();

    setAppliedFilter({ ...selectedChecklist, ...filters });

    setIsLoading(false);
    onClose();
  };

  function toggleTrueToFalse(obj) {
    // Verifica se o objeto é um array
    if (Array.isArray(obj)) {
      // Se for um array, percorra cada elemento e chame a função novamente
      obj.forEach((item) => toggleTrueToFalse(item));
    } else if (typeof obj === 'object' && obj !== null) {
      // Se for um objeto, percorra cada chave-valor e chame a função novamente
      Object.keys(obj).forEach((key) => {
        // Verifica se o valor é true e o converte para false
        if (obj[key] === true) {
          obj[key] = false;
        }
        // Chama recursivamente a função para valores de objeto
        toggleTrueToFalse(obj[key]);
      });
    }
  }

  const cancelEditing = async () => {
    setIsEdit(false);
    setTitleSelection(null);
    onClose();

    const options = await filterOptionsMapping();

    setFilterOptions(options);

    setColumnData(initialFields);
    localStorage.removeItem('filter-comex-mapping');
  };

  const selectTabIndex = (index) => {
    setIndexTab(index);
  };

  useEffect(() => {
    setIsEdit(false);
    setTitleSelection(null);

    localStorage.removeItem('filter-comex-mapping');
  }, [isOpen]);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsMapping());
  };

  useEffect(() => {
    loadUsers();
    loadSelectionList();
    getFilterOptions();
  }, []);

  return (
    <>
      <Button
        h="46px"
        px="24px"
        py="14px"
        bgColor="primary"
        color="#FFFFFF"
        borderRadius="27px"
        _hover={{ opacity: '0.9' }}
        rightIcon={<MdTune color="#FFFFFF" size={20} />}
        onClick={async () => {
          closeFloatActionButton();
          onOpen();
        }}
        {...props}>
        <Text>Configurar filtro</Text>
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent borderRadius="5px" maxW="none" w={{ sm: '90vw', lg: '50vw' }} h="80vh">
          <ModalHeader p="25px 0 0 25px">
            <Text fontSize="25px" textColor="primary">
              {!titleSelection ? 'Seleção de filtros' : 'Editando filtro ' + '(' + titleSelection + ')'}
            </Text>

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody py="0px">
            <Formik
              initialValues={{ name: '', user: [], clients: [] }}
              validationSchema={yup.object().shape({
                name: yup.string().required('Campo obrigatório'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                const selectedChecklist = createSelectedChecklist();

                let filter = {
                  ...selectedChecklist,
                  clients: values.clients,
                };

                let data = {
                  name: values.name,
                  user: [values?.user && values?.user?.value],
                  edit: '',
                  sendEmail: sendDailyMail,
                  parent: filterEditing,
                };

                var newName = selectionList.map(function (objeto) {
                  return objeto.name;
                });

                if (newName.some((nome) => nome === values.name)) {
                  toast.error('Nome do filtro já em uso. Por favor, escolha um nome diferente.');

                  setSubmitting(false);
                } else {
                  if (isEdit) {
                    requests
                      .newSelectionMap(data, filter, columnData)
                      .then(() => {
                        requests.deleteSelection(filterEditing);

                        onClose();

                        loadSelectionList();
                        setIsEdit(false);
                        setTitleSelection(null);

                        toast.success('Filtro editado e aplicado!');

                        appendFilter(screen, selectedChecklist);
                        setAppliedFilter(getFilter(screen), { ...selectedChecklist, ...filters });
                      })
                      .catch(() => {
                        setSubmitting(false);
                      });
                  } else {
                    requests
                      .newSelectionMap(data, filter, columnData)
                      .then(() => {
                        onClose();

                        loadSelectionList();
                        setIsEdit(false);
                        setTitleSelection(null);

                        toast.success('Filtro criado e aplicado!');

                        appendFilter(screen, selectedChecklist);
                        setAppliedFilter(getFilter(screen), { ...selectedChecklist, ...filters });
                      })
                      .finally(() => {
                        setSubmitting(false);
                      });
                  }
                }
              }}>
              {({ values, handleSubmit, handleChange, isSubmitting, setFieldValue, errors }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Tabs isFitted index={indexTab}>
                    <TabList borderBottom="1px" borderColor="#7070702E">
                      <Tab _selected={{ borderColor: '#422C76' }} onClick={() => selectTabIndex(0)}>
                        <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76">
                          Filtro aplicado
                        </Text>
                      </Tab>

                      <Tab _selected={{ borderColor: '#422C76' }} onClick={() => selectTabIndex(1)}>
                        <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76">
                          Filtros salvos
                        </Text>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel px="3px" py="20px" h="calc(80vh - 180px)" overflowY="auto">
                        {indexTab === 0 && (
                          <Flex gap="20px" direction="column">
                            <FormConfigFilter
                              screen={screen}
                              filterOptions={filterOptions}
                              handleChange={handleChange}
                              values={values}
                              setFilters={setFilters}
                              titleSelection={titleSelection}
                              userOptions={userOptions}
                              isEdit={isEdit}
                              setFieldValue={setFieldValue}
                            />

                            <Grid templateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }} w="full">
                              {filterOptions &&
                                filterOptions.map((item, key) => {
                                  if (item instanceof FilterOption) {
                                    return (
                                      <Flex
                                        key={key}
                                        direction="column"
                                        align="flex-start"
                                        style={{ listStyle: 'none', fontSize: '18px', fontWeight: 'bold' }}>
                                        {item.renderTree(getFilter(screen, item.value))}
                                      </Flex>
                                    );
                                  }
                                })}
                            </Grid>

                            <DragAndDropConfigFilter
                              setColumnData={setColumnData}
                              columnData={columnData}
                              sendDailyMail={sendDailyMail}
                              setSendDailyMail={setSendDailyMail}
                            />
                          </Flex>
                        )}
                      </TabPanel>

                      <TabPanel px="3px" py="20px" h="calc(80vh - 180px)" overflowY="auto">
                        {indexTab === 1 && (
                          <ListSavedFilters
                            onClose={onClose}
                            screen={screen}
                            selectTabIndex={selectTabIndex}
                            selectionList={selectionList}
                            setAppliedFilter={setAppliedFilter}
                            setColumnData={setColumnData}
                            setFilterEditing={setFilterEditing}
                            setFilterSalved={setFilterSalved}
                            setFieldValue={setFieldValue}
                            setFilters={setFilters}
                            setIsEdit={setIsEdit}
                            loadSelectionList={loadSelectionList}
                            refreshAppliedFields={refreshAppliedFields}
                            setTitleSelection={setTitleSelection}
                            setSelectionList={setSelectionList}
                            setFilterOptions={setFilterOptions}
                            filterOptions={filterOptions}
                          />
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <ModalFooter borderTop="1px" borderColor="#7070702E" p="0">
                    {isEdit && (
                      <Button
                        variant="primary"
                        w="fit-content"
                        borderRadius="7px"
                        m="15px"
                        p="9px 23px"
                        onClick={() => {
                          cancelEditing();
                        }}>
                        Cancelar
                      </Button>
                    )}

                    <Button
                      variant="secundary"
                      w="fit-content"
                      borderRadius="7px"
                      m="15px"
                      p="9px 23px"
                      type="submit"
                      isDisabled={isLoading}
                      isLoading={isSubmitting}>
                      Salvar e aplicar
                    </Button>

                    {!isEdit && (
                      <Button
                        variant="primary"
                        w="fit-content"
                        borderRadius="7px"
                        m="15px"
                        p="9px 23px"
                        isDisabled={isSubmitting}
                        isLoading={isLoading}
                        onClick={() => {
                          onlyApply();
                        }}>
                        Somente aplicar
                      </Button>
                    )}
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalConfigFilter;
