import React, { useEffect, useState } from 'react';

import { ResponsivePie } from '@nivo/pie';

import { Box } from '@chakra-ui/react';

/**
 * Componente de gráfico de pizza reutilizável.
 *
 * @param {Object[]} data - Dados de entrada para o gráfico. Cada chave de `data` deve corresponder a uma chave especificada em `segments`.
 * @param {Object[]} segments - Array de objetos que define as chaves e as configurações para cada segmento do gráfico.
 * @param {string} segments[].id - Identificador único para cada segmento do gráfico.
 * @param {string} segments[].key - A chave em `data` que será usada para obter o valor numérico para o segmento do gráfico.
 * @param {string} segments[].filterPie - Filtro que será aplicado ao clicar em um segmento do gráfico.
 * @param {string} [segments[].color] - Cor específica para o segmento do gráfico. Se não for fornecida, será usada uma cor padrão.
 * @param {Function} setFilterPie - Função callback para definir o filtro baseado no segmento clicado no gráfico.
 * @param {...Object} props - Demais propriedades que serão passadas ao componente `ResponsivePie` da biblioteca `@nivo/pie`.
 *
 * @example
 * <CommonPieChart
 *   data={expeditionStatus}
 *   segments={[
 *     { id: 'Atendidos', key: 'onTime', filterPie: 'NoPrazo', color: '#2ECC71' },
 *     { id: 'Atrasados', key: 'outOfTime', filterPie: 'ForaDoPrazo', color: '#E74C3C' },
 *   ]}
 *   setFilterPie={setOrderStatus}
 * />
 */

const CommonPieChart = ({ data, segments, setFilterPie, innerRadius = '0.8', posContent = '-0.6em', ...props }) => {
  const [chartData, setChartData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Função para lidar com o clique no gráfico
  const handleClickStatus = ({ id }) => {
    const selectedKey = segments.find((key) => key.id === id);

    if (!setFilterPie) {
      return;
    }
    if (selectedStatus === id) {
      setFilterPie('');
      setSelectedStatus(null);
    } else {
      setFilterPie(selectedKey ? selectedKey.filterPie : '');
      setSelectedStatus(id);
    }
  };

  const formatValue = (value) => value?.toLocaleString('pt-BR') || '';

  const calculatePercentage = (value, sum) => {
    if (!value || sum === 0) return '0%';
    return `${((value / sum) * 100).toFixed(2)}%`;
  };

  useEffect(() => {
    if (!Array.isArray(segments) || segments.length === 0) return;

    // Calcular o total
    const total = segments.reduce((sum, { key }) => sum + (parseInt(data[key], 10) || 0), 0);
    setTotal(total);

    // Criar os dados do gráfico
    const chartData = segments.map(({ id, key, color }) => {
      const value = parseInt(data[key], 10) || 0;
      return {
        id,
        label: `${id} (${calculatePercentage(value, total)})`,
        value,
        color, // Use a cor definida em cada item de segments
      };
    });

    setChartData(chartData);
  }, [data, segments]);

  return (
    <Box position="relative" height="300px">
      <ResponsivePie
        data={chartData}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={innerRadius}
        padAngle={1}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ datum: 'data.color' }} // Usa a cor definida em chartData
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabel={({ id, value }) => `${id} (${value})`}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10} //ocultar itens com angulo menor qe o valor
        arcLabelsTextColor={{ theme: 'background' }}
        onClick={handleClickStatus}
        legends={[
          {
            anchor: 'bottom',
            symbolSpacing: 5,
            direction: 'row',
            translateX: 0,
            translateY: 70,
            itemsSpacing: 0,
            itemWidth: 130,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [{ on: 'hover', style: { itemTextColor: '#333333' } }],
          },
        ]}
        layers={[
          'arcs',
          'arcLabels',
          'arcLinkLabels',
          'legends',
          ({ centerX, centerY }) => (
            <text
              x={centerX}
              y={centerY}
              textAnchor="middle"
              dominantBaseline="central"
              style={{ fontSize: '28px', fontWeight: 'bold' }}>
              <tspan x={centerX} dy={posContent} fill="#B6BABF">
                Total
              </tspan>
              <tspan x={centerX} dy="1.2em" fill="#333333">
                {formatValue(total)}
              </tspan>
            </text>
          ),
        ]}
        {...props}
      />
    </Box>
  );
};

export default CommonPieChart;
