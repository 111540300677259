import React from 'react';

import { ErrorMessage } from 'formik';
import Select from 'react-select';

import { Flex, FormControl, Grid, Input, TabPanel, Text } from '@chakra-ui/react';

import InputCurrency from '../../../../components/Form/Input/InputCurrency';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { InputPercentage } from '../../../../components/Form/Input/InputPercentage';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';

const CommercialDataForm = ({ values, setFieldValue, handleChange, isPreviwer, isDraft, hasEntityExpired }) => {
  const isIndefiniteContractOptions = [
    {
      label: 'Indeterminada',
      value: false,
    },
    {
      label: 'Sim',
      value: true,
    },
  ];

  const isProject = [
    {
      label: 'Projeto ',
      value: true,
    },
    {
      label: 'Recorrente',
      value: false,
    },
  ];

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
    }),
  });

  return (
    <TabPanel p="30px">
      <Grid templateColumns="repeat(3, 1fr)" gap={10} mb="20px">
        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Restrições gerais</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />
          <InputDefault
            isDisabled={isPreviwer || hasEntityExpired}
            placeholder="Preencha as restrições gerais"
            name="commercial.generalRestrictionsInformation"
            id="generalRestrictionsInformation"
            styles={selectCustomStyles()}
            onChange={handleChange}
            value={values.commercial.generalRestrictionsInformation}
          />
          <ErrorMessage component="p" className="error-message-error" name="commercial.generalRestrictionsInformation" />
        </FormControl>
        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Rentabilidade</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />

          <InputPercentage
            name="commercial.profitabilityInformation"
            id="profitabilityInformation"
            value={values.commercial.profitabilityInformation}
            setFieldValue={setFieldValue}
            placeholder="0,00%"
            isDisabled={isPreviwer || hasEntityExpired}
          />

          <ErrorMessage component="p" className="error-message-error" name="commercial.profitabilityInformation" />
        </FormControl>
        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Contrato tem validade?</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />
          <Select
            styles={selectCustomStyles()}
            options={isIndefiniteContractOptions}
            name="commercial.isIndefiniteContract"
            isDisabled={isPreviwer || hasEntityExpired}
            value={isIndefiniteContractOptions.find(({ value }) => value === values.commercial.isIndefiniteContract)}
            placeholder="Contrato tem validade"
            onChange={(value) => {
              setFieldValue('commercial.isIndefiniteContract', value.value);
              if (!value.value) {
                setFieldValue('commercial.contractValidUntil', '');
              }
            }}
          />
        </FormControl>

        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Faturamento mensal</Text>
                <Text fontSize="small"> (opcional)</Text>
              </Flex>
            }
          />
          <InputCurrency
            isDisabled={isPreviwer || values.commercial.isProject.value}
            placeholder="R$0,00"
            name="commercial.monthlyBilling"
            value={values?.commercial?.monthlyBilling}
            setFieldValue={setFieldValue}
          />
          <ErrorMessage component="p" className="error-message-error" name="commercial.monthlyBilling" />
        </FormControl>

        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Faturamento anual</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />
          <InputCurrency
            isDisabled={isPreviwer || hasEntityExpired}
            placeholder="R$0,00"
            name="commercial.annualBilling"
            value={values?.commercial?.annualBilling}
            setFieldValue={setFieldValue}
          />

          <ErrorMessage component="p" className="error-message-error" name="commercial.annualBilling" />
        </FormControl>

        {values?.commercial?.isIndefiniteContract && (
          <FormControl>
            <LabelDefault
              text={
                <Flex gap="4px" alignItems="center">
                  <Text>Validade do contrato</Text>
                  {isDraft && <Text fontSize="small"> (opcional)</Text>}
                </Flex>
              }
            />
            <Input
              type="date"
              name="commercial.contractValidUntil"
              cursor="pointer"
              bg="#F2F2F2"
              isDisabled={isPreviwer || hasEntityExpired}
              borderColor="#70707033"
              value={values.commercial.contractValidUntil}
              onChange={handleChange}
            />

            <ErrorMessage component="p" className="error-message-error" name="commercial.contractValidUntil" />
          </FormControl>
        )}

        <FormControl>
          <LabelDefault
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Tipo de Faturamento</Text>
                {isDraft && <Text fontSize="small"> (opcional)</Text>}
              </Flex>
            }
          />
          <Select
            placeholder="Selecione"
            name="commercial.isProject"
            styles={selectCustomStyles()}
            isDisabled={isPreviwer || hasEntityExpired}
            options={isProject}
            value={isProject.find((selected) => selected.label === values.commercial.isProject.label)}
            onChange={(option) => {
              setFieldValue('commercial.isProject', option);

              if (option.value === true) {
                setFieldValue('commercial.monthlyBilling', 0);
              }
            }}
          />

          <ErrorMessage component="p" className="error-message-error" name="commercial.isProject" />
        </FormControl>
      </Grid>

      <FormControl>
        <LabelDefault
          text={
            <Flex gap="4px" alignItems="center">
              <Text>Comentários adicionais</Text>
              <Text fontSize="small"> (opcional)</Text>
            </Flex>
          }
        />
        <InputDefault
          isDisabled={isPreviwer || hasEntityExpired}
          // placeholder="Comentários adicionais (opcional)"
          as="textarea"
          minH="120px"
          pt="10px"
          name="commercial.additionalInformation"
          styles={selectCustomStyles()}
          onChange={handleChange}
          value={values?.commercial?.additionalInformation}
        />
      </FormControl>
    </TabPanel>
  );
};

export default CommercialDataForm;
