import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const primary = definePartsStyle({
  field: {
    height: '40px',
    border: '1px',
    borderColor: '#70707033',
    fontsize: '16px',
    paddingLeft: '25px',
    _placeholder: { color: '#0F0A1D66' },
    _focus: { borderColor: 'black' },
  },
});

export const inputStyles = defineMultiStyleConfig({ variants: { primary } });
