import React, { useEffect, useState } from 'react';

import { Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import '../../../styles/globals.css';

const EditPass = ({ setPassword, maxW = '300px' }) => {
  const [newPassowrd, setNewPassword] = useState('');
  const [newPassowrdConfirm, setNewPasswordConfirm] = useState('');

  useEffect(() => {
    if (newPassowrd.length >= 6 && newPassowrd === newPassowrdConfirm) {
      setPassword(newPassowrd);
    } else {
      setPassword('');
    }
  }, [newPassowrd, newPassowrdConfirm]);

  return (
    <Flex direction="column" gap="20px">
      <Text textStyle="tableTitle" color="primary" fontWeight="bold">
        Alterar senha
      </Text>

      <FormControl>
        <Text>
          <FormLabel color="primary">Nova senha</FormLabel>

          <Input
            variant="primary"
            maxW={maxW}
            type="password"
            name="password"
            id="password"
            value={newPassowrd}
            onChange={(event) => setNewPassword(event.target.value)}
          />

          {newPassowrd.length > 1 && newPassowrd.length < 8 ? <Text color="red">Insira no mínimo 8 caracteres</Text> : null}
        </Text>
      </FormControl>

      <FormControl>
        <Text>
          <FormLabel color="primary">Confirmar senha</FormLabel>

          <Input
            variant="primary"
            maxW={maxW}
            type="password"
            name="confirm-pass"
            id="confirm-pass"
            value={newPassowrdConfirm}
            onChange={(event) => setNewPasswordConfirm(event.target.value)}
          />

          {newPassowrdConfirm.length > 1 && newPassowrdConfirm.length < 8 ? (
            <Text color="red">Insira no mínimo 8 caracteres</Text>
          ) : null}
          {newPassowrdConfirm !== newPassowrd && <Text color="red">As senhas não coferem!</Text>}
        </Text>
      </FormControl>
    </Flex>
  );
};

export default EditPass;
