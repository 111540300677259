import { useEffect, useState } from 'react';

import { generateRoute } from './generateRoute';

export const buildPolyline = (origin, destination, length = 2) => {
  const [markerOrigin, setMarkerOrigin] = useState([]);
  const [markerDestination, setMarkerDestination] = useState([]);

  useEffect(() => {
    const updateRoutes = async () => {
      if (origin.length >= length && destination.length > 0) {
        let pastRoute;
        let futureRoute;

        origin && ((pastRoute = await generateRoute([origin[0], ...destination])), setMarkerOrigin(pastRoute));
        destination && ((futureRoute = await generateRoute([origin[1], ...destination])), setMarkerDestination(futureRoute));
      }
    };

    updateRoutes();
  }, [origin, destination]);

  const markerCurrent = [{ ...markerOrigin[markerOrigin.length - 1], marker: '' }];
  //verifica se existe a coordenada do current
  const hasMarkerCurrent = markerCurrent[0].title;
  //realizara apenas se só houver origin e destino, caso tenha viagens não se aplica a essa regra
  const isOnlyTwoMarkers = origin.length === 2;

  return {
    markerCurrent,
    markerOrigin,
    markerDestination,
    hasMarkerCurrent,
    isOnlyTwoMarkers,
  };
};
