import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, FormLabel, Input, Text } from '@chakra-ui/react';

import api from '../../../../services/api';

const RecoverPwd = ({ setStep, setIsLoading }) => {
  const yup = require('yup');

  return (
    <Formik
      initialValues={{
        user: window.localStorage.getItem('profile-email') ?? '',
      }}
      validationSchema={yup.object().shape({
        user: yup.string().required('Campo de e-mail obrigatório'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setIsLoading(true);

        api
          .post('recover/request', {
            username: values.user,
          })
          .then(() => {
            toast.success('Código enviado para email informado!');
            localStorage.setItem('profile-email', values.user);

            setStep(2);
            setIsLoading(false);
          })
          .finally(() => {
            setSubmitting(false);
            setIsLoading(false);
          });
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="30px" className="down-anim">
            <Flex direction="column" gap="10px">
              <Text textStyle="subtitle" textColor="linkColor">
                Esqueceu a senha?
              </Text>

              <Text textStyle="subtitle" textColor="#FFFFFF">
                Informe o e-mail cadastrado para receber instruções para recuperação da sua conta.
              </Text>
            </Flex>

            <Flex direction="column">
              <FormLabel className="up-anim" color="white" m="0">
                E-mail
              </FormLabel>

              <Input
                color="#FFFFFF"
                h="40px"
                variant="flushed"
                name="user"
                id="user"
                type="email"
                _placeholder={{ color: '#FFFFFF' }}
                _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                value={values.user}
                onChange={handleChange}
              />

              <ErrorMessage component="p" className="error-message-error" name="user" />
            </Flex>
          </Flex>

          <Flex
            w="full"
            mt={{ base: '20px', md: '30px' }}
            gap={{ base: '0px', md: '15px' }}
            justify={{ base: 'space-between', md: 'end' }}>
            <Button
              variant="outline"
              height="40px"
              borderRadius="5px"
              borderColor="#FF2F69"
              textColor="#FF2F69"
              transition=".3s"
              _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
              isDisabled={isSubmitting}
              onClick={() => {
                window.location.href = '/';
              }}>
              <Text>Voltar</Text>
            </Button>

            <Button
              type="submit"
              variant="solid"
              height="40px"
              borderRadius="5px"
              bgColor="#FF2F69"
              textColor="#FFFFFF"
              transition=".3s"
              _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
              isLoading={isSubmitting}>
              <Text>Enviar</Text>
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default RecoverPwd;
