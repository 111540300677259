import { animatedLocation, destinationMap, originMap } from '../../../utils/exports';

export const MaritimeMarkerIcons = () => {
  const createCircleIcon = (number, dashed = false) => {
    const strokeDasharray = dashed ? '4,2' : '';
    return {
      url: `data:image/svg+xml;charset=UTF-8,
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <circle cx="16" cy="16" r="14" fill="white" stroke="black" stroke-width="3" stroke-dasharray="${strokeDasharray}"/>
          <text x="16" y="22" font-size="18" font-weight="bold" text-anchor="middle" fill="black" font-family="Helvetica">${number}</text>
        </svg>`,
      scaledSize: new google.maps.Size(20, 20),
    };
  };

  const createPulseIcon = () => {
    return {
      url: animatedLocation,
      scaledSize: new google.maps.Size(45, 45),
      anchor: new google.maps.Point(22.5, 22.5),
    };
  };

  const createOriginIcon = () => {
    return {
      url: originMap,
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 50),
    };
  };

  const createDestinationIcon = () => {
    return {
      url: destinationMap,
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(10, 50),
    };
  };

  return {
    createCircleIcon,
    createPulseIcon,
    createOriginIcon,
    createDestinationIcon,
  };
};
