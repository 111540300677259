import React from 'react';

import { MdArticle, MdClose } from 'react-icons/md';

import {
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Wrap,
  useDisclosure,
} from '@chakra-ui/react';

import { modalityAdditional } from './utils/translate';

const BillingDetail = ({ additional, status }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <Button variant="link" ml="30px" color="#3498DB" onClick={onOpen} leftIcon={<MdArticle />}>
        <Text>Ver detalhes </Text>
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="2xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalHeader>
            <Flex align="center" justify="space-between">
              <Text textStyle="tableTitle" textColor="primary" fontWeight="bold">
                Dados de faturamento
              </Text>

              <IconButton bgColor="#FFFFFF" icon={<MdClose size={20} color="#00000057" />} onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody p="25px" position="relative">
            <Wrap className="down-anim">
              {additional.map((item, key) => {
                if (item.section === 'BILLING' && status != 'REFUSED') {
                  return (
                    <Flex direction="column" key={key}>
                      <label color="#422C76" htmlFor="licensePlate">
                        {modalityAdditional(item.modality)}
                      </label>
                      <Input w="150px" type="text" value={item.description} isDisabled={true} />
                    </Flex>
                  );
                } else if (item.section === 'BILLING' && status == 'REFUSED' && item.observation != null) {
                  return (
                    <Flex direction="column" key={key}>
                      <Text fontWeight="bold">Observação</Text>
                      <Text>{item.observation}</Text>
                    </Flex>
                  );
                } else {
                  return null;
                }
              })}
            </Wrap>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BillingDetail;
