import React, { useEffect, useRef, useState } from 'react';

import { DirectionsRenderer, GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';

import { Box, Flex, Switch, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';

import Card from '../../../../../components/Card/Card';
import MapPopup from '../../../../../components/Map/MapPopup';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';
import { generateTrajectoryPath, reduceCheckpointListByDistance } from '../../../../../utils/geolocation';
import { descriptionStatusTrip, tripLabelsString } from '../../../../../utils/translate';

const MapTrajectory = ({
  w = 'full',
  h = 'full',
  type = null,
  viewport,
  isLoadingEntity = false,
  entity = null,
  currentDriverPosition,
  isLoadingEvents = false,
  events = [],
  isLoadingCheckpoints = false,
  checkpoints = [],
  additional = {},
  setCenter,
  center,
  setZoom,
  zoom,
}) => {
  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const [map, setMap] = useState(null);

  const [isLoadingTrajectory, setIsLoadingTrajectory] = useState(true);
  const [trajectory, setTrajectory] = useState([]);

  const [trajectoryMarkers, setTrajectoryMarkers] = useState([]);
  const [eventMarkers, setEventMarkers] = useState([]);
  const [lastCheckpointMarker, setLastCheckpointMarker] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [showCheckpoints, setShowCheckpoints] = useState(false);
  const [checkpointMarkers, setCheckpointMarkers] = useState([]);

  const [showDirection, setShowDirection] = useState(false);

  let profileRole = localStorage.getItem('profile-role');

  const createTrajectoryAddressMarker = (item, entity, labels, labelIndex) => {
    let color = '#4B0082';

    if (item.type === 'COLETA') {
      color = '#2ecc71';
    } else if (item.type === 'ENTREGA') {
      color = '#f00';
    }

    return {
      header: {
        title: item.type,
        icon: generateIconEvent(item?.type, '#4B0082'),
        color: '#4B0082',
      },
      content: {
        code: entity.code,
        address: item.address,
      },
      lat: item.latitude,
      lng: item.longitude,
      label: labels[labelIndex % labels.length],
      icon: {
        url:
          'data:image/svg+xml;charset=UTF-8,' +
          encodeURIComponent(`
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="639.445" height="818" viewBox="0 0 639.445 818">
              <defs>
                <filter id="local_icone" x="0" y="0" width="639.445" height="818" filterUnits="userSpaceOnUse">
                  <feOffset dy="3" input="SourceAlpha"/>
                  <feGaussianBlur stdDeviation="3" result="blur"/>
                  <feFlood flood-color="#00acff" flood-opacity="0.349"/>
                  <feComposite operator="in" in2="blur"/>
                  <feComposite in="SourceGraphic"/>
                </filter>
              </defs>
              <g id="Grupo_4778" data-name="Grupo 4778" transform="translate(-4871.807 23808)">
                <g transform="matrix(1, 0, 0, 1, 4871.81, -23808)" filter="url(#local_icone)">
                  <path
                    id="local_icone-2"
                    data-name="local_icone"
                  d="M621.435,310.753a314.6,314.6,0,0,1-61.971,186.131l-.782,1.043c-4.65,5.823-182.089,248.363-204.818,279.305A56.07,56.07,0,0,1,308.8,800h-.261A56.14,56.14,0,0,1,263.427,776.8C214.407,709,67.388,504.359,61.651,495.885A306.7,306.7,0,0,1,.028,306.755C3.635,140.7,136.051,6.243,302.018.158a310.758,310.758,0,0,1,319.417,310.6Z"
                    transform="translate(9.01 6)"
                    fill="${color}"
                    fill-opacity="0.9"
                  />
                </g>
              </g>
            </svg>
          `),
        scaledSize: { width: 40, height: 40 },
      },
    };
  };

  const loadTrajectory = async () => {
    let checkpointsReduced = [];
    let bounds = new google.maps.LatLngBounds();

    if (checkpoints && checkpoints.length > 0) {
      const distance = checkpoints.length < 1000 ? 1 : 3;
      checkpointsReduced = reduceCheckpointListByDistance(checkpoints, distance);

      for (let k in checkpointsReduced) {
        let latitude = checkpointsReduced[k].latitude;
        let longitude = checkpointsReduced[k].longitude;

        if (!latitude || !longitude) {
          continue;
        }

        bounds.extend(new google.maps.LatLng(latitude, longitude));
      }
    }

    for (let k in entity.trajectories) {
      let latitude = entity.trajectories[k].latitude;
      let longitude = entity.trajectories[k].longitude;

      if (!latitude || !longitude) {
        continue;
      }

      bounds.extend(new google.maps.LatLng(latitude, longitude));
    }

    setTrajectory(
      await generateTrajectoryPath(entity?.trajectories, checkpointsReduced, loadDirections, entity?.status, currentDriverPosition)
    );

    if (map && !bounds.isEmpty()) {
      map.fitBounds(bounds);

      setIsLoadingTrajectory(false);
    } else {
      console.error('Map object or bounds are not ready yet.');
    }
  };

  const loadDirections = async (origin, destination, waypoints = []) => {
    if (typeof google === 'undefined' || !google) {
      return null;
    }

    if (!origin || !destination) {
      return;
    }

    var trajectory = null;

    let originAddress = origin?.address;
    let destinationAddress = destination?.address;

    // Se os endereços não estiverem disponíveis, usando as coordenadas de latitude e longitude
    if (!originAddress) {
      originAddress = `${origin?.latitude},${origin?.longitude}`;
    }
    if (!destinationAddress) {
      destinationAddress = `${destination?.latitude},${destination?.longitude}`;
    }

    try {
      const response = await requests.getTrajectoryTripByAddress(originAddress, destinationAddress, JSON.stringify(waypoints));

      if (response?.status == 204) {
        try {
          await new google.maps.DirectionsService().route(
            {
              origin: new google.maps.LatLng(origin.latitude, origin.longitude),
              destination: new google.maps.LatLng(destination.latitude, destination.longitude),
              waypoints: waypoints?.slice(0, Math.min(waypoints.length, 21))?.map((point) => ({
                location: new google.maps.LatLng(point.latitude, point.longitude),
                stopover: true,
              })),
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                trajectory = result;
                requests.addTrajectoryTripByAddress(
                  originAddress,
                  destinationAddress,
                  JSON.stringify(waypoints),
                  JSON.stringify(result)
                );
              }
            }
          );
        } catch (e) {}
      } else if (response?.status == 200) {
        trajectory = response.data;
      }
    } catch (e) {}

    return trajectory;
  };

  const createEventMarker = (item, entity, processed) => {
    let latitudeOffset = 0;
    let longitudeOffset = 0;
    const hash = `${item.latitude}-${item.longitude}`;

    if (!processed.includes(hash)) {
      processed.push(hash);
    } else {
      const baseOffset = 0.00003; // Distância base para o deslocamento
      const angle = 45; // Ângulo fixo para o deslocamento

      // Cálculo do deslocamento
      latitudeOffset = baseOffset * Math.cos(angle * (Math.PI / 180));
      longitudeOffset = baseOffset * Math.sin(angle * (Math.PI / 180));
    }

    const marker = {
      header: {
        title: 'EVENTO',
        icon: generateIconEvent(item?.modality?.description, '#4B0082'),
        color: generateColorEvent(item?.modality?.description),
      },
      content: {
        code: entity.code,
        date: item.occuredAtFormatted,
        driver: entity?.userDriverName,
        plates: entity?.plates,
        description: item.description != 'Outro' ? item.description : item.observation,
      },
      lat: item.latitude + latitudeOffset,
      lng: item.longitude + longitudeOffset,
      icon: {
        url:
          'data:image/svg+xml;charset=UTF-8,' +
          encodeURIComponent(`
            ${generateSvg(item?.modality?.description, '#4B0082')}
          `),
        scaledSize: { width: 21, height: 28 },
      },
    };

    if (item?.description == 'Descarga/Entrega' && entity?.fileDeliveryIdentifier) {
      marker.deliveryImage = {
        tripIdentifier: entity.identifier,
        fileIdentifier: entity.fileDeliveryIdentifier,
      };
    }

    return marker;
  };

  const generateColorEvent = (modality) => {
    switch (modality) {
      case 'MOVING':
        return '#2ECC71';

      case 'PAUSED':
        return '#E52359';

      default:
        return '#A885F9';
    }
  };

  const createCheckpointMarker = (item, key, list) => {
    let iconPath = 0;
    if (typeof google != 'undefined' && google) {
      iconPath = google.maps.SymbolPath.CIRCLE;
    }

    const content =
      profileRole === 'ROLE_SYSTEM_ADMIN'
        ? {
            id: key + 1,
            code: list.code,
            date: item.dateFormatted,
            driver: list?.userDriverName,
            plates: list?.plates,
          }
        : {
            code: list.code,
            date: item.dateFormatted,
            driver: list?.userDriverName,
            plates: list?.plates,
          };

    return {
      header: {
        title: 'LOCALIZAÇÃO',
        color: '#4B0082',
      },
      content: content,
      lat: item.latitude,
      lng: item.longitude,
      speed: item.speedFormatted,
      color: '#4B0082',
      icon: {
        path: iconPath,
        scale: 6,
        fillColor: '#4B0082',
        fillOpacity: 0.8,
        strokeWeight: 0.2,
      },
    };
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const generateSvg = (status, color) => {
    switch (status) {
      case 'MOVING':
        return `<svg id="continuar2" xmlns="http://www.w3.org/2000/svg" width="138.291" viewBox="0 0 138.291 178">
          <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(0.002 -0.003)" fill="${color}" fill-opacity="0.9" />
          <g id="Grupo_4785" data-name="Grupo 4785" transform="translate(38.986 31.485)">
            <path id="Caminho_2346" data-name="Caminho 2346" d="M138.312,90.031,110.944,121.96a2.786,2.786,0,0,0,2.114,4.6h10.377a1.673,1.673,0,0,1,1.672,1.672V173.91a2.787,2.787,0,0,0,2.786,2.786H152.96a2.787,2.787,0,0,0,2.786-2.786V128.232a1.673,1.673,0,0,1,1.672-1.672h10.377a2.786,2.786,0,0,0,2.114-4.6L142.541,90.031a2.783,2.783,0,0,0-4.228,0h0Z" transform="translate(-110.268 -89.058)" fill="#fff"/>
            <path id="Caminho_2347" data-name="Caminho 2347" d="M193.841,130.426v43.49a2.787,2.787,0,0,1-2.786,2.786h8.913a2.787,2.787,0,0,0,2.786-2.786V128.238a1.673,1.673,0,0,1,1.672-1.672H197.7a3.861,3.861,0,0,0-3.861,3.861Zm23.076-8.457L189.549,90.041a2.783,2.783,0,0,0-4.228,0l-2.34,2.733,25.028,29.2a2.786,2.786,0,0,1-2.114,4.6h8.913a2.786,2.786,0,0,0,2.114-4.6h0Z" transform="translate(-157.273 -89.064)" fill="#eceaec"/>
          </g>
        </svg>`;
      case 'PAUSED':
        return `<svg id="para" xmlns="http://www.w3.org/2000/svg" width="138.291" viewBox="0 0 138.291 178">
          <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(0.002 -0.003)" fill="${color}" fill-opacity="0.9" />
          <g id="Grupo_4792" data-name="Grupo 4792" transform="translate(24.304 60.965)">
            <path id="Caminho_2346" data-name="Caminho 2346" d="M149.837,197.818H74.347l-5.607-2.956V178.047a5.607,5.607,0,0,1,5.607-5.607h78.466l2.623,5.607v14.177a5.606,5.606,0,0,1-5.607,5.593Z" transform="translate(-68.74 -172.44)" fill="#fff"/>
            <path id="Caminho_2349" data-name="Caminho 2349" d="M152.824,172.44v14.022a8.409,8.409,0,0,1-8.407,8.407H68.75a5.607,5.607,0,0,0,5.607,5.607h78.466a5.607,5.607,0,0,0,5.607-5.607h0V178.054a5.6,5.6,0,0,0-5.6-5.614Z" transform="translate(-68.746 -172.44)" fill="#eceaec"/>
          </g>
        </svg>
        `;

      default:
        return `<svg id="informacao" xmlns="http://www.w3.org/2000/svg" width="138.291" viewBox="0 0 138.291 178">
          <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(0.002 -0.003)" fill="${color}" fill-opacity="0.9" />
          <g id="Grupo_4788" data-name="Grupo 4788" transform="translate(53.825 34.481)">
            <g id="Grupo_4786" data-name="Grupo 4786" transform="translate(0 28.344)">
              <path id="Caminho_2348" data-name="Caminho 2348" d="M152.24,181.479v41.9a2.787,2.787,0,0,0,2.786,2.786h25.067a2.787,2.787,0,0,0,2.786-2.786V181.331a3.632,3.632,0,0,0-3.631-3.631H156.016a3.775,3.775,0,0,0-3.776,3.776Z" transform="translate(-152.24 -177.7)" fill="#fff"/>
              <path id="Caminho_2349" data-name="Caminho 2349" d="M210.441,177.7h-1.835v45.678a2.787,2.787,0,0,1-2.786,2.786h8.913a2.787,2.787,0,0,0,2.786-2.786V181.193a3.494,3.494,0,0,0-3.493-3.493h-3.581Z" transform="translate(-186.877 -177.7)" fill="#eceaec"/>
            </g>
            <g id="Grupo_4787" data-name="Grupo 4787">
              <path id="Caminho_2350" data-name="Caminho 2350" d="M152.24,100.921v16.235a3.391,3.391,0,0,0,3.391,3.391h23.861a3.391,3.391,0,0,0,3.391-3.391V100.921a3.391,3.391,0,0,0-3.391-3.391H155.631a3.391,3.391,0,0,0-3.391,3.391Z" transform="translate(-152.24 -97.53)" fill="#fff"/>
              <path id="Caminho_2351" data-name="Caminho 2351" d="M210.441,97.53h-1.835v20.23a2.787,2.787,0,0,1-2.786,2.786h8.913a2.787,2.787,0,0,0,2.786-2.786V101.027a3.494,3.494,0,0,0-3.493-3.493h-3.581Z" transform="translate(-186.877 -97.53)" fill="#eceaec"/>
            </g>
          </g>
        </svg>`;
    }
  };

  const getColoredTruckSvg = (color) => {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="611.999" height="387.592" viewBox="0 0 611.999 387.592">
        <g id="Grupo_4056" data-name="Grupo 4056" transform="translate(0 -112.204)">
          <path
            fill="${color || '#4B0082'}"
            fill-opacity="0.9"
            id="Caminho_2306" data-name="Caminho 2306" d="M504.9,395.756a52.02,52.02,0,1,0,52.02,52.02A52.083,52.083,0,0,0,504.9,395.756Zm0,67.32a15.3,15.3,0,1,1,15.3-15.3,15.314,15.314,0,0,1-15.3,15.3Z"
          />
          <path
            fill="${color || '#4B0082'}"
            fill-opacity="0.9"
            id="Caminho_2307" data-name="Caminho 2307" d="M499.918,179.518H410.04a12.241,12.241,0,0,0-12.24,12.24v238.68a12.241,12.241,0,0,0,12.24,12.24h12.981A12.2,12.2,0,0,0,435.23,432.3c4.8-36.806,34.162-59.633,69.676-59.633S569.778,395.5,574.582,432.3a12.194,12.194,0,0,0,12.209,10.373h12.968A12.245,12.245,0,0,0,612,430.438V311.1a12.218,12.218,0,0,0-2.852-7.852L509.3,183.906a12.246,12.246,0,0,0-9.387-4.388Zm-77.638,97.92v-61.2A12.241,12.241,0,0,1,434.52,204h53.917a12.242,12.242,0,0,1,9.4,4.406l51,61.2a12.24,12.24,0,0,1-9.406,20.074H434.52a12.236,12.236,0,0,1-12.24-12.24ZM12.24,442.684H43.581A12.2,12.2,0,0,0,55.79,432.311c4.8-36.806,34.162-59.633,69.676-59.633s64.872,22.828,69.676,59.633a12.194,12.194,0,0,0,12.209,10.373H361.08a12.244,12.244,0,0,0,12.24-12.24v-306a12.245,12.245,0,0,0-12.24-12.24H12.24A12.241,12.241,0,0,0,0,124.444v306A12.24,12.24,0,0,0,12.24,442.684Z"
          />
          <path
            fill="${color || '#4B0082'}"
            fill-opacity="0.9"
            id="Caminho_2308" data-name="Caminho 2308" d="M125.46,395.756a52.02,52.02,0,1,0,52.02,52.02,52.083,52.083,0,0,0-52.02-52.02Zm0,67.32a15.3,15.3,0,1,1,15.3-15.3,15.314,15.314,0,0,1-15.3,15.3Z"
          />
        </g>
      </svg>
    `;
  };

  const generateIconEvent = (modality, color) => {
    switch (modality) {
      case 'MOVING':
        return `<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.15 503.47">
          <defs>
            <style>
              .cls-1 {
                fill: ${color};
                stroke-width: 0px;
              }
            </style>
          </defs>
          <path class="cls-1" d="m391.07,190.12C388.06,82.15,298.09-2.93,190.12.08,85.65,3.9,2.28,88.52.02,193.04c-.65,42.88,12.99,84.76,38.78,119.02,3.61,5.33,96.14,134.13,126.99,176.83,6.61,9.1,17.15,14.51,28.39,14.58h.17c11.19-.01,21.71-5.33,28.36-14.33,14.3-19.47,125.93-172.12,128.9-175.78l.49-.65c25.16-33.89,38.83-74.95,39.01-117.16h.04c0-1.81-.03-3.62-.08-5.43Zm-148.83,5.22c-.15.05-.3.1-.44.16h-.01c-.28.13-.54.27-.79.44-.26.17-.49.36-.7.58-.11.11-.21.22-.31.33-.68.81-1.09,1.86-1.09,3.01v129.2c0,1.36-.34,2.64-.95,3.76-.37.67-.83,1.28-1.36,1.81-.27.28-.55.53-.86.74-.37.29-.78.54-1.2.75-.14.07-.29.14-.43.2-.95.4-1.98.62-3.07.62h-70.91c-4.35,0-7.88-3.53-7.88-7.88v-129.2c0-2.61-2.12-4.73-4.73-4.73h-29.35c-6.73,0-10.36-7.9-5.98-13.01l77.41-90.31c.2-.23.41-.45.63-.64.21-.2.44-.38.67-.56.23-.17.46-.32.7-.47h0c.37-.21.74-.39,1.13-.55h.01c.13-.05.26-.09.4-.14,1.58-.51,3.3-.51,4.88,0,1.33.43,2.56,1.22,3.54,2.37l77.41,90.31c.49.58.88,1.19,1.18,1.82.11.21.2.42.27.64,1.76,4.85-1.72,10.54-7.44,10.54h-29.35c-.49,0-.95.08-1.39.21Z"/>
        </svg>`;

      case 'PAUSED':
        return `<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.15 503.47">
          <defs>
            <style>
              .cls-1 {
                fill: ${color};
                stroke-width: 0px;
              }
            </style>
          </defs>
          <path class="cls-1" d="m391.07,190.12C388.06,82.15,298.09-2.93,190.12.08,85.65,3.9,2.28,88.52.02,193.04c-.65,42.88,12.99,84.76,38.78,119.02,3.61,5.33,96.14,134.13,126.99,176.83,6.61,9.1,17.15,14.51,28.39,14.58h.17c11.19-.01,21.71-5.33,28.36-14.33,14.3-19.47,125.93-172.12,128.9-175.78l.49-.65c25.16-33.89,38.83-74.95,39.01-117.16h.04c0-1.81-.03-3.62-.08-5.43Zm-68.66,45.76c0,8.76-7.1,15.86-15.86,15.86H84.61c-8.76,0-15.86-7.1-15.86-15.86h.03l-.04-.02v-47.56c0-8.76,7.1-15.86,15.86-15.86h221.94v.02s.01-.02.01-.02h.03c8.76.01,15.84,7.12,15.83,15.88v47.56Z"/>
        </svg>`;

      case 'COLETA':
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="138.291" viewBox="0 0 138.291 178">
          <defs>
            <clipPath id="clip-path">
              <rect id="Retângulo_1824" data-name="Retângulo 1824" width="62.592" height="62.6" fill="none"/>
            </clipPath>
          </defs>
          <g id="Grupo_4802" data-name="Grupo 4802" transform="translate(-9788 -1556)">
            <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(9788.002 1555.997)" fill="${color}"/>
            <g id="Grupo_4798" data-name="Grupo 4798" transform="matrix(0.875, 0.485, -0.485, 0.875, 9845.349, 1589)">
              <g id="Grupo_4797" data-name="Grupo 4797" transform="translate(0)" clip-path="url(#clip-path)">
                <path id="Caminho_2360" data-name="Caminho 2360" d="M20.881,119.91l4.887,1.815a1.079,1.079,0,0,0,1.317-1.317l-1.815-4.887a1.117,1.117,0,0,0-.294-.512L4.606,94.641,0,99.247l20.369,20.369a1.117,1.117,0,0,0,.512.294" transform="translate(35.472 -59.161)" fill="#fff"/>
                <path id="Caminho_2361" data-name="Caminho 2361" d="M67.451,54.967C64.214,45.59,50.558,31.932,50.558,31.932L64.38,18.11S78.038,31.77,87.415,35Z" transform="translate(-43.777 -11.321)" fill="#e7e5e8"/>
                <path id="Caminho_2362" data-name="Caminho 2362" d="M50.563,23.363A36.3,36.3,0,0,0,64.1,37.77a35.263,35.263,0,0,0,8.364,3.615L78.85,35c-9.378-3.227-23.031-16.89-23.031-16.89Z" transform="translate(-35.214 -11.319)" fill="#fff"/>
                <path id="Caminho_2363" data-name="Caminho 2363" d="M123.729.632,106.817,17.544a2.158,2.158,0,0,0,0,3.053l1.554,1.554a2.158,2.158,0,0,0,3.052,0L128.336,5.239a2.159,2.159,0,0,0,0-3.053L126.781.632a2.159,2.159,0,0,0-3.052,0" transform="translate(-106.185 0)" fill="#fff"/>
                <path id="Caminho_2364" data-name="Caminho 2364" d="M54.611,51.833,26.949,79.494a2.159,2.159,0,0,0,0,3.052L28.5,84.1a2.158,2.158,0,0,0,3.052,0L59.218,56.44a2.159,2.159,0,0,0,0-3.053l-1.555-1.554a2.159,2.159,0,0,0-3.052,0" transform="translate(-7.124 -32.006)" fill="#fff"/>
                <path id="Caminho_2365" data-name="Caminho 2365" d="M138.811,14.351,126.647,26.515a2.159,2.159,0,0,0,0,3.052l1.555,1.555a2.159,2.159,0,0,0,3.052,0l10.11-10.107s-2.288-4.907-2.553-6.664" transform="translate(-126.015 -8.971)" fill="#e7e5e8"/>
              </g>
            </g>
          </g>
        </svg>`;

      case 'ENTREGA':
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="138.291" viewBox="0 0 138.291 178">
          <defs>
            <clipPath id="clip-path">
              <rect id="Retângulo_1825" data-name="Retângulo 1825" width="69.343" height="73.867" fill="none"/>
            </clipPath>
          </defs>
          <g id="Grupo_4805" data-name="Grupo 4805" transform="translate(-9561 -1532)">
            <g id="Grupo_4801" data-name="Grupo 4801" transform="translate(-45 -24)">
              <path id="local_icone-2" data-name="local icone-2" d="M138.275,69.138a70,70,0,0,1-13.792,41.422l-.173.23c-1.05,1.294-40.517,55.263-45.572,62.147A12.482,12.482,0,0,1,68.711,178h-.06a12.5,12.5,0,0,1-10.037-5.155c-10.907-15.1-43.621-60.634-44.9-62.518A68.247,68.247,0,0,1,.006,68.251a69.144,69.144,0,0,1,138.255-1.032c.018.64.028,1.28.028,1.92h-.014Z" transform="translate(9606.002 1555.997)" fill="${color}"/>
            </g>
            <g id="Grupo_4804" data-name="Grupo 4804" transform="translate(9595.475 1571.133)">
              <g id="Grupo_4803" data-name="Grupo 4803" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Caminho_2366" data-name="Caminho 2366" d="M71.563,17.166s-1.78,30.349-1.8,32.239c-.014,1.8-1.006,1.16-1.393.856a3.824,3.824,0,0,0-.485-.347c-4.236-2.548-11.074-2.25-15.683-1.55a31.453,31.453,0,0,0-7.057,1.974c-4.932,1.981-15.092,6.723-23.1,5.3-6.515-1.16-10.547-6.5-13.037-12.581,0,0-.021-.056-.063-.163-.668-1.727-5.959-16.686,8.914-24.7a20.526,20.526,0,0,0,6.951.821c.584-.014,1.161-.063,1.745-.127.387-.042.767-.085,1.154-.148a35.683,35.683,0,0,0,3.574-.715c1.78-.446,3.567-1.019,5.347-1.641s3.56-1.309,5.34-1.981c2.674-1.012,5.347-2.01,8.021-2.774.894-.255,1.787-.481,2.674-.679.239-.05.478-.092.718-.142.274-.056.549-.113.823-.163.232-.042.464-.078.7-.113.077-.014.148-.028.225-.035.035-.007.07-.007.106-.014.366-.057.725-.085,1.091-.134a11.96,11.96,0,0,1,1.745-.127H58.1c.436-.022.879-.022,1.316-.007A20.056,20.056,0,0,1,69.142,13a4.852,4.852,0,0,1,2.42,4.168" transform="translate(-2.22 -3.024)" fill="#fff"/>
                <path id="Caminho_2367" data-name="Caminho 2367" d="M69.343,13.666l-.272,37.622a1.083,1.083,0,0,1-.189.619,1.163,1.163,0,0,1-.922.542h-.007a.54.54,0,0,1-.112-.014,1.415,1.415,0,0,1-.167-.034,1.028,1.028,0,0,1-.126-.049.893.893,0,0,1-.182-.091A19.992,19.992,0,0,0,57.7,49.195c-.216-.014-.433-.02-.649-.027-.44-.007-.887-.007-1.333.007a28.764,28.764,0,0,0-5.4.7l-.112.021c-7.037,1.544-14.067,5.154-21.105,6.941-.034.007-.077.021-.111.028a31.454,31.454,0,0,1-6.41.974c-.53.021-1.061.028-1.6.007a20.509,20.509,0,0,1-11.9-4.313v17.3a3.047,3.047,0,0,1-6.094,0V11.209A6,6,0,0,1,.031,5.4a6.042,6.042,0,0,1,12.049.619,6,6,0,0,1-3,5.189v.585c.154.118.314.229.468.326A20.811,20.811,0,0,0,14.86,14.9c.077.034.146.056.223.084.1.034.189.063.265.09a.405.405,0,0,0,.112.035.983.983,0,0,0,.1.034.931.931,0,0,0,.139.042.883.883,0,0,0,.084.028C1.022,23.09,6.273,37.795,6.935,39.492c.042.1.063.16.063.16C9.47,45.627,13.47,50.878,19.935,52.019c7.945,1.4,18.026-3.262,22.92-5.21a31.423,31.423,0,0,1,7-1.94c4.573-.689,11.358-.981,15.562,1.523a3.859,3.859,0,0,1,.482.341c.383.3,1.368.925,1.382-.841.021-1.857,2.06-32.224,2.06-32.224" transform="translate(0 0)" fill="#e7e5e8"/>
              </g>
            </g>
          </g>
        </svg>`;

      default:
        return `<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.15 503.47">
            <defs>
              <style>
                .cls-1 {
                  fill: ${color};
                  stroke-width: 0px;
                }
              </style>
            </defs>
            <path class="cls-1" d="m391.07,190.12C388.06,82.15,298.09-2.93,190.12.08,85.65,3.9,2.28,88.52.02,193.04c-.65,42.88,12.99,84.76,38.78,119.02,3.61,5.33,96.14,134.13,126.99,176.83,6.61,9.1,17.15,14.51,28.39,14.58h.17c11.19-.01,21.71-5.33,28.36-14.33,14.3-19.47,125.93-172.12,128.9-175.78l.49-.65c25.16-33.89,38.83-74.95,39.01-117.16h.04c0-1.81-.03-3.62-.08-5.43Zm-157.7,124.31c-.74.24-1.53.36-2.35.36h-70.9c-4.35,0-7.88-3.53-7.88-7.88v-118.53c0-5.9,4.78-10.68,10.68-10.68h65.71c5.67,0,10.27,4.6,10.27,10.27v118.94c0,.81-.12,1.6-.35,2.34-.77,2.46-2.71,4.41-5.18,5.18Zm5.54-161.39c0,5.3-4.3,9.59-9.59,9.59h-67.49c-5.3,0-9.59-4.3-9.59-9.59v-45.92c0-5.3,4.3-9.59,9.59-9.59h57.07s10.13.01,10.13.01c5.45,0,9.88,4.42,9.88,9.88v45.62Z"/>
          </svg>`;
    }
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
  };

  const CardInfo = ({ title, value, viewport, bgColor = 'primary' }) => (
    <Flex
      top="10px"
      left="10px"
      zIndex="10"
      bgColor={bgColor}
      p='5px 10px'
      pointerEvents="none"
      borderRadius="5px"
      direction="column"
      gap="5px">
      <Text color="white" fontSize={viewport > '900' ? '12px' : '11px'}>
        {title}
      </Text>

      <Text color="white" fontSize={viewport > '900' ? '20px' : '14px'}>
        {value}
      </Text>
    </Flex>
  );

  useEffect(() => {
    let markers = [];

    let countLabel = -1;
    let previous = null;
    let current = null;

    for (let k in entity.trajectories) {
      let item = entity.trajectories[k];

      if (
        item.latitude == '0' ||
        item.longitude == '0' ||
        item.latitude == '' ||
        item.longitude == '' ||
        item.latitude === null ||
        item.longitude === null
      ) {
        continue;
      }

      current = {
        lat: item.latitude,
        lng: item.longitude,
      };

      if (previous && current.lat === previous.lat && current.lng === previous.lng) {
        continue;
      }
      countLabel++;

      let color = '#f00';
      if (item.type === 'COLETA') {
        color = '#2ecc71';
      }

      markers.push(createTrajectoryAddressMarker(item, entity, tripLabelsString, countLabel));

      previous = current;
    }

    setTrajectoryMarkers(markers);
  }, [entity]);

  useEffect(() => {
    let markers = [];
    let processed = [];

    events?.map((item) => {
      if (item?.latitude === null || item?.latitude === undefined || item?.longitude === null || item?.longitude === undefined) {
        return;
      }

      markers.push(createEventMarker(item, entity, processed));
    });

    setEventMarkers(markers);
  }, [events]);

  useEffect(() => {
    if (checkpoints && checkpoints?.length > 0) {
      let markers = [];

      const distance = checkpoints.length < 1000 ? 1 : 3;
      const checkpointsReduced = reduceCheckpointListByDistance(checkpoints, distance);

      checkpointsReduced?.map((item, key) => {
        if (item?.latitude === null || item?.longitude === null || item?.latitude.length === 0 || item?.longitude.length === 0) {
          return false;
        }

        markers.push(createCheckpointMarker(item, key, entity));
      });

      setCheckpointMarkers(markers);

      setLastCheckpointMarker(markers[markers.length - 1]);
    } else {
      setLastCheckpointMarker({ lat: currentDriverPosition?.latitude, lng: currentDriverPosition?.longitude });
    }

    if (map) {
      loadTrajectory();
    }
  }, [map, checkpoints]);

  return (
    <Card w={w} h={h}>
      <Box w="full" h="full" position="relative">
        {viewport > '600' && (
          <Flex
            position="absolute"
            top="0px"
            left="0px"
            zIndex="10"
            p="10px 15px"
            pointerEvents="none"
            borderRadius="5px"
            direction={viewport < '1600' ? 'column' : 'row'}
            gap="10px">
            <CardInfo title="Status atual" value={descriptionStatusTrip(entity.status)} viewport={viewport} bgColor="linkColor" />

            {additional?.speedCurrent > 0 && (
              <CardInfo title="Velocidade atual" value={`${additional?.speedCurrent} km/h`} viewport={viewport} />
            )}

            {additional?.speedAverage > 0 && (
              <CardInfo title="Velocidade média" value={`${additional?.speedAverage} km/h`} viewport={viewport} />
            )}

            {additional?.speedMaximum > 0 && (
              <CardInfo title="Velocidade máxima" value={`${additional?.speedMaximum} km/h`} viewport={viewport} />
            )}

            {additional?.travelledDistance > 0 && (
              <CardInfo title="Distância Percorrida" value={`${additional?.travelledDistance} km`} viewport={viewport} />
            )}
          </Flex>
        )}

        {isLoaded && typeof google !== 'undefined' && google && (
          <ScreenLoader
            isLoading={
              !(
                isLoadingEntity === false &&
                isLoadingEvents === false &&
                isLoadingCheckpoints === false &&
                isLoadingTrajectory === false
              )
            }>
            <GoogleMap
              onLoad={(map) => {
                setMap(map);
              }}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onZoomChanged={() => {
                if (mapRef.current) {
                  const zoom = mapRef.current.getZoom();
                  const center = mapRef.current.getCenter();
                  setCurrentZoom(zoom);

                  setCenter({
                    lat: center.lat(),
                    lng: center.lng(),
                    zoom,
                  });
                }
              }}
              options={{
                mapId: '6d2819c49ba264e7',
                minZoom: 2,
                zoomControl: true,
                streetViewControl: false,
                scaleControl: false,
                gestureHandling: true,
                mapTypeControl: true,
                fullscreenControl: true,
                fullscreenControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
              }}>
              {trajectoryMarkers.map((point, index) => {
                return (
                  <Marker
                    key={index}
                    position={point}
                    icon={point?.icon}
                    label={point?.label}
                    title={point?.title}
                    optimized={false}
                    onClick={() => {
                      handleMarkerClick(point);
                    }}>
                    {selectedMarker === point && (
                      <MapPopup key={index} point={selectedMarker} onCloseClick={() => setSelectedMarker(null)} />
                    )}
                  </Marker>
                );
              })}

              {lastCheckpointMarker !== null && entity?.status == 'TRAVELLING' && (
                <Marker
                  cursor={type ? 'default' : 'pointer'}
                  key={'current-point'}
                  position={lastCheckpointMarker}
                  label={lastCheckpointMarker?.label}
                  title={lastCheckpointMarker?.title}
                  zIndex={200}
                  icon={{
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getColoredTruckSvg()),
                    scaledSize: { width: 37, height: 37 },
                  }}
                  onClick={() => {
                    !type && handleMarkerClick(lastCheckpointMarker);
                  }}>
                  {selectedMarker === lastCheckpointMarker && (
                    <MapPopup key={'current-point-popup'} point={selectedMarker} onCloseClick={() => setSelectedMarker(null)} />
                  )}
                </Marker>
              )}

              {eventMarkers.length > 0 && (
                <MarkerClusterer
                  title="Clique para ver de perto"
                  gridSize={20}
                  maxZoom={15}
                  minimumClusterSize={2}
                  zoomOnClick={true}
                  styles={[
                    {
                      url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m4.png',
                      textColor: '#fff',
                      height: 53,
                      width: 53,
                    },
                  ]}>
                  {(clusterer) =>
                    eventMarkers.map((point, index) => (
                      <Marker
                        key={index}
                        position={point}
                        icon={point?.icon}
                        label={point?.label}
                        title={point?.title}
                        clusterer={clusterer}
                        onClick={() => {
                          handleMarkerClick(point);
                        }}>
                        {selectedMarker === point && (
                          <MapPopup key={index} point={selectedMarker} onCloseClick={() => setSelectedMarker(null)} />
                        )}
                      </Marker>
                    ))
                  }
                </MarkerClusterer>
              )}

              {trajectory.map((trajectory, trajectoryKey) => {
                let polylineOptions = {
                  strokeColor: '#6C48C2',
                  strokeWeight: 4,
                };

                if (showDirection) {
                  polylineOptions.strokeOpacity = 0;
                  polylineOptions.icons = [
                    {
                      icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        strokeOpacity: 1,
                        scale: 2,
                      },
                      offset: '0',
                      repeat: '15px',
                    },
                  ];
                } else {
                  if (trajectory.status !== 'COMPLETED') {
                    polylineOptions.strokeOpacity = 0;
                    polylineOptions.icons = [
                      {
                        icon: {
                          path: 'M 0,-1 0,1',
                          strokeOpacity: 1,
                          scale: 2,
                        },
                        offset: '0',
                        repeat: '15px',
                      },
                    ];
                  }
                }

                return (
                  <DirectionsRenderer
                    key={`directions-${trajectoryKey}-${showDirection}`}
                    directions={trajectory.directions}
                    options={{
                      preserveViewport: true,
                      suppressMarkers: true,
                      polylineOptions: polylineOptions,
                    }}
                  />
                );
              })}

              {showCheckpoints &&
                checkpointMarkers &&
                checkpointMarkers.length > 0 &&
                checkpointMarkers.map((point, index) => {
                  return (
                    <Marker
                      key={index}
                      position={point}
                      icon={point?.icon}
                      label={point?.label}
                      title={point?.title}
                      optimized={false}
                      onClick={() => {
                        handleMarkerClick(point);
                      }}>
                      {selectedMarker === point && (
                        <MapPopup key={index} point={selectedMarker} onCloseClick={() => setSelectedMarker(null)} />
                      )}
                    </Marker>
                  );
                })}
            </GoogleMap>
          </ScreenLoader>
        )}
      </Box>

      <ScreenLoader h="auto" isLoading={isLoadingCheckpoints}>
        <Table w="fit-content" m="5px" variant="outline">
          <Tbody>
            {checkpoints && checkpoints.length > 0 && (
              <>
                <Tr>
                  <Td p="5px">
                    <Text color="primary" fontSize="12px" display={'flex'} gap={'10px'}>
                      Mostrar todos os dados de posição do veículo?
                    </Text>
                  </Td>

                  <Td p="5px">
                    <Switch
                      size="md"
                      colorScheme="teal"
                      onChange={() => {
                        setShowCheckpoints(!showCheckpoints);
                      }}
                    />
                  </Td>
                </Tr>

                {profileRole === 'ROLE_SYSTEM_ADMIN' && (
                  <Tr>
                    <Td p="5px">
                      <Text color="primary" fontSize="12px" display={'flex'} gap={'10px'}>
                        Exibir informações avançadas no mapa?
                      </Text>
                    </Td>

                    <Td p="5px">
                      <Switch
                        size="md"
                        colorScheme="teal"
                        onChange={() => {
                          setShowDirection(!showDirection);
                        }}
                      />
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
        </Table>
      </ScreenLoader>
    </Card>
  );
};

export default MapTrajectory;
