import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Text } from '@chakra-ui/react';

import iconContract from '../../../assets/images/contract_management.svg';
import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsContractManagement } from '../../../utils/filters/filterPresets';

import ContractManagementCard from './components/ContractManagementCard';
import ContractManagementChangeStatus from './components/ContractManagementChangeStatus';
import ContractManagementTable from './components/ContractManagementTable';
import DocumentModalView from './components/DocumentModalView';

const ModuleContractManagementPage = () => {
  const hasPermission = permissions.vendemmiaContractManagement;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [currentFileName, setCurrentFileName] = useState(null);

  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [isOpenModalStatusChange, setIsOpenModalStatusChange] = useState(false);

  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentModality, setCurrentModality] = useState(null);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listContracts(filters, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const handleView = (identifier, fileName, d4SignFilePathSigned) => {
    const modality = d4SignFilePathSigned != null && d4SignFilePathSigned !== undefined ? 'signed' : 'unsigned';
    setCurrentIdentifier(identifier);
    setCurrentFileName(fileName);
    setCurrentModality(modality);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const handleCloseModalFile = () => {
    setCurrentIdentifier(null);
    setCurrentFileName(null);
    setCurrentModality(null);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const handleCloseModalChangeStatus = () => {
    setCurrentIdentifier(null);
    setCurrentStatus(null);
    setCurrentModality(null);
    setIsOpenModalStatusChange(!isOpenModalStatusChange);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsContractManagement());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="contract-management"
      title="Gestão de contratos"
      breadcrumbIcon={iconContract}
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Título ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar novo contrato',
          text: 'Cadastro de novo contrato',
          action: (
            <Link to="/vendemmia/contract-management/new" key={2}>
              <Button
                leftIcon={<MdAdd size={20} color="#ffffff" />}
                _hover={{ bgColor: '#70D499' }}
                bg="green"
                p="1.5rem"
                pb="1.5rem"
                pl="1.3rem"
                pr="1.3rem"
                borderRadius="50px">
                <Text mr="10px" color="white">
                  Novo contrato
                </Text>
              </Button>
            </Link>
          ),
          modality: 'custom',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Gestão de contratos
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.item_count} contratos
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            {!isMobile ? (
              <ContractManagementTable
                list={list}
                action={forceLoadTrigger}
                setAction={setForceLoadTrigger}
                metadata={metadata}
                setMetadata={setMetadata}
                handleView={handleView}
                handleCurrentIdentifier={setCurrentIdentifier}
                handleCurrentStatus={setCurrentStatus}
                handleCurrentModality={setCurrentModality}
                handleOpenModalStatus={setIsOpenModalStatusChange}
              />
            ) : (
              <ContractManagementCard
                list={list}
                action={forceLoadTrigger}
                setAction={setForceLoadTrigger}
                metadata={metadata}
                setMetadata={setMetadata}
                handleView={handleView}
                handleCurrentIdentifier={setCurrentIdentifier}
                handleCurrentStatus={setCurrentStatus}
                handleCurrentModality={setCurrentModality}
                handleOpenModalStatus={setIsOpenModalStatusChange}
              />
            )}
          </ScreenLoader>

          <DocumentModalView
            isOpen={isOpenModalViewFile}
            identifier={currentIdentifier}
            fileName={currentFileName}
            modality={currentModality}
            onClose={handleCloseModalFile}
          />

          <ContractManagementChangeStatus
            isOpen={isOpenModalStatusChange}
            identifier={currentIdentifier}
            status={currentStatus}
            modality={currentModality}
            onClose={handleCloseModalChangeStatus}
            action={forceLoadTrigger}
            setAction={setForceLoadTrigger}
          />
        </Box>
      </Card>
    </Page>
  );
};

export default ModuleContractManagementPage;
