import React, { useState } from 'react';

import { MdOutlineFileUpload } from 'react-icons/md';
import { toast } from 'react-toastify';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../../services/requests';

const ModalPublishTerm = ({ entity, onSave }) => {
  const [load, setLoad] = useState(false);

  const onSubmit = async () => {
    setLoad(true);

    try {
      await requests.publishTermUse(entity.identifier, { status: 'PUBLISH' });

      onSave();
      setLoad(false);

      toast.success('Tópico publicado com sucesso');
    } catch (error) {
      setLoad(false);

      toast.error('Erro ao publicar o tópico, tente novamente!');
    }

    setLoad(false);
  };
  return (
    <DefaultModal
      enabled={entity.status === 'DRAFT'}
      label="Publicar"
      ctaButton={<MdOutlineFileUpload size={25} color="#422C76" />}
      title="Certeza que deseja publicar?"
      subtitle="Todos os demais tópicos publicados ficaram como obsoleto"
      loading={load}
      buttonColor="green"
      txtButton="Confirmar"
      callback={async (decision) => {
        if (decision) {
          await onSubmit();
        }
      }}
    />
  );
};

export default ModalPublishTerm;
