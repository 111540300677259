import React from "react";

import { NumericFormat } from 'react-number-format';

export const InputPercentage = ({ name, id, value, setFieldValue, placeholder, limitInput = 100, isDisabled}) => {
    return (
        <NumericFormat
            isAllowed={values => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || floatValue <= 100;
            }}
            className="custom-input-mask"
            name={name}
            id={id}
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            value={value}
            decimalSeparator=","
            suffix="%"
            displayType="input"
            type="text"
            disabled={isDisabled}
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={values => {
                const { formattedValue, floatValue } = values;
                setFieldValue(name, formattedValue);
            }}
            placeholder={placeholder}
        />
    );
};
