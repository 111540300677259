import React from 'react';

import moment from 'moment';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Button, Flex, IconButton, Image, Text } from '@chakra-ui/react';

import iconAgenda from '../../../../assets/images/iconAgenda.svg';
import iconKanban from '../../../../assets/images/iconKanban.svg';

const HeaderCustomCard = ({
  period,
  isLoading,
  selectedTab,
  selectedAgendaTab,
  setSelectedAgendaTab,
  handleTabChange,
  isSystemAdmin,
  handlePrevious,
  handleNext,
  setForceSetSearchPeriod,
}) => {
  const optionsSchedule = [
    { label: 'Dia', value: 'day' },
    { label: 'Semana', value: 'week' },
    { label: 'Mês', value: 'month' },
  ];

  const tabsOptions = [
    ...(isSystemAdmin === 'ROLE_SYSTEM_ADMIN' ? [{ label: 'Agenda', icon: iconAgenda }] : []),
    ...(isSystemAdmin === 'ROLE_SYSTEM_ADMIN' ? [{ label: 'Kanban', icon: iconKanban }] : []),
    // { label: 'Circuito', icon: iconCircuit },
    // { label: 'Pátio', icon: iconPatio },
  ];

  const formattedDate = () => {
    switch (selectedAgendaTab) {
      case 'day':
        return moment(period.start).format('DD/MM/Y');

      case 'month':
        return moment(period.start).format('MMMM/YYYY').toUpperCase();

      default:
        return `${moment(period.start).format('DD/MM/Y')} até ${moment(period.end).format('DD/MM/Y')}`;
    }
  };

  const CustomButton = ({ isSelected, onClick, children, width = '120px' }) => {
    return (
      <Button
        h="full"
        bg={isSelected ? '#F2E9FFAB' : 'transparent'}
        w={width}
        borderRadius="0px"
        color="primary"
        _hover={{ bgColor: '#F2E9FFAB' }}
        onClick={onClick}>
        <Flex w="full" align="center" justify="center" gap="10px">
          {children}
        </Flex>
      </Button>
    );
  };

  return (
    <Flex h="54px" w="full" align="center" justify="flex-end" gap="20px">
      {selectedTab === 'Agenda' && (
        <>
          <Flex h="full">
            {selectedTab === 'Agenda' && (
              <Flex w="fit-content" borderRadius="8px" shadow="lg" className="up-anim">
                {optionsSchedule.map((option, index) => (
                  <CustomButton
                    key={index}
                    isSelected={selectedAgendaTab === option.value}
                    onClick={() => {
                      setForceSetSearchPeriod({
                        startsAt: '',
                      });

                      setSelectedAgendaTab(option.value);
                    }}
                    width="100px">
                    <p fontWeight="bold">{option.label}</p>
                  </CustomButton>
                ))}
              </Flex>
            )}
          </Flex>

          <Flex h="full" w="full" align="center" justify="space-between" className="up-anim">
            <IconButton
              h="full"
              bgColor="#6C48C2"
              borderRadius="0px"
              _hover={{ bgColor: '#6C48C2', opacity: 0.8 }}
              onClick={handlePrevious}
              isLoading={isLoading}
              isDisabled={isLoading}
              icon={<MdKeyboardArrowLeft color="white" size={30} />}
            />

            <Flex h="full" w="full" align="center" justify="center" border="2px solid #6C48C2">
              <Text fontSize="20px" textColor="#6C48C2" fontWeight="bold">
                {formattedDate()}
              </Text>
            </Flex>

            <IconButton
              h="full"
              bgColor="#6C48C2"
              borderRadius="0px"
              _hover={{ bgColor: '#6C48C2', opacity: 0.8 }}
              onClick={handleNext}
              isLoading={isLoading}
              isDisabled={isLoading}
              icon={<MdKeyboardArrowRight color="white" size={30} />}
            />
          </Flex>
        </>
      )}

      <Flex h="full" justify="flex-end">
        {isSystemAdmin === 'ROLE_SYSTEM_ADMIN' && (
          <Flex w="full" borderRadius="8px" shadow="lg" className="up-anim">
            {tabsOptions.map((tab, index) => (
              <CustomButton key={index} isSelected={selectedTab === tab.label} onClick={() => handleTabChange(tab)}>
                <Image src={tab.icon} />

                <p fontWeight="bold">{tab.label}</p>
              </CustomButton>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default HeaderCustomCard;
