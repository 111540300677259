import React, { useEffect, useRef, useState } from 'react';

import { MdClose } from 'react-icons/md';

import {
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import Page from '../../components/Page';
import permissions from '../../services/permissions';
import requests from '../../services/requests';
import FilterOption from '../../utils/filters/FilterOption';
import { filterOptionsDashboard } from '../../utils/filters/filterPresets';

import DashboardCharts from './components/DashboardCharts';
import DashboardMap from './components/DashboardMap';
import DashboardUpdates from './components/DashboardUpdates';
import DashboardWeeklySchedule from './components/DashboardWeeklySchedule';
import PriorityProcess from './components/PriorityProcess';

const DashboardPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const request = useRef(0);

  const [isLoading, setIsLoading] = useState(true);

  const [filtersState, setFiltersState] = useState();

  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);

  const [updatesList, setUpdatesList] = useState();
  const [commentsList, setCommentsList] = useState();
  const [favouriteList, setFavouriteList] = useState();

  const [processByChannel, setProcessByChannel] = useState([]);
  const [processPerStep, setProcessByStage] = useState([]);
  const [carbonFootprint, setCarbonFootprint] = useState();

  const [calendarData, setCalendarData] = useState([]);

  const [priorityProcess, setPriorityProcess] = useState([]);

  const [popup, setPopup] = useState([]);
  const [loadPopup, setLoadPopup] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);
  const [filtersCharts, setFiltersCharts] = useState([]);

  const [metaUpdate, setMetaUpdate] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [metaComment, setMetaComment] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [metaFavourite, setMetaFavourite] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsDashboard());
  };

  const getPopup = () => {
    requests
      .getPopup()
      .then((res) => {
        setPopup(res);
      })
      .catch((err) => {});
  };

  const onClosePopup = (identifier) => {
    setLoadPopup(true);

    requests
      .postPopupRead(identifier)
      .then(() => {
        onClose();

        setLoadPopup(false);
      })
      .catch((err) => {
        setLoadPopup(false);
      });

    setLoadPopup(false);
  };

  function convertJSONByChannel(jsonData) {
    const colors = ['#70D499', '#F3D783', '#EC5D66', '#696969', '#D6D6D6'];
    const convertedData = jsonData.map((item, index) => {
      return {
        name: item.channel,
        value: parseInt(item.qtt, 10),
        color: colors[index] || '#D6D6D6',
      };
    });

    return convertedData;
  }

  function convertJSONPerStep(jsonData) {
    const colors = ['#67B7DC', '#6794DC', '#8067DC', '#A367DC', '#C767DC', '#70D499'];
    const convertedData = jsonData.map((item, index) => {
      return {
        name: item.stage ? item.stage : item.channel,
        value: parseInt(item.qtt, 10),
        color: colors[index] || '#D6D6D6',
      };
    });
    return convertedData;
  }

  const getPage = (page) => (page > 0 ? page : 1);

  const load = async (filters, key) => {
    setIsLoading(true);
    setFiltersState(filters);

    const calendarFilters = { ...filters };
    delete calendarFilters.period;

    const requestsPromises = [
      //api do mapa
      requests.mapPositions(null, filters),

      //api das atualizações
      requests.lastUpdates(filters, getPage(metaUpdate.current_page), 10),
      requests.lastComments(filters, getPage(metaComment.current_page), 10),
      requests.favouriteComments(filters, getPage(metaFavourite.current_page), 10),

      //api dos gráficos
      requests.dashboardProcessByChannel(filters),
      requests.dashboardProcessByStage(filters),
      requests.carbonFootprint(filters),

      //api do calendário
      requests.calendar('week', 'event', calendarFilters),

      //api da lista de favoritos
      requests.listProcessPriority(filters),
    ];

    try {
      const [
        positionsData,
        updatesData,
        commentsData,
        favouriteData,
        processByChannel,
        processPerStep,
        carbonFootprint,
        calendarData,
        priorityProcess,
      ] = await Promise.all(requestsPromises);

      if (request.current && request.current > key) {
        return;
      }

      request.current = key;
      //set do mapa
      setPoints(positionsData.points);
      setLines(positionsData.lines);

      //set das atualizações
      setUpdatesList(updatesData.data);
      setMetaUpdate(updatesData.meta);

      setCommentsList(commentsData.data);
      setMetaComment(commentsData.meta);

      setFavouriteList(favouriteData.data);
      setMetaFavourite(favouriteData.meta);

      //set dos gráficos
      setProcessByChannel(convertJSONByChannel(processByChannel));
      setProcessByStage(convertJSONPerStep(processPerStep));
      setCarbonFootprint(carbonFootprint);

      //set do calendário
      setCalendarData(calendarData.weeks[0]);

      //set da lista de favoritos
      setPriorityProcess(priorityProcess);

      setIsLoading(false);
    } catch (err) {
      console.error('Error:', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    popup.forEach((entity) => {
      if (entity.modality === 'NEW_PANEL' && !isOpen) {
        onOpen();
      }
    });
  }, [popup]);

  useEffect(() => {
    getFilterOptions();
    getPopup();
  }, []);

  useEffect(() => {
    let aux = [];

    for (let key in filterOptions) {
      if (!filterOptions[key] instanceof FilterOption) {
        continue;
      }

      let name = filterOptions[key].name ?? filterOptions[key].value;
      let children = filterOptions[key].children ?? filterOptions[key].options;

      aux[name] = children;
    }

    setFiltersCharts(aux);
  }, [filterOptions]);

  return (
    <Page
      screen="dashboard"
      title="Visão geral"
      placeholder="Pesquisar por texto"
      filterOptions={filterOptions}
      load={load}
      showRefreshData={true}>
      {popup.map((entity) => (
        <Modal
          key={entity.id}
          isCentered
          isOpen={entity.modality === 'NEW_PANEL' && isOpen}
          onClose={onClose}
          motionPreset="slideInBottom">
          <ModalOverlay />

          <ModalContent borderRadius="9px" width="full" maxWidth={'680px'} margin={'25px'} overflowY="auto">
            <ModalHeader p="24px 24px 0 24px" display="flex" alignItems="center" justifyContent="flex-end">
              <IconButton
                bgColor="#ffffff"
                icon={<MdClose size={20} color="#00000057" />}
                onClick={() => onClosePopup(entity.identifier)}
              />
            </ModalHeader>

            <ModalBody px="35px" justifyContent="center" alignItems="center" display="flex" flexDirection="column" gap="20px">
              <Flex direction="column" w="500px">
                <Text textStyle="primaryTitle" fontWeight="medium" textColor="primary" textAlign="center" className="up-anim">
                  {entity.title}
                </Text>

                <Text
                  font="normal normal medium 20px/26px Hanken Grotesk"
                  textColor="#0F0A1D80"
                  textAlign="center"
                  className="up-anim">
                  {entity.subtitle}
                </Text>
              </Flex>

              <Flex gap="20px" align="center" justify="center">
                <Link href={entity.description} target="_blank">
                  <Button variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" _hover={{ opacity: 0.8 }}>
                    Acessar manual
                  </Button>
                </Link>

                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  isLoading={loadPopup}
                  onClick={() => onClosePopup(entity.identifier)}>
                  Acessar sistema
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      ))}

      <Flex direction="column" gap="10px" p="10px">
        {(permissions.comexMapping || permissions.comexProcess) && (
          <Grid
            columnGap="10px"
            h={{ base: 'initial', md: '500px' }}
            maxHeight={{ base: 'initial', md: '500px' }}
            gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(6, 1fr)' }}>
            <GridItem colSpan={4}>
              <DashboardMap isLoading={isLoading} points={points} lines={lines} maxH={{ base: '350px', md: '500px' }} />
            </GridItem>

            <GridItem colSpan={2}>
              <DashboardUpdates
                isLoading={isLoading}
                updatesList={updatesList}
                setUpdatesList={setUpdatesList}
                setMetaUpdate={setMetaUpdate}
                commentsList={commentsList}
                favouriteList={favouriteList}
                metaUpdate={metaUpdate}
                metaComment={metaComment}
                metaFavourite={metaFavourite}
                setCommentsList={setCommentsList}
                setMetaComment={setMetaComment}
                setFavouriteList={setFavouriteList}
                setMetaFavourite={setMetaFavourite}
                filtersState={filtersState}
              />
            </GridItem>
          </Grid>
        )}

        {(permissions.metricsComex || permissions.comexProcess) && (
          <DashboardCharts
            isLoading={isLoading}
            processByChannel={processByChannel}
            processPerStep={processPerStep}
            carbonFootprint={carbonFootprint}
            filtersCharts={filtersCharts}
          />
        )}

        {(permissions.comexAgenda || permissions.comexProcess) && (
          <DashboardWeeklySchedule isLoading={isLoading} calendarData={calendarData} />
        )}

        {permissions.comexProcess && <PriorityProcess priorityProcess={priorityProcess} />}
      </Flex>
    </Page>
  );
};

export default DashboardPage;
