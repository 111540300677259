import React from 'react';

import { Flex } from '@chakra-ui/react';

export function CommentsRoot({ children }) {
  return (
    <Flex className="down-anim" mb="20px">
      {children}
    </Flex>
  );
}
