import React, { useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdOutlineSave } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Box, Button, Flex, HStack, Input, Tag, TagCloseButton, TagLabel, Text, Textarea } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import Page from '../../../../components/Page';
import { useForceRefresh } from '../../../../hooks/useForceRefresh';
import requests from '../../../../services/requests';
import formatDocument from '../../../../utils/fields/masks/formatDocument';
import { executeRequest } from '../../../../utils/requests/executeRequest';
import { initialValues } from '../Helpers/initialValues';
import { stateOptions } from '../Helpers/stateOptions';
import { validationSchema } from '../Helpers/validationSchema';

import TaxInquiryDetailPageStyles from './TaxInquiryDetailPageStyles';
import TaxInquiryGridColumn from './TaxInquiryGridColumn';

const TaxInquiryDetailPage = () => {
  const { identifier } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [productsOptionsMessage, setProductsOptionsMessage] = useState('Nenhum resultado encontrado');
  const [entity, setEntity] = useState({});
  const [listTags, setListTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  let navigate = useNavigate();

  const branchOptions = [
    { value: 'Santa Catarina', label: 'Santa Catarina' },
    { value: 'São Paulo', label: 'São Paulo' },
    { value: 'Minas Gerais', label: 'Minas Gerais' },
    { value: 'Espírito Santo', label: 'Espírito Santo' },
    { value: 'Pernambuco', label: 'Pernambuco' },
    { value: 'Estados Unidos', label: 'Estados Unidos' },
  ];

  const icmsOptions = [
    { value: 0, label: 'Não' },
    { value: 1, label: 'Sim' },
  ];

  const typeOfSaleOptions = [
    { value: 'VENDA_A_ORDEM', label: 'Venda a ordem' },
    { value: 'VENDA_DIRETA', label: 'Venda direta' },
  ];

  const taxRegimeOptions = [
    { value: 'LUCRO_REAL', label: 'Lucro real' },
    { value: 'LUCRO_PRESUMIDO', label: 'Lucro presumido' },
    { value: 'SIMPLES_NACIONAL', label: 'Simples nacional' },
    { value: 'DIFAL', label: 'Difal' },
  ];

  const importModalityOptions = [
    { value: 'CONTA_E_ORDEM', label: 'Conta e ordem' },
    { value: 'ENCOMENDA', label: 'Encomenda' },
    { value: 'CONTA_PROPRIA', label: 'Conta própria' },
  ];

  const finalityOptions = [
    { value: 'CONSUMO', label: 'Consumo' },
    { value: 'REVENDA', label: 'Revenda' },
    { value: 'INDUSTRIALIZACAO', label: 'Industrialização' },
    { value: 'ATIVO_FIXO', label: 'Ativo Fixo' },
    { value: 'LEASING', label: 'Leasing' },
  ];

  const taxBenefitOptions = [
    { value: 'TTD_SC', label: 'TTD-SC' },
    { value: 'FUNDAP_ES', label: 'FUNDAP-ES' },
    { value: 'PRODEPE_PE', label: 'PRODEPE-PE' },
  ];

  const statusOptions = [
    { value: 'EM_ESTUDO', label: 'Em estudo' },
    { value: 'EM_VALIDACAO', label: 'Em validação' },
    { value: 'EM_USO', label: 'Em uso' },
    { value: 'OBSOLETA', label: 'Obsoleta' },
    { value: 'CANCELADA', label: 'Cancelada' },
  ];

  const load = (filters, key) => {
    loadUsers();

    /* if (identifier !== undefined) {
      getTaxInquiry(identifier);
    } */
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const getTaxInquiry = async () => {
    const data = await executeRequest({
      action: () => requests.fetchTaxQuery(identifier),
      setIsLoading,
    });

    setEntity(data);
    setListTags(data?.tags);
    setIsLoading(false);
  };

  const loadUsers = async () => {
    const res = await executeRequest({
      action: () => requests.getUsersTaxQuery(),
      setIsLoading,
    });
    const options = res.map((data) => {
      return {
        value: data.identifier,
        label: data.name,
      };
    });

    setUserOptions(options);
  };

  const loadProducts = (code = '') => {
    if (code?.length < 3) {
      setProductsOptionsMessage('Insira ao menos 4 digítos para fazer a busca');
      return;
    }

    if (code?.length > 3) {
      setProductsOptionsMessage('Carregando');

      requests
        .taxInquiryListNcmsAction({
          codeTaxInquiry: code,
          statusTaxInquiry: 'ACTIVE',
        })
        .then((response) => {
          let options = [];
          response?.data?.length &&
            response.data.forEach((ncm) => {
              options.push({
                value: ncm.code,
                label: ncm.code + ' ' + ncm.description,
              });
            });

          setProductsOptions(options);
          setProductsOptionsMessage('Nenhum resultado encontrado');
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao trazer os NCMs');
        });
    }
  };

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  const handleTagAdd = () => {
    if (tagInput.trim() !== '') {
      setListTags([...listTags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const handleTagRemove = (index) => {
    const newTags = [...listTags];
    newTags.splice(index, 1);
    setListTags(newTags);
  };

  const checkDisabled = (name) => {
    if (identifier === undefined && name !== 'status') {
      return false;
    } else if (entity?.status === 'EM_ESTUDO' || entity?.status === 'EM_VALIDACAO') {
      return false;
    }

    return true;
  };

  const showButtons = () => {
    if (identifier === undefined) {
      return true;
    } else if (entity?.status === 'EM_ESTUDO' || entity?.status === 'EM_VALIDACAO') {
      return true;
    }

    return false;
  };

  return (
    <Page
      title={entity.identifier ? `Detalhes da consulta ${entity?.code}` : 'Nova consulta'}
      breadcrumbs={[
        { link: '#', title: 'Vendemmia' },
        { link: '/vendemmia/tax-inquiry', title: 'Consulta tributária' },
      ]}
      setIsMobile={setIsMobile}
      isContentLoading={isLoading}
      hideAllFilters={true}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}
      forceLoadTrigger={forceLoadTrigger}>
      <TaxInquiryDetailPageStyles />
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues(entity)}
          validationSchema={validationSchema()}
          onSubmit={(values, { setFieldError, setSubmitting }) => {
            setSubmitting(true);

            if (listTags?.length == 0) {
              setSubmitting(false);
              setFieldError('tags', 'É necessário incluir pelo menos uma tag.');
              return;
            }

            const auxProducts = [];
            if (values?.products?.length > 0) {
              values?.products.map((item) => {
                auxProducts.push(item?.value);
              });
            }

            const auxResponsibles = [];
            if (values?.responsibles?.length > 0) {
              values?.responsibles.map((item) => {
                auxResponsibles.push(item?.value);
              });
            }
            const aux = {
              companyBranchName: values?.companyBranchName?.value,
              companyBranchDocumentNumber: values?.companyBranchDocumentNumber,
              stateCodeBilling: values?.stateCodeBilling?.value,
              clientDocumentNumber: values?.clientDocumentNumber,
              stateCodeResidenceDocumentNumber: values?.stateCodeResidenceDocumentNumber?.value,
              isIcmsTaxpayer: values?.isIcmsTaxpayer?.value,
              stateRegistration: values?.stateRegistration,
              typeOfSale: values?.typeOfSale?.value,
              taxRegime: values?.taxRegime?.value,
              importModality: values?.importModality?.value,
              finality: values?.finality?.value,
              taxBenefit: values?.taxBenefit?.value,
              requestedAt: values?.requestedAt,
              expiresAt: values?.expiresAt,
              operationObservation: values?.operationObservation,
              fiscalObservation: values?.fiscalObservation,
              tags: listTags,
              status: values?.status?.value,
              products: auxProducts,
              responsibles: auxResponsibles,
            };
            if (entity?.identifier) {
              requests
                .editTaxQuery(entity.identifier, aux)
                .then(() => {
                  toast.success(`Consulta tributária editada com sucesso`);
                  setSubmitting(false);

                  setTimeout(() => {
                    navigate('/vendemmia/tax-inquiry');
                  }, 1000);
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(`Problema ao editar consulta tributária`);
                });
            } else {
              requests
                .createTaxQuery(aux)
                .then(() => {
                  toast.success(`Consulta tributária cadastrada com sucesso`);
                  setSubmitting(false);

                  setTimeout(() => {
                    navigate('/vendemmia/tax-inquiry');
                  }, 1000);
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(`Problema ao cadastrar consulta tributária`);
                });
            }
          }}>
          {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting, errors }) => (
            <form id="form" onSubmit={handleSubmit}>
              <Card
                m="10px"
                header={
                  <Flex gap="2px" direction="column">
                    <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                      Informações de responsabilidade do solicitante
                    </Text>
                  </Flex>
                }>
                <Flex data-form-wrapper>
                  <Flex data-form-card>
                    <Text mb="4" color="secondary">
                      Importador
                    </Text>
                    <TaxInquiryGridColumn>
                      <Flex direction="column">
                        <LabelDefault name="companyBranchName" text="Filial Vendemmia" />
                        <Select
                          options={branchOptions}
                          styles={selectCustomStyles()}
                          id="companyBranchName"
                          name="companyBranchName"
                          value={values.companyBranchName}
                          onChange={(option) => setFieldValue('companyBranchName', option)}
                          isDisabled={checkDisabled('companyBranchName')}
                          className="input-register"
                          placeholder="Selecione a filial"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="companyBranchName" />
                      </Flex>
                      <Flex direction="column">
                        <LabelDefault name="companyBranchDocumentNumber" text="CNPJ Vendemmia" />
                        <InputDefault
                          setFieldValue={setFieldValue}
                          value={values.companyBranchDocumentNumber}
                          maxLength={18}
                          onChange={(event) => {
                            const formatted = formatDocument(event.target.value);
                            handleChange({ target: { name: 'companyBranchDocumentNumber', value: formatted } });
                          }}
                          disabled={checkDisabled('companyBranchDocumentNumber')}
                          name="companyBranchDocumentNumber"
                          placeholder="Digite o CNPJ"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="companyBranchDocumentNumber" />
                      </Flex>
                      <Flex direction="column">
                        <LabelDefault name="stateCodeBilling" text="UF Faturamento" />
                        <Select
                          options={stateOptions}
                          styles={selectCustomStyles()}
                          id="stateCodeBilling"
                          name="stateCodeBilling"
                          value={values.stateCodeBilling}
                          onChange={(option) => setFieldValue('stateCodeBilling', option)}
                          isDisabled={checkDisabled('stateCodeBilling')}
                          className="input-register"
                          placeholder="Selecione o estado"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="stateCodeBilling" />
                      </Flex>
                    </TaxInquiryGridColumn>
                  </Flex>

                  <Flex data-form-card>
                    <Text mb="4" color="secondary">
                      Cliente
                    </Text>
                    <TaxInquiryGridColumn>
                      <Flex direction="column">
                        <LabelDefault name="clientDocumentNumber" text="CNPJ Cliente" />
                        <InputDefault
                          setFieldValue={setFieldValue}
                          value={values.clientDocumentNumber}
                          maxLength={18}
                          onChange={(event) => {
                            const formatted = formatDocument(event.target.value);
                            handleChange({ target: { name: 'clientDocumentNumber', value: formatted } });
                          }}
                          disabled={checkDisabled('clientDocumentNumber')}
                          name="clientDocumentNumber"
                          placeholder="Digite o CNPJ"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="clientDocumentNumber" />
                      </Flex>
                      <Flex direction="column">
                        <LabelDefault name="stateCodeResidenceDocumentNumber" text="UF Domicílio CNPJ" />
                        <Select
                          options={stateOptions}
                          styles={selectCustomStyles()}
                          id="stateCodeResidenceDocumentNumber"
                          name="stateCodeResidenceDocumentNumber"
                          value={values.stateCodeResidenceDocumentNumber}
                          onChange={(option) => setFieldValue('stateCodeResidenceDocumentNumber', option)}
                          isDisabled={checkDisabled('stateCodeResidenceDocumentNumber')}
                          className="input-register"
                          placeholder="Selecione o estado"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="stateCodeResidenceDocumentNumber" />
                      </Flex>
                      <Flex direction="column">
                        <LabelDefault name="isIcmsTaxpayer" text="Contribuinte ICMS" />
                        <Select
                          options={icmsOptions}
                          styles={selectCustomStyles()}
                          id="isIcmsTaxpayer"
                          name="isIcmsTaxpayer"
                          value={values.isIcmsTaxpayer}
                          onChange={(option) => setFieldValue('isIcmsTaxpayer', option)}
                          isDisabled={checkDisabled('isIcmsTaxpayer')}
                          className="input-register"
                          placeholder="É contribuinte ICMS"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="isIcmsTaxpayer" />
                      </Flex>

                      <Flex direction="column">
                        <LabelDefault name="stateRegistration" text="Inscrição estadual" />
                        <InputDefault
                          setFieldValue={setFieldValue}
                          value={values.stateRegistration}
                          disabled={checkDisabled('stateRegistration')}
                          name="stateRegistration"
                          placeholder="Preencha a IE"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="stateRegistration" />
                      </Flex>
                    </TaxInquiryGridColumn>
                  </Flex>

                  <Flex data-form-card>
                    <Text mb="4" color="secondary">
                      Informações gerais
                    </Text>
                    <TaxInquiryGridColumn>
                      <Flex direction="column">
                        <LabelDefault name="typeOfSale" text="Tipo de venda" />
                        <Select
                          options={typeOfSaleOptions}
                          styles={selectCustomStyles()}
                          id="typeOfSale"
                          name="typeOfSale"
                          value={values.typeOfSale}
                          onChange={(option) => setFieldValue('typeOfSale', option)}
                          isDisabled={checkDisabled('typeOfSale')}
                          className="input-register"
                          placeholder="Selecione o tipo"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="typeOfSale" />
                      </Flex>
                      <Flex direction="column">
                        <LabelDefault name="taxRegime" text="Regime tributário" />
                        <Select
                          options={taxRegimeOptions}
                          styles={selectCustomStyles()}
                          id="taxRegime"
                          name="taxRegime"
                          value={values.taxRegime}
                          onChange={(option) => setFieldValue('taxRegime', option)}
                          isDisabled={checkDisabled('taxRegime')}
                          className="input-register"
                          placeholder="Selecione o regime"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="taxRegime" />
                      </Flex>
                      <Flex direction="column">
                        <LabelDefault name="importModality" text="Modalidade da Importação" />
                        <Select
                          options={importModalityOptions}
                          styles={selectCustomStyles()}
                          id="importModality"
                          name="importModality"
                          value={values.importModality}
                          onChange={(option) => setFieldValue('importModality', option)}
                          isDisabled={checkDisabled('importModality')}
                          className="input-register"
                          placeholder="Selecione a modalidade"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="importModality" />
                      </Flex>

                      <Flex direction="column">
                        <LabelDefault name="finality" text="Finalidade" />
                        <Select
                          options={finalityOptions}
                          styles={selectCustomStyles()}
                          id="finality"
                          name="finality"
                          value={values.finality}
                          onChange={(option) => setFieldValue('finality', option)}
                          isDisabled={checkDisabled('finality')}
                          className="input-register"
                          placeholder="Selecione a finalidade"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="finality" />
                      </Flex>

                      <Flex direction="column">
                        <LabelDefault name="taxBenefit" text="Benefício Fiscal" />
                        <Select
                          options={taxBenefitOptions}
                          styles={selectCustomStyles()}
                          id="taxBenefit"
                          name="taxBenefit"
                          value={values.taxBenefit}
                          onChange={(option) => setFieldValue('taxBenefit', option)}
                          isDisabled={checkDisabled('taxBenefit')}
                          className="input-register"
                          placeholder="Selecione o benefício"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="taxBenefit" />
                      </Flex>
                    </TaxInquiryGridColumn>

                    <TaxInquiryGridColumn mt="6">
                      <Flex direction="column">
                        <LabelDefault name="requestedAt" text="Data da solicitação" />

                        <Input
                          type="date"
                          name="requestedAt"
                          cursor="pointer"
                          bg="#F2F2F2"
                          width={{ base: '7rem', md: '10rem' }}
                          borderColor="#70707033"
                          disabled={checkDisabled('requestedAt')}
                          value={values.requestedAt}
                          onChange={handleChange}
                        />
                        <ErrorMessage component="p" className="error-message-error" name="requestedAt" />
                      </Flex>

                      <Flex direction="column">
                        <LabelDefault name="expiresAt" text="Validade da consulta" />

                        <Input
                          type="date"
                          name="expiresAt"
                          cursor="pointer"
                          bg="#F2F2F2"
                          width={{ base: '7rem', md: '10rem' }}
                          borderColor="#70707033"
                          disabled={checkDisabled('expiresAt')}
                          value={values.expiresAt}
                          onChange={handleChange}
                        />
                        <ErrorMessage component="p" className="error-message-error" name="expiresAt" />
                      </Flex>

                      <Flex direction="column" display={'none'}>
                        <LabelDefault name="status" text="Status" />
                        <Select
                          options={statusOptions}
                          styles={selectCustomStyles()}
                          id="status"
                          name="status"
                          value={values.status}
                          onChange={(option) => setFieldValue('status', option)}
                          isDisabled={checkDisabled('status')}
                          className="input-register"
                          placeholder="Selecione o status"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="status" />
                      </Flex>
                    </TaxInquiryGridColumn>
                    <Flex direction="column" mt="8">
                      <LabelDefault name="products" text="Produtos" />

                      <Select
                        styles={selectCustomStyles()}
                        name="products"
                        value={values.products}
                        placeholder="Digite os quatro primeiros digitos do código NCM. ex: 0101"
                        className="input-register"
                        onInputChange={(inputValue) => {
                          loadProducts(inputValue);
                        }}
                        onChange={(option) => {
                          setFieldValue('products', option);
                        }}
                        noOptionsMessage={() => productsOptionsMessage}
                        options={productsOptions}
                        isDisabled={checkDisabled('products')}
                        isMulti={true}
                        isClearable={true}
                        menuPlacement="top"
                      />

                      <ErrorMessage component="p" className="error-message-error" name="products" />
                    </Flex>
                  </Flex>

                  <Flex data-form-card>
                    <Text mb="4" color="secondary">
                      Observações
                    </Text>
                    <Flex gap="1.875rem" direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column">
                        <LabelDefault name="operationObservation" text="Observações operacionais" />

                        <Textarea
                          name="operationObservation"
                          disabled={checkDisabled('operationObservation')}
                          width={{ base: '15rem', md: '66.25rem' }}
                          height="11.25rem"
                          resize="none"
                          bg="#F2F2F2"
                          mt="1"
                          value={values.operationObservation}
                          onChange={(event) => setFieldValue('operationObservation', event.target.value)}
                        />

                        <ErrorMessage component="p" className="error-message-error" name="operationObservation" />
                      </Flex>
                    </Flex>

                    <Flex mt="6" gap="1.875rem" direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column">
                        <LabelDefault name="fiscalObservation" text="Observações fiscais" />

                        <Textarea
                          name="fiscalObservation"
                          disabled={checkDisabled('fiscalObservation')}
                          width={{ base: '15rem', md: '66.25rem' }}
                          height="11.25rem"
                          resize="none"
                          bg="#F2F2F2"
                          mt="1"
                          value={values.fiscalObservation}
                          onChange={(event) => setFieldValue('fiscalObservation', event.target.value)}
                        />
                        <ErrorMessage component="p" className="error-message-error" name="fiscalObservation" />
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex data-form-card>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" w="w-full">
                        <LabelDefault name="tags" text="Incluir TAGs" />

                        <HStack spacing={4}>
                          <Flex direction="column">
                            <Input
                              type="text"
                              placeholder="Digite as tags e pressione Enter"
                              value={tagInput}
                              disabled={checkDisabled('tags')}
                              onChange={(e) => setTagInput(e.target.value)}
                              width={{ base: '10rem', md: '18rem' }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleTagAdd();
                                }
                              }}
                            />
                            <Flex gap="10px" mt="4">
                              {listTags?.map((tag, index) => (
                                <Tag size="md" key={index} variant="subtle" colorScheme="cyan">
                                  <TagLabel>{tag}</TagLabel>
                                  {showButtons() && <TagCloseButton onClick={() => handleTagRemove(index)} />}
                                </Tag>
                              ))}
                            </Flex>

                            {errors?.tags && <Text className="error-message-error">{errors.tags}</Text>}
                          </Flex>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex data-form-card>
                    <Flex direction="column" w="w-full">
                      <LabelDefault name="responsibles" text="Validação" />

                      <Select
                        styles={selectCustomStyles()}
                        name="responsibles"
                        value={values.responsibles}
                        className="input-register"
                        placeholder="Selecone os e-mails"
                        onChange={(option) => {
                          setFieldValue('responsibles', option);
                        }}
                        options={userOptions}
                        isDisabled={checkDisabled('responsibles')}
                        isMulti={true}
                        isClearable={true}
                        menuPlacement="top"
                      />

                      <ErrorMessage component="p" className="error-message-error" name="responsibles" />
                    </Flex>
                  </Flex>
                  <Flex className="float-button-modal">
                    <Button
                      rightIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                      type="submit"
                      h="46px"
                      px="24px"
                      py="14px"
                      bgColor="primary"
                      color="#FFFFFF"
                      borderRadius="27px"
                      _hover={{ bgColor: '#8067DC' }}
                      isLoading={isSubmitting}
                      loadingText="Salvando">
                      <Text>Salvar</Text>
                    </Button>
                  </Flex>
                </Flex>
              </Card>
              {/* {showButtons() && <FloatActionButton options={[<></>]} isOpen={isOpenFloatButton} setIsOpen={setIsOpenFloatButton} />} */}
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

export default TaxInquiryDetailPage;
