import React from 'react';

import { InfoWindow } from '@react-google-maps/api';
import { BsSpeedometer } from 'react-icons/bs';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, IconButton, Image, Text } from '@chakra-ui/react';

import document from '../../assets/images/document.svg';
import DefaultModal from '../../components/Modal/Template/DefaultModal';
import { handleDocuments } from '../../utils/documents/handleDocuments';

const MapPopup = ({ point, onCloseClick }) => {
  const infoWindowOffset = { x: 0, y: -32 };
  let profileRole = localStorage.getItem('profile-role');

  function generateLabel(label) {
    switch (label) {
      case 'id':
        return 'id';
      case 'code':
        return 'Viagem';
      case 'address':
        return 'Local';
      case 'lat':
        return 'Latitude';
      case 'lng':
        return 'Longitude';
      case 'date':
        return 'Data';
      case 'driver':
        return 'Motorista';
      case 'description':
        return 'Descrição';
      case 'speed':
        return 'Velocidade';
      case 'cte':
        return 'CT-e';
      case 'plates':
        return 'Placa (s)';
      default:
        return 'Não definido';
    }
  }

  const svgCode = `
    ${process.env.PUBLIC_URL}${point?.header?.icon}
  `;
  const overflowY = point?.processes?.length > 4 ? 'auto' : 'unset !important';
  const temSVG = /<svg\b/i.test(point?.header?.icon);

  return (
    <InfoWindow
      position={point}
      onCloseClick={onCloseClick}
      options={{
        pixelOffset: new window.google.maps.Size(infoWindowOffset.x, infoWindowOffset.y),
      }}
      style={{ padding: '0px' }}>
      <Flex width="fit-content" mb="4" mr="2">
        <style>
          {`
            [role="dialog"] > div:first-child {
              overflow-x: unset !important;
              overflow-y: ${overflowY};
            }
          `}
        </style>

        {temSVG ? (
          <Flex title="Flex" dangerouslySetInnerHTML={{ __html: svgCode }} w="12" mr="4" />
        ) : point?.header?.icon == undefined ? (
          <></>
        ) : (
          <Image src={svgCode} />
        )}

        <Flex className="info-window-content" direction="column" align="flex-start" w="full">
          <Flex w="full" align="flex-end" mb="3">
            <Text className="up-anim" fontSize="1rem" fontWeight="bold">
              {point?.header?.title}
            </Text>
          </Flex>

          {point?.content != null &&
            Object.entries(point.content)?.map(([key, item]) => {
              if (item == '') return;
              return (
                <Flex key={key} gap="5px" align="center">
                  <Text fontWeight="bold">{generateLabel(key)}:</Text>
                  <Text>{item ?? '-'}</Text>
                </Flex>
              );
            })}
          {profileRole === 'ROLE_SYSTEM_ADMIN' && point?.processes == null && point?.lat != null && point?.lng != null && (
            <>
              <Flex gap="5px" align="center">
                <Text fontWeight="bold">Latitude:</Text>
                <Text>{point?.lat ?? '-'}</Text>
              </Flex>
              <Flex gap="5px" align="center">
                <Text fontWeight="bold">Longitude:</Text>
                <Text>{point?.lng ?? '-'}</Text>
              </Flex>
            </>
          )}
          {point?.processes != null &&
            Object.entries(point.processes).map(([key, item]) => {
              return (
                <Flex key={key} justifyContent="space-between" w="110px" mb="1">
                  <Text>{item.code}</Text>
                  <Flex direction="row" justify="flex-end">
                    <Link to={`/comex/process/detail/${item.identifier}`} target="_blank" title="Visualizar processo">
                      <Image
                        w="18px"
                        src={document}
                        alt="Detalhes"
                        filter="saturate(0)"
                        cursor="pointer"
                        transition="0.2s"
                        _hover={{
                          filter: 'saturate(1)',
                        }}
                      />
                    </Link>
                  </Flex>
                </Flex>
              );
            })}
          {point?.speed != null && point?.speed > 0 && (
            <Box border="1px" title="Velocidade" borderRadius="5px" borderColor="#cccccc" bgColor="#f5f5f5" mt="2" px="4" py="2">
              <Flex gap=".5rem">
                <BsSpeedometer />
                <Text>{point?.speed} km/h</Text>
              </Flex>
            </Box>
          )}
          {point?.deliveryImage != null && (
            <Box mt="2" width="5px">
              <DefaultModal
                label="Visualizar arquivo"
                width="70vw"
                height="44vw"
                ctaButton={
                  <IconButton
                    bg="#6b6b6b"
                    p="0"
                    mr="2"
                    icon={<MdOutlineOpenInNew size={16} color="#FFFFFF" />}
                    transition="0.2s"
                    _hover={{ bgColor: '#b8b8b8' }}
                  />
                }
                title="Comprovante de entrega"
                showButtons={false}>
                {handleDocuments(
                  `/open/freight/${point?.deliveryImage?.tripIdentifier}/file/${point?.deliveryImage?.fileIdentifier}/view`,
                  point.name
                )}
              </DefaultModal>
            </Box>
          )}
        </Flex>
      </Flex>
    </InfoWindow>
  );
};

export default MapPopup;
