import { replaceIdentifiersInEndpoint } from '../replace/replaceIdentifiersInEndpoint';

export const handleDownloadDocument = (endpoint, fileIdentifier, uploadName) => {
  const link = document.createElement('a');

  const url = replaceIdentifiersInEndpoint({
    endpoint,
    identifiersOrValue: fileIdentifier,
  });

  link.href = (process.env.REACT_APP_API_URL + url).replace('//open', '/open');
  link.target = '_blank';
  link.download = uploadName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
