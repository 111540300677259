import React from 'react';

import { Box, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

const ProcessProgressBar = ({ tooltip, label, value = 0, max = 10.0, color = '#6576FF', originalValue = value }) => {
  function getValue(val) {
    if (!val) {
      return 0;
    }

    return val;
  }

  function getPercent() {
    if (originalValue === 0) {
      return 0;
    }

    return ((originalValue / max) * 100.0).toFixed(2);
  }

  return (
    <Tooltip label={tooltip ? tooltip : `${getValue(value)} de ${getValue(max)} (${getPercent()}%)`}>
      <Flex direction="column" width="100%" cursor="pointer" mt="7px">
        <Grid templateColumns={'2fr 3fr 50px'} gap={'15px'} justify="space-between" fontSize={'14px'} alignItems={'center'}>
          <Text textStyle="paragraph" mr="1px" fontWeight="bold" fontSize="14">
            {label}
          </Text>
          <Box width={'100%'} bg="#EFEFEF" borderRadius="8px" h="10px">
            <Box
              className="progress-anim"
              h="100%"
              transformOrigin="left"
              transition=".5s"
              borderRadius="8px"
              bg={color}
              w={`${getPercent()}%`}
            />
          </Box>
          <Text className="up-anim">{value}%</Text>
        </Grid>
      </Flex>
    </Tooltip>
  );
};

export default ProcessProgressBar;
