import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { useDisclosure } from '@chakra-ui/react';

import requests from '../../../../../services/requests';

import ModalFormTerm from './ModalFormTerm';

export const ModalEditTerm = ({ onSave, entity }) => {
  const { onClose } = useDisclosure();
  const [description, setDescription] = useState(entity.description);
  const [load, setLoad] = useState(false);

  const handleContentChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    setLoad(true);

    try {
      await requests.editTermUse(entity.identifier, {
        description,
      });

      onSave();
      setLoad(false);
      onClose();

      toast.success('Tópico editado com sucesso');
    } catch (error) {
      setLoad(false);
      onClose();

      toast.error('Erro ao editar, tente novamente!');
    }
  };

  return (
    <>
      <ModalFormTerm
        enabled={entity.status === 'DRAFT'}
        isEdit={true}
        txtButton="Novo cadastro"
        txtHeader="Edição dos Termos de Uso"
        textButtonConfirm="Salvar"
        onSubmit={onSubmit}
        description={description}
        handleContentChange={handleContentChange}
        load={load}
      />
    </>
  );
};

export default ModalEditTerm;
