import React from 'react';

import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

const HistoryBlock = ({ question, response }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />

        <ModalContent p="10px 35px" borderRadius="9px" width="auto" maxH="80vh" maxW="80vh" overflowY="auto">
          <ModalBody p="35px" position="relative" justifyContent="center">
            <Text textStyle="primaryTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
              {question}
            </Text>
            <Text textStyle="subtitle" color="#0F0A1D" dangerouslySetInnerHTML={{ __html: response }} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex
        onClick={onOpen}
        cursor="pointer"
        direction="column"
        border="1px solid #6C48C299"
        className="up-anim"
        bg="#6C48C214"
        borderRadius="8px"
        p="30px"
        w="400px"
        maxW="100%"
        minW="30%"
        gap="10px">
        <Text textStyle="tableTitle" color="#0F0A1D" fontWeight="800" fontSize={{ base: '18px', md: '20px' }}>
          {limitChars(question, 80)}
        </Text>
        <Text
          textStyle="subtitle"
          fontSize={{ base: '14px', md: '16px' }}
          color="#0F0A1D"
          dangerouslySetInnerHTML={{ __html: limitChars(response, 200) }}
        />
      </Flex>
    </>
  );
};

export default HistoryBlock;
