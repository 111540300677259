/*
Exemplo de uso

const parametersToAdd = {
  name: 'John Doe',
  'new-report': 'true',
  'another-param': 'value'
}; 

addParametersToURL(parametersToAdd);
*/

const addParametersToURL = (parameters) => {
  let existingUrl = window.location.href;

  // Convertendo os parâmetros para um formato de query string
  const queryString = Object.keys(parameters)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`)
    .join('&');

  let newUrl;

  // Remove os parametros antigos e substitui pelos novos
  if (existingUrl.includes('?')) {
    existingUrl = window.location.origin + window.location.pathname;
    newUrl = existingUrl + `?${queryString}`;
  } else {
    newUrl = existingUrl + `?${queryString}`;
  }

  window.history.pushState(null, '', newUrl);
};

export default addParametersToURL;
