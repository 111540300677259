import React, { useEffect, useState } from 'react';

import { MdHelp } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Box, Button, Checkbox, Flex, ModalBody, ModalFooter, Stack, Table, Tbody, Text, Thead, Tooltip, Tr } from '@chakra-ui/react';

import SearchFilter from '../../../../components/MenuBar/components/SearchFilter';
import Paginate from '../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';
import { filterOptionsCountry } from '../../../../utils/filters/filterPresets';

import ModalCloneUser from './ModalCloneUser';

const ClientData = ({ loadList, user = {}, handleClose, getUser = () => {} }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  const [isSelectedAllOnPage, setIsSelectedAllOnPage] = useState(false);
  const [isSelectedAllExisting, setIsSelectedAllExisting] = useState(false);
  const [isOpenModalCloneUser, setIsOpenModalCloneUser] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [searchFilterData, setSearchFilterData] = useState(null);

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsCountry());
  };

  const onChangeFilters = (value) => {
    setSearchFilterData(value);
  };

  const onChangeTextFilter = (value) => {
    if (value.length >= 3) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  };

  const loadData = () => {
    setIsLoadingData(true);

    if (searchFilterData !== null) {
      delete searchFilterData?.search;
    }

    requests
      .showUserClients(
        user.identifier,
        {
          search: searchString,
          filters: searchFilterData,
        },
        metadata.current_page > 0 ? metadata.current_page : 1
      )
      .then((response) => {
        setMetadata(response.meta);
        setData(response.data);
        setIsLoadingData(false);
      });
  };

  function getClientNameFormatted(client) {
    let result = '';
    if (client?.name) {
      result += client.name;
    }

    result += ' - ';

    if (client?.documentNumber?.length > 8) {
      result += '(' + client.documentNumberFormatted + ')';
      if (client?.codeState) {
        result += ' (' + client.codeState + ') ';
      }
    }

    if (client?.type) {
      result += ' (' + client?.type + ') ';
    }

    return result;
  }

  function handlSelectAllOnPage() {
    const aux = data.map((client) => {
      client.isChecked = !isSelectedAllOnPage;
      if (client.children) {
        client.children.map((child) => {
          child.isChecked = !isSelectedAllOnPage;
        });
      }

      return client;
    });

    setData(aux);
  }

  const handleSelectAllChange = (client) => {
    if (!client) {
      return;
    }

    const updatedData = data.map((a) => {
      if (a.client.identifier === client.client.identifier) {
        const updatedClient = {
          ...a,
          isChecked: !a.isChecked,
          children: a.children.map((c) => ({
            ...c,
            isChecked: !a.isChecked,
          })),
        };

        return updatedClient;
      }

      return a;
    });

    setData(updatedData);
  };

  const handleCheckboxChange = (clickedChild) => {
    if (!clickedChild) {
      return;
    }

    const updatedData = data.map((parent) => {
      const updatedChildren = parent.children.map((child) => {
        if (child.client.identifier === clickedChild.client.identifier) {
          return {
            ...child,
            isChecked: !child.isChecked,
          };
        }
        return child;
      });

      const allChildrenChecked = updatedChildren.every((child) => child.isChecked);
      return {
        ...parent,
        children: updatedChildren,
        isChecked: allChildrenChecked,
      };
    });

    setData(updatedData);
  };

  function actionModalCloneUser() {
    setIsOpenModalCloneUser(!isOpenModalCloneUser);
  }

  const addAll = () => {
    setIsLoadingSaveData(true);
    setIsSelectedAllExisting(true);

    if (!isSelectedAllExisting) {
      requests
        .addAllProfileClients(user.identifier)
        .then(() => {
          toast.success('Todos clientes vinculados com sucesso!');

          getUser();
          setIsLoadingSaveData(false);
        })
        .catch(() => {
          setIsLoadingSaveData(false);
        });
    } else {
      requests
        .removeAllProfileClients(user.identifier)
        .then(() => {
          toast.success('Todos clientes desvinculados com sucesso!');

          getUser();
          setIsLoadingSaveData(false);
        })
        .catch(() => {
          setIsLoadingSaveData(false);
        });
    }
  };

  const saveData = () => {
    setIsLoadingSaveData(true);

    let add = [];
    let remove = [];

    data.forEach((client) => {
      if (client.isChecked) {
        add.push(client.client.identifier);
      } else {
        remove.push(client.client.identifier);
      }

      if (client.children && client.children.length) {
        client.children.forEach((child) => {
          if (child.isChecked) {
            add.push(child.client.identifier);
          } else {
            remove.push(child.client.identifier);
          }
        });
      }
    });

    requests
      .saveProfileClients(user.identifier, {
        clients: add,
        remove: remove,
      })
      .then(() => {
        toast.success('Informações alteradas com sucesso!');

        setIsLoadingSaveData(false);
        getUser();
        loadList();
      })
      .catch(() => {
        setIsLoadingSaveData(false);
      });
  };

  useEffect(() => {
    if (!user?.identifier) {
      return;
    }

    loadData();
  }, [action, searchString, searchFilterData]);

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    setIsSelectedAllExisting(user?.hasAllClients);
  }, [user]);

  useEffect(() => {
    data.map((checked) => {
      checked.isChecked ? setIsSelectedAllOnPage(true) : setIsSelectedAllOnPage(false);
    });
  }, [data]);

  return (
    <>
      <ModalCloneUser
        isOpen={isOpenModalCloneUser}
        entity={user}
        actionModalCloneUser={actionModalCloneUser}
        getEntity={getUser}
        modality="company"
      />
      <ModalBody mt="10px" overflowY="auto" h={{ md: '500px', sm: '350px' }} w="full" className="up-anim">
        <Flex justifyContent="space-between" mb="30px" flexDirection={{ md: 'row', sm: 'column' }} gap={{ sm: '15px' }}>
          <Stack spacing={4} flexDirection={{ md: 'row', sm: 'column' }}>
            {!isSelectedAllExisting && (
              <Checkbox
                iconColor="primary"
                isChecked={isSelectedAllOnPage}
                onChange={() => {
                  setIsSelectedAllOnPage(!isSelectedAllOnPage);
                  handlSelectAllOnPage();
                }}>
                <Text textColor="primary" fontWeight="bold">
                  Todos da página
                </Text>
              </Checkbox>
            )}

            <Checkbox
              iconColor="primary"
              isChecked={isSelectedAllExisting}
              onChange={() => {
                addAll();
              }}>
              <Flex gap="5px">
                <Text textColor="primary" fontWeight="bold">
                  Todos os clientes e novos automáticamente
                </Text>

                <Tooltip label="Se ativo, todos os novos clientes são automaticamente associados sempre que um novo usuário for inserido.">
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </Flex>
            </Checkbox>
          </Stack>

          {!isSelectedAllExisting && <SearchFilter placeholder="CNPJ ou razão social" onChange={onChangeTextFilter} />}
        </Flex>

        {isLoadingData ? (
          <ScreenLoader isLoading={isLoadingData} />
        ) : (
          <>
            {!isSelectedAllExisting ? (
              <Stack spacing={3}>
                {data?.map((client, key) => (
                  <React.Fragment key={key}>
                    <Table variant="striped" colorScheme="teal" w="full" border="1px" borderColor="#A18BD8">
                      <Thead bgColor="#A18BD81A" border="1px" borderColor="#A18BD8">
                        <Tr>
                          <Checkbox
                            iconColor="primary"
                            px="15px"
                            id={client.identifier}
                            value={client.identifier}
                            isChecked={client.isChecked}
                            onChange={() => handleSelectAllChange(client)}>
                            <Text fontWeight="bold" fontSize={{ sm: '14px', md: '16px' }}>
                              {getClientNameFormatted(client.client)}
                            </Text>
                          </Checkbox>
                        </Tr>
                      </Thead>

                      <Tbody border="1px" borderColor="#A18BD8">
                        {client?.children.map((child, key) => (
                          <>
                            <Tr>
                              <Checkbox
                                key={key}
                                iconColor="primary"
                                ml="10px"
                                px="15px"
                                id={child.identifier}
                                value={child.identifier}
                                isChecked={child.isChecked}
                                onChange={() => handleCheckboxChange(child)}>
                                <Text fontSize={{ sm: '12px', md: '14px' }}>{getClientNameFormatted(child.client)}</Text>
                              </Checkbox>
                            </Tr>
                          </>
                        ))}
                      </Tbody>
                    </Table>
                  </React.Fragment>
                ))}
              </Stack>
            ) : (
              <Text textAlign="center">Usuário associado a todos os clientes.</Text>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter
        borderTop="1px"
        borderColor="#7070702E"
        p="0"
        m={{ sm: '10px', md: 'initial' }}
        justifyContent="space-between"
        flexDirection={{ md: 'row', sm: 'column' }}>
        {!isSelectedAllExisting && (
          <>
            {metadata.total_pages > 1 && (
              <Paginate
                w="1000px"
                metadata={metadata}
                setMetadata={setMetadata}
                action={action}
                setAction={setAction}
                showDetails={true}
              />
            )}
          </>
        )}

        <Flex w="full" justify="flex-end" flexDirection={{ md: 'row', sm: 'column' }} gap={{ sm: '10px' }}>
          <Button
            border="1px"
            borderRadius="7px"
            m={{ md: '15px' }}
            w={{ md: 'fit-content', sm: '100%' }}
            p="9px 23px"
            color="#E73668"
            borderColor="#E52359"
            bgColor="transparent"
            type="button"
            onClick={handleClose}>
            Cancelar e fechar
          </Button>

          <Button
            variant="secundary"
            borderRadius="7px"
            m={{ md: '15px' }}
            w={{ md: 'fit-content', sm: '100%' }}
            p="9px 23px"
            onClick={() => actionModalCloneUser()}
            loadingText="Clonando...">
            Clonar de outro usuário
          </Button>

          <Button
            variant="primary"
            borderRadius="7px"
            m={{ md: '15px' }}
            w={{ md: 'fit-content', sm: '100%' }}
            p="9px 23px"
            type="submit"
            isLoading={isLoadingSaveData}
            onClick={() => {
              saveData();
            }}
            loadingText="Salvando">
            Salvar
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

export default ClientData;
