import React, { useCallback, useEffect, useState } from 'react';

import { MdDelete, MdOutlineFileDownload, MdOutlineOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../../../services/permissions';
import requests from '../../../../../services/requests';
import { handleDocuments } from '../../../../../utils/documents/handleDocuments';

const DocumentModalView = ({ isOpen, identifier, code, onClose }) => {
  let profileRole = localStorage.getItem('profile-role');
  const hasPermissionView = permissions.logisticDocumentView;
  const hasPermissionManagement = permissions.logisticDocumentManagement;

  const [files, setFiles] = useState([]);
  const [isLoadingListData, setIsLoadingListData] = useState(false);

  const handleDownload = (fileIdentifier, uploadName) => {
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/freight/${identifier}/file/${fileIdentifier}/download`).replace(
      '//open',
      '/open'
    );
    link.target = '_blank';
    link.download = uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = useCallback((fileIdentifier) => {
    setIsLoadingListData(true);

    requests
      .removeCTEFile(identifier, fileIdentifier)
      .then(() => {
        toast.success(`Arquivo removido com sucesso`);
        // onClose();
      })
      .finally(() => {
        loadDriverFreightFiles();
      });
  });

  const isShowButtonsView = (file) => {
    if (!hasPermissionView) {
      return;
    }
    return (
      <>
        <DefaultModal
          label="Visualizar arquivo"
          width="70vw"
          ctaButton={
            <IconButton
              bg="#6b6b6b"
              p="0"
              mr="2"
              icon={<MdOutlineOpenInNew size={20} color="#FFFFFF" />}
              transition="0.2s"
              _hover={{ bgColor: '#b8b8b8' }}
            />
          }
          title={file?.modalityReadable}
          showButtons={false}>
          {handleDocuments(`/open/freight/${identifier}/file/${file.identifier}/view`, file.name)}
        </DefaultModal>
        <Tooltip label="Baixar arquivo">
          <IconButton
            bg="#633dbe"
            p="0"
            icon={<MdOutlineFileDownload size={20} color="#FFFFFF" />}
            mr="2"
            transition="0.2s"
            _hover={{ bgColor: '#ab88ff' }}
            onClick={(decision) => {
              if (decision) {
                handleDownload(file.identifier, file.uploadName);
              }
            }}
          />
        </Tooltip>
      </>
    );
  };

  const isShowButtonDelete = (identifier, modality) => {
    if (!hasPermissionManagement || modality == 'LOG') {
      return;
    }
    return (
      <DefaultModal
        ctaButton={
          <IconButton
            bg="#EF8484"
            p="0"
            icon={<MdDelete size={20} color="#FFFFFF" />}
            transition="0.2s"
            _hover={{ bgColor: '#f6a4a4' }}
          />
        }
        title={'Certeza que deseja excluir ?'}
        subtitle={<>Esta ação é permanente, deseja prosseguir?</>}
        buttonColor="red"
        txtButton="Excluir"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            await handleDelete(identifier);
          }
        }}
        showButtons={true}></DefaultModal>
    );
  };

  const loadDriverFreightFiles = () => {
    setIsLoadingListData(true);

    requests
      .showDriverFreightFiles(identifier)
      .then((response) => {
        setFiles(response.data);
        setIsLoadingListData(false);
      })
      .catch(() => {
        setIsLoadingListData(false);
        toast.error('Problemas ao listar arquivos da viagem, tente novamente ou entre em contato com o Administrador');
      });
  };

  useEffect(() => {
    if (identifier) {
      loadDriverFreightFiles();
    }
  }, [identifier]);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />

        <ModalContent borderRadius="5px" overflow="auto">
          <ModalHeader>#{code} - Arquivos</ModalHeader>

          <ModalCloseButton zIndex="100000" />

          <ModalBody p="25px" position="relative" maxH="50vh">
            <ScreenLoader isLoading={isLoadingListData}>
              <Table className="table-default" w="100%">
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>Modalidade</Th>
                    {(hasPermissionView || hasPermissionManagement) && <Th>Ações</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {files?.length > 0 &&
                    files?.map((file, key) => {
                      if (profileRole !== 'ROLE_SYSTEM_ADMIN' && file?.modality == 'LOG') {
                        return;
                      }

                      return (
                        <React.Fragment key={key}>
                          <Tr>
                            <Td>{file?.name ?? '-'}</Td>
                            <Td>{file?.modalityReadable ?? '-'}</Td>
                            {(hasPermissionView || hasPermissionManagement) && (
                              <Td>
                                <Flex>
                                  {isShowButtonsView(file)}
                                  {isShowButtonDelete(file.identifier, file?.modality)}
                                </Flex>
                              </Td>
                            )}
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                </Tbody>
              </Table>
            </ScreenLoader>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentModalView;
