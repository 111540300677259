// populate the waypoints
export const registerCoordenatedPath = (point, state, title, description, marker = '') => {
  if (Array.isArray(point)) {
    point.forEach((item) => registerCoordenatedPath(item, state, title, description, marker));
  } else if ((point && point?.latitude) || (point?.lat !== undefined && point?.longitude) || point?.lng !== undefined) {
    state.push(
      title && description
        ? {
            lat: point.latitude ?? point.lat,
            lng: point.longitude ?? point.lng,
            title,
            description,
            marker,
          }
        : {
            lat: point.latitude ?? point.lat,
            lng: point.longitude ?? point.lng,
          }
    );
  }
};
