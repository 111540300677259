export const colors = {
  primary: '#422C76',
  secundary: '#C767DC',
  violet: '#AA8BE0',
  violet2: '#8067DC',
  lightViolet: '#F2E9FF',
  linkColor: '#6C48C2',
  myoga: '#E52359',

  blue: '#3498DB',
  yellow: '#F1C40F',
  lightRed: '#F3A59D',
  red: '#E74C3C',
  lightRed: '#ec7063',
  lightGreen: '#B3E9CA',
  green: '#2ECC71',
  white: '#FFFFFF',

  offColor: '#AFAEB4',
  placeholder: '#85828C',
  textColor3: '#5F5B68',
  titleColor: '#0F0A1D',
  iconBaseColor: '#9999A1',
  inputColor: '#E8EAED',
  background: '#F5F5F5',
  offWhite: '#FAF5F9',
  bgActionButton: '#D0B0FD36',
  purple: '#F2E9FF',

  'black-400': '#1A202C',

  'gray-bg': '#f9f9f9',
  'gray-100': '#DFDFDF',
  'gray-200': '#B1B1B1',
  'gray-250': '#949494',
  'gray-300': '#707070',

  'purple-100': '#AA8BE0',
  'purple-300': '#8067DC',
  'purple-400': '#6C48C2',
  'purple-800': '#422C76',

  'blue-400': '#2EA0CC',

  'orange-400': '#CC782E',

  'status-100': '#422C76',
  'status-200': '#2EC5CC',
  'status-300': '#2EA0CC',
  'status-400': '#F1C40F',
  'status-500': '#9B2ECC',
  'status-600': '#CC2E96',
  'status-700': '#422ECC',
  'status-800': '#F14F0F',
  'status-900': '#2ECC71',

  'yellow-300': '#F1C40F',
  'yellow-400': '#CCB32E',
  'yellow-500': '#CCA42E',
};
