import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

const ProcessesErrorsTable = ({ data }) => {
  return (
    <Flex h="600px" w="full" direction="column" p="25px" overflow="auto">
      <Table w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px!important"></Box>
            </Th>
            <Th>
              <Box p="10px!important">Processo</Box>
            </Th>

            <Th>
              <Box p="10px!important"></Box>
            </Th>
            <Th>
              <Box p="10px!important">Etapa</Box>
            </Th>
            <Th>
              <Box p="10px!important">Título</Box>
            </Th>
            <Th>
              <Box p="10px!important">Descrição</Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.length > 0 ? (
            data.map((entity, key) =>
              entity.errors.map((error, idx) => (
                <Tr className="border" key={`${key}-${idx}`}>
                  <Td>
                    <Flex p="10px!important"></Flex>
                  </Td>
                  <Td>
                    <Flex w="150px" p="10px!important">
                      {entity.process ?? '-'}
                    </Flex>
                  </Td>

                  <Td>
                    <Flex p="10px!important">
                      <Tooltip label="Visualizar detalhes do processo">
                        <Link to={`/comex/process/detail/${entity.identifier}`} target="_blank">
                          <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                        </Link>
                      </Tooltip>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex w="150px" p="10px!important">
                      {error.stage ?? '-'}
                    </Flex>
                  </Td>
                  <Td>
                    <Flex p="10px!important">{error.title ?? '-'}</Flex>
                  </Td>
                  <Td>
                    <Flex p="10px!important">{error.description ?? '-'}</Flex>
                  </Td>
                </Tr>
              ))
            )
          ) : (
            <Tr className="border">
              <Td>
                <Flex></Flex>
              </Td>
              <Td colSpan={5}>
                <Flex justify="center">Não há resultados que correspondam aos filtros selecionados.</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} /> */}
    </Flex>
  );
};

export default ProcessesErrorsTable;
