import React from 'react';

import { AiOutlineRise } from 'react-icons/ai';

import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';

const CalendarWorkloadItem = ({ entity, setIsOpenModalItem, setCurrentItem }) => {
  return (
    <Flex
      style={{ border: '1px solid #E2E8F0' }}
      direction="column"
      bg="#FFFFFF"
      w="14.375rem"
      minH="7.5rem"
      borderRadius="8px"
      transition=".3s">
      <Box h="4px" bg="#422C76" />

      <Flex direction="column" p="10px" w="full" h="full">
        <Flex direction="column" h="full" justify="space-between">
          <Flex direction="column" justify="space-between" gap="5px" my="8px">
            <Flex align="center" w="full" justifyContent="space-between">
              <Text fontSize="0.9rem" opacity="1" color="#0F0A1D">
                {entity.event}
              </Text>

              <Flex bg="#422C76" w="57px" h="25px" borderRadius="5px" alignItems="center" justifyContent="center">
                <Text color="#FFFFFF" fontSize="0.7rem" fontWeight="medium">
                  {entity.qtt}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Divider borderColor="#E2E8F0" my="5px" />
        </Flex>

        <Flex align="center" justifyContent="center">
          <Button
            bgColor="#6C48C2"
            color="white"
            borderRadius="8px"
            shadow="xl"
            width="6rem"
            h="26px"
            py="15px"
            px="15px"
            onClick={(decision) => {
              if (decision) {
                setCurrentItem(entity);
                setIsOpenModalItem(true);
              }
            }}
            _hover={{ bgColor: '#70D499' }}>
            <AiOutlineRise />

            <Text textStyle="paragraph" fontSize="0.6rem" ml="5px">
              Detalhes
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CalendarWorkloadItem;
