import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import { MdHelp } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';

import { Box, Flex, Grid, Input, Text, Tooltip } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import api from '../../../../services/api';
import requests from '../../../../services/requests';
import dangerousClassOptions from '../Helpers/dangerousClassOptions';
import incotermOptions from '../Helpers/incotermOptions';
import quoteTypeOptions from '../Helpers/quoteTypeOptions';
import yesOrNoOptions from '../Helpers/yesOrNoOptions';

const FormGeneralInfo = ({
  values,
  setFieldValue,
  handleChange,
  currency,
  clientsOptions,
  setClientsOptions,
  setModality,
  setListUser,
  selectCustomStyles,
  listUser,
  setRequiredList,
  requiredList,
}) => {
  let profileRole = localStorage.getItem('profile-role');

  const [modalityOptions, setModalityOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [portsOptions, setPortsOptions] = useState([]);

  const [loadOptionsModality, setLoadOptionsModality] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);

  const [noClientOptionsMessage, setNoClientOptionsMessage] = useState();
  const [noOptionsMessage, setNoOptionsMessage] = useState();

  const [listUsersLogistic, setListUsersLogistic] = useState([]);

  const loadModality = () => {
    api
      .get('transport-modality/list', {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
      })
      .then((response) => {
        var modalities = [];
        response.data.data.forEach((item) => {
          if (item?.slug !== 'ground') {
            modalities.push({
              value: item?.identifier,
              label: item?.description + ' (' + item?.slug + ')',
            });
          }
        });

        setLoadOptionsModality(false);
        setModalityOptions(modalities);
      });
  };

  const loadPorts = () => {
    api
      .get('port/list?page_size=99999999', {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
      })
      .then((response) => {
        var ports = [];

        response.data.data.forEach((item) => {
          ports.push({
            value: item?.id,
            label: item?.name + (item?.country && ' (' + item?.country.name + ')'),
          });
        });

        setPortsOptions(ports);
      });
  };

  const loadUsersLogistic = () => {
    requests.getUsersLogistic().then((res) => {
      setListUsersLogistic(res.data);
    });
  };

  const loadClientsList = (search = '') => {
    setIsLoadingClients(true);

    if (search?.length <= 2) {
      setNoClientOptionsMessage('Insira ao menos 2 caracteres para fazer a busca');
      setIsLoadingClients(false);
    } else {
      api
        .get(`quote/client/list?page_size=1000${search && `&search=${encodeURIComponent(search)}`}`, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
          },
        })
        .then((response) => {
          var clients = [];

          response.data.data.forEach((item) => {
            clients.push({
              value: item?.identifier,
              label: `${item?.name} - ${item?.documentNumberFormatted}`,
            });
          });

          if (response.data.data.length === 0) {
            setNoClientOptionsMessage('Nenhum resultado encontrado');
          }

          setClientsOptions(clients);
        })
        .catch(() => {
          setNoClientOptionsMessage('Nenhum resultado encontrado');
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const loadProcessList = (client = '', search = '') => {
    setIsLoadingProcess(true);

    if (search.length < 2 || client.length === 0) {
      setNoOptionsMessage('Insira ao menos 2 caracteres para fazer a busca');
      setIsLoadingProcess(false);
    } else {
      api
        .get('process/list/all?page_size=10000' + (client ? `&clients[]=${client}` : '') + (search ? `&search=${search}` : ''), {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
          },
        })
        .then((response) => {
          var processList = [];

          response.data.forEach((item) => {
            processList.push({
              value: item?.identifier,
              label: item?.code,
            });
          });

          if (response.data.length === 0) {
            setNoOptionsMessage('Nenhum resultado encontrado');
          }

          setProcessOptions(processList);
        })
        .catch(() => {
          setNoOptionsMessage('Nenhum resultado encontrado');
        })
        .finally(() => {
          setIsLoadingProcess(false);
        });
    }
  };

  const selectedModal = (modal) => {
    setModality(modal);
  };

  useEffect(() => {
    loadClientsList();
    loadModality();
    loadUsersLogistic();
    loadPorts();
  }, []);

  return (
    <Flex m="20px" gap="20px" direction="column">
      <Text textStyle="cardTitle">Informações gerais</Text>

      <Grid templateColumns="repeat(5, 1fr)" gridTemplateColumns={'auto auto auto'} gap="15px" alignItems="start">
        <Flex direction="column" gridColumn={'span 2'}>
          <LabelDefault name="responsibleLogisticUser" text="Responsável logístico" />

          <Select
            styles={selectCustomStyles()}
            id="responsibleLogisticUser"
            name="responsibleLogisticUser"
            value={values.responsibleLogisticUser}
            options={[
              { value: null, label: 'Nenhum' },
              ...listUsersLogistic.map((item) => ({ value: item.identifier, label: item.name })),
            ]}
            onChange={(option) => setFieldValue('responsibleLogisticUser', option)}
            className="input-register"
            placeholder="Selecione..."
            isDisabled={profileRole != 'ROLE_LOGISTIC'}
          />

          <ErrorMessage component="p" className="error-message-error" name="responsibleLogisticUser" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <Flex>
            <LabelDefault name="reference" text="Referência" />

            <Tooltip label="Campo para identificar a cotação de forma facilitada" placement="auto">
              <Box ml="5px">
                <MdHelp color="#422C76" />
              </Box>
            </Tooltip>
          </Flex>

          <InputDefault
            setFieldValue={setFieldValue}
            value={values.reference}
            name="reference"
            placeholder="Preencha uma referência"
          />

          <ErrorMessage component="p" className="error-message-error" name="reference" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="client" text="Cliente" />

          <Select
            options={clientsOptions}
            styles={selectCustomStyles()}
            id="client"
            name="client"
            value={values.client}
            onChange={(option) => {
              setFieldValue('client', option);
              loadProcessList(option?.value);
            }}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => noClientOptionsMessage}
            onInputChange={(inputValue) => {
              loadClientsList(inputValue);
              setListUser(!listUser);
            }}
            isLoading={isLoadingClients}
            className="input-register"
            placeholder="Selecione um cliente"
          />
          <ErrorMessage component="p" className="error-message-error" name="client" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <Flex>
            <LabelDefault name="process" text="Processo" />

            <Tooltip label="Selecione um cliente para liberar esse campo" placement="auto">
              <Box ml="5px">
                <MdHelp color="#422C76" />
              </Box>
            </Tooltip>
          </Flex>
          <Select
            styles={selectCustomStyles()}
            options={values.client ? processOptions : []} // Somente exibe opções se um cliente for selecionado
            id="process"
            name="process"
            onInputChange={(inputValue) => {
              if (values.client) {
                loadProcessList(values.client.value, inputValue);
              }
            }}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => (values.client ? noOptionsMessage : 'Selecione um cliente primeiro')}
            value={values.process}
            onChange={(option) => {
              setFieldValue('process', option);
            }}
            isLoading={isLoadingProcess}
            className="input-register"
            placeholder="Selecione um processo"
            isDisabled={!values.client} // Desabilita o select até que um cliente seja selecionado
          />
          <ErrorMessage component="p" className="error-message-error" name="process" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="modality" text="Modal" />

          <Select
            styles={selectCustomStyles()}
            options={modalityOptions}
            isLoading={loadOptionsModality}
            id="modality"
            name="modality"
            value={values.modality}
            onChange={(option) => {
              setFieldValue('modality', option);
              setRequiredList({ modality: option?.label });

              selectedModal(option?.label);
            }}
            className="input-register"
            placeholder="Selecione uma modalidade"
          />

          <ErrorMessage component="p" className="error-message-error" name="modality" />
        </Flex>

        <Flex direction="column" gridColumn={'span 3'}>
          <LabelDefault name="originPort" text="Origem" />

          <Select
            options={portsOptions}
            styles={selectCustomStyles()}
            id="originPort"
            name="originPort"
            value={values.originPort}
            onChange={(option) => setFieldValue('originPort', option)}
            className="input-register"
            placeholder="Selecione a origem"
          />

          <ErrorMessage component="p" className="error-message-error" name="originPort" />
        </Flex>

        <Flex direction="column" gridColumn={'span 3'}>
          <LabelDefault name="originPort" text="Destino" />

          <Select
            options={portsOptions}
            styles={selectCustomStyles()}
            id="destinationPort"
            name="destinationPort"
            value={values.destinationPort}
            onChange={(option) => setFieldValue('destinationPort', option)}
            className="input-register"
            placeholder="Selecione o destino"
          />

          <ErrorMessage component="p" className="error-message-error" name="destinationPort" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <Flex>
            <LabelDefault name="dangerousClass" text="Classe IMO" />

            <Tooltip label="IMO - Organização Marítima Internacional" placement="auto">
              <Box ml="5px">
                <MdHelp color="#422C76" />
              </Box>
            </Tooltip>
          </Flex>

          <Select
            styles={selectCustomStyles()}
            id="dangerousClass"
            name="dangerousClass"
            options={dangerousClassOptions}
            value={values.dangerousClass}
            onChange={(option) => setFieldValue('dangerousClass', option)}
            className="input-register"
            placeholder="Selecione a classe"
          />

          <ErrorMessage component="p" className="error-message-error" name="dangerousClass" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <Flex>
            <LabelDefault name="incoterm" text="Incoterm" />

            <Tooltip label="International Commercial Terms / Termos Internacionais de Comércio" placement="auto">
              <Box ml="5px">
                <MdHelp color="#422C76" />
              </Box>
            </Tooltip>
          </Flex>

          <Select
            options={incotermOptions}
            styles={selectCustomStyles()}
            value={values.incoterm}
            onChange={(option) => {
              setFieldValue('incoterm', option);
              setRequiredList({
                ...requiredList,
                incoterm: option?.value,
              });
            }}
            className="input-register"
            placeholder="Selecione o incoterm"
          />

          <ErrorMessage component="p" className="error-message-error" name="incoterm" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="availableDate" text="Prontidão da mercadoria / carga" />

          <Input
            type="date"
            name="availableDate"
            cursor="pointer"
            bg="#F2F2F2"
            borderColor="#70707033"
            onChange={handleChange}
            value={values.availableDate}
          />

          <ErrorMessage component="p" className="error-message-error" name="availableDate" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="isPacklistReceived" text="Packing List" />

          <Select
            styles={selectCustomStyles()}
            options={yesOrNoOptions}
            name="isPacklistReceived"
            value={values.isPacklistReceived}
            onChange={(option) => setFieldValue('isPacklistReceived', option)}
            className="input-register"
            placeholder="Selecione se é Packlist liberado"
          />

          <ErrorMessage component="p" className="error-message-error" name="isPacklistReceived" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="inlandZipCode" text="Inland ZIP code" />

          <InputDefault
            setFieldValue={setFieldValue}
            value={values.inlandZipCode}
            name="inlandZipCode"
            placeholder="Preencha o inland zip code"
          />

          <ErrorMessage component="p" className="error-message-error" name="inlandZipCode" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="currency" text="Moeda" />

          <Select
            styles={selectCustomStyles()}
            id="currency"
            name="currency"
            placeholder="Selecione uma moeda"
            options={currency}
            value={values.currency}
            onChange={(option) => setFieldValue('currency', option)}
          />

          <ErrorMessage component="p" className="error-message-error" name="currency" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="valueOfTheMerchandise" text="Valor da mercadoria" />

          <NumericFormat
            placeholder="Insira o valor da mercadoria"
            className="custom-input-mask"
            type="text"
            displayType="input"
            thousandSeparator="."
            decimalSeparator=","
            thousandsGroupStyle="thousand"
            id="valueOfTheMerchandise"
            name="valueOfTheMerchandise"
            value={values.valueOfTheMerchandise}
            decimalScale={2}
            allowNegative={true}
            fixedDecimalScale={true}
            onValueChange={(values) => {
              const { floatValue } = values;
              setFieldValue('valueOfTheMerchandise', floatValue);
            }}
          />

          <ErrorMessage component="p" className="error-message-error" name="valueOfTheMerchandise" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="invoiceValue" text="Valor da invoice" />

          <NumericFormat
            placeholder="Preencha o valor da invoice"
            className="custom-input-mask"
            type="text"
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            displayType="input"
            name="invoiceValue"
            id="invoiceValue"
            decimalScale={2}
            allowNegative={true}
            fixedDecimalScale={true}
            value={values.invoiceValue}
            onValueChange={(values) => {
              const { floatValue } = values;
              setFieldValue('invoiceValue', floatValue);
            }}
          />

          <ErrorMessage component="p" className="error-message-error" name="invoiceValue" />
        </Flex>

        <Flex direction="column" gridColumn={'span 1'}>
          <LabelDefault name="weigth" text="Peso bruto (kg)" />

          <NumericFormat
            placeholder="Preencha o peso bruto"
            className="custom-input-mask"
            type="text"
            displayType="input"
            thousandSeparator="."
            decimalSeparator=","
            thousandsGroupStyle="thousand"
            id="weigth"
            name="weigth"
            value={values.weigth}
            decimalScale={2}
            allowNegative={true}
            fixedDecimalScale={true}
            onValueChange={(values) => {
              const { floatValue } = values;
              setFieldValue('weigth', floatValue);
            }}
          />

          <ErrorMessage component="p" className="error-message-error" name="weigth" />
        </Flex>

        {values.modality.label?.includes('Marítimo') && (
          <Flex direction="column" gridColumn={'span 1'}>
            <Flex>
              <LabelDefault name="quoteType" text="Tipo de container" />

              <Tooltip label="Tipo de carga no container: FCL (Full Container Load) ou LCL (Less Container Load)" placement="auto">
                <Box ml="5px">
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            </Flex>

            <Select
              options={quoteTypeOptions}
              styles={selectCustomStyles()}
              value={values.quoteType}
              onChange={(option) => {
                setFieldValue('quoteType', option);

                if (option?.value === 'LCL') {
                  setRequiredList({
                    ...requiredList,
                    quoteType: option?.value,
                  });
                }
              }}
              className="input-register"
              placeholder="Selecione o tipo"
            />

            <ErrorMessage component="p" className="error-message-error" name="quoteType" />
          </Flex>
        )}
      </Grid>
    </Flex>
  );
};

export default FormGeneralInfo;
