import React from 'react';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex } from '@chakra-ui/react';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={15} transform="rotate(-55)">
        {payload.value}
      </text>
    </g>
  );
};

const tickFormatter = (tickItem) => {
  return tickItem.toLocaleString('pt-BR');
};

const convertVolume = (value) => {
  return parseInt(value, 10);
};

const valueFormat = (valor) => {
  return valor.toLocaleString('pt-BR');
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
        {payload.map((entity, key) => (
          <Flex direction="row" align="center" key={key}>
            {entity.dataKey === 'qttVolume' ? 'Volumes: ' : 'Notas: '}

            {valueFormat(entity.value)}
          </Flex>
        ))}
      </Flex>
    );
  }
  return null;
};

const WarehouseCityChart = ({ entity }) => {
  const formattedEntity = entity.map((item) => ({
    ...item,
    qttVolume: convertVolume(item.qttVolume),
  }));

  return (
    <ResponsiveContainer height={440}>
      <LineChart
        data={formattedEntity}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 180,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="city" tick={<CustomizedAxisTick />} interval={0} />
        <YAxis yAxisId="left" orientation="left" stroke="#422C76" tickFormatter={tickFormatter} />
        <YAxis yAxisId="right" orientation="right" stroke="#E52359" tickMargin={10} tickFormatter={tickFormatter} />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top"/>
        <Line type="monotone" dataKey="qttVolume" name="Volumes" yAxisId="left" stroke="#422C76" strokeWidth={2} />
        <Line type="monotone" dataKey="qttInvoice" name="Notas" yAxisId="right" stroke="#E52359" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default WarehouseCityChart;
