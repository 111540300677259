import React, { useEffect, useState } from 'react';

import { DirectionsRenderer, GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';

import { Box, Flex, Switch, Text } from '@chakra-ui/react';

import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import { descriptionStatusTrip } from '../../utils/translate';

import MapPopup from './MapPopup';

const Map = ({
  tripList = [],
  mapBounds = null,
  calculateBounds,
  center,
  setCenter,
  currentZoom,
  setCurrentZoom,
  currentTripData = null,
  setCurrentTripData = null,
  currentTripIsTrackable = false,
  currentTrajectory,
  setCurrentTrajectory,
  isLoading = false,
  isMobile = false,
  fullscreenControl = true,
  mapTypeControl = true,
}) => {
  const [map, setMap] = useState(null);

  const [showAllTrips, setShowAllTrips] = useState(false);
  const [showOnlyCurrent, setShowOnlyCurrent] = useState(true);
  const [showAllTrajectories, setShowAllTrajectories] = useState(false);
  const [showAllCheckpoints, setShowAllCheckpoints] = useState(false);

  const [selectedMarker, setSelectedMarker] = useState(null);

  const [pointsRealtime, setPointsRealtime] = useState([]);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const containerStyle = {
    width: '100%',
    height: !isMobile ? '500px' : '250px',
  };

  const handleMarkerClick = (marker, trip, inZoom = 0) => {
    if (marker) {
      setSelectedMarker(marker);
      if (trip) {
        setCurrentTripData(trip);
        if (inZoom > 0) {
          setCenter({
            lat: marker.lat,
            lng: marker.lng,
          });
          setCurrentZoom(inZoom);
        }
      } else {
        setCurrentTripData(null);
      }
    } else {
      setSelectedMarker(null);
    }
  };

  const getColoredTruckSvg = (color) => {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="611.999" height="387.592" viewBox="0 0 611.999 387.592">
        <g id="Grupo_4056" data-name="Grupo 4056" transform="translate(0 -112.204)">
          <path
            fill="${color || '#000'}"
            fill-opacity="0.9"
            id="Caminho_2306" data-name="Caminho 2306" d="M504.9,395.756a52.02,52.02,0,1,0,52.02,52.02A52.083,52.083,0,0,0,504.9,395.756Zm0,67.32a15.3,15.3,0,1,1,15.3-15.3,15.314,15.314,0,0,1-15.3,15.3Z"
          />
          <path
            fill="${color || '#000'}"
            fill-opacity="0.9"
            id="Caminho_2307" data-name="Caminho 2307" d="M499.918,179.518H410.04a12.241,12.241,0,0,0-12.24,12.24v238.68a12.241,12.241,0,0,0,12.24,12.24h12.981A12.2,12.2,0,0,0,435.23,432.3c4.8-36.806,34.162-59.633,69.676-59.633S569.778,395.5,574.582,432.3a12.194,12.194,0,0,0,12.209,10.373h12.968A12.245,12.245,0,0,0,612,430.438V311.1a12.218,12.218,0,0,0-2.852-7.852L509.3,183.906a12.246,12.246,0,0,0-9.387-4.388Zm-77.638,97.92v-61.2A12.241,12.241,0,0,1,434.52,204h53.917a12.242,12.242,0,0,1,9.4,4.406l51,61.2a12.24,12.24,0,0,1-9.406,20.074H434.52a12.236,12.236,0,0,1-12.24-12.24ZM12.24,442.684H43.581A12.2,12.2,0,0,0,55.79,432.311c4.8-36.806,34.162-59.633,69.676-59.633s64.872,22.828,69.676,59.633a12.194,12.194,0,0,0,12.209,10.373H361.08a12.244,12.244,0,0,0,12.24-12.24v-306a12.245,12.245,0,0,0-12.24-12.24H12.24A12.241,12.241,0,0,0,0,124.444v306A12.24,12.24,0,0,0,12.24,442.684Z"
          />
          <path
            fill="${color || '#000'}"
            fill-opacity="0.9"
            id="Caminho_2308" data-name="Caminho 2308" d="M125.46,395.756a52.02,52.02,0,1,0,52.02,52.02,52.083,52.083,0,0,0-52.02-52.02Zm0,67.32a15.3,15.3,0,1,1,15.3-15.3,15.314,15.314,0,0,1-15.3,15.3Z"
          />
        </g>
      </svg>
    `;
  };

  const calculateZoomForBounds = (bounds) => {
    let zoom;

    // Se os limites do mapa forem válidos
    if (bounds.getNorthEast() && bounds.getSouthWest()) {
      // Calcula a diferença em latitude e longitude
      let latDiff = bounds.getNorthEast().lat() - bounds.getSouthWest().lat();
      let lngDiff = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();

      // Calcula o zoom para que os limites do mapa estejam visíveis
      let latZoom = Math.floor(Math.log(960 / latDiff) / Math.LN2);
      let lngZoom = Math.floor(Math.log(960 / lngDiff) / Math.LN2);
      zoom = Math.min(latZoom, lngZoom);
    } else {
      zoom = 2; // Zoom padrão
    }

    return zoom;
  };

  useEffect(() => {
    if (!mapBounds || !map || typeof google === 'undefined' || !google) {
      return;
    }

    map.fitBounds(mapBounds);

    // Calcula o zoom com base nos limites do mapa e define o zoom atual
    if (currentTripData == null) {
      let zoom = calculateZoomForBounds(mapBounds);
      setCurrentZoom(zoom);
    }
  }, [mapBounds]);

  useEffect(() => {
    if (currentTripData && currentTripData.identifier.length > 0) {
      setShowOnlyCurrent(true);
      setShowAllTrips(false);
      setShowAllTrajectories(false);
    } else {
      setShowOnlyCurrent(false);
    }
  }, [currentTripData]);

  return (
    <Box border="1px" borderColor="#7070703D" bgColor="#FFFFFF" position={'relative'}>
      {currentTripData !== null && (
        <Box w="100%" h={'200px'} position="absolute">
          <Flex
            position="absolute"
            top="10px"
            left="10px"
            zIndex="10"
            p="10px 15px"
            pointerEvents="none"
            borderRadius="5px"
            gap="10px">
            <Flex
              bgColor="linkColor"
              top="10px"
              left="10px"
              zIndex="10"
              p="10px 15px"
              pointerEvents="none"
              borderRadius="5px"
              direction="column"
              gap="10px">
              <Text color="white" fontSize="20px">
                {`Viagem #${currentTripData.code} - ` +
                  descriptionStatusTrip(currentTripData.status ?? currentTripData.timeline.currentStatus)}
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}
      <ScreenLoader isLoading={isLoading}>
        {isLoaded ? (
          <>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={currentZoom}
              options={{
                mapId: '6d2819c49ba264e7',
                minZoom: 1,
                zoomControl: true,
                streetViewControl: false,
                keyboardShortcuts: false,
                scaleControl: false,
                gestureHandling: true,
                mapTypeControl: false,
                fullscreenControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                fullscreenControl: fullscreenControl,
                mapTypeControl: mapTypeControl,
              }}
              onLoad={(map) => {
                setMap(map);
              }}>
              {/* Markers de viagens */}
              {/* {tripList.map((trip, key) => {
                return (
                  <React.Fragment key={'markers-' + trip.identifier + key}>
                    {trip.trajectoryMarkers.map((marker, markerKey) => {
                      if (!showAllTrips && !trip.isTrackable) {
                        return <React.Fragment key={'markers-' + trip.identifier + key + markerKey} />;
                      }

                      if (showOnlyCurrent && currentTripData && trip.identifier !== currentTripData.identifier) {
                        return <React.Fragment key={'markers-' + trip.identifier + key + markerKey} />;
                      }

                      return (
                        <Marker
                          key={'markers-' + trip.identifier + key + markerKey}
                          position={marker}
                          icon={marker?.icon}
                          label={marker?.label}
                          title={marker?.title}
                          optimized={false}
                          onClick={() => {
                            handleMarkerClick(marker, trip);
                          }}>
                          {selectedMarker === marker && (
                            <MapPopup key={markerKey} point={selectedMarker} onCloseClick={() => handleMarkerClick(null, null)} />
                          )}
                        </Marker>
                      );
                    })}
                  </React.Fragment>
                );
              })} */}

              {/* Directions de viagens */}
              {tripList.map((trip, key) => {
                // if (!trip.trajectoryPath || trip.trajectoryPath.length === 0) {
                //   return <React.Fragment key={'directions-' + trip.identifier + key} />;
                // }

                // if (!trip.isTrackable && (!showAllTrips || !showAllTrajectories)) {
                //   return <React.Fragment key={'directions-' + trip.identifier + key} />;
                // }

                // if (showOnlyCurrent && currentTripData && trip.identifier !== currentTripData.identifier) {
                //   return <React.Fragment key={'directions-' + trip.identifier + key} />;
                // }

                if ((currentTripData == null || trip.identifier === currentTripData.identifier) && trip.lastCheckpointMarker) {
                  return (
                    <Marker
                      key={'current-marker-last-position' + trip.identifier}
                      position={trip.lastCheckpointMarker}
                      label={trip.lastCheckpointMarker?.label}
                      title={trip.lastCheckpointMarker?.title}
                      zIndex={200}
                      onMouseOver={() => {
                        if (selectedMarker == null) setSelectedMarker(trip.lastCheckpointMarker);
                      }}
                      onClick={() => {
                        handleMarkerClick(trip.lastCheckpointMarker, trip, 13);
                      }}
                      icon={{
                        url:
                          'data:image/svg+xml;charset=UTF-8,' +
                          encodeURIComponent(getColoredTruckSvg(trip.lastCheckpointMarker?.color)),
                        scaledSize: { width: 35, height: 35 },
                      }}>
                      {selectedMarker === trip.lastCheckpointMarker && (
                        <MapPopup key={'last-position'} point={selectedMarker} onCloseClick={() => handleMarkerClick(null, null)} />
                      )}
                    </Marker>
                  );
                }

                // return (
                //   <>
                //     {trip.trajectoryPath.map((trajectory, trajectoryKey) => {
                //       let polylineOptions = {
                //         strokeColor: trip.color,
                //         strokeWeight: 4,
                //       };
                //       if (trajectory.status !== 'COMPLETED') {
                //         polylineOptions.strokeOpacity = 0;
                //         polylineOptions.icons = [
                //           {
                //             icon: {
                //               path: 'M 0,-1 0,1',
                //               strokeOpacity: 1,
                //               scale: 2,
                //             },
                //             offset: '0',
                //             repeat: '10px',
                //           },
                //         ];
                //       }

                //       return (
                //         <DirectionsRenderer
                //           key={'directions-' + trip.identifier + key + trajectoryKey}
                //           directions={trajectory.directions}
                //           options={{
                //             preserveViewport: true,
                //             suppressMarkers: true,
                //             polylineOptions: polylineOptions,
                //           }}
                //         />
                //       );
                //     })}
                //   </>
                // );
              })}

              {/* Viagem atual */}
              {currentTripData &&
                tripList.map((trip, key) => {
                  if (trip.identifier !== currentTripData.identifier) {
                    return <React.Fragment key={'current-' + trip.identifier + key} />;
                  }

                  return (
                    <React.Fragment key={key}>
                      {/* Mostra os pontos de início e fim */}
                      {trip.trajectoryMarkers.map((marker, markerKey) => {
                        return (
                          <Marker
                            key={'current-markers-' + trip.identifier + key + markerKey}
                            position={marker}
                            icon={marker?.icon}
                            label={marker?.label}
                            title={marker?.title}
                            optimized={false}
                            zIndex={201}
                            onClick={() => {
                              handleMarkerClick(marker, trip);
                            }}>
                            {selectedMarker === marker && (
                              <MapPopup key={markerKey} point={selectedMarker} onCloseClick={() => handleMarkerClick(null, null)} />
                            )}
                          </Marker>
                        );
                      })}

                      {/* Mostra o trajeto, se houver */}
                      {currentTrajectory?.map((trajectory, trajectoryKey) => {
                        let polylineOptions = {
                          strokeColor: trip.color,
                          strokeWeight: 4,
                        };
                        if (trajectory.status !== 'COMPLETED') {
                          polylineOptions.strokeOpacity = 0;
                          polylineOptions.icons = [
                            {
                              icon: {
                                path: 'M 0,-1 0,1',
                                strokeOpacity: 1,
                                scale: 2,
                              },
                              offset: '0',
                              repeat: '10px',
                            },
                          ];
                        }
                        return (
                          <DirectionsRenderer
                            preserveViewport={true}
                            key={'current-diretion-' + trip.identifier + key + trajectoryKey}
                            directions={trajectory.directions}
                            options={{
                              preserveViewport: true,
                              suppressMarkers: true,
                              polylineOptions: polylineOptions,
                            }}
                          />
                        );
                      })}

                      {/* Mostra os checkpoints, se for para mostrar */}
                      {showAllCheckpoints &&
                        trip.checkpointsMarkers.map((marker, markerKey) => {
                          return (
                            <Marker
                              key={'current-marker-' + trip.identifier + key + markerKey}
                              position={marker}
                              icon={marker?.icon}
                              label={marker?.label}
                              title={marker?.title}
                              optimized={false}
                              onClick={() => {
                                handleMarkerClick(marker, trip);
                              }}>
                              {selectedMarker === marker && (
                                <MapPopup key={markerKey} point={selectedMarker} onCloseClick={() => handleMarkerClick(null, null)} />
                              )}
                            </Marker>
                          );
                        })}

                      {/* Mostra a última posição, se houver */}
                      {/*trip.lastCheckpointMarker && (
                        <Marker
                          key={'current-marker-last-position' + trip.identifier}
                          position={trip.lastCheckpointMarker}
                          label={trip.lastCheckpointMarker?.label}
                          title={trip.lastCheckpointMarker?.title}
                          optimized={false}
                          icon={{
                            url: truck,
                            origin: {
                              x: 0,
                              y: -25,
                            },
                          }}
                          onClick={() => {
                            handleMarkerClick(trip.lastCheckpointMarker, trip);
                          }}>
                          {selectedMarker === trip.lastCheckpointMarker && (
                            <MapPopup
                              key={'last-position'}
                              point={selectedMarker}
                              onCloseClick={() => handleMarkerClick(null, null)}
                            />
                          )}
                        </Marker>
                          )*/}
                    </React.Fragment>
                  );
                })}

              <MarkerClusterer
                key={'cluster'}
                gridSize={15}
                title={'Clique para ver de perto'}
                maxZoom={2}
                minimumClusterSize={1}
                styles={[
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                    textColor: '#fff',
                    height: 53,
                    width: 53,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
                    textColor: '#fff',
                    height: 40,
                    width: 40,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png',
                    textColor: '#fff',
                    height: 66,
                    width: 66,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m4.png',
                    textColor: '#fff',
                    height: 78,
                    width: 78,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m5.png',
                    textColor: '#fff',
                    height: 90,
                    width: 90,
                  },
                ]}>
                {(clusterer) => {
                  return (
                    <div>
                      {tripList.map((trip, key) => {
                        return (
                          <React.Fragment key={'cluster-' + trip.identifier + key}>
                            {trip.trajectoryMarkers.map((marker, markerKey) => {
                              return (
                                <Marker key={'cluster-marker-' + trip.identifier + key + markerKey} position={marker} opacity={0} />
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  );
                }}
              </MarkerClusterer>
            </GoogleMap>
            <Flex top="10px" left="10px" bg="white" zIndex="99" p="10px 15px" borderRadius="5px" direction="row" gap="10px">
              {/*<Text color="primary" fontSize="12px" display="flex" gap="10px" mr="20px">
                Mostrar as viagens sem rastreio?
                <Switch
                  size="md"
                  colorScheme="teal"
                  isChecked={showAllTrips}
                  onChange={() => {
                    if (showAllTrips) {
                      // Trabalhar com a inversa
                      setShowAllTrajectories(false);
                    }

                    setShowAllTrips(!showAllTrips);
                  }}
                />
                </Text>*/}

              {/*showAllTrips && (
                <Text color="primary" fontSize="12px" display="flex" gap="10px">
                  Mostrar os trajetos sem rastreio?
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={showAllTrajectories}
                    onChange={() => {
                      if (!showAllTrajectories) {
                        // Trabalhar com a negativa, visto que o booleano está invertendo no set abaixo
                        setShowOnlyCurrent(false);
                      }

                      setShowAllTrajectories(!showAllTrajectories);
                    }}
                  />
                </Text>
                  )*/}

              {currentTripData && (
                <Text color="primary" fontSize="12px" display="flex" gap="10px">
                  Mostrar somente a viagem atual?
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={showOnlyCurrent}
                    onChange={() => {
                      if (!showOnlyCurrent) {
                        // Trabalhar com a negativa, visto que o booleano está invertendo no set abaixo
                        setShowAllTrajectories(false);
                        setShowAllTrips(false);
                      } else {
                        setCurrentTripData(null);
                        setCurrentTrajectory([]);
                        calculateBounds();
                      }

                      setShowOnlyCurrent(!showOnlyCurrent);
                    }}
                  />
                </Text>
              )}
              {currentTripData && currentTripIsTrackable && currentTripData.checkpoints && currentTripData.checkpoints.length > 0 && (
                <Text color="primary" fontSize="12px" display="flex" gap="10px">
                  Mostrar todos os pontos rastreados dessa viagem?
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={showAllCheckpoints}
                    onChange={() => {
                      setShowAllCheckpoints(!showAllCheckpoints);
                    }}
                  />
                </Text>
              )}
            </Flex>
          </>
        ) : (
          <></>
        )}
      </ScreenLoader>
    </Box>
  );
};

export default Map;
