import React from 'react';

import { Input } from '@chakra-ui/react';

const InputDefault = ({
  as,
  hidden = false,
  useRequiredCustom = false,
  isRequired = false,
  name,
  placeholder,
  setFieldValue = () => {},
  setFormData = () => {},
  type = 'text',
  value,
  variant = 'primary',
  className = 'input-register',
  background = '#F2F2F2',
  register = () => {},
  ...props
}) => {
  if (useRequiredCustom) {
    return (
      <Input
        autoComplete="off"
        bg={background}
        variant={variant}
        className={className}
        as={as}
        id={name}
        name={name}
        type={type}
        value={value}
        hidden={hidden}
        placeholder={placeholder}
        {...register(name, {
          required: !isRequired ? false : 'Campo obrigatório',
          onChange: (e) => setFormData({ [name]: e.target.value }),
        })}
        {...props}
      />
    );
  }

  return (
    <Input
      autoComplete="off"
      bg={background}
      variant={variant}
      className={className}
      as={as}
      id={name}
      name={name}
      type={type}
      value={value}
      hidden={hidden}
      placeholder={placeholder}
      onChange={(event) => setFieldValue(name, event.target.value)}
      {...register(name, { required: 'Campo obrigatório' })}
      {...props}
    />
  );
};

export default InputDefault;
