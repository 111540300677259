import React from 'react';

import { Button, Flex, TabPanel, Text } from '@chakra-ui/react';

import UpdateItem from './UpdateItem';

const UpdatesNotificationTab = ({
  type,
  entity,
  metaUpdate,
  metaComment,
  metaFavourite,
  loadMoreItemsUpdates,
  loadMoreItemsComments,
  loadMoreItemsfavouriteComments,
  isLoadingFavourites,
  isLoadingUpdates,
  isLoadingComments,
}) => {
  const handleLoadMoreUpdate = () => {
    loadMoreItemsUpdates();
  };

  const handleLoadMoreComment = () => {
    loadMoreItemsComments();
  };

  const handleLoadMoreFavourite = () => {
    loadMoreItemsfavouriteComments();
  };

  return (
    <TabPanel p="0px 0" h="full">
      {entity ? (
        entity.length > 0 ? (
          entity.map((item, key) => {
            const isLastItem = key === entity.length - 1;
            return <UpdateItem key={key} type={type} entity={item} isLastItem={isLastItem} />;
          })
        ) : (
          <Flex justify="center" align="center" h="full">
            <Text>Não há resultados que correspondam aos filtros selecionados.</Text>
          </Flex>
        )
      ) : (
        <Flex justify="center" align="center" h="full">
          <Text>Carregando...</Text>
        </Flex>
      )}

      {metaUpdate?.total_pages > 1 && metaUpdate?.current_page < metaUpdate?.total_pages && (
        <Button h="30" w="full" mb="20px" textAlign="center" onClick={handleLoadMoreUpdate} isLoading={isLoadingUpdates}>
          Ver mais
        </Button>
      )}
      {metaComment?.total_pages > 1 && metaComment?.current_page < metaComment?.total_pages && (
        <Button h="30" w="full" mb="20px" textAlign="center" onClick={handleLoadMoreComment} isLoading={isLoadingComments}>
          Ver mais
        </Button>
      )}
      {metaFavourite?.total_pages > 1 && metaFavourite?.current_page < metaFavourite?.total_pages && (
        <Button h="30" w="full" mb="20px" textAlign="center" onClick={handleLoadMoreFavourite} isLoading={isLoadingFavourites}>
          Ver mais
        </Button>
      )}
    </TabPanel>
  );
};

export default UpdatesNotificationTab;
