import commercialValidation from './commercialValidation';
import contactValidation from './contactValidation';
import financialValidation from './financialValidation';
import operationalValidation from './operationalValidation';
import registrationValidation from './registrationValidation';
import responsibleValidation from './responsibleValidation';
import tributaryValidation from './tributaryValidation';

const yup = require('yup');

const ValidationsSpecification = (isDraft = false, isSecondaryZone = false) => {
  let validation = yup.object().shape({
    documents: yup.array(),
    registration: registrationValidation(),
    contact: contactValidation(isDraft),
    commercial: commercialValidation(isDraft),
    tributary: tributaryValidation(isDraft),
    operational: operationalValidation(isDraft, isSecondaryZone),
    additionalInformation: yup.string(),
    nationalFreightAdditionalInformation: yup.string(),
    financial: financialValidation(isDraft),
    responsibleUsers: responsibleValidation(isDraft),
  });

  return validation;
};

export default ValidationsSpecification;
