import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsTrip, filterOptionsTripKanban } from '../../../utils/filters/filterPresets';

import TravelCte from './components/CTE/TravelCte';
import TravelKanban from './components/Kanban/TravelKanban';
import ModalFreight from './components/TravelList/ModalFreight';
import TravelList from './components/TravelList/TravelList';

function TravelManagementPage() {
  const hasPermission = permissions.logisticTripFullManagement || permissions.logisticTripView;

  const request = useRef(0);

  const [tab, setTab] = useState('progress');

  const [update, setUpdate] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);
  const [filterSelected, setFilterSelected] = useState([]);

  const [list, setList] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests.listDriverFreight(filters, page).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList(data.data);
      setMetadata(data?.meta);
      setFilterSelected(filters);
      setIsLoading(false);
    });
  };

  const reloadList = () => {
    setIsLoading(true);

    requests.listDriverFreight(filterSelected, metadata.current_page).then((data) => {
      setList(data.data);
      setIsLoading(false);
    });
  };

  const getFilterOptions = async () => {
    if (tab === 'kanban') {
      setFilterOptions(await filterOptionsTripKanban());
    } else {
      setFilterOptions(await filterOptionsTrip());
    }
  };

  useEffect(() => {
    getFilterOptions();
  }, [tab]);

  useEffect(() => {
    getFilterOptions();

    const currentUrl = window.location.href;
    let aux = currentUrl.split('#');
    if (aux.length === 2) {
      if (aux[1].indexOf('kanban') !== -1) {
        setTab('kanban');
      } else {
        setTab(aux[1]);
      }
    }
  }, []);

  const exportListFABAction = (filters, key) => {
    //preset
    const extension = `xlsx`;
    const endpoint = `/adm/driver-freight/export`;
    const fileName = `report-trip`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  return (
    <Page
      screen="trip"
      title="Gestão de Viagens"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Motorista, viagem, transportadora, arquivo ou placa"
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      allowFiltersOverride={true}
      allowEmptySearchPeriod={true}
      showRefreshData={true}
      showFilters={true}
      FAB={
        tab === 'progress' && [
          permissions.logisticTripCreate && {
            title: 'Exportar .csv',
            text: 'Exportar .csv',
            action: (
              <Button
                bgColor="green"
                color="white"
                borderRadius="50px"
                shadow="xl"
                h="50px"
                py="15px"
                px="30px"
                onClick={() => {
                  onOpen();
                }}
                _hover={{ bgColor: '#70D499' }}>
                <MdAdd />
                <Text textStyle="paragraph" ml="10px">
                  Nova viagem
                </Text>
              </Button>
            ),
            modality: 'custom',
          },
          {
            title: 'Exportar viagem',
            text: 'Exportar arquivo (excel) com dados de viagens',
            action: exportListFABAction,
            modality: 'export-csv',
          },
        ]
      }>
      {tab === 'progress' && (
        <TravelList
          tab={tab}
          setTab={setTab}
          load={isLoading}
          list={list}
          action={action}
          setAction={setAction}
          metadata={metadata}
          setMetadata={setMetadata}
          reloadList={reloadList}
        />
      )}
      <ModalFreight
        entity={false}
        isOpen={isOpen}
        onClose={onClose}
        title="Cadastro de viagem"
        subTitle="Você pode adicionar os dados e salvar sua viagem como rascunho."
        tab={tab}
        reloadList={reloadList}
      />

      {tab === 'kanban' && (
        <TravelKanban filterSelected={filterSelected} action={update} setAction={setUpdate} tab={tab} setTab={setTab} />
      )}

      {tab === 'cte' && <TravelCte tab={tab} filterSelected={filterSelected} setTab={setTab} />}
    </Page>
  );
}

export default TravelManagementPage;
