import React from 'react';

import { FaDownload, FaPenNib /*, FaUndo*/ } from 'react-icons/fa';
import { MdFileOpen, MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
} from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';

const ContractManagementCard = ({
  list,
  action,
  setAction,
  metadata,
  setMetadata,
  handleView,
  handleCurrentIdentifier,
  handleCurrentStatus,
  handleCurrentModality,
  handleOpenModalStatus,
}) => {
  let navigate = useNavigate();

  const handleColor = (status) => {
    switch (status) {
      case 'CADASTRADO':
        return 'status-100';
      case 'ENVIADO':
        return 'status-300';
      case 'EM_ASSINATURA':
        return 'yellow-500';
      case 'ASSINADO':
      case 'RENOVADO':
        return 'green';
      case 'EXPIRADO':
      case 'CANCELADO':
        return 'red';
      default:
        return 'gray-200';
    }
  };

  const handleDownload = (identifier, uploadName) => {
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/contract/${identifier}/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangeStatus = (identifier, status) => {
    handleCurrentIdentifier(identifier);
    handleCurrentStatus(status);
    handleCurrentModality('create');
    handleOpenModalStatus(true);
  };

  return (
    <>
      <Grid>
        <Flex border="1px" borderColor={'#70707036'}>
          <Grid templateColumns={'180px 10px auto'} justifyContent={'space-between'} w="full" p={'1px 1px'} borderColor={'#70707036'}>
            <GridItem fontWeight={700}>Contrato</GridItem>
            <GridItem> </GridItem>
          </Grid>
        </Flex>
        <Flex border="1px" borderColor={'#70707036'}>
          <Accordion w={'100%'}>
            {list?.map((entity, key) => (
              <AccordionItem key={key} style={{ '--delay': `0.${key}s` }} _odd={{ bg: '#eee' }} _hover={'none'}>
                <AccordionButton>
                  <Flex alignItems="center" templateColumns={'1fr 40px auto'} justifyContent={'space-between'} w={'100%'}>
                    <Box textAlign={'left'} color={'#707070'}>
                      <Flex alignItems="center">
                        <Box mr="2">
                          <Flex
                            align="center"
                            w={'18px'}
                            h={'18px'}
                            gap="10px"
                            borderRadius="15px"
                            bgColor={handleColor(entity.status)}
                            color="white"></Flex>
                        </Box>
                        {entity.fileName ?? '-'}
                      </Flex>
                    </Box>
                    <Box textAlign={'end'}>
                      <AccordionIcon />
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel p={'15px 20px'} bg={'white'}>
                  <Grid flexDir={'column'} gridTemplateColumns={'1fr'} fontSize={'14px'}>
                    <Text>
                      <strong>Título:</strong> {entity.title ?? '-'}
                    </Text>
                    <Text>
                      <strong>Descrição:</strong> {entity.description ?? '-'}
                    </Text>
                    <Text>
                      <strong>Dt Criação:</strong> {entity.createdAtFormatted ?? '-'}
                    </Text>
                    <Text>
                      <strong>Tipo de vencimento:</strong> {entity.expirationTypeReadable ?? '-'}
                    </Text>
                    <Text>
                      <strong>Expiração:</strong> {entity.expiresAtFormatted ?? '-'}
                    </Text>
                    <Flex direction="column">
                      <Text fontWeight="bold">Assinaturas:</Text>
                      <Text>
                        Realizadas: {entity?.verifiedSignatures?.signed ?? 0}
                        {entity?.verifiedSignatures?.signed > 0 && (
                          <>{` ( ${entity?.verifiedSignatures?.responsiblesSigned?.join(', ')} )`}</>
                        )}
                      </Text>
                      <Text>
                        Pendentes: {entity?.verifiedSignatures?.unsigned ?? 0}
                        {entity?.verifiedSignatures?.unsigned > 0 && (
                          <>{` ( ${entity?.verifiedSignatures?.responsiblesUnsigned?.join(', ')} )`}</>
                        )}
                      </Text>
                    </Flex>
                    <Text>
                      <strong>Status:</strong> {entity.statusReadable ?? '-'}
                    </Text>
                    <Divider mt="4" mb="4" />
                    <Text fontWeight="bold">Ações:</Text>
                    <Flex gap="0.62rem" alignItems="center">
                      <Box>
                        <IconButton
                          bg="transparent"
                          icon={<MdFileOpen size={26} color="#6C48C2" />}
                          onClick={(decision) => {
                            if (decision) {
                              handleView(entity?.identifier, entity?.fileName);
                            }
                          }}
                        />
                      </Box>

                      <Box>
                        <IconButton
                          bg="transparent"
                          icon={<MdOpenInNew size={26} color="#6C48C2" />}
                          onClick={(decision) => {
                            if (decision) {
                              navigate(`/vendemmia/contract-management/detail/${entity?.identifier}`);
                            }
                          }}
                        />
                      </Box>

                      <Box>
                        <IconButton
                          bg="transparent"
                          icon={<FaDownload size={23} color="#6C48C2" />}
                          onClick={(decision) => {
                            if (decision) {
                              handleDownload(entity?.identifier, entity?.fileName);
                            }
                          }}
                        />
                      </Box>

                      <Box>
                        {entity?.status !== 'EM_RASCUNHO' ? (
                          <FaPenNib size={23} color="#B1B1B1" />
                        ) : (
                          <IconButton
                            bg="transparent"
                            icon={<FaPenNib size={23} color="#6C48C2" />}
                            onClick={(decision) => {
                              if (decision) {
                                handleChangeStatus(entity?.identifier, entity?.status);
                              }
                            }}
                          />
                        )}
                      </Box>
                    </Flex>
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}

            {list?.length == 0 && (
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            )}
          </Accordion>
        </Flex>
      </Grid>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default ContractManagementCard;
