import React from 'react';

import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const IncomeAndExpenseChart = ({ inOutGraphDataValue }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload?.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.dataKey == 'inflow' ? 'Entrada' : 'Saída'} :</Text>

                <Text>{entity.value.toLocaleString()}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  return (
    <Flex p={'50px 100px 45px'} flexDirection={'column'}>
      <Text p={'0 0 65px 60px'} fontSize={'26px'} lineHeight={'34px'} color={'primary'} fontWeight={'600'}>
        Entrada e saída nos próximos dias
      </Text>

      <ResponsiveContainer height={500}>
        <BarChart data={inOutGraphDataValue.data}>
          <CartesianGrid />

          <XAxis dataKey="title" fontSize={'12px'} />
          <YAxis />

          <Tooltip content={<CustomTooltip />} />

          <Legend
            payload={[
              { value: 'Entrada', type: 'square', id: 'inflow', color: '#AA8BE0' },
              { value: 'Saída', type: 'square', id: 'outflow', color: '#E52359' },
            ]}
          />

          <ReferenceLine y={'0'} stroke="#000" />

          <Bar dataKey="inflow" barSize={50} fill="#AA8BE0" />
          <Bar dataKey="outflow" barSize={50} fill="#E52359" />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default IncomeAndExpenseChart;
