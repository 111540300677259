const yup = require('yup');

const registrationValidation = () => {
  let validation = yup.object().shape({
    vendemmiaOperationUnit: yup.array().required('Campo obrigatório').nullable(),
    operationType: yup.string().required('Campo obrigatório').nullable(),
    companyName: yup.string().required('Campo obrigatório'),
    companyFantasyName: yup.string().required('Campo obrigatório'),
    companyOperationDocumentNumber: yup.string().required('Campo obrigatório'),
    companyOperationSubscriptionState: yup.string().required('Campo obrigatório'),
    companyOperationName: yup.string().required('Campo obrigatório'),
    companyBranches: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required('Campo obrigatório'),
          documentNumber: yup.string().required('Campo obrigatório'),
        })
      )
      .test({
        message: 'Todos os campos devem estar preenchidos.',
        test: (arr) => {
          return arr.every(({ documentNumber, name }) => {
            return documentNumber && name;
          });
        },
      }),
  });

  return validation;
};

export default registrationValidation;
