import React from 'react';

import { Box, Flex, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';

import TavelListCollapse from './TavelListCollapse';

import './styles.css';

const TableTravel = ({ driver, list, load }) => {
  return (
    <TableContainer>
      <Table maxW="100%">
        <Thead>
          <Tr h="64px" className="border">
            <Th>
              <Box>*</Box>
            </Th>
            <Th>
              <Box></Box>
            </Th>
            <Th>
              <Box>
                <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Viagem
                </Text>
              </Box>
            </Th>
            <Th>
              <Box>
                <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Início
                </Text>
              </Box>
            </Th>
            <Th>
              <Box>
                <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Final
                </Text>
              </Box>
            </Th>
            <Th>
              <Box>
                <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Status
                </Text>
              </Box>
            </Th>
            <Th>
              <Box>*</Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {!load && (
            <>
              {list?.map((entity, key) => {
                return <TavelListCollapse entity={entity} key={key} driver={driver} />;
              })}
            </>
          )}
        </Tbody>
      </Table>

      {load ? (
        <Flex justify="center" my="25px">
          <span>Carregando...</span>
        </Flex>
      ) : (
        <>
          {list?.length === 0 && (
            <Flex justify="center" my="25px">
              Nenhum dado encontrado para os parâmetros filtrados.
            </Flex>
          )}
        </>
      )}
    </TableContainer>
  );
};

export default TableTravel;
