import React, { useCallback, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { MdAttachFile, MdDownload } from 'react-icons/md';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import ModalUpload from '../../../../components/Modal/Upload/ModalUpload';
import requests from '../../../../services/requests';
import closeFloatActionButton from '../../../../utils/actions/modal/closeFloatActionButton';

import ModalDeleteDocument from './ModalDeleteDocument';
import ModalViewDocument from './ModalViewDocument';

const ModalListDocuments = ({ quote = {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documents, setDocuments] = useState([]);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [isDownloadingAllFiles, setIsDownloadingAllFiles] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  const getDocuments = () => {
    setIsLoadingDocuments(true);
    requests.getFilesListByQuote(quote.identifier, metadata?.current_page).then((response) => {
      setDocuments(response.data);
      setMetadata(response.meta);
      setIsLoadingDocuments(false);
    });
  };

  const downloadFile = useCallback((identifier, filename, ext) => {
    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/quote/file/${quote.identifier}/${identifier}/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = filename + '.' + ext;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

  if (!quote?.identifier) {
    return;
  }

  const typeOptions = [
    { value: 'PACKLIST', label: 'PACKLIST' },
    { value: 'DIVERSOS', label: 'DIVERSOS' },
  ];

  useEffect(() => {
    if (quote?.identifier) {
      getDocuments();
    }
  }, [quote]);

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .saveQuoteDocsUploads(quote.identifier, docs, config)
        .then(() => {
          getDocuments();
          onOpen();
          resolve();
        })
        .catch(() => {
          onOpen();
          reject();
        });
    });
  };

  const downloadAllFiles = useCallback(() => {
    setIsDownloadingAllFiles(true);

    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/quote/file/${quote.identifier}/all/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = quote.identifier + '.zip';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsDownloadingAllFiles(false);
  });

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    onOpen();
    closeFloatActionButton();
  };

  return (
    <>
      <ModalUpload
        title="Importar novo arquivo"
        subtitle="Upload de documentos da cotação"
        accept=".xml, .pdf, .png, .jpeg"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={openModalUploadFile}
        onOpen={onOpen}
        onClick={flagClick.bind(this)}
        onClose={actionModalUploadFile}
        multiple={true}
        requestAction={sendUpload}
        nameForm="quote_file_upload"
        additional={[
          {
            label: 'Tipo',
            group: 'stage',
            name: 'stage',
            required: true,
            options: typeOptions,
          },
        ]}
      />

      <Button
        leftIcon={<MdAttachFile color="#FFFFFF" size={20} />}
        p="1.5rem"
        pb="1.5rem"
        pl="1.3rem"
        pr="1.3rem"
        borderRadius="50px"
        bgColor="green"
        _hover={{ bgColor: '#70D499' }}
        onClick={flagClick.bind(this)}>
        <Text mr="10px" color="white">
          Ver documentos/anexos
        </Text>
      </Button>

      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />

        <DrawerContent maxW={'100% !important'} w={{ base: 'initial', md: '50% !important' }} pt="30px">
          <DrawerCloseButton />

          <Flex align="center" justify="space-between" mr="30px">
            <Text px="30px" textStyle="secondaryTitle" textColor="primary" fontWeight="bold" my="10px">
              Documentos
            </Text>

            <Flex>
              <Button loadingText="Baixando..." isLoading={isDownloadingAllFiles} variant="primary" onClick={() => downloadAllFiles()}>
                <Text fontSize="14px" textColor="#FFFFFF">
                  Baixar todos
                </Text>
              </Button>

              <Button
                ml="10px"
                variant="primary"
                onClick={() => {
                  actionModalUploadFile(), onClose();
                }}>
                <Text fontSize="14px" textColor="#FFFFFF">
                  Anexar novo
                </Text>
              </Button>
            </Flex>
          </Flex>

          <DrawerBody px="30px">
            <Table className="table-default" w="full" p="0">
              <Thead>
                <Tr>
                  <Td>
                    <Text>Nome</Text>
                  </Td>
                  <Td>
                    <Text>Extensão</Text>
                  </Td>
                  <Td>
                    <Text>Data</Text>
                  </Td>
                  <Td>
                    <Text>Hora</Text>
                  </Td>
                  <Td>
                    <Text>Tipo</Text>
                  </Td>
                  <Td>
                    <Text>Ações</Text>
                  </Td>
                </Tr>
              </Thead>

              <Tbody>
                {documents.length > 0 ? (
                  documents.map((document, key) => {
                    let newCreatedAt = new Date(document?.createdAt);
                    let arrFileName = document?.uploadName?.split('.');

                    return (
                      <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                        <Td>{arrFileName[0] ?? '-'}</Td>
                        <Td>{arrFileName[1] ?? '-'}</Td>
                        <Td>{newCreatedAt ? format(newCreatedAt, 'dd/MM/yyyy') : ''}</Td>
                        <Td>{newCreatedAt ? format(newCreatedAt, 'HH:mm') : ''}</Td>
                        <Td>{document?.type ?? '-'}</Td>
                        <Td>
                          <Flex gap="10px">
                            <button
                              onClick={() => downloadFile(document.identifier, arrFileName[0], arrFileName.pop())}
                              title="Baixar documento"
                              target="_blank">
                              <MdDownload size={20} />
                            </button>
                            <ModalViewDocument quote={quote} document={document} />
                            <ModalDeleteDocument quote={quote} document={document} handleSuccess={getDocuments} />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={6}>
                      <Flex justify="center" my="25px">
                        Nenhum dado encontrado para os parâmetros filtrados.
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ModalListDocuments;

