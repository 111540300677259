import React from 'react';

import { Grid } from '@chakra-ui/react';

const TaxInquiryGridColumn = ({ children, ...props }) => {
  return (
    <Grid {...props} templateColumns={{ base: '1fr', md: 'repeat(5, 1fr)' }} justifyItems="flex-start" gap={{ base: '2rem', md: '0' }}>
      {children}
    </Grid>
  );
};

export default TaxInquiryGridColumn;
