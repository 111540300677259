// Constantes para as máscaras comuns
const MASKS = {
  documentNumber: '###.###.###-##', // CPF
  cnpj: '##.###.###/####-##', // CNPJ
  phone: '(##) ####-####', // Telefone fixo
  cellphone: '(##) #####-####', // Telefone celular
  BIRTHDAY: '##/##/####', // Data de nascimento
  ADDRESS_ZIPCODE: '#####-###', // CEP
  creditCard: '#### #### #### ####', // Número de cartão de crédito
  date: '##/##/####', // Data (genérica)
  time: '##:##', // Hora
  datetime: '##/##/#### ##:##', // Data e hora
  currency: 'R$ ###.###.###,##', // Valor monetário
  percentage: '###%', // Percentual
};

// Função para retornar a máscara correspondente a uma chave
export const getMaskByKey = (key) => MASKS[key] || null;
