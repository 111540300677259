import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const LogisticsInfoCard = ({ title = '', icon = null, children, ...props }) => {
  return (
    <Flex w="full" h="full" direction="column" p=" 17px 32px" border="1px solid #6C48C299" {...props}>
      <Flex gap="8px" align="center">
        <Text textColor="primary">{title}</Text>

        {icon && (
          <Flex bgColor="#D0B0FD2B" p="8px" borderRadius="4px">
            {icon}
          </Flex>
        )}
      </Flex>
      {children}
    </Flex>
  );
};

export default LogisticsInfoCard;
