import React from 'react';

import { Box, Flex, Link, Text } from '@chakra-ui/react';

import FAQ from '../Modal/FAQ/FAQ';

const Footer = () => {
  return (
    <>
      <Box as="footer">
        <Flex
          width="full"
          left="0"
          bottom="0"
          align="center"
          justify="center"
          color="primary"
          fontSize="16px"
          fontWeight="semibold"
          flexWrap="wrap"
          gap={{base: "5px 14px", md: "14px"}}
          bgColor="#F4F4F4"
          p="5px 10px 5px"
           >
          <Text>Vendemmia {new Date().getFullYear()}</Text>
          {'|'}

          <Link href="https://vendemmia.com.br/" target="_blank">
            Visite nosso site
          </Link>
          {'|'}

          <FAQ selectedContent={0} color="primary" size="16px" />
          {'|'}

          <FAQ selectedContent={1} color="primary" size="16px" />
          {'|'}

          <FAQ selectedContent={2} color="primary" size="16px" />
        </Flex>
      </Box>
    </>
  );
};

export default Footer;
