import React from 'react';

import { MdArticle, MdDownload } from 'react-icons/md';

import {
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

const DocumentModalView = ({ identifier, name, file }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let documentUrl = (process.env.REACT_APP_API_URL + `/open/user/file/${identifier}/${file?.identifier}/view`).replace("//open", "/open");
  const arrName = file?.uploadName.split(".");
  const extension = arrName?.pop();

  if (extension == "docx" || extension == "xlsx" || extension == "pptx") {
    documentUrl = "https://view.officeapps.live.com/op/embed.aspx?src=" + documentUrl;
  }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/user/file/${identifier}/${file?.identifier}/download`).replace(
      "//open",
      "/open"
  );
    link.target = '_blank';
    link.download = file?.uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const attachment = () => {
    switch (extension) {
      case 'pdf':
        return <iframe
          src={documentUrl}
          title="PDF Viewer"
          allowFullScreen={true}
          style={{ width: '100%', height: '300px' }}
        />;
      default:
        return <Image src={documentUrl} />;
    }
  };

  return (
    <>
      <Button variant="link" ml="30px" color="#3498DB" onClick={onOpen} leftIcon={<MdArticle />}>
        <Text>Ver arquivos </Text>
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalCloseButton zIndex="100000" />
          <ModalBody p="25px" position="relative">
            <Flex alignItems="center" mb="20px">
              <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" mr="20px">
                {name}: {file?.name}
              </Text>
              <IconButton variant="primary" icon={<MdDownload />} onClick={handleDownload} />
            </Flex>
            {attachment()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentModalView;
