import React, { useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import BarChartComponent from '../BarChartComponent';

const CurveStockChart = ({ data, modality, setStatusGrafico, setAdditionalStatusGrafico, isPreview }) => {
  const [entity, setEntity] = useState([]);

  const handleClickStatus = (status) => {
    if (modality === 'EXPEDICAO') {
      setAdditionalStatusGrafico(status);
    } else {
      setStatusGrafico(status);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let curvaA, curvaB, curvaC;

        if (modality === 'ESTOQUE') {
          curvaA = parseInt(data.total_curva_a, 10);
          curvaB = parseInt(data.total_curva_b, 10);
          curvaC = parseInt(data.total_curva_c, 10);
        } else if (modality === 'EXPEDICAO') {
          curvaA = parseInt(data.totalCurveA, 10);
          curvaB = parseInt(data.totalCurveB, 10);
          curvaC = parseInt(data.totalCurveC, 10);
        }

        const formatNumber = (value) => (isNaN(value) ? 0 : value);
        // const formatString = (value) => formatNumber(value).toLocaleString('pt-BR');

        const chartData = [
          { name: 'A', qtt: formatNumber(curvaA), color: '#2ECC71', status: 'curvaA' },
          { name: 'B', qtt: formatNumber(curvaB), color: '#F1C40F', status: 'curvaB' },
          { name: 'C', qtt: formatNumber(curvaC), color: '#E74C3C', status: 'curvaC' },
        ];

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data, modality]);

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="20px" flexDirection={{ base: 'column', md: 'row' }}>
      <BarChartComponent entity={entity} handleClickStatus={handleClickStatus} isPreview={isPreview} />
    </Flex>
  );
};

export default CurveStockChart;
