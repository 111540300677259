import React, { useEffect, useState } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const HelpPrivacyPolicy = () => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setLoad(true);

    try {
      const res = await requests.currentPolicyPrivacy();

      setList([res]);
    } catch (error) {
      setLoad(false);
    }

    setLoad(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <Flex direction="column" p="30px">
      {list?.map((entity, key) => {
        return (
          <Text
            key={key}
            textStyle="subtitle"
            color="#6C48C2AD"
            dangerouslySetInnerHTML={{ __html: entity?.description.replace(/\n/g, '<br />') }}
          />
        );
      })}

      {load ? (
        <Flex justify="center">
          <Text textStyle="tableTitle" color="#6C48C2AD">
            Carregando...
          </Text>
        </Flex>
      ) : (
        <>
          {list.length === 0 && (
            <Text textStyle="tableTitle" color="#6C48C2AD">
              Nenhuma informação disponível no momento.
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default HelpPrivacyPolicy;
