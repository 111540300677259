import { useState } from 'react';

import { MdArrowDropDown } from 'react-icons/md';

import { Flex, Select, Switch, useDisclosure } from '@chakra-ui/react';

import NotificationModal from './NotificationModal';

const CustomFields = ({ children, ...props }) => {
  const { field } = props;

  const type = field?.type;
  const options = field?.options;
  const selected = props?.selected;
  const action = field?.action;

  const identifier = props?.identifier;

  const { isOpen: isOpenSelectModal, onOpen: onOpenSelectModal, onClose: onCloseSelectModal } = useDisclosure();
  const { isOpen: isOpenRadioModal, onOpen: onOpenRadioModal, onClose: onCloseRadioModal } = useDisclosure();

  switch (type) {
    case 'select':
      const [currentFilter, setCurrentFilter] = useState({
        label: options?.label || '',
        value: options?.value || '',
        slug: options?.slug || '',
      });

      const handleClickSelect = (userDecision) => {
        userDecision ? (action(identifier, currentFilter), onCloseSelectModal()) : onCloseSelectModal();
      };

      return (
        <Flex alignItems="center" cursor="pointer">
          <Select
            key={identifier}
            data-custom-field-select
            icon={<MdArrowDropDown />}
            variant="primary"
            defaultValue={selected}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => {
              if (!event.target.value) {
                toast.error('Você deve selecionar um perfil');
                return;
              }

              setCurrentFilter({
                label: event.target.value,
                value: options.find((e) => e.value === event.target.value)?.value,
                slug: options.find((e) => e.value === event.target.value)?.slug,
              });
              onOpenSelectModal();
            }}>
            {options.map((role, key) => (
              <option key={role.value + key} value={role.value}>
                {role.label}
              </option>
            ))}
          </Select>

          <NotificationModal
            isOpen={isOpenSelectModal}
            onOpen={onOpenSelectModal}
            onClose={onCloseSelectModal}
            callback={handleClickSelect}
          />
        </Flex>
      );

    case 'radio':
      const findStatusByCondition = (initialValue, conditions) => {
        const match = conditions.find((condition) => condition.condition === initialValue);
        return match ? match.status : false; // Assuming the default status is false when no match is found
      };

      const initialRadioStatus = findStatusByCondition(selected, field?.roles);
      const [enableAction, setEnableAction] = useState(initialRadioStatus);

      const handleClickRadio = (userDecision) => {
        //userDecision e a opção escolhida no modal
        userDecision ? (action(identifier, !enableAction), setEnableAction(!enableAction), onCloseRadioModal()) : onCloseRadioModal();
      };

      return (
        <Flex align={'center'} gap={'10px'} fontSize={'13px'}>
          <Switch
            size="md"
            data-customField-radio
            isChecked={enableAction}
            onChange={(e) => {
              e.stopPropagation();
              onOpenRadioModal();
            }}
            /* colorScheme={field.bgColor.turnOff} */
          />
          {enableAction
            ? field?.roles[0].label || ''
              ? field.roles[0].label
              : 'Ativar'
            : field?.roles[1].label || ''
            ? field.roles[1].label
            : 'Desativar'}
          <NotificationModal
            isOpen={isOpenRadioModal}
            onOpen={onOpenRadioModal}
            onClose={onCloseRadioModal}
            callback={handleClickRadio}
          />
        </Flex>
      );

    default:
      break;
  }
};

export default CustomFields;
