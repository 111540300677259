import React, { useEffect, useRef, useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import OrderProgressChart from '../../../components/Charts/Warehouse/OrderProgressChart';
import CommonPieChart from '../../../components/CommonChats/CommonPieChart';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import FilterOption from '../../../utils/filters/FilterOption';
import { filterOptionsWarehousePicking } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';
import PickingPackingTotalProducts from './components/PickingPackingTotalProducts';

const PickingPackingPage = () => {
  const hasPermission = permissions.warehousePickingAndPacking;

  const request = useRef(0);
  const requestChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);
  const [filters, setFilters] = useState();

  const [list, setList] = useState([]);
  const [sublist, setSublist] = useState();
  const [chartData, setChartData] = useState({});

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const [statusGrafico, setStatusGrafico] = useState(null);
  const [collectionStatus, setCollectionStatus] = useState([]);

  //modals methods
  const { isOpen: modalDeleteIsOpen, onOpen: modalDeleteOnOpen, onClose: modalDeleteOnClose } = useDisclosure();

  //fields
  const [item, setItem] = useState();
  const [identifier, setIdentifier] = useState();

  // Função auxiliar para criar os filtros
  const buildFiltersForPicking = (customFilters) => {
    return {
      ...customFilters,
      statusGrafico,
      type_storage: 'picking',
    };
  };

  const load = (filters, key, page) => {
    const filtersForPicking = buildFiltersForPicking(filters);

    // Atualiza os filtros no estado
    setFilters(filtersForPicking);

    // Chama a função de listagem com os filtros atualizados
    getListPickingPack(filtersForPicking, key, page);
  };

  const getListPickingPack = async (filters, key, page) => {
    const res = await executeRequest({
      action: () => requests.listPickingPack(filters, page),
      setIsLoading,
    });

    if (request.current && request.current > key) {
      return;
    }
    request.current = key;

    setList(res.data);
    setSublist(res.data);
    setMetadata(res.meta);
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const sanitizeFilters = (filters) => {
    const { sort, page, ...rest } = filters;
    return rest;
  };

  const loadCharts = async (filters, key) => {
    const sanitizedFilters = sanitizeFilters(filters);
    const filtersForPicking = { ...sanitizedFilters, type_storage: 'picking' };

    const data = await executeRequest({
      action: () => requests.dashboardPickingPack(filtersForPicking, 'picking'),
      setIsLoading: setIsLoadingChart,
    });

    if (requestChart.current && requestChart.current > key) {
      return;
    }
    requestChart.current = key;

    setChartData(data.data);
  };

  const handleDelete = async () => {
    await executeRequest({
      action: () => requests.deletePickingPack(identifier),
      msgSuccess: `Picking & Pack de nota ${item.idNotafiscal} removido com sucesso!`,
      msgError: 'Erro ao excluir a nota, tente novamente!',
      setIsLoading,
      triggerRefresh,
    });
  };

  // Função auxiliar para configurar e gerar o arquivo de exportação
  const exportPickingPackingList = (fileName, type_storage) => {
    filters.type_storage = type_storage;

    // Configuração comum
    const endpoint = `/pickingepacking/export/`;
    const extension = `xlsx`;
    const method = 'GET';
    const body = {};
    const params = filters;

    // Geração do arquivo de exportação
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  // Função para exportar a lista de Picking e Packing
  const exportListFABActionPickingPacking = () => {
    const fileName = `PickingPacking`;
    return exportPickingPackingList(fileName, 'picking');
  };

  // Função para exportar a lista de Picking, Packing e Expedição
  const exportListFABActionPickingPackingExpedicao = () => {
    const fileName = `PickingPacking-e-Expedicao`;
    return exportPickingPackingList(fileName, 'both');
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehousePicking());
  };

  //view detailsOR
  const handleOpenModalDelete = (data) => {
    setItem(data);
    modalDeleteOnOpen();
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    const filteredChildren = filterOptions.filter((item) => item instanceof FilterOption).map((item) => item.children);

    setCollectionStatus(filteredChildren);
  }, [filterOptions]);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [statusGrafico]);

  useEffect(() => {
    if (item) {
      setIdentifier(item);
    }
  }, [item]);

  const chartsView = [
    {
      title: 'Status dos pedidos',
      content: (
        <CommonPieChart
          data={chartData}
          segments={[
            { id: 'No prazo', key: 'onTime', filterPie: 'NoPrazo', color: '#2ECC71' },
            { id: 'Atrasados', key: 'outOfTime', filterPie: 'ForaDoPrazo', color: '#E74C3C' },
          ]}
          setFilterPie={setStatusGrafico}
          /* startAngle={-90}
          endAngle={90} */
        />
      ),
    },
    {
      title: 'Status de coleta',
      content: <OrderProgressChart data={chartData} collectionStatus={collectionStatus} filterOptions={filterOptions} type="status" />,
    },
    {
      title: 'Soma dos valores fiscais',
      content: <PickingPackingTotalProducts data={chartData} />,
    },
  ];

  const customButtons = [
    {
      main: [
        {
          label: 'Excluir',
          tooltip: 'Excluir',
          deniedRoles: ['ROLE_CLIENT'],
          icon: <FaTrashAlt size={16} color="#E74C3C" />,
          action: handleOpenModalDelete,
        },
      ],
      collapsed: [],
    },
  ];

  return (
    <Page
      screen="warehouse-picking"
      title="Picking e Packing"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading || isLoadingChart}
      sorting={sorting}
      filterOptions={filterOptions}
      hasChartChanges={hasChartChanges}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Exportar Picking & Packing',
          text: 'Exportar arquivo (Excel) com dados de Picking & Packing',
          action: exportListFABActionPickingPacking,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo (Excel) com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="10px" m="10px">
        <Flex w="full" gap="10px" h={{ base: 'initial', md: '345px' }}>
          {!isMobile ? (
            chartsView.map((item, index) => (
              <Card w="full" title={item.title} key={index}>
                <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
              </Card>
            ))
          ) : (
            <Accordion w="full" gap="10px" display="grid">
              {chartsView.map((item, index) => {
                return (
                  <AccordionItem key={index}>
                    <Card w="full">
                      <AccordionButton display="flex" justifyContent="space-between">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <ScreenLoader isLoading={isLoadingChart}>
                        <AccordionPanel>{item.content}</AccordionPanel>
                      </ScreenLoader>
                    </Card>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </Flex>

        <CommonList
          //lists
          list={list}
          rawData={list}
          subList={sublist}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //custom
          customButtons={customButtons}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />
      </Flex>

      {/* delete */}
      <CommonModal
        heading={'Certeza que deseja excluir?'}
        subtitle={'Deseja mesmo excluir esse registro? Ele sumirá automaticamente da lista.'}
        callback={handleDelete}
        isOpen={modalDeleteIsOpen}
        onOpen={modalDeleteOnOpen}
        onClose={modalDeleteOnClose}
        variant={'caution'}
        size={'md'}
        removeRadius={true}
      />
    </Page>
  );
};

export default PickingPackingPage;
