import React, { useEffect } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdArrowDropDown } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, Grid, ModalBody, ModalFooter, Select } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import requests from '../../../../services/requests';
import { userInitialValues } from '../Helpers/userInitialValues';
import { userValidationSchema } from '../Helpers/userValidationSchema';

const RegistrationData = ({
  setIsLoadingEntity,
  getUser,
  loadList,
  roleOptions,
  entity,
  handleClose,
  callhandleClose,
  setFields,
  fields,
  isBasicRegister,
}) => {
  useEffect(() => {
    setFields({
      ...fields,
      role: {
        label: roleOptions[0].label,
        value: roleOptions[0].value,
        slug: roleOptions[0].slug,
      },
    });
  }, [entity]);

  return (
    <Formik
      initialValues={userInitialValues(entity, roleOptions, fields)}
      validationSchema={userValidationSchema(entity)}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        if (entity?.identifier) {
          requests
            .editUser(entity.identifier, values)
            .then((response) => {
              toast.success(`Usuário ${response.name} editado com sucesso!`);

              setIsLoadingEntity(true);
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);

              toast.error('Problemas ao editar usuário, tente novamente ou entre em contato com o Administrador');
            });

          setSubmitting(false);
        } else {
          requests
            .addUser(fields?.role?.slug, values)
            .then((response) => {
              toast.success(`Usuário ${response.name} cadastrado com sucesso!`);

              getUser();
              setIsLoadingEntity(true);
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);

              toast.error('Problemas ao cadastrar usuário, tente novamente ou entre em contato com o Administrador');
            });
        }
      }}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody mt="10px" overflowY="auto" h="500px" w="full" className="up-anim">
            <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="20px">
              <Flex direction="column">
                <LabelDefault name="name" text="Nome completo" />

                <InputDefault setFieldValue={setFieldValue} value={values.name} name="name" placeholder="Preencha o nome completo" />
                <ErrorMessage component="p" className="error-message-error" name="name" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="role" text="Tipo de perfil" />

                <Select
                  variant="primary"
                  h="40px"
                  color="#0F0A1D66"
                  className="input-register"
                  icon={<MdArrowDropDown />}
                  isDisabled={entity?.identifier ? true : false}
                  defaultValue={roleOptions.find((e) => e.value === entity.role)?.value}
                  onChange={(event) => {
                    if (!event.target.value) {
                      toast.error('Você deve selecionar um perfil');
                      return;
                    }

                    setFields({
                      ...fields,
                      role: {
                        label: event.target.value,
                        value: roleOptions.find((e) => e.value === event.target.value)?.value,
                        slug: roleOptions.find((e) => e.value === event.target.value)?.slug,
                      },
                    });
                  }}>
                  {roleOptions.map((role, key) => (
                    <option key={role.value + key} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </Select>

                <ErrorMessage component="p" className="error-message-error" name="role" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="cellphone" text="Celular (opcional)" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.cellphone}
                  name="cellphone"
                  placeholder="Preencha o celular (opcional)"
                />
                <ErrorMessage component="p" className="error-message-error" name="cellphone" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="phone" text="Telefone (opcional)" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.phone}
                  name="phone"
                  placeholder="Preencha o telefone (opcional)"
                />
                <ErrorMessage component="p" className="error-message-error" name="phone" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="email" text="E-mail" />
                <InputDefault setFieldValue={setFieldValue} value={values.email} name="email" placeholder="Preencha o e-mail" />

                <ErrorMessage component="p" className="error-message-error" name="email" />
              </Flex>

              {!entity?.identifier && (
                <Flex direction="column">
                  <LabelDefault name="password" text="Senha de acesso" />

                  <InputDefault
                    setFieldValue={setFieldValue}
                    value={values.password}
                    name="password"
                    type="password"
                    placeholder="Preencha a senha"
                  />
                  <ErrorMessage component="p" className="error-message-error" name="password" />
                </Flex>
              )}
            </Grid>
          </ModalBody>

          <ModalFooter borderTop="1px" borderColor="#7070702E" p="0">
            <Button
              border="1px"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="9px 23px"
              color="#E73668"
              borderColor="#E52359"
              bgColor="transparent"
              type="button"
              onClick={handleClose}>
              Cancelar e fechar
            </Button>

            <Button
              variant="primary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="9px 23px"
              type="submit"
              isLoading={isSubmitting}
              loadingText="Salvando">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};
export default RegistrationData;
