const yup = require('yup');

const financialValidation = (isDraft = false) => {
  let validation = yup.object().shape({
    hasAdministrativeFee: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
    administrativeFee: isDraft
      ? yup.string()
      : yup.string().when('hasAdministrativeFee', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    minAdministrativeFee: isDraft
      ? yup.string()
      : yup.string().when('hasAdministrativeFee', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    administrativeFeeAdditionalInformation: yup.string(),
    upFrontPaymentWarranty: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    upFrontPaymentWarrantyAdditionalInformation: yup.string(),
    commercialDiscount: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
    icmsRates: isDraft
      ? yup.string()
      : yup.mixed().when('commercialDiscount', {
          is: true,
          then: yup
            .array(
              yup.object({
                value: yup.string(),
                discountValue: yup.string(),
              })
            )
            .test({
              message: 'Os campos acima devem ser completamente preenchidos',
              test: (arr) => {
                return arr.find(({ value, discountValue }) => {
                  return value && discountValue;
                });
              },
            }),
        }),
    extraCommercialDiscount: isDraft
      ? yup.string()
      : yup.string().when('commercialDiscount', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    commercialDiscountAdditionalInformation: yup.string(),
    serviceInvoice: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
    administrateTax: isDraft
      ? yup.string()
      : yup.string().when('serviceInvoice', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    serviceInvoiceMinValue: isDraft
      ? yup.string()
      : yup.string().when('serviceInvoice', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    serviceInvoiceModality: isDraft
      ? yup.string()
      : yup.string().when('serviceInvoice', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    serviceInvoiceAdditionalInformation: yup.string(),
    exporterPayment: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
    exporterPaymentAdditionalInformation: yup.string(),
    financialCharges: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
    financialChargesModality: isDraft
      ? yup.string()
      : yup.string().when('financialCharges', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    financialChargesAddition: isDraft
      ? yup.string()
      : yup.string().when('financialCharges', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    financialChargesAdditionalInformation: yup.string(),
    paymentTermsCommodity: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    paymentTermsCommodityDays: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    paymentTermsTax: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    paymentTermsTaxDays: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    paymentTermsExpenses: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    paymentTermsExpensesDays: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    paymentTermsServiceInvoice: isDraft
      ? yup.string()
      : yup.string().when('serviceInvoice', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    paymentTermsServiceInvoiceDays: isDraft
      ? yup.string()
      : yup.string().when('serviceInvoice', {
          is: true,
          then: yup.string().required('Campo obrigatório'),
        }),
    paymentTermsAdditionalInformation: yup.string(),
  });

  return validation;
};

export default financialValidation;
