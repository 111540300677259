import React, { useEffect, useRef, useState } from 'react';

// import { FaTrashAlt } from 'react-icons/fa';
// import { MdOutlineModeEditOutline } from 'react-icons/md';

import { FaTrashAlt } from 'react-icons/fa';
import { MdOutlineModeEditOutline } from 'react-icons/md';

import { Button, Image, Text, useDisclosure } from '@chakra-ui/react';

import add from '../../../assets/images/add.svg';
// import CommonList from '../../../components/CommonList/CommonList';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import { DynamicFormButton } from '../../../components/CommonForm';
import { DynamicForm } from '../../../components/CommonForm/DynamicForm';
import { DynamicFormFooter } from '../../../components/CommonForm/DynamicFormFooter';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsVehicle } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';

import { commonListConfig } from './components/commonListConfig';
import DynamicFormVehicleManagement from './components/DynamicFormVehicleManagement';

const yup = require('yup');

const VehicleManagementPage = () => {
  const hasPermission = permissions.logisticFleetManagement;
  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [filters, setFilters] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [list, setList] = useState([]);
  const [hasType, setHasType] = useState();

  const [identifier, seIdentifier] = useState([]);

  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //modals methods
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();
  const { isOpen: modalDeleteIsOpen, onOpen: modalDeleteOnOpen, onClose: modalDeleteOnClose } = useDisclosure();

  const validationSchema = yup.object().shape({
    licensePlate: yup.string().required('Campo obrigatório'),
    ownership: yup.string().required('Campo obrigatório'),
  });

  const load = (filters, key, page) => {
    setIsLoading(true);

    setFilters(filters);

    setInitialValues({
      licensePlate: undefined,
      brand: undefined,
      color: undefined,
      modelVehicle: 'Caminhão',
      typeCar: undefined,
      ownership: 'VENDEMMIA',
      capacity: undefined,
      yearManufacture: undefined,
      model: undefined,
      yearModel: undefined,
    });

    requests
      .listVehicles(filters, page)
      .then((res) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(res.data);
        setMetadata(res.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const exportListFABAction = async () => {
    //preset
    const endpoint = `/adm/vehicle/export-csv`;
    const fileName = `vehicles`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, filters);
  };

  const handleOpenModalNew = () => {
    setInitialValues({
      licensePlate: undefined,
      brand: undefined,
      color: undefined,
      modelVehicle: 'Caminhão',
      typeCar: undefined,
      ownership: 'VENDEMMIA',
      capacity: undefined,
      yearManufacture: undefined,
      model: undefined,
      yearModel: undefined,
    });
    addParametersToURL({ type: 'new' });
    drawerOnOpen();
  };

  const handleOpenModalEdit = (identifier, data) => {
    seIdentifier(identifier);
    addParametersToURL({ type: 'edit' });
    setInitialValues(data);
    drawerOnOpen();
  };

  const handleVehicleNew = async (values) => {
    await executeRequest({
      action: () => requests.addVehicle(values),
      msgSuccess: 'Veículo criado com sucesso',
      msgError: 'Erro ao criar o veículo, tente novamente.',
      setIsLoading,
      triggerRefresh,
      callback: () => drawerOnClose(),
    });
  };

  const handleEditVehicle = async (_, values) => {
    await executeRequest({
      action: () => requests.editVehicle(identifier, values),
      msgSuccess: 'Veículo editado com sucesso',
      msgError: 'Erro ao editar o veículo, tente novamente.',
      setIsLoading,
      triggerRefresh,
      callback: () => drawerOnClose(),
    });
  };

  const handleDelete = async () => {
    await executeRequest({
      action: () => requests.deleteVehicle(identifier),
      msgSuccess: `Veículo excluído com sucesso!`,
      msgError: 'Problemas ao remover veículo, tente novamente ou entre em contato com o Administrador',
      setIsLoading,
      triggerRefresh,
      callback: () => drawerOnClose(),
    });
  };

  const handleOpenModalDelete = (identifier) => {
    seIdentifier(identifier);
    modalDeleteOnOpen();
  };

  const handleCustomUserAction = async (identifier, currentFilter) => {
    let status = 'activate';

    if (!currentFilter) {
      status = 'inactivate';
    }

    await executeRequest({
      action: () => requests.changeStatusVehicle(identifier, status),
      msgSuccess: `Veículo ${currentFilter ? 'ativado' : 'inativado'} com sucesso!`,
      msgError: 'Problemas ao alterar status do veículo, tente novamente ou entre em contato com o Administrador',
      setIsLoading,
      triggerRefresh,
      callback: () => drawerOnClose(),
    });
  };

  const onSubmitForm = (values) => {
    hasType === 'new' && handleVehicleNew(values);
    hasType === 'edit' && handleEditVehicle(identifier, values);
  };

  useEffect(() => {
    setFilterOptions(filterOptionsVehicle);
  }, []);

  // Atualiza o hasType quando o parâmetro de URL muda
  useEffect(() => {
    const urlType = hasSpecificURLParameters(['type']).value;
    setHasType(urlType);
  }, [window.location.href]);

  /* component preset */
  const subtitle = `Existe um total de ${metadata.total_count} perguntas veículos`;

  const customFields = [
    {
      type: 'radio',
      title: 'Status',
      name: 'status',
      roles: [
        { label: 'Ativado', condition: 'ATIVO', status: true },
        { label: 'Desativado', condition: 'INATIVO', status: false },
      ],
      bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' },
      action: handleCustomUserAction,
    },
  ];

  /* Component preset */
  const customButtons = [
    {
      main: [
        { label: 'Editar', tooltip: 'Editar veículo', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenModalEdit },
        {
          label: 'Excluir',
          tooltip: 'Excluir veículo',
          icon: <FaTrashAlt size={16} color="#E74C3C" />,
          action: handleOpenModalDelete,
          isButtonDisabled: (item) => !!(item?.countTrips > 0),
        },
      ],
      collapsed: [],
    },
  ];

  return (
    <Page
      screen="vehicle"
      title="Gestão de frota"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Placa e propriedade veículo"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      metadata={metadata}
      load={load}
      filterOptions={filterOptions}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar veículo',
          text: 'Cadastro de veículo',
          action: (
            <Button variant="primary" pt="23px" pb="23px" borderRadius="50px" onClick={handleOpenModalNew}>
              <Image src={add} />
              <Text ml="10px">Novo veículo</Text>
            </Button>
          ),
          modality: 'custom',
        },
        {
          title: 'Exportar Veículos',
          text: 'Exportar arquivo CSV com dados de Veículos',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        deleteButtonAction={modalDeleteOnOpen}
        //custom
        customFields={customFields}
        customButtons={customButtons}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />

      {/* drawer */}
      <CommonDrawer
        heading={hasType === 'new' ? 'Novo Veículo' : 'Editar Veículo'}
        placement="left"
        isOpen={drawerIsOpen}
        onOpen={drawerOnOpen}
        onClose={drawerOnClose}>
        <DynamicForm config={{ initialValues, validationSchema, callback: onSubmitForm }}>
          <DynamicFormVehicleManagement />
          <DynamicFormFooter>
            <DynamicFormButton name={'Salvar'} type="button" />
          </DynamicFormFooter>
        </DynamicForm>
      </CommonDrawer>

      {/* delete */}
      <CommonModal
        heading={'Certeza que deseja excluir?'}
        subtitle={'Deseja mesmo excluir esse registro? Ele sumirá automaticamente da lista.'}
        callback={handleDelete}
        isOpen={modalDeleteIsOpen}
        onOpen={modalDeleteOnOpen}
        onClose={modalDeleteOnClose}
        variant={'caution'}
        size={'md'}
        removeRadius={true}
      />
    </Page>
  );
};

export default VehicleManagementPage;
