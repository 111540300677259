import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

const CardRequest = ({ bgColor, text, icon, value }) => {
  return (
    <Box w={{ base: "initial", md:"300px" }} borderRadius="6px" className="up-anim" bgColor={bgColor}>
      <Flex direction="column" h="full" textColor="primary" px="30px" py="20px">
        <Flex direction="row">
          <Text w="full" textStyle="secondaryTitle" fontSize="18px" textColor="#FFFFFF">
            {text}
          </Text>
          {icon}
        </Flex>

        <Text textStyle="primaryTitle" textColor="#FFFFFF" fontSize={{ base:"26px", md:"40px" }}>
          {value}
        </Text>
      </Flex>
    </Box>
  );
};

export default CardRequest;
