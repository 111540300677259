import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const CarbonFootprintChart = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  // Função para transformar os dados da API no formato esperado pelo gráfico
  const transformApiData = (data) => {
    if (!data) return [];

    return Object.keys(data).map((key) => {
      // Converte a chave (key) para um objeto Date e depois a formata para MM/YYYY
      const date = new Date(key.slice(0, 4), key.slice(4) - 1);
      const formattedDate = format(date, 'MM/yyyy');

      return {
        date: formattedDate,
        qtt: parseFloat(data[key].calculated),
      };
    });
  };

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.dataKey && 'Pegada de carbono'}:</Text>
                <Text>{entity.value.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  // UseEffect para transformar os dados assim que o componente for montado
  useEffect(() => {
    if (data) {
      const transformedData = transformApiData(data);
      setChartData(transformedData);
    }
  }, [data]);

  return (
    <Flex direction="column" h="full">
      <Flex align="end" h="full">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
            }}>
            <CartesianGrid vertical={false} />
            <Tooltip content={<CustomTooltip />} />
            <XAxis dataKey="date" tickLine={false} fontSize="13px" />
            <YAxis stroke="#2ECC71" tickFormatter={tickFormatter} tickLine={false} />
            <Bar
              dataKey="qtt"
              barSize={35}
              fill="#2ECC71"
              label={({ value, x, y, width, height }) => (
                <text x={x + width / 2} y={y - 10} fill="#4A5568" textAnchor="middle" dy={-6} fontSize="13px">
                  {value.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })}
                </text>
              )}
            />
          </BarChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
};

export default CarbonFootprintChart;
