import React, { useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Box, Collapse, Flex, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';


import ConsolidationModalityDetails from './ConsolidationModalityDetails';

const ConsolidationModality = ({ list, modality, index, viewport }) => {
  const [collapseModality, setCollapseModality] = useState(null);

  function moneyFormat(value) {
    return (value ?? 0)
      .toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'code',
      })
      .replace('BRL', '');
  }

  function colorFormat(value) {
    return value < 0 ? 'red' : 'inherit';
  }

  const handleCollapseModality = (index) => {
    setCollapseModality(collapseModality === index ? null : index);
  };

  return (
    <>
      <Tr key={index} onClick={() => handleCollapseModality(index)} cursor="pointer" className="border">
        <Td minW="100px" maxW="100px">
          <Box>
            {collapseModality === index ? (
              <MdKeyboardArrowDown size={20} color="#707070" />
            ) : (
              <MdKeyboardArrowRight size={20} color="#707070" />
            )}
          </Box>
        </Td>

        <Td minW="280px" textStyle="paragaph" textColor="primary" fontWeight="500">
          <Flex>
            <Text>{list.result.data[modality].title}</Text>
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].billed)}>{moneyFormat(list.result.data[modality].billed)}</Text>
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].estimated)}>{moneyFormat(list.result.data[modality].estimated)}</Text>
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].budgeted)}>{moneyFormat(list.result.data[modality].budgeted)}</Text>
          </Flex>
        </Td>

        <Td w="full">
          <Flex justify={viewport > '1500' ? 'center' : 'normal'}>
            <Flex w={viewport > '1500' ? '280px' : 'full'} justify="space-between">
              <Text textColor="linkColor" fontWeight="bold">
                R$
              </Text>

              <Text color={colorFormat(list.result.data[modality].difference)}>
                {moneyFormat(list.result.data[modality].difference)}
              </Text>
            </Flex>
          </Flex>
        </Td>
      </Tr>

      <Tr key={`${index}-modality`}>
        <Td colSpan={6} border="none" p="0px">
          <Collapse in={collapseModality === index}>
            <Flex bgColor="#F9F9F9" pb="10px">
              <Table w="full" ml="15px">
                <Tbody>
                  {Object.keys(list.result.data[modality].data).map((modalityDetails, index) => (
                    <ConsolidationModalityDetails
                      key={index}
                      index={index}
                      list={list}
                      modality={modality}
                      modalityDetails={modalityDetails}
                      moneyFormat={moneyFormat}
                      colorFormat={colorFormat}
                      viewport={viewport}
                    />
                  ))}
                </Tbody>
              </Table>
            </Flex>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ConsolidationModality;
