import React, { useState }  from 'react';

import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Switch, Text, useDisclosure } from '@chakra-ui/react';

const ModalChangeToggle = ({ text, status = false, callback }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (userDecision) => {
    setIsLoading(true);
    callback(userDecision);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Flex align="center">
        <Switch
          isChecked={status}
          onChange={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        />

        <Text fontWeight="bold" ml="4px" fontSize="14px" onClick={onOpen}>
          {status ? 'Ativado' : 'Desativado'}
        </Text>
      </Flex>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />

        <ModalContent p="10px 35px" borderRadius="50px">
          <ModalBody display="flex" flexDirection="column" alignItems="center" justifyContent="center" p="35px">
            <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
              Você tem certeza que deseja <strong>{!status ? 'ativar' : 'desativar'}</strong>
              {text}?
            </Text>

            <Flex gap="10px">
              <Button variant="secundary" w="fit-content" borderRadius="7px" p="25px 35px" onClick={() => handleClick(false)}>
                Cancelar
              </Button>

              <Button isLoading={isLoading} variant="primary" w="fit-content" borderRadius="7px" p="25px 35px" onClick={() => handleClick(true)}>
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalChangeToggle;
