import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { toast } from 'react-toastify';

import { Box, Button, Flex, Modal, ModalBody, ModalContent, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const ModalCloneUser = ({ actionModalCloneUser = () => {}, isOpen = false, entity = {}, getEntity = () => {}, modality }) => {
  const [userOptions, setUserOptions] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  const loadData = () => {
    setIsLoadingData(true);

    requests.listUsers({}, null, 99999999).then((response) => {
      let aux = [];
      response?.data?.length > 0 &&
        response.data.forEach((data) => {
          aux.push({
            label: `${data.name} (${data.email})`,
            value: data.identifier,
          });
        });

      setUserOptions(aux);
      setIsLoadingData(false);
    });
  };

  const sendData = () => {
    setIsLoadingData(true);

    requests.postCloneUser(entity?.identifier, userSelected?.value, modality).then((response) => {
      toast.success(`Clonagem de ${modality === 'permission' ? 'permissões' : 'empresas'} efetuada com sucesso`);
      setIsLoadingData(false);
      getEntity();
    });
  };

  useEffect(() => {
    if (!entity?.identifier) {
      return;
    }

    loadData();
  }, []);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={actionModalCloneUser} size="xl">
        <ModalContent p="10px 35px" borderRadius="50px">
          <ModalBody p="35px" position="relative">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Box>
                <Text
                  textStyle="primaryTitle"
                  fontWeight="medium"
                  textColor="#0F0A1D"
                  textAlign="center"
                  mb="20px"
                  className="up-anim">
                  {`Clonar ${modality === 'permission' ? ' permissões' : ' empresas'}`}
                </Text>
                <Text textStyle="tableTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
                  {`Selecione um usuário para clonar suas ${modality === 'permission' ? ' permissões' : ' empresas'}`}
                </Text>
              </Box>
              <ScreenLoader isLoading={isLoadingData}>
                <Select
                  options={userOptions}
                  styles={selectCustomStyles()}
                  id="user"
                  name="user"
                  value={userSelected}
                  onChange={(option) => setUserSelected(option)}
                  className="input-register"
                  placeholder="Selecione um usuário"
                />
                <Flex>
                  <Button
                    variant="secundary"
                    w="fit-content"
                    borderRadius="7px"
                    m="15px"
                    p="25px 35px"
                    onClick={() => actionModalCloneUser()}>
                    Cancelar
                  </Button>
                  <Button variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={() => sendData()}>
                    Continuar
                  </Button>
                </Flex>
              </ScreenLoader>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCloneUser;
