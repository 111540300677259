import React, { useState } from 'react';

import { MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';

import requests from '../../../../services/requests';
import { filterOptionsMapping } from '../../../../utils/filters/filterPresets';
import { appendFilter } from '../../../../utils/storageFilter';

const ListSavedFilters = ({
  screen,
  selectionList,
  onClose,
  loadSelectionList,
  refreshAppliedFields,
  selectTabIndex,
  setAppliedFilter,
  setColumnData,
  setFilterEditing,
  setFilterSalved,
  setFieldValue,
  setIsEdit,
  setTitleSelection,
  setFilterOptions,
  filterOptions,
}) => {
  const [loadingStates, setLoadingStates] = useState({});

  const applySelectionList = (data) => {
    setFilterSalved(true);

    setAppliedFilter(JSON.parse(data.filters));

    refreshAppliedFields(JSON.parse(data.filters).fields);

    onClose();
    toast.success('Filtro aplicado!');
  };

  const deleteSelectionList = (data) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [data?.identifier]: true,
    }));

    requests.deleteSelection(data?.identifier).then(() => {
      toast.success('Filtro deletado!');
      loadSelectionList();
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [data?.identifier]: false,
      }));
    });
  };

  const editSelectionList = async (data) => {
    window.localStorage.removeItem('filter-comex-mapping');

    const options = await filterOptionsMapping();
    setFilterOptions(options);

    //formatar dados
    let auxF = JSON.parse(data?.filters);

    //mudar chamada da api para edição
    setIsEdit(true);

    //recuperar titulo
    setTitleSelection(data?.name);

    //recuperar nome do campo
    setFieldValue('name', data?.name);

    //recuperar usuário
    setFieldValue('user', data?.user.identifier);

    //recuperar clientes
    setFieldValue('clients', auxF.clients);

    //recuperar status do checkbox e setar no localStorage
    const { channels = [], processModalities = [], stages = [], transportModalities = [] } = auxF;

    const extractedData = {
      channels,
      processModalities,
      stages,
      transportModalities,
    };

    appendFilter(screen, extractedData);

    //recuperar campos/colunas
    setColumnData(auxF.fields);

    //recuper identifier para excluir filtro após editado
    setFilterEditing(data.identifier);

    //mudar de aba
    selectTabIndex(0);
  };

  return (
    <Table className="table-default" w="100%">
      <Thead>
        <Tr>
          <Td>Nome</Td>
          <Td>Alterado em</Td>
          <Td>Aplicar</Td>
          <Td>Editar</Td>
          <Td>Excluir</Td>
        </Tr>
      </Thead>

      <Tbody>
        {selectionList?.length > 0 ? (
          selectionList?.map((data, key) => (
            <Tr key={key}>
              <Td>{data?.name}</Td>
              <Td>{data?.createdAtFormatted}</Td>
              <Td>
                <Button onClick={() => applySelectionList(data)}>
                  <MdCheck color="#2ECC71" />
                </Button>
              </Td>
              <Td>
                <Button
                  onClick={() => {
                    editSelectionList(data);
                  }}>
                  <MdEdit color="#422C7694" />
                </Button>
              </Td>
              <Td>
                <Button isLoading={loadingStates[data?.identifier]} onClick={() => deleteSelectionList(data)}>
                  <MdDelete color="#E52359" />
                </Button>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={5}>
              <Flex justify="center" my="25px">
                Nenhum filtro salvo
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default ListSavedFilters;
