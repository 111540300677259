import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Listagem de usuários',
  // Estrutura da tabela
  categories: [
    {
      title: 'Nome',
      field: 'e.name',
      value: 'name',
    },
    {
      title: 'E-mail',
      field: 'e.email',
      value: 'email',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Permissões',
      value: 'permissionsQtt',
      align: 'center',
    },
    {
      title: 'Empresas vinculadas',
      value: 'clientsQtt',
      align: 'center',
    },
    {
      title: 'Perfil do usuário',
      defaultValue: '',
      align: 'center',
    },
    {
      title: 'Status',
      defaultValue: '',
      align: 'center',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'name',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 50,
  // Estilo
  resizeRows: '1fr 1fr 120px 120px 280px 280px 80px',
};
