export const cleanPathDuplicateAndNull = (path) => {
  const seen = new Set();
  return path.filter((point) => {
    const { latitude, longitude } = point;
    // Skip points with lat or lng equal to 0
    if (latitude === 0 && longitude === 0) return false;
    const key = `${latitude},${longitude}`;
    // If the key is already in the set, skip this point
    if (seen.has(key)) return false;
    // Otherwise, add the key to the set and keep the point
    seen.add(key);
    return true;
  });
};

export function removeDuplicates(array) {
  let seen = new Set();
  return array.filter((item) => {
    let key = `${item.lat}-${item.lng}`;
    return seen.has(key) ? false : seen.add(key);
  });
}
