import React from 'react';

import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { DynamicSelectField } from '../../../../components/CommonForm/Fields/DynamicSelectField';

const DynamicFormDriverProfile = ({ values }) => {
  // Opções para o campo 'ownership'
  const typeOptions = [
    { value: 'INTERNAL', name: 'Motorista Vendemmia' },
    { value: 'EXTERNAL', name: 'Parceiro externo' },
  ];

  // Opções para o campo 'typeCar'
  const stateOptions = [
    { value: 'AC', name: 'Acre' },
    { value: 'AL', name: 'Alagoas' },
    { value: 'AP', name: 'Amapá' },
    { value: 'AM', name: 'Amazonas' },
    { value: 'BA', name: 'Bahia' },
    { value: 'CE', name: 'Ceará' },
    { value: 'DF', name: 'Distrito Federal' },
    { value: 'ES', name: 'Espírito Santo' },
    { value: 'GO', name: 'Goiás' },
    { value: 'MA', name: 'Maranhão' },
    { value: 'MT', name: 'Mato Grosso' },
    { value: 'MS', name: 'Mato Grosso do Sul' },
    { value: 'MG', name: 'Minas Gerais' },
    { value: 'PA', name: 'Pará' },
    { value: 'PB', name: 'Paraíba' },
    { value: 'PR', name: 'Paraná' },
    { value: 'PE', name: 'Pernambuco' },
    { value: 'PI', name: 'Piauí' },
    { value: 'RJ', name: 'Rio de Janeiro' },
    { value: 'RN', name: 'Rio Grande do Norte' },
    { value: 'RS', name: 'Rio Grande do Sul' },
    { value: 'RO', name: 'Rondônia' },
    { value: 'RR', name: 'Roraima' },
    { value: 'SC', name: 'Santa Catarina' },
    { value: 'SP', name: 'São Paulo' },
    { value: 'SE', name: 'Sergipe' },
    { value: 'TO', name: 'Tocantins' },
  ];

  // Mapeamento dos labels 
  const labels = {
    type: 'Tipo',
    name: 'Nome',
    documentNumber: 'CPF',
    email: 'E-mail',
    phone: 'Telefone (Opcional)',
    cellphone: 'Celular',
    additional: {
      RG: 'RG (Opcional)',
      CNH: 'CNH (Opcional)',
      BIRTHDAY: 'Data de Nascimento (Opcional)',
      ADDRESS_STREET: 'Endereço (Opcional)',
      ADDRESS_NUMBER: 'Número (Opcional)',
      ADDRESS_COMPLEMENT: 'Complemento (Opcional)',
      ADDRESS_NEIGHBORHOOD: 'Bairro (Opcional)',
      ADDRESS_CITY: 'Cidade (Opcional)',
      ADDRESS_STATE: 'Estado (Opcional)',
      ADDRESS_ZIPCODE: 'CEP (Opcional)',
      IDWALL: 'Token da Idwall (Opcional)',
    },
  };

  // Função para formatar o rótulo (label) de cada campo
  const formatLabel = (key, isAdditional = false) => {
    if (isAdditional) {
      return labels.additional[key] || key;
    }
    return labels[key] || key;
  };

  // Mapeamento dos tipos de campo
  const fieldTypes = {
    type: 'select',
    ADDRESS_STATE: 'select',
  };

  // Função para determinar o tipo do campo (input ou select)
  const getFieldType = (key) => fieldTypes[key] || 'input';

  // Função para renderizar o campo adequado, dependendo do tipo
  const renderDynamicField = (key, item = '', isAdditional = false) => {
    const type = getFieldType(key);
    const label = formatLabel(key, isAdditional);
    const mask = key;

    if (isAdditional) {
      key = 'additional.' + key;
    }

    if (type === 'select') {
      const list = key === 'type' ? typeOptions : stateOptions;
      return (
        <React.Fragment key={key}>
          <DynamicSelectField
            props={{
              name: key,
              placeholder: label,
              list: list,
              keyProp: 'value', // Substitua pela chave correta da lista
              displayProp: 'name', // Substitua pelo valor a ser exibido
              value: item, // Preenche o valor (padrão será '') }} />
            }}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={key}>
        <DynamicInputField props={{ name: key, placeholder: label, item, mask }} />
      </React.Fragment>
    );
  };

  const safeValues = values || {};
  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="10px 25px">
      {Object.keys(labels).map((key) => {
        if (key === 'additional') {
          const additionalValues = safeValues.additional || {};
          return Object.keys(labels.additional).map((additionalKey) =>
            renderDynamicField(additionalKey, additionalValues[additionalKey], true)
          );
        }
        return renderDynamicField(key, safeValues[key]);
      })}
    </Grid>
  );
};

export default DynamicFormDriverProfile;
