import React, { useEffect, useState } from 'react';

import { MdDns } from 'react-icons/md';

import { Flex, Grid, Text } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

import CardRequest from './CardRequest';
import TableRequestRole from './TableRequestRole';
import TableRequestRote from './TableRequestRote';
import TableRequestStatusCode from './TableRequestStatusCode';
import TableRequestUser from './TableRequestUser';

const CardMetricsRequest = ({ searchPeriod }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setIsLoading(true);
    try {
      const response = await requests.requestSum({ period: searchPeriod });

      setList([response]);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
      loadList();
    }
  }, [searchPeriod]);

  return (
    <Card
      m="10px"
      header={
        <Flex gap="2px" direction="column">
          <Text textStyle="cardTitle" fontWeight="bold" color="primary">
            Métricas de requisições
          </Text>
        </Flex>
      }>
      <Flex direction="column" p="15px">
        {list.map((entity, key) => (
          <Flex 
            gap="15px" 
            key={key}
            flexDirection={{ base: "column", md:"row" }}
          >
            <ScreenLoader isLoading={isLoading} w="auto">
              <CardRequest
                value={entity.qttRequests}
                bgColor="#A885F9"
                text="Total de requisições"
                icon={<MdDns size={26} color="#FFFFFF" />}
              />
            </ScreenLoader>

            <ScreenLoader isLoading={isLoading} w="auto">
              <CardRequest
                value={entity.qttLoginSuccess}
                bgColor="#2ECC71"
                text="Requisições com sucesso"
                icon={<MdDns size={26} color="#FFFFFF" />}
              />
            </ScreenLoader>

            <ScreenLoader isLoading={isLoading} w="auto">
              <CardRequest
                value={entity.qttLoginFailure}
                bgColor="#E52359"
                text="Requisições com falha"
                icon={<MdDns size={26} color="#FFFFFF" />}
              />
            </ScreenLoader>
          </Flex>
        ))}

        <Grid 
          display={{ base:"flex", md:"grid" }}
          templateColumns={{ base:"initial", md:"repeat(2, 1fr)" }} 
          flexDirection={{ base:"column", md:"row" }}
          gap="55px" 
          mt="60px"
        >
          <TableRequestUser searchPeriod={searchPeriod} />
          <TableRequestRole searchPeriod={searchPeriod} />
          <TableRequestRote searchPeriod={searchPeriod} />
          <TableRequestStatusCode searchPeriod={searchPeriod} />
        </Grid>
      </Flex>
    </Card>
  );
};

export default CardMetricsRequest;
