/* 

exemplo de uso
const dayOfWeek = getDayOfWeek(date);

 */

const getDayOfWeek = (date) => {
  const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
  const formattedDate = new Date(date);
  const dayOfWeek = daysOfWeek[formattedDate.getDay()];
  return dayOfWeek;
};

export default getDayOfWeek;
