import React, { useState } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

import 'react-toastify/dist/ReactToastify.css';
import BackgroundLogin from '../../../components/Login/BackgroundLogin/BackgroundLogin';

import ReactivationCodeForm from './components/ReactivationCodeForm';
import ReactivationEmailForm from './components/ReactivationEmailForm';

const ReactivationPage = () => {
  const [step, setStep] = useState(1);

  return (
    <BackgroundLogin>
      <Flex direction="column" gap="20px">
        {step === 1 && <ReactivationEmailForm setStep={setStep} />}

        {step === 2 && <ReactivationCodeForm setStep={setStep} />}

        {step === 3 && (
          <Flex direction="column" gap="20px" justify="center" align="center" className="down-anim">
            <Text textStyle="subtitle" textColor="#FFFFFF">
              Seu usuário foi <Text as={'strong'}>reativado</Text> com sucesso e sua senha alterada.
            </Text>

            <Button
              w="fit-content"
              variant="solid"
              height="40px"
              borderRadius="5px"
              bgColor="#FF2F69"
              textColor="#FFFFFF"
              transition=".3s"
              _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
              onClick={() => {
                window.location.href = '/';
              }}>
              <Text>Acessar</Text>
            </Button>
          </Flex>
        )}
      </Flex>
    </BackgroundLogin>
  );
};

export default ReactivationPage;
