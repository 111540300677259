const yup = require('yup');

export const validationSchema = () => {

  return yup.object().shape({
    // title: yup.string().required('Campo obrigatório'),
    releasedAt: yup.string().required('Campo obrigatório'),
    expiredAt: yup.string().required('Campo obrigatório'),
    // status: yup.string().required('Campo obrigatório'),
  });
};
