const statusOptions = [
  { value: "EM_ANALISE", label: "Em análise"},
  { value: "APROVADO", label: "Aprovado"},
  { value: "REPROVADO", label: "Reprovado"},
  { value: "ATIVO", label: "Ativo"},
  { value: "INATIVO", label: "Inativo"},
  { value: "ANONIMIZADO", label: "Anonimizado"},
  { value: "EXPIRADO", label: "Expirado"}
];

export default statusOptions;