import { Button, Flex, Modal, ModalBody, ModalContent, Text, useDisclosure } from '@chakra-ui/react';

const NotificationModal = ({ callback, isOpen, onOpen, onClose }) => {
  const { isOpen: disclosureIsOpen, onOpen: disclosureOnOpen, onClose: disclosureOnClose } = useDisclosure();

  const handleOpen = () => {
    onOpen ? onOpen() : disclosureOnOpen();
  };

  const handleClose = () => {
    onClose ? onClose() : disclosureOnClose();
  };

  return (
    <Modal isOpen={isOpen || disclosureIsOpen} onOpen={handleOpen} onClose={handleClose} size="lg">
      <ModalContent p="10px 35px" borderRadius="50px">
        <ModalBody p="35px" position="relative">
          <Flex direction="column" alignItems="center" justifyContent="center">
            <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" mb="20px" className="up-anim">
              Você tem certeza que deseja realizar essa alteração?
              {/* <strong>{options.find((e) => e.value === fields.value)?.label}</strong>? */}
            </Text>
            <Flex>
              <Button variant="secundary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={() => callback(false)}>
                Cancelar
              </Button>
              <Button variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={() => callback(true)}>
                Continuar
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationModal;
