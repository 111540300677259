import { ListItem, Text } from '@chakra-ui/react';

export const CompanyInformationInfo = ({ field, value, children, ...props }) => {
  return (
    <ListItem display={'flex'} gap={'30px'} {...props}>
      <Text as={'strong'} minW={'100px'} color={'#0F0A1D'} fontSize={'16px'}>
        {field}:
      </Text>
      <Text color={'#707070'} fontSize={'16px'}>
        {value}
      </Text>
      {children}
    </ListItem>
  );
};
