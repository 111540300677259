import { useEffect, useState } from 'react';

import { Box, Button, Card, Divider, Flex, Grid, GridItem, HStack, Heading, Image, Switch, Text, Tooltip } from '@chakra-ui/react';

import { reportInfo, reportPlus } from '../../../../utils/exports';

import ReportInputParams from './ReportInputParams';

const ReportParams = ({
  dataReport,
  listDefautParams,
  shouldAddParameter,
  hiddenName,
  cols,
  isLabelDisabled,
  containerHeight,
  resetStyles,
  activateDefaultParameters,
  activateUnsavedChanges,
  showDisableParametersButton,
  unsavedChanges,
  disableParameters,
  setDisableParameters,
  isMobile,
}) => {
  const [formData, setFormData] = useState({
    defaultParameters: [],
    customParameters: [],
  });

  const templateColumns = `repeat(${cols}, 1fr)`;

  const [defaultParameters, setDefaultParameters] = useState();
  const [customParameters, setCustomParameters] = useState();
  const [updateFields, setUpdateFields] = useState(false);

  const [switchDisableParameters, setSwitchDisableParameters] = useState();

  const onChangeUpdateFields = () => {
    setUpdateFields(!updateFields);
  };

  const handleAddRow = () => {
    setFormData({
      defaultParameters: [...defaultParameters],
      customParameters: [...customParameters, { label: '', name: '', value: '' }],
    });

    updateReportParametersFieldCount('sum');
  };

  const handleUpdateRow = (param, index) => {
    localStorage.setItem('report-label-' + index, param.label);
    localStorage.setItem('report-name-' + index, param.name);
    localStorage.setItem('report-value-' + index, param.value);

    let updatedCustomParameters;

    updatedCustomParameters = customParameters.find((_, i) => i === index);

    (updatedCustomParameters.label = param.label === '' ? '-' : param.label),
      (updatedCustomParameters.name = param.name === '' ? '-' : param.name),
      (updatedCustomParameters.value = param.value === '' ? '-' : param.value);

    setFormData({
      defaultParameters: [...defaultParameters],
      customParameters: [...customParameters],
    });
  };

  const handleKeepRow = (param, index) => {
    let fieldLabel = localStorage.getItem('report-label-' + index);
    let fieldName = localStorage.getItem('report-name-' + index);
    let fieldValue = localStorage.getItem('report-value-' + index);

    let updatedCustomParameters;

    updatedCustomParameters = customParameters.find((_, i) => i === index);

    (updatedCustomParameters.label = fieldLabel ? fieldLabel : param.label),
      (updatedCustomParameters.name = fieldName ? fieldName : param.name),
      (updatedCustomParameters.value = fieldValue ? fieldValue : param.value);

    setFormData({
      defaultParameters: [...defaultParameters],
      customParameters: [...customParameters],
    });
  };

  const handleRemoveRow = (paramType, index) => {
    if (paramType === 'custom') {
      let updatedCustomParameters;

      updatedCustomParameters = customParameters.filter((_, i) => i !== index);

      localCleaner(index);

      setFormData({
        defaultParameters: [...defaultParameters],
        customParameters: updatedCustomParameters,
      });

      updateReportParametersFieldCount('sub');
    }
  };

  const updateReportParametersFieldCount = (operation) => {
    const reportParametersFieldCount = localStorage.getItem('report-params-fields');
    localStorage.removeItem('report-params-fields');
    localStorage.setItem(
      'report-params-fields',
      operation === 'sum' ? parseInt(reportParametersFieldCount + 1) : parseInt(reportParametersFieldCount - 1)
    );
  };

  const localCleaner = (index) => {
    localStorage.removeItem('report-name-undefined');
    localStorage.removeItem('report-value-undefined');

    if (index) {
      localStorage.removeItem(`report-name-${index}`);
      localStorage.removeItem(`report-value-${index}`);
    }
  };

  const setLocalStorage = (data) => {
    localStorage.setItem('report-parameters', JSON.stringify(data));
    localStorage.setItem('report-params-fields', data.dataReport ? data.dataReport.customParameters.length : 0);
  };

  const load = () => {
    setFormData({
      defaultParameters: dataReport ? dataReport.defaultParameters : [],
      customParameters: dataReport ? dataReport.parameters : [],
    });

    setLocalStorage(formData);
  };

  useEffect(() => {
    setCustomParameters(formData.customParameters);
    setDefaultParameters(formData.defaultParameters);
    setLocalStorage(formData);
    localCleaner();
  }, [formData]);

  useEffect(() => {
    setLocalStorage(formData);
  }, [updateFields]);

  useEffect(() => {
    load();
  }, [dataReport, listDefautParams]);

  return (
    <Card as="section" bg={'white'} h={containerHeight} boxShadow={resetStyles && 'none'}>
      <Flex
        as="header"
        borderBottom={resetStyles ? 'none' : '1px solid #0F0A1D26'}
        minH={'68px'}
        justifyContent={'space-between'}
        transition={'padding 0.5s ease 0s'}
        m={'0 26px 20px'}>
        <Heading
          display={'flex'}
          gap={'15px'}
          align={'center'}
          fontSize={'20px'}
          color={'primary'}
          fontWeight={'bold'}
          alignItems={'center'}
          fontFamily={'Hanken Grotesk'}>
          Parâmetros
          <Tooltip label="Os parâmetros devem ser inseridos na query no formato: ':nome_do_parâmetro'. Por exemplo :hoje ou :primeiro_dia_mes">
            <Image src={reportInfo} />
          </Tooltip>
        </Heading>
        {showDisableParametersButton && (
          <Flex alignItems={'center'} gap={'10px'}>
            <HStack>
              <style>
                {`
                    [data-checked] .chakra-switch__track {
                      background-color: #7F3AFC;
                    }
                  `}
              </style>
              <Switch
                isFocusable={true}
                size="md"
                bottom="2px"
                colorScheme="purple"
                onChange={() => {
                  setDisableParameters(!disableParameters);
                }}
                isChecked={disableParameters}
              />
            </HStack>
            <Text as={'span'} minWidth={'200px'}>
              {disableParameters && disableParameters ? 'Visualização habilitada' : 'Visualização desabilitada'}
            </Text>
          </Flex>
        )}
      </Flex>
      <Box padding={{ base: '0 15px 15px', md: '0 40px 15px' }}>
        {!isMobile && (
          <Grid
            boxShadow="0px 3px 20px #00000012"
            p={'15px 20px'}
            gridTemplateColumns={templateColumns}
            borderRadius="8px"
            border={'1px solid #70707036'}
            mb={'10px'}
            gap={'40px'}>
            <GridItem fontSize={'16px'} fontWeight={'bold'}>
              Label do parâmetro
            </GridItem>
            {!hiddenName && (
              <GridItem display={'flex'} gap={'6px'} fontSize={'16px'} fontWeight={'bold'}>
                Nome do parâmetro
                <Tooltip label="Não use espaços ou caracteres especiais no nome do parâmetro, mas dê nomes descritivos.">
                  <Image src={reportInfo} />
                </Tooltip>
              </GridItem>
            )}
            <GridItem fontSize={'16px'} fontWeight={'bold'}>
              Valor do parâmetro
            </GridItem>
          </Grid>
        )}
        <Flex p={'0 22px'} flexDirection={'column'} overflow={'auto'} maxH={'450px'}>
          {customParameters && (
            <ReportInputParams
              type={'custom'}
              onChangeUpdateFields={onChangeUpdateFields}
              handleRemoveRow={handleRemoveRow}
              handleUpdateRow={handleUpdateRow}
              handleKeepRow={handleKeepRow}
              hiddenName={hiddenName}
              isLabelDisabled={isLabelDisabled}
              activateUnsavedChanges={activateUnsavedChanges}
              unsavedChanges={unsavedChanges}
              cols={cols}
              list={customParameters}
              isMobile={isMobile}
            />
          )}
          {activateDefaultParameters && (
            <>
              <Divider m={'40px 0 20px'} />
              {defaultParameters && (
                <ReportInputParams
                  type={'default'}
                  onChangeUpdateFields={onChangeUpdateFields}
                  handleRemoveRow={handleRemoveRow}
                  handleUpdateRow={handleUpdateRow}
                  handleKeepRow={handleKeepRow}
                  hiddenName={hiddenName}
                  isLabelDisabled={isLabelDisabled}
                  activateUnsavedChanges={activateUnsavedChanges}
                  unsavedChanges={unsavedChanges}
                  cols={cols}
                  list={defaultParameters}
                  isMobile={isMobile}
                />
              )}
            </>
          )}

          {!shouldAddParameter && customParameters?.length === 0 && <Text>Não há parametros cadastrados</Text>}
        </Flex>
        {shouldAddParameter && (
          <Flex
            justifyContent={'center'}
            m={'25px 0 0'}
            position={'relative'}
            _before={{
              content: '""',
              borderBottom: '1px solid #70707036',
              position: 'absolute',
              width: '100%',
              height: '1px',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}>
            <Button
              bg={'white'}
              h={'28px'}
              w={'60px'}
              p={0}
              _hover={{
                bg: 'white',
              }}
              onClick={handleAddRow}>
              <Image
                src={reportPlus}
                _hover={{
                  transition: 'opacity .25s ease-in-out',
                  opacity: 0.8,
                }}
              />
            </Button>
          </Flex>
        )}
      </Box>
    </Card>
  );
};

export default ReportParams;
