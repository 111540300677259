export const FormattedList = (list, categories, path) => {
  const formattedValues = [];

  if (!list || !categories) {
    console.error('List or categories are undefined');
    return formattedValues;
  }

  const items = path || list;

  items.forEach((item) => {
    const obj = {};
    categories.forEach((category) => {
      const { value, defaultValue, renderHTML } = category;

      let fieldValue = item[value] !== undefined ? item[value] : defaultValue || '-';

      if (typeof renderHTML === 'function') {
        const renderedValue = renderHTML(item);
        fieldValue = renderedValue !== undefined && renderedValue !== null ? renderedValue : defaultValue || '-';
      }

      if (defaultValue !== '' || typeof renderHTML === 'function') {
        obj[value] = fieldValue;
      }
    });
    formattedValues.push(obj);
  });

  return formattedValues;
};
