/* 
Exemplo de como utilizar para retornar o valor buscado se existir o parametro

const parametersToCheck = ['param1', 'param2', 'param3'];
const foundParamValue = hasSpecificURLParameters(parametersToCheck);
*/

export default function hasSpecificURLParameters(params) {
  const searchParams = new URLSearchParams(window.location.search);

  for (const param of params) {
    if (searchParams.has(param)) {
      // Se o parâmetro existe, retornamos o valor associado a ele
      return {exists: true, value: searchParams.get(param)};
    }
  }

  return { exists: false, value: null };
}