import React from 'react';

import moment from 'moment';

import { Box, Flex, FormControl, FormLabel, Grid, Image, Text } from '@chakra-ui/react';

import 'moment/locale/pt-br';
import { getIconByText } from './getIconByText';

moment.locale('pt-br');

const FormEventScheduling = ({ events, getStatusColor }) => {
  const generalInfo = [
    { label: 'Selecione o tipo de agendamento', value: events.modality },
    { label: 'Número do container', value: events.container },
    { label: 'Número da DI', value: events.diNumber },
    { label: 'CNPJ', value: events.companyWarehouse?.documentNumber },
    { label: 'Placa do veículo', value: events.vehiclePlates },
    { label: 'Documento do motorista', value: events.driverDocument },
    { label: 'Nome do motorista', value: events.driverName },
  ];

  const companyInfo = [
    { label: 'Armazém da Empresa', value: events.companyWarehouse?.name },
    { label: 'CNPJ do Armazém', value: events.companyWarehouse?.documentNumber },
    { label: 'Depositante', value: events.companyDepositor?.name },
    { label: 'CNPJ do Depositante', value: events.companyDepositor?.documentNumber },
  ];

  const stageHistory = events.stage.map((stage) => ({
    label: stage.stage,
    value: moment(stage.happenedAt).format('DD/MM/Y hh:mm'),
  }));

  // Função para renderizar grids
  const renderGridSection = (title, data, columns, stage) => (
    <>
      <Text fontWeight="bold" mt="10px">
        {title}
      </Text>

      <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} bgColor="#F5F5F5" p="20px" borderRadius="6px" gap={stage ? '8px' : '20px'}>
        {data.map((field, index) => (
          <FormControl key={index}>
            <FormLabel fontWeight="bold">{field.label}</FormLabel>

            <Text textColor="#6B7280" fontSize={stage && '14px'}>
              {field.value || '-'}
            </Text>
          </FormControl>
        ))}
      </Grid>
    </>
  );

  return (
    <Flex direction="column" gap="10px" textColor="primary">
      {/* Header Info */}
      <Grid gridTemplateColumns="repeat(3, 1fr)" bgColor="#F5F5F5" p="20px" borderRadius="6px">
        <FormControl>
          <Text fontWeight="bold">{moment(events.expectedAt).format('DD/MM/Y hh:mm')}</Text>

          <Text textColor="#6B7280">
            {moment(events.expectedAt).format('dddd').charAt(0).toUpperCase() + moment(events.expectedAt).format('dddd').slice(1)}
          </Text>
        </FormControl>

        <Flex align="center" gap="15px">
          <Image w="25px" src={getIconByText(events.modality)} />

          <Text textColor={events.modality === 'RECEBIMENTO' ? '#6c48c2' : '#a885f9'} fontWeight="bold">
            {events.modality}
          </Text>
        </Flex>

        <Flex h="full" align="center" gap="10px" color={getStatusColor(events.currentStageModality.slug)}>
          <Box h="full" w="4px" bgColor={getStatusColor(events.currentStageModality.slug)} />

          <Flex direction="column">
            <Text fontSize="13px" textColor="#6B7280">
              Status
            </Text>

            <Text fontWeight="medium">{events.currentStageModality.name}</Text>
          </Flex>
        </Flex>
      </Grid>

      {/* Reuso da função renderGridSection */}
      {renderGridSection('Informações gerais', generalInfo, 2)}
      {renderGridSection('Empresas relacionadas', companyInfo, 2)}
      {events.stage?.length > 0 && renderGridSection('Histórico de estágios', stageHistory, 6, true)}
    </Flex>
  );
};

export default FormEventScheduling;
