import React from 'react';

import { MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Flex, Image, Text } from '@chakra-ui/react';

import error404 from '../../assets/images/404.png';
import bgError from '../../assets/images/bg-error.png';
import Layout from '../../components/Layout';

const Error404Page = () => {
  return (

    <Layout>
      <Flex
        justify="center"
        align="center"
        h="100vh"
        direction="column"
        backgroundImage={`url(${bgError})`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        mt="-70px" 
        p={{ base: "0 30px", md: "initial" }}
      >
        <Text
          textStyle="primaryTitle"
          textColor="#FFFFFF"
          fontSize={{ base: "28px", md: "40px" }}
          fontWeight="medium"
          fontStyle="italic"
          textShadow="1px 1px 3px rgba(0, 0, 0, 0.5)">
          Não tem nada aqui :(
        </Text>


        <Image 
          maxW={{ base: "250px", md: "initial" }}
          src={error404} 
        />

        <Link to="/dashboard">
          <Button
            h="60px"
            px="60px"
            bgColor="#A885F9"
            borderRadius="52px" 
            shadow="lg"
            _hover={{ bg: 'violet2' }}
            leftIcon={<MdHome color="#FFFFFF" size="20px" />}>
            <Text textStyle="tableTitle" textColor="#FFFFFF">
              Ir para a página inicial
            </Text>
          </Button>
        </Link>
      </Flex>
    </Layout>
  );
};

export default Error404Page;
