import React from 'react';

import { MdList } from 'react-icons/md';

import { Flex, Text } from '@chakra-ui/react';

import ProgressBarWithLabel from './ProgressBarWithLabel';

const ReportsCard = ({ list }) => {
  return (
    <Flex className="down-anim" direction="column" border="1px solid #7070703D" p="40px">
      <Flex justify="space-between" alignItems="center">
        <Text fontSize="22px" fontWeight="600" color="primary">
          Visão geral - {"CT-e's"}
        </Text>
        <MdList size="26px" />
      </Flex>
      <Flex direction="column" w="100%" mt="20px" gap="20px">
        <ProgressBarWithLabel label="CT-e's Ofertados" value={list?.total?.ctes?.ACCEPTED_BY_DRIVER ?? 0} max={list?.total?.qttCte} color="#6576FF" />
        <ProgressBarWithLabel label="CT-e's Entregues" value={list?.total?.ctes?.COMPLETED ?? 0} max={list?.total?.qttCte} color="#FFA353" />
        <ProgressBarWithLabel
          label="Viagens ofertadas"
          value={list?.total?.status?.ACCEPTED_BY_DRIVER ?? 0}
          max={list?.total?.qtt}
          color="#2ECC71"
        />
        <ProgressBarWithLabel label="Viagens entregues" value={list?.total?.status?.COMPLETED ?? 0} max={list?.total?.qtt} color="#F1C40F" />
      </Flex>
    </Flex>
  );
};

export default ReportsCard;
