import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, Grid, ModalBody, ModalFooter, Text } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import requests from '../../../../services/requests';
import { passwordInitialValues } from '../Helpers/passwordInitialValues';
import { passwordValidationSchema } from '../Helpers/passwordValidationSchema';

const AuthData = ({ entity, getUser, loadList, handleClose }) => {
  return (
    <Formik
      initialValues={passwordInitialValues()}
      validationSchema={passwordValidationSchema()}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        setSubmitting(true);
        if (values.password === values.confirmPassword) {
          requests
            .editUserPassword(entity.identifier, {
              password: values.password,
            })
            .then(() => {
              setSubmitting(false);
              getUser();
              loadList();
              document.getElementById('password').value = '';
              document.getElementById('confirmPassword').value = '';
              toast.success('Senha alterada com sucesso!');
            });
        } else {
          setSubmitting(false);
          setFieldError('confirmPassword', 'As senhas não coincidem');
        }
      }}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody mt="10px" overflowY="auto" h="500px" w="full" className="up-anim">
            <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="20px">
              <Flex direction="column">
                <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                  Alterar senha
                </Text>

                <Flex direction="column" gap="20px">
                  <Flex direction="column">
                    <LabelDefault name="password" text="Nova senha" />

                    <InputDefault
                      setFieldValue={setFieldValue}
                      value={values.password}
                      name="password"
                      placeholder="Preencha a nova senha"
                    />
                    <ErrorMessage component="p" className="error-message-error" name="password" />
                  </Flex>

                  <Flex direction="column">
                    <LabelDefault name="confirmPassword" text="Confirmar senha" />

                    <InputDefault
                      setFieldValue={setFieldValue}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      placeholder="Preencha a confirmação da nova senha"
                    />
                    <ErrorMessage component="p" className="error-message-error" name="confirmPassword" />
                  </Flex>
                </Flex>
              </Flex>

              <Flex direction="column">
                <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                  Autenticação
                </Text>

                <Flex direction="column">
                  <LabelDefault name="cellphone" text="Celular" />

                  <InputDefault
                    setFieldValue={setFieldValue}
                    value={values.cellphone}
                    name="cellphone"
                    placeholder="Preencha o celular"
                  />
                  <ErrorMessage component="p" className="error-message-error" name="cellphone" />
                </Flex>

                <Flex justifyContent="flex-end" mt="20px">
                  <Button
                    border="1px"
                    px="20px"
                    py="9px"
                    color="#E73668"
                    borderColor="#E52359"
                    bgColor="transparent"
                    type="button"
                    onClick={() => alert('Autenticado')}>
                    Autenticar
                  </Button>
                </Flex>
              </Flex>
            </Grid>
          </ModalBody>

          <ModalFooter borderTop="1px" borderColor="#7070702E" p="0">
            <Button
              border="1px"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="9px 23px"
              color="#E73668"
              borderColor="#E52359"
              bgColor="transparent"
              type="button"
              onClick={handleClose}>
              Cancelar e fechar
            </Button>

            <Button
              variant="primary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="9px 23px"
              type="submit"
              isLoading={isSubmitting}
              loadingText="Salvando">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AuthData;
