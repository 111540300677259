import { Box } from '@chakra-ui/react';

export const VoyageListIcon = ({ process, children }) => {
  return (
    <Box
      position={'absolute'}
      left={'-55px'}
      bg={process.originDescription === 'gestao' ? '#E52359' : '#A885F9'}
      borderRadius={'50px'}
      p={'5px'}
      top={'50%'}
      transform={'translateY(-50%)'}
      style={{ '--delay': `${5 / 10}s` }}>
      {children}
    </Box>
  );
};
