import { Skeleton, Stack } from '@chakra-ui/react';

export const SkeletonContent = ({ rows = 10 }) => {
  return (
    <Stack>
      {Array.from({ length: rows }).map((_, index) => (
        <Skeleton key={index} w={'full'} h={'58px'} borderRadius={'10px'} />
      ))}
    </Stack>
  );
};
