export const modalityAdditional = (modality) => {
    switch (modality) {
      case 'BIRTHDAY':
        return 'Data Nascimento';
      case 'ADDRESS':
        return 'Endereço';
      case 'NUMBER':
        return 'Número';
      case 'COMPLEMENT':
        return 'Complemento';
      case 'ZIPCODE':
        return 'CEP';
      case 'STATE':
        return 'Estado';
      case 'CITY':
        return 'Cidade';
      case 'NEIGHBORHOOD':
        return 'Bairro';
      case 'NAME':
        return 'Nome';
      case 'BANK':
        return 'Banco';
      case 'AGENCY':
        return 'Agência';
      case 'ACCOUNT':
        return 'Conta';
      default:
        return modality;
    }
  }