import moment from 'moment';

import { Text } from '@chakra-ui/react';

import { commonListConfigDocumentation } from '../../../../../components/CommonList/document/commonListConfigDocumentation';

// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  // Estrutura da tabela
  categories: [
    {
      title: 'Status',
      field: 'e.status',
      value: 'status',
      variantTag: 'softDashed',
    },
    {
      title: 'Conteúdo',
      field: 'e.description',
      value: 'description',
    },
    {
      title: 'Data de criação',
      field: 'e.createdAt',
      value: 'renderHTMLCreatedAt',
      align: 'center',
      renderHTML: (item) => <Text>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>,
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
    },
    {
      title: 'Ultima atualização',
      field: 'e.updatedAt',
      value: 'renderHTMLUpdatedAt',
      align: 'center',
      renderHTML: (item) => <Text>{moment(item.updatedAt).format('DD/MM/YYYY')}</Text>,
      /* formatted: (value) => moment(value).format('DD/MM/YYYY'), */
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  tags: ['status'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Publicado', value: 'PUBLISHED' },
    { color: '#F1C40F', label: 'Rascunho', value: 'DRAFT' },
    { color: '#E74C3C', label: 'Cancelado', value: 'CANCELLED' },
    { color: '#AFAEB4', label: 'Obsoleto', value: 'DEPRECATED' },
  ],
  mainCategoryMobile: 'status',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 115,
  // Estilo
  resizeRows: '220px 1fr 210px 210px 220px',
};
