export const handleInputChange = (index, setFieldValue, valueAll, value, name, type, form) => {
  let tempValue = valueAll[name];
  tempValue[index] = { ...valueAll[name][index], [type]: value };
  setFieldValue(`${form}.${name}`, tempValue);
};

export const handleAddClick = (controller, setController) => {
  setController([...controller, {}]);
};

export const handleRemoveChange = (setFieldValue, index, value, name, controller, setController, form) => {
  const list = [...controller];
  let tempValue = value;
  tempValue.splice(index, 1);
  list.splice(index, 1);
  setFieldValue(`${form}.${name}`, tempValue);
  setController(list);
};
export default { handleInputChange, handleAddClick, handleRemoveChange };
