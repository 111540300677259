import React from 'react';

import { Button, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';

const ModalDetailAdditionalData = ({ entity }) => {
  return (
    <DefaultModal
      ctaButton={
        <Button variant="secundary">
          <Text fontSize="14px">Histórico</Text>
        </Button>
      }
      title={'Histórico de alteração'}
      label="Visualizar todo o histórico"
      showButtons={false}>
      <ul
        style={{
          display: 'flex',
          flexFlow: 'column',
          gap: '10px',
          maxHeight: '70vh',
          overflowY: 'auto',
          padding: '20px',
        }}>
        {entity?.logs?.length > 0 ? (
          <Table className="table-default">
            <Thead border="1px" borderBottom="1px">
              <Tr>
                <Th>Alteração</Th>
                <Th>Data</Th>
                <Th>Usuário</Th>
              </Tr>
            </Thead>
            <Tbody border="1px">
              {entity.logs.map((log, index) => (
                <Tr key={index}>
                  <Td>{log.description}</Td>
                  <Td>{log.createdAtFormatted}</Td>
                  <Td>{log?.user?.name}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <li className="gray-box">Sem alterações</li>
        )}
      </ul>
    </DefaultModal>
  );
};

export default ModalDetailAdditionalData;
