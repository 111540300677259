import { memo } from 'react';

import { Field } from 'formik';

import { Input } from '@chakra-ui/react';

/**
 * Componente de campo oculto para Formik.
 * Envia o valor no submit, mas não é exibido visualmente.
 *
 * @param {object} props - Propriedades do campo.
 * @param {string} name - Nome do campo no Formik.
 * @returns {JSX.Element} Um campo de formulário oculto.
 */
const MemoizedDynamicHiddenField = ({ props }) => {
  const { name, ...restProps } = props;

  return <Field name={name}>{({ field }) => <Input {...field} {...restProps} type="hidden" />}</Field>;
};

// Exporta o componente memorizado para evitar renderizações desnecessárias
export const DynamicHiddenField = memo(MemoizedDynamicHiddenField);
