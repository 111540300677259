import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

const CardStatus = ({ bgColor, text, fontSize, p, margin, icon }) => {
  return (
    <Box w={{ base: "initial", md:"325px" }} bgColor={bgColor} borderRadius="6px" className="up-anim">
      <Flex align="center" h="full" textColor="primary" px="30px" p={p}>
        {icon}
        <Text w="full" ml={margin} textStyle="secondaryTitle" fontSize={fontSize} fontWeight="bold">
          {text}
        </Text>
        <Text textStyle="primaryTitle" fontWeight="bold" fontSize={{ base:"18px", md:"40px" }}>
          10
        </Text>
      </Flex>
    </Box>
  );
};

export default CardStatus;
