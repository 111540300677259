import { Grid } from '@chakra-ui/react';

import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';

export const renderContactFields = (contactIndex) => {
  const basePath = `contacts[${contactIndex}]`;

  return (
    <Grid key={contactIndex} gridTemplateColumns="1fr 1fr" columnGap="40px">
      <DynamicInputField
        props={{
          name: `${basePath}.name`,
          placeholder: 'Nome',
        }}
      />
      <DynamicInputField
        props={{
          name: `${basePath}.email`,
          placeholder: 'E-mail',
        }}
      />
    </Grid>
  );
};
