export const ProcessStyles = () => {
  return (
    <style>
      {`

        [data-border-green="true"] {
          position: relative;
        }

        [data-border-green="true"]::before {
          content: '';
          width: 41px;
          height: 41px;
          border: 2px solid #2ECC71;
          border-radius: 100%;
          position: absolute;
          box-sizing: border-box;
          z-index: 990;
        }
      `}
    </style>
  );
};
