import React from 'react';

import doneIcon from '../../assets/images/done.svg';
import logo from '../../assets/images/login/icons/logo.png';
import './style.css';

const Loading = ({ status = false, done = false }) => {
  return (
    <aside className={`loading ${status || done ? 'start' : 'stop'} ${done ? 'done' : ''}`}>
      <figure>
        <img className="logo-icon" src={logo} alt="Vendemmia - Carregando..." />
        <img className="done-icon" src={doneIcon} alt="Salvo" />
      </figure>
    </aside>
  );
};
export default Loading;
