import React from 'react';

import { MdClose } from 'react-icons/md';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

/**
 *
 * Template	padrão de modal do sistema.
 *
 * @param  label texto que aparece ao fazer hover no botão (opcional, default: "Excluir")
 * @param  title título do modal (obrigatório)
 * @param  subtitle subtítulo do modal (obrigatório)
 * @param  children itens internos (opcional)
 * @param  icon ícone do botão (obrigatório)
 * @param  txtButton texto do botão (opcional, default: "Confirmar")
 * @param  txtCancel texto do botão de cancelar (opcional, default: "Cancelar")
 * @param  buttonColor cor do botão (opcional, default: "primary" (roxo))
 * @param  loadingText texto do botão de excluir, default: "Carregando"
 * @param  loading spinner do botão de excluir, default: "false"
 * @param  enabled se o botão está ou não habilitado (opcional, default: true)
 * @param  callback evento que ocorre ao clicar em um dos botões do modal (tipo: function(bool)) ao clicar em cancelar ele retorna false e true caso o usuário confirme
 *
 */
const DefaultModal = ({
  label = 'Excluir',
  title,
  subtitle,
  ctaButton,
  children,
  buttonColor = 'primary',
  txtButton = 'Confirmar',
  txtCancel = 'Cancelar',
  loadingText = 'Carregando',
  loading = false,
  enabled = true,
  callback,
  showButtons = true,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = async (userDecision) => {
    if (callback) {
      await callback(userDecision);
    }

    onClose();
  };

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    enabled ? onOpen() : null;
  };

  return (
    <>
      <Tooltip label={label}>
        <Box
          onClick={flagClick.bind(this)}
          filter={enabled ? 'saturate(1)' : 'saturate(0)'}
          opacity={enabled ? '1' : '0.5'}
          cursor={enabled ? 'pointer' : 'unset'}>
          {ctaButton}
        </Box>
      </Tooltip>

      <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />

        <ModalContent maxH="100vh" maxW="100vw" w="auto" h="auto" {...props}>
          <ModalHeader p="0px">
            <Flex align="center" justify="flex-end">
              <IconButton bgColor="#ffffff" icon={<MdClose size={20} color="#00000057" />} onClick={onClose} />
            </Flex>
          </ModalHeader>

          <ModalBody position="relative" display="flex" flexDirection="column" gap="5px" overflow="auto">
            <Text textStyle="cardTitle" fontWeight="medium" textColor="#0F0A1D" textAlign="center" className="up-anim">
              {title}
            </Text>

            {subtitle && (
              <Text font="normal normal medium 20px/26px Hanken Grotesk" textColor="#0F0A1D80" textAlign="center" className="up-anim">
                {subtitle}
              </Text>
            )}

            {children}

            {showButtons && (
              <Flex alignItems="center" justifyContent="center" className="down-anim">
                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(false)}>
                  {txtCancel}
                </Button>

                <Button
                  variant="primary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  bg={buttonColor}
                  _active={{ background: buttonColor }}
                  _focus={{ background: buttonColor }}
                  _hover={{ opacity: 0.8 }}
                  isLoading={loading}
                  loadingText={loadingText}
                  onClick={() => handleClick(true)}>
                  {txtButton}
                </Button>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DefaultModal;
