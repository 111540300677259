import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { MdSearch } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import { Comments } from '../../../../components/Comments';
import { CommentsContent } from '../../../../components/Comments/CommentsContent';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

import FilterComments from './FilterComments';

const ProcessDetailActivity = ({ updates, process, onProcessUpdates }) => {
  const [comments, setComments] = useState([]);
  const [selectedOption, setSelectedOption] = useState('0');
  const [selectedStage, setSelectedStage] = useState('0');
  const [selectedOrder, setSelectedOrder] = useState('first');
  const [textareaValue, setTextareaValue] = useState('');
  const [searchString, setSearchString] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const defaultValue = process.currentStageModalitySequential - 1;

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectStage = (event) => {
    setSelectedStage(event.target.value);
  };

  const handleOrderChange = (event) => {
    setSelectedOrder(event.target.value);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const onChangeTextFilter = (e) => {
    let search = e.target.value.trim();
    setSearchString('');
    if (search.length > 2) {
      setSearchString(search);
    }
  };

  const getComments = async () => {
    setIsLoadingData(true);
    if (!process || !process.identifier) {
      return;
    }

    const filters = {
      stages: selectedStage,
      sortProcessComment: selectedOrder,
      search: searchString,
    };

    await requests.getProcessComments(process.identifier, filters).then((data) => {
      setComments(data.data);
      setIsLoadingData(false);
    });
  };

  const sendComment = async () => {
    setIsLoading(true);
    if (textareaValue === '') {
      return setIsLoading(false);
    }

    await requests
      .addProcessStageComment(process.identifier, updates[selectedOption].stage.sequential, {
        description: textareaValue,
        hasBeenRead: 0,
      })
      .then(async () => {
        setTextareaValue('');
        clearErrors();
        getComments();
        toast.success('Enviado com sucesso!');
        await onProcessUpdates();
      })
      .catch(() => {
        toast.error('Erro ao enviar o comentário, tente novamente.');
      });
    setIsLoading(false);
  };

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, []);

  useEffect(() => {
    getComments();
  }, [process, selectedOrder, selectedStage, searchString, updates]);

  return (
    <>
      <form onSubmit={handleSubmit(sendComment)}>
        <Textarea
          w="100%"
          h="150px"
          bg="#F2F2F2"
          resize="none"
          border="1px solid #7070703B"
          placeholder="Escreva sua mensagem..."
          {...register('text', { required: true })}
          name="text"
          value={textareaValue}
          onChange={handleTextareaChange}
        />
        {errors['text'] && <Text textStyle="error">Campo obrigatório.</Text>}

        {process.currentStageModalitySequential && (
          <Flex w="full" align="center" justify="space-between" mt="10px">
            <Flex direction="column">
              <Select
                variant="primary"
                placeholder="Selecione a etapa"
                w="fit-content"
                {...register('stage', { required: true })}
                name="stage"
                defaultValue={defaultValue.toLocaleString('pt-BR')}
                onChange={handleSelectChange}>
                <option value="0">Pré embarque</option>
                <option value="1">Trânsito</option>
                <option value="2">Desembaraço</option>
                <option value="3">Faturamento</option>
                <option value="4">Transporte</option>
                <option value="5">Entrega</option>
              </Select>
              {errors['stage'] && <Text textStyle="error">Campo obrigatório.</Text>}
            </Flex>

            <Button variant="primary" isLoading={isLoading} loadingText="Enviando" type="submit">
              Enviar
            </Button>
          </Flex>
        )}
      </form>

      <Divider my="30px" borderColor="#7070702E" />

      <Flex justify="space-between" alignItems="center" mt="10px" flexWrap="wrap">
        <Flex align="center" textStyle="tableTitle" gap="5px">
          <Text textColor="primary" fontWeight="bold">
            Comentários:
          </Text>

          <Text textColor="linkColor" fontWeight="medium">
            {process.qttComment}
          </Text>
        </Flex>

        <Flex align="center" gap="10px">
          <InputGroup w={{ base: 'initial', md: '330px' }}>
            <Tooltip label="Insira no mínimo 3 caracteres">
              <Input
                h="45px"
                placeholder="Buscar comentário"
                bgColor="#E8EAED"
                borderRadius="10px"
                borderColor="#70707026"
                onChange={(event) => {
                  onChangeTextFilter(event);
                }}
              />
            </Tooltip>

            <InputRightElement h="auto" my="auto" top="15px" alignItems="center" pointerEvents="none">
              <MdSearch color="#000000" size={15} />
            </InputRightElement>
          </InputGroup>

          <FilterComments
            handleSelectStage={handleSelectStage}
            handleOrderChange={handleOrderChange}
            selectedStage={selectedStage}
            selectedOrder={selectedOrder}
            onOpen={onOpen}
            isOpen={isOpen}
            onClose={onClose}
          />
        </Flex>
      </Flex>

      <Flex direction="column" mt="40px">
        <ScreenLoader isLoading={isLoadingData}>
          {isLoadingData && (
            <Flex justify="center" my="25px" w="100%">
              Carregando...
            </Flex>
          )}
          {!isLoadingData &&
            comments.map((comment) => (
              <Comments.Root key={comment.identifier}>
                <Comments.Avatar userName={comment.userName} userProfilePictureUri={comment.userProfilePictureUri} />
                <CommentsContent entity={comment} getComments={getComments} onProcessUpdates={onProcessUpdates} />
              </Comments.Root>
            ))}
          {/* <ProcessChatMessage entity={comment} key={comment.identifier} getComments={getComments} processComments={process} /> */}
        </ScreenLoader>
      </Flex>
    </>
  );
};

export default ProcessDetailActivity;
