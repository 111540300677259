import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import getProfile from '../../../services/profile';
import requests from '../../../services/requests';
import '../../../styles/globals.css';
import { editarPerfil, signatureEmail } from '../../../utils/exports';
import EditInfos from '../../DropdownProfile/Edit/EditInfos';
import EditPass from '../../DropdownProfile/Edit/EditPass';

const EditProfileModal = ({ profile = {}, setProfile = () => {} }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [newPicture, setNewPicture] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [password, setPassword] = useState('');

  const [isLoaded, setIsLoaded] = useState(false);

  const saveAll = async () => {
    setIsLoaded(true);
    let allOK = true;

    if (newPicture) {
      saveNewImage();
    }

    allOK = allOK && (await saveProfileData());

    if (password.length > 6) {
      allOK = allOK && (await saveProfilePassword());
    }

    await getProfile.update();

    if (allOK) {
      toast.success('Dados alterados com sucesso!');
      setIsLoaded(false);
    } else {
      toast.error('Algo deu errado, por favor, revise os dados inseridos.');
      setIsLoaded(false);
    }
  };

  const saveNewImage = async () => {
    if (!newPicture) {
      return;
    }

    const data = new FormData();
    data.append('image_upload[file]', newPicture);

    try {
      await requests.uploadUserPicture(getProfile.identifier, data);
      window.localStorage.setItem('profile-picture', imageBlob);
      setNewPicture(null);
    } catch (error) {
      toast.error('Falha ao alterar a imagem, tente novamente.');
    }
  };

  const saveProfileData = async () => {
    try {
      await requests.editProfile({
        name: profile.name,
        email: profile.email,
        cellphone: profile.cellphone,
        phone: profile.phone,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const saveProfilePassword = async () => {
    try {
      await requests.editPassword({
        password: password,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Flex gap="20px" align="center" cursor="pointer" onClick={onOpen}>
        <Image src={editarPerfil} w="44px" h="33px" />
        <Text textStyle="tableTitle" fontWeight="bold" color="primary" _hover={{ textDecoration: 'underline' }}>
          Editar perfil
        </Text>
      </Flex>

      {profile.role !== 'ROLE_CLIENT' && (
        <Flex
          gap="20px"
          align="center"
          cursor="pointer"
          onClick={(decision) => {
            if (decision) {
              navigate('/signature', {
                state: {
                  name: getProfile.name,
                  description: '',
                  email: getProfile.email,
                  phone: getProfile.cellphone ? getProfile.cellphone : getProfile.phone,
                  address: '',
                },
              });
            }
          }}>
          <Image src={signatureEmail} w="44px" h="33px" />
          <Text textStyle="tableTitle" fontWeight="bold" color="primary" _hover={{ textDecoration: 'underline' }}>
            Assinatura de e-mail
          </Text>
        </Flex>
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent maxW="768px" maxH="80vh" borderRadius="5px">
          <ModalBody p="25px" position="relative" overflowY="scroll">
            <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#422C76" mb="20px" fontSize={{ base: '22px', md: '30px' }}>
              Editar informações de perfil
            </Text>

            <Tabs>
              <TabList>
                <Tab _selected={{ borderColor: '#422C76' }}>
                  <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" fontSize={{ base: '16px', md: '20px' }}>
                    Edição de Perfil
                  </Text>
                </Tab>

                <Tab _selected={{ borderColor: '#422C76' }}>
                  <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" fontSize={{ base: '16px', md: '20px' }}>
                    Login e acesso
                  </Text>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <EditInfos
                    profile={profile}
                    setProfile={setProfile}
                    newPicture={newPicture}
                    setNewPicture={setNewPicture}
                    imageBlob={imageBlob}
                    setImageBlob={setImageBlob}
                  />
                </TabPanel>

                <TabPanel>
                  <EditPass setPassword={setPassword} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button variant="secundary" borderRadius="7px" onClick={onClose}>
              Cancelar e Fechar
            </Button>

            <Button variant="primary" borderRadius="7px" marginLeft="15px" isLoading={isLoaded} onClick={saveAll}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditProfileModal;
