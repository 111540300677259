import { Avatar, Flex } from '@chakra-ui/react';

export function CommentsAvatar({ userName, userProfilePictureUri } = props) {
  function getPicture(picture) {
    let url = process.env.REACT_APP_BASE_URL;

    if (url === 'http://localhost:8000' || 'http://localhost:8001') {
      url = 'https://api.vendemmia.com.br';
    }

    const newImage = window.localStorage.getItem('temp-profile-picture');
    const tempPicture = newImage || picture;
    return (url + tempPicture)?.replace('//profile', '/profile');
  }

  return (
    <Flex mt="15px" mr="20px" borderRadius="100%" border="#422C76 solid 1px" p="2px" h="fit-content">
      <Avatar w="40px" h="40px" src={getPicture(userProfilePictureUri)} name={userName} />
    </Flex>
  );
}
