import { useContext } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Button, Collapse, Flex, useDisclosure } from '@chakra-ui/react';

import CommonListItemLine from './CommonListItemLine';
import { CommonListContext } from './contexts/CommonListContext';
import CommonListTable from './templates/CommonListTable';

const CommonListItemContent = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  const {
    list,
    categories,
    data,
    isMobile,
    deviceType,
    viewport,
    hasDetailsButton,
    detailsRedirectEndpoint,
    hasDetailsButtonSublist,
    detailsRedirectEndpointSublist,
    hasDeleteButton,
    deleteButtonAction,
    deleteButtonSubtitle,
    customButtons,
    customFields,
    hasCollapsed,
    collapsedContentType,
    tooltipCategories,
    tags,
    statusPreset,
    images,
    imagePreset,
    order,
    orderChildren,
    subcategories,
    subList,
    pathSublist,
    isLoading,
    formattedList,
    emptyListMessage,
    maxLengthResume,
    maxLengthResumeChildren,
    cellRowSize,
    resizeRows,
    resizeRowsChildren,
    isLoaded,
  } = useContext(CommonListContext);

  const gridTemplateLength = resizeRows ? resizeRows : gridTemplateLength;

  //instance
  const item = props?.item;
  const listIndex = props?.listIndex;
  const content = props?.children;
  const rawItem = props?.rawItem;
  const identifier = props?.identifier;

  const customButtonsPrimary = customButtons && customButtons[0]?.main;
  const customButtonsSecundary = customButtons && customButtons[0]?.collapsed;

  //lenght
  //vazio trás a lista em primeiro nivel, se nao, em segundo.
  const sublistPathItens = pathSublist != '' ? data[listIndex][pathSublist] : [data[listIndex]];
  const sublistLength = sublistPathItens && sublistPathItens.length > 0;

  return (
    <>
      {hasCollapsed && sublistLength ? (
        //exibição dos itens com colapso
        <Button variant="reset" textAlign={'left'} color={'black-400'} lineHeight={'40px'} onClick={onToggle} position={'relative'}>
          <CommonListItemLine
            list={formattedList}
            categories={categories}
            item={item}
            rawItem={rawItem}
            content={content}
            gridTemplateLength={gridTemplateLength}
            hasDetailsButton={hasDetailsButton}
            detailsRedirectEndpoint={detailsRedirectEndpoint}
            hasDeleteButton={hasDeleteButton}
            deleteButtonAction={deleteButtonAction}
            deleteButtonSubtitle={deleteButtonSubtitle}
            customButtons={customButtonsPrimary}
            customFields={customFields}
            identifier={identifier}
            isMobile={isMobile}
            deviceType={deviceType}
            viewport={viewport}
            maxLengthResume={maxLengthResume}
            listIndex={listIndex}
            tags={tags}
            statusPreset={statusPreset}
            images={images}
            imagePreset={imagePreset}
            data={data}
            cellRowSize={cellRowSize}
            order={order}
            isLoading={isLoading}
          />
          <Flex data-collapsed>{isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}</Flex>
        </Button>
      ) : (
        //exibição dos itens sem colapso
        <CommonListItemLine
          list={formattedList}
          categories={categories}
          item={item}
          rawItem={rawItem}
          content={content}
          gridTemplateLength={gridTemplateLength}
          hasDetailsButton={hasDetailsButton}
          detailsRedirectEndpoint={detailsRedirectEndpoint}
          hasDeleteButton={hasDeleteButton}
          deleteButtonAction={deleteButtonAction}
          deleteButtonSubtitle={deleteButtonSubtitle}
          customButtons={customButtonsPrimary}
          customFields={customFields}
          identifier={identifier}
          isMobile={isMobile}
          deviceType={deviceType}
          viewport={viewport}
          maxLengthResume={maxLengthResume}
          listIndex={listIndex}
          tags={tags}
          statusPreset={statusPreset}
          images={images}
          imagePreset={imagePreset}
          data={data}
          cellRowSize={cellRowSize}
          order={order}
          isLoading={isLoading}
        />
      )}

      {
        //escolha da exibição do template do colapso
        hasCollapsed && (
          <Collapse in={isOpen} animateOpacity>
            {collapsedContentType === 'table' && (
              //As props à abaixo são necessárias pra renderizar o proprio componente de tabela

              <CommonListTable
                content={content}
                gridTemplateLength={gridTemplateLength}
                isMobile={isMobile}
                deviceType={deviceType}
                viewport={viewport}
                identifier={identifier} // envia o identificador para a lista mapear a posição
                categories={categories}
                hasDetailsButton={hasDetailsButtonSublist}
                detailsRedirectEndpoint={detailsRedirectEndpointSublist}
                deleteButtonAction={deleteButtonAction}
                deleteButtonSubtitle={deleteButtonSubtitle}
                customButtons={customButtonsSecundary}
                customFields={customFields}
                pathSublist={sublistPathItens}
                subcategories={subcategories}
                maxLengthResume={maxLengthResumeChildren}
                tooltipCategories={tooltipCategories}
                emptyListMessage={emptyListMessage}
                subList={subList}
                listIndex={listIndex}
                sublistPathItens={sublistPathItens}
                tags={tags}
                statusPreset={statusPreset}
                images={images}
                imagePreset={imagePreset}
                data={data}
                cellRowSize={cellRowSize}
                resizeRowsChildren={resizeRowsChildren}
                order={orderChildren}
                isLoading={isLoading}
              />
            )}
          </Collapse>
        )
      }
    </>
  );
};

export default CommonListItemContent;
