// Importando as imagens
import iconCollection from '../../../../assets/images/iconCollection.svg';
import iconReceived from '../../../../assets/images/iconReceived.svg';

// Função exportável para retornar o ícone correspondente
export function getIconByText(modality) {
  switch (modality) {
    case 'RECEBIMENTO':
      return iconReceived;
    case 'COLETA':
      return iconCollection;
    default:
      // Retorne um ícone padrão ou `null` se o valor não for esperado
      return null;
  }
}
