import React, { useEffect, useState } from 'react';

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const InconsistentTable = ({ process, updates }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getData = () => {
    if (!process || !process.identifier) {
      return;
    }

    requests
      .listProcessInconsistencies(process.identifier)
      .then((data) => {
        setData(data);
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    getData();
  }, [process, updates]);

  return (
    <ScreenLoader isLoading={isLoadingData}>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Etapa</Th>
            <Th>Título</Th>
            <Th>Descrição</Th>
          </Tr>
        </Thead>

        <Tbody border="1px">
          {data?.length > 0 &&
            data.map((entity, key) => (
              <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                <Td>{entity?.stage ?? '-'}</Td>
                <Td>{entity?.title ?? '-'}</Td>
                <Td>{entity?.description ?? '-'}</Td>
              </Tr>
            ))}

          {isLoadingData ? (
            <Tr>
              <Td colSpan={3}>
                <Flex justify="center" my="25px">
                  Carregando...
                </Flex>
              </Td>
            </Tr>
          ) : (
            <>
              {data.length === 0 && (
                <Tr>
                  <Td colSpan={3}>
                    <Flex justify="center" my="25px">
                      Nenhum dado encontrado para os parâmetros filtrados.
                    </Flex>
                  </Td>
                </Tr>
              )}
            </>
          )}
        </Tbody>
      </Table>
    </ScreenLoader>
  );
};

export default InconsistentTable;
