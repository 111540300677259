import React, { useState } from 'react';

import { GrGallery } from "react-icons/gr";
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  position: relative;
  border: 1px dashed #6C48C285;
  border-radius: 2rem;
  background-color: #422C760D;

  label {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    cursor: pointer;

    &:hover {
      background: rgba(220, 220, 220, 0.13);
    }

    input[type='file'] {
      display: none;
    }
  }

  .upload-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #646464;

    .icon {
      font-size: 6rem;
      margin-bottom: 0.5rem;
      color: #6C48C2;

      path {
        stroke: #6C48C2 !important;
      }
    }

    strong {
      font-size: 1.313;
      font-weight: bold;

      a {
        color: #6C48C2;
        text-decoration: underline;
      }
    }

    span {
      color: #00000047;
      font-size: 1.188rem;
    }
  }
`;

export default function InputPhoto({ onChange, accept, multiple }) {
    const [inputKey, setInputKey] = useState(Date.now());
  const acceptText = accept
    .split(',')
    .map((d) => (d === 'image/*' ? 'Imagens' : d))
    .join(', ');

    const handleFileChange = (e) => {
        onChange(e);
        setInputKey(Date.now());
      };

  return (
    <InputContainer>
      <label htmlFor="upload">
        <input
          key={inputKey}
          type="file"
          name="upload"
          id="upload"
          accept={accept}
          onChange={handleFileChange}
          multiple={multiple}
        />
        <div className="upload-info">
          <GrGallery className="icon" color="#6C48C2" />
          <strong><a>Clique para subir</a> ou solte seu arquivo aqui</strong>
          <span>{acceptText?.toUpperCase()} com o mínimo de 200x200 px de resolução</span>
        </div>
      </label>
    </InputContainer>
  );
}
