import React, { forwardRef } from 'react';

import { Card as CardChakra, Flex, Text } from '@chakra-ui/react';

const Card = forwardRef(({ title, header, children, ...props }, ref) => {
  return (
    <CardChakra
      bgColor="#FFFFFF"
      h="auto"
      overflow="hidden"
      direction="column"
      border="1px"
      borderColor="#7070703D"
      ref={ref}
      style={{ backgroundColor: 'white' }}
      {...props}>
      {(title || header) && (
        <Flex justify="space-between" alignItems="center" p="10px 15px" borderBottom="#70707036 solid 1px">
          {title && <Text textStyle="cardTitle">{title}</Text>}
          {header}
        </Flex>
      )}
      {children}
    </CardChakra>
  );
});

Card.displayName = 'Card';

export default Card;
