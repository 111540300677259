import React from 'react';

import { Flex, Tag, Text } from '@chakra-ui/react';

const DetailsProcessMetrics = ({ title, totalProcess, totalCarbon, totalNeutral }) => {
  return (
    <Flex direction="column" gap="20px">
      <Tag
        h="138px"
        w="302px"
        bgColor="#6794DC"
        p="37px 30px"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        gap="5px"
        textColor="white">
        <Text fontSize="20px">{title}</Text>

        <Text fontSize="40px">{totalProcess}</Text>
      </Tag>

      <Tag
        h="138px"
        w="302px"
        bgColor="#2ECC71"
        p="37px 30px"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        gap="5px"
        textColor="white">
        <Text fontSize="20px">Pegada de carbono</Text>

        <Flex w="full" gap="5px" align="center" justify="space-between" flexWrap="wrap">
          <Text fontSize="40px">{totalCarbon}</Text>

          <Text fontSize="16px">Toneladas</Text>
        </Flex>
      </Tag>

      <Tag
        h="138px"
        w="302px"
        bgColor="#6C48C2"
        p="37px 30px"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        gap="5px"
        textColor="white">
        <Text fontSize="20px">Carbono neutro</Text>

        <Flex w="full" gap="5px" align="center" justify="space-between" flexWrap="wrap">
          <Text fontSize="40px">{totalNeutral}</Text>
          <Text fontSize="16px">Toneladas</Text>
        </Flex>
      </Tag>
    </Flex>
  );
};

export default DetailsProcessMetrics;
