import React from 'react';

import { MdNorth } from 'react-icons/md';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import graph1 from '../../../../assets/images/graph1.svg';
import graph2 from '../../../../assets/images/graph2.svg';
import graph3 from '../../../../assets/images/graph3.svg';
import graph4 from '../../../../assets/images/graph4.svg';

const CardStatusRequests = () => {
  return (
    <Box bgColor="#FFFFFF" border="1px" borderColor="#7070703D" className="up-anim" py="50px" px="40px">
      <Flex direction="column" gap="40px">
        <Flex justify="space-between">
          <Flex direction="column" gap="18px">
            <Text textStyle="subtitle">Pedidos enviados completos</Text>
            <Image src={graph1} />
          </Flex>

          <Flex direction="column" align="flex-end">
            <Text textStyle="primaryTitle" fontWeight="bold">
              32
            </Text>
            <Flex direction="row" align="center">
              <MdNorth color="#2ECC71" />
              <Text textShadow="paragraph" textColor="green">
                3,5%
              </Text>
            </Flex>
            <Text textStyle="paragraph">Desde a última semana</Text>
          </Flex>
        </Flex>

        <Flex justify="space-between">
          <Flex direction="column" gap="18px">
            <Text textStyle="subtitle">Pedidos enviados completos</Text>
            <Image src={graph2} />
          </Flex>

          <Flex direction="column" align="flex-end">
            <Text textStyle="primaryTitle" fontWeight="bold">
              32
            </Text>
            <Flex direction="row" align="center">
              <MdNorth color="#2ECC71" />
              <Text textShadow="paragraph" textColor="green">
                3,5%
              </Text>
            </Flex>
            <Text textStyle="paragraph">Desde a última semana</Text>
          </Flex>
        </Flex>

        <Flex justify="space-between">
          <Flex direction="column" gap="18px">
            <Text textStyle="subtitle">Pedidos enviados completos</Text>
            <Image src={graph3} />
          </Flex>

          <Flex direction="column" align="flex-end">
            <Text textStyle="primaryTitle" fontWeight="bold">
              32
            </Text>
            <Flex direction="row" align="center">
              <MdNorth color="#2ECC71" />
              <Text textShadow="paragraph" textColor="green">
                3,5%
              </Text>
            </Flex>
            <Text textStyle="paragraph">Desde a última semana</Text>
          </Flex>
        </Flex>

        <Flex justify="space-between">
          <Flex direction="column" gap="18px">
            <Text textStyle="subtitle">Pedidos enviados completos</Text>
            <Image src={graph4} />
          </Flex>

          <Flex direction="column" align="flex-end">
            <Text textStyle="primaryTitle" fontWeight="bold">
              32
            </Text>
            <Flex direction="row" align="center">
              <MdNorth color="#2ECC71" />
              <Text textShadow="paragraph" textColor="green">
                3,5%
              </Text>
            </Flex>
            <Text textStyle="paragraph">Desde a última semana</Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CardStatusRequests;
