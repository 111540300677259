import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { MdEdit, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';

import CreateLinkDrawer from './CreateLinkDrawer';
import EditLinkDrawer from './EditLinkDrawer';

const LinkListDrawer = ({ isOpen, onClose, identifier, entity }) => {
  const [loadingLink, setLoadingLink] = useState(false);
  const [listLink, setListLink] = useState([]);

  const [showLinkManagement, setShowLinkManagement] = useState(false);
  const [entityShareTrip, setEntityShareTrip] = useState();

  const { isOpen: isOpenShareTrip, onOpen: onOpenShareTrip, onClose: onCloseShareTrip } = useDisclosure();
  const { isOpen: isOpenConfirmModal, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure();

  const formatDate = (date) => {
    return moment(date).format('DD/MM/yyyy HH:mm:ss');
  };

  const loadTripLinksList = () => {
    setLoadingLink(true);

    requests
      .getTripLinksList(identifier)
      .then((response) => setListLink(response.data))
      .finally(() => setLoadingLink(false));
  };

  useEffect(() => {
    loadTripLinksList();
  }, [identifier]);

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xl">
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader display="flex" alignItems="flex-start" gap="20px">
            <Box w="4px" h="full" bgColor="linkColor" />

            <Flex direction="column">
              <Text textColor="primary">Links da viagem</Text>

              <Text textColor="primary" opacity="0.6" fontSize="small">
                id: #{entity?.code}
              </Text>
            </Flex>
          </DrawerHeader>

          <DrawerBody border="1px solid #422C761A" borderRadius="5px" m="30px">
            <ScreenLoader isLoading={loadingLink}>
              <Table>
                <Thead>
                  <Tr className="border">
                    <Th>
                      <Box p="10px!important"></Box>
                    </Th>

                    <Th>
                      <Box p="10px!important">Liberação do link</Box>
                    </Th>

                    <Th>
                      <Box p="10px!important">Expiração do link</Box>
                    </Th>

                    <Th>
                      <Box p="10px!important"></Box>
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {listLink.length > 0 ? (
                    listLink.map((entityLink, key) => (
                      <Tr className="border" key={key}>
                        <Td>
                          <Flex p="10px!important"></Flex>
                        </Td>

                        <Td>
                          <Flex p="10px!important">{formatDate(entityLink?.releasedAt)}</Flex>
                        </Td>

                        <Td>
                          <Flex p="10px!important">{formatDate(entityLink?.expiredAt)}</Flex>
                        </Td>

                        <Td>
                          <Flex p="10px!important" gap="5px" justify="flex-end">
                            <Tooltip label="Gerenciar link">
                              <Box>
                                <IconButton
                                  h="31px"
                                  w="40px"
                                  bgColor="#EAE3F4"
                                  icon={<MdEdit color="#6C48C2" />}
                                  onClick={() => {
                                    setShowLinkManagement(true); // Habilita a gestão do link
                                    setEntityShareTrip(entityLink); // Armazena a entidade
                                    onOpenShareTrip(); // Abre o DrawerLinkSharing
                                  }}
                                />
                              </Box>
                            </Tooltip>

                            {entityLink?.status !== 'EXPIRADO' && (
                              <>
                                <Tooltip label="Abrir link">
                                  <Link
                                    to={`/public/tms/trip/${entity?.identifier}/link/${entityLink?.identifier}/share`}
                                    target="_blank">
                                    <IconButton h="31px" w="40px" bgColor="#EAE3F4" icon={<MdOpenInNew color="#6C48C2" />} />
                                  </Link>
                                </Tooltip>
                              </>
                            )}
                          </Flex>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr className="border">
                      <Td>
                        <Flex></Flex>
                      </Td>

                      <Td colSpan={5}>
                        <Flex justify="center">Nenhum link foi cadastrado para essa viagem até o momento.</Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </ScreenLoader>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="primary"
              onClick={() => {
                setShowLinkManagement(false); // Desabilita a gestão do link
                setEntityShareTrip(null); // Não armazena nenhuma entidade
                onOpenShareTrip(); // Abre o DrawerLinkSharing
              }}>
              Novo link
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {showLinkManagement ? (
        <EditLinkDrawer
          entity={entity}
          entityShareTrip={entityShareTrip}
          isOpenShareTrip={isOpenShareTrip}
          onCloseShareTrip={onCloseShareTrip}
          setListLink={setListLink}
          reloadTripLinksList={loadTripLinksList}
          isOpenConfirmModal={isOpenConfirmModal}
          onOpenConfirmModal={onOpenConfirmModal}
          onCloseConfirmModal={onCloseConfirmModal}
        />
      ) : (
        <CreateLinkDrawer
          entity={entity}
          isOpenShareTrip={isOpenShareTrip}
          onCloseShareTrip={onCloseShareTrip}
          setListLink={setListLink}
          reloadTripLinksList={loadTripLinksList}
        />
      )}
    </>
  );
};

export default LinkListDrawer;
