import React, { useEffect, useState } from 'react';

import { TabPanel, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import Tag from '../../../../../components/Generic/Tag';
import { limitChars } from '../../../../../utils/strings';

import PersonalDataActions from './PersonalDataActions';

const PersonalDataTab = ({ user, refresh }) => {
  const [documents, setDocuments] = useState([]);
  const [load, setLoad] = useState(false);

  const listDocuments = () => {
    setLoad(true);
    setDocuments(user?.personalData);
    setLoad(false);
  };

  useEffect(() => {
    listDocuments();
  }, []);

  const convertStatusColor = (original) => {
    switch (original) {
      case 'ERROR':
        return 'red';
      case 'SUCCESS':
        return 'green';
      default:
        return 'yellow';
    }
  };

  useEffect(() => {
    listDocuments();
  }, [user]);

  return (
    <TabPanel>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Dados</Th>
            <Th>Data de envio</Th>
            <Th>Status</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {!load &&
            documents?.length > 0 &&
            documents?.map(function (document, key) {
              return (
                <React.Fragment key={key}>
                  <Tr style={{ '--delay': `0.${key}s` }}>
                    <Td>{limitChars(document?.name)}</Td>
                    <Td>{document?.lastSendDateFormatted}</Td>

                    <Td>
                      <Tag type={convertStatusColor(document?.priorityType)}>{document?.statusFormatted}</Tag>
                    </Td>
                    <Td>
                      <PersonalDataActions
                        identifier={user.identifier}
                        additional={user?.additional}
                        section={document?.card}
                        status={document?.status}
                        enableActions={document?.status == 'PENDDING'}
                        documentUrl={document?.fileUrl}
                        refresh={refresh}
                        user={user}
                      />
                    </Td>
                  </Tr>
                </React.Fragment>
              );
            })}
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default PersonalDataTab;
