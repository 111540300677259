import React from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Button, Flex, IconButton, Select, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import InputStyled from './InputStyled';
import Form from './styles';

const CardFile = ({
  accept = '.pdf, .docx, .xml, .jpg, .jpeg, .png, .pfx, csv',
  multiple = true,
  entity,
  additional = [],
  requestAction = () => {},
}) => {
  const generateInitialValues = () => {
    const initialValues = {
      files: {},
      modality: {},
    };

    return initialValues;
  };

  const removeTempFile = ({ values, key, setValues, setErrors }) => {
    const form = document.getElementById('form');
    form.reset();
    setErrors({});
    delete values.files[key];
    setValues({ ...values });
  };

  let source;

  const onSubmit = (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);
    const { files } = values;
    const form = document.getElementById('form');

    if (!Object.keys(files).length) {
      toast.error('Clique e escolha um arquivo ou arraste.');
    }

    source = axios.CancelToken.source();
    let hasError = 0;
    const data = new FormData();

    data.append('cargoNumber', entity?.cargoNumber);
    data.append('client', entity?.client);
    data.append('noteNumber', entity?.noteNumber);

    Object.entries(files).forEach(([key, file]) => {
      data.append(`files[${key}][file]`, file);
      data.append(`files[${key}][modality]`, values?.modality[key].label ?? values?.modality[key].toUpperCase());

      if (values?.modality[key] === undefined || values?.modality[key] === null || values?.modality[key] === '') {
        setFieldError(`modality[${key}]`, 'Campo obrigatório');
        hasError++;
      }
    });

    if (hasError === 0) {
      requestAction(data, null)
        .then(() => {
          setSubmitting(false);
          form.reset();
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      setSubmitting(false);
    }
  };

  const checkOptionMatch = (fileName, options) => {
    const lowerCaseFileName = fileName.toLowerCase();

    for (const option of options) {
      const lowerCaseOptionValue = option.value.toLowerCase();

      if (lowerCaseFileName.includes(lowerCaseOptionValue)) {
        return lowerCaseOptionValue;
      }
    }

    return '';
  };

  /* const preselectOption = (fileName) => {
    additional[0].options.forEach((element, index) => {
      const lowerCaseFileName = fileName.toLowerCase();

      
      if (lowerCaseFileName.includes(element.name)) {
        setFieldValue(`modalitySelect`, fileName);
      }
    });
  }; */

  return (
    <Formik initialValues={generateInitialValues(additional)} onSubmit={onSubmit}>
      {({ setValues, handleSubmit, isSubmitting, values, setFieldValue, errors, setErrors, setFieldError }) => (
        <Form id="form" onSubmit={handleSubmit}>
          <Flex direction="column" gap="15px" w="full">
            <InputStyled
              name="files"
              id="files"
              accept={accept}
              multiple={multiple}
              onChange={(e) => {
                const files = Array.from(e.target?.files);

                files.forEach((file, index) => {
                  const key = file.lastModified;
                  setFieldValue(`files[${key}]`, file);

                  let matchedOption = checkOptionMatch(file.name, additional[0].options);

                  if (matchedOption != '') {
                    setFieldValue(`modality[${key}]`, { value: 'romaneio', label: 'ROMANEIO' });
                  }
                });
              }}
            />
            {!Object.keys(values.files).length && (
              <ErrorMessage component="p" className="text-red mb-4 font-light text-xs" name={`files`} />
            )}
          </Flex>

          <Flex direction="column" gap="15px" w="full" mt="15px">
            <Table className="table-default" w="100%">
              <Thead>
                <Tr>
                  <Th>Arquivo</Th>
                  {additional.map((element, index) => (
                    <Th key={index}>{element?.label}</Th>
                  ))}
                  <Th>Ações</Th>
                </Tr>
              </Thead>

              <Tbody>
                {Object.entries(values.files)?.length > 0 ? (
                  <>
                    {Object.entries(values.files).map(([key, file]) => (
                      <Tr key={key}>
                        <Td>{file.name}</Td>

                        {additional.map((element, index) => {
                          const matchedOption = checkOptionMatch(file.name, additional[0].options);

                          return (
                            <Td key={index}>
                              <Select
                                placeholder="Selecione"
                                value={element.options.find((value) => value.value === matchedOption)?.value}
                                name={`${element.name}[${key}]`}
                                onChange={(item) => {
                                  setFieldValue(`${element.group}[${key}]`, item?.target?.value);

                                  if (typeof element?.additionalOnChange === 'function') {
                                    element.additionalOnChange(item?.target?.value);
                                  }
                                }}>
                                {element.options?.length > 0 &&
                                  element.options.map(function (option, index2) {
                                    return (
                                      <option key={index2} value={option.value}>
                                        {option.label}
                                      </option>
                                    );
                                  })}
                              </Select>

                              {errors[element.group]?.[key] && (
                                <p
                                  style={{
                                    fontSize: '12px',
                                    color: '#f22c3d',
                                    marginTop: '2px',
                                    marginLeft: '3px',
                                  }}>
                                  Campo Obrigatório
                                </p>
                              )}
                            </Td>
                          );
                        })}

                        <Td>
                          <IconButton
                            bg="none"
                            p="0"
                            icon={<FaTrashAlt size={20} color="#EF8484" />}
                            onClick={() => removeTempFile({ values, key, setValues, setErrors })}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </>
                ) : (
                  <Tr>
                    <Td colSpan={2 + additional.length}>
                      <Flex justify="center" my="5px">
                        Nenhum arquivo selecionado
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <Flex direction="row" w="full" justifyContent="flex-end">
              <Button
                ml="4"
                variant="primary"
                px="15px"
                py="15px"
                fontSize="14px"
                loadingText="Carregando..."
                isLoading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
                isDisabled={Object.keys(values.files).length === 0}>
                Salvar
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CardFile;
