import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import moment from 'moment';
import { MdDownloadForOffline, MdOutlineFileCopy, MdOutlineLowPriority, MdOutlineSave } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Box, Button, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';

import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../components/Card/Card';
import { LabelDefault } from '../../../components/Form/Label/LabelDefault';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import api from '../../../services/api';
import profile from '../../../services/profile';
import requests from '../../../services/requests';

import DocumentModalView from './components/DocumentModalView';
import FormContainers from './components/FormContainers';
import FormGeneralInfo from './components/FormGeneralInfo';
import FormMerchandiseCargo from './components/FormMerchandiseCargo';
import FormQuote from './components/FormQuote';
import LogHistoryDrawer from './components/LogHistoryDrawer';
import ModalListDocuments from './components/ModalListDocuments';
import ModalStatusChange from './components/ModalStatusChange';
import { initialValues } from './Helpers/initialValues';
import { validationSchema } from './Helpers/validationSchema';

const QuoteDetailPage = () => {
  const [listUser, setListUser] = useState(false);
  const [isLoadCurrency, setIsLoadCurrency] = useState(false);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);

  const [loadUserOptions, setLoadUserOptions] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  const [clientsOptions, setClientsOptions] = useState([]);

  const [entity, setEntity] = useState({});
  const [modality, setModality] = useState();
  const [currency, setCurrency] = useState([]);
  const [logHistory, setLogHistory] = useState([]);
  const [requiredList, setRequiredList] = useState({});
  const [inputNcmsRelations, setInputNcmsRelations] = useState([]);

  const [action, setAction] = useState(1);
  const [logHistoryMeta, setLogHistoryMeta] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const { identifier } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const optionStatus = [
    {
      label: 'Aprovar',
      value: 'APPROVED',
    },
    {
      label: 'Rejeitar',
      value: 'REJECTED',
    },
    {
      label: 'Cancelar',
      value: 'CANCELLED',
    },
    {
      label: 'Análise logística',
      value: 'ON_REVIEW_LOGISTICS',
    },
    {
      label: 'Análise operacional',
      value: 'ON_REVIEW_OPERATIONAL',
    },
    {
      label: 'Finalizando sem fechamento',
      value: 'FINALIZED',
    },
  ];

  const optionStatusLogistics = [
    {
      label: 'Análise logística',
      value: 'ON_REVIEW_LOGISTICS',
    },
    {
      label: 'Análise operacional',
      value: 'ON_REVIEW_OPERATIONAL',
    },
    {
      label: 'Finalizando sem fechamento',
      value: 'FINALIZED',
    },
  ];

  const loadUsers = (client = undefined) => {
    if (client?.length > 0) {
      setLoadUserOptions(true);

      let aux = [];
      client.map((data) => {
        aux.push(data.value);
      });

      api
        .get(`/quote/client/users`, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
          },
          params: { clients: aux },
        })
        .then((res) => {
          let options = res?.data.map((data) => {
            return {
              value: data.identifier,
              label: data.name,
            };
          });

          setUserOptions(options);
          setLoadUserOptions(false);
        })
        .finally(() => {
          setLoadUserOptions(false);
        });
    }
  };

  const getQuote = () => {
    setIsLoadingEntity(true);

    requests.getQuoteShow(identifier).then((data) => {
      if (data?.transportModalitySlug === 'sea') {
        setRequiredList({ modality: 'Marítimo (sea)' });
      } else if (data?.transportModalitySlug === 'air') {
        setRequiredList({ modality: 'Aéreo (air)' });
      }

      setEntity(data);

      if (data && data.ncms && data.ncms.length > 0) {
        let aux = [];
        data.ncms.forEach((ncm) => {
          aux.push({
            code: ncm.code,
            description: ncm.code + ' ' + ncm.description,
            exTariffs: ncm.exTariffs,
          });
        });

        setInputNcmsRelations(aux);
      }

      setIsLoadingEntity(false);
    });
  };

  const loadCurrency = () => {
    setIsLoadCurrency(true);

    requests.getCurrency().then((response) => {
      let aux = [];
      response.data.map((entity) => {
        aux.push({
          value: entity.code,
          label: entity.name,
        });
      });

      setCurrency(aux);
      setIsLoadCurrency(false);
    });
  };

  const loadLogHistory = () => {
    requests.getLogHistory(identifier, logHistoryMeta.current_page > 0 ? logHistoryMeta.current_page : 1, 10).then((res) => {
      setLogHistory(res.data);
      setLogHistoryMeta(res.meta);
    });
  };

  const handleClickSelect = (userDecision) => {
    userDecision ? onClose() : onClose();
  };

  const handleReactive = (entity = {}) => {
    requests.postRequestOperacional(entity?.identifier, { status: 'reactive' }).then((response) => {
      toast.success('Cotação reativada com sucesso');
      setTimeout(() => {
        window.location.href = '/vendemmia/quote';
      }, 2000);
    });
  };

  const handleDuplicateQuote = (entity) => {
    requests.postQuoteDuplicate(entity?.identifier).then((response) => {
      toast.success(`${response.message}`);

      let entityOld = `${response.newEntityDuplicated?.name} (Cópia de ${response.entity?.name})`;
      localStorage.setItem('entity-old', entityOld);

      setTimeout(() => {
        window.location.href = `/vendemmia/quote/detail/${response.newEntityDuplicated.identifier}`;
      }, 3000);
    });
  };

  const actionModalView = () => {
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const getButtons = (values, isSubmitting = false) => {
    let result = [];

    result.push(
      <LogHistoryDrawer entity={logHistory} meta={logHistoryMeta} setMeta={setLogHistoryMeta} action={action} setAction={setAction} />
    );

    result.push(<ModalListDocuments quote={entity} />);

    if (entity?.status !== 'CANCELLED') {
      result.push(
        <Button
          leftIcon={<MdOutlineFileCopy color="#FFFFFF" size={20} />}
          p="1.5rem"
          pb="1.5rem"
          pl="1.3rem"
          pr="1.3rem"
          borderRadius="50px"
          bgColor="green"
          _hover={{ bgColor: '#70D499' }}
          isLoading={isSubmitting}
          loadingText="Duplicando..."
          onClick={() => {
            handleDuplicateQuote(entity);
          }}>
          <Text mr="10px" color="white">
            Duplicar cotação
          </Text>
        </Button>
      );
    }

    if (entity?.status === 'CANCELLED') {
      result.push(
        <Button
          leftIcon={<MdOutlineLowPriority color="#FFFFFF" size={20} />}
          p="1.5rem"
          pb="1.5rem"
          pl="1.3rem"
          pr="1.3rem"
          borderRadius="50px"
          bgColor="green"
          _hover={{ bgColor: '#70D499' }}
          isLoading={isSubmitting}
          loadingText="Reativando..."
          onClick={() => {
            handleReactive(entity);
          }}>
          <Text mr="10px" color="white">
            Reativar cotação
          </Text>
        </Button>
      );
    }

    if (entity?.logisticInformation?.length > 0) {
      result.push(
        <Button
          leftIcon={<MdDownloadForOffline color="#FFFFFF" size={20} />}
          p="1.5rem"
          pb="1.5rem"
          pl="1.3rem"
          pr="1.3rem"
          borderRadius="50px"
          bgColor="green"
          _hover={{ bgColor: '#70D499' }}
          isLoading={isSubmitting}
          loadingText="Duplicando..."
          onClick={() => {
            actionModalView();
          }}>
          <Text mr="10px" color="white">
            Baixar cotação
          </Text>
        </Button>
      );
    }

    // result.push(<ModalViewQuote entity={entity} modality={modality} values={values} />);
    return result;
  };

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  useEffect(() => {
    loadCurrency();

    if (identifier !== undefined) {
      getQuote(identifier);
    }
  }, []);

  useEffect(() => {
    loadUsers(clientsOptions);
  }, [listUser]);

  useEffect(() => {
    if (identifier !== undefined) {
      loadLogHistory(identifier);
    }
  }, [action]);

  return (
    <>
      <Layout>
        <Menubar
          linkTree={[
            { link: '#', title: 'Vendemmia' },
            { link: '/vendemmia/quote', title: 'Cotação' },
          ]}
          title={
            entity.identifier
              ? localStorage.getItem('entity-old')
                ? localStorage.getItem('entity-old')
                : 'Detalhes da cotação'
              : 'Nova cotação'
          }
          hideAllFilters={true}
        />
        <DocumentModalView isOpen={isOpenModalViewFile} entity={entity} onClose={actionModalView} />
        {isLoadingEntity ? (
          <ScreenLoader isLoading={isLoadingEntity} />
        ) : (
          <Box>
            <Formik
              initialValues={initialValues(entity, currency)}
              validationSchema={validationSchema(requiredList, entity, profile)}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                let aux = {
                  availableDate: values?.availableDate,
                  responsibleLogisticUser: values?.responsibleLogisticUser?.value,
                  reference: values?.reference,
                  currency: values?.currency?.value,
                  valueOfTheMerchandise: values?.valueOfTheMerchandise,
                  client: values?.client?.value,
                  process: values?.process?.value,
                  modality: values?.modality?.value,
                  originPort: values?.originPort?.value,
                  destinationPort: values?.destinationPort?.value,
                  incoterm: values?.incoterm?.value,
                  inlandZipCode: values?.inlandZipCode,
                  quoteType: values?.quoteType?.value,
                  dangerousClass: values?.dangerousClass?.value,
                  weigth: values?.weigth,
                  description: values?.description,
                  invoiceValue: values?.invoiceValue,
                  isPacklistReceived: values?.isPacklistReceived?.value === true ? 1 : 0,
                  copyPeople3Email: values?.copyPeople3Email,
                  copyPeople2: values?.copyPeople2?.value,
                  additionalCubageValues: [],
                  ctrs: [],
                  logisticInformation: [],
                  ncms: [],
                };

                if (values?.logisticInformation?.length > 0) {
                  values.logisticInformation.map((entity) => {
                    aux.logisticInformation.push({
                      supplier: entity?.supplier,
                      currency: entity?.currency?.value,
                      totalValue: entity?.totalValue,
                      transitTime: entity?.transitTime,
                      freeTime: entity?.freeTime,
                      expiresAt: entity?.expiresAt ? moment(entity.expiresAt).format('YYYY-MM-DD') : null,
                    });
                  });
                }

                if (values?.additionalCubageValues?.length > 0) {
                  values.additionalCubageValues.map((entity) => {
                    aux.additionalCubageValues.push({
                      quantity: entity?.quantity,
                      height: entity?.height,
                      width: entity?.width,
                      length: entity?.length,
                      cbmCubicMeters: entity?.cbmCubicMeters,
                      cubicWeight: entity?.cubicWeight,
                    });
                  });
                }

                if (values?.ctrs?.length > 0 && values?.modality?.label?.includes('Marítimo')) {
                  values.ctrs.map((ctr) => {
                    aux.ctrs.push({
                      qtt: ctr?.qtt,
                      size: ctr?.size?.value,
                      type: ctr?.type?.value,
                    });
                  });
                }

                if (values?.ncms?.length > 0) {
                  values.ncms.map((ncm) => {
                    aux.ncms.push(ncm.code);
                    // aux.ncms.push({ code: ncm.code, exTariffs: ncm.exTariffs });
                  });
                }

                if (entity?.identifier) {
                  requests
                    .postQuoteEdit(entity.identifier, aux)
                    .then((response) => {
                      toast.success(`Cotação de referência ${response?.reference} editada com sucesso`);
                      getQuote();
                      setSubmitting(false);
                    })
                    .finally((error) => {
                      setSubmitting(false);
                    });
                } else {
                  requests
                    .postQuoteNew(aux)
                    .then((response) => {
                      toast.success(`Cotação de referência ${response?.reference} cadastrada com sucesso`);

                      setTimeout(() => {
                        window.location.href = '/vendemmia/quote';
                      }, 2000);

                      setSubmitting(false);
                    })
                    .finally((error) => {
                      setSubmitting(false);
                    });
                }
              }}>
              {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Card
                    m="10px"
                    maxH="calc(100vh - 210px)"
                    overflowY="auto"
                    header={
                      <Flex justify="space-between" align="center" w="full">
                        <Flex gap="2px" direction="column">
                          <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                            Cotação
                          </Text>

                          {entity.identifier && (
                            <>
                              <Flex gap="2px">
                                <Text textStyle="subtitle" color="#BEBEBE" pt="3px">
                                  Código da cotação:
                                </Text>

                                <Text textStyle="cardTitle" fontWeight="bold" color="primary" ml="5px">
                                  {entity?.name}
                                </Text>
                              </Flex>
                            </>
                          )}
                        </Flex>

                        {entity.identifier && (
                          <Flex cursor="pointer" direction="column">
                            <LabelDefault name="status" text="Status" />

                            <Select
                              styles={selectCustomStyles()}
                              isDisabled={
                                entity?.status === 'APPROVED' ||
                                entity?.status === 'REJECTED' ||
                                entity?.status === 'CANCELLED' ||
                                entity?.status === 'FINALIZED'
                              }
                              className="input-register"
                              placeholder="Selecione..."
                              name="status"
                              value={
                                entity?.status === 'ON_REVIEW_LOGISTICS'
                                  ? optionStatusLogistics.find((value) => value.value === entity?.status)
                                  : optionStatus.find((value) => value.value === entity?.status)
                              }
                              options={entity?.status === 'ON_REVIEW_LOGISTICS' ? optionStatusLogistics : optionStatus}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(option) => {
                                setFieldValue('status', option);
                                onOpen();
                              }}
                            />

                            <ModalStatusChange
                              isOpen={isOpen}
                              onOpen={onOpen}
                              onClose={onClose}
                              values={values}
                              entity={entity}
                              callback={handleClickSelect}
                            />
                          </Flex>
                        )}
                      </Flex>
                    }>
                    <Flex direction="column" p="20px" gap="20px">
                      <FormGeneralInfo
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        currency={currency}
                        clientsOptions={clientsOptions}
                        setClientsOptions={setClientsOptions}
                        selectCustomStyles={selectCustomStyles}
                        listUser={listUser}
                        setListUser={setListUser}
                        requiredList={requiredList}
                        setRequiredList={setRequiredList}
                        setModality={setModality}
                      />

                      <Divider />

                      <FormMerchandiseCargo
                        values={values}
                        setFieldValue={setFieldValue}
                        setModality={setModality}
                        modality={modality}
                        selectCustomStyles={selectCustomStyles}
                        inputNcmsRelations={inputNcmsRelations}
                        setInputNcmsRelations={setInputNcmsRelations}
                        loadUserOptions={loadUserOptions}
                        userOptions={userOptions}
                      />

                      <Divider />

                      {values.modality.label?.includes('Marítimo') && (
                        <>
                          <FormContainers values={values} setFieldValue={setFieldValue} selectCustomStyles={selectCustomStyles} />

                          <Divider />
                        </>
                      )}

                      <FormQuote
                        values={values}
                        setFieldValue={setFieldValue}
                        selectCustomStyles={selectCustomStyles}
                        isLoadCurrency={isLoadCurrency}
                        handleChange={handleChange}
                        currency={currency}
                      />
                    </Flex>
                  </Card>

                  <FloatActionButton
                    options={getButtons(values)}
                    isOpen={isOpenFloatButton}
                    setIsOpen={setIsOpenFloatButton}
                    saveButton={
                      <Button
                        leftIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                        p="1.5rem"
                        pb="1.5rem"
                        pl="1.3rem"
                        pr="1.3rem"
                        borderRadius="50px"
                        bgColor="primary"
                        _hover={{ bgColor: '#8067DC' }}
                        isLoading={isSubmitting}
                        type="submit"
                        loadingText="Salvando">
                        <Text mr="10px" color="white">
                          Salvar
                        </Text>
                      </Button>
                    }
                  />
                </form>
              )}
            </Formik>
          </Box>
        )}
      </Layout>
    </>
  );
};

export default QuoteDetailPage;
