import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const HelpInfoVendemmia = () => {
  const options = [
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - 13.631.538/0001-46',
      tell: '',
      adress:
        'Avenida Nossa Senhora da Penha, n. 1.495, sala 1108, Torre BT, Edifício Corporate Center, Santa Lúcia, Vitória, Estado do Espírito Santo, CEP. 29056-905',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA (Filial 1 - São Paulo) CNPJ 13.631.538/0002-27',
      tell: '',
      adress:
        'Rua Funchal, n. 129, 13o andar, salas E13A e E13B, do Edifício Montreal, bairro Vila Olímpia, São Paulo, SP, CEP n. 04.551-060',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 2 - Itajaí - 13.631.538/0003-08',
      tell: '',
      adress: 'Av. Marcos Konder nº 805, sala 807, Centro, Itajaí, SC, CEP 88.301-302',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 3 - Recife - 13.631.538/0004-99',
      tell: '',
      adress: 'Rua Ribeiro de Brito, n. 901, sala 404, bairro Boa Viagem, em Recife, PE, CEP 51.021-310',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 4 - Navegantes - 13.631.538/0005-70',
      tell: '',
      adress: 'Sala 5, do Modulo 1, do Galpão 1 BR-470, n. 6800, na cidade de Navegantes, SC, CEP 88371-890',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 5 - Pouso Alegre - 13.631.538/0006-50',
      tell: '',
      adress:
        'Rodovia Fernão Dias - BR 381, S/N - Km 848, Box Industrial 03, container 03, Bairro Ipiranga, Setor Industrial - CEP 37.556-338 na cidade de Pouso Alegre, Estado de Minas Gerais',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 6 - Volta Grande - 3.631.538/0007-31',
      tell: '',
      adress:
        'Rodovia BR 470 - Ingo Hering, n. 6800, Bairro Volta Grande, na cidade de Navegantes, Estado de Santa Catarina, CEP 88.371-890',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 7 - Itapevi - 13.631.538/0009-01',
      tell: '',
      adress:
        'Avenida Portugal n. 325, Galpão comercial número 03, Bairro Itaqui, na cidade de Itapevi, estado de São Paulo, CEP 06.696-901',
    },
    {
      name: 'VENDEMMIA COMÉRCIO INTERNACIONAL LTDA - Filial 8 - Navegantes - 13.631.538/0008-12',
      tell: '',
      adress:
        'Rodovia BR 470 (Rodovia Ingo Hering), n. 4669, Armazém 02, no Bairro Volta Grande, cidade de Navegantes, Estado de Santa Catarina, CEP: 88.371-890',
    },
  ];

  return (
    <Flex direction="column" p="30px" gap="10px">
      {options.map((entity, key) => (
        <Flex direction="column" p="20px" border="1px" borderColor="linkColor" key={key}>
          <Text textColor="linkColor" fontWeight="medium" mb="10px">
            {entity.name}
          </Text>

          <Flex gap="5px" opacity=".8">
            <Text>Telefone:</Text>
            <Text>{entity.tell}</Text>
          </Flex>

          <Flex gap="5px" opacity=".8">
            <Text>Endereço:</Text>
            <Text>{entity.adress}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default HelpInfoVendemmia;
