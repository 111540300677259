import React from 'react';

import { Link as LinkReact } from 'react-router-dom';

import { Flex, Link, List, ListItem, Text, Tooltip, useBoolean } from '@chakra-ui/react';

import moneyFormat from '../../../../utils/currency/moneyFormat';

const CategoryColumn = ({ fluxCashTableDataValue, fetchDetailsByStatus, detailsByStatus, id, dates }) => {
  const [toggleButton, setToggleButton] = useBoolean(false);

  const { data } = fluxCashTableDataValue;

  const handlerOnHoverEffect = (event, toggle) => {
    const data = currentHoveredElements(event, toggle);
    currentHoveredNodes(`[role="list"] [data-process="${data.process}"]`, toggle, data.scope);
  };

  const handlerOutHoverEffect = (event, toggle) => {
    const data = currentHoveredElements(event, toggle);
    currentHoveredNodes(`[role="list"] [data-process][data-hover="true"]`, toggle, data.scope);
  };

  const currentHoveredElements = (event, toggle) => {
    const changedElement = event.target;
    const process = changedElement.textContent;
    const processList = changedElement.closest('li');
    const scope = changedElement.closest('[role="list"]');

    const data = { process, scope };

    const fetchProcessHover = processList.querySelectorAll('p');
    fetchProcessHover.forEach((item) => {
      item.textContent === process && setHoverEffect(item, toggle);
    });

    return data;
  };

  const currentHoveredNodes = (searchElement, toggle, scope) => {
    const fetchNodeHover = scope.querySelectorAll(searchElement);
    fetchNodeHover.forEach((item) => {
      setHoverEffect(item, toggle);
    });
  };

  const setHoverEffect = (event, toggle) => {
    const el = event?.target || event;
    el.dataset.hover = toggle === 'on';
  };

  const setNewSlug = (slug) => {
    fetchDetailsByStatus(slug);
  };

  const handlerOpenDetails = (event) => {
    setToggleButton.toggle();
    const slug = event.target.dataset.slug;

    if (!toggleButton) {
      setNewSlug(slug);
    }
  };

  return (
    data && (
      <Flex
        flexDirection={'column'}
        bg={toggleButton ? '#f6f6f6' : 'transparent'}
        boxShadow={toggleButton ? '1px 3px 5px #00000029' : '1px 3px 5px transparent'}
        border={toggleButton ? '1px solid #70707036' : '1px solid transparent'}
        borderRadius={'12px'}>
        <List
          display={'grid'}
          gridTemplateColumns={`repeat(8, 1fr)`}
          borderBottom={'1px solid #70707036'}
          alignItems={'center'}
          minH={'75px'}>
          <ListItem minW={'330px'} color={'primary'} p={'5px 0 5px 40px'} fontSize={'20px'} lineHeight={'26px'} fontWeight={'500'}>
            <Link onClick={(event) => handlerOpenDetails(event)} data-toggle-button={toggleButton} data-slug={data[id].slug}>
              {data[id].title}
            </Link>
          </ListItem>

          {data[id].list &&
            data[id].list.map((item, key) => (
              <ListItem
                key={key}
                display={'flex'}
                justifyContent={'space-between'}
                p={'5px 22px'}
                w={'full'}
                data-leftlineswitch={toggleButton}>
                {item && (
                  <>
                    <Text as={'span'} lineHeight={'18px'} fontSize={'14px'} color={'linkColor'} fontWeight={'500'}>
                      R$
                    </Text>

                    <Text as={'p'} lineHeight={'22px'} fontSize={'17px'} color={'titleColor'} fontWeight={'500'}>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                        .format(item.value)
                        .replace('R$', '')
                        .trim()}
                    </Text>
                  </>
                )}
              </ListItem>
            ))}
        </List>

        {toggleButton && (
          <List display={'grid'} gridTemplateColumns={`repeat(1, 1fr)`}>
            {detailsByStatus?.data < 1 ? (
              <ListItem minW={'330px'} textAlign={'center'} p={'20px'}>
                <Text fontSize={'16px'} fontWeight={'500'}>
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Text>
              </ListItem>
            ) : (
              detailsByStatus?.data &&
              detailsByStatus?.data.map((listItem, key) => (
                <ListItem
                  minW={'330px'}
                  alignItems={'center'}
                  textAlign={'center'}
                  key={key}
                  display={'grid'}
                  gridTemplateColumns={`repeat(8, 1fr)`}>
                  <Tooltip label="Visualizar processo">
                    <LinkReact to={`/comex/process/detail/${listItem.identifier}`} target="_blank">
                      <Text
                        key={key}
                        minW={'310px'}
                        bg={'#EBEBEB'}
                        boxShadow={'0px 1px 3px #00000029'}
                        border={'1px solid #854FFF'}
                        borderRadius={'5px'}
                        fontSize={'15px'}
                        lineHeight={'25px'}
                        textAlign={'center'}
                        m={'10px'}
                        color={'linkColor'}
                        onMouseEnter={(event) => handlerOnHoverEffect(event, 'on')}
                        onMouseLeave={(event) => handlerOutHoverEffect(event, 'out')}>
                        {listItem.process}
                      </Text>
                    </LinkReact>
                  </Tooltip>

                  {dates.map((date, columnKey) => {
                    const matchingDate = listItem.dates.find((d) => d.date === date);
                    const value = matchingDate ? moneyFormat(matchingDate.value).replace('R$', '').trim() : '0,00';

                    return (
                      <ListItem key={columnKey}>
                        <Text
                          data-process={matchingDate?.process}
                          display={'flex'}
                          boxShadow={'0px 1px 3px #00000029'}
                          bg={'#EBEBEB'}
                          borderRadius={'5px'}
                          minH={'27px'}
                          textAlign={'center'}
                          m={'6px 17px'}
                          color={'linkColor'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          p={'3.5px 11px'}
                          as={'span'}
                          lineHeight={'20px'}
                          fontSize={'15px'}
                          minW={'70px'}
                          fontWeight={'500'}>
                          <Text>R$</Text>
                          <Text>{value}</Text>
                        </Text>
                      </ListItem>
                    );
                  })}
                </ListItem>
              ))
            )}
          </List>
        )}
      </Flex>
    )
  );
};

export default CategoryColumn;
