import { useEffect, useRef, useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import {
  Link as ChakraLink,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
  Tooltip,
  useBoolean,
  Link,
  Button,
} from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import { reportEmpty, reportFolder, reportGraph, reportNote, reportOpenFolder } from '../../../../utils/exports';
import hasSpecificURLParameters from '../../../../utils/url/hasSpecificURLParameter';

const ReportSidebar = (props) => {
  const {
    loadSingleReportOfMenu,
    sidebarMenu,
    setIsNewReport,
    setSwitchPageButton,
    currentFolder,
    updateCurrentFolder,
    unsavedChangesExist,
    unsavedChanges,
  } = props;

  const [toggleButton, setToggleButton] = useBoolean(true);

  const toogleIndentLine = toggleButton ? '35px' : '0';
  const toogleMenu = toggleButton ? '365px' : 'auto';
  const toogleWidthBar = toggleButton ? '0 18px' : '0 9px';
  const toogleAlignButtons = toggleButton ? 'flex-start' : 'center';
  const reportGapItens = toggleButton ? '15px' : '5px';
  const reportGapItensMenu = toggleButton ? '5px' : '5px';
  const maxHeight = window.innerHeight - window.innerHeight * 0.12;

  const menuRef = useRef(null);
  const ctaButtonRef = useRef(null);

  const identifier = hasSpecificURLParameters(['id']).value ?? null;
  /* const folder = localStorage.getItem(['report-folder']); */

  const [menu, setmenu] = useState();
  const [refMenu, setRefMenu] = useState();

  const resetMenu = () => {
    const links = refMenu.current.querySelectorAll('a');
    links.forEach((link) => {
      delete link.dataset.active;
    });
  };

  const activeMenu = (event) => {
    const currentLink = event.target.closest('.chakra-link');

    resetMenu();
    currentLink.dataset.active = true;
  };

  const activeFolder = () => {
    const currentItem = document.querySelector('[data-active="true"]');

    if (currentItem) {
      const currentItemFather = currentItem.closest('.chakra-accordion__item');
      const currentItemButton = currentItemFather.querySelector('button');

      currentItemButton.click();
    }
  };

  const handlerMenuReport = (identifier, event, key) => {
    /*  openCurrentFolder(key); */

    if (unsavedChangesExist) {
      ctaButtonRef.current.click();

      if (unsavedChangesExist) {
        return;
      }
    }

    setSwitchPageButton(true);
    setIsNewReport(false);
    activeMenu(event);
    loadSingleReportOfMenu(identifier);
  };

  const openCurrentFolder = (key) => {
    updateCurrentFolder(key);
  };

  useEffect(() => {
    setRefMenu(menuRef);
  }, [menuRef]);

  useEffect(() => {
    setmenu(sidebarMenu);
  }, [sidebarMenu]);

  useEffect(() => {
    setTimeout(() => {
      activeFolder();
    }, 1500);
  }, []);

  return (
    <Card as="aside" bg={'white'} h={'100%'} minW={{ base:'100%', md:toogleMenu }} transition={'width 0.5s ease 0s'}>
      <Flex
        as="header"
        borderBottom={'1px solid #0F0A1D26'}
        minH={'68px'}
        p={toogleWidthBar}
        justifyContent={'space-between'}
        transition={'padding 0.5s ease 0s'}
        mb={'25px'}>
        <Flex gap={'15px'} align={'center'} ml={'14px'} fontSize={'20px'} color={'primary'} fontWeight={'bold'}>
          <Image src={reportGraph} />
          {toggleButton && <Box>Relatórios gerados</Box>}
        </Flex>

        <Flex align={'center'} cursor={'pointer'} display={{ base:'none', md:'flex' }}>
          {toggleButton ? (
            <MdKeyboardArrowLeft size={30} onClick={setToggleButton.toggle} color={'#6C48C2'} />
          ) : (
            <MdKeyboardArrowRight size={30} onClick={setToggleButton.toggle} color={'#6C48C2'} />
          )}
        </Flex>
      </Flex>

      <Accordion
        as="nav"
        ref={menuRef}
        overflowY={'auto'}
        maxH={maxHeight + 'px'}
        display={'flex'}
        flexDirection={'column'}
        gap={reportGapItensMenu}
        pb={'25px'}
        /* defaultIndex={[currentFolder && currentFolder]} */
        allowMultiple>
        {sidebarMenu.length > 0 ? (
          sidebarMenu.map((menu, key) => {
            return (
              <AccordionItem key={key} border={0}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      minH={'40px'}
                      p={'0 16px'}
                      justifyContent={toogleAlignButtons}
                      _hover={{
                        background: 'transparent',
                      }}>
                      {toggleButton ? (
                        isExpanded ? (
                          <>
                            {toggleButton && <MdKeyboardArrowDown color={'#6C48C2'} size={30} />}
                            <Image src={reportOpenFolder} />
                          </>
                        ) : (
                          <>
                            {toggleButton && <MdKeyboardArrowRight color={'#6C48C2'} size={30} />}
                            <Image src={reportFolder} />
                          </>
                        )
                      ) : isExpanded ? (
                        <>
                          {toggleButton && <MdKeyboardArrowDown color={'#6C48C2'} size={30} />}
                          <Tooltip hasArrow label={menu.name}>
                            <IconButton bgColor="#FFFFFF" icon={<Image src={reportOpenFolder} size={20} color="#AFAEB4" />} />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          {toggleButton && <MdKeyboardArrowRight color={'#6C48C2'} size={30} />}
                          <Tooltip hasArrow label={menu.name}>
                            <IconButton bgColor="#FFFFFF" icon={<Image src={reportFolder} size={20} color="#AFAEB4" />} />
                          </Tooltip>
                        </>
                      )}

                      {toggleButton && (
                        <Heading as="h2" size="18px" noOfLines={1} fontWeight={600} textIndent={'15px'}>
                          {menu && menu.name}
                        </Heading>
                      )}
                    </AccordionButton>

                    <AccordionPanel
                      display={'flex'}
                      flexDirection={'column'}
                      ml={toogleIndentLine}
                      transition={'all 0.5s ease 0s'}
                      gap={reportGapItens}
                      className="c-report-sidebar">
                      {menu.list ? (
                        menu.list.map((submenu, _key) => (
                          <ChakraLink
                            data-active={identifier === submenu.identifier && true}
                            onClick={(event) => handlerMenuReport(submenu.identifier, event, key)}
                            key={submenu.identifier} 
                            maxW={'280px'}
                            _hover={{
                              color: '#a885f9',
                              transition: 'all .3s ease-in-out',
                            }}>
                            <Flex gap={'5px'} alignItems={'center'} justifyContent={toogleAlignButtons}>
                              <Text fontSize={'15px'} fontWeight={'600'} display={'flex'}>
                                {toggleButton ? (
                                  <Image src={reportNote} size={20} color="#AFAEB4" />
                                ) : (
                                  <Tooltip hasArrow label={submenu.name}>
                                    <IconButton bgColor="#FFFFFF" icon={<Image src={reportNote} size={20} color="#AFAEB4" />} />
                                  </Tooltip>
                                )}
                                {toggleButton && <Box marginLeft={'21px'}>{submenu.name}</Box>}
                              </Text>
                            </Flex>
                          </ChakraLink>
                        ))
                      ) : (
                        <Flex justifyContent={toogleAlignButtons}>
                          {toggleButton ? (
                            <Flex display={'flex'} marginLeft={'10px'} color={'#AFAEB4'}>
                              <Image src={reportEmpty} size={18} color="#AFAEB4" />A pasta esta vazia.
                            </Flex>
                          ) : (
                            <Image src={reportEmpty} size={18} color="#AFAEB4" />
                          )}
                        </Flex>
                      )}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })
        ) : (
          <Text maxW={'365px'} p={toogleWidthBar} color="#7d7d7d" fontSize={'14px'} textAlign={'center'}>
            <Image src={reportEmpty} size={18} color="#7d7d7d" margin={'0 auto 15px'} />
            {toggleButton && (
              <>
                Sem relatórios para visualizar. <br />
                Entre em contato com um administrador, para solicitar acesso aos relatórios necessários.
              </>
            )}
          </Text>
        )}
      </Accordion>
      <DefaultModal
        ctaButton={
          <Button display={'none'} ref={ctaButtonRef} w={'full'} variant="secundary"></Button>
        }
        title={'Certeza que deseja sair sem salvar as alterações?'}
        subtitle={<>Quaisquer alterações feitas no documento atual serão perdidas.</>}
        buttonColor="red"
        txtButton="Confirmar"
        callback={async (decision) => {
          if (decision) {
            unsavedChanges(false);
          }
        }}
      />
    </Card>
  );
};

export default ReportSidebar;
