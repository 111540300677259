import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';
import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  // Estrutura da tabela
  categories: [
    {
      title: 'Importação',
      /* field: 'e.originModality', */
      value: 'originImport',
      align: 'center',
    },
    {
      title: 'Status',
      field: 'e.status',
      value: 'statusNf',
      align: 'left',
      variantTag: 'softDashed',
    },
    {
      title: 'Conferente',
      field: 'e.usuarioConferencia',
      value: 'usuarioConferencia',
      align: 'center',
    },
    {
      title: 'Armazém',
      field: 'e.ufArmazem',
      value: 'stateCodeFormatted',
      align: 'center',
    },
    {
      title: 'Nota Fiscal',
      field: 'e.nuNota',
      value: 'nuNota',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Série',
      field: 'e.serieNf',
      value: 'serieNf',
      align: 'center',
    },
    {
      title: 'Pedido',
      field: 'e.idPedido',
      value: 'idPedido',
      type: 'label',
      defaultValue: 'não encontrado',
      align: 'center',
    },
    {
      title: 'Destinatário',
      field: 'e.nomeDestinatario',
      value: 'nomeDestinatario',
    },
    {
      title: 'UF Destinatário',
      field: 'e.ufDestinatario',
      value: 'ufDestinatario',
      align: 'center',
    },
    {
      title: 'Cidade destinatário',
      field: 'e.recipientCity',
      value: 'recipientCity',
      align: 'center',
    },
    {
      title: 'Depositante',
      field: 'e.nomeDepositante',
      value: 'nomeDepositante',
    },
    {
      title: 'Transportadora',
      field: 'e.nomeTransportadora',
      value: 'nomeTransportadora',
    },
    {
      title: 'Faturado em',
      field: 'e.dtFaturamento',
      value: 'dtFaturamentoFormatted',
      align: 'center',
    },
    {
      title: 'Coletado em',
      field: 'e.dtFimColeta',
      value: 'dtFimColetaFormatted',
      align: 'center',
    },
    {
      title: 'Ag. de coleta',
      field: 'e.embLiberado',
      value: 'embLiberado',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Carga',
      field: 'e.carga',
      value: 'carga',
      type: 'label',
      defaultValue: 'não encontrado',
      align: 'center',
    },
    {
      title: 'Volumes',
      field: 'e.qtVolumes',
      value: 'qtVolumesFormatted',
      align: 'center',
    },
    {
      title: 'Valor da NF',
      field: 'e.valorNf',
      value: 'valorNfFormatted',
      align: 'center',
    },
    {
      title: 'Cub.',
      field: 'e.cubagemM3',
      value: 'cubagemM3Formatted',
      align: 'center',
    },
    {
      title: 'Peso Bruto',
      field: 'e.pesoBrutoKg',
      value: 'weightFormatted',
      align: 'center',
    },
    {
      title: 'Observação',
      value: 'observacao',
      align: 'center',
    },
  ],

  subCategories: [
    {
      title: 'Código',
      value: 'codProduto',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'descricaoProduto',
    },
    {
      title: 'Quantidade',
      value: 'qtdeExpedidaFormatted',
      align: 'center',
    },
    {
      title: 'Valor',
      value: 'totalValueFormatted',
      align: 'center',
    },
    {
      title: 'Lote Indústria',
      value: 'loteIndustrial',
      align: 'center',
    },
    {
      title: 'Curva ABC',
      value: 'inAbc',
      align: 'center',
    },
    {
      title: 'Número de série',
      value: 'infoEspecifica',
      align: 'center',
    },
  ],
  pathSublist: 'itens',
  mainCategoryMobile: 'idPedido',
  collapsedContentType: 'table',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: true,
  tags: ['statusNf', 'inAbc', 'embLiberado'],
  statusPreset: [
    { color: '#422C76', label: 'Faturado', value: 'FATURADO' },
    { color: '#2EC5CC', label: 'Coleta iniciada', value: 'COLETA INICIADA' },
    { color: '#2EA0CC', label: 'Processado', value: 'PROCESSADO' },
    { color: '#2ECC71', label: 'Expedido', value: 'EXPEDIDO' },
    { color: '#E74C3C', label: 'Cancelado', value: 'CANCELADO' },
    { color: '#2ECC71', label: 'Sim', value: 'SIM' },
    { color: '#E74C3C', label: 'Não', value: 'NAO' },
    { color: '#2ECC71', label: 'A', value: 'A' },
    { color: '#F1C40F', label: 'B', value: 'B' },
    { color: '#E74C3C', label: 'C', value: 'C' },
  ],
  images: ['originImport'],
  imagePreset: [
    { value: 'API', label: 'Importação via API Sênior', image: api },
    { value: 'FTP', label: 'Importação via FTP Sênior', image: ftp },
    { value: 'OUTROS', label: 'Importação via FTP Sênior', image: ftp },
  ],
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 20,
  maxLengthResumeChildren: 35,
  // Estilo
  resizeRows:
    '100px 150px 150px 120px 120px 120px 150px 280px 120px 150px 280px 280px 150px 150px 120px 135px 120px 120px 120px 120px 280px',
  resizeRowsChildren: '180px 500px 120px 120px 150px 120px 450px',
};
