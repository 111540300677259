import React, { useEffect, useRef, useState } from 'react';

import { MdHelp, MdRemoveRedEye } from 'react-icons/md';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CurveStockChart from '../../../components/Charts/Warehouse/CurveStockChart';
import OrderProgressByStateChart from '../../../components/Charts/Warehouse/OrderProgressByStateChart';
import ShipmentAnalysisChart from '../../../components/Charts/Warehouse/ShipmentAnalysisChart';
import WerehouseSlaChart from '../../../components/Charts/Warehouse/WerehouseSlaChart';
import CommonPieChart from '../../../components/CommonChats/CommonPieChart';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { useDebounce } from '../../../hooks/useDebounce';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { importFileAction } from '../../../utils/actions/importFileAction';
import { filterOptionsWarehouseExpedition } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';

const ExpeditionPage = () => {
  const hasPermission = permissions.warehouseExpedition;
  const isSystemAdmin = permissions.isSystemAdmin;

  const [clientOptions, setClientOptions] = useState([]);
  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [isLoadingChart, setIsLoadingChart] = useState(true);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [list, setList] = useState([]);
  const [sublist, setSublist] = useState();

  const [dataExpeditionChart, setDataExpeditionChart] = useState({});

  const [useOwnState, setUseOwnState] = useState(false);
  const [ownState, setOwnState] = useState(false);

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const [filters, setFilters] = useState();
  const [previousSearch, setPreviousSearch] = useState();

  //filter expedition chart
  const [orderStatus, setOrderStatus] = useState();
  const [abcCurve, setAbcCurve] = useState();

  //filter charts
  const [expeditionStatus, setExpeditionStatus] = useState('');
  const [dataRemittanceChart, setDataRemittanceChart] = useState({ average: '0' });

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Função auxiliar para criar os filtros
  const buildFiltersForExpedition = (customFilters) => {
    return {
      ...customFilters,
      statusGrafico: orderStatus,
      additionalStatusGrafico: abcCurve,
      type_storage: 'expedition',
    };
  };

  const load = (filters, key, page) => {
    const filtersForPicking = buildFiltersForExpedition(filters);

    // Atualiza os filtros no estado
    setFilters(filtersForPicking);

    // Chama a função de listagem com os filtros atualizados
    getListPickingPack(filtersForPicking, key, page);
  };

  const getListPickingPack = async (filters, key, page) => {
    const res = await executeRequest({
      action: () => requests.listPickingPack(filters, page),
      setIsLoading,
    });

    if (request.current && request.current > key) {
      return;
    }
    request.current = key;

    setList(res.data);
    setSublist(res.data);
    setMetadata(res.meta);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const sanitizeFilters = (filters) => {
    const { sort, page, ...rest } = filters;
    return rest;
  };

  const fetchChartsData = async (filtersForExpedition, orderStatus, abcCurve) => {
    return Promise.all([
      executeRequest({
        action: () =>
          requests.remittanceAnalysisExpedition({
            ...filtersForExpedition,
            statusGrafico: orderStatus,
            additionalStatusGrafico: abcCurve,
          }),
      }),
      executeRequest({
        action: () =>
          requests.dashboardPickingPack(
            { ...filtersForExpedition, statusGrafico: orderStatus, additionalStatusGrafico: abcCurve },
            'expedition'
          ),
      }),
    ]);
  };

  const isLatestRequest = (requestChart, requestKey, setRequestChart) => {
    if (requestChart.current && requestChart.current > requestKey) {
      return;
    }
    requestChart.current = requestKey;
    setRequestChart(requestChart.data);
  };

  const handleChartLoadingError = (error) => {
    console.error('Error loading charts:', error);
  };

  // Adicionando o debounce
  const debouncedFetchChartsData = useDebounce(async (filtersForExpedition, orderStatus, abcCurve, key) => {
    try {
      const [requestRemittance, requestExpeditionStatus] = await fetchChartsData(filtersForExpedition, orderStatus, abcCurve);

      isLatestRequest(requestRemittance, key, setDataRemittanceChart);
      isLatestRequest(requestExpeditionStatus, key, setExpeditionStatus);
    } catch (error) {
      handleChartLoadingError(error);
    } finally {
      setIsLoadingChart(false);
    }
  }, 800);

  const loadCharts = async (filters, key) => {
    setIsLoadingChart(true);

    const sanitizedFilters = sanitizeFilters(filters);
    const filtersForExpedition = { ...sanitizedFilters, type_storage: 'expedition' };

    // Usando a função debounced
    debouncedFetchChartsData(filtersForExpedition, orderStatus, abcCurve, key);
  };

  // Função auxiliar para configurar e gerar o arquivo de exportação
  const generateExport = (type_storage, fileName, endpoint, fileType) => {
    filters.type_storage = type_storage;

    // Configurações comuns
    const extension = fileType === 'zip' ? 'zip' : 'xlsx';
    const method = 'GET';
    const body = {};
    const params = filters;

    // Geração do arquivo de exportação
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  // Função para exportar a lista de Expedição
  const exportListFABActionExpedition = () => {
    const endpoint = `/pickingepacking/export`;
    return generateExport('expedition', 'Expedicao', endpoint, 'xlsx');
  };

  // Função para exportar a lista de Expedição Serial
  const exportListFABActionExpeditionSerial = () => {
    const endpoint = `/expedition/export/serial/xlsx`;
    return generateExport('expedition', 'Expedicao-serial', endpoint, 'xlsx');
  };

  // Função para exportar a lista de Picking, Packing e Expedição
  const exportListFABActionPickingPackingExpedicao = () => {
    const endpoint = `/pickingepacking/export`;
    return generateExport('both', 'PickingPacking-e-Expedicao', endpoint, 'xlsx');
  };

  // Função para exportar documentos de Romaneio
  const exportDocumentsFABActionRomaneio = () => {
    const type = 'cargo-detail';
    const endpoint = `/open/expedition/document/${type}/download`;
    return generateExport('expedition', 'Arquivos-romaneio', endpoint, 'zip');
  };

  // Função para exportar documentos de Canhoto
  const exportDocumentsFABActionCanhoto = () => {
    const type = 'receipt-comprovant';
    const endpoint = `/open/expedition/document/${type}/download`;
    return generateExport('expedition', 'Arquivos-canhoto', endpoint, 'zip');
  };

  // Função para exportar documentos de Romaneio e Canhoto
  const exportDocumentsFABActionRomaneioCanhoto = () => {
    const type = 'all';
    const endpoint = `/open/expedition/document/${type}/download`;
    return generateExport('expedition', 'Arquivos-romaneio-e-canhoto', endpoint, 'zip');
  };

  const uploadFABActionDocuments = (docs, config) => {
    return importFileAction({
      endpoint: `/expedition/document/simple/upload`,
      docs,
      config,
      setIsLoading: setOwnState,
      triggerRefresh,
    });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouseExpedition());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [orderStatus, abcCurve]);

  const chartsView = [
    isSystemAdmin
      ? {
          title: 'SLA de picking & packing expedido',
          content: (
            <CommonPieChart
              data={expeditionStatus}
              segments={[
                { id: 'Atendidos', key: 'onTime', filterPie: 'NoPrazo', color: '#2ECC71' },
                { id: 'Atrasados', key: 'outOfTime', filterPie: 'ForaDoPrazo', color: '#E74C3C' },
              ]}
              setFilterPie={setOrderStatus}
            />
          ),
        }
      : null, // Retorna null se for isSystemAdmin para que seja filtrado
    {
      title: 'Curva ABC',
      content: <CurveStockChart data={expeditionStatus} modality="EXPEDICAO" setAdditionalStatusGrafico={setAbcCurve} />,
      hasModal: false,
    },
    {
      title: 'Valor de nota em expedição por estado',
      content: <OrderProgressByStateChart data={dataRemittanceChart} filterOptions={filterOptions} type="state" />,
      hasModal: false,
    },
    {
      title: 'Análise de remessas',
      content: <ShipmentAnalysisChart data={dataRemittanceChart} />,
      hasModal: true,
      helpTooltip: (
        <Tooltip label="Análise de remessa = soma total dos itens / período de expedição">
          <Box ml="0.325rem">
            <MdHelp size={15} color="#422C76" />
          </Box>
        </Tooltip>
      ),
      modal: <WerehouseSlaChart data={dataRemittanceChart} type="expedition" onOpen={onOpen} isOpen={isOpen} onClose={onClose} />,
    },
  ].filter(Boolean); // Filtra qualquer valor falso ou nulo do array

  return (
    <Page
      screen="warehouse-expedition"
      title="Expedição"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      useOwnState={useOwnState}
      ownState={ownState}
      setOwnState={setOwnState}
      hasChartChanges={hasChartChanges}
      forceLoadTrigger={forceLoadTrigger}
      additionalFormFields={[
        {
          label: 'Modalidade',
          group: 'modality',
          name: 'mod',
          required: true,
          options: [
            { value: 'CANHOTO', label: 'Canhoto' },
            { value: 'ROMANEIO', label: 'Romaneio' },
          ],
        },
      ]}
      FAB={[
        {
          title: 'Exportar Expedição por número de série',
          text: 'Exportar arquivo (Excel) com dados de Expedição separados por número de série',
          action: exportListFABActionExpeditionSerial,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Expedição',
          text: 'Exportar arquivo (Excel) com dados de Expedição',
          action: exportListFABActionExpedition,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo (Excel) com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
        {
          title: 'Exportar documentos de Romaneio',
          text: 'Exportar documentos de Romaneio anexos à Expedição',
          action: exportDocumentsFABActionRomaneio,
          modality: 'export-files',
        },
        {
          title: 'Exportar documentos de Canhoto',
          text: 'Exportar documentos de Canhoto anexos à Expedição',
          action: exportDocumentsFABActionCanhoto,
          modality: 'export-files',
        },
        {
          title: 'Exportar documentos de Romaneio e Canhoto',
          text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
          action: exportDocumentsFABActionRomaneioCanhoto,
          modality: 'export-files',
        },
        {
          title: 'Upload de documentos',
          text: 'Upload de documentos para anexar à Expedição',
          action: uploadFABActionDocuments,
          modality: 'upload-files',
        },
      ]}>
      <Flex direction="column" gap="20px" m="10px">
        <Grid w="full" h={{ base: 'initial', md: '345px' }}>
          <Accordion
            display="grid"
            gap="10px"
            gridTemplateColumns={{ base: '1fr', md: isSystemAdmin ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)' }}>
            {chartsView.map((item, key) => {
              return !item.hasModal ? (
                !isMobile ? (
                  /* desktop */
                  <Card key={key} title={item.title}>
                    <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                  </Card>
                ) : (
                  /* mobile */
                  <AccordionItem key={key}>
                    <Card>
                      <AccordionButton display="flex" justifyContent="space-between" _hover="none">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                      </AccordionPanel>
                    </Card>
                  </AccordionItem>
                )
              ) : /* modal */
              !isMobile ? (
                /* desktop */
                <Card
                  key={key}
                  header={
                    <Flex justify="space-between" w="full" alignItems="center">
                      <Flex justify="center">
                        <Text textStyle="cardTitle" justifyContent="center">
                          {item.title}
                        </Text>
                        {item?.helpTooltip}
                      </Flex>

                      {!isLoadingChart && (
                        <Tooltip label={item.title}>
                          <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                        </Tooltip>
                      )}
                    </Flex>
                  }>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <Flex direction="row" justify="center" h="full">
                      {item.content}

                      {item?.modal}
                    </Flex>
                  </ScreenLoader>
                </Card>
              ) : (
                /* mobile */
                <AccordionItem key={key}>
                  <Card
                    borderBottom="0"
                    header={
                      <Flex justify="space-between" w="full" alignItems="center">
                        <Text textStyle="cardTitle">
                          <AccordionButton _hover="none" p="8px 5px">
                            <Text textStyle="cardTitle" fontSize="16px">
                              {item.title}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                        </Text>

                        {!isLoadingChart && (
                          <Tooltip label={item.title}>
                            <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                          </Tooltip>
                        )}
                      </Flex>
                    }>
                    <ScreenLoader isLoading={isLoadingChart}>
                      <Flex direction="row" justify="center" h="full">
                        <AccordionPanel>{item.content}</AccordionPanel>

                        {item?.modal}
                      </Flex>
                    </ScreenLoader>
                  </Card>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Grid>

        <CommonList
          //lists
          list={list}
          rawData={list}
          subList={sublist}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />
      </Flex>
    </Page>
  );
};

export default ExpeditionPage;
