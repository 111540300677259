import React from 'react';

import { MdOutlineExpandLess, MdOutlineExpandMore, MdOutlineUnfoldMore } from 'react-icons/md';

import { Flex, Text } from '@chakra-ui/react';

import { colors } from '../../../styles/colors';

const ArrowIcon = ({ isActive, direction, themeColor }) => {
  if (!isActive) {
    return <MdOutlineUnfoldMore color={themeColor} size={20} />;
  }
  return direction === 'ASC' ? (
    <MdOutlineExpandMore color={themeColor} size={20} />
  ) : (
    <MdOutlineExpandLess color={themeColor} size={20} />
  );
};

const SortableListHeader = ({ children, ...props }) => {
  const themeColor = colors.linkColor;

  const column = props?.column;
  const label = props?.label;
  const sorting = props?.sorting;
  const handleSort = props?.handleSort;

  const isActive = sorting?.column === column;

  //group by column
  const markGroupName = props?.markGroupName;
  const markGroupColor = props?.markGroupColor;

  const markNameFormatted = markGroupName ?? '';
  const markNameLower = markGroupName && markGroupName.toLowerCase();

  return (
    <>
      <style>
        {`
          .sortable_table_header_${markNameLower}::before {
            content: "${markNameFormatted}";  
            background: #fff;
            display: block;
            position: absolute;
            left: 0;
            top: -20px;
            font-size: 10px;
            padding-right: 15px;
            color: ${markGroupColor};
          }
      `}
      </style>
      {column ? (
        <Flex
          className={markGroupName ? `sortable_table_header_${markNameLower}` : 'sortable_table_header'}
          direction="row"
          align="center"
          onClick={() => handleSort(column, isActive && sorting?.direction === 'ASC' ? 'DESC' : 'ASC')}
          cursor="pointer">
          <Text color={themeColor}>{label}</Text>
          <ArrowIcon isActive={isActive} direction={sorting?.direction} themeColor={themeColor} />
        </Flex>
      ) : (
        <Text>{label}</Text>
      )}
    </>
  );
};

export default SortableListHeader;
