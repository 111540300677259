/**
 * Aplica uma máscara ao valor informado.
 * @param {string} value - Valor a ser mascarado.
 * @param {string} mask - Máscara no formato '###.###-##'.
 * @returns {string} - Valor formatado com a máscara.
 */
export const applyMask = (value, mask) => {
  const cleanValue = value.replace(/\D/g, '');
  let maskedValue = '';
  let index = 0;

  if (!mask) return value;

  for (const char of mask) {
    if (char === '#') {
      if (index < cleanValue.length) {
        maskedValue += cleanValue[index++];
      } else {
        break;
      }
    } else {
      maskedValue += char;
    }
  }

  return maskedValue;
};
