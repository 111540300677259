import { useEffect } from 'react';

import { usePopulateRoutes } from '../Hooks/usePopulateRoutes';

import { AnimatedPolyline } from './AnimatedPolyline';
import MaritimeMarker from './MaritimeMarker';

export const CommonRoutes = ({ lines, information, updateBounds }) => {
  const {
    // Default
    originRender,
    destinationRender,
    // Pontos
    pastPathRender,
    currentPathRender,
    futurePathRender,
    // Marcadores
    markerPastRender,
    markerCurrentRender,
    markerFutureRender,
    // Condições
    hasPastPath,
    hasCurrentPath,
    hasFuturePath,
    hasPastPathCount,
  } = usePopulateRoutes(information);

  useEffect(() => {
    // Atualiza os bounds sempre que os caminhos forem alterados
    const allPoints = lines.flatMap((line) => [
      { lat: line.origin.latitude, lng: line.origin.longitude },
      { lat: line.destination.latitude, lng: line.destination.longitude },
    ]);
    updateBounds(allPoints);
  }, [lines, updateBounds]);

  return (
    <>
      {/* Marcadores */}
      <MaritimeMarker path={markerPastRender} icon={'line'} />
      <MaritimeMarker path={markerFutureRender} count={hasPastPathCount} icon={'dashed'} />

      {lines.map((item, index) => (
        <AnimatedPolyline
          key={index}
          path={[
            {
              lat: item.origin.latitude,
              lng: item.origin.longitude,
            },
            {
              lat: item.destination.latitude,
              lng: item.destination.longitude,
            },
          ]}
          hasArrow={'true'}
        />
      ))}
    </>
  );
};
