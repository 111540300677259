import React from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import BilledListBody from './BilledListBody';

const BilledTableHeader = ({ isLoading, list, meta, setMeta, action, setAction, height }) => {
  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return (
    <ScreenLoader isLoading={isLoading}>
      <Box overflow="auto" h={height}>
        <Table minW="1600px">
          <Thead>
            <Tr h="60px">
              <Th w="70px !important"></Th>

              <Th w="180px !important">
                <Flex direction="row" align="center">
                  <Text>Categoria</Text>
                </Flex>
              </Th>

              <Th w="180px !important">
                <Flex direction="row" align="center">
                  <Text>Modalidade</Text>
                </Flex>
              </Th>

              <Th w="600px !important">
                <Flex direction="row" align="center">
                  <Text>Cliente</Text>
                </Flex>
              </Th>

              <Th w="180px !important">
                <Flex direction="column" align="end">
                  <Text>Orçamento R$</Text>
                </Flex>
              </Th>

              <Th w="190px !important">
                <Flex direction="column" align="end">
                  <Text>Total faturado R$</Text>
                </Flex>
              </Th>

              <Th w="180px !important">
                <Flex direction="column" align="end">
                  <Text>Diferença R$</Text>
                </Flex>
              </Th>

              <Th w="190px !important">
                <Flex align="end">
                  <Text textAlign="end">Pedidos a realizar R$</Text>
                </Flex>
              </Th>

              <Th w="130px !important">
                <Flex align="end">
                  <Text>Margem %</Text>
                </Flex>
              </Th>

              <Th w="180px !important">
                <Flex direction="column" align="end">
                  <Text>Rentabilidade</Text>
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {list?.result?.information?.data && list?.result?.information?.data.length === 0 ? (
              <Tr>
                <Td colSpan={10}>
                  <Flex justify="center" my="10px">
                    Nenhum dado encontrado para os parâmetros filtrados.
                  </Flex>
                </Td>
              </Tr>
            ) : (
              list?.result?.information?.data.map((item, key) => <BilledListBody key={key} entity={item} />)
            )}
          </Tbody>
        </Table>
      </Box>

      <Table className="table-default min" w="96.5vw" mt="10px">
        <Tbody>
          <Tr>
            <Td w="70px" px="24px !important"></Td>

            <Td w="180px" px="24px !important">
              Total
            </Td>

            <Td w="180px" px="24px !important"></Td>

            <Td w="600px" px="24px !important"></Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">
                <Text textStyle="paragaph">{moneyFormat(list.result?.sum?.billed?.budget ?? 0)}</Text>
              </Flex>
            </Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">
                <Text textStyle="paragaph">{moneyFormat(list.result?.sum?.billed?.billing ?? 0)}</Text>
              </Flex>
            </Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">
                <Text textStyle="paragaph">{moneyFormat(list.result?.sum?.billed?.difference ?? 0)}</Text>
              </Flex>
            </Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">
                <Text textStyle="paragaph">{moneyFormat(list.result?.sum?.billed?.pendingBilling ?? 0)}</Text>
              </Flex>
            </Td>

            <Td w="130px" px="24px !important"></Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">
                <Text textStyle="paragaph">{moneyFormat(list.result?.sum?.billed?.profitability ?? 0)}</Text>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {meta.total_pages > 1 && (
        <Paginate setMetadata={setMeta} metadata={meta} action={action} setAction={setAction} showDetails={true} />
      )}
    </ScreenLoader>
  );
};

export default BilledTableHeader;
