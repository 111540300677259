import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Box, Button, Flex, Image, Modal, ModalBody, ModalContent, Select, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ModalSendTrip = ({ icon, enabled, w = '25px', user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [trips, setTrips] = useState([]);
  const [load, setLoad] = useState(false);
  const [selected, setSelected] = useState('');

  const listTrips = () => {
    requests.listTrips(user?.identifier, 'disponible').then((list) => {
      setTrips(list.data);
    });
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const sendTrip = () => {
    setLoad(true);
    requests.sendTrip(selected, user?.identifier).then(() => {
      listTrips();
      setLoad(false);
      toast.success('Código enviado para email informado!');
      onClose();
    });
  };

  useEffect(() => {
    if (user?.identifier && isOpen) {
      listTrips();
    }
  }, [user, isOpen]);

  return (
    <>
      <Tooltip label="Enviar viagem">
        <Image
          w={{ base: '20px', md: w }}
          src={icon}
          alt="Enviar viagem"
          filter="saturate(0)"
          cursor="pointer"
          transition="0.2s"
          _hover={{
            filter: enabled ? 'saturate(1)' : 'saturate(0)',
          }}
          onClick={enabled ? onOpen : onClose}
        />
      </Tooltip>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalContent borderRadius="5px" maxWidth={'680px'} margin={'25px'}>
          <ModalBody p="25px" position="relative">
            <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" mb="20px">
              Enviar Viagem ao motorista: {user?.name}
            </Text>
            <Flex direction="column" mb="10px" border="1px solid #7070703D">
              <Box borderBottom="1px solid #7070703D" p="15px">
                <Text font="normal normal medium 20px/26px Hanken Grotesk" textColor="#422C76">
                  Viagens Disponíveis
                </Text>
              </Box>
              <Flex alignItems="center" p="0 20px" flexDirection={{ base: 'column', md: 'row' }}>
                <Select
                  w={{ base: '100%', md: '40%' }}
                  placeholder="Selecione a viagem"
                  onChange={handleChange}
                  mt={{ base: '15px', md: '0' }}>
                  {trips.length > 0 &&
                    trips.map(function (trip, key) {
                      return (
                        <option key={key} value={trip?.identifier}>
                          Viagem #{trip?.code}
                        </option>
                      );
                    })}
                </Select>
                <Button
                  variant="primary"
                  w={{ base: '100%', md: 'fit-content' }}
                  borderRadius="7px"
                  m="15px"
                  isLoading={load}
                  loadingText="Enviando"
                  onClick={load ? null : sendTrip}>
                  Enviar ao motorista
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalSendTrip;
