import React from 'react';

import { Formik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Text,
} from '@chakra-ui/react';

import requests from '../../../../../services/requests';
import { initialValues } from '../../Helpers/initialValues';

const CreateLinkDrawer = ({ entity, isOpenShareTrip, onCloseShareTrip, setListLink, reloadTripLinksList }) => {
  const formatDate = (value) => moment(value).format('DD/MM/YYYY HH:mm:ss');

  return (
    <Formik
      initialValues={initialValues({})}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        const aux = {
          releasedAt: values?.releasedAt ? moment(values?.releasedAt).format('DD/MM/yyyy HH:mm:ss') : '',
          expiredAt: values?.expiredAt ? moment(values?.expiredAt).format('DD/MM/yyyy HH:mm:ss') : '',
          status: 'ATIVO',
        };

        requests
          .newTripSharingLink(entity.identifier, aux)
          .then((response) => {
            setListLink(response);
            toast.success('Link criado com sucesso');
            reloadTripLinksList();
          })
          .finally(() => {
            setSubmitting(false);
            onCloseShareTrip();
          });
      }}>
      {({ handleChange, handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Drawer isOpen={isOpenShareTrip} onClose={onCloseShareTrip} placement="left" size="xl">
            <DrawerContent>
              <DrawerCloseButton />

              <DrawerHeader display="flex" alignItems="flex-start" gap="20px">
                <Box w="4px" h="full" bgColor="linkColor" />
                <Flex direction="column">
                  <Text textColor="primary">Criar novo link</Text>

                  <Text textColor="primary" opacity="0.6" fontSize="small">
                    id: #{entity?.code}
                  </Text>
                </Flex>
              </DrawerHeader>

              <DrawerBody>
                <Flex border="1px solid #422C761A" borderRadius="5px" p="25px 30px">
                  <Grid w="full" templateColumns="repeat(2, 1fr)" gap="20px">
                    <FormControl>
                      <FormLabel textColor="primary">Data e hora de liberação</FormLabel>
                      <Input
                        type="datetime-local"
                        name="releasedAt"
                        value={values.releasedAt}
                        onChange={(e) => {
                          const formattedDate = formatDate(e.target.value);
                          handleChange(e);
                          values.releasedAt = formattedDate;
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel textColor="primary">Data e hora de expiração</FormLabel>
                      <Input
                        type="datetime-local"
                        name="expiredAt"
                        value={values.expiredAt}
                        onChange={(e) => {
                          const formattedDate = formatDate(e.target.value);
                          handleChange(e);
                          values.expiredAt = formattedDate;
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Flex>
              </DrawerBody>

              <DrawerFooter>
                <Button
                  variant="primary"
                  isLoading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}>
                  Criar link
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </form>
      )}
    </Formik>
  );
};

export default CreateLinkDrawer;
