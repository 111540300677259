import React, { useEffect, useRef, useState } from 'react';

import { AiFillStar } from 'react-icons/ai';

import { Box, Tooltip } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const FavoriteProcess = ({ process, onChange, processPriority = null }) => {
  const [isActive, setIsActive] = useState(false); // Inicialmente definido como false
  const toggleRef = useRef(null);

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    savePriority();
    onChange && onChange(!isActive);
    setIsActive(!isActive);
  };

  const savePriority = () => {
    requests.saveProcessPriority(process.identifier, !isActive);
  };

  if (processPriority) {
    useEffect(() => {
      if (processPriority.includes(process.identifier)) {
        setIsActive(true);
      }
    }, [processPriority, process.identifier]);
  } else {
    useEffect(() => {
      requests.checkProcessPriority(process.identifier).then((data) => {
        setIsActive(data.isFavourite);
      });
    }, []);
  }

  return (
    <Tooltip
      label={
        <>
          Marcar como favorito?
          <br /> Obs. Os itens favoritados são exibidos no dashboard em destaque.
        </>
      }>
      <Box ref={toggleRef} position="relative">
        <AiFillStar cursor="pointer" color="#e52359" size={25} className={`star ${isActive ? 'active' : ''}`} onClick={flagClick} />
      </Box>
    </Tooltip>
  );
};

export default FavoriteProcess;
