import { useRef } from 'react';

import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import { 
  Button, 
  Drawer, 
  DrawerBody, 
  DrawerCloseButton, 
  DrawerContent, 
  DrawerHeader, 
  DrawerOverlay, 
  useDisclosure,
  Flex, 
  Grid, 
  GridItem, 
  Image, 
  Text 
} from "@chakra-ui/react";

import moneyFormat from '../../../utils/currency/moneyFormat';
import { itenSemEstoque }  from '../../../utils/exports';


export default function CardDrawerButton({
  hasDrawerCard
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  return (
    <>
      <Button
        ref={btnRef}
        onClick={onOpen}
        rightIcon={<MdKeyboardDoubleArrowRight size={20} color="#C767DC" />}
        bg={'transparent'}
        color={'primary'}
        w={{ base:'100%', md:'initial' }}
        border={'1px solid #C767DC'}
        _hover={{ bg: '#f1f1f1' }}>
        Ver itens
      </Button>
      <Drawer 
        isOpen={isOpen} 
        placement="right" 
        onClose={onClose} 
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW={'100% !important'} w={{ base: "initial", md:"50% !important" }} px="30px" py="30px"
        >
          <DrawerCloseButton />
          <DrawerHeader
            display={'flex'}
            gap={'12px'}
            fontSize={'30px'}
            color={'primary'}
            p={{ base: '30px 30px 44px', md: '60px 60px 88px' }}>
            <Image src={itenSemEstoque} /> Itens sem estoque
          </DrawerHeader>

          <DrawerBody>
            <Flex flexDirection={'column'}>
              <Grid
                gridTemplateColumns={'repeat(' + hasDrawerCard.header.length + ', 1fr)'}
                border={'1px solid #7070703D'}
                p={{base: '20px 20px 12px', md: '30px 35px 22px'}}
              >
                {hasDrawerCard.header.map((item, key) => {
                  return (
                    <GridItem key={key} display={'flex'}>
                      <Text 
                        fontSize={{base: '16px', md: '18px'}}
                        color={'titleColor'} 
                        fontWeight={'bold'}
                      >
                        {item}
                      </Text>
                    </GridItem>
                  );
                })}
              </Grid>
              <Grid>
                {hasDrawerCard.data != undefined &&
                  hasDrawerCard.data.map((item, key) => {
                    return (
                      <GridItem
                        key={key}
                        display={'grid'}
                        gridTemplateColumns={'repeat(' + hasDrawerCard.header.length + ', 1fr)'}
                        alignItems={'center'}
                        p={{base: '5px 20px', md: '5px 35px'}}
                        minH={'52px'}
                        _even={{ bg: '#eee' }}>
                        {hasDrawerCard.content.map((field, index) => {
                          return (
                            <Text 
                              key={index} 
                              fontSize={{base: '14px', md: '17px'}}
                              color={'textColor3'}
                            >

                              {hasDrawerCard.formatField === index + 1 ? (
                                item[field] !== undefined ? moneyFormat(item[field]) : '-'
                              ) : (
                                item[field] !== undefined ? item[field] : '-'
                              )}

                            </Text>
                          );
                        })}
                      </GridItem>
                    );
                  })}
              </Grid>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
