import React, { useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Collapse, Flex, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';


import ConsolidationClients from './ConsolidationClients';

const ConsolidationModalityDetails = ({ list, modality, modalityDetails, index, moneyFormat, colorFormat, viewport }) => {
  const [collapseModalityDetails, setCollapseModalityDetails] = useState(null);

  const dataIsEmpty =
    !list.result.data[modality].data[modalityDetails].clients ||
    Object.keys(list.result.data[modality].data[modalityDetails].clients).length === 0;

  const handleCollapseModalityDetails = (index) => {
    if (!dataIsEmpty) {
      setCollapseModalityDetails(collapseModalityDetails === index ? null : index);
    }
  };

  return (
    <>
      {/* row modality */}
      <Tr
        key={index}
        onClick={() => handleCollapseModalityDetails(index)}
        cursor={dataIsEmpty ? 'default' : 'pointer'}
        className="border">
        <Td minW="85px" maxW="85px">
          <Flex mt="5px !important">
            {!dataIsEmpty &&
              (collapseModalityDetails === index ? (
                <MdKeyboardArrowDown size={20} color="#707070" />
              ) : (
                <MdKeyboardArrowRight size={20} color="#707070" />
              ))}
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex mt="5px !important">
            <Text>{list.result.data[modality].data[modalityDetails].title}</Text>
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex mt="5px !important" justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].billed)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].billed)}
            </Text>
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex mt="5px !important" justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].estimated)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].estimated)}
            </Text>
          </Flex>
        </Td>

        <Td minW="280px">
          <Flex mt="5px !important" justify="space-between">
            <Text textColor="linkColor" fontWeight="bold">
              R$
            </Text>
            <Text color={colorFormat(list.result.data[modality].data[modalityDetails].budgeted)}>
              {moneyFormat(list.result.data[modality].data[modalityDetails].budgeted)}
            </Text>
          </Flex>
        </Td>

        <Td w="full">
          <Flex mt="5px !important" justify={viewport > '1500' ? 'center' : 'normal'}>
            <Flex w={viewport > '1500' ? '280px' : 'full'} justify="space-between">
              <Text textColor="linkColor" fontWeight="bold">
                R$
              </Text>

              <Text color={colorFormat(list.result.data[modality].data[modalityDetails].difference)}>
                {moneyFormat(list.result.data[modality].data[modalityDetails].difference)}
              </Text>
            </Flex>
          </Flex>
        </Td>
      </Tr>

      {/* collapse type modality */}
      {!dataIsEmpty && (
        <Tr key={`${index}-modalityDetails`}>
          <Td colSpan={6} border="none" p="0px">
            <Collapse in={collapseModalityDetails === index}>
              <Flex bgColor="#F9F9F9" pb="10px">
                <Table w="full" ml="15px">
                  <Tbody>
                    {Object.keys(list.result.data[modality].data[modalityDetails].clients).map((clients, index) => (
                      <ConsolidationClients
                        key={index}
                        index={index}
                        list={list}
                        modality={modality}
                        modalityDetails={modalityDetails}
                        clients={clients}
                        moneyFormat={moneyFormat}
                        colorFormat={colorFormat}
                        viewport={viewport}
                      />
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            </Collapse>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default ConsolidationModalityDetails;
