import React, { useEffect, useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import moment from 'moment';
import { MdBlock, MdCheck, MdHistory, MdInsertLink, MdLinkOff, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';
import { initialValues } from '../../Helpers/initialValues';
import { validationSchema } from '../../Helpers/validationSchema';

const EditLinkDrawer = ({
  entity,
  entityShareTrip,
  isOpenShareTrip,
  onCloseShareTrip,
  reloadTripLinksList,
  isOpenConfirmModal,
  onOpenConfirmModal,
  onCloseConfirmModal,
}) => {
  const [loadDetails, setLoadDetails] = useState(false);
  const [dataLink, setDataLink] = useState([]);

  const [loadHistory, setLoadHistory] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);

  const [showHistory, setShowHistory] = useState(true);

  const formatDate = (value) => moment(value).format('DD/MM/YYYY HH:mm:ss');

  const handleCopyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success('Texto copiado para a área de transferência.'))
      .catch(() => toast.error('Falha ao copiar o texto.'));
  };

  const handleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  const loadDetailsLink = () => {
    setLoadDetails(true);

    requests
      .getTripLinkDetails(entity.identifier, entityShareTrip.identifier)
      .then((res) => setDataLink(res))
      .finally(() => setLoadDetails(false));
  };

  const loadHistoryLink = () => {
    setLoadHistory(true);

    requests
      .getTripLinkHistory(entity.identifier, entityShareTrip.identifier)
      .then((res) => setDataHistory(res))
      .finally(() => setLoadHistory(false));
  };

  const loadTravelLinkStatus = (type) => {
    requests
      .putTripLinkStatus(entity.identifier, entityShareTrip.identifier, type)
      .then(() => {
        toast.success(`Link ${type === 'activate' ? 'ativado' : 'dativado'} com sucesso.`);
      })
      .catch(() => {
        toast.error('Erro ao ativar o link, tente novamente.');
        loadDetailsLink();
      })
      .finally(() => {
        loadDetailsLink();
      });
  };

  useEffect(() => {
    loadDetailsLink();
    loadHistoryLink();

    setShowHistory(true);
  }, [entityShareTrip]);

  return dataLink ? (
    <Formik
      initialValues={initialValues(dataLink)}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        const aux = {
          releasedAt: moment(values?.releasedAt).format('DD/MM/yyyy HH:mm:ss'),
          expiredAt: moment(values?.expiredAt).format('DD/MM/yyyy HH:mm:ss'),
          status: values.status,
        };

        requests
          .editTripSharingLink(entity.identifier, entityShareTrip.identifier, aux)
          .then(() => {
            toast.success('Link atualizado com sucesso');
            reloadTripLinksList();

            onCloseShareTrip();
          })
          .catch(() => {})
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ handleChange, setFieldValue, handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Drawer isOpen={isOpenShareTrip} onClose={onCloseShareTrip} placement="left" size="xl">
            <DrawerContent>
              <DrawerCloseButton />

              <DrawerHeader display="flex" alignItems="flex-start" gap="20px">
                <Box w="4px" h="full" bgColor="linkColor" />

                <Flex direction="column">
                  <Text textColor="primary">Compartilhar viagem</Text>

                  <Text textColor="primary" opacity="0.6" fontSize="small">
                    id: #{entity?.code}
                  </Text>
                </Flex>
              </DrawerHeader>

              <ScreenLoader isLoading={loadDetails}>
                <DrawerBody>
                  <FormControl mb="20px">
                    <FormLabel textColor="primary">Gerenciar link</FormLabel>

                    <Flex
                      h="47px"
                      justify="space-between"
                      px="10px"
                      py="8px"
                      bgColor={values.status === 'ATIVO' ? '#F3F1FA' : '#50505014'}
                      borderRadius="6px">
                      <Flex align="center" gap="5px" color={values.status === 'ATIVO' ? '#6C48C2' : '#707070'}>
                        {values.status === 'ATIVO' ? <MdInsertLink /> : <MdLinkOff />}

                        <Text
                          as="u"
                          fontSize="13px"
                          cursor={values.status === 'ATIVO' ? 'copy' : 'not-allowed'}
                          onClick={() =>
                            values.status === 'ATIVO' &&
                            handleCopyText(
                              `https://analytics.vendemmia.com.br/public/tms/trip/${entity.identifier}/link/${entityShareTrip.identifier}/share`
                            )
                          }>
                          {`https://analytics.vendemmia.com.br/public/tms/trip/${entity.identifier}/link/${entityShareTrip.identifier}/share`}
                        </Text>
                      </Flex>

                      {values.status === 'ATIVO' ? (
                        <Flex h="full" gap="5px">
                          <Tooltip label="Abrir link">
                            <Link
                              to={`/public/tms/trip/${entity.identifier}/link/${entityShareTrip.identifier}/share`}
                              target="_blank">
                              <IconButton h="full" bgColor="#EAE3F4" icon={<MdOpenInNew color="#6C48C2" />} />
                            </Link>
                          </Tooltip>

                          <Tooltip label="Desabilitar link">
                            <IconButton
                              h="full"
                              bgColor="#EAE3F4"
                              icon={<MdBlock color="#E74C3C" />}
                              onClick={() => onOpenConfirmModal(true)}
                            />
                          </Tooltip>
                        </Flex>
                      ) : (
                        values.status !== 'EXPIRADO' && (
                          <Tooltip label="Ativar link">
                            <IconButton
                              h="full"
                              bgColor="#EAE3F4"
                              textColor="linkColor"
                              icon={<MdCheck />}
                              onClick={() => loadTravelLinkStatus('activate')}
                            />
                          </Tooltip>
                        )
                      )}
                    </Flex>
                  </FormControl>

                  <Flex border="1px solid #422C761A" borderRadius="5px" p="25px 30px" mb="20px">
                    <Grid w="full" templateColumns="repeat(2, 1fr)" gap="20px">
                      <FormControl>
                        <FormLabel textColor="primary">Data e hora de liberação</FormLabel>

                        <Input
                          type="datetime-local"
                          name="releasedAt"
                          value={values.releasedAt}
                          onChange={(e) => {
                            const formattedDate = formatDate(e.target.value);
                            handleChange(e);
                            values.releasedAt = formattedDate;
                          }}
                          isDisabled={values.status === 'EXPIRADO'}
                        />

                        <ErrorMessage component="p" className="error-message-error" name="releasedAt" />
                      </FormControl>

                      <FormControl>
                        <FormLabel textColor="primary">Data e hora de expiração</FormLabel>

                        <Input
                          type="datetime-local"
                          name="expiredAt"
                          value={values.expiredAt}
                          onChange={(e) => {
                            const formattedDate = formatDate(e.target.value);
                            handleChange(e);
                            values.expiredAt = formattedDate;
                          }}
                          isDisabled={values.status === 'EXPIRADO'}
                        />

                        <ErrorMessage component="p" className="error-message-error" name="expiredAt" />
                      </FormControl>
                    </Grid>
                  </Flex>

                  <Flex h="calc(100vh - 420px)" direction="column" overflow="auto">
                    {dataHistory.length >= 0 && (
                      <Flex w="fit-content" align="center" gap="5px" mb="20px" cursor="pointer" onClick={() => handleShowHistory()}>
                        <Flex bgColor="#EAE3F4" borderRadius="6px" p="10px">
                          <MdHistory color="#6C48C2" />
                        </Flex>

                        <Text textColor="#6C48C2" fontSize="13px" my="10px">
                          Ver histórico
                        </Text>
                      </Flex>
                    )}

                    {dataHistory.length >= 0 &&
                      showHistory &&
                      dataHistory.map((item, key) => (
                        <Flex
                          w="full"
                          p="25px 30px"
                          mb="20px"
                          border="1px solid #422C761A"
                          borderRadius="5px"
                          justify="space-between"
                          gap="50px"
                          key={key}>
                          <Text fontSize="13px" textColor="#707070">
                            {item.description}
                          </Text>

                          <Text fontSize="12px" textColor="#707070" style={{ textWrap: 'nowrap' }}>
                            {moment(item.createdAt).format('DD/MM/yyyy HH:mm')}
                          </Text>
                        </Flex>
                      ))}
                  </Flex>
                </DrawerBody>
              </ScreenLoader>

              <DrawerFooter borderTop="1px" borderColor="#7070702E">
                <Button
                  variant="primary"
                  isLoading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                  isDisabled={values.status === 'EXPIRADO'}>
                  Atualizar link
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>

          <Modal isCentered isOpen={isOpenConfirmModal} onClose={onCloseConfirmModal}>
            <ModalOverlay />

            <ModalContent w="auto" borderRadius="26px" p="40px 80px" maxW="none">
              <ModalHeader textAlign="center" fontSize="34px">
                Desabilitar link
              </ModalHeader>

              <ModalBody>
                <Text textColor="#0F0A1D80" textAlign="center">
                  Você tem certeza que deseja desabilitar esse link? <br />
                  Esse link ficará inacessível e apresentará uma página de erro.
                </Text>
              </ModalBody>

              <ModalFooter justifyContent="center" gap="10px">
                <Button w="full" h="47px" variant="secundary" onClick={() => onCloseConfirmModal()}>
                  Cancelar
                </Button>

                <Button
                  w="full"
                  h="47px"
                  bgColor="#E74C3C"
                  textColor="white"
                  onClick={() => {
                    loadTravelLinkStatus('deactivate');

                    onCloseConfirmModal();
                  }}>
                  Desabilitar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </form>
      )}
    </Formik>
  ) : (
    <p>Carregando...</p>
  );
};

export default EditLinkDrawer;
