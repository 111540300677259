import React from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import CommonPieChart from '../../../../components/CommonChats/CommonPieChart';

const InconsistenciesByStageChart = ({ data }) => {
  const segments = [
    { id: 'Pré embarque', key: 'Pré-embarque', color: '#67B7DC' },
    { id: 'Trânsito', key: 'Trânsito', color: '#6794DC' },
    { id: 'Desembaraço', key: 'Desembaraço', color: '#8067DC' },
    { id: 'Faturamento', key: 'Faturamento', color: '#A367DC' },
    { id: 'Transporte', key: 'Transporte', color: '#C767DC' },
    { id: 'Entrega', key: 'Entrega', color: '#70D499' },
    { id: 'Sem etapa', key: 'Transporte', color: '#D6D6D6' },
  ];

  return (
    <Grid templateColumns="3fr 1fr" alignItems="center" justifyContent="center">
      <CommonPieChart
        data={data}
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        startAngle={-90}
        endAngle={90}
        innerRadius={'0.6'}
        posContent={'-1.7em'}
        segments={segments}
        enableArcLinkLabels={false}
        legends={[]}
      />

      <Flex w="full" direction="column" gap="15px">
        {segments.map((entity, key) => (
          <Flex align="center" key={key}>
            <Box w="28px" h="18px" bgColor={entity.color} borderRadius="3px" />
            <Text textAlign="center" fontSize={'0.875rem'} ml="1">
              {entity.id}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Grid>
  );
};

export default InconsistenciesByStageChart;
