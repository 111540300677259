import React from 'react';

import { Bar, CartesianGrid, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex } from '@chakra-ui/react';

import CustomTooltip from '../../../../components/Tooltip/customTooltip';

const ChartStockTurnover = ({ data }) => {

  const resolution = (window.innerWidth) - 150;

  return (
    <Flex align="center" justify="center">
      <ComposedChart margin={{left:25, top:25}} width={resolution} height={500} data={data}>
        <CartesianGrid vertical={false} />
        <YAxis />
        <XAxis dataKey="codProduto" init="produto" />
        <Tooltip content={
          <CustomTooltip 
            payload={data} 
            tooltip={[
              {
                name: "Turnover",
                dataKey: "turnover"
              },
              {
                name: "Código do produto",
                dataKey: "codProduto", 
                color: '#C767DC'
              }
            ]}
          >
          </CustomTooltip>} 
        />
        <Bar dataKey="turnover" barSize={40} fill="#C767DC" />
      </ComposedChart>
    </Flex>
  );
};

export default ChartStockTurnover;
