import React from 'react';

import { MdHelp, MdNorth } from 'react-icons/md';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

const CardRequests = ({ title, text, bgColor, textColor, iconColor, Img }) => {
  return (
    <Box bgColor={bgColor} border="1px" borderColor="#7070703D" className="up-anim">
      <Flex px="30px" py="20px" direction="column">
        <Flex w="full" gap="20px">
          <Text textColor={textColor}>{title}</Text>
          <MdHelp color={iconColor} size={20} />
        </Flex>

        <Flex align="center" gap="13px">
          <Text textStyle="tableTitle" textColor={textColor} fontWeight="bold">
            {text}
          </Text>
        </Flex>

        <Flex direction="row" align="flex-end" justify="space-between">
          <Flex direction="column">
            <Flex direction="row" align="baseline" gap="5px" textColor={textColor}>
              <Text textStyle="primaryTitle" fontSize="56px" fontWeight="bold">
                300
              </Text>
            </Flex>

            <Flex direction="row" align="center" gap="5px">
              <Flex align="center" gap="5px">
                <MdNorth color="#2ECC71" size={12} />
                <Text textStyle="paragraph" textColor="#2ECC71">
                  3,5%
                </Text>
              </Flex>

              <Text textStyle="paragraph" textColor={textColor}>
                desde a última semana
              </Text>
            </Flex>
          </Flex>
          <Image src={Img} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default CardRequests;
