

export const initialValues = (entity = {}) => {
  let responsibles = [];
  if (entity?.responsibles?.length > 0) {
    entity?.responsibles.map((user, index) => {
      responsibles.push( { value: user?.responsible?.identifier, label: user?.name });
    });
  }

  return {
    title: entity?.title || '',
    description: entity?.description || '',
    expirationType: entity?.expirationType || '',
    expirationType: entity?.expirationType ? { value: entity?.expirationType, label: entity?.expirationTypeReadable } : '',
    expiresAt: entity?.expiresAt || '',
    responsibles: responsibles,
  };
};
