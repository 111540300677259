const yup = require('yup');

const commercialValidation = (isDraft = false) => {
  let validation = yup.object().shape({
    generalRestrictionsInformation: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    profitabilityInformation: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    isIndefiniteContract: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    contractValidUntil: isDraft
      ? yup.string()
      : yup.string().when('isIndefiniteContract', {
          is: true,
          then: yup.string().required('Campo obrigatório').nullable(),
        }),
    annualBilling: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    monthlyBilling: yup.string(),
    isProject: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    additionalInformation: yup.string(),
  });

  return validation;
};

export default commercialValidation;
