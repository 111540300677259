import React, { useEffect, useState } from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import PieChartComponent from '../PieChartComponent';

const StockCollectionStatusChart = ({ data, setStockPosition, isPreview }) => {
  const [entity, setEntity] = useState([]);
  const [total, setTotal] = useState(0);

  const handleClickStockPosition = (status) => {
    setStockPosition(status);
  };

  const formatValue = (value) => (isNaN(value) ? 0 : value.toLocaleString('pt-BR'));

  const calculatePercentage = (value) => {
    if (value !== undefined && total !== 0 && !isNaN(total)) {
      const percentage = (value / total) * 100;
      return `${percentage.toFixed(2)}%`;
    }
    return '0%';
  };

  const fetchData = async () => {
    try {
      const Blocado = parseInt(data.Blocado, 10) || 0;
      const Devolução = parseInt(data.Devolução, 10) || 0;
      const Expedição = parseInt(data.Expedição, 10) || 0;
      const PortaPallet = parseInt(data['Porta Pallet'], 10) || 0;
      const Recebimento = parseInt(data.Recebimento, 10) || 0;

      const chartData = [
        {
          name: 'Blocado',
          qtt: Blocado,
          color: '#5289C9',
          status: 'bl',
        },
        {
          name: 'Devolução',
          qtt: Devolução,
          color: '#f1c40f',
          status: 'devol',
        },
        {
          name: 'Expedição',
          qtt: Expedição,
          color: '#2ECC71',
          status: 'exped',
        },
        {
          name: 'Porta Pallet',
          qtt: PortaPallet,
          color: '#E52359',
          status: 'pp',
        },
        {
          name: 'Recebimento',
          qtt: Recebimento,
          color: '#422C76',
          status: 'receb',
        },
      ];

      setTotal(data.total);
      setEntity(chartData);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <Flex
      alignItems="center"
      justify="space-evenly"
      m={'0 0 65px'}
      w="full"
      h="full"
      gap="10px"
      flexDirection={{ base: 'column', md: 'row' }}>
      <Grid templateColumns={'1fr 1fr'} direction="column" gap="30px" flexDirection={{ base: 'row', md: 'column' }}>
        {entity.map((entry, index) => (
          <Flex key={`item-${index}`} direction="column">
            <Flex gap="10px" align="center">
              <Box bgColor={entry.color} borderRadius="3px" p={'5px'}>
                <Text textStyle="tableTitle" textColor="#ffffff" fontSize={'16px'} fontWeight="500">
                  {entry.qtt}
                </Text>
              </Box>

              <Flex fontSize={'14px'} flexDirection={'column'}>
                <Text>{entry.name}</Text>
                <Text>{calculatePercentage(entry.qtt)}</Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Grid>

      <PieChartComponent
        entity={entity}
        total={total}
        formatValue={formatValue}
        handleClickStatus={handleClickStockPosition}
        isPreview={isPreview}
      />
    </Flex>
  );
};

export default StockCollectionStatusChart;
