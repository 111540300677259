import React from 'react';

import { useForm } from 'react-hook-form';
import { MdAdd, MdEditNote } from 'react-icons/md';

import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

const ModalFormFaq = ({
  load,
  txtButton,
  txtHeader,
  textButtonConfirm,
  onSubmit,
  title,
  description,
  typeSystem,
  modality,
  handlesetTypeSystemChange,
  handleModalityChange,
  handleTitleChange,
  handleContentChange,
  isEdit,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Submit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <>
      {isEdit ? (
        <Tooltip label="Editar">
          <Box>
            <MdEditNote size={20} cursor="pointer" onClick={onOpen} color="#422C76" />
          </Box>
        </Tooltip>
      ) : (
        <Button
          leftIcon={<MdAdd color="#FFFFFF" size={20} />}
          _hover={{ bgColor: '#70D499' }}
          bg="green"
          p="1.5rem"
          pb="1.5rem"
          pl="1.3rem"
          pr="1.3rem"
          borderRadius="50px"
          onClick={onOpen}>
          <Text mr="10px" color="white">
            {txtButton}
          </Text>
        </Button>
      )}

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent borderRadius="9px" maxW="none" w="40vw" p="20px">
          <ModalHeader mb="20px" p="0">
            <Text textStyle="primaryTitle" textColor="#0F0A1D" textAlign="center">
              {txtHeader}
            </Text>
          </ModalHeader>

          <Flex as="form" h="full" direction="column" justify="space-between" onSubmit={handleSubmit(Submit)}>
            <ModalBody flexDirection="column" p="0" display="flex" gap="10px">
              <Flex direction="row" gap="20px">
                <FormControl w="300px">
                  <Select
                    variant="primary"
                    placeholder="Selecione a categoria"
                    h="50px"
                    bgColor="#F2F2F2"
                    borderColor="#7070703B"
                    {...register('type', { required: true })}
                    name="type"
                    onChange={handlesetTypeSystemChange}
                    value={typeSystem}>
                    <option value="SYSTEM_ANALYTICS">Painel Analytics</option>
                    <option value="APP_DRIVER">App do Motorista</option>
                  </Select>
                  {errors.type && <Text textStyle="error">Campo obrigatório.</Text>}
                </FormControl>

                <FormControl w="300px">
                  <Select
                    variant="primary"
                    placeholder="Selecione a modalidade"
                    h="50px"
                    bgColor="#F2F2F2"
                    borderColor="#7070703B"
                    {...register('modality', { required: true })}
                    name="modality"
                    onChange={handleModalityChange}
                    value={modality}>
                    <option value="AUTHENTICATED">Autenticado</option>
                    <option value="ANONYMOUS">Público</option>
                  </Select>
                  {errors.modality && <Text textStyle="error">Campo obrigatório.</Text>}
                </FormControl>
              </Flex>

              <Flex direction="column" gap="10px">
                <FormControl>
                  <Input
                    h="40px"
                    bgColor="#F2F2F2"
                    borderColor="#7070703B"
                    placeholder="Título do tópico..."
                    name="title"
                    {...register('title', { required: true })}
                    onChange={handleTitleChange}
                    value={title}
                  />
                  {errors.title && <Text textStyle="error">Campo obrigatório.</Text>}
                </FormControl>

                <FormControl>
                  <Textarea
                    value={description}
                    minH="230px"
                    resize="none"
                    bgColor="#F2F2F2"
                    borderColor="#7070703B"
                    placeholder="Descreva a aqui...."
                    name="description"
                    {...register('description', { required: true })}
                    onChange={handleContentChange}
                  />
                  {errors.description && <Text textStyle="error">Campo obrigatório.</Text>}
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter alignItems="center" justifyContent="center">
              <Button variant="secundary" borderRadius="7px" m="15px" p="25px 35px" onClick={onClose}>
                Cancelar
              </Button>

              <Button
                variant="primary"
                borderRadius="7px"
                m="15px"
                p="25px 35px"
                _hover={{ opacity: 0.8 }}
                type="submit"
                isLoading={load}
                loadingText="Salvando">
                {textButtonConfirm}
              </Button>
            </ModalFooter>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalFormFaq;
