/**
 * Substitui identificadores dinâmicos no formato ${identifier} pelo valor correspondente no objeto de parâmetros
 * ou um valor direto.
 *
 * @param {string} endpoint - A URL ou string contendo identificadores no formato ${identifier}.
 * @param {Object|string} identifiersOrValue - Objeto contendo identificadores e seus valores correspondentes,
 * ou um valor direto para substituir todos os identificadores.
 * @returns {string} - A string com os identificadores substituídos por seus valores.
 */
export const replaceIdentifiersInEndpoint = ({ endpoint, identifiersOrValue }) => {
  if (typeof endpoint !== 'string') {
    throw new TypeError('O endpoint deve ser uma string.');
  }

  const isObject = typeof identifiersOrValue === 'object' && identifiersOrValue !== null;
  const isStringOrNumber = typeof identifiersOrValue === 'string' || typeof identifiersOrValue === 'number';

  if (!isObject && !isStringOrNumber) {
    throw new TypeError('O segundo parâmetro deve ser um objeto ou um valor direto (string ou número).');
  }

  return endpoint.replace(/\${(\w+)}/g, (match, identifier) => {
    if (isObject) {
      // Se for um objeto, retorna o valor correspondente ao identifier ou mantém o identificador se não encontrado
      return identifiersOrValue.hasOwnProperty(identifier) ? identifiersOrValue[identifier] : match;
    }

    // Se for um valor direto (string ou número), substitui todos os identificadores pelo valor
    return identifiersOrValue;
  });
};
