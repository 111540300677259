import React, { useRef, useState } from 'react';

import { Flex, Grid } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

import PercentagePeriodChart from './components/PercentagePeriodChart';
import StockAgingTable from './components/StockAgingTable';
import TotalFamilyBarChart from './components/TotalFamilyBarChart';

const StockAgingPage = () => {
  const hasPermission = permissions.warehouseStockAging;

  const request = useRef(0);

  const [chartAgingTotal, setChartAgingTotal] = useState([]);
  const [isLoadingchartAgingTotal, setIsLoadingchartAgingTotal] = useState(true);
  const [chartAgingPeriod, setChartAgingPeriod] = useState([]);
  const [isLoadingAgingPeriod, setIsLoadingAgingPeriod] = useState(true);
  const [listAgingReport, setListAgingReport] = useState([]);
  const [isLoadingAgingReport, setIsLoadingAgingReport] = useState(true);

  const colors = ['#6576FF', '#FFA353', '#2ECC71', '#F1C40F'];

  const loadData = async (filters, key, page) => {
    await Promise.all([
      loadAgingTotalPerFamily(filters, key),
      loadPercentagePerPeriod(filters, key),
      loadStockAgingReport(filters, key, page),
    ]);
  };

  const loadAgingTotalPerFamily = async (filters, key) => {
    setIsLoadingchartAgingTotal(true);
    try {
      const data = await requests.getAgingTotalPerFamily(filters);
      if (request.current && request.current > key) return;
      request.current = key;
      setChartAgingTotal(data);
    } finally {
      setIsLoadingchartAgingTotal(false);
    }
  };

  const loadPercentagePerPeriod = async (filters, key) => {
    setIsLoadingAgingPeriod(true);
    try {
      const data = await requests.getPercentagePerPeriod(filters);
      if (request.current && request.current > key) return;
      request.current = key;
      setChartAgingPeriod(data);
    } finally {
      setIsLoadingAgingPeriod(false);
    }
  };

  const loadStockAgingReport = async (filters, key, page) => {
    setIsLoadingAgingReport(true);
    try {
      const data = await requests.getAgingReport(filters, page);
      if (request.current && request.current > key) return;
      request.current = key;
      setListAgingReport(data);
    } finally {
      setIsLoadingAgingReport(false);
    }
  };

  const totalValue = Object.values(chartAgingTotal).reduce((sum, value) => sum + value, 0);

  return (
    <Page
      screen="warehouse-stock-aging"
      title="Aging de estoque"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por produto ou família"
      hasPermission={hasPermission}
      load={loadData}
      hideAllFilters={true}
      allowEmptySearchPeriod={true}
      isContentLoading={isLoadingAgingPeriod || isLoadingAgingReport || isLoadingchartAgingTotal}
      isRefreshLoading={isLoadingAgingPeriod || isLoadingAgingReport || isLoadingchartAgingTotal}>
      <Flex direction="column" gap="10px" m="10px">
        <Grid templateColumns="1fr 2fr" gap="10px" h={{ base: 'initial', md: '345px' }} className="up-anim">
          <Card title="Total por família">
            <ScreenLoader isLoading={isLoadingchartAgingTotal}>
              <Flex h="full" gap="30px" direction="column" justify="center" p="10px">
                {Object.entries(chartAgingTotal).map(([label, value], index) => (
                  <Flex key={label}>
                    <TotalFamilyBarChart
                      label={label}
                      value={value}
                      max={totalValue}
                      originalValue={value}
                      color={colors[index % colors.length]}
                    />
                  </Flex>
                ))}
              </Flex>
            </ScreenLoader>
          </Card>

          <Card w="full" title="Porcentagem por período">
            <ScreenLoader isLoading={isLoadingAgingPeriod}>
              <PercentagePeriodChart data={chartAgingPeriod} />
            </ScreenLoader>
          </Card>
        </Grid>

        <Card className="up-anim">
          <ScreenLoader isLoading={isLoadingAgingReport}>
            <Flex m="10px">
              <StockAgingTable list={listAgingReport} />
            </Flex>
          </ScreenLoader>
        </Card>
      </Flex>
    </Page>
  );
};

export default StockAgingPage;
