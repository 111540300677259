import React, { useState } from 'react';

import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import { IconButton } from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';

const ModalDeleteComment = ({ entity, getComments, onProcessUpdates = () => {} }) => {
  const [load, setLoad] = useState(false);

  const handleDelete = async () => {
    setLoad(true);
    try {
      await requests.removeProcessComments(entity.processIdentifier, entity.identifier);
      toast.success('Comentário removido com sucesso!');

      getComments();
      onProcessUpdates();
    } catch (error) {
      toast.error('Ocorreu um erro ao excluir o comentário, atualize a página e tente novamente.');
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <DefaultModal
        ctaButton={<IconButton icon={<MdDelete color="#E74C3C" />} />}
        title={'Certeza que deseja excluir ?'}
        loading={load}
        subtitle={<>Esta ação é permanente, deseja prosseguir?</>}
        buttonColor="red"
        txtButton="Excluir"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            await handleDelete();
          }
        }}
      />
    </>
  );
};

export default ModalDeleteComment;
