import React from 'react';

import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';

import ListCollapse from './ListCollapse';

const TableTimeLine = ({
  load,
  list,
  tripList,
  paginate,
  currentTripData = null,
  setCurrentTripData = null,
  currentTripIsTrackable = false,
  handleCurrentTrip,
}) => {
  return (
    <>
      <Table minW="1800px">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px !important"></Box>
            </Th>
            <Th>
              <Box p="10px !important"></Box>
            </Th>
            <Th>
              <Box p="10px !important">Status</Box>
            </Th>
            <Th>
              <Box p="10px !important">Viagem</Box>
            </Th>
            <Th>
              <Box p="10px !important">Arquivos</Box>
            </Th>
            <Th>
              <Box p="10px !important">Empresas</Box>
            </Th>
            <Th>
              <Box p="10px !important">Transporte</Box>
            </Th>
            <Th>
              <Box p="10px !important">Início</Box>
            </Th>
            <Th>
              <Box p="10px !important">Conclusão</Box>
            </Th>
            <Th>
              <Box p="10px !important"></Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {list?.length > 0 && (
            <>
              {list?.map((entity, key) => {
                return (
                  <ListCollapse
                    entity={entity}
                    entityMarker={
                      tripList.filter((value) => {
                        return value.identifier === entity.identifier;
                      })[0]
                    }
                    key={key}
                    currentTripData={currentTripData}
                    setCurrentTripData={setCurrentTripData}
                    currentTripIsTrackable={currentTripIsTrackable}
                    handleCurrentTrip={handleCurrentTrip}
                    load={load}
                  />
                );
              })}
            </>
          )}
        </Tbody>
      </Table>
      {paginate}
    </>
  );
};

export default TableTimeLine;
