import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const LabelWithText = ({ title, content }) => {
  return (
    <Flex direction="column">
      <Text variant="span" color="#9B9B9B">
        {title}
      </Text>
      <Text variant="paragraph" fontWeight="bold" color="#0F0A1D">
        {content}
      </Text>
    </Flex>
  );
};

export default LabelWithText;
