import React from 'react';

import { useForm } from 'react-hook-form';
// import Select from 'react-select';

import { Button, Flex, FormControl, Select, Text } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';

const CardGeneralInformation = ({ setStep, clientOptions, formData, setFormData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values) => {
    setFormData({ ...formData, ...values });
    setStep(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="20px">
        <Flex flexWrap="wrap" gap="20px" mx="2px">
          <FormControl>
            <LabelDefault name="client" text="Cliente" />
            <Select
              id="client"
              name="client"
              placeholder="Selecione"
              className="input-register"
              background="#F2F2F2"
              value={formData?.client}
              {...register('client', { required: 'Campo obrigatório' })}>
              {clientOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {errors.client && <Text color="red">{errors.client.message}</Text>}
          </FormControl>

          <Flex gap="20px">
            <FormControl>
              <LabelDefault
                name="noteNumber"
                text={`Número da nota ${formData?.cargoNumber && !formData?.noteNumber ? ' (opcional)' : ''}`}
              />
              <InputDefault
                name="noteNumber"
                placeholder="Número da nota"
                value={formData?.noteNumber}
                register={register}
                useRequiredCustom={true}
                isRequired={formData?.cargoNumber && !formData?.noteNumber ? false : true}
                setFormData={setFormData}
              />
              {!formData?.cargoNumber && errors.noteNumber && <Text color="red">{errors.noteNumber.message}</Text>}
            </FormControl>

            <FormControl>
              <LabelDefault
                name="cargoNumber"
                text={`Número de identificação da carga ${formData?.noteNumber && !formData?.cargoNumber ? ' (opcional)' : ''}`}
              />
              <InputDefault
                name="cargoNumber"
                placeholder="Número de identificação da carga"
                value={formData?.cargoNumber}
                register={register}
                useRequiredCustom={true}
                isRequired={formData?.noteNumber && !formData?.cargoNumber ? false : true}
                setFormData={setFormData}
              />
              {!formData?.noteNumber && errors.cargoNumber && <Text color="red">{errors.cargoNumber.message}</Text>}
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <Button mt="20px" variant="primary" type="submit">
        Avançar
      </Button>
    </form>
  );
};

export default CardGeneralInformation;
