import React from 'react';

import { Card } from '@chakra-ui/card';
import { Box, Flex, Text } from '@chakra-ui/react';

import MapDashboard from '../../../components/Map/MapDashboard';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';

const DashboardMap = ({ isLoading, w, h, points, lines }) => {
  return (
    <Card w={w} maxH={{ base: '350px', md: '500px' }} overflow="hidden">
      <ScreenLoader isLoading={isLoading}>
        <Box w="100%" h={h} position="relative">
          <Flex
            position="absolute"
            top="10px"
            left="10px"
            zIndex="10"
            bg="white"
            p="10px 15px"
            boxShadow="0px 6px 25px #0000001A"
            pointerEvents="none"
            alignItems="center"
            gap="10px">
            <Text color="primary" fontWeight="700" fontSize="20px">
              Mapa de importações
            </Text>
          </Flex>

          <MapDashboard w="100%" h={h} points={points} lines={lines} />
        </Box>
      </ScreenLoader>
    </Card>
  );
};

export default DashboardMap;
