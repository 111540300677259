import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { MdHelp, MdOutlineWarning } from 'react-icons/md';

import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';

const ModalFreightConfirm = ({
  values,
  load,
  setSubmitting,
  isOpen,
  onOpen,
  onClose,
  handleSubmit,
  newStatus,
  setNewStatus,
  filterOptions,
  clientList,
  validatedForm,
  setFieldError,
  externalDriver = null,
  isLoading,
  setIsLoading,
  showAlert,
}) => {
  const [companies, setCompanies] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [destiny, setDestiny] = useState('Destino não definido');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (filterOptions?.[0]?.name === 'clients') {
      let aux = [];
      clientList?.forEach((identifier) => {
        filterOptions?.[0]?.options.forEach((option) => {
          if (identifier === option.identifier) {
            aux.push(option.name + ' (' + option.documentNumberFormatted + ')');
          }
        });
      });

      setCompanies(aux);
    }

    let originSet = true;
    values.trip?.forEach((trajectory) => {
      if (originSet && trajectory.type.value == 'COLETA') {
        setOrigin(trajectory.address);
        originSet = false;
      } else {
        setDestiny(trajectory.address);
      }
    });
  }, [isOpen]);

  return (
    <>
      <Button
        onClick={async () => {
          if (await validatedForm(values, setFieldError)) {
            setNewStatus('created');
            onOpen();
          }
        }}
        isDisabled={load && newStatus == 'draft'}
        isLoading={load && newStatus == 'created'}
        variant="primary"
        px="30px"
        py="9px">
        Salvar
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />

        <ModalContent maxW="none" w="700px" h="auto">
          {showAlert && (
            <ModalHeader
              className="up-anim"
              bgColor="#FEF8E1"
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              gap="5px">
              <MdOutlineWarning color="#F1C40F" />
              <Flex textStyle="subtitle" textColor="#000000">
                Esta viagem não possui nenhum CT-e associado.
              </Flex>
            </ModalHeader>
          )}

          <ModalBody p="30px">
            <Flex direction="column" gap="5px">
              <Text textStyle="subtitle" textColor="textColor3">
                Verifique se todas as informações foram adicionadas corretamente
              </Text>
            </Flex>

            <Divider my="25px" />

            <Flex direction="column" gap="13px">
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Modalidade de cadastro
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.main?.modality?.label}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Empresas vinculadas
                </Text>

                <Flex>
                  <Text textStyle="paragraph" textColor="primary" opacity="1">
                    {clientList?.length === 0
                      ? 'Nenhuma empresa vinculada'
                      : clientList?.length + ` vinculada${clientList?.length > 1 ? 's' : ''}`}
                  </Text>
                  <Box cursor="pointer">
                    {companies?.length > 0 && (
                      <Tooltip label={companies?.join(', ')}>
                        <Box ml="10px">
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Divider my="25px" />

            <Flex direction="column" gap="13px">
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Motorista
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.information?.driver?.label
                    ? values?.information?.driver?.label
                    : externalDriver ?? 'Motorista não definido'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Data inicial
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.information?.startsAt
                    ? moment(values?.information?.startsAt).format('DD/MM/YYYY - HH:mm')
                    : 'Data inicial não definida'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Data final
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.information?.endsAt
                    ? moment(values?.information?.endsAt).format('DD/MM/YYYY - HH:mm')
                    : 'Data final não definida'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Origem
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {origin ? origin : 'Origem não definido'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Destino
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {destiny}
                </Text>
              </Flex>
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Valor da mercadoria
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.freight?.merchandiseValue ? values.freight.merchandiseValue : '-'}
                </Text>
              </Flex>
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Valor da mercadoria avariada
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.freight?.merchandiseDamagedValue ? values.freight.merchandiseDamagedValue : '-'}
                </Text>
              </Flex>
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Cubagem do valor da mercadoria
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {values?.freight?.cubage ? values.freight.cubage : '-'}
                </Text>
              </Flex>
            </Flex>
            <Divider my="25px" />

            <Flex gap="76px" align="center">
              <Text textStyle="secondaryTitle" textColor="linkColor" fontWeight="medium">
                Frete
              </Text>

              <Text textStyle="secondaryTitle" textColor="linkColor" fontWeight="medium">
                R$ {values?.freight?.freightValue ? values.freight.freightValue : '-'}
              </Text>
            </Flex>

            <Flex gap="23px" justify="flex-end">
              <Button
                variant="secundary"
                px="30px"
                py="9px"
                borderRadius="9px"
                _focus={{ borderColor: 'linkColor' }}
                isDisabled={isLoading}
                onClick={handleClose}>
                Editar
              </Button>

              <Button
                variant="primary"
                px="30px"
                py="9px"
                borderRadius="9px"
                loadingText="Salvando..."
                isDisabled={isLoading}
                isLoading={isLoading}
                onClick={(decision) => {
                  if (decision) {
                    setIsLoading(true);
                    handleSubmit('created');
                  }
                }}>
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalFreightConfirm;
