import React, { useEffect, useState } from 'react';

import { Bar, CartesianGrid, ComposedChart, LabelList, XAxis, YAxis } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

const TotalProducts = ({ data }) => {
  const [entity, setEntity] = useState([]);

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const formatValue = (value) => (isNaN(value) ? 0 : value.toLocaleString('pt-BR'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chartData = [
          {
            total: parseInt(data.total, 10),
            totalFormatted: parseInt(data.total, 10).toLocaleString(),
            color: '#422C76',
          },
        ];

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="10px" flexDirection={{ base: 'column', md: 'row' }}>
      <ComposedChart data={entity} width={250} height={250} margin={{ left: 20, right: 20, bottom: -18 }}>
        <CartesianGrid vertical={false} />
        <XAxis tick={false} />
        <YAxis tickFormatter={tickFormatter} />
        <Bar dataKey="total" barSize={150} fill={entity.length > 0 ? entity[0].color : null}>
          <LabelList dataKey="totalFormatted" position="center" fill="#FFFFFF" />
        </Bar>
      </ComposedChart>

      <Flex direction="column" gap="20px" flexDirection={{ base: 'row', md: 'column' }}>
        {entity.map((entry, key) => (
          <Flex direction="column" align="center" key={key}>
            <Flex gap="10px" align="center">
              <Box w="17px" h="17px" bgColor="#422C76" borderRadius="3px" />
              <Text>Total</Text>
            </Flex>

            <Text textStyle="tableTitle" textColor="#0F0A1D" fontWeight="bold">
              {formatValue(entry.total)}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default TotalProducts;
