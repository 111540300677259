import React from 'react';

import { Flex, Grid, Text } from '@chakra-ui/layout';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Image } from '@chakra-ui/react';

import neutral from '../../../assets/svgs/process-retired.svg';
import Card from '../../../components/Card/Card';
import CarbonFootprintChart from '../../../components/Charts/Comex/CarbonFootprintChart';
import ParameterizationChart from '../../../components/Charts/Comex/ParameterizationChart';
import ProcessPerStepChart from '../../../components/Charts/Comex/ProcessPerStepChart';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';

const DashboardCharts = ({ isLoading, processPerStep, processByChannel, carbonFootprint, filtersCharts }) => {
  const responsiveCheck = useResponsiveCheck();
  const viewport = responsiveCheck.viewport;

  const chartView = [
    {
      title: 'Processos por canal: Parametrização',
      content: (
        <ParameterizationChart
          direction={'column'}
          processByChannel={processByChannel}
          sizeWidth={viewport > '1300' ? 400 : 300}
          filtersCharts={filtersCharts.channels}
        />
      ),
    },
    {
      title: 'Processos por etapa',
      content: (
        <ProcessPerStepChart
          direction={'column'}
          templateColumns="repeat(3, 1fr)"
          processPerStep={processPerStep}
          sizeWidth={viewport > '1300' ? 400 : 300}
          filtersCharts={filtersCharts.stages}
        />
      ),
    },
    {
      title: (
        <Flex gap="5px">
          <Image src={neutral} w="14px" />
          Pegada de carbono
        </Flex>
      ),
      content: (
        <CarbonFootprintChart
          carbonFootprint={carbonFootprint}
          sizeWidth={viewport > 1300 ? 400 : 300}
          filtersCharts={filtersCharts.stages}
        />
      ),
    },
  ];

  return (
    <>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} justifyItems="center" gap="10px">
        {chartView.map((item, index) => {
          return (
            <Card key={index} title={item.title} h="430px" w="full">
              <ScreenLoader isLoading={isLoading}>{item.content}</ScreenLoader>
            </Card>
          );
        })}
      </Grid>

      <Flex
        justify="center"
        align="center"
        display={{ base: 'flex', md: 'none' }}
        borderRadius={8}
        overflow="hidden"
        borderColor="#70707033"
        borderWidth="0px 1px 0px 1px"
        bg="white"
        boxShadow="base">
        <Grid w="100%">
          <Accordion defaultIndex={[0]} allowToggle>
            {chartView.map((item, index) => {
              return (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton
                      p="20px"
                      _hover={{
                        background: 'white',
                      }}>
                      <Text textStyle="title" textColor="primary" flex="1" fontWeight="bold" fontSize="18px">
                        {item.title}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg="white">
                    {item.content}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Grid>
      </Flex>
    </>
  );
};

export default DashboardCharts;
