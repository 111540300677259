import React, { useEffect, useState } from 'react';

import { MdQueryStats } from 'react-icons/md';

import { Flex, TabPanel, Text } from '@chakra-ui/react';

import ColorfullCard from '../../../../../components/Card/ColorfullCard';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';

import ReportsCard from './ReportsCard';

const ReportsTab = ({ user }) => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState({});

  const report = () => {
    setLoad(true);
    requests
      .reportDriver(user?.identifier)
      .then((data) => {
        setList(data);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (user?.identifier != null) {
      report();
    }
  }, [user]);

  return (
    <ScreenLoader isLoading={load}>
      <TabPanel>
        <Flex direction="column" maxW="540px">
          <Flex alignItems="center">
            <MdQueryStats className="up-anim" size="28px" color="#6C48C2" />
            <Text fontSize="28px" ml="10px" fontWeight="600" color="#6C48C2">
              Relatórios do motorista
            </Text>
          </Flex>
          <Flex gap="5px" justifyContent="space-between" mt="30px" mb="20px" minH="100px">
            <ColorfullCard
              title="Total de km percorridas no mês anterior"
              content={`${list?.previousMonth?.distance ? (list.previousMonth.distance / 1000).toFixed(2)?.replace('.', ',') : 0} km`}
            />
            <ColorfullCard
              title="Total de km percorridas no mês atual"
              content={`${list?.currentMonth?.distance ? (list.currentMonth.distance / 1000).toFixed(2)?.replace('.', ',') : 0} km`}
            />
            <ColorfullCard
              title="Total de km percorridas"
              content={`${list?.total?.distance ? (list.total.distance / 1000).toFixed(2)?.replace('.', ',') : 0} km`}
              color="myoga"
            />
          </Flex>
          <Flex gap="5px" justifyContent="space-between" mt="30px" mb="20px" minH="100px">
            <ColorfullCard
              title="Velocidade máxima atingida"
              content={`${list?.total && list.total?.maxSpeed > 0 ? (list.total.maxSpeed * 3.6).toFixed(2)?.replace('.', ',') : 0} km/h`}
            />
            <ColorfullCard
              title="Tempo de viagem total"
              content={`${list?.total && list.total?.totalTimeSpentInSeconds > 0 ? (list.total.totalTimeSpentInSeconds / 3600)?.toFixed(2)?.replace('.', ',') : 0} hrs`}
            />
            <ColorfullCard
              title="Total de viagens realizadas"
              content={`${list?.total && list?.total.qtt ? list?.total.qtt : 0}`}
              color="myoga"
            />
          </Flex>
          <ReportsCard list={list} />
        </Flex>
      </TabPanel>
    </ScreenLoader>
  );
};

export default ReportsTab;
