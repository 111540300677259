import { useEffect, useState } from 'react';

const useResponsiveCheck = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    isMobile: false,
    deviceType: '',
    viewport: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const isMobileUserAgent = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      let type = '';

      switch (true) {
        case isMobileUserAgent || width < 480:
          type = 'mobile';
          break;
        case width >= 480 && width < 768:
          type = 'tablet';
          break;
        case width >= 768 && width < 1024:
          type = 'laptop';
          break;
        default:
          type = 'desktop';
          break;
      }

      setDeviceInfo({
        isMobile: width < 768 || type === 'mobile',
        deviceType: type,
        viewport: width,
      });
    };

    // Adiciona um listener de redimensionamento ao montar o componente
    window.addEventListener('resize', handleResize);

    // Define o valor inicial ao montar o componente
    handleResize();

    // Remove o listener de redimensionamento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // A dependência vazia garante que o useEffect seja chamado apenas no montar/desmontar do componente

  return deviceInfo;
};

export default useResponsiveCheck;
