import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { Button, Flex, Image, Modal, ModalBody, ModalContent, ModalOverlay, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import iconHide from '../../../../assets/svgs/hide-process.svg';
import iconVisible from '../../../../assets/svgs/visible-process.svg';
import requests from '../../../../services/requests';

const HideProcess = ({ process, forceLoadTrigger, setForceLoadTrigger }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    onOpen();
  };

  const handleClick = async (hide) => {
    setIsLoadingData(true);

    requests
      .changeProcessVisibility(process.identifier, {
        hide: hide,
      })
      .then(() => {
        setIsLoadingData(false);
        onClose();
        toast.success(`Processo ${process.code} - ${hide ? 'ocultado' : 'exibido novamente'} com sucesso!`);
        setForceLoadTrigger(forceLoadTrigger + 1);
      })
      .catch(() => {
        setIsLoadingData(false);
        toast.error(
          `Ocorreu um erro ao ${hide ? 'ocultar' : 'exibir novamente'} o processo ${process.code}. Tente novamente mais tarde.`
        );
      });
  };

  return (
    <>
      {process && (
        <>
          <Tooltip
            label={
              process?.hideProcess ? (
                <>
                  Processo oculto:
                  <br /> Para exibir, clique novamente neste cadeado e confirme a ação.
                </>
              ) : (
                <>
                  Processo visível:
                  <br /> Para ocultar, clique neste cadeado e confirme a ação.
                </>
              )
            }>
            <Flex alignItems="center" gap="5px" cursor="pointer" onClick={flagClick.bind(this)} ref={btnRef}>
              <Image src={process?.hideProcess ? iconHide : iconVisible} />
            </Flex>
          </Tooltip>

          <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent p="10px 35px" borderRadius="50px">
              <ModalBody p="35px" position="relative">
                <Flex direction="column" alignItems="center" justifyContent="center">
                  <Text
                    textStyle="secondaryTitle"
                    fontWeight="medium"
                    textColor="#0F0A1D"
                    textAlign="center"
                    mb="20px"
                    className="up-anim">
                    Você tem certeza que deseja <strong>{process?.hideProcess ? 'exibir novamente' : 'ocultar'}</strong> o processo?
                  </Text>
                  <Flex>
                    <Button variant="secundary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={onClose}>
                      Cancelar
                    </Button>
                    <Button
                      variant="primary"
                      w="fit-content"
                      borderRadius="7px"
                      m="15px"
                      p="25px 35px"
                      isLoading={isLoadingData}
                      loadingText="Salvando..."
                      onClick={() => handleClick(!process?.hideProcess)}>
                      Continuar
                    </Button>
                  </Flex>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default HideProcess;
