import React, { useEffect, useRef, useState } from 'react';

import { Accordion, AccordionItem, Button, Flex, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';

import ProcessListItem from './components/ProcessListItem';

const ProcessPage = () => {
  const hasPermission = permissions.comexProcess;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [processPriority, setProcessPriority] = useState([]);

  const load = (filters, key) => {
    setIsLoading(true);

    requests.listProcess(filters, metadata.current_page > 0 ? metadata.current_page : 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setList(data.data);
      setMetadata(data.meta);
      setProcessPriority(data.favourite);
      setIsLoading(false);
    });
  };

  const loadMore = (filters) => {
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoadingMore(false);
    });
  };

  const handleClickLoadMore = () => {
    setIsLoadingMore(true);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComex());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="comex"
      title="Processos"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      filterOptions={filterOptions}
      allowFiltersOverride={true}
      forceLoadTrigger={forceLoadTrigger}
      isRefreshLoading={isLoading || isLoadingMore}
    >
      <Card
        m="10px"
        minH="calc(100vh - 200px)"
        header={
          <Flex gap="50px" align="center" borderBottom="0">
            <Text textStyle="subtitle" fontWeight="bold" textColor="primary">
              Total de processos: {metadata.total_count}
            </Text>

            <Text textStyle="paragraph" fontWeight="medium" textColor="#AFAEB4">
              Mostrando: {list.length}
            </Text>
          </Flex>
        }>
        <Flex h="full" direction="column">
          {!isMobile ? (
            list.map((process, key) => (
              <ProcessListItem
                key={key}
                process={process}
                processPriority={processPriority}
                forceLoadTrigger={forceLoadTrigger}
                setForceLoadTrigger={setForceLoadTrigger}
              />
            ))
          ) : (
            <Accordion>
              {list.map((process, key) => (
                <AccordionItem key={key}>
                  <ProcessListItem process={process} processPriority={processPriority} />
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Flex>
        {list.length > 0 && list.length < metadata.total_count && (
          <Button onClick={handleClickLoadMore} mt="20px" isLoading={isLoadingMore} loadingText="Carregando">
            Carregar mais processos
          </Button>
        )}
      </Card>
    </Page>
  );
};

export default ProcessPage;
