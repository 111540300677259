import React from 'react';

import { MdArrowDropDown } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, Modal, ModalBody, ModalContent, Select, Text, useDisclosure } from '@chakra-ui/react';

const ModalChangeStatus = ({
  driverFreight = {},
  options = [],
  file = {},
  field,
  setField,
  callback
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClick = (userDecision) => {
    callback(userDecision);
    onClose();
  };

  return (
    <>
      <Flex alignItems="center" cursor="pointer">
        <Select
          key={file.name}
          icon={<MdArrowDropDown />}
          variant="primary"
          h={{ base: '30px', md: '48px'}}
          className="input-register"
          color="#0F0A1D66"
          defaultValue={file.status}
          isDisabled={file?.statusAvailable?.length === 1}
          onChange={(event) => {
            if (!event.target.value) {
              toast.error('Você deve selecionar um status');
              return;
            }

            let updatedField = {
              ...field,
              status: {
                old: file?.status,
                new: event.target.value
              }
            };

            setField(updatedField);
            onOpen();
          }}
        >
          {options.map((status, key) => (
            <option
              key={key}
              value={status.description}
            >
                {status.translated}
            </option>
          ))}
        </Select>
      </Flex>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalContent p="10px 35px" borderRadius="50px">
          <ModalBody p="35px" position="relative">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text
                textStyle="secondaryTitle"
                fontWeight="medium"
                textColor="#0F0A1D"
                textAlign="center"
                mb="20px"
                className="up-anim"
              >
                Você tem certeza que deseja alterar o status do <strong>{file.name}</strong> de {""}
                <strong>{driverFreight.statusReadable}</strong> para {""}
                <strong>{options.find(e => e.value === field?.status)?.label}</strong>?
              </Text>
              <Flex>
                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(false)}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(true)}>
                  Continuar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalChangeStatus;
