import React from 'react';

import moment from 'moment';
import { MdOutlineClose, MdOutlineVisibility } from 'react-icons/md';

import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

const ModalViewTerm = ({ entity }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="Visualizar">
        <Box>
          <MdOutlineVisibility size={25} onClick={onOpen} cursor="pointer" color="#422C76" />
        </Box>
      </Tooltip>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent 
          borderRadius="9px" 
          maxH="700px" 
          h={'full'} 
          maxW="768px" 
          w={'full'} 
          p="40px" 
        >
          <ModalHeader>
            <Flex justify="space-between" align="flex-start">
              <Box>
                <Text textStyle="secondaryTitle" color="primary">
                  Termos de Uso
                </Text>
                <Text textStyle="subtitle" color="placeholder">
                  Criado em: {moment(entity.createdAt).format('DD/MM/YYYY')}
                </Text>
              </Box>
              <IconButton icon={<MdOutlineClose size={30} />} onClick={onClose} bgColor="transparent" />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Text
              textStyle="tableTitle"
              color="primary"
              textAlign="justify"
              dangerouslySetInnerHTML={{ __html: entity.description }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalViewTerm;
