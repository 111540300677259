import React, { useState } from 'react';

import { MdBlock } from 'react-icons/md';
import { toast } from 'react-toastify';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../../services/requests';

const ModalCancelTerm = ({ entity, onSave }) => {
  const [load, setLoad] = useState(false);

  const handleCancel = async () => {
    setLoad(true);
    try {
      await requests.cancelTermUse(entity.identifier, {});

      setLoad(false);
      onSave();

      toast.success('Tópico cancelado com sucesso!');
    } catch (error) {
      setLoad(false);

      toast.error('Erro ao cancelar o tópico, tente novamente!');
    }
  };

  return (
    <DefaultModal
      enabled={entity.status === 'DRAFT'}
      label="Cancelar"
      ctaButton={<MdBlock size={25} color="#E74C3C" />}
      title={'Certeza que deseja cancelar?'}
      loading={load}
      buttonColor="red"
      txtButton="Confirmar"
      callback={async (decision) => {
        if (decision) {
          await handleCancel();
        }
      }}
    />
  );
};

export default ModalCancelTerm;
