import React from 'react';

import { MdOpenInFull } from 'react-icons/md';

import { Box, Flex, IconButton, Modal, ModalCloseButton, ModalContent, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react';

const ExpandContainer = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        position="absolute"
        top="0px"
        right="0px"
        opacity=".5"
        w="fit-content"
        h="fit-content"
        zIndex="100"
        transform="scale(.8)"
        transition={'.3s'}
        transformOrigin="top right"
        _hover={{ transform: 'scale(1.2)', opacity: '.9' }}>
        <Tooltip label="Expandir tabela" placement="top-end">
          <IconButton bg="none" p="0" icon={<MdOpenInFull size={20} color="#422C76" />} onClick={onOpen} />
        </Tooltip>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />

        <ModalContent w="100vw" h="calc(100vh - 70px)" maxW="none" top="64px" p="10px" pt="50px" m="0">
          <Tooltip label="Fechar tabela">
            <ModalCloseButton alt="Fechar tabela" title="Fechar tabela" />
          </Tooltip>

          <Flex w="full" h="full" direction="column" overflowY="auto">
            <Box>{children}</Box>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExpandContainer;
