import { commonListConfigDocumentation } from '../../../../components/CommonList/document/commonListConfigDocumentation';
// Mantido para documentação
const _docReference = commonListConfigDocumentation;

/**
 * Componente que utiliza o setup de configurações da tabela dinâmica.
 *
 * Consulte a documentação completa em `commonListConfigDocumentation.js`.
 */

//setup
export const commonListConfig = {
  title: 'Listagem de clientes',
  // Estrutura da tabela
  categories: [
    {
      title: 'Nome',
      field: 'e.companyName',
      value: 'companyName',
    },
    {
      title: 'CNPJ',
      field: 'e.documentNumber',
      value: 'documentNumberFormatted',
    },
    {
      title: 'Modalidade',
      field: 'e.type',
      value: 'type',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'companyName',
  // Funcionalidades
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 50,
  // Estilo
  resizeRows: '1fr 1fr 1fr 80px',
};
