import React, { useState } from 'react';

import { MdClose } from 'react-icons/md';

import {
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

import ShippingCompanyChart from './ShippingCompanyChart';
import WarehouseCityChart from './WarehouseCityChart';
import WarehouseClientChart from './WarehouseClientChart';
import WarehousePeriodChart from './WarehousePeriodChart';
import WarehouseUfChart from './WarehouseUfChart';
import WerehouseDispatchedChart from './WerehouseDispatchedChart';

const WerehouseSlaChart = ({ data, type, onClose, isOpen }) => {
  const [chartType, setChartType] = useState('byDay');

  const formatValue = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR');
    } else {
      return '';
    }
  };

  const handleChartClick = (event) => {
    setChartType(event);
  };

  const modalChartView = [
    {
      title: 'Análise de remessas por dia',
      type: 'byDay',
      content: <WerehouseDispatchedChart entity={data.byPeriodDispatched} />,
    },
    {
      title: 'Análise de remessas por UF',
      type: 'byUF',
      content: <WarehouseUfChart entity={data.byStateCode} />,
    },
    {
      title: 'Análise de remessas por Cidade',
      type: 'byCity',
      content: <WarehouseCityChart entity={data.byCity} />,
    },
    {
      title: 'Análise de remessas por Cliente',
      type: 'byClient',
      content: <WarehouseClientChart entity={data.byClient} />,
    },
    {
      title: 'Análise de remessas por Transportadora',
      type: 'byShippingCompany',
      content: <ShippingCompanyChart entity={data.byShippingCompany} />,
    },
  ];

  return (
    <>
      {type === 'receipt' ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />

          <ModalContent w="80vw" maxW="none">
            <ModalHeader>
              <Flex align="center" justify="space-between">
                <Text textStyle="tableTitle" textColor="primary" fontWeight="bold">
                  Análise de remessas por dia
                </Text>

                <IconButton bgColor="#FFFFFF" icon={<MdClose size={20} color="#00000057" />} onClick={onClose} />
              </Flex>
            </ModalHeader>

            <Flex h="full" direction="column" p="20px" gap="30px" overflowY="auto">
              <WarehousePeriodChart entity={data.byPeriod} />

              <Flex direction="column" justify="center" h="full" gap="10px">
                <Text>Quantidade de dias no periodo selecionado: {formatValue(data?.byPeriod?.length)}</Text>
                <Text>Soma da quantidade no periodo selecionado: {formatValue(data?.sumItems)}</Text>
              </Flex>
            </Flex>
          </ModalContent>
        </Modal>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />

          <ModalContent w="80vw" maxW="none">
            <ModalHeader>
              <Tabs>
                <Flex justify="space-between">
                  <TabList justifyContent="space-around" flexDirection={{ base: 'column', md: 'inherit' }}>
                    {modalChartView.map((item, index) => {
                      return (
                        <Tab key={index}>
                          <Text
                            textStyle="tableTitle"
                            textColor="primary"
                            fontWeight="bold"
                            cursor="pointer"
                            onClick={() => {
                              handleChartClick(item.type);
                            }}
                            style={{
                              color: chartType === item.type ? '#422C76' : '#AFAEB4',
                            }}>
                            {item.title}
                          </Text>
                        </Tab>
                      );
                    })}
                  </TabList>

                  <IconButton bgColor="#FFFFFF" icon={<MdClose size={20} color="#00000057" />} onClick={onClose} />
                </Flex>

                <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />

                <TabPanels>
                  {modalChartView.map((item, index) => {
                    return (
                      <TabPanel key={index}>
                        <Flex h="full" w="full" p="20px" overflowY="auto">
                          {item.content}
                        </Flex>
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            </ModalHeader>

            {chartType === 'byDay' && (
              <Flex direction="column" justify="center" h="full" p="20px" gap="10px">
                <Text>Quantidade de dias no período selecionado: {formatValue(data?.byPeriodDispatched?.length)}</Text>
                <Text>Soma da quantidade no período selecionado: {formatValue(data?.sumItems)}</Text>
              </Flex>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default WerehouseSlaChart;
