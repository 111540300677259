import React from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import EstimatedListBody from './EstimatedTableBody';

const EstimatedTableHeader = ({ isLoading, list, meta, setMeta, action, setAction, height }) => {
  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return (
    <ScreenLoader isLoading={isLoading}>
      <Box overflow="auto" h={height}>
        <Table minW="1500px">
          <Thead>
            <Tr h="60px">
              <Th w="70px"></Th>

              <Th w="190px">
                <Flex direction="row" align="center">
                  <Text>Categoria</Text>
                </Flex>
              </Th>

              <Th w="190px">
                <Flex direction="row" align="center">
                  <Text>Modalidade</Text>
                </Flex>
              </Th>

              <Th w="400px">
                <Flex direction="row" align="center">
                  <Text>Cliente</Text>
                </Flex>
              </Th>

              <Th w="100px">
                <Flex direction="row" align="center">
                  <Text>Moeda</Text>
                </Flex>
              </Th>

              <Th w="180px">
                <Flex direction="column" align="end">
                  <Text>Pedidos</Text>
                  <small>(MINEG)</small>
                </Flex>
              </Th>

              <Th w="180px">
                <Flex direction="column" align="end">
                  <Text>Taxas</Text>
                </Flex>
              </Th>

              <Th w="180px">
                <Flex direction="column" align="end">
                  <Text>Pedidos R$</Text>
                  <small>(MINAC)</small>
                </Flex>
              </Th>

              <Th w="180px">
                <Flex direction="column" align="end">
                  <Text>Fator</Text>
                </Flex>
              </Th>

              <Th w="180px">
                <Flex direction="column" align="end">
                  <Text>Previsão de faturamento R$</Text>
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {list.result?.information?.data && list.result?.information?.data?.length === 0 ? (
              <Tr>
                <Td colSpan={10}>
                  <Flex justify="center" my="10px">
                    Nenhum dado encontrado para os parâmetros filtrados.
                  </Flex>
                </Td>
              </Tr>
            ) : (
              list.result?.information?.data.map((item, key) => <EstimatedListBody key={key} entity={item} />)
            )}
          </Tbody>
        </Table>
      </Box>

      <Table className="table-default min" w="96.5vw" mt="10px">
        <Tbody>
          <Tr>
            <Td w="70px" px="24px !important"></Td>

            <Td w="190px" px="24px !important">
              Total
            </Td>

            <Td w="190px" px="24px !important"></Td>

            <Td w="400px" px="24px !important"></Td>

            <Td w="100px" px="24px !important"></Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">{moneyFormat(list?.result?.sum?.progress?.orderValue ?? 0)}</Flex>
            </Td>

            <Td w="180px" px="24px !important"></Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">{moneyFormat(list?.result?.sum?.progress?.orderValueBrl ?? 0)}</Flex>
            </Td>

            <Td w="180px" px="24px !important"></Td>

            <Td w="180px" px="24px !important">
              <Flex justify="end">{moneyFormat(list?.result?.sum?.progress?.estimatedBilling ?? 0)}</Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      {meta.total_pages > 1 && (
        <Paginate setMetadata={setMeta} metadata={meta} action={action} setAction={setAction} showDetails={true} />
      )}
    </ScreenLoader>
  );
};

export default EstimatedTableHeader;
