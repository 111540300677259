import React from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import PieChartMiddle from '../../../pages/metrics/MetricsAnalytics/components/PieChartMiddle';

const ParameterizationChart = ({
  direction = 'row',
  templateColumns = 'repeat(3, 1fr)',
  processByChannel,
  sizeWidth,
  filtersCharts,
  isPreview,
}) => {
  return (
    <Flex h="full" align="center" justify="space-between" p="20px" direction={direction}>
      <PieChartMiddle
        text="Total de processos"
        sizeWidth={sizeWidth}
        entity={processByChannel}
        filtersCharts={filtersCharts}
        isPreview={isPreview}
      />

      <Grid templateColumns={templateColumns} gap="5px">
        {processByChannel?.length > 0 &&
          processByChannel.map((item, key) => (
            <Flex gap="8px" align="center" key={key} textAlign="left">
              <Box minW="28px" minH="18px" bgColor={item.color} borderRadius="3px" />

              <Text textStyle={{ base: 'paragraph', xl: 'subtitle' }} textColor="titleColor" textAlign="left">
                {item.name}
              </Text>
            </Flex>
          ))}
      </Grid>
    </Flex>
  );
};

export default ParameterizationChart;
