import React from 'react';

import { Flex, Grid, Text } from '@chakra-ui/react';

import moneyFormat from '../../utils/currency/moneyFormat';

/* exemplo de uso do componente com dados variaveis
<CustomTooltip payload={data} tooltip={
  [
    {
        name: "Valor",            //obrigatório
        dataKey: "sum",           //obrigatório
        format: "currency"        //opcional
        style: "USD"              //opcional
    }
  ]
}>
</CustomTooltip>}  */

export default function CustomTooltip({ children, active, payload, ...props }) {
  const { tooltip } = props;

  if (active && payload && payload.length) {
    return (
      <Flex
        direction="column"
        bgColor="#FFFFFF"
        border="1px"
        borderColor="#7070703D"
        p="15px 20px 10px"
        gap="10px"
        borderRadius={'5px'}>
        {payload.map(
          (entity, key) =>
            //renderizar apenas uma vez o componente do tooltip
            key < 1 && (
              <Flex direction="column" align="left" gap="5px" key={key}>
                {tooltip &&
                  tooltip.map((prop, index) => {
                    let nameColor = '#85828C';

                    //definir cor do texto
                    prop.color && (nameColor = prop.color);

                    return (
                      <Text
                        key={index}
                        color={nameColor}
                        /* fontWeight={'bold'} */
                      >
                        {prop.name + ': '}

                        {prop.format
                          ? /* tipos de formatações */
                            prop.format === 'currency' && moneyFormat(entity.payload[prop.dataKey], prop.style)
                          : entity.payload[prop.dataKey]}
                      </Text>
                    );
                  })}

                <Grid>{children}</Grid>
              </Flex>
            )
        )}
      </Flex>
    );
  }

  return null;
}
